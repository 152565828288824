import React from "react";

function CategoriesAll () {

return (
    <div className="hierarchical-list">
      <ul>
        <li className='top-level'>Εξοπλισμός ασφαλείας και πυρόσβεσης, αστυνομικός και αμυντικός εξοπλισμός (209)
  <ul>
    <li className='top-level'>Στρατιωτικά αεροσκάφη, πύραυλοι και διαστημικά αεροσκάφη (41)
    <ul>
      <li>Στρατιωτικά διαστημικά αεροσκάφη (4)
      <ul>
        <li>Στρατιωτικοί δορυφόροι (3)
        <ul>
          <li>Δορυφόροι επικοινωνιών (0)
          </li>
          <li>Δορυφόροι πλοήγησης (0)
          </li>
          <li>Δορυφόροι παρατήρησης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Στρατιωτικά αεροσκάφη (15)
      <ul>
        <li>Μη επανδρωμένα εναέρια οχήματα (1)
        <ul>
          <li>Μη επανδρωμένα πολεμικά εναέρια οχήματα (0)
          </li>
        </ul>
        </li>
        <li>Ελικόπτερα αναζήτησης και διάσωσης (0)
        </li>
        <li>Ελικόπτερα ανθυποβρυχιακού πολέμου (0)
        </li>
        <li>Μαχητικά-βομβαρδιστικά αεροσκάφη/αεροσκάφη προσβολής στόχων επιφάνειας (0)
        </li>
        <li>Εκπαιδευτικά αεροσκάφη (0)
        </li>
        <li>Αεροσκάφη ανεφοδιασμού εν πτήσει (0)
        </li>
        <li>Αναγνωριστικά αεροσκάφη (0)
        </li>
        <li>Πολεμικά ελικόπτερα (0)
        </li>
        <li>Πολεμικά αεροσκάφη (0)
        </li>
        <li>Στρατιωτικά μεταγωγικά αεροσκάφη (0)
        </li>
        <li>Στρατιωτικά μεταγωγικά ελικόπτερα (0)
        </li>
        <li>Βομβαρδιστικά αεροσκάφη (0)
        </li>
        <li>Ελικόπτερα υποστήριξης (0)
        </li>
        <li>Αεροσκάφη θαλάσσιας περιπολίας (0)
        </li>
      </ul>
      </li>
      <li>Πύραυλοι (15)
      <ul>
        <li>Στρατηγικοί πύραυλοι (4)
        <ul>
          <li>Διηπειρωτικοί βαλλιστικοί πύραυλοι (0)
          </li>
          <li>Στρατηγικοί αντιβαλλιστικοί πύραυλοι (0)
          </li>
          <li>Βαλλιστικοί πύραυλοι εκτοξευόμενοι από υποβρύχια (0)
          </li>
          <li>Βαλλιστικοί πύραυλοι μέσου βεληνεκούς (0)
          </li>
        </ul>
        </li>
        <li>Πύραυλοι κρουζ (1)
        <ul>
          <li>Πύραυλοι κρουζ αέρος/εδάφους/θαλάσσης (0)
          </li>
        </ul>
        </li>
        <li>Τακτικοί πύραυλοι (7)
        <ul>
          <li>Πύραυλοι εναντίον πλοίων (0)
          </li>
          <li>Τακτικοί αντιβαλλιστικοί πύραυλοι (0)
          </li>
          <li>Αντιαρματικοί κατευθυνόμενοι πύραυλοι (0)
          </li>
          <li>Πύραυλοι εδάφους-αέρος (0)
          </li>
          <li>Ανθυποβρυχιακές ρουκέτες (0)
          </li>
          <li>Πύραυλοι αέρος-αέρος (0)
          </li>
          <li>Πύραυλοι αέρος-εδάφους (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μέρη στρατιωτικού αεροδιαστημικού εξοπλισμού (3)
      <ul>
        <li>Δομικά και μηχανικά ανταλλακτικά μέρη για στρατιωτικό αεροδιαστημικό εξοπλισμό (1)
        <ul>
          <li>Κινητήρες και μέρη κινητήρων για στρατιωτικό αεροδιαστημικό εξοπλισμό (0)
          </li>
        </ul>
        </li>
        <li>Ηλεκτρονικά και ηλεκτρικά ανταλλακτικά μέρη για στρατιωτικό αεροδιαστημικό εξοπλισμό (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εξοπλισμός εκτάκτου ανάγκης και ασφαλείας (55)
    <ul>
      <li>Συστήματα και διατάξεις επιτήρησης και ασφαλείας (23)
      <ul>
        <li>Εξοπλισμός προσδιορισμού τόπου (5)
        <ul>
          <li>Εξοπλισμός ελέγχου ελαστικού ωραρίου εργασίας (0)
          </li>
          <li>Συστήματα αναγνώρισης βίντεο (0)
          </li>
          <li>Σύστημα μαγνητικής κάρτας (0)
          </li>
          <li>Διακριτικά σήματα αναγνώρισης (0)
          </li>
          <li>Σύστημα χρονομέτρησης (0)
          </li>
        </ul>
        </li>
        <li>Σύστημα παρακολούθησης (4)
        <ul>
          <li>Κάμερες ασφαλείας (0)
          </li>
          <li>Συστήματα ελέγχου χρόνου ή καταγραφής εργάσιμων ωρών (0)
          </li>
          <li>Αισθητήρες (1)
          <ul>
            <li>Βιομετρικοί αισθητήρες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ασφαλείας (π.χ. έναντι κλοπής, πυρκαγιάς κ.λπ.) (9)
        <ul>
          <li>Βομβητές (0)
          </li>
          <li>Ραδιοανιχνευτές (0)
          </li>
          <li>Εξαρτήματα ασφαλείας (0)
          </li>
          <li>Επιγραφές (0)
          </li>
          <li>Σάκοι ασφαλείας (0)
          </li>
          <li>Κυρτά κάτοπτρα ασφαλείας (0)
          </li>
          <li>Ανιχνευτές πλαστών χαρτονομισμάτων (0)
          </li>
          <li>Σφραγίδες (0)
          </li>
          <li>Συστήματα συναγερμού (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός σάρωσης γραμμωτού κώδικα (0)
        </li>
        <li>Ανιχνευτές μετάλλου (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός πυρόσβεσης, διάσωσης και ασφαλείας (30)
      <ul>
        <li>Πυροσβεστικός εξοπλισμός (9)
        <ul>
          <li>Αναπνευστικές συσκευές πυρόσβεσης (0)
          </li>
          <li>Πυροκατασβεστήρες (2)
          <ul>
            <li>Φορητοί πυροσβεστήρες (0)
            </li>
            <li>Αφρός πλήρωσης πυροσβεστήρων (0)
            </li>
          </ul>
          </li>
          <li>Συστήματα πυρόσβεσης (2)
          <ul>
            <li>Πυροσβεστικός αφρός ή παρόμοιες ενώσεις (0)
            </li>
            <li>Πυροσβεστικά εργαλεία χειρός (0)
            </li>
          </ul>
          </li>
          <li>Υλικά πυρόσβεσης (0)
          </li>
          <li>Εξοπλισμός πυρασφάλειας (0)
          </li>
        </ul>
        </li>
        <li>Διατάξεις ασφάλειας (έναντι των ατυχημάτων) (15)
        <ul>
          <li>Εξοπλισμός πυρηνικής, βιολογικής, χημικής και ακτινολογικής προστασίας (1)
          <ul>
            <li>Εξοπλισμός πυρηνικής ασφάλειας (0)
            </li>
          </ul>
          </li>
          <li>Εγκαταστάσεις ασφαλείας (0)
          </li>
          <li>Ρούχα προστασίας και ασφαλείας (9)
          <ul>
            <li>Προστατευτικά πουκάμισα ή παντελόνια (0)
            </li>
            <li>Ανακλαστικά γιλέκα (0)
            </li>
            <li>Προστατευτικά περικάρπια (0)
            </li>
            <li>Προστατευτικές κάλτσες ή καλσόν (0)
            </li>
            <li>Προστατευτικοί επενδύτες ή επώμια (0)
            </li>
            <li>Ενδύματα για βιολογική και χημική προστασία (0)
            </li>
            <li>Γιλέκα ασφαλείας (0)
            </li>
            <li>Προστατευτικές ρόμπες (0)
            </li>
            <li>Ενδύματα για πυρηνική και ακτινολογική προστασία (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός ασφάλειας εργοταξίων (1)
          <ul>
            <li>Σύστημα προστασίας πυρηνικού αντιδραστήρα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός διάσωσης και κινδύνου (3)
        <ul>
          <li>Κούκλες εκπαίδευσης σε καταστάσεις έκτακτης ανάγκης (0)
          </li>
          <li>Εκπλυντές ματιών (0)
          </li>
          <li>Καταιονητήρες έκτακτης ανάγκης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Αστυνομικός εξοπλισμός (10)
    <ul>
      <li>Χειροπέδες (0)
      </li>
      <li>Πινακίδες αστυνομίας (3)
      <ul>
        <li>Πίνακες πληροφοριών (1)
        <ul>
          <li>Πίνακες πληροφοριών μεταβαλλόμενου μηνύματος (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ελέγχου σηματοδότησης διασταυρώσεων (0)
        </li>
      </ul>
      </li>
      <li>Μέσα απώθησης επιθέσεων σκύλων (0)
      </li>
      <li>Στόχοι για εξάσκηση στη σκοποβολή (0)
      </li>
      <li>Σειρήνες (0)
      </li>
      <li>Εξοπλισμός για την καταστολή εξεγέρσεων (1)
      <ul>
        <li>Κανόνια νερού (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Στρατιωτικά ηλεκτρονικά συστήματα (9)
    <ul>
      <li>Συστήματα ηλεκτρονικού πολέμου και άλλα αντίμετρα (0)
      </li>
      <li>Συστήματα διοίκησης, ελέγχου, επικοινωνίας και ηλεκτρονικών υπολογιστών (2)
      <ul>
        <li>Συστήματα τακτικής διοίκησης, ελέγχου και επικοινωνίας (0)
        </li>
        <li>Συστήματα διοίκησης, ελέγχου και επικοινωνίας (0)
        </li>
      </ul>
      </li>
      <li>Προσομοιωτές μάχης (0)
      </li>
      <li>Συστήματα πληροφοριών, επιτήρησης, πρόσκτησης στόχου και αναγνώρισης (3)
      <ul>
        <li>Συσκευές ραδιοεντοπισμού (ραντάρ) (0)
        </li>
        <li>Ηλεκτρονικά συστήματα πληροφοριών (0)
        </li>
        <li>Ραντάρ αεράμυνας (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Πολεμικά πλοία και μέρη αυτών (20)
    <ul>
      <li>Μέρη πολεμικών πλοίων (3)
      <ul>
        <li>Ηλεκτρονικά και ηλεκτρικά ανταλλακτικά για πολεμικά πλοία (0)
        </li>
        <li>Κήτη και μηχανικά ανταλλακτικά για πολεμικά πλοία (1)
        <ul>
          <li>Κινητήρες και μέρη κινητήρων για πολεμικά πλοία (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Πολεμικά πλοία (15)
      <ul>
        <li>Πολεμικά πλοία επιφάνειας (4)
        <ul>
          <li>Αεροπλανοφόρα (0)
          </li>
          <li>Κορβέτες και περιπολικά πλοία (0)
          </li>
          <li>Αμφίβια σκάφη και πλοία (0)
          </li>
          <li>Αντιτορπιλλικά και φρεγάτες (0)
          </li>
        </ul>
        </li>
        <li>Υποβρύχια (4)
        <ul>
          <li>Υποβρύχια κρούσης πυρηνικής πρόωσης (0)
          </li>
          <li>Στρατηγικά υποβρύχια πυρηνικής πρόωσης (0)
          </li>
          <li>Μη επανδρωμένα υποβρύχια οχήματα (0)
          </li>
          <li>Ντιζελοκίνητα υποβρύχια κρούσης (0)
          </li>
        </ul>
        </li>
        <li>Πλοία ναρκοπολέμου και βοηθητικά πλοία (4)
        <ul>
          <li>Ναρκοθηρικά/ναρκαλιευτικά πλοία (0)
          </li>
          <li>Βοηθητικά ερευνητικά σκάφη (0)
          </li>
          <li>Βοηθητικά νοσοκομειακά/μεταγωγικά/πετρελαιοφόρα/οχηματαγωγά σκάφη τύπου ρο-ρο (0)
          </li>
          <li>Βοηθητικά σκάφη συλλογής πληροφοριών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Στρατιωτικά οχήματα και μέρη αυτών (14)
    <ul>
      <li>Τεθωρακισμένα στρατιωτικά οχήματα (9)
      <ul>
        <li>Άρματα μάχης (2)
        <ul>
          <li>Ελαφρά άρματα μάχης (0)
          </li>
          <li>Μέσα άρματα μάχης (0)
          </li>
        </ul>
        </li>
        <li>Τεθωρακισμένα οχήματα μάχης (5)
        <ul>
          <li>Οχήματα αναγνώρισης και περιπολίας (0)
          </li>
          <li>Πολεμικά οχήματα πεζικού (0)
          </li>
          <li>Τεθωρακισμένα οχήματα μεταφοράς προσωπικού (0)
          </li>
          <li>Οχήματα διοίκησης και οχήματα συνδέσμου διοίκησης (0)
          </li>
          <li>Τεθωρακισμένα οχήματα μεταφοράς οπλισμού (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μέρη στρατιωτικών οχημάτων (3)
      <ul>
        <li>Μηχανικά ανταλλακτικά για στρατιωτικά οχήματα (1)
        <ul>
          <li>Μηχανές και μέρη αυτών για στρατιωτικά οχήματα (0)
          </li>
        </ul>
        </li>
        <li>Ηλεκτρονικά και ηλεκτρικά ανταλλακτικά για στρατιωτικά οχήματα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Όπλα, πυρομαχικά και συναφή μέρη (36)
    <ul>
      <li>Μέρη πυροβόλων όπλων και πυρομαχικά (4)
      <ul>
        <li>Μέρη ελαφρών πυροβόλων όπλων (1)
        <ul>
          <li>Εξαρτήματα σωληνώσεων από ορείχαλκο πυροβόλων (0)
          </li>
        </ul>
        </li>
        <li>Μέρη ολμοβόλων (0)
        </li>
        <li>Μέρη εκτοξευτήρων ρουκετών (0)
        </li>
      </ul>
      </li>
      <li>Διάφορα όπλα (6)
      <ul>
        <li>Ξίφη, σπαθιά, ξιφολόγχες και λόγχες (4)
        <ul>
          <li>Λόγχες (0)
          </li>
          <li>Ξιφολόγχες (0)
          </li>
          <li>Σπαθιά (0)
          </li>
          <li>Ξίφη (0)
          </li>
        </ul>
        </li>
        <li>Αεριοβόλα όπλα (0)
        </li>
      </ul>
      </li>
      <li>Πυροβόλα όπλα (10)
      <ul>
        <li>Πυροβόλα (5)
        <ul>
          <li>Ρυμουλκούμενα πυροβόλα (0)
          </li>
          <li>Αντιαεροπορικά όπλα (0)
          </li>
          <li>Αυτοπροωθούμενα πυροβόλα (0)
          </li>
          <li>Ολμοβόλα (0)
          </li>
          <li>Οβιδοβόλα (0)
          </li>
        </ul>
        </li>
        <li>Ελαφρά πυροβόλα όπλα (3)
        <ul>
          <li>Πιστόλια χειρός (0)
          </li>
          <li>Πολυβόλα (0)
          </li>
          <li>Τουφέκια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Πυρομαχικά (12)
      <ul>
        <li>Πυρομαχικά για εναέριες πολεμικές επιχειρήσεις (2)
        <ul>
          <li>Βόμβες (0)
          </li>
          <li>Ρουκέτες (0)
          </li>
        </ul>
        </li>
        <li>Πυρομαχικά για ναυτικές πολεμικές επιχειρήσεις (2)
        <ul>
          <li>Τορπίλες (0)
          </li>
          <li>Νάρκες θαλάσσης (0)
          </li>
        </ul>
        </li>
        <li>Πυρομαχικά για πυροβόλα και πολεμικές επιχειρήσεις (5)
        <ul>
          <li>Βλήματα (0)
          </li>
          <li>Σφαίρες πυροβόλων (0)
          </li>
          <li>Χειροβομβίδες (0)
          </li>
          <li>Φυσίγγια (0)
          </li>
          <li>Νάρκες ξηράς (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ατομικός εξοπλισμός και εξοπλισμός υποστήριξης (16)
    <ul>
      <li>Εξοπλισμός υποστήριξης (2)
      <ul>
        <li>Σημαίες (1)
        <ul>
          <li>Κοντάρια σημαιών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ατομικός εξοπλισμός (12)
      <ul>
        <li>Στολές εκστρατείας (3)
        <ul>
          <li>Επενδύτες παραλλαγής (0)
          </li>
          <li>Φόρμες εκστρατείας (0)
          </li>
          <li>Εξοπλισμός εκστρατείας (0)
          </li>
        </ul>
        </li>
        <li>Ενδύματα αντιβαλλιστικής προστασίας (1)
        <ul>
          <li>Αλεξίσφαιρα γιλέκα (0)
          </li>
        </ul>
        </li>
        <li>Στρατιωτικά κράνη (1)
        <ul>
          <li>Καλύμματα για κράνη (0)
          </li>
        </ul>
        </li>
        <li>Μάσκες αερίου (0)
        </li>
        <li>Στολές πυροσβεστών (0)
        </li>
        <li>Στρατιωτικές στολές (0)
        </li>
        <li>Στολές αστυνομικών (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Κατασκευαστικές εργασίες (821)
  <ul>
    <li className='top-level'>Εργασίες αποπεράτωσης κτιρίων (68)
    <ul>
      <li>Εργασίες επίχρισης κονιαμάτων (0)
      </li>
      <li>Εργασίες τοποθέτησης στοιχείων από ξύλο (25)
      <ul>
        <li>Εργασίες χονδροξυλουργικής και λεπτοξυλουργικής (1)
        <ul>
          <li>Ξυλουργικές εργασίες (0)
          </li>
        </ul>
        </li>
        <li>Εργασίες ξυλουργικής (22)
        <ul>
          <li>Τοποθέτηση θυρών, παραθύρων και συναφών στοιχείων (21)
          <ul>
            <li>Τοποθέτηση μεταλλικών προσαρτημάτων (0)
            </li>
            <li>Εγκατάσταση μεταλλικών πλαισίων θυρών και παραθύρων (2)
            <ul>
              <li>Εγκατάσταση πλαισίων παραθύρων (0)
              </li>
              <li>Εγκατάσταση πλαισίων θυρών (0)
              </li>
            </ul>
            </li>
            <li>Τοποθέτηση μεταλλικών κατασκευών εκτός από θύρες και παράθυρα (8)
            <ul>
              <li>Εργασίες εγκατάστασης τεντών (0)
              </li>
              <li>Εγκατάσταση παντζουριών (0)
              </li>
              <li>Εγκατάσταση πυλών (0)
              </li>
              <li>Εγκατάσταση αναρτημένων οροφών (0)
              </li>
              <li>Εργασίες τοποθέτησης ρολλών παραθύρων (0)
              </li>
              <li>Εγκατάσταση χωρισμάτων (0)
              </li>
              <li>Εγκατάσταση μεταλλικών εσχαρών (0)
              </li>
              <li>Εργασίες τοποθέτησης σκιάστρων (0)
              </li>
            </ul>
            </li>
            <li>Εγκατάσταση θυρών και παραθύρων (2)
            <ul>
              <li>Εγκατάσταση παραθύρων (0)
              </li>
              <li>Εγκατάσταση θυρών (0)
              </li>
            </ul>
            </li>
            <li>Εγκατάσταση κατωφλίων (0)
            </li>
            <li>Εργασίες εγκατάστασης μη μεταλλικών κατασκευών (3)
            <ul>
              <li>Εγκατάσταση διαχωριστικών τοίχων (0)
              </li>
              <li>Εγκατάσταση εντοιχισμένων κουζινών (0)
              </li>
              <li>Τοποθέτηση εντοιχιζόμενων επίπλων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Άλλες εργασίες αποπεράτωσης κτιρίων (10)
      <ul>
        <li>Εργασίες διακόσμησης (3)
        <ul>
          <li>Εργασίες τοποθέτησης ξύλινων επενδύσεων (0)
          </li>
          <li>Εργασίες τοποθέτησης εξαρτημάτων διακόσμησης (0)
          </li>
          <li>Εσωτερικοί κήποι (0)
          </li>
        </ul>
        </li>
        <li>Εργασίες εξωτερικού καθαρισμού κτιρίων (1)
        <ul>
          <li>Καθαρισμός εξωτερικών τοίχων με αμμοβολή (0)
          </li>
        </ul>
        </li>
        <li>Εργασίες γενικής επισκευής και ανακαίνισης (1)
        <ul>
          <li>Εργασίες αποκατάστασης (0)
          </li>
        </ul>
        </li>
        <li>Εργασίες αναδόμησης (1)
        <ul>
          <li>Εργασίες ανακαίνισης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εργασίες επένδυσης δαπέδων και τοίχων (16)
      <ul>
        <li>Εργασίες διάστρωσης και επένδυσης δαπέδων, επένδυσης τοίχων και τοποθέτησης χαρτιού ταπετσαρίας (12)
        <ul>
          <li>Εργασίες επένδυσης και ταπετσαρίας τοίχων (2)
          <ul>
            <li>Εργασίες τοποθέτησης ταπετσαρίας τοίχου (0)
            </li>
            <li>Εργασίες επικάλυψης τοίχων (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες κάλυψης και επένδυσης δαπέδων (8)
          <ul>
            <li>Κατασκευή ψευδοδαπέδων (1)
            <ul>
              <li>Δάπεδα για εγκατάσταση συστημάτων πληροφορικής (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες επίστρωσης δαπέδων (4)
            <ul>
              <li>Εργασίες τοποθέτησης εύκαμπτων επενδύσεων δαπέδων (0)
              </li>
              <li>Τοποθέτηση οδοστρώματος (0)
              </li>
              <li>Τοποθέτηση ξύλινων δαπέδων (0)
              </li>
              <li>Επίστρωση παρκέτων (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες κάλυψης δαπέδου (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εργασίες πλακόστρωσης (2)
        <ul>
          <li>Εργασίες τοποθέτησης πλακιδίων τοίχων (0)
          </li>
          <li>Εργασίες τοποθέτησης πλακιδίων δαπέδων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εργασίες χρωματισμών και υαλοτεχνικές εργασίες (12)
      <ul>
        <li>Εργασίες πρόσοψης (0)
        </li>
        <li>Εργασίες εφαρμογής προστατευτικών επικαλύψεων (9)
        <ul>
          <li>Εργασίες βαφής (5)
          <ul>
            <li>Εργασίες βαφής και προστατευτικής επικάλυψης τεχνικών κατασκευών (1)
            <ul>
              <li>Εργασίες βαφής τεχνικών κατασκευών (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες αφαίρεσης βαφής (0)
            </li>
            <li>Εργασίες βαφής κτιρίων (0)
            </li>
            <li>Εργασίες νέας βαφής (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες εφαρμογής αντιδιαβρωτικών επικαλύψεων (1)
          <ul>
            <li>Εργασίες γαλβανισμού (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες προστασίας επιφανειών (0)
          </li>
        </ul>
        </li>
        <li>Υαλοτεχνικές εργασίες (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εργασίες κτιριακών εγκαταστάσεων (79)
    <ul>
      <li>Εργασίες τοποθέτησης περιφράξεων, κιγκλιδωμάτων και εξοπλισμού ασφαλείας (8)
      <ul>
        <li>Τοποθέτηση περιφράξεων (0)
        </li>
        <li>Εργασίες εγκαταστάσεων πρόληψης πυρκαγιάς (5)
        <ul>
          <li>Εργασίες πυροπροστασίας (0)
          </li>
          <li>Εργασίες εγκατάστασης εξοπλισμών πυρόσβεσης (3)
          <ul>
            <li>Εργασίες εγκατάστασης εξοπλισμών κατάσβεσης με διοξείδιο του άνθρακα (0)
            </li>
            <li>Εργασίες εγκατάστασης αυτόματων συστημάτων πυρόσβεσης (σπρίνκλερ) (0)
            </li>
            <li>Εργασίες εγκατάστασης πυροσβεστήρων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Τοποθέτηση κιγκλιδωμάτων (0)
        </li>
      </ul>
      </li>
      <li>Εργασίες ηλεκτρολογικών εγκαταστάσεων (45)
      <ul>
        <li>Εγκατάσταση ηλεκτρικών καλωδιώσεων και εξαρτημάτων (2)
        <ul>
          <li>Εργασίες ηλεκτρικής καλωδίωσης (0)
          </li>
          <li>Εγκατάσταση ηλεκτρικών εξαρτημάτων (0)
          </li>
        </ul>
        </li>
        <li>Εργασίες εγκατάστασης ανελκυστήρων και κυλιομένων κλιμάκων (3)
        <ul>
          <li>Εργασίες εγκατάστασης ανελκυστήρα (0)
          </li>
          <li>Εργασίες εγκατάστασης κυλιόμενης κλίμακας (1)
          <ul>
            <li>Εργασίες εγκατάστασης κυλιόμενου διάδρομου (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εγκατάσταση τηλεπικοινωνιακού εξοπλισμού (6)
        <ul>
          <li>Εγκατάσταση τηλεφωνικών κέντρων (1)
          <ul>
            <li>Εγκατάσταση πινάκων ελέγχου (0)
            </li>
          </ul>
          </li>
          <li>Εγκατάσταση τηλεφωνικών γραμμών (0)
          </li>
          <li>Καλωδιακή υποδομή (2)
          <ul>
            <li>Εγκατάσταση καλωδίωσης υπολογιστών (0)
            </li>
            <li>Τοποθέτηση καλωδίων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Ηλεκτρολογικές εργασίες εγκατάστασης θέρμανσης και άλλου ηλεκτρολογικού εξοπλισμού κτιρίων (7)
        <ul>
          <li>Ηλεκτρολογικές εργασίες εγκαταστάσεων μέσης τάσης (0)
          </li>
          <li>Εγκαταστάσεις ηλεκτρικής παροχής (0)
          </li>
          <li>Εργασίες τοποθέτησης ηλεκτρολογικών εγκαταστάσεων (0)
          </li>
          <li>Εργασίες εγκατάστασης σταθμών ηλεκτρικών πινάκων (0)
          </li>
          <li>Ηλεκτρολογικές εργασίες εγκαταστάσεων χαμηλής τάσης (0)
          </li>
          <li>Εργασίες σε μονάδες με στροβιλοκινητήρα (0)
          </li>
          <li>Ηλεκτρολογικές εργασίες εγκαταστάσεων υψηλής τάσης (0)
          </li>
        </ul>
        </li>
        <li>Άλλες εργασίες ηλεκτρολογικών εγκαταστάσεων (4)
        <ul>
          <li>Εργασίες ηλεκτρικής εγκατάστασης εξοπλισμού άντλησης (0)
          </li>
          <li>Εργασίες ηλεκτρικής εγκατάστασης συσκευών διανομής ρεύματος (0)
          </li>
          <li>Εργασίες ηλεκτρικής εγκατάστασης εξοπλισμού διήθησης (0)
          </li>
          <li>Εργασίες ηλεκτρικής εγκατάστασης μετασχηματιστών (0)
          </li>
        </ul>
        </li>
        <li>Εργασίες εγκατάστασης συστημάτων φωτισμού και σηματοδότησης (9)
        <ul>
          <li>Τοποθέτηση εξοπλισμού φωτισμού εξωτερικών χώρων (1)
          <ul>
            <li>Εγκατάσταση εξοπλισμού φωτισμού οδών (0)
            </li>
          </ul>
          </li>
          <li>Τοποθέτηση εξοπλισμού σηματοδότησης (6)
          <ul>
            <li>Εγκατάσταση εξοπλισμού παρακολούθησης της κυκλοφορίας (3)
            <ul>
              <li>Τοποθέτηση εξοπλισμού καθοδήγησης της κυκλοφορίας (0)
              </li>
              <li>Εγκατάσταση φωτεινών οδικών πινακίδων (0)
              </li>
              <li>Εγκατάσταση φωτεινών σηματοδοτών για τη ρύθμιση της κυκλοφορίας (0)
              </li>
            </ul>
            </li>
            <li>Τοποθέτηση εξοπλισμού φωτεινής σηματοδότησης αεροδρομίου (0)
            </li>
            <li>Εγκατάσταση εξοπλισμού σηματοδότησης λιμένων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εργασίες εγκατάστασης συστημάτων συναγερμού και κεραιών (7)
        <ul>
          <li>Εργασίες εγκατάστασης κεραίας (4)
          <ul>
            <li>Εργασίες εγκατάστασης κεραίας τηλεόρασης (0)
            </li>
            <li>Εργασίες εγκατάστασης κεραίας ραδιοφωνικής λήψης (0)
            </li>
            <li>Εργασίες προστασίας από τον κεραυνό (1)
            <ul>
              <li>Εργασίες εγκατάστασης αλεξικέραυνου (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Εργασίες εγκατάστασης συστήματος συναγερμού πυρκαγιάς (0)
          </li>
          <li>Εργασίες εγκατάστασης συστήματος αντικλεπτικού συναγερμού (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μηχανολογικές εγκαταστάσεις (1)
      <ul>
        <li>Εργασίες μηχανολογικών εγκαταστάσεων (0)
        </li>
      </ul>
      </li>
      <li>Εργασίες μόνωσης (3)
      <ul>
        <li>Εργασίες ηχομόνωσης (0)
        </li>
        <li>Εργασίες θερμομόνωσης (0)
        </li>
        <li>Εργασίες τοποθέτησης γυψοσανίδων (0)
        </li>
      </ul>
      </li>
      <li>Υδραυλικές εργασίες (17)
      <ul>
        <li>Εργασίες εγκαταστάσεων θέρμανσης, αερισμού και κλιματισμού (9)
        <ul>
          <li>Εργασίες εγκαταστάσεων αερισμού και κλιματισμού (6)
          <ul>
            <li>Εργασίες εγκατάστασης αερισμού (1)
            <ul>
              <li>Εργασίες εξωτερικής εγκατάστασης αερισμού (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες εγκατάστασης ψυκτικού εξοπλισμού (1)
            <ul>
              <li>Εργασίες εγκατάστασης εξοπλισμού ψύξης (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες εγκατάστασης συστημάτων κλιματισμού (1)
            <ul>
              <li>Εργασίες εγκατάστασης συστημάτων μερικού κλιματισμού (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Εργασίες εγκατάστασης κεντρικής θέρμανσης (1)
          <ul>
            <li>Εργασίες εγκατάστασης λεβήτων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εργασίες τοποθέτησης εξαρτημάτων εγκαταστάσεων αερίου (2)
        <ul>
          <li>Εργασίες εγκατάστασης μετρητών αερίου (0)
          </li>
          <li>Εργασίες εγκατάστασης εξοπλισμού ρύθμισης αερίου (0)
          </li>
        </ul>
        </li>
        <li>Εργασίες υδραυλικών εγκαταστάσεων και τοποθέτησης αποχετευτικών αγωγών (3)
        <ul>
          <li>Υδραυλικά έργα (0)
          </li>
          <li>Εργασίες τοποθέτησης αποχετευτικών αγωγών (0)
          </li>
          <li>Εργασίες εγκατάστασης εξαρτημάτων υγιεινής (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εργασίες για ολόκληρες κατασκευές ή μέρη κατασκευών και έργα πολιτικού μηχανικού (613)
    <ul>
      <li>Κατασκευαστικές εργασίες για μονάδες ηλεκτροπαραγωγής, καθώς και για τον εξορυκτικό και μεταποιητικό τομέα και για κτίρια βιομηχανίας πετρελαίου και αερίου (69)
      <ul>
        <li>Κατασκευαστικές εργασίες για σταθμούς επεξεργασίας λυμάτων, εγκαταστάσεις καθαρισμού και σταθμούς αποτέφρωσης απορριμμάτων (16)
        <ul>
          <li>Κατασκευαστικές εργασίες για σταθμούς αποτέφρωσης απορριμμάτων (0)
          </li>
          <li>Κατασκευαστικές εργασίες για σταθμούς επεξεργασίας ακάθαρτων υδάτων (12)
          <ul>
            <li>Κατασκευαστικές εργασίες για μονάδες αφυδάτωσης ιλύος (0)
            </li>
            <li>Κατασκευαστικές εργασίες για σταθμούς επεξεργασίας υδάτων (7)
            <ul>
              <li>Εγκαταστάσεις καθίζησης (0)
              </li>
              <li>Εργασίες απόθεσης βράχων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για εγκαταστάσεις επεξεργασίας πόσιμου νερού (0)
              </li>
              <li>Χωνευτήρες ακαθάρτων υδάτων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σταθμούς επεξεργασίας λυμάτων (0)
              </li>
              <li>Εργασίες βυθοκόρησης και άντλησης (0)
              </li>
              <li>Εγκαταστάσεις κοσκινίσματος (0)
              </li>
            </ul>
            </li>
            <li>Εξοπλισμός σταθμού επεξεργασίας λυμάτων (0)
            </li>
            <li>Κατασκευαστικές εργασίες για μονάδες κατεργασίας άνθρακα (0)
            </li>
            <li>Κατασκευαστικές εργασίες για κινητές μονάδες (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός εγκαταστάσεων καθαρισμού (1)
          <ul>
            <li>Κατασκευαστικές εργασίες για εγκαταστάσεις καθαρισμού νερού (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για τη βιομηχανία πετρελαίου και αερίου (15)
        <ul>
          <li>Κατασκευαστικές εργασίες για τερματικούς σταθμούς αερίου (0)
          </li>
          <li>Κατασκευαστικές εργασίες για εγκαταστάσεις παραγωγής αερίου (0)
          </li>
          <li>Εργασίες γεώτρησης και εξερεύνησης (0)
          </li>
          <li>Κατασκευαστικές εργασίες για διυλιστήρια πετρελαίου (1)
          <ul>
            <li>Κατασκευαστικές εργασίες για τερματικούς σταθμούς πετρελαίου (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες σε φρέατα με σπειροειδείς σωληνώσεις (0)
          </li>
          <li>Κατασκευαστικά έργα για πλατφόρμες παραγωγής (3)
          <ul>
            <li>Κατασκευαστικές εργασίες για πετρελαιοπηγές (0)
            </li>
            <li>Κατασκευαστικές εργασίες για εγκαταστάσεις εξέδρας (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για εγκαταστάσεις επιφάνειας εξέδρας (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για εγκαταστάσεις αεριοποίησης άνθρακα (0)
          </li>
          <li>Εργασίες κατασκευών (3)
          <ul>
            <li>Κατασκευαστικές εργασίες σε εγκαταστάσεις ξηράς (0)
            </li>
            <li>Διάλυση εξεδρών εξόρυξης πετρελαίου (0)
            </li>
            <li>Κατασκευαστικές εργασίες σε εγκαταστάσεις ανοικτής θαλάσσης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για μονάδες ηλεκτροπαραγωγής και θερμοηλεκτρισμού (15)
        <ul>
          <li>Κατασκευαστικές εργασίες για μονάδα ηλεκτροπαραγωγής (9)
          <ul>
            <li>Κατασκευαστικές εργασίες για πυρηνικούς σταθμούς παραγωγής ενέργειας (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για πυρηνικούς αντιδραστήρες (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για σταθμούς παραγωγής θερμικής ενέργειας (3)
            <ul>
              <li>Κατασκευαστικές εργασίες για σταθμούς γεωθερμικής ενέργειας (0)
              </li>
              <li>Κατασκευαστικές εργασίες για εγκαταστάσεις παραγωγής πεπιεσμένου αέρα (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σταθμούς παραγωγής ενέργειας από την καύση ξύλου (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για πύργους ψύξης (0)
            </li>
            <li>Κατασκευαστικές εργασίες για υδροηλεκτρικές εγκαταστάσεις (0)
            </li>
            <li>Εργασίες εγκαταστάσεων αιολικής ενέργειας (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για θερμοηλεκτρικούς σταθμούς (4)
          <ul>
            <li>Κατασκευαστικές εργασίες για σταθμούς συνδυασμένης παραγωγής (0)
            </li>
            <li>Κατασκευαστικές εργασίες για μονάδες παραγωγής ατμού (0)
            </li>
            <li>Κατασκευαστικές εργασίες για μονάδες κεντρικής θέρμανσης περιοχής (0)
            </li>
            <li>Κατασκευαστικές εργασίες για σταθμούς παραγωγής ηλεκτρικής ενέργειας με χρήση αερίου παραγόμενου σε χωματερή (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Επισκευή και συντήρηση εγκαταστάσεων (4)
        <ul>
          <li>Επισκευή και συντήρηση εγκαταστάσεων θέρμανσης (0)
          </li>
          <li>Επισκευή και συντήρηση εγκαταστάσεων καθαρισμού (0)
          </li>
          <li>Εργασίες αναβάθμισης εγκαταστάσεων (0)
          </li>
          <li>Επισκευή και συντήρηση εγκατάστασης επεξεργασίας ακάθαρτων υδάτων (0)
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για εγκαταστάσεις επεξεργασίας χημικών προϊόντων (10)
        <ul>
          <li>Κατασκευαστικές εργασίες για μονάδες βιοχημικής αποσύνθεσης οργανικών υλών (0)
          </li>
          <li>Κατασκευαστικές εργασίες για μονάδες αποσκλήρυνσης (0)
          </li>
          <li>Κατασκευαστικές εργασίες για εγκαταστάσεις φαρμακοβιομηχανίας (0)
          </li>
          <li>Κατασκευαστικές εργασίες για μονάδες απιονισμού (0)
          </li>
          <li>Κατασκευαστικές εργασίες για μονάδες λιπασματοποίησης (0)
          </li>
          <li>Κατασκευαστικές εργασίες για πετροχημικές εγκαταστάσεις (0)
          </li>
          <li>Κατασκευαστικές εργασίες για εγκαταστάσεις απόσταξης ή επαναδιύλισης (2)
          <ul>
            <li>Κατασκευαστικές εργασίες για εγκαταστάσεις απόσταξης νερού (0)
            </li>
            <li>Κατασκευαστικές εργασίες για εγκαταστάσεις απόσταξης οινοπνεύματος (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για μονάδες αποθείωσης (0)
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για τον εξορυκτικό και μεταποιητικό τομέα (3)
        <ul>
          <li>Κατασκευαστικές εργασίες για μεταποιητικές εγκαταστάσεις (0)
          </li>
          <li>Κατασκευαστικές εργασίες για ορυχεία (1)
          <ul>
            <li>Κατασκευαστικές εργασίες για προθαλάμους ορυχείου (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εργασίες μηχανικού και δομικές εργασίες (56)
      <ul>
        <li>Κατασκευαστικές εργασίες για γέφυρες και σήραγγες, φρέατα και υπόγειες διαβάσεις (30)
        <ul>
          <li>Κατασκευαστικές εργασίες για σήραγγες, φρέατα και υπόγειες διαβάσεις (16)
          <ul>
            <li>Κατασκευαστικές εργασίες για σήραγγες (8)
            <ul>
              <li>Κατασκευαστικές εργασίες για επενδύσεις σήραγγας (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σήραγγες καναλιού (0)
              </li>
              <li>Εργασίες διάνοιξης σήραγγας (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σιδηροδρομικές σήραγγες (0)
              </li>
              <li>Κατασκευαστικές εργασίες για υποθαλάσσιες σήραγγες (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σήραγγες διάβασης πεζών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σήραγγες κάτω από τον ποταμό (0)
              </li>
              <li>Κατασκευαστικές εργασίες για οδικές σήραγγες (0)
              </li>
            </ul>
            </li>
            <li>Υπόγεια έργα εκτός από σήραγγες, φρέατα και υπόγειες διαβάσεις (0)
            </li>
            <li>Φρέατα (0)
            </li>
            <li>Οχετός (0)
            </li>
            <li>Καλυμμένες ή εν μέρει καλυμμένες εκσκαφές (3)
            <ul>
              <li>Υπόγεια διάβαση πεζών (0)
              </li>
              <li>Καλυμμένες ή εν μέρει καλυμμένες οδικές εκσκαφές (0)
              </li>
              <li>Καλυμμένες ή εν μέρει καλυμμένες σιδηροδρομικές εκσκαφές (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες σε γέφυρες (12)
          <ul>
            <li>Κατασκευαστικές εργασίες για γέφυρες (8)
            <ul>
              <li>Κατασκευαστικές εργασίες για γέφυρες αγωγών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για γέφυρες διάβασης πεζών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για χαλύβδινες γέφυρες (0)
              </li>
              <li>Κατασκευαστικές εργασίες για οδικές γέφυρες (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σιδηρές γέφυρες (0)
              </li>
              <li>Κατασκευαστικές εργασίες για πλάστιγγες ζύγισης οχημάτων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για ανακαίνιση γεφυρών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σιδηροδρομικές γέφυρες (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κοιλαδογέφυρες (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για επίστυλες οδογέφυρες (0)
              </li>
              <li>Κατασκευαστικές εργασίες για επίστυλες σιδηροδομικές γέφυρες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για δομικές κατασκευές (19)
        <ul>
          <li>Συναρμολόγηση και ανέγερση κατασκευών από προκατασκευασμένα στοιχεία (4)
          <ul>
            <li>Κατασκευές από προκατασκευασμένα στοιχεία (0)
            </li>
            <li>Προκατασκευασμένες μονάδες και στοιχεία (2)
            <ul>
              <li>Προκατασκευασμένες μονάδες (0)
              </li>
              <li>Προκατασκευασμένα στοιχεία (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Εργασίες δομικού σκελετού (2)
          <ul>
            <li>Έργα κατασκευής δομικού σκελετού (0)
            </li>
            <li>Χαλύβδινες δομικές κατασκευές (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για σπίτια σκύλων (0)
          </li>
          <li>Κατασκευαστικές εργασίες για χώρους εξυπηρέτησης (2)
          <ul>
            <li>Κατασκευαστικές εργασίες για πρατήρια βενζίνης/αερίου (0)
            </li>
            <li>Κατασκευαστικές εργασίες για σταθμούς εξυπηρέτησης σε αυτοκινητόδρομο (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για σταθμούς ραντάρ (0)
          </li>
          <li>Συναρμολόγηση μεταλλικών δομικών κατασκευών (1)
          <ul>
            <li>Τοποθέτηση μεταλλικών δομικών κατασκευών (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για χώρους στάθμευσης (2)
          <ul>
            <li>Κατασκευαστικές εργασίες για υπόγειους χώρους στάθμευσης (0)
            </li>
            <li>Κατασκευαστικές εργασίες για χώρους P+R (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευές από οπλισμένο σκυρόδεμα (0)
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για τεχνικά έργα εκτός από γέφυρες και σήραγγες, φρέατα και υπόγειες διαβάσεις (4)
        <ul>
          <li>Τεχνικά έργα για στρατιωτικές εγκαταστάσεις (0)
          </li>
          <li>Τεχνικά έργα για εγκαταστάσεις ασφαλείας (0)
          </li>
          <li>Κατασκευαστικές εργασίες για μονάδες επεξεργασίας αποβλήτων (1)
          <ul>
            <li>Κατασκευαστικές εργασίες για χώρους απόθεσης απορριμμάτων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Κατασκευαστικές εργασίες για αγωγούς μεγάλων αποστάσεων, καλώδια επικοινωνιών και μεταφοράς ενέργειας, αυτοκινητόδρομους, οδούς, αεροδρόμια και σιδηρόδρομους· εργασίες διάστρωσης (172)
      <ul>
        <li>Εργασίες διάστρωσης (14)
        <ul>
          <li>Εργασίες διάστρωσης επιφανειών διαφόρων εγκαταστάσεων άθλησης (6)
          <ul>
            <li>Εργασίες διάστρωσης επιφανειών για γήπεδα αθλητισμού (5)
            <ul>
              <li>Εργασίες επισκευής γηπέδων αθλητισμού (0)
              </li>
              <li>Εργασίες διάστρωσης γηπέδου γκολφ (0)
              </li>
              <li>Εργασίες διάστρωσης γηπέδου τένις (0)
              </li>
              <li>Εργασίες διάστρωσης ιπποδρόμου (0)
              </li>
              <li>Εργασίες διάστρωσης διαδρομών δρομέων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Εργασίες διάστρωσης εγκαταστάσεων αναψυχής (5)
          <ul>
            <li>Εργασίες επισκευής χώρων ψυχαγωγίας (0)
            </li>
            <li>Εργασίες διάστρωσης για πάρκα (0)
            </li>
            <li>Εργασίες διάστρωσης παιδικής χαράς (0)
            </li>
            <li>Εργασίες διάστρωσης ζωολογικού κήπου (0)
            </li>
            <li>Εργασίες διάστρωσης κήπων (0)
            </li>
          </ul>
          </li>
          <li>Χωματουργικές εργασίες νεκροταφείων (0)
          </li>
        </ul>
        </li>
        <li>Επικουρικά έργα για αγωγούς μεγάλων αποστάσων και καλωδιώσεις (42)
        <ul>
          <li>Επικουρικά έργα για αγωγούς ύδρευσης (11)
          <ul>
            <li>Εργασίες σχετιζόμενες με τους αγωγούς διανομής νερού (4)
            <ul>
              <li>Κατασκευαστικές εργασίες για ανακαίνιση κεντρικών αγωγών νερού (0)
              </li>
              <li>Κατασκευαστικές εργασίες για υπερυψωμένες δεξαμενές πόσιμου νερού (0)
              </li>
              <li>Κατασκευαστικές εργασίες για αντλιοστάσια (0)
              </li>
              <li>Κατασκευαστικές εργασίες για υδατόπυργους (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες κεντρικών αγωγών τηλεθέρμανσης (2)
            <ul>
              <li>Εργασίες σε εγκαταστάσεις θέρμανσης (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σταθμούς μετάδοσης θερμότητας (0)
              </li>
            </ul>
            </li>
            <li>Αρδευτικά έργα (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για σωληνώσεις διανομής νερού άρδευσης (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για σωληνώσεις ομβρίων υδάτων (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες και επικουρικά έργα για γραμμές τηλεφώνου και επικοινωνιών (7)
          <ul>
            <li>Κατασκευαστικές εργασίες για τηλεφωνικές γραμμές (1)
            <ul>
              <li>Τηλέφωνα έκτακτης ανάγκης κατά μήκος του οδικού δικτύου (0)
              </li>
            </ul>
            </li>
            <li>Γραμμές καλωδιακής ραδιοφωνικής εκπομπής (0)
            </li>
            <li>Τοποθέτηση κεραιών (2)
            <ul>
              <li>Εργασίες σχετιζόμενες με εγκαταστάσεις τηλεπικοινωνιών (0)
              </li>
              <li>Εργασίες σχετιζόμενες με εγκαταστάσεις ραδιοφωνικής μετάδοσης (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για σταθμούς βάσης κινητής τηλεφωνίας (0)
            </li>
          </ul>
          </li>
          <li>Επικουρικά έργα για γραμμές ηλεκτρικής ενέργειας (3)
          <ul>
            <li>Κατασκευαστικές εργασίες για υποσταθμούς (1)
            <ul>
              <li>Υποσταθμός μετασχηματιστών (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευή εναέριας γραμμής (0)
            </li>
          </ul>
          </li>
          <li>Έργα κατασκευής αποχετεύσεων (17)
          <ul>
            <li>Έργα σε υπονόμους (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για σωληνώσεις ακάθαρτων υδάτων (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για αγωγούς αποχετεύσεων (0)
            </li>
            <li>Εργασίες σε εγκαταστάσεις ειδών υγιεινής (0)
            </li>
            <li>Εργασίες κατασκευής δικτύου αποστράγγισης (4)
            <ul>
              <li>Κατασκευαστικές εργασίες για λεκάνες συλλογής ομβρίων υδάτων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για αποχετεύσεις (0)
              </li>
              <li>Εργασίες αποστράγγισης (0)
              </li>
              <li>Εργασίες σε δίκτυο αποστράγγισης και έργα επιφανείας (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες αποχετευτικών δικτύων (4)
            <ul>
              <li>Κατασκευαστικές εργασίες για αντλιοστάσια νερών υπονόμων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για εκβολείς υπονόμου (0)
              </li>
              <li>Εργασίες επεξεργασίας λυμάτων (0)
              </li>
              <li>Εργασίες επεξεργασίας ιλύος (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες επεξεργασίας του νερού (1)
            <ul>
              <li>Αντλιοστάσιο λυμάτων (0)
              </li>
            </ul>
            </li>
            <li>Σταθμός διαμετακόμισης αποβλήτων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για αγωγούς μεγάλων αποστάσεων, καλώδια επικοινωνιών και μεταφοράς ενέργειας (16)
        <ul>
          <li>Κατασκευαστικές εργασίες για γραμμές επικοινωνιών (0)
          </li>
          <li>Κατασκευαστικές εργασίες για γραμμές ηλεκτροδότησης (0)
          </li>
          <li>Εργασίες σε αγωγούς πεπιεσμένου αέρα (1)
          <ul>
            <li>Εργασίες σε αγωγούς πεπιεσμένου αέρα για παράδοση αλληλογραφίας (0)
            </li>
          </ul>
          </li>
          <li>Γενικές κατασκευαστικές εργασίες για αγωγούς μεγάλων αποστάσεων (4)
          <ul>
            <li>Κατασκευαστικές εργασίες για τοποθέτηση αγωγών (3)
            <ul>
              <li>Εξαγωγή και επανατοποθέτηση αγωγού (0)
              </li>
              <li>Εγκατάσταση συστήματος αγωγών (0)
              </li>
              <li>Εργασίες επανατοποθέτησης αγωγού μεγάλης απόστασης (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για αγωγούς ύδρευσης και αποχέτευσης (0)
          </li>
          <li>Κατασκευαστικές εργασίες αγωγών μεγάλων αποστάσεων για τη μεταφορά πετρελαίου και αερίου (5)
          <ul>
            <li>Κατασκευαστικές εργασίες για πετρελαιαγωγούς (0)
            </li>
            <li>Κατασκευαστικές εργασίες για αγωγούς μεταφοράς αερίου (3)
            <ul>
              <li>Εργασίες σε αεριοφυλάκια (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κεντρικούς αγωγούς παροχής αερίου (0)
              </li>
              <li>Εργασίες σχετιζόμενες με τη διανομή αερίου (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για σκηνές χώρων εκδηλώσεων (0)
        </li>
        <li>Κατασκευαστικά έργα, έργα θεμελίωσης και επιφανειακά έργα σε αυτοκινητοδρόμους και οδούς (54)
        <ul>
          <li>Κατασκευή αυτοκινητοδρόμων και οδών (22)
          <ul>
            <li>Έργα οδοποιίας (9)
            <ul>
              <li>Κατασκευαστικές εργασίες για ανισόπεδους κόμβους (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κύριες οδικές αρτηρίες (0)
              </li>
              <li>Κατασκευαστικές εργασίες για οδικούς κόμβους (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κυκλικούς κόμβους (0)
              </li>
              <li>Κατασκευαστικές εργασίες για περιφερειακές οδούς (0)
              </li>
              <li>Κατασκευαστικά έργα σε κύριες οδούς (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σταυροδρόμια (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κόμβους τύπου Τ (0)
              </li>
              <li>Κατασκευαστικές εργασίες για δευτερεύουσες οδούς (0)
              </li>
            </ul>
            </li>
            <li>Έργα αποσυμφόρησης της κυκλοφορίας (0)
            </li>
            <li>Κατασκευαστικές εργασίες για αυτοκινητόδρομους (0)
            </li>
            <li>Δίοδοι προσπέλασης και λοιπές μη ασφαλτοστρωμένες επιφάνειες (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για οδούς για ποδήλατα (0)
              </li>
              <li>Κατασκευαστικές εργασίες για διόδους προσπέλασης (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κεντρικές οδικές αρτηρίες (2)
            <ul>
              <li>Συντήρηση κεντρικών οδικών αρτηριών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για υπερυψωμένους αυτοκινητόδρομους (0)
              </li>
            </ul>
            </li>
            <li>Οδικά έργα (3)
            <ul>
              <li>Εργασίες συντήρησης οδών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για άνω διαβάσεις (0)
              </li>
              <li>Εργασίες επισκευής οδών (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Διάφορες εργασίες επικάλυψης του οδοστρώματος (25)
          <ul>
            <li>Τοποθέτηση φραγμάτων ασφαλείας οδών (0)
            </li>
            <li>Επιφανειακές εργασίες του οδοστρώματος κεντρικών αρτηριών (0)
            </li>
            <li>Επιφανειακές εργασίες οδοστρώματος οδών (9)
            <ul>
              <li>Κατασκευαστικές εργασίες για έργα οδοστρωσίας και ασφαλτόστρωσης (0)
              </li>
              <li>Κατασκευαστικές εργασίες για επίχριση επιφάνειας οδού (0)
              </li>
              <li>Επαναδιάστρωση οδοστρώματος (0)
              </li>
              <li>Κατασκευαστικές εργασίες για οδούς διαχωρισμένου οδοστρώματος με μια λωρίδα κυκλοφορίας (0)
              </li>
              <li>Κατασκευαστικές εργασίες για εξόδους αυτοκινητοδρόμου (0)
              </li>
              <li>Κατασκευαστικές εργασίες για συνδετήριες οδούς (0)
              </li>
              <li>Συντήρησης ερεισμάτων (0)
              </li>
              <li>Εργασίες διαγράμμισης οδικών επιφανειών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για οδούς διαχωρισμένου οδοστρώματος με δύο λωρίδες κυκλοφορίας (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες επίστρωσης εκτός από οδοστρώσεις (3)
            <ul>
              <li>Επιφανειακές εργασίες οδοστρώματος δρόμων (0)
              </li>
              <li>Εργασίες επαναδιάστρωσης (0)
              </li>
              <li>Επιφανειακές εργασίες πεζοδρόμων (0)
              </li>
            </ul>
            </li>
            <li>Διαγράμμιση χώρου στάθμευσης (0)
            </li>
            <li>Εγκατάσταση οδικών πινακίδων (4)
            <ul>
              <li>Εγκατάσταση οδικής επίπλωσης (0)
              </li>
              <li>Εγκατάσταση μικρών οδικών στύλων (0)
              </li>
              <li>Εγκατάσταση οδικών σημάτων (0)
              </li>
              <li>Εγκατάσταση εξοπλισμού ασφαλείας (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για πεζοδρόμους (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για άνω διάβαση πεζών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για ζώνη πεζοδρόμου (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Εργασίες θεμελιώσεων για αυτοκινητόδρομους, οδούς, δρόμους και πεζοδρόμους (4)
          <ul>
            <li>Εργασίες θεμελιώσεων για πεζόδρομους (0)
            </li>
            <li>Εργασίες θεμελιώσεων για οδούς (0)
            </li>
            <li>Εργασίες θεμελιώσεων για δρόμους (0)
            </li>
            <li>Εργασίες θεμελιώσεων για αυτοκινητόδρομους (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για σιδηρόδρομους και συστήματα καλωδιακής έλξης (30)
        <ul>
          <li>Συστήματα μεταφοράς υποστηριζόμενα από καλώδια (5)
          <ul>
            <li>Κατασκευαστικές εργασίες για ανελκυστήρες χιονοδρόμων (0)
            </li>
            <li>Οδοντωτός σιδηρόδρομος (0)
            </li>
            <li>Κατασκευαστικές εργασίες για τελεφερίκ (0)
            </li>
            <li>Συστήματα μεταφοράς με καμπίνες υποστηριζόμενα από καλώδια (0)
            </li>
            <li>Κατασκευαστικές εργασίες για τελεσιέζ (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για σιδηρόδρομους (23)
          <ul>
            <li>Κατασκευαστικές εργασίες για υποσταθμούς σιδηροδρομικών μηχανών (0)
            </li>
            <li>Εργασίες σε υπεραστικούς σιδηρόδρομους (6)
            <ul>
              <li>Κατασκευαστικές εργασίες για επιχωματώσεις σιδηροδρομικών γραμμών (0)
              </li>
              <li>Αποξήλωση σιδηροδρομικών γραμμών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για χώρους στάθμευσης τροχαίου υλικού (0)
              </li>
              <li>Εργασίες σηματοδότησης σιδηροδρομικών γραμμών (0)
              </li>
              <li>Εργασίες διάστρωσης σιδηροτροχιών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για αστικούς σιδηροδρόμους (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες σε αστικό σιδηρόδρομο (9)
            <ul>
              <li>Κατασκευαστικές εργασίες για χώρους στάθμευσης τραμ (0)
              </li>
              <li>Σταθμός υπόγειου σιδηρόδρομου (0)
              </li>
              <li>Εργασίες διάστρωσης σιδηροτροχιών σε αστικό σιδηρόδρομο (0)
              </li>
              <li>Εργασίες σε τροχιόδρομο (0)
              </li>
              <li>Κατασκευή γραμμής τραμ (0)
              </li>
              <li>Μεταφορά επιβατών με υπόγειο σιδηρόδρομο (0)
              </li>
              <li>Εργασίες σε μερικώς υπόγειο σιδηρόδρομο (0)
              </li>
              <li>Έργα σε υπόγειο σιδηρόδρομο (0)
              </li>
              <li>Κατασκευαστικές εργασίες για εξέδρες γραμμής τραμ (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για ισόπεδες διαβάσεις (0)
            </li>
            <li>Κατασκευαστικές εργασίες για μηχανοστάσια (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για συνεργεία διαχωρισμού σιδηροτροχιών (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για γραμμές επαφής με αιώρηση αλυσοειδούς (κατενέρ) (0)
            </li>
            <li>Κατασκευαστικές εργασίες με έρμα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Γενικές κατασκευαστικές εργασίες για αεροδρόμια, διαδρόμους προσγείωσης-απογείωσης και επιφάνειες ελιγμών (9)
        <ul>
          <li>Εργασίες κατακευής διαδρόμου προσγείωσης-απογείωσης (1)
          <ul>
            <li>Επανεπίστρωση διαδρόμου προσγείωσης-απογείωσης (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες χώρων ελιγμού αεροσκαφών (3)
          <ul>
            <li>Κατασκευαστικές εργασίες για διαδρόμους τροχοδρόμησης αεροσκαφών (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για επίστρωση διαδρόμων τροχοδρόμησης αεροσκαφών (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για χώρους στάθμευσης και φόρτωσης αεροσκαφών (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για αερολιμένες (2)
          <ul>
            <li>Κατασκευαστικές εργασίες για αεροδρόμια (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για επίστρωση επιφάνειας αεροδρομίου (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Κατασκευαστικές εργασίες για υδραυλικά έργα (53)
      <ul>
        <li>Κατασκευαστικές εργασίες υδραυλικών-μηχανολογικών κατασκευών (5)
        <ul>
          <li>Κατασκευαστικές εργασίες για πλωτές αποβάθρες (0)
          </li>
          <li>Κατασκευαστικές εργασίες για θυροφράγματα διώρυγας (0)
          </li>
          <li>Κατασκευαστικές εργασίες για δεξαμενές επισκευής πλοίων (0)
          </li>
          <li>Κατασκευαστικές εργασίες για κινητά φράγματα (0)
          </li>
          <li>Κατασκευαστικές εργασίες για εξέδρες αποβίβασης (0)
          </li>
        </ul>
        </li>
        <li>Κατασκευή μαρίνας (2)
        <ul>
          <li>Εγκαταστάσεις μαρίνας (0)
          </li>
          <li>Προβλήτες (0)
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για παραθαλάσσιες εγκαταστάσεις αναψυχής (4)
        <ul>
          <li>Κατασκευαστικές εργασίες για μαρίνες (1)
          <ul>
            <li>Κατασκευαστικές εργασίες για λιμένες σκαφών αναψυχής (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες εγκαταστάσεων θαλάσσιων σπορ (1)
          <ul>
            <li>Κατασκευαστικές εργασίες εγκατάστασης καθέλκυσης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εργασίες βυθοκόρισης και άντλησης για εγκαταστάσεις μονάδων επεξεργασίας ύδατος (0)
        </li>
        <li>Κατασκευαστικές εργασίες φραγμάτων, διωρύγων, αρδευτικών καναλιών και υδραγωγείων (14)
        <ul>
          <li>Κατασκευαστικές εργασίες υδάτινων οδών (5)
          <ul>
            <li>Υδάτινες οδοί εκτός από διώρυγες (0)
            </li>
            <li>Κατασκευαστικές εργασίες για υδραγωγεία (0)
            </li>
            <li>Κατασκευή διώρυγας (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για αρδευτικά κανάλια (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κανάλια αποστράγγισης (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες φραγμάτων και παρόμοιων μόνιμων κατασκευών (7)
          <ul>
            <li>Κατασκευαστικές εργασίες για μικρά ποτάμια φράγματα (0)
            </li>
            <li>Κατασκευαστικές εργασίες για αντιπλημμυρικά αναχώματα (0)
            </li>
            <li>Κατασκευαστικές εργασίες για στατικά φράγματα (0)
            </li>
            <li>Κατασκευαστικές εργασίες δεξαμενής (0)
            </li>
            <li>Κατασκευαστικές εργασίες για φράγματα (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για τοίχους φραγμάτων (0)
              </li>
              <li>Εργασίες ενίσχυσης φράγματος (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Παράκτια αμυντικά έργα (8)
        <ul>
          <li>Κατασκευαστικές εργασίες για κυματοθραύστες (0)
          </li>
          <li>Κατασκευαστικές εργασίες για θαλάσσια αμυντικά έργα (1)
          <ul>
            <li>Εργασίες επιχωμάτωσης (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για κρηπιδότοιχους (0)
          </li>
          <li>Εργασίες προστασίας γκρεμού (1)
          <ul>
            <li>Εργασίες σταθεροποίησης γκρεμού (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες σταθεροποίησης ακτής (0)
          </li>
          <li>Κατασκευαστικές εργασίες για λιμενοβραχίονες (0)
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για λιμάνια (6)
        <ul>
          <li>Εγκατάσταση εξοπλισμού φωτισμού λιμένα (0)
          </li>
          <li>Κατασκευαστικές εργασίες για αποβάθρες (0)
          </li>
          <li>Κατασκευαστικές εργασίες για προκυμαίες (0)
          </li>
          <li>Κατασκευαστικές εργασίες για προβλήτες (0)
          </li>
          <li>Κατασκευαστικές εργασίες για προκυμαίες πλεύρισης σκαφών (0)
          </li>
          <li>Επιτόπιες κατασκευαστικές εργασίες τερματικού σταθμού ανοικτής θαλάσσης (0)
          </li>
        </ul>
        </li>
        <li>Έργα ρύθμισης ροής ποταμού και αντιπλημμυρικά έργα (6)
        <ul>
          <li>Κατασκευαστικές εργασίες για χώρους περιπάτου (1)
          <ul>
            <li>Κατασκευαστικές εργασίες για χώρους περιπάτου στην προκυμαία (0)
            </li>
          </ul>
          </li>
          <li>Έργα παρόχθιας προστασίας (0)
          </li>
          <li>Κατασκευή παρόχθιου τοίχου (0)
          </li>
          <li>Αντιπλημμυρικά έργα (1)
          <ul>
            <li>Συντήρηση αντιπλημμυρικών έργων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εργασίες κατασκευής κτιρίων (186)
      <ul>
        <li>Κατασκευαστικές εργασίες για φουσκωτά κτίρια (0)
        </li>
        <li>Κατασκευαστικές εργασίες για κτίρια υπηρεσιών υγείας ή κοινωνικών υπηρεσιών, κρεματόριων και δημόσιων αποχωρητηρίων (25)
        <ul>
          <li>Κατασκευαστικές εργασίες για κτίρια υπηρεσιών υγείας (12)
          <ul>
            <li>Κατασκευαστικές εργασίες για εγκαταστάσεις νοσοκομείου (8)
            <ul>
              <li>Κατασκευαστικές εργασίες για θαλάμους ακτινοσκόπησης (0)
              </li>
              <li>Κατασκευαστικές εργασίες για θαλάμους παθολογικής εξέτασης (0)
              </li>
              <li>Κατασκευαστικές εργασίες για θαλάμους καθετηριασμού (0)
              </li>
              <li>Κατασκευαστικές εργασίες για χειρουργεία (0)
              </li>
              <li>Κατασκευαστικές εργασίες για θαλάμους διάγνωσης (0)
              </li>
              <li>Κατασκευαστικές εργασίες για θαλάμους μη διαγνωστικής εξέτασης (0)
              </li>
              <li>Κατασκευαστικές εργασίες για μονάδες εντατικής παρακολούθησης (0)
              </li>
              <li>Κατασκευαστικές εργασίες για νεκροτομεία (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κλινικές (0)
            </li>
            <li>Κατασκευαστικές εργασίες για ειδικά ιατρεία (0)
            </li>
            <li>Κατασκευαστικές εργασίες για ιαματικά λουτρά (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για κρεματόρια (0)
          </li>
          <li>Νεκροταφείο (0)
          </li>
          <li>Κατασκευαστικές εργασίες για κτίρια κοινωνικών υπηρεσιών (8)
          <ul>
            <li>Κατασκευαστικές εργασίες για κτίρια κοινωνικής πρόνοιας εκτός από γηροκομεία (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για κέντρα ημερήσιας φροντίδας (0)
              </li>
              <li>Κατασκευαστικές εργασίες για δημοτικά κέντρα (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για γηροκομεία κοινωνικής πρόνοιας (4)
            <ul>
              <li>Κατασκευαστικές εργασίες για οίκους ευγηρίας (0)
              </li>
              <li>Κατασκευαστικές εργασίες για γηροκομεία στα οποία παρέχεται και περίθαλψη (0)
              </li>
              <li>Κατασκευαστικές εργασίες για οικοτροφεία παιδιών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κοινωνικές κατοικίες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Δημόσια αφοδευτήρια (0)
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για κτίρια υπηρεσιών δημόσιας τάξης ή έκτακτης ανάγκης και στρατιωτικά κτίρια (20)
        <ul>
          <li>Κατασκευαστικές εργασίες για κτίρια υπηρεσιών δημόσιας τάξης ή έκτακτης ανάγκης (15)
          <ul>
            <li>Κατασκευαστικές εργασίες για κτίρια υπηρεσιών δημόσιας τάξης (4)
            <ul>
              <li>Κατασκευαστικές εργασίες για κτίρια δικαστηρίου (0)
              </li>
              <li>Κοινοβουλευτικά κτίρια και κτίρια δημοσίων συνελεύσεων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για αστυνομικά τμήματα (0)
              </li>
              <li>Κατασκευαστικές εργασίες για φυλακές (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κτίρια έκτακτης ανάγκης (9)
            <ul>
              <li>Κατασκευαστικές εργασίες για κτίρια ακτοφυλακής (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σταθμούς σωσίβιων λέμβων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για πυροσβεστικούς σταθμούς (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κτίρια υπηρεσιών έκτακτης ανάγκης (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σταθμούς υπηρεσιών διάσωσης (0)
              </li>
              <li>Κατασκευαστικές εργασίες για φάρους (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σταθμούς ασθενοφόρων (0)
              </li>
              <li>Καταφύγια προστασίας (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κτίρια διάσωσης πυροσβεστών (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για στρατιωτικά κτίρια και εγκαταστάσεις (3)
          <ul>
            <li>Κατασκευαστικές εργασίες για οχυρά (0)
            </li>
            <li>Κατασκευαστικές εργασίες για στρατιωτικά καταφύγια (0)
            </li>
            <li>Κατασκευαστικές εργασίες για χαρακώματα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για κτίρια αναψυχής, αθλητισμού, πολιτιστικών εκδηλώσεων, στέγασης και εστιατορίων (55)
        <ul>
          <li>Κτίρια παροχής καταλύματος και εστίασης (8)
          <ul>
            <li>Κατασκευαστικές εργασίες για κτίρια εστιατορίων και παρόμοιων διευκολύνσεων (3)
            <ul>
              <li>Κατασκευαστικές εργασίες για καφετέριες (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κυλικεία (0)
              </li>
              <li>Κατασκευαστικές εργασίες για εστιατόρια (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κτίρια στέγασης (3)
            <ul>
              <li>Κατασκευαστικές εργασίες για ξενώνες (0)
              </li>
              <li>Κατασκευαστικές εργασίες για καταλύματα μικρής διάρκειας παραμονής (0)
              </li>
              <li>Κατασκευαστικές εργασίες για ξενοδοχεία (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Μετατροπή κουζινών ή εστιατορίων (0)
          </li>
          <li>Κατασκευαστικές εργασίες για αθλητικές εγκαταστάσεις (12)
          <ul>
            <li>Εγκατάσταση αποδυτηρίων (0)
            </li>
            <li>Κατασκευαστικές εργασίες για αθλητικές εγκαταστάσεις πολλαπλών αθλημάτων (5)
            <ul>
              <li>Κατασκευαστικές εργασίες κατασκευών για γήπεδο αθλοπαιδιών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για εγκαταστάσεις χειμερινών σπορ (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κλειστή αθλητική εγκατάσταση (0)
              </li>
              <li>Κατασκευαστικές εργασίες για γυμναστήρια (0)
              </li>
              <li>Κατασκευαστικές εργασίες για στάδια (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για συγκεκριμένα αθλήματα (3)
            <ul>
              <li>Κατασκευαστικές εργασίες για παγοδρόμια (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κολυμβητήριο (0)
              </li>
              <li>Εργασίες σήμανσης αθλητικών εγκαταστάσεων (0)
              </li>
            </ul>
            </li>
            <li>Επισκευή και συντήρηση αθλητικών εγκαταστάσεων (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για περίπτερα (0)
          </li>
          <li>Κατασκευή εγκαταστάσεων χώρων ψυχαγωγίας (11)
          <ul>
            <li>Έργα ηλιοπροστασίας (0)
            </li>
            <li>Κατασκευαστικές εργασίες για κέντρα αναψυχής (0)
            </li>
            <li>Κατασκευαστικές εργασίες για πάρκα ψυχαγωγίας (0)
            </li>
            <li>Κατασκευαστικές εργασίες για κινηματογράφους (0)
            </li>
            <li>Κατασκευαστικές εργασίες για κτίρια ψυχαγωγίας (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για κέντρα ψυχαγωγίας (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κέντρα αναψυχής (0)
              </li>
            </ul>
            </li>
            <li>Εγκατάσταση αναψυχής (0)
            </li>
            <li>Κατασκευαστικές εργασίες για καζίνο (0)
            </li>
            <li>Κατασκευαστικές εργασίες για γραφεία έκδοσης εισιτηρίων (0)
            </li>
            <li>Κατασκευαστικές εργασίες για πάρκα ψυχαγωγικών εκδηλώσεων (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για καλλιτεχνικά και πολιτιστικά κτίρια (18)
          <ul>
            <li>Κατασκευαστικές εργασίες για κτίρια καλλιτεχνικών εκδηλώσεων (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για θέατρα (0)
              </li>
              <li>Κατασκευαστικές εργασίες για αίθουσες θεάτρου (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κτίρια εκθέσεων (4)
            <ul>
              <li>Κατασκευαστικές εργασίες για γκαλερί (0)
              </li>
              <li>Κατασκευαστικές εργασίες για μουσεία (0)
              </li>
              <li>Κατασκευαστικές εργασίες για ιστορικά μνημεία ή μνημεία (0)
              </li>
              <li>Κατασκευαστικές εργασίες για εκθεσιακά κέντρα (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για αίθουσες διαλέξεων (0)
            </li>
            <li>Κατασκευαστικές εργασίες για θρησκευτικά κτίρια (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για εκκλησίες (0)
              </li>
            </ul>
            </li>
            <li>Κτίρια με ιδιαίτερο ιστορικό ή αρχιτεκτονικό ενδιαφέρον (4)
            <ul>
              <li>Κατασκευαστικές εργασίες για πύργους (0)
              </li>
              <li>Κατασκευαστικές εργασίες για βιομηχανικά μνημεία (0)
              </li>
              <li>Κατασκευαστικές εργασίες για προϊοστορικά μνημεία (0)
              </li>
              <li>Κατασκευαστικές εργασίες για ανάκτορα (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για βιβλιοθήκες (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για βιβλιοθήκες πολυμέσων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για κτίρια εκπαίδευσης ή έρευνας (22)
        <ul>
          <li>Κατασκευαστικές εργασίες για κτίρια συνεχιζόμενης εκπαίδευσης (0)
          </li>
          <li>Κατασκευαστικές εργασίες για κτίρια έρευνας (5)
          <ul>
            <li>Κατασκευαστικές εργασίες για εγκαταστάσεις έρευνας και δοκιμών (0)
            </li>
            <li>Επιστημονικές εγκαταστάσεις (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για αποστειρωμένους χώρους (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κτίρια εργαστηρίου (0)
            </li>
            <li>Κατασκευαστικές εργασίες για μετεωρολογικούς σταθμούς (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για κτίρια πανεπιστημίου (3)
          <ul>
            <li>Κατασκευαστικές εργασίες για πολυτεχνεία (0)
            </li>
            <li>Κατασκευαστικές εργασίες για εργαστήρια εκμάθησης γλωσσών (0)
            </li>
            <li>Κατασκευαστικές εργασίες για αμφιθεατρικές αίθουσες διδασκαλίας (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για κτίρια μέσης εκπαίδευσης (2)
          <ul>
            <li>Κατασκευαστικές εργασίες για επαγγελματικές σχολές (0)
            </li>
            <li>Κατασκευαστικές εργασίες για τεχνικές σχολές (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για φοιτητικές εστίες (1)
          <ul>
            <li>Κατασκευαστικές εργασίες για αίθουσες υποδοχής (0)
            </li>
          </ul>
          </li>
          <li>Κτίριο εκπαιδευτικών εγκαταστάσεων (0)
          </li>
          <li>Κατασκευαστικές εργασίες για παιδικούς σταθμούς (0)
          </li>
          <li>Κατασκευαστικές εργασίες για σχολικά κτίρια (3)
          <ul>
            <li>Κατασκευαστικές εργασίες για σχολεία πρωτοβάθμιας εκπαίδευσης (0)
            </li>
            <li>Κατασκευαστικές εργασίες για σχολεία δευτεροβάθμιας εκπαίδευσης (0)
            </li>
            <li>Κατασκευαστικές εργασίες για ειδικά σχολεία (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Γενικές κατασκευαστικές εργασίες για πολυκατοικίες και μονοκατοικίες (10)
        <ul>
          <li>Κατασκευαστικές εργασίες για καταλύματα υπερηλίκων με φύλακα (0)
          </li>
          <li>Κατασκευαστικές εργασίες για σπίτια (7)
          <ul>
            <li>Κατασκευαστικές εργασίες για πολυλειτουργικά κτίρια (0)
            </li>
            <li>Κατασκευαστικές εργασίες για σάουνες (0)
            </li>
            <li>Κατασκευαστικές εργασίες για λουτρά (0)
            </li>
            <li>Κατασκευαστικές εργασίες για βεράντες (0)
            </li>
            <li>Κατασκευαστικές εργασίες για πολυκατοικίες (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για διαμερίσματα (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για ανάπυξη αστικής περιοχής (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για κατοικίες (0)
          </li>
        </ul>
        </li>
        <li>Κατασκευαστικές εργασίες για εμπορικά κτίρια, αποθήκες και βιομηχανικά κτίρια, για κτίρια σχετιζόμενα με τις μεταφορές (47)
        <ul>
          <li>Κατασκευαστικές εργασίες για εμπορικά κτίρια (9)
          <ul>
            <li>Κατασκευαστικές εργασίες για ταχυδρομεία (0)
            </li>
            <li>Κατασκευαστικές εργασίες για αγορές (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για υπαίθριες αγορές (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κλειστές αγορές (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κτίρια καταστημάτων (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για εμπορικά κέντρα (0)
              </li>
              <li>Κατασκευαστικές εργασίες για καταστήματα (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για συγκροτήματα γραφείων (0)
            </li>
            <li>Κατασκευαστικές εργασίες για τράπεζες (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευαστικές εργασίες για αποθήκες και βιομηχανικά κτίρια (13)
          <ul>
            <li>Κατασκευαστικές εργασίες για γεωργικά κτίρια (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για σταύλους αγελάδων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για αχυρώνες (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για σταθμούς ανακύκλωσης (0)
            </li>
            <li>Κατασκευαστικές εργασίες βιομηχανικών κτιρίων (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για βιομηχανικές μονάδες (0)
              </li>
              <li>Κατασκευαστικές εργασίες για εργαστήρια (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για μεγάλες αποθήκες (1)
            <ul>
              <li>Κατασκευαστικές εργασίες για αποθήκες εμπορευμάτων (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για αποθήκες καταστημάτων (0)
            </li>
            <li>Κατασκευαστικές εργασίες για εγκαταστάσεις παραγωγής λιπάσματος μέσω αποσύνθεσης οργανικών υλών (0)
            </li>
            <li>Κατασκευαστικές εργασίες για σφαγεία (0)
            </li>
            <li>Εγκαταστάσεις ψυγείων αποθήκευσης (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες εγκατάστασης για αίθουσες προσωπικού (0)
          </li>
          <li>Κτίρια σχετιζόμενα με τις μεταφορές (21)
          <ul>
            <li>Κατασκευαστικές εργασίες για κτίρια σχετιζόμενα με τις σιδηροδρομικές μεταφορές (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για σιδηροδρομικούς σταθμούς (0)
              </li>
              <li>Κατασκευαστικές εργασίες για τερματικούς σιδηροδρομικούς σταθμούς (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κτίρια σχετιζόμενα με τις αερομεταφορές (3)
            <ul>
              <li>Κατασκευαστικές εργασίες για πύργους ελέγχου αεροδρομίου (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κτίρια αεροδρομίων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για έλεγχο εισιτηρίων σε αεροδρόμια (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κτίρια σχετιζόμενα με τις πλωτές μεταφορές (2)
            <ul>
              <li>Κατασκευαστικές εργασίες για τερματικούς σταθμούς πορθμείων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για τερματικούς σταθμούς σκαφών τύπου Ro-Ro (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κτίρια σχετιζόμενα με διάφορα μεταφορικά μέσα (3)
            <ul>
              <li>Υπηρεσίες εγκατάστασης για γέφυρες επιβίβασης επιβατών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για υπόστεγα συντήρησης αεροσκαφών (0)
              </li>
              <li>Κατασκευαστικές εργασίες για συνεργεία συντήρησης (0)
              </li>
            </ul>
            </li>
            <li>Κατασκευαστικές εργασίες για κτίρια σχετιζόμενα με τις οδικές μεταφορές (6)
            <ul>
              <li>Κατασκευαστικές εργασίες για στάσεις λεωφορείων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σταθμούς οδικής εξυπηρέτησης (0)
              </li>
              <li>Κατασκευαστικές εργασίες για σταθμούς λεωφορείων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για κτίρια στάθμευσης αυτοκινήτων (0)
              </li>
              <li>Κατασκευαστικές εργασίες για διαδρόμους (0)
              </li>
              <li>Κατασκευαστικές εργασίες για χώρους στάθμευσης λεωφορείων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Στεγαστικές εργασίες και άλλες κατασκευαστικές εργασίες ειδικής φύσης (71)
      <ul>
        <li>Κατασκευαστικές εργασίες ειδικής φύσης εκτός από εργασίες στέγης (49)
        <ul>
          <li>Εργασίες μπαλκονιών (0)
          </li>
          <li>Εργασίες επέκτασης κτιρίου (0)
          </li>
          <li>Εργασίες σκυροδέματος (9)
          <ul>
            <li>Εργασίες οπλισμένου σκυροδέματος (1)
            <ul>
              <li>Εργασίες καλουπώματος για την έγχυση σκυροδέματος (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες επισκευής κατασκευών σκυροδέματος (0)
            </li>
            <li>Εργασίες επίχρισης σκυροδέματος (0)
            </li>
            <li>Εργασίες με τσιμεντοκονίαμα (0)
            </li>
            <li>Εργασίες μη οπλισμένου σκυροδέματος (0)
            </li>
            <li>Εργασίες πλήρωσης με ρευστό κονίαμα (0)
            </li>
            <li>Εργασίες επικάλυψης δαπέδων (1)
            <ul>
              <li>Εργασίες ρύθμισης πάχους επίστρωσης δαπέδων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Εργασίες ανέγερσης χαλύβδινων δομικών μερών (8)
          <ul>
            <li>Εργασίες ανέγερσης χαλύβδινων δομικών μερών για κατασκευές (6)
            <ul>
              <li>Εργασίες κατασκευής τμημάτων κατασκευών ανοικτής θαλάσσης (0)
              </li>
              <li>Εργασίες υποβρύχιων γεωτρήσεων (0)
              </li>
              <li>Εργασίες κατασκευής περιβλήματος (0)
              </li>
              <li>Εργασίες κατασκευής πασσάλων (0)
              </li>
              <li>Εργασίες αγκυροβολίων ανοικτής θαλάσσης (0)
              </li>
              <li>Εργασίες κατασκευής καταστρωμάτων (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες ανέγερσης χαλύβδινων δομικών μερών για κτίρια (0)
            </li>
          </ul>
          </li>
          <li>Διάφορες κατασκευαστικές εργασίες ειδικής φύσης (9)
          <ul>
            <li>Κατασκευή κλιβάνων (0)
            </li>
            <li>Εργασίες αφαίρεσης αμιάντου (0)
            </li>
            <li>Εργασίες ανακαίνισης υποβαθμισμένων κτιρίων (0)
            </li>
            <li>Βιομηχανικές καπνοδόχοι (0)
            </li>
            <li>Εργασίες κατεργασίας μετάλλων (0)
            </li>
            <li>Έργα αναβάθμισης περιβάλλοντος (0)
            </li>
            <li>Φέροντες τοίχοι (0)
            </li>
            <li>Εργασίες επικάλυψης (0)
            </li>
            <li>Εργασίες συγκόλλησης (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες τοιχοποιίας και οπτοπλινθοδομών (6)
          <ul>
            <li>Λιθοκατασκευές (2)
            <ul>
              <li>Λάξευση λίθων (0)
              </li>
              <li>Κατασκευές με λαξευτούς λίθους (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες οπτοπλινθοδομής (2)
            <ul>
              <li>Οπτοπλινθοδομές πρόσοψης (0)
              </li>
              <li>Εργασίες τοιχοποιίας (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Εργασίες μετατροπής κτιρίου (1)
          <ul>
            <li>Εργασίες συντήρησης νωπογραφιών (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες θεμελιώσεων και διάνοιξης φρεάτων ύδατος (5)
          <ul>
            <li>Εργασίες θεμελιώσεων (3)
            <ul>
              <li>Εργασίες σταθεροποίησης ορυγμάτων (0)
              </li>
              <li>Πασσάλωση (0)
              </li>
              <li>Τεχνική διαφραγματικού τοίχου (0)
              </li>
            </ul>
            </li>
            <li>Διάνοιξη φρεάτων ύδατος (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες ικριωμάτων (2)
          <ul>
            <li>Εργασίες αποξήλωσης ικριωμάτων (0)
            </li>
            <li>Εργασίες ανέγερσης ικριωμάτων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Οικοδομικές και συναφείς εργασίες επικάλυψης στεγών και κατασκευής διαχωριστικών πλαισίων (20)
        <ul>
          <li>Εργασίες κατασκευής πλαισίων στεγών (0)
          </li>
          <li>Τοποθέτηση ελασμάτων (2)
          <ul>
            <li>Εργασίες υδατοστεγάνωσης (0)
            </li>
            <li>Εργασίες μόνωσης στέγης (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες επικάλυψης και βαφής στεγών (9)
          <ul>
            <li>Εργασίες βαφής και άλλες εργασίες επίχρισης στέγης (2)
            <ul>
              <li>Εργασίες βαφής στέγης (0)
              </li>
              <li>Εργασίες επίχρισης στέγης με τσιμέντο (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες κάλυψης στέγης (5)
            <ul>
              <li>Εργασίες επικάλυψης στέγης για ηλιακούς συλλέκτες (0)
              </li>
              <li>Επίστρωση στεγών με πλακίδια (0)
              </li>
              <li>Εργασίες επικάλυψης στέγης με πίσσα (0)
              </li>
              <li>Επίστρωση στεγών με σχιστολιθικά πλακίδια (0)
              </li>
              <li>Εργασίες μεταλλικής κάλυψης οροφής (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Εργασίες επισκευής και συντήρησης στέγης (2)
          <ul>
            <li>Εργασίες συντήρησης στέγης (0)
            </li>
            <li>Εργασίες επισκευής στέγης (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες τοποθέτησης στεγανωτικών ελασμάτων και υδρορροών (2)
          <ul>
            <li>Εργασίες τοποθέτησης στεγανωτικών ελασμάτων (0)
            </li>
            <li>Εργασίες τοποθέτησης υδρορροών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ενοικίαση κατασκευαστικών μηχανημάτων και εξοπλισμού για έργα πολιτικού μηχανικού με χειριστή (2)
    <ul>
      <li>Ενοικίαση γερανών με χειριστή (0)
      </li>
      <li>Ενοικίαση εξοπλισμού χωματουργικών εργασιών με χειριστή (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Εργασίες προπαρασκευής εργοταξίων (54)
    <ul>
      <li>Δοκιμαστικές διατρήσεις και γεωτρήσεις (2)
      <ul>
        <li>Δοκιμαστικές διατρήσεις (0)
        </li>
        <li>Δοκιμαστικές γεωτρήσεις (0)
        </li>
      </ul>
      </li>
      <li>Εργασίες κατεδάφισης και αποξήλωσης εγκαταστάσεων κτιρίων και εργασίες μετακίνησης γαιών (50)
      <ul>
        <li>Εργασίες εκσκαφών και μετακίνησης γαιών (30)
        <ul>
          <li>Εργασίες προσχώσεων και αξιοποίησης εκτάσεων γης (6)
          <ul>
            <li>Αποκατάσταση χώρου απόθεσης απορριμμάτων (0)
            </li>
            <li>Εργασίες αποκατάστασης εκτάσεων γης (0)
            </li>
            <li>Εργασίες απορρύπανσης εδάφους (0)
            </li>
            <li>Εργασίες αποκατάστασης περιοχής (0)
            </li>
            <li>Υπηρεσίες αποκατάστασης εδαφών (0)
            </li>
            <li>Εργασίες προσχώσεων (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες μετακίνησης γαιών (0)
          </li>
          <li>Εργασίες διάνοιξης τάφρων (0)
          </li>
          <li>Εργασίες διαμόρφωσης τοπίου (11)
          <ul>
            <li>Εργασίες διαμόρφωσης τοπίου για αεροδρόμια (0)
            </li>
            <li>Εργασίες διαμόρφωσης τοπίου για χώρους πρασίνου (4)
            <ul>
              <li>Εργασίες διαμόρφωσης τοπίου για πάρκα (0)
              </li>
              <li>Εργασίες διαμόρφωσης τοπίου για νεκροταφεία (0)
              </li>
              <li>Εργασίες διαμόρφωσης τοπίου για κήπους δωμάτων (0)
              </li>
              <li>Εργασίες διαμόρφωσης τοπίου για κήπους (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες διαμόρφωσης τοπίου για γήπεδα αθλητισμού και χώρους αναψυχής (3)
            <ul>
              <li>Εργασίες διαμόρφωσης τοπίου για κέντρα ιππασίας (0)
              </li>
              <li>Εργασίες διαμόρφωσης τοπίου για παιχνιδιότοπους (0)
              </li>
              <li>Εργασίες διαμόρφωσης τοπίου για γήπεδα γκολφ (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες διαμόρφωσης τοπίου για οδούς και αυτοκινητοδρόμους (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες αφαίρεσης και πλήρωσης γαιών (0)
          </li>
          <li>Εργασίες εκσκαφών (5)
          <ul>
            <li>Βαθμίδωση λόφων (1)
            <ul>
              <li>Εργασίες σχηματισμού βαθμίδων (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες εκσκαφής αρχαιολογικών χώρων (0)
            </li>
            <li>Εργασίες εκσκαφής τάφων (0)
            </li>
            <li>Εργασίες εκσκαφής θεμελίων (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες αφαίρεσης μολυσμένου εδάφους (1)
          <ul>
            <li>Εργασίες αφαίρεσης μολυσμένου επιφανειακού εδάφους (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εργασίες κατεδάφισης, διευθέτησης και εκκαθάρισης εργοταξίου (17)
        <ul>
          <li>Υπηρεσίες αποσυναρμολόγησης (2)
          <ul>
            <li>Υπηρεσίες αποσυναρμολόγησης για στρατιωτικές εγκαταστάσεις (0)
            </li>
            <li>Υπηρεσίες αποσυναρμολόγησης για εγκαταστάσεις ασφαλείας (0)
            </li>
          </ul>
          </li>
          <li>Εργασίες κατεδάφισης (0)
          </li>
          <li>Εργασίες διευθέτησης και εκκαθάρισης εργοταξίου (12)
          <ul>
            <li>Εργασίες ανατίναξης και εκβραχισμού (4)
            <ul>
              <li>Εργασίες ανατίναξης (0)
              </li>
              <li>Εργασίες εκκαθάρισης εργοταξίου (0)
              </li>
              <li>Εργασίες εκβραχισμού (0)
              </li>
              <li>Εργασίες εκκαθάρισης διά εκρήξεων (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες προπαρασκευής εργοταξίων για ορυχεία (0)
            </li>
            <li>Εργασίες αποστράγγισης εδάφους (0)
            </li>
            <li>Εργασίες εδαφολογικών ερευνών (0)
            </li>
            <li>Εργασίες σταθεροποίησης εδάφους (0)
            </li>
            <li>Προπαρασκευαστικές εργασίες για την παροχή υπηρεσιών (1)
            <ul>
              <li>Εργασίες αξιοποίησης περιοχής (0)
              </li>
            </ul>
            </li>
            <li>Εργασίες απομάκρυνσης μπάζων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εργασίες εργοταξίου (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Γεωργικά μηχανήματα (43)
  <ul>
    <li className='top-level'>Γεωργικά μηχανήματα ψεκασμού (0)
    </li>
    <li className='top-level'>Ελκυστήρες (3)
    <ul>
      <li>Μεταχειρισμένοι ελκυστήρες (0)
      </li>
      <li>Μικροί γεωργικοί ελκυστήρες με πεζοπόρο χειριστή (0)
      </li>
      <li>Κινητήρες για ελκυστήρες (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Θεριστικά μηχανήματα (7)
    <ul>
      <li>Μηχανήματα κοπής και ξήρανσης σανού (0)
      </li>
      <li>Χορτοκοπτικές μηχανές (2)
      <ul>
        <li>Μηχανήματα κοπής χλοοτάπητα (1)
        <ul>
          <li>Χορτοκοπτικές μηχανές για χλοοτάπητες, πάρκα ή αθλητικά γήπεδα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Θεριστικά και αλωνιστικά μηχανήματα (0)
      </li>
      <li>Μηχανές δεματισμού αχύρου ή χορτονομής (1)
      <ul>
        <li>Συλλεκτικές και συμπιεστικές μηχανές αχύρου (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ρυμουλκούμενα ή ημιρυμουλκούμενα οχήματα που διαθέτουν συστήματα αυτοφόρτωσης ή εκφόρτωσης για γεωργικούς σκοπούς (4)
    <ul>
      <li>Ρυμουλκούμενα γεωργικά οχήματα εκφόρτωσης (0)
      </li>
      <li>Ημιρυμουλκούμενα γεωργικά οχήματα αυτοφόρτωσης (0)
      </li>
      <li>Ημιρυμουλκούμενα γεωργικά οχήματα εκφόρτωσης (0)
      </li>
      <li>Ρυμουλκούμενα γεωργικά οχήματα αυτοφόρτωσης (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Ειδικά γεωργικά και δασοκομικά μηχανήματα (13)
    <ul>
      <li>Μηχανήματα πτηνοτροφείων (1)
      <ul>
        <li>Εκκολαπτήρες και επωαστήρες πτηνοτροφείων (0)
        </li>
      </ul>
      </li>
      <li>Μελισσοκομικά μηχανήματα (0)
      </li>
      <li>Μηχανήματα για την παρασκευή ζωοτροφών (0)
      </li>
      <li>Μηχανές για τον καθαρισμό, τη διαλογή ή τη διαβάθμιση αυγών, φρούτων ή άλλων προϊόντων (7)
      <ul>
        <li>Μηχανές καθαρισμού, διαλογής και διαβάθμισης σπόρου σποράς, σπόρων δημητριακών ή αποξηραμένων λαχανικών (0)
        </li>
        <li>Μηχανήματα για τον καθαρισμό προϊόντων (2)
        <ul>
          <li>Μηχανήματα για τον καθαρισμό φρούτων (0)
          </li>
          <li>Μηχανήματα για τον καθαρισμό αυγών (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα διαλογής ή διαβάθμισης προϊόντων (2)
        <ul>
          <li>Μηχανήματα διαλογής ή διαβάθμισης αυγών (0)
          </li>
          <li>Μηχανήματα διαλογής ή διαβάθμισης φρούτων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Αμελκτικές μηχανές (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Γεωργικά και δασοκομικά μηχανήματα για την προετοιμασία ή την καλλιέργεια του εδάφους (7)
    <ul>
      <li>Μηχανήματα σποράς, φύτευσης και μεταφύτευσης (0)
      </li>
      <li>Άροτρα και δισκοσβάρνες (0)
      </li>
      <li>Σβάρνες, αναμοχλευτήρες, καλλιεργητικές μηχανές, μηχανικά σκαλιστήρια και τσάπες (0)
      </li>
      <li>Διάφορα είδη εξοπλισμού κηπουρικής (0)
      </li>
      <li>Κύλινδροι χλοοτάπητα ή αθλητικών γηπέδων (0)
      </li>
      <li>Μηχανήματα διάστρωσης κοπριάς (1)
      <ul>
        <li>Μηχανήματα διάστρωσης λιπάσματος (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μέρη γεωργικών και δασοκομικών μηχανημάτων (2)
    <ul>
      <li>Μέρη δασοκομικών μηχανημάτων (0)
      </li>
      <li>Μέρη γεωργικών μηχανημάτων (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Χρηματοπιστωτικές και ασφαλιστικές υπηρεσίες (88)
  <ul>
    <li className='top-level'>Υπηρεσίες αντασφάλισης (2)
    <ul>
      <li>Υπηρεσίες αντασφάλισης ζωής (0)
      </li>
      <li>Υπηρεσίες αντασφάλισης ατυχήματος και υγείας (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες ασφάλισης και συνταξιοδότησης (53)
    <ul>
      <li>Συνταξιοδοτικές υπηρεσίες (4)
      <ul>
        <li>Ομαδικές συνταξιοδοτικές υπηρεσίες (0)
        </li>
        <li>Ατομικές συνταξιοδοτικές υπηρεσίες (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα συνταξιοδοτικών ταμείων (1)
        <ul>
          <li>Υπηρεσίες διαχείρισης των συνταξιοδοτικών ταμείων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες ασφάλισης (47)
      <ul>
        <li>Υπηρεσίες ασφάλισης πιστώσεων και εγγυήσεων (3)
        <ul>
          <li>Υπηρεσίες ασφάλισης πιστώσεων (0)
          </li>
          <li>Υπηρεσίες ασφάλισης έναντι κινδύνων διαχείρισης (0)
          </li>
          <li>Υπηρεσίες ασφάλισης εγγυήσεων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες ασφαλειών ζωής (0)
        </li>
        <li>Ασφάλεια ζημίας ή απώλειας (6)
        <ul>
          <li>Υπηρεσίες ασφάλισης περιουσίας (0)
          </li>
          <li>Υπηρεσίες ασφάλισης έναντι θεομηνίας και εισοδηματικής ζημίας (0)
          </li>
          <li>Υπηρεσίες σχετιζόμενες με την ασφάλιση έναντι θεομηνίας (2)
          <ul>
            <li>Υπηρεσίες ασφάλισης έναντι εισοδηματικής ζημίας (1)
            <ul>
              <li>Υπηρεσίες ασφάλισης έναντι χρηματικής απώλειας (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Ασφάλεια πυρός (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες ασφάλισης τεχνικών εγκαταστάσεων, επικουρικές ασφαλιστικές υπηρεσίες, υπηρεσίες ασφάλισης αβαριών, υπηρεσίες ασφάλισης έναντι ζημιών, υπηρεσίες εμπειρογνωμόνων ασφαλίσεων και υπηρεσίες ασφάλισης διάσωσης (8)
        <ul>
          <li>Υπηρεσίες διαχείρισης των δικαιωμάτων διάσωσης (0)
          </li>
          <li>Υπηρεσίες διακανονισμού των ζημιών (0)
          </li>
          <li>Επικουρικές ασφαλιστικές υπηρεσίες (1)
          <ul>
            <li>Υπηρεσίες παροχής συμβουλών σε θέματα ασφάλισης (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες εμπειρογνωμόνων ασφαλίσεων (0)
          </li>
          <li>Υπηρεσίες ασφάλισης εγκαταστάσεων παραγωγής πετρελαίου ή φυσικού αερίου (0)
          </li>
          <li>Υπηρεσίες διακανονισμού αβαριών (0)
          </li>
          <li>Υπηρεσίες ασφάλισης των τεχνικών εγκαταστάσεων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες χρηματομεσιτείας και πρακτορεύσεων στον τομέα των ασφαλειών (3)
        <ul>
          <li>Υπηρεσίες ασφαλιστικών πρακτόρων (0)
          </li>
          <li>Υπηρεσίες ασφαλιστικών γραφείων (0)
          </li>
          <li>Υπηρεσίες διακανονισμού ασφαλιστικών αποζημιώσεων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες ασφάλισης νομικής προστασίας και υπηρεσίες ασφάλισης έναντι παντός κινδύνου (2)
        <ul>
          <li>Υπηρεσίες ασφάλισης δικαστικών εξόδων (0)
          </li>
          <li>Υπηρεσίες ασφάλισης εργολήπτη έναντι παντός κινδύνου (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες ασφάλισης ατυχημάτων και υγείας (4)
        <ul>
          <li>Υπηρεσίες ασφάλισης υγείας (2)
          <ul>
            <li>Υπηρεσίες ιατροφαρμακευτικής ασφάλισης (0)
            </li>
            <li>Υπηρεσίες εθελοντικής ασφάλισης υγείας (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες ασφάλισης ατυχημάτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες ασφάλισης αστικής ευθύνης (5)
        <ul>
          <li>Ασφάλεια αστικής ευθύνης μηχανοκίνητων οχημάτων (0)
          </li>
          <li>Γενική ασφάλεια αστικής ευθύνης (0)
          </li>
          <li>Υπηρεσίες ασφάλισης επαγγελματικής ευθύνης (0)
          </li>
          <li>Ασφάλεια αστικής ευθύνης αεροσκαφών (0)
          </li>
          <li>Ασφάλεια αστικής ευθύνης πλοίων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες ασφάλισης φορτίου και υπηρεσίες ασφάλισης σε σχέση με μεταφορές (7)
        <ul>
          <li>Υπηρεσίες ασφάλισης σε σχέση με μεταφορές (5)
          <ul>
            <li>Υπηρεσίες ασφάλισης πλοίων (0)
            </li>
            <li>Υπηρεσίες ασφάλισης θαλάσσιων, αεροπορικών και άλλων μεταφορών (0)
            </li>
            <li>Υπηρεσίες ασφάλισης σιδηροδρόμων (0)
            </li>
            <li>Υπηρεσίες ασφάλισης μηχανοκίνητων οχημάτων (0)
            </li>
            <li>Υπηρεσίες ασφάλισης αεροσκαφών (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες ασφάλισης φορτίου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Τραπεζικές και επενδυτικές υπηρεσίες (29)
    <ul>
      <li>Επενδυτικές τραπεζικές και συναφείς υπηρεσίες (2)
      <ul>
        <li>Υπηρεσίες συγχώνευσης και εξαγοράς (0)
        </li>
        <li>Εταιρικές χρηματοπιστωτικές υπηρεσίες και υπηρεσίες κεφαλαίων επιχειρηματικού κινδύνου (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες διαχείρισης χαρτοφυλακίων (1)
      <ul>
        <li>Υπηρεσίες διαχείρισης της χρηματοδότησης των συνταξιοδοτικών ταμείων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες διαχείρισης των χρηματαγορών (3)
      <ul>
        <li>Υπηρεσίες πράξεων σε κεφαλαιαγορές (1)
        <ul>
          <li>Υπηρεσίες λιανικής σε ηλεκτρονικές αγορές (0)
          </li>
        </ul>
        </li>
        <li>Ρυθμιστικές υπηρεσίες κεφαλαιαγορών (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες συναλλάγματος (0)
      </li>
      <li>Υπηρεσίες καταπιστευμάτων και παρακαταθηκών (2)
      <ul>
        <li>Υπηρεσίες καταπιστευμάτων (0)
        </li>
        <li>Υπηρεσίες παρακαταθηκών (0)
        </li>
      </ul>
      </li>
      <li>Χρηματομεσιτικές υπηρεσίες και συναφείς υπηρεσίες χρεογράφων και εμπορευμάτων (4)
      <ul>
        <li>Εμπορομεσιτικές υπηρεσίες (0)
        </li>
        <li>Υπηρεσίες επεξεργασίας και συμψηφισμού (0)
        </li>
        <li>Υπηρεσίες χρηματομεσητείας χρεωγράφων (1)
        <ul>
          <li>Υπηρεσίες επένδυσης συνταξιοδοτικών ταμείων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες παροχής οικονομικών συμβουλών, επεξεργασίας χρηματοπιστωτικών συναλλαγών και γραφείων συμψηφισμού (2)
      <ul>
        <li>Υπηρεσίες παροχής οικονομικών συμβουλών (0)
        </li>
        <li>Υπηρεσίες επεξεργασίας χρηματοπιστωτικών συναλλαγών και γραφείων συμψηφισμού (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες χρηματομεσητείας δανείων (0)
      </li>
      <li>Τραπεζικές υπηρεσίες (6)
      <ul>
        <li>Υπηρεσίες χορήγησης πίστωσης (1)
        <ul>
          <li>Υπηρεσίες χορήγησης μικροπιστώσεων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες καταθέσεων (0)
        </li>
        <li>Υπηρεσίες διεθνούς μεταφοράς πληρωμών (0)
        </li>
        <li>Υπηρεσίες κεντρικής τράπεζας (0)
        </li>
        <li>Υπηρεσίες χρηματοδοτικής μίσθωσης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες διαχείρισης κεφαλαίων (0)
    </li>
  </ul>
  </li>
  <li className='top-level'>Επιχειρηματικές υπηρεσίες: νομικές, μάρκετινγκ, παροχής συμβουλών, πρόσληψης, εκτύπωσης και ασφάλειας (198)
  <ul>
    <li className='top-level'>Υπηρεσίες ερευνών και ασφάλειας (12)
    <ul>
      <li>Υπηρεσίες ασφάλειας (7)
      <ul>
        <li>Υπηρεσίες φύλαξης (0)
        </li>
        <li>Υπηρεσίες παρακολούθησης συστημάτων συναγερμού (0)
        </li>
        <li>Υπηρεσίες παράδοσης υπηρεσιακού διακριτικού σήματος (0)
        </li>
        <li>Υπηρεσίες περιπόλου (0)
        </li>
        <li>Υπηρεσίες επιτήρησης (2)
        <ul>
          <li>Υπηρεσίες συστήματος ανίχνευσης (1)
          <ul>
            <li>Υπηρεσίες ανεύρεσης φυγόδικων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ανακριτικές υπηρεσίες (3)
      <ul>
        <li>Υπηρεσίες ανάλυσης απορριμμάτων (0)
        </li>
        <li>Υπηρεσίες γραφείου ιδιωτικής αστυνομίας (0)
        </li>
        <li>Υπηρεσίες γραφολογίας (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες υποστήριξης γραφείου (14)
    <ul>
      <li>Υπηρεσίες δακτυλογράφησης, επεξεργασίας κειμένων και εκδόσεων με τη βοήθεια Η/Υ (3)
      <ul>
        <li>Υπηρεσίες εκδόσεων με τη βοήθεια Η/Υ (0)
        </li>
        <li>Υπηρεσίες επεξεργασίας κειμένων (0)
        </li>
        <li>Υπηρεσίες δακτυλογράφησης (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες αρχειοθέτησης (0)
      </li>
      <li>Υπηρεσίες διερμηνείας (0)
      </li>
      <li>Υπηρεσίες απόκρισης τηλεφωνικών κλήσεων (2)
      <ul>
        <li>Κέντρο κλήσεων (0)
        </li>
        <li>Υπηρεσίες τηλεφωνητών (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες σύνταξης καταλόγων διευθύνσεων και υπηρεσίες διεκπεραίωσης ταχυδρομείου (1)
      <ul>
        <li>Υπηρεσίες ταχυδρομικών αποστολών (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες αναπαραγωγής εγγράφων (1)
      <ul>
        <li>Φωτοαντιγραφικές υπηρεσίες (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες μετάφρασης (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες λογιστικής, διαχειριστικού ελέγχου και φορολογικές υπηρεσίες (17)
    <ul>
      <li>Φορολογικές υπηρεσίες (3)
      <ul>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα φορολογίας (0)
        </li>
        <li>Υπηρεσίες προετοιμασίας φορολογικών δηλώσεων (0)
        </li>
        <li>Υπηρεσίες εκτελωνισμού (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες λογιστικής και διαχειριστικού ελέγχου (12)
      <ul>
        <li>Λογιστικές υπηρεσίες (4)
        <ul>
          <li>Υπηρεσίες σύνταξης οικονομικών απολογισμών (0)
          </li>
          <li>Υπηρεσίες τήρησης λογιστικών βιβλίων (2)
          <ul>
            <li>Υπηρεσίες διαχείρισης μισθολογικών καταστάσεων (0)
            </li>
            <li>Υπηρεσίες καταγραφής αγοραπωλησιών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες διαχειριστικού ελέγχου (6)
        <ul>
          <li>Υπηρεσίες διαχειριστικού ελέγχου απάτης (0)
          </li>
          <li>Υπηρεσίες νόμιμου διαχειριστικού ελέγχου (0)
          </li>
          <li>Υπηρεσίες λογιστικής αναθεώρησης (0)
          </li>
          <li>Υπηρεσίες οικονομικού διαχειριστικού ελέγχου (1)
          <ul>
            <li>Υπηρεσίες αξιολόγησης εταιρικής διακυβέρνησης (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες εσωτερικού διαχειριστικού ελέγχου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες παροχής γενικών επιχειρηματικών συμβουλών και συμβουλών σε θέματα διαχείρισης (20)
    <ul>
      <li>Υπηρεσίες που σχετίζονται με τη διαχείριση (4)
      <ul>
        <li>Υπηρεσίες διαιτησίας και συμβιβασμού (0)
        </li>
        <li>Υπηρεσίες διαχείρισης έργων, εκτός από εργασίες κατασκευής (2)
        <ul>
          <li>Υπηρεσίες μελέτης έργων, εκτός από εργασίες κατασκευής (0)
          </li>
          <li>Υπηρεσίες επίβλεψης έργων, εκτός από εργασίες κατασκευής (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες διαχείρισης κρίσεων (0)
      </li>
      <li>Υπηρεσίες παροχής επιχειρηματικών συμβουλών και συμβουλών σε θέματα διαχείρισης (13)
      <ul>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα εκτιμήσεων (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα οικονομικής διαχείρισης (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα διαχείρισης ανθρώπινου δυναμικού (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα προμηθειών (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα ασφάλειας (0)
        </li>
        <li>Υπηρεσίες δημοσίων σχέσεων (2)
        <ul>
          <li>Υπηρεσίες παροχής συμβουλών σε θέματα δημοσίων σχέσεων (0)
          </li>
          <li>Υπηρεσίες διαχείρισης θεμάτων που αφορούν τις δημόσιες σχέσεις (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα γενικής διαχείρισης (1)
        <ul>
          <li>Υπηρεσίες παροχής συμβουλών σε θέματα ανάπτυξης επιχειρηματικών δραστηριοτήτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα μάρκετινγκ (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα οργάνωσης παραγωγής (1)
        <ul>
          <li>Υπηρεσίες παροχής συμβουλών σε θέματα σχεδιασμού (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Έρευνα αγοράς και οικονομική έρευνα δημοσκοπήσεις και στατιστικές (30)
    <ul>
      <li>Υπηρεσίες δημοσκοπήσεων (0)
      </li>
      <li>Υπηρεσίες στατιστικής (0)
      </li>
      <li>Υπηρεσίες διαφήμισης και μάρκετινγκ (15)
      <ul>
        <li>Υπηρεσίες μάρκετινγκ (9)
        <ul>
          <li>Υπηρεσίες δημοπρασίας (1)
          <ul>
            <li>Υπηρεσίες ηλεκτρονικής δημοπρασίας (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες πελατείας (4)
          <ul>
            <li>Υπηρεσίες εξυπηρέτησης πελατών (1)
            <ul>
              <li>Πρόγραμμα αφοσίωσης των πελατών (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες έρευνας στους πελάτες (1)
            <ul>
              <li>Έρευνα βαθμού ικανοποίησης του πελάτη (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες άμεσου μάρκετινγκ (0)
          </li>
          <li>Υπηρεσίες προώθησης (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες διαφήμισης (4)
        <ul>
          <li>Υπηρεσίες παροχής συμβουλών σε θέματα διαφήμισης (0)
          </li>
          <li>Υπηρεσίες διαφημιστικής εκστρατείας (0)
          </li>
          <li>Υπηρεσίες διαχείρισης διαφημίσεων (0)
          </li>
          <li>Υπηρεσίες εναέριας διαφήμισης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες έρευνας αγοράς (11)
      <ul>
        <li>Μελέτη σκοπιμότητας (0)
        </li>
        <li>Υπηρεσίες κοινωνικών ερευνών (0)
        </li>
        <li>Υπηρεσίες ελέγχου αγοράς (0)
        </li>
        <li>Υπηρεσίες ερευνών (6)
        <ul>
          <li>Υπηρεσίες ανάλυσης αποτελεσμάτων ερευνών (0)
          </li>
          <li>Υπηρεσίες σχεδιασμού έρευνας (0)
          </li>
          <li>Υπηρεσίες διεξαγωγής ερευνών (1)
          <ul>
            <li>Υπηρεσίες επιτήρησης τηλεφωνικού δικτύου (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες οικονομικών ερευνών (1)
          <ul>
            <li>Εκτίμηση οικονομικών επιπτώσεων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εκτίμησης απόδοσης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες πρόσληψης (16)
    <ul>
      <li>Υπηρεσίες τοποθέτησης προσωπικού (3)
      <ul>
        <li>Υπηρεσίες τοποθέτησης βοηθητικού προσωπικού γραφείου (0)
        </li>
        <li>Παροχή υπηρεσιών για την ανεύρεση εργασίας (0)
        </li>
        <li>Υπηρεσίες αλλαγής θέσης προσωπικού (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες διοίκησης προσωπικού εκτός από τις υπηρεσίες τοποθέτησης και διάθεσης (5)
      <ul>
        <li>Υπηρεσίες κατάρτισης προσωπικού (0)
        </li>
        <li>Υπηρεσίες κέντρου αξιολόγησης σε θέματα προσλήψεων (0)
        </li>
        <li>Υπηρεσίες διοίκησης προσωπικού και μισθοδοσίας (0)
        </li>
        <li>Υπηρεσίες εξέλιξης προσωπικού (0)
        </li>
        <li>Υπηρεσίες καθοδήγησης σε θέματα σταδιοδρομίας (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες διάθεσης προσωπικού όπου περιλαμβάνεται προσωρινό προσωπικό (5)
      <ul>
        <li>Υπηρεσίες διάθεσης προσωπικού οικιακών βοηθών (0)
        </li>
        <li>Υπηρεσίες διάθεσης νοσηλευτικού προσωπικού (0)
        </li>
        <li>Υπηρεσίες διάθεσης ιατρικού προσωπικού (0)
        </li>
        <li>Υπηρεσίες διάθεσης εμπορικών ή βιομηχανικών εργατών (0)
        </li>
        <li>Υπηρεσίες διάθεσης προσωπικού γραφείου (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Νομικές υπηρεσίες (12)
    <ul>
      <li>Υπηρεσίες παροχής νομικών συμβουλών και πληροφοριών (0)
      </li>
      <li>Υπηρεσίες νομικής τεκμηρίωσης και πιστοποίησης (3)
      <ul>
        <li>Υπηρεσίες τεκμηρίωσης (0)
        </li>
        <li>Υπηρεσίες πιστοποίησης (1)
        <ul>
          <li>Υπηρεσίες πιστοποίησης ηλεκτρονικών υπογραφών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες παροχής συμβουλών σε θέματα διπλωμάτων ευρεσιτεχνίας και δικαιωμάτων πνευματικής ιδιοκτησίας (2)
      <ul>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα πνευματικής ιδιοκτησίας (1)
        <ul>
          <li>Υπηρεσίες παροχής συμβουλών σε θέματα πνευματικής ιδιοκτησίας λογισμικού (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες παροχής συμβουλών νομικού χαρακτήρα και νομικής εκπροσώπησης (3)
      <ul>
        <li>Υπηρεσίες παροχής νομικών συμβουλών (0)
        </li>
        <li>Υπηρεσίες νομικής εκπροσώπησης (1)
        <ul>
          <li>Υπηρεσίες εκπροσώπησης ενδιαφερόμενων μερών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Διάφορες επιχειρηματικές και συναφείς υπηρεσίες (54)
    <ul>
      <li>Υπηρεσίες συσκευασίας και συναφείς υπηρεσίες (1)
      <ul>
        <li>Υπηρεσίες συσκευασίας (0)
        </li>
      </ul>
      </li>
      <li>Διάφορες επιχειρηματικές υπηρεσίες (15)
      <ul>
        <li>Υπηρεσίες σάρωσης και έκδοσης τιμολογίων (2)
        <ul>
          <li>Υπηρεσίες έκδοσης τιμολογίων (0)
          </li>
          <li>Υπηρεσίες σάρωσης (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες διαχείρισης κτιρίων και εγκαταστάσεων (1)
        <ul>
          <li>Υπηρεσίες διαχείρισης εγκαταστάσεων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες διαχείρισης βιβλιοθηκών (2)
        <ul>
          <li>Υπηρεσίες αρχείου (0)
          </li>
          <li>Υπηρεσίες κατάρτισης καταλόγων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες ελέγχου αποθεμάτων (0)
        </li>
        <li>Υπηρεσίες υποδοχής (0)
        </li>
        <li>Υπηρεσίες διαχείρισης συμβάσεων (0)
        </li>
        <li>Υπηρεσίες επαγγελματικών ταξιδίων (0)
        </li>
        <li>Υπηρεσίες προγύμνασης (0)
        </li>
        <li>Υπηρεσίες οργάνωσης επιχειρήσεων (1)
        <ul>
          <li>Διαχείριση μητρώων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες διοργάνωσης εκθέσεων και συνεδρίων (8)
      <ul>
        <li>Υπηρεσίες οργάνωσης πάρτι (0)
        </li>
        <li>Υπηρεσίες οργάνωσης εμπορικών εκθέσεων και εκθέσεων (0)
        </li>
        <li>Υπηρεσίες οργάνωσης επιδείξεων μόδας (0)
        </li>
        <li>Υπηρεσίες εκδηλώσεων (1)
        <ul>
          <li>Υπηρεσίες οργάνωσης πολιτιστικών εκδηλώσεων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες οργάνωσης δημοπρασιών (0)
        </li>
        <li>Υπηρεσίες οργάνωσης σεμιναρίων (0)
        </li>
        <li>Υπηρεσίες οργάνωσης φεστιβάλ (0)
        </li>
      </ul>
      </li>
      <li>Φωτογραφικές και συναφείς υπηρεσίες (11)
      <ul>
        <li>Υπηρεσίες εμφάνισης φωτογραφιών (0)
        </li>
        <li>Υπηρεσίες αποκατάστασης, αντιγραφής και ρετουσαρίσματος φωτογραφιών (0)
        </li>
        <li>Φωτογραφικές υπηρεσίες (8)
        <ul>
          <li>Ειδικές φωτογραφικές υπηρεσίες (5)
          <ul>
            <li>Υπηρεσίες υποβρύχιας φωτογράφησης (0)
            </li>
            <li>Υπηρεσίες μικροφωτογραφήσεων (0)
            </li>
            <li>Υπηρεσίες ακτινογράφησης (0)
            </li>
            <li>Υπηρεσίες στούντιο φωτογραφίας (0)
            </li>
            <li>Υπηρεσίες φωτογράφησης γεωτρήσεων (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες διαφημιστικών φωτογραφήσεων (0)
          </li>
          <li>Υπηρεσίες αεροφωτογραφιών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες δημοσιεύσεων (5)
      <ul>
        <li>Υπηρεσίες εκδόσεως λεξικών (1)
        <ul>
          <li>Υπηρεσίες εκδόσεως λεξικών τοπικών διαλέκτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες βιβλιοδεσίας και φινιρίσματος (2)
        <ul>
          <li>Υπηρεσίες βιβλιοδεσίας (0)
          </li>
          <li>Υπηρεσίες φινιρίσματος βιβλίων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες διαχείρισης ελεγχουσών εταιρειών (0)
      </li>
      <li>Υπηρεσίες συνδρομών (0)
      </li>
      <li>Υπηρεσίες γραφείων είσπραξης χρεών (1)
      <ul>
        <li>Υπηρεσίες είσπραξης διοδίων (0)
        </li>
      </ul>
      </li>
      <li>Ειδικές υπηρεσίες μελετών (4)
      <ul>
        <li>Υπηρεσίες σχεδίασης επίπλων (0)
        </li>
        <li>Υπηρεσίες εσωτερικής διακόσμησης (0)
        </li>
        <li>Υπηρεσίες εσωτερικού σχεδιασμού (0)
        </li>
        <li>Υπηρεσίες υποστήριξης στον τομέα του σχεδιασμού (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες εκτύπωσης και συναφείς υπηρεσίες (14)
    <ul>
      <li>Υπηρεσίες σχετιζόμενες με την εκτύπωση (10)
      <ul>
        <li>Υπηρεσίες τυπογραφικής σύνθεσης (5)
        <ul>
          <li>Υπηρεσίες φωτοχαρακτικής (0)
          </li>
          <li>Υπηρεσίες γραφικού σχεδιασμού (0)
          </li>
          <li>Λιθογραφικές υπηρεσίες (0)
          </li>
          <li>Υπηρεσίες στοιχειοθεσίας (0)
          </li>
          <li>Υπηρεσίες παρασκευής εκτυπωτικών πλακών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εκτύπωσης και παράδοσης (0)
        </li>
        <li>Υπηρεσίες φινιρίσματος εκτυπώσεων (1)
        <ul>
          <li>Υπηρεσίες διόρθωσης κειμένων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εκτύπωσης και διανομής (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εκτύπωσης (2)
      <ul>
        <li>Υπηρεσίες ψηφιακής εκτύπωσης (0)
        </li>
        <li>Υπηρεσίες τύπωσης χαρτονομισμάτων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Προϊόντα γεωργίας, κτηνοτροφίας, αλιείας, δασοκομίας και συναφή προϊόντα (229)
  <ul>
    <li className='top-level'>Δημητριακά, πατάτες, λαχανικά, φρούτα και ξηροί καρποί (90)
    <ul>
      <li>Λαχανικά, φρούτα και ξηροί καρποί (69)
      <ul>
        <li>Φρούτα και καρποί (37)
        <ul>
          <li>Μη τροπικά φρούτα (18)
          <ul>
            <li>Μήλα, αχλάδια και κυδώνια (3)
            <ul>
              <li>Μήλα (0)
              </li>
              <li>Αχλάδια (0)
              </li>
              <li>Κυδώνια (0)
              </li>
            </ul>
            </li>
            <li>Μούρα (5)
            <ul>
              <li>Σμέουρα (0)
              </li>
              <li>Μύρτιλλα μακρόκαρπα (0)
              </li>
              <li>Φράουλες (0)
              </li>
              <li>Χαμαίμουρα (0)
              </li>
              <li>Μαύρα, λευκά ή κόκκινα φραγκοστάφυλα (0)
              </li>
            </ul>
            </li>
            <li>Σταφύλια (2)
            <ul>
              <li>Κρασοστάφυλα (0)
              </li>
              <li>Επιτραπέζια σταφύλια (0)
              </li>
            </ul>
            </li>
            <li>Εμπύρηνα φρούτα (4)
            <ul>
              <li>Ροδάκινα (0)
              </li>
              <li>Κεράσια (0)
              </li>
              <li>Βερίκοκα (0)
              </li>
              <li>Δαμάσκηνα (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Ελιές (0)
          </li>
          <li>Εσπεριδοειδή (5)
          <ul>
            <li>Γκρέιπ φρουτ (0)
            </li>
            <li>Πορτοκάλια (0)
            </li>
            <li>Μοσχολέμονα (0)
            </li>
            <li>Μανταρίνια (0)
            </li>
            <li>Λεμόνια (0)
            </li>
          </ul>
          </li>
          <li>Τροπικά φρούτα και καρποί (10)
          <ul>
            <li>Καρύδες (0)
            </li>
            <li>Τροπικά φρούτα (8)
            <ul>
              <li>Μπανάνες (0)
              </li>
              <li>Ανανάδες (0)
              </li>
              <li>Σύκα (0)
              </li>
              <li>Χουρμάδες (0)
              </li>
              <li>Σταφίδες (0)
              </li>
              <li>Μάνγκο (0)
              </li>
              <li>Αβοκάντο (0)
              </li>
              <li>Ακτινίδια (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Λαχανικά (30)
        <ul>
          <li>Οπωροκηπευτικά (12)
          <ul>
            <li>Αρακάς (2)
            <ul>
              <li>Νωπά μπιζέλια (0)
              </li>
              <li>Μπιζέλια κήπου (0)
              </li>
            </ul>
            </li>
            <li>Πιπεριές (0)
            </li>
            <li>Μανιτάρια (0)
            </li>
            <li>Αγγούρια (0)
            </li>
            <li>Τομάτες (0)
            </li>
            <li>Φασόλια (3)
            <ul>
              <li>Φρέσκα φασόλια (0)
              </li>
              <li>Φασόλια Ισπανίας (0)
              </li>
              <li>Κουκιά (0)
              </li>
            </ul>
            </li>
            <li>Κολοκύθια (0)
            </li>
          </ul>
          </li>
          <li>Βολβοειδή και κονδυλώδη λαχανικά (6)
          <ul>
            <li>Βολβοειδή λαχανικά (4)
            <ul>
              <li>Ρίζα τεύτλου (0)
              </li>
              <li>Γογγύλια (0)
              </li>
              <li>Κρεμμύδια (0)
              </li>
              <li>Καρότα (0)
              </li>
            </ul>
            </li>
            <li>Κονδυλώδη λαχανικά (0)
            </li>
          </ul>
          </li>
          <li>Φυλλώδη λαχανικά (4)
          <ul>
            <li>Μαρούλι (0)
            </li>
            <li>Αγκινάρες (0)
            </li>
            <li>Σπανάκι (0)
            </li>
            <li>Πράσινες σαλάτες (0)
            </li>
          </ul>
          </li>
          <li>Κραμβοειδή λαχανικά (4)
          <ul>
            <li>Λαχανάκια Βρυξελλών (0)
            </li>
            <li>Μπρόκολο (0)
            </li>
            <li>Κουνουπίδι (0)
            </li>
            <li>Λάχανο, κράμβη (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Δημητριακά και πατάτες (19)
      <ul>
        <li>Πατάτες και αφυδατωμένα λαχανικά (7)
        <ul>
          <li>Πατάτες (0)
          </li>
          <li>Αφυδατωμένα οσπριώδη φυτά και όσπρια (5)
          <ul>
            <li>Αφυδατωμένα οσπριώδη φυτά (3)
            <ul>
              <li>Ρεβίθια (0)
              </li>
              <li>Φακές (0)
              </li>
              <li>Ξηρά μπιζέλια (0)
              </li>
            </ul>
            </li>
            <li>Όσπρια (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Δημητριακά (10)
        <ul>
          <li>Σίκαλη (0)
          </li>
          <li>Ρύζι (0)
          </li>
          <li>Βρώμη (0)
          </li>
          <li>Κριθάρι (0)
          </li>
          <li>Προϊόντα δημητριακών (0)
          </li>
          <li>Καλαμπόκι (0)
          </li>
          <li>Βύνη (0)
          </li>
          <li>Σιτάρι (2)
          <ul>
            <li>Σκληρό σιτάρι (0)
            </li>
            <li>Μαλακό σιτάρι (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Προϊόντα κτηνοτροφίας, θήρας και αλιείας (47)
    <ul>
      <li>Ενώτια για το μαρκάρισμα ζώων (1)
      <ul>
        <li>Ενώτια για το μαρκάρισμα βοοειδών (0)
        </li>
      </ul>
      </li>
      <li>Μεγάλα ζώα, ζωικό κεφάλαιο και μικρά ζώα (12)
      <ul>
        <li>Ζώντα πουλερικά (0)
        </li>
        <li>Ζωικό κεφάλαιο (3)
        <ul>
          <li>Πρόβατα (0)
          </li>
          <li>Αίγες (0)
          </li>
          <li>Άλογα (0)
          </li>
        </ul>
        </li>
        <li>Μεγάλα ζώα (2)
        <ul>
          <li>Μοσχάρια (0)
          </li>
          <li>Βοοειδή (0)
          </li>
        </ul>
        </li>
        <li>Χοιροειδή (0)
        </li>
        <li>Μικρά ζώα (2)
        <ul>
          <li>Λαγοί (0)
          </li>
          <li>Κουνέλια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ψάρια, οστρακοειδή και υδρόβια προϊόντα (23)
      <ul>
        <li>Ψάρια (13)
        <ul>
          <li>Πλατύψαρα (2)
          <ul>
            <li>Γλώσσα (0)
            </li>
            <li>Πησσί (0)
            </li>
          </ul>
          </li>
          <li>Μαρίδα (0)
          </li>
          <li>Τόνος (0)
          </li>
          <li>Ψάρια της οικογένειας του μπακαλιάρου (4)
          <ul>
            <li>Βακαλάος μελανόχρωμος (0)
            </li>
            <li>Σαυρίδι (0)
            </li>
            <li>Μπακαλιάρος (0)
            </li>
            <li>Καλλαρίας (0)
            </li>
          </ul>
          </li>
          <li>Γάδος (0)
          </li>
          <li>Ρέγγα (0)
          </li>
          <li>Σολωμός (0)
          </li>
        </ul>
        </li>
        <li>Υδρόβια προϊόντα (4)
        <ul>
          <li>Φυσικοί σπόγγοι (0)
          </li>
          <li>Φύκια (1)
          <ul>
            <li>Άλγες (0)
            </li>
          </ul>
          </li>
          <li>Κοράλλια ή παρόμοια προϊόντα (0)
          </li>
        </ul>
        </li>
        <li>Καρκινοειδή (3)
        <ul>
          <li>Στρείδια (0)
          </li>
          <li>Οστρακοειδή (0)
          </li>
          <li>Υδρόβια ασπόνδυλα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Προϊόντα ζώων εκτρεφόμενων σε αγροκτήματα (7)
      <ul>
        <li>Νωπό αγελαδινό γάλα (0)
        </li>
        <li>Νωπό γάλα από αιγοπρόβατα (2)
        <ul>
          <li>Πρόβειο γάλα (0)
          </li>
          <li>Κατσικίσιο γάλα (0)
          </li>
        </ul>
        </li>
        <li>Έριο και ζωικές τρίχες (2)
        <ul>
          <li>Κουρεμένο μαλλί (0)
          </li>
          <li>Ζωικές τρίχες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Δασοκομικά και υλοτομικά προϊόντα (32)
    <ul>
      <li>Προϊόντα φυτωρίων δέντρων (5)
      <ul>
        <li>Φυτά (3)
        <ul>
          <li>Βολβοί λουλουδιών (0)
          </li>
          <li>Θάμνοι (0)
          </li>
          <li>Φυτά για φύτευση σε παρτέρια (0)
          </li>
        </ul>
        </li>
        <li>Δένδρα (0)
        </li>
      </ul>
      </li>
      <li>Δασοκομικά προϊόντα (1)
      <ul>
        <li>Διακοσμητικά φυτά, χορτάρια, βρύα και λειχήνες (0)
        </li>
      </ul>
      </li>
      <li>Πολτός (2)
      <ul>
        <li>Ξυλοπολτός (1)
        <ul>
          <li>Χημικός ξυλοπολτός (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Κόμμεα (2)
      <ul>
        <li>Λάκα (0)
        </li>
        <li>Βάλσαμα (0)
        </li>
      </ul>
      </li>
      <li>Ξύλο (13)
      <ul>
        <li>Ξυλεία από τροπικά δέντρα (0)
        </li>
        <li>Απορρίμματα ξύλου (0)
        </li>
        <li>Υπολείμματα ξύλου (1)
        <ul>
          <li>Πριονίδια (0)
          </li>
        </ul>
        </li>
        <li>Ακατέργαστη ξυλεία (0)
        </li>
        <li>Ακατέργαστοι κορμοί (1)
        <ul>
          <li>Σκληρή ξυλεία (0)
          </li>
        </ul>
        </li>
        <li>Ξυλεία (2)
        <ul>
          <li>Ξυλεία ορυχείων (0)
          </li>
          <li>Προϊόντα ξυλείας (0)
          </li>
        </ul>
        </li>
        <li>Μαλακή ξυλεία (0)
        </li>
        <li>Καυσόξυλα (0)
        </li>
        <li>Ξυλεία από κωνοφόρα δέντρα (0)
        </li>
      </ul>
      </li>
      <li>Φελλός (3)
      <ul>
        <li>Φυσικός φελλός (0)
        </li>
        <li>Καλαθοποιία (1)
        <ul>
          <li>Σπαρτοπλεκτική (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Προϊόντα γεωργίας και κηπευτικής (56)
    <ul>
      <li>Προϊόντα κηπευτικής και φυτωρίου (4)
      <ul>
        <li>Φυτοκομικά προϊόντα (3)
        <ul>
          <li>Ζώντα φυτά, βολβοί, ρίζες και μοσχεύματα (0)
          </li>
          <li>Κομμένα άνθη (1)
          <ul>
            <li>Άνθινες διατάξεις (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ζωικά και συναφή προϊόντα (9)
      <ul>
        <li>Γεωργικές προμήθειες (0)
        </li>
        <li>Ζωικά προϊόντα (5)
        <ul>
          <li>Βρώσιμα προϊόντα ζωικής προέλευσης (0)
          </li>
          <li>Σαλιγκάρια (0)
          </li>
          <li>Φυσικό μέλι (0)
          </li>
          <li>Αυγά (0)
          </li>
          <li>Κηροί (0)
          </li>
        </ul>
        </li>
        <li>Σπέρμα ταύρου (0)
        </li>
        <li>Προϊόντα μεικτής γεωργοκτηνοτροφικής δραστηριότητας (0)
        </li>
      </ul>
      </li>
      <li>Φυτά για αφεψήματα και μπαχαρικά (6)
      <ul>
        <li>Μη επεξεργασμένα καρυκεύματα (0)
        </li>
        <li>Φυτά για αφεψήματα (4)
        <ul>
          <li>Κόκκοι κακάου (0)
          </li>
          <li>Τσάι σε κλώνους (0)
          </li>
          <li>Ματέ (0)
          </li>
          <li>Κόκκοι καφέ (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Φυτά και προϊόντα μεγάλης καλλιέργειας και κηπευτικά είδη (33)
      <ul>
        <li>Σπόροι (9)
        <ul>
          <li>Αράπικα φιστίκια (0)
          </li>
          <li>Σπόροι από σινάπι (0)
          </li>
          <li>Σπόροι ηλιάνθου (0)
          </li>
          <li>Σπόροι από σουσάμι (0)
          </li>
          <li>Σπόροι λαχανικών (0)
          </li>
          <li>Σπόροι καρπών (0)
          </li>
          <li>Βαμβακόσποροι (0)
          </li>
          <li>Σπόροι άνθεων (0)
          </li>
          <li>Σπόροι σόγιας (0)
          </li>
        </ul>
        </li>
        <li>Ακατέργαστες φυτικές ύλες (4)
        <ul>
          <li>Φυτικές πρώτες ύλες που χρησιμοποιούνται στην κλωστοϋφαντουργία (3)
          <ul>
            <li>Γιούτα (0)
            </li>
            <li>Λινάρι (0)
            </li>
            <li>Βαμβάκι (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Φυτά που χρησιμοποιούνται σε ειδικούς τομείς (6)
        <ul>
          <li>Φυτά που χρησιμοποιούνται στην αρωματοποιία, τη φαρμακευτική ή την παραγωγή εντομοκτόνων ή για παρόμοιους σκοπούς (4)
          <ul>
            <li>Φυτά που χρησιμοποιούνται για την παραγωγή εντομοκτόνων (0)
            </li>
            <li>Φυτά που χρησιμοποιούνται στη φαρμακευτική (0)
            </li>
            <li>Φυτά που χρησιμοποιούνται στην αρωματοποιία (0)
            </li>
            <li>Φυτά που χρησιμοποιούνται για την παραγωγή μυκητοκτόνων ή για παρόμοιους σκοπούς (0)
            </li>
          </ul>
          </li>
          <li>Σπόροι φυτών που χρησιμοποιούνται σε ειδικούς τομείς (0)
          </li>
        </ul>
        </li>
        <li>Φυτά που χρησιμοποιούνται για την παρασκευή ζάχαρης (2)
        <ul>
          <li>Ζαχαροκάλαμο (0)
          </li>
          <li>Ζαχαρότευτλα (0)
          </li>
        </ul>
        </li>
        <li>Άχυρα και χορτονομές (2)
        <ul>
          <li>Χορτονομές (0)
          </li>
          <li>Άχυρα (0)
          </li>
        </ul>
        </li>
        <li>Φυσικό καουτσούκ, λατέξ και συναφή προϊόντα (3)
        <ul>
          <li>Προϊόντα από λατέξ (0)
          </li>
          <li>Φυσικό καουτσούκ (0)
          </li>
          <li>Φυσικό λατέξ (0)
          </li>
        </ul>
        </li>
        <li>Μη μεταποιημένος καπνός (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Είδη και εξαρτήματα ρουχισμού, υπόδησης, αποσκευών (193)
  <ul>
    <li className='top-level'>Ενδύματα (22)
    <ul>
      <li>Στηθόδεσμοι, κορσέδες, ζαρτιέρες και συναφή είδη (3)
      <ul>
        <li>Ζαρτιέρες (0)
        </li>
        <li>Κορσέδες (0)
        </li>
        <li>Στηθόδεσμοι (0)
        </li>
      </ul>
      </li>
      <li>Κοντομάνικες φανέλες και πουκάμισα (3)
      <ul>
        <li>Κοντομάνικες φανέλες (0)
        </li>
        <li>Μπλούζες πόλο (0)
        </li>
        <li>Πουκάμισα (0)
        </li>
      </ul>
      </li>
      <li>Εσώρουχα (13)
      <ul>
        <li>Κάλτσες (0)
        </li>
        <li>Μακριές κάλτσες (0)
        </li>
        <li>Καλσόν (0)
        </li>
        <li>Μακριά εσώβρακα (0)
        </li>
        <li>Σλιπ (0)
        </li>
        <li>Ρόμπες μπάνιου (0)
        </li>
        <li>Κιλότες (0)
        </li>
        <li>Πιτζάμες και νυχτικά (5)
        <ul>
          <li>Πιτζάμες (0)
          </li>
          <li>Φανέλες (0)
          </li>
          <li>Ρόμπες (0)
          </li>
          <li>Ενδύματα νυχτός (0)
          </li>
          <li>Νυχτικά (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ρουχισμός επαγγελματικής χρήσης, ειδικός ρουχισμός εργασίας και εξαρτήματα (11)
    <ul>
      <li>Εξαρτήματα επαγγελματικού ρουχισμού (3)
      <ul>
        <li>Προστατευτικά εξαρτήματα (0)
        </li>
        <li>Προσωπίδες ασφαλείας (0)
        </li>
        <li>Γάντια εργασίας (0)
        </li>
      </ul>
      </li>
      <li>Ρουχισμός επαγγελματικής χρήσης (2)
      <ul>
        <li>Ολόσωμα ενδύματα (0)
        </li>
        <li>Ρουχισμός για χρήση στη βιομηχανία (0)
        </li>
      </ul>
      </li>
      <li>Ειδικός ρουχισμός εργασίας (3)
      <ul>
        <li>Ρουχισμός ιπταμένων (2)
        <ul>
          <li>Στολές ιπταμένων (0)
          </li>
          <li>Επενδύτες ιπταμένων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Αποσκευές, είδη σελλοποιίας, σάκοι και τσάντες (27)
    <ul>
      <li>Είδη σαγματοποιίας και ιπποσκευής (3)
      <ul>
        <li>Μαστίγια ιππασίας (0)
        </li>
        <li>Σέλλες (0)
        </li>
        <li>Μαστίγια (0)
        </li>
      </ul>
      </li>
      <li>Αποσκευές (9)
      <ul>
        <li>Πουγγιά και πορτοφόλια (2)
        <ul>
          <li>Πορτοφόλια (0)
          </li>
          <li>Πουγγιά (0)
          </li>
        </ul>
        </li>
        <li>Νεσεσέρ (0)
        </li>
        <li>Μπαούλα (0)
        </li>
        <li>Θήκες για φιάλες νερού και πιστολιοθήκες (2)
        <ul>
          <li>Πιστολιοθήκες (0)
          </li>
          <li>Θήκες για φιάλες νερού (0)
          </li>
        </ul>
        </li>
        <li>Βαλίτσες (0)
        </li>
      </ul>
      </li>
      <li>Σάκοι και τσάντες (12)
      <ul>
        <li>Τσάντες χειρός (0)
        </li>
        <li>Σάκοι καθαριστηρίου (0)
        </li>
        <li>Σάκοι αλληλογραφίας και δεμάτων (1)
        <ul>
          <li>Ταχυδρομικοί σάκοι (0)
          </li>
        </ul>
        </li>
        <li>Σάκοι συσκευασίας (1)
        <ul>
          <li>Σακούλες συσκευασίας (0)
          </li>
        </ul>
        </li>
        <li>Ταξιδιωτικοί σάκοι (1)
        <ul>
          <li>Εκδρομικά σακίδια (0)
          </li>
        </ul>
        </li>
        <li>Ταχυδρομικοί φάκελοι με προστατευτική επένδυση (0)
        </li>
        <li>Σάκοι προσωπικών ειδών (σακίδια) (0)
        </li>
        <li>Αθλητικές τσάντες (0)
        </li>
        <li>Υφασμάτινοι σάκοι (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Γούνες και γούνινα είδη (5)
    <ul>
      <li>Γούνες (0)
      </li>
      <li>Γούνινα είδη (3)
      <ul>
        <li>Γούνινα ενδύματα (0)
        </li>
        <li>Είδη από τεχνητή γούνα (0)
        </li>
        <li>Γουνοδέρματα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εξωτερικά ενδύματα (26)
    <ul>
      <li>Διάφορα είδη ρουχισμού (9)
      <ul>
        <li>Φορέματα (0)
        </li>
        <li>Παντελόνια (0)
        </li>
        <li>Φούστες (0)
        </li>
        <li>Πουλόβερ, πλεκτές μάλλινες ζακέτες και παρόμοια είδη (4)
        <ul>
          <li>Γιλέκα-επενδύτες (0)
          </li>
          <li>Πουλόβερ (0)
          </li>
          <li>Πλεκτές ζακέτες (0)
          </li>
          <li>Μπλούζες φούτερ (0)
          </li>
        </ul>
        </li>
        <li>Κοντά παντελονάκια (0)
        </li>
      </ul>
      </li>
      <li>Παλτά (3)
      <ul>
        <li>Αντιανεμικοί επενδύτες (0)
        </li>
        <li>Επινώτια (0)
        </li>
        <li>Μανδύες (0)
        </li>
      </ul>
      </li>
      <li>Ρουχισμός προστασίας από την κακοκαιρία (11)
      <ul>
        <li>Συλλογικός ρουχισμός (2)
        <ul>
          <li>Κουστούμια (ανδρικά)· ταγιέρ (γυναικεία) (0)
          </li>
          <li>Σύνολα ρουχισμού (0)
          </li>
        </ul>
        </li>
        <li>Αδιάβροχος ρουχισμός (3)
        <ul>
          <li>Αδιάβροχα παλτά (0)
          </li>
          <li>Αδιάβροχοι μανδύες (0)
          </li>
          <li>Άνορακ (0)
          </li>
        </ul>
        </li>
        <li>Επενδύτες και σακάκια τύπου μπλέιζερ (2)
        <ul>
          <li>Επενδύτες (0)
          </li>
          <li>Σακάκια τύπου μπλέιζερ (0)
          </li>
        </ul>
        </li>
        <li>Ενδύματα από επικαλυμμένες ή εμποτισμένες υφαντικές ύλες (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Κοσμήματα, ρολόγια χειρός και συναφή είδη (23)
    <ul>
      <li>Δώρα και βραβεία (0)
      </li>
      <li>Είδη ωρολογοποιίας για προσωπική χρήση (4)
      <ul>
        <li>Ρολόγια (1)
        <ul>
          <li>Γυαλί για ρολόγια (0)
          </li>
        </ul>
        </li>
        <li>Χρονόμετρα (0)
        </li>
        <li>Ρολόγια χειρός (0)
        </li>
      </ul>
      </li>
      <li>Κοσμήματα και συναφή είδη (16)
      <ul>
        <li>Νομίσματα και μετάλλια (2)
        <ul>
          <li>Μετάλλια (0)
          </li>
          <li>Νομίσματα (0)
          </li>
        </ul>
        </li>
        <li>Είδη κοσμηματοπωλείου (5)
        <ul>
          <li>Είδη από πολύτιμα μέταλλα (0)
          </li>
          <li>Είδη χρυσοχοείου (0)
          </li>
          <li>Μαργαριτάρια (0)
          </li>
          <li>Είδη αργυροχόου (0)
          </li>
          <li>Είδη από πολύτιμους ή ημιπολύτιμους λίθους (0)
          </li>
        </ul>
        </li>
        <li>Πολύτιμοι λίθοι για κοσμήματα (6)
        <ul>
          <li>Χαλαζίας (0)
          </li>
          <li>Σμαράγδια (0)
          </li>
          <li>Ρουμπίνια (0)
          </li>
          <li>Τουρμαλίνης (0)
          </li>
          <li>Διαμάντια (0)
          </li>
          <li>Οπάλιο (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ειδικά ενδύματα και εξαρτήματα ένδυσης (39)
    <ul>
      <li>Ειδικά ενδύματα (6)
      <ul>
        <li>Αθλητικά ενδύματα (4)
        <ul>
          <li>Ενδύματα κολύμβησης (0)
          </li>
          <li>Αθλητικά μπλουζάκια (0)
          </li>
          <li>Αθλητικά ενδύματα για τζόγκινγκ (0)
          </li>
          <li>Στολή χιονοδρόμων (0)
          </li>
        </ul>
        </li>
        <li>Βρεφικά ενδύματα (0)
        </li>
      </ul>
      </li>
      <li>Εξαρτήματα ρουχισμού (9)
      <ul>
        <li>Μαντίλια (0)
        </li>
        <li>Μαντίλια λαιμού (0)
        </li>
        <li>Γραβάτες (0)
        </li>
        <li>Ζώνες (1)
        <ul>
          <li>Φυσιγγιοθήκες (0)
          </li>
        </ul>
        </li>
        <li>Γάντια (3)
        <ul>
          <li>Γάντια μιας χρήσης (0)
          </li>
          <li>Γάντια χωρίς δάκτυλα (0)
          </li>
          <li>Μακριά γάντια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Καπέλα και καλύμματα κεφαλής (16)
      <ul>
        <li>Καπέλα (0)
        </li>
        <li>Προστατευτικά καλύμματα κεφαλής (5)
        <ul>
          <li>Κράνη ασφαλείας (3)
          <ul>
            <li>Κράνη (2)
            <ul>
              <li>Κράνη προστασίας από την πρόσκρουση (0)
              </li>
              <li>Κράνη ποδηλάτων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Καπέλα από άκαμπτο υλικό (0)
          </li>
        </ul>
        </li>
        <li>Καλύμματα κεφαλής και εξαρτήματα (8)
        <ul>
          <li>Υποσιάγωνα καλυμμάτων κεφαλής (0)
          </li>
          <li>Γείσα (0)
          </li>
          <li>Καλύμματα κεφαλής (4)
          <ul>
            <li>Στρατιωτικά τζόκεϊ (0)
            </li>
            <li>Μπερέδες (0)
            </li>
            <li>Πηλήκια (0)
            </li>
            <li>Κουκούλες (0)
            </li>
          </ul>
          </li>
          <li>Κεφαλόδεσμοι (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Αγκράφες (για ενδύματα) (4)
      <ul>
        <li>Κουμπιά (1)
        <ul>
          <li>Μέρη κουμπιών (0)
          </li>
        </ul>
        </li>
        <li>Παραμάνες (0)
        </li>
        <li>Φερμουάρ (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υποδήματα (32)
    <ul>
      <li>Υποδήματα, εκτός των αθλητικών και των προστατευτικών υποδημάτων (17)
      <ul>
        <li>Αδιάβροχα υποδήματα (0)
        </li>
        <li>Γαλότσες (0)
        </li>
        <li>Υποδήματα με μέρη από ελαστικό ή πλαστικό (4)
        <ul>
          <li>Μπότες από ελαστικό (0)
          </li>
          <li>Σαγιονάρες (0)
          </li>
          <li>Υποδήματα περιπάτου με επάνω μέρος από ελαστικό ή πλαστικό (0)
          </li>
          <li>Σανδάλια με επάνω μέρος από ελαστικό ή πλαστικό (0)
          </li>
        </ul>
        </li>
        <li>Μπότες (4)
        <ul>
          <li>Ψηλές αδιάβροχες μπότες (0)
          </li>
          <li>Μποτάκια μέχρι τον αστράγαλο (0)
          </li>
          <li>Μπότες ως το γόνατο (0)
          </li>
          <li>Μπότες ως τη γάμπα (0)
          </li>
        </ul>
        </li>
        <li>Υποδήματα με επάνω μέρη από δέρμα (3)
        <ul>
          <li>Σανδάλια (0)
          </li>
          <li>Υποδήματα πόλης (0)
          </li>
          <li>Παντόφλες (0)
          </li>
        </ul>
        </li>
        <li>Υποδήματα με επάνω μέρη από ύφασμα (0)
        </li>
      </ul>
      </li>
      <li>Αθλητικά υποδήματα (5)
      <ul>
        <li>Μπότες ορειβασίας (0)
        </li>
        <li>Υποδήματα προπόνησης (0)
        </li>
        <li>Υποδήματα χιονοδρομίας (1)
        <ul>
          <li>Υποδήματα για πορεία με χιονοπέδιλα (0)
          </li>
        </ul>
        </li>
        <li>Υποδήματα ποδοσφαίρου (0)
        </li>
      </ul>
      </li>
      <li>Προστατευτικά υποδήματα (3)
      <ul>
        <li>Υποδήματα με ενσωματωμένη προστατευτική μεταλλική μύτη (0)
        </li>
        <li>Ειδικά υποδήματα (1)
        <ul>
          <li>Υποδήματα πτήσεων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μέρη υποδημάτων (3)
      <ul>
        <li>Επάνω μέρη υποδημάτων (0)
        </li>
        <li>Τακούνια (0)
        </li>
        <li>Σόλες (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες που αφορούν τα ακίνητα (35)
  <ul>
    <li className='top-level'>Διάφορες υπηρεσίες μεσιτικού γραφείου ακινήτων έναντι αμοιβής ή βάσει σύμβασης (14)
    <ul>
      <li>Υπηρεσίες χρονομεριστικής μίσθωσης (0)
      </li>
      <li>Υπηρεσίες διαχείρισης ακινήτων έναντι αμοιβής ή βάσει σύμβασης (7)
      <ul>
        <li>Υπηρεσίες σχετιζόμενες με τις κατοικίες (0)
        </li>
        <li>Υπηρεσίες σχετιζόμενες με ακίνητα που δεν προορίζονται για κατοικία (3)
        <ul>
          <li>Υπηρεσίες διαχείρισης εμπορικών ιδιοκτησιών (0)
          </li>
          <li>Υπηρεσίες σχετιζόμενες με τη βιομηχανική ιδιοκτησία (0)
          </li>
          <li>Υπηρεσίες διαχείρισης έγγειας ιδιοκτησίας (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες σχετιζόμενες με ακίνητα που προορίζονται για κατοικία (1)
        <ul>
          <li>Υπηρεσίες διαχείρισης ιδρυμάτων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες ενοικίασης ή πώλησης έγγειας ιδιοκτησίας (2)
      <ul>
        <li>Υπηρεσίες ενοικίασης ή πώλησης ελεύθερων οικοπέδων (0)
        </li>
        <li>Υπηρεσίες ενοικίασης έγγειας ιδιοκτησίας (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες ενοικίασης ή πώλησης κτιρίων (1)
      <ul>
        <li>Υπηρεσίες ενοικίασης ή πώλησης κτιρίου προοριζόμενου για κατοικίες (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες ενοικίασης ή χρηματοδοτικής μίσθωσης ιδιόκτητων ακινήτων (2)
    <ul>
      <li>Υπηρεσίες ενοικίασης ή χρηματοδοτικής μίσθωσης ιδιόκτητων ακινήτων που δεν προορίζονται για κατοίκηση (0)
      </li>
      <li>Υπηρεσίες ενοικίασης ή χρηματοδοτικής μίσθωσης ιδιόκτητων ακινήτων που προορίζονται για κατοίκηση (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες που αφορούν ιδιόκτητα ακινήτα (16)
    <ul>
      <li>Υπηρεσίες ανάπτυξης ακινήτων (2)
      <ul>
        <li>Ανάπτυξη ακινήτων που δεν προορίζονται για κατοικίες (0)
        </li>
        <li>Ανάπτυξη ακινήτων για κατοικίες (0)
        </li>
      </ul>
      </li>
      <li>Αγοραπωλησία ακινήτων (11)
      <ul>
        <li>Αγοραπωλησία κτιρίων (2)
        <ul>
          <li>Υπηρεσίες πώλησης κτιρίων (0)
          </li>
          <li>Υπηρεσίες αγοράς κτιρίων (0)
          </li>
        </ul>
        </li>
        <li>Αγοραπωλησία οικοπέδων (4)
        <ul>
          <li>Υπηρεσίες πώλησης οικοπέδων (1)
          <ul>
            <li>Υπηρεσίες πώλησης ελεύθερων οικοπέδων (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες αγοράς οικοπέδων (1)
          <ul>
            <li>Υπηρεσίες αγοράς ελεύθερων οικοπέδων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Πώληση ακινήτων (2)
        <ul>
          <li>Πώληση ακινήτων που δεν προορίζονται για κατοικίες (0)
          </li>
          <li>Πώληση ακινήτων για κατοικίες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες ενοικίασης ιδιόκτητων ακινήτων (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες εκπαίδευσης και επιμόρφωσης (54)
  <ul>
    <li className='top-level'>Υπηρεσίες κατάρτισης (23)
    <ul>
      <li>Παράδοση μαθημάτων ξένων γλωσσών (0)
      </li>
      <li>Υπηρεσίες κατάρτισης εμπειρογνωμόνων (3)
      <ul>
        <li>Υπηρεσίες επιμόρφωσης προσωπικού (0)
        </li>
        <li>Υπηρεσίες εκπαίδευσης σκύλων (0)
        </li>
        <li>Υπηρεσίες εκμάθησης ιππασίας (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εκπαίδευσης στον τομέα της υγείας και των πρώτων βοηθειών (2)
      <ul>
        <li>Υπηρεσίες εκπαίδευσης στον τομέα των πρώτων βοηθειών (0)
        </li>
        <li>Υπηρεσίες εκπαίδευσης στον τομέα της υγείας (0)
        </li>
      </ul>
      </li>
      <li>Παροχή υπηρεσιών κατάρτισης (2)
      <ul>
        <li>Υπηρεσίες προγραμμάτων κατάρτισης (0)
        </li>
        <li>Σεμινάρια κατάρτισης (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εκπαίδευσης σε θέματα προσωπικής ανάπτυξης (0)
      </li>
      <li>Υπηρεσίες επαγγελματικής κατάρτισης (7)
      <ul>
        <li>Υπηρεσίες βιομηχανικής και τεχνικής εκπαίδευσης (2)
        <ul>
          <li>Υπηρεσίες βιομηχανικής εκπαίδευσης (0)
          </li>
          <li>Υπηρεσίες τεχνικής εκπαίδευσης (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εξοικείωσης και εκπαίδευσης στη χρήση υπολογιστών (2)
        <ul>
          <li>Μαθήματα πληροφορικής (0)
          </li>
          <li>Υπηρεσίες εκπαίδευσης στον τομέα της πληροφορικής (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εκπαίδευσης στον τομέα της διαχείρισης (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εκπαίδευσης σε θέματα ασφάλειας (0)
      </li>
      <li>Υπηρεσίες διδασκαλίας (0)
      </li>
      <li>Υπηρεσίες περιβαλλοντικής εκπαίδευσης (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες τριτοβάθμιας εκπαίδευσης (4)
    <ul>
      <li>Εκπαιδευτικές υπηρεσίες σε θέματα ασφάλειας (0)
      </li>
      <li>Ιατρικές εκπαιδευτικές υπηρεσίες (0)
      </li>
      <li>Υπηρεσίες εκπαίδευσης νέων (0)
      </li>
      <li>Ειδικές εκπαιδευτικές υπηρεσίες (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες επιμόρφωσης σε ζητήματα άμυνας και ασφάλειας (6)
    <ul>
      <li>Επιμόρφωση και προσομοίωση σε στρατιωτικά οχήματα (0)
      </li>
      <li>Επιμόρφωση και προσομοίωση σε πολεμικά σκάφη (0)
      </li>
      <li>Επιμόρφωση και προσομοίωση σε στρατιωτικά ηλεκτρονικά συστήματα (0)
      </li>
      <li>Επιμόρφωση και προσομοίωση σε αεροσκάφη, πυραύλους και διαστημόπλοια (0)
      </li>
      <li>Επιμόρφωση και προσομοίωση σε πυροβόλα όπλα και πυρομαχικά (0)
      </li>
      <li>Επιμόρφωση και προσομοίωση σε εξοπλισμό ασφαλείας (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες δευτεροβάθμιας εκπαίδευσης (3)
    <ul>
      <li>Υπηρεσίες τεχνικής και επαγγελματικής δευτεροβάθμιας εκπαίδευσης (2)
      <ul>
        <li>Υπηρεσίες δευτεροβάθμιας επαγγελματικής εκπαίδευσης (0)
        </li>
        <li>Υπηρεσίες δευτεροβάθμιας τεχνικής εκπαίδευσης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες πρωτοβάθμιας εκπαίδευσης (1)
    <ul>
      <li>Υπηρεσίες προσχολικής εκπαίδευσης (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες εκπαίδευσης ενηλίκων και άλλες εκπαιδευτικές υπηρεσίες (11)
    <ul>
      <li>Υπηρεσίες ηλεκτρονικής μάθησης (0)
      </li>
      <li>Διάφορες σχολικές υπηρεσίες (7)
      <ul>
        <li>Υπηρεσίες σχολών χειριστών αεροσκαφών (0)
        </li>
        <li>Υπηρεσίες σχολής οδηγών (2)
        <ul>
          <li>Μαθήματα οδήγησης (0)
          </li>
          <li>Υπηρεσίες εξέτασης νέων οδηγών αυτοκινήτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες σχολών ιστιοπλοΐας (0)
        </li>
        <li>Υπηρεσίες σχολών χιονοδρομίας (0)
        </li>
        <li>Υπηρεσίες σχολών καταδύσεων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εκπαίδευσης ενηλίκων σε πανεπιστημιακό επίπεδο (0)
      </li>
      <li>Λειτουργία εκπαιδευτικού κέντρου (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Δέρμα και υφάσματα, υλικά από πλαστικό και καουτσούκ (96)
  <ul>
    <li className='top-level'>Δέρματα (13)
    <ul>
      <li>Δέρματα άλλων ζώων, ανασυσταθέν δέρμα ή άλλα δέρματα (4)
      <ul>
        <li>Σύνθετα δέρματα (0)
        </li>
        <li>Δέρμα λουστρίνι (0)
        </li>
        <li>Δέρματα άλλων ζώων (0)
        </li>
        <li>Απομίμηση δέρματος (0)
        </li>
      </ul>
      </li>
      <li>Δέρματα από βοοειδή ή ιπποειδή (0)
      </li>
      <li>Δέρματα αιγοπροβάτων ή χοιροειδών (3)
      <ul>
        <li>Δέρματα προβάτων ή αρνιών (0)
        </li>
        <li>Δέρματα χοιροειδών (0)
        </li>
        <li>Δέρματα γιδιών ή κατσικιών (0)
        </li>
      </ul>
      </li>
      <li>Δέρμα σαμουά (0)
      </li>
      <li>Δερμάτινα είδη που χρησιμοποιούνται σε μηχανήματα ή άλλες μηχανικές συσκευές (0)
      </li>
      <li>Λουριά ρολογιών (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υλικά από καουτσούκ και πλαστικό (17)
    <ul>
      <li>Προϊόντα από καουτσούκ (9)
      <ul>
        <li>Αναγεννημένο καουτσούκ (0)
        </li>
        <li>Είδη από μη βουλκανισμένο καουτσούκ (0)
        </li>
        <li>Εσωτερικοί αεροθάλαμοι, πέλματα επισώτρων και εσωτερικές λωρίδες προστασίας από καουτσούκ (3)
        <ul>
          <li>Εσωτερικοί αεροθάλαμοι (0)
          </li>
          <li>Πέλματα επισώτρων (0)
          </li>
          <li>Εσωτερικές προστατευτικές λωρίδες επισώτρων (0)
          </li>
        </ul>
        </li>
        <li>Ελαστικοποιημένα υφάσματα (2)
        <ul>
          <li>Υφάσματα για νήματα ελαστικών (0)
          </li>
          <li>Κολλητική ταινία από ελαστικοποιημένα υφάσματα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Πλαστικά προϊόντα (6)
      <ul>
        <li>Προϊόντα πολυστυρολίου (2)
        <ul>
          <li>Πλάκες πολυστυρολίου (0)
          </li>
          <li>Φύλλα πολυστυρολίου (0)
          </li>
        </ul>
        </li>
        <li>Ρητίνες (2)
        <ul>
          <li>Εποξική ρητίνη (1)
          <ul>
            <li>Σωληνώσεις από εποξική ρητίνη (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υφαντικά νήματα και κλωστές (16)
    <ul>
      <li>Τεχνητές υφαντικές ίνες (0)
      </li>
      <li>Φυσικές υφαντικές ίνες (0)
      </li>
      <li>Συνθετικά νήματα ή κλωστές (4)
      <ul>
        <li>Συνθετικές κλωστές (2)
        <ul>
          <li>Συνθετική κλωστή ραψίματος (0)
          </li>
          <li>Συνθετικό νήμα πλεξίματος (0)
          </li>
        </ul>
        </li>
        <li>Συνθετικά νήματα (0)
        </li>
      </ul>
      </li>
      <li>Υφαντικά νήματα και κλωστές από φυσικές ίνες (8)
      <ul>
        <li>Βαμβακερά νήματα (0)
        </li>
        <li>Νήματα από λινάρι (0)
        </li>
        <li>Κλωστές και νήματα ραψίματος από φυσικές ίνες (2)
        <ul>
          <li>Νήματα για πλέξιμο (0)
          </li>
          <li>Κλωστή ραψίματος (0)
          </li>
        </ul>
        </li>
        <li>Μάλλινα νήματα (0)
        </li>
        <li>Μεταξωτά νήματα (0)
        </li>
        <li>Υφαντικά νήματα από φυτικές ίνες (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υφάσματα και συναφή είδη (30)
    <ul>
      <li>Ύφασμα (0)
      </li>
      <li>Μη υφαντά υφάσματα (0)
      </li>
      <li>Μάλλινα υφάσματα (0)
      </li>
      <li>Ειδικά υφάσματα (5)
      <ul>
        <li>Υφάσματα εσωτερικών επενδύσεων (για φόδρα) (0)
        </li>
        <li>Υφάσματα ταπετσαρίας (0)
        </li>
        <li>Υφάσματα για κουρτίνες (0)
        </li>
        <li>Χνούδι υφάσματος (0)
        </li>
        <li>Ύφασμα για πετσέτες (0)
        </li>
      </ul>
      </li>
      <li>Ζωικό μαλλί, γουνοδέρματα και δέρματα (3)
      <ul>
        <li>Μαλλί (0)
        </li>
        <li>Δέρματα πουλιών και φτερά (0)
        </li>
        <li>Δέρματα ζώων (0)
        </li>
      </ul>
      </li>
      <li>Υφαντά υφάσματα (10)
      <ul>
        <li>Συνθετικά υφαντά υφάσματα (1)
        <ul>
          <li>Υφαντά υφάσματα από ανάμεικτες ίνες (0)
          </li>
        </ul>
        </li>
        <li>Υφαντά βαμβακερά υφάσματα (7)
        <ul>
          <li>Χονδρό βαμβακερό ύφασμα (ντενίμ) (0)
          </li>
          <li>Λινάτσα (1)
          <ul>
            <li>Είδη από λινάτσα (0)
            </li>
          </ul>
          </li>
          <li>Ποπλίνα (0)
          </li>
          <li>Ύφασμα ενισχυτικών λωρίδων (1)
          <ul>
            <li>Ενισχυτικές λωρίδες (0)
            </li>
          </ul>
          </li>
          <li>Υφάσματα για μαξιλάρια, στρώματα κ.λπ. (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Πλεκτά υφάσματα ή υφάσματα κροσέ (3)
      <ul>
        <li>Πλεκτά υφάσματα (1)
        <ul>
          <li>Χνουδωτά υφάσματα (0)
          </li>
        </ul>
        </li>
        <li>Υφάσματα κροσέ (0)
        </li>
      </ul>
      </li>
      <li>Λινά υφάσματα (1)
      <ul>
        <li>Λινά είδη (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπολείμματα δέρματος, υφαντικών υλών, καουτσούκ και πλαστικού (4)
    <ul>
      <li>Υπολείμματα υφαντικών ειδών (0)
      </li>
      <li>Σάκοι και τσάντες αποβλήτων και απορριμμάτων από πολυαιθυλένιο (0)
      </li>
      <li>Υπολείμματα δέρματος (0)
      </li>
      <li>Υπολείμματα καουτσούκ (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Συνθετικό καουτσούκ και ίνες (10)
    <ul>
      <li>Τεχνητές ίνες (3)
      <ul>
        <li>Τεχνητό πλεγμένο νήμα (0)
        </li>
        <li>Νήμα από τεχνητές ίνες (0)
        </li>
        <li>Πολυπροπυλένιο (0)
        </li>
      </ul>
      </li>
      <li>Συνθετικές ίνες (4)
      <ul>
        <li>Συνθετικό μονόινο νήμα (0)
        </li>
        <li>Νήμα μεγάλης ανθεκτικότητας (0)
        </li>
        <li>Νήμα απλής πλέξης (0)
        </li>
        <li>Σχοινί από συνθετικές ίνες (0)
        </li>
      </ul>
      </li>
      <li>Συνθετικό καουτσούκ (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Συλλογή και καθαρισμός νερού (3)
  <ul>
    <li className='top-level'>Φυσικό νερό (2)
    <ul>
      <li>Μη πόσιμο νερό (0)
      </li>
      <li>Πόσιμο νερό (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Χημικά προϊόντα (291)
  <ul>
    <li className='top-level'>Πλαστικά σε πρωτογενείς μορφές (11)
    <ul>
      <li>Σιλικόνες σε πρωτογενείς μορφές (0)
      </li>
      <li>Πολυμερή του στυρολίου σε πρωτογενείς μορφές (0)
      </li>
      <li>Πολυμερή του προπυλενίου σε πρωτογενείς μορφές (0)
      </li>
      <li>Πολυαμίδια σε πρωτογενείς μορφές (0)
      </li>
      <li>Πολυμερή του αιθυλενίου σε πρωτογενείς μορφές (0)
      </li>
      <li>Πολυμερή του βινυλίου σε πρωτογενείς μορφές (2)
      <ul>
        <li>Πολυμερή του οξικού βινύλιου σε πρωτογενείς μορφές (0)
        </li>
        <li>Ακρυλικά πολυμερή σε πρωτογενείς μορφές (0)
        </li>
      </ul>
      </li>
      <li>Αμινορητίνες σε πρωτογενείς μορφές (0)
      </li>
      <li>Πολυεστέρες σε πρωτογενείς μορφές (0)
      </li>
      <li>Ρητίνες ουρίας σε πρωτογενείς μορφές (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Αέρια (18)
    <ul>
      <li>Βιομηχανικά αέρια (17)
      <ul>
        <li>Υδρογόνο, αργόν, ευγενή αέρια, άζωτο και οξυγόνο (9)
        <ul>
          <li>Άζωτο (0)
          </li>
          <li>Αργόν (0)
          </li>
          <li>Ευγενή αέρια (0)
          </li>
          <li>Ιατρικά αέρια (0)
          </li>
          <li>Ήλιο (0)
          </li>
          <li>Υδρογόνο (0)
          </li>
          <li>Οξυγόνο (0)
          </li>
          <li>Νέον (0)
          </li>
          <li>Υγροποιημένο άζωτο (0)
          </li>
        </ul>
        </li>
        <li>Ανόργανες οξυγονούχες ενώσεις (3)
        <ul>
          <li>Αέριες ανόργανες οξυγονούχες ενώσεις (0)
          </li>
          <li>Οξείδια του αζώτου (0)
          </li>
          <li>Διοξείδιο του άνθρακα (0)
          </li>
        </ul>
        </li>
        <li>Υγροποιημένος και πεπιεσμένος αέρας (2)
        <ul>
          <li>Υγροποιημένος αέρας (0)
          </li>
          <li>Πεπιεσμένος αέρας (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εκρηκτικά (11)
    <ul>
      <li>Έτοιμα εκρηκτικά (10)
      <ul>
        <li>Πυραγωγά φυτίλια, καψύλια, εναυστήρες και ηλεκτρικοί πυροκροτητές (0)
        </li>
        <li>Φωτοβολίδες σηματοδότησης, ρουκέτες βροχής, σήματα ομίχλης και είδη πυροτεχνίας (2)
        <ul>
          <li>Πυροτεχνήματα (0)
          </li>
          <li>Κροτίδες εκφοβισμού πτηνών (0)
          </li>
        </ul>
        </li>
        <li>Προωθητική πυρίτιδα (1)
        <ul>
          <li>Καύσιμα προπεργόλης (0)
          </li>
        </ul>
        </li>
        <li>Διάφορα εκρηκτικά (3)
        <ul>
          <li>ΤΝΤ (0)
          </li>
          <li>Νιτρογλυκερίνη (0)
          </li>
          <li>Δυναμίτιδα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Λιπάσματα και ενώσεις του αζώτου (21)
    <ul>
      <li>Φωσφορικά λιπάσματα (2)
      <ul>
        <li>Φωσφορικά χημικά λιπάσματα (0)
        </li>
        <li>Ανόργανα φωσφορικά λιπάσματα (0)
        </li>
      </ul>
      </li>
      <li>Ζωικά ή φυτικά λιπάσματα (0)
      </li>
      <li>Αγροχημικά προϊόντα (7)
      <ul>
        <li>Εντομοκτόνα (0)
        </li>
        <li>Απολυμαντικά (0)
        </li>
        <li>Ζιζανιοκτόνα (0)
        </li>
        <li>Παρασιτοκτόνα (0)
        </li>
        <li>Μυοκτόνα (0)
        </li>
        <li>Ρυθμιστές της ανάπτυξης των φυτών (0)
        </li>
        <li>Μυκητοκτόνα (0)
        </li>
      </ul>
      </li>
      <li>Διάφορα λιπάσματα (0)
      </li>
      <li>Αζωτούχα λιπάσματα (7)
      <ul>
        <li>Σουλφονιτρικά οξέα (0)
        </li>
        <li>Αμμωνία (3)
        <ul>
          <li>Χλωριούχο αμμώνιο (0)
          </li>
          <li>Αμμωνία σε υγρή μορφή (0)
          </li>
          <li>Θειικό αμμώνιο (0)
          </li>
        </ul>
        </li>
        <li>Νιτρικό οξύ (1)
        <ul>
          <li>Νιτρικό νάτριο (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Βασικές ανόργανες και οργανικές χημικές ουσίες (147)
    <ul>
      <li>Βασικές οργανικές χημικές ουσίες (63)
      <ul>
        <li>Αλκοόλες, φαινόλες, φαινολαλκοόλες και τα αλογονωμένα, θειωμένα, νιτρωμένα, ή νιτριδωμένα παράγωγά τους· βιομηχανικές λιπαρές αλκοόλες (10)
        <ul>
          <li>Οινόπνευμα (1)
          <ul>
            <li>Αιθυλική αλκοόλη (0)
            </li>
          </ul>
          </li>
          <li>Διόλες, πολυαλκοόλες, κυκλικές αλκοόλες και παράγωγά τους (2)
          <ul>
            <li>Αιθυλενογλυκόλη (0)
            </li>
            <li>Παράγωγα αλκοόλης (0)
            </li>
          </ul>
          </li>
          <li>Φαινόλες· φαινολαλκοόλες και παράγωγα φαινολών (0)
          </li>
          <li>Μονοσθενείς αλκοόλες (2)
          <ul>
            <li>Μεθανόλη (0)
            </li>
            <li>Αιθανόλη (0)
            </li>
          </ul>
          </li>
          <li>Βιομηχανικές λιπαρές αλκοόλες (0)
          </li>
        </ul>
        </li>
        <li>Αιθέρες, οργανικά υπεροξείδια, εποξείδια, ακετάλες και ημιακετάλες και οργανικές ενώσεις (5)
        <ul>
          <li>Ενώσεις με αλδεϋδική ρίζα (0)
          </li>
          <li>Οργανικά υπεροξείδια (2)
          <ul>
            <li>Αιθυλενοξείδιο (0)
            </li>
            <li>Αιθέρες (0)
            </li>
          </ul>
          </li>
          <li>Ενώσεις με ρίζα κετόνης και κινόνης (0)
          </li>
        </ul>
        </li>
        <li>Βιομηχανικά μονοκαρβοξυλικά λιπαρά οξέα (8)
        <ul>
          <li>Βιομηχανικά όξινα έλαια προερχόμενα από διύλιση (0)
          </li>
          <li>Αρωματικά πολυκαρβοξυλικά και καρβοξυλικά οξέα (0)
          </li>
          <li>Ακόρεστα μονοκαρβοξυλικά οξέα και ενώσεις τους (2)
          <ul>
            <li>Εστέρες του ακρυλικού οξέος (0)
            </li>
            <li>Εστέρες του μεθακρυλικού οξέος (0)
            </li>
          </ul>
          </li>
          <li>Καρβοξυλικά οξέα (2)
          <ul>
            <li>Υπεροξικό οξύ (0)
            </li>
            <li>Οξικό οξύ (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Οργανικές ενώσεις με αζωτούχο ρίζα (4)
        <ul>
          <li>Ουρεΐνες (0)
          </li>
          <li>Ενώσεις με αμινική ρίζα (0)
          </li>
          <li>Ενώσεις με αζωτούχες ρίζες (0)
          </li>
          <li>Οξυγονούχες αμινικές ενώσεις (0)
          </li>
        </ul>
        </li>
        <li>Υδρογονάνθρακες (20)
        <ul>
          <li>Κεκορεσμένοι υδρογονάνθρακες (7)
          <ul>
            <li>Κεκορεσμένοι κυκλικοί υδρογονάνθρακες (0)
            </li>
            <li>Κεκορεσμένοι άκυκλοι υδρογονάνθρακες (5)
            <ul>
              <li>Μεθάνιο (0)
              </li>
              <li>Ακετυλένιο (0)
              </li>
              <li>Βουτένιο (0)
              </li>
              <li>Αιθυλένιο (0)
              </li>
              <li>Προπένιο (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Χλωριωμένα παράγωγα άκυκλων υδρογονανθράκων (2)
          <ul>
            <li>Τετραχλωράνθρακας (0)
            </li>
            <li>Τετραχλωραιθυλένιο (0)
            </li>
          </ul>
          </li>
          <li>Ακόρεστοι υδρογονάνθρακες (8)
          <ul>
            <li>Ακόρεστοι άκυκλοι υδρογονάνθρακες (0)
            </li>
            <li>Ακόρεστοι κυκλικοί υδρογονάνθρακες (6)
            <ul>
              <li>Αιθυλοβενζόλιο (0)
              </li>
              <li>M-ξυλένια (0)
              </li>
              <li>Ο-ξυλένια (0)
              </li>
              <li>Στυρένιο (0)
              </li>
              <li>Βενζένιο (0)
              </li>
              <li>Τολουένιο (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Οργανικές ενώσεις του θείου (0)
        </li>
        <li>Διάφορα χημικά προϊόντα (9)
        <ul>
          <li>Προϊόντα ρητίνης (0)
          </li>
          <li>Υγρά κατάλοιπα από την κατεργασία του ξυλοπολτού (0)
          </li>
          <li>Παράγωγα φυτικών προϊόντων βαφής (0)
          </li>
          <li>Ξυλάνθρακες (0)
          </li>
          <li>Έλαια και άλλα προϊόντα της απόσταξης της λιθανθρακόπισσας σε υψηλή θερμοκρασία, πίσσα και πισσάσφαλτος (4)
          <ul>
            <li>Πισσάσφαλτος (0)
            </li>
            <li>Πίσσα (0)
            </li>
            <li>Λιθανθρακόπισσα (1)
            <ul>
              <li>Κρεόζωτο (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Βασικές ανόργανες χημικές ουσίες (82)
      <ul>
        <li>Ανόργανες χημικές ενώσεις (12)
        <ul>
          <li>Βαρύ ύδωρ, άλλα ισότοπα και ενώσεις τους (0)
          </li>
          <li>Πυριτικά άλατα (1)
          <ul>
            <li>Πυριτικό νάτριο (0)
            </li>
          </ul>
          </li>
          <li>Υπεροξείδιο του υδρογόνου (0)
          </li>
          <li>Ενώσεις μετάλλων σπανίων γαιών (0)
          </li>
          <li>Πιεζοηλεκτρικός χαλαζίας (0)
          </li>
          <li>Βορικά και υπερβορικά άλατα (0)
          </li>
          <li>Κυανίδια, οξείδια των κυανιδίων, κροτικά, κυανικά, πυριτικά, βορικά και υπερβορικά άλατα, άλατα ανόργανων οξέων (4)
          <ul>
            <li>Κυανίδια (0)
            </li>
            <li>Κροτικά άλατα (0)
            </li>
            <li>Κυανικά άλατα (0)
            </li>
            <li>Κυανιούχα οξείδια (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Άλατα μετάλλων (2)
        <ul>
          <li>Άλατα οξομεταλλικών οξέων (0)
          </li>
          <li>Υπερμαγγανικό κάλιο (0)
          </li>
        </ul>
        </li>
        <li>Συνθετικοί λίθοι (2)
        <ul>
          <li>Συνθετικοί ημιπολύτιμοι λίθοι (0)
          </li>
          <li>Συνθετικοί πολύτιμοι λίθοι (0)
          </li>
        </ul>
        </li>
        <li>Θειούχα και θειικά άλατα· νιτρικά, φωσφορικά και ανθρακικά άλατα (18)
        <ul>
          <li>Φωσφινικά, φωσφονικά, φωσφορικά και πολυφωσφορικά άλατα (2)
          <ul>
            <li>Εξαμεταφωσφορικό νάτριο (0)
            </li>
            <li>Φωσφορικά άλατα (0)
            </li>
          </ul>
          </li>
          <li>Νιτρικά άλατα (0)
          </li>
          <li>Θειούχα, θειώδη και θειικά άλατα (10)
          <ul>
            <li>Θειικά άλατα (6)
            <ul>
              <li>Θειικό άλας σιδήρου (0)
              </li>
              <li>Θειικό άλας χαλκού (0)
              </li>
              <li>Θειικά άλατα νατρίου (0)
              </li>
              <li>Θειικό άλας αργιλίου (0)
              </li>
              <li>Θειοθειικό νάτριο (0)
              </li>
              <li>Θειικός σίδηρος (0)
              </li>
            </ul>
            </li>
            <li>Διάφορα θειούχα άλατα (2)
            <ul>
              <li>Πολυθειούχα άλατα (0)
              </li>
              <li>Υδρόθειο (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Ανθρακικά άλατα (2)
          <ul>
            <li>Διττανθρακικό νάτριο (0)
            </li>
            <li>Ανθρακικό νάτριο (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Απεσταγμένο νερό (0)
        </li>
        <li>Χημικά στοιχεία, ανόργανα οξέα και ενώσεις (31)
        <ul>
          <li>Μεταλλοειδή (8)
          <ul>
            <li>Εξευγενισμένο θείο (0)
            </li>
            <li>Φωσφίδια (0)
            </li>
            <li>Πυριτίδια (0)
            </li>
            <li>Καρβίδια (0)
            </li>
            <li>Νιτρίδια (0)
            </li>
            <li>Βορίδια (0)
            </li>
            <li>Υδρίδια (0)
            </li>
            <li>Αζωτίδια (0)
            </li>
          </ul>
          </li>
          <li>Άνθρακας (0)
          </li>
          <li>Υδροξείδια ως βασικές ανόργανες χημικές ουσίες (5)
          <ul>
            <li>Οξείδια μετάλλων (1)
            <ul>
              <li>Σιδηροπυρίτες και οξείδια του σιδήρου (0)
              </li>
            </ul>
            </li>
            <li>Υδροξείδιο του νατρίου (2)
            <ul>
              <li>Υγρή σόδα (0)
              </li>
              <li>Καυστική σόδα (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Ενώσεις θείου (0)
          </li>
          <li>Μέταλλα αλκαλίων (1)
          <ul>
            <li>Υδράργυρος (0)
            </li>
          </ul>
          </li>
          <li>Χλώριο (0)
          </li>
          <li>Αλογονούχες ή θειούχες ενώσεις (0)
          </li>
          <li>Υδροχλώριο, ανόργανα οξέα, διοξείδιο του πυριτίου και διοξείδιο του θείου (8)
          <ul>
            <li>Φωσφορικό οξύ (0)
            </li>
            <li>Διοξείδιο του θείου (0)
            </li>
            <li>Εξαφθοροπυριτικό οξύ (0)
            </li>
            <li>Πολυφωσφορικά οξέα (0)
            </li>
            <li>Ανόργανα οξέα (1)
            <ul>
              <li>Θειικό οξύ (0)
              </li>
            </ul>
            </li>
            <li>Πυρίτιο (0)
            </li>
            <li>Υδροχλώριο (0)
            </li>
          </ul>
          </li>
          <li>Θείο (0)
          </li>
        </ul>
        </li>
        <li>Αλογονούχα άλατα μετάλλων· υποχλωριώδη, χλωρικά και υπερχλωρικά (10)
        <ul>
          <li>Υποχλωριώδη και χλωρικά άλατα (2)
          <ul>
            <li>Χλωριώδες νάτριο (0)
            </li>
            <li>Υποχλωριώδες νάτριο (0)
            </li>
          </ul>
          </li>
          <li>Αλογονούχα άλατα μετάλλων (6)
          <ul>
            <li>Εξαφθοροπυριτικό νάτριο (0)
            </li>
            <li>Χλωρίδια (3)
            <ul>
              <li>Χλωριούχο αργίλιο (0)
              </li>
              <li>Χλωριούχος σίδηρος (0)
              </li>
              <li>Πολυχλωριούχο αργίλιο (0)
              </li>
            </ul>
            </li>
            <li>Χλωροϋδρικό αργίλιο (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εκλεπτυσμένα και ποικίλα χημικά προϊόντα (52)
    <ul>
      <li>Αιθέρια έλαια (0)
      </li>
      <li>Χημικά παρασκευάσματα (32)
      <ul>
        <li>Χημικά πρόσθετα (2)
        <ul>
          <li>Παρασκευασμένες συνδετικές ύλες για μήτρες ή πυρήνες χυτηρίων (0)
          </li>
          <li>Συνθετικές πρόσθετες ύλες για τσιμεντοκονιάματα, ασβεστοκονιάματα ή σκυροδέματα (0)
          </li>
        </ul>
        </li>
        <li>Γράσα και λιπαντικά (12)
        <ul>
          <li>Πρόσθετα για ορυκτέλαια (3)
          <ul>
            <li>Μέσα γόμωσης πυροσβεστήρων (0)
            </li>
            <li>Υλικά γόμωσης πυροσβεστήρων (0)
            </li>
            <li>Σκόνη γόμωσης πυροσβεστήρων (0)
            </li>
          </ul>
          </li>
          <li>Υδραυλικά υγρά (2)
          <ul>
            <li>Αποπαγωτικά υγρά (1)
            <ul>
              <li>Αντιψυκτικά παρασκευάσματα (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Χημικώς τροποποιημένα λίπη και έλαια (0)
          </li>
          <li>Λιπαντικά (3)
          <ul>
            <li>Υγρά τρυπανιών (0)
            </li>
            <li>Λάσπη γεωτρήσεων (0)
            </li>
            <li>Λίπος σιλικόνης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Πολτοί προπλασμάτων (1)
        <ul>
          <li>Οδοντιατρικός κηρός (0)
          </li>
        </ul>
        </li>
        <li>Υλικά φινιρίσματος (0)
        </li>
        <li>Χημικά προϊόντα για τη βιομηχανία πετρελαίου και φυσικού αερίου (4)
        <ul>
          <li>Χημικά ενδοδιατρητικών εργασιών (0)
          </li>
          <li>Χημικές ουσίες ιλύος (0)
          </li>
          <li>Φύσιγγες γέλης για την εξουδετέρωση εκρηκτικών (0)
          </li>
          <li>Κροκιδωτικά μέσα (0)
          </li>
        </ul>
        </li>
        <li>Πεπτόνες και πρωτεϊνικές ουσίες (0)
        </li>
        <li>Ενεργός άνθρακας (2)
        <ul>
          <li>Πρωτογενής ενεργός άνθρακας (0)
          </li>
          <li>Αναγεννημένος ενεργός άνθρακας (0)
          </li>
        </ul>
        </li>
        <li>Χημικές τουαλέτες (0)
        </li>
        <li>Αεροζόλ και χημικά στοιχεία σε μορφή δισκίου (2)
        <ul>
          <li>Αεροζόλ (0)
          </li>
          <li>Χημικά στοιχεία σε μορφή δισκίου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Φωτογραφικά χημικά υλικά (8)
      <ul>
        <li>Φωτογραφικές πλάκες και φιλμ (7)
        <ul>
          <li>Ευαισθητοποιημένα γαλακτώματα για φωτογραφική χρήση (6)
          <ul>
            <li>Ενισχυτικά έντασης της εικόνας (0)
            </li>
            <li>Υλικά στερέωσης ακτινογραφιών (0)
            </li>
            <li>Μέσα εργαστηριακής καλλιέργειας (0)
            </li>
            <li>Προϊόντα εμφάνισης ακτινογραφιών (0)
            </li>
            <li>Προϊόντα εμφάνισης φωτογραφιών (0)
            </li>
            <li>Υλικά στερέωσης φωτογραφιών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Κόλλες (2)
      <ul>
        <li>Ζελατίνες (1)
        <ul>
          <li>Συγκολλητικά μέσα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ποικίλα χημικά προϊόντα (5)
      <ul>
        <li>Ένζυμα (0)
        </li>
        <li>Υγρά ψυγείου αυτοκινήτων (0)
        </li>
        <li>Γλυκερίνη (0)
        </li>
        <li>Αντιδιαβρωτικά προϊόντα (0)
        </li>
        <li>Χημικά επεξεργασίας νερού (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Χρώματα και χρωστικές ουσίες (24)
    <ul>
      <li>Οξείδια, υπεροξείδια και υδροξείδια (17)
      <ul>
        <li>Ένυδρη άσβεστος (0)
        </li>
        <li>Οξείδια και υδροξείδια χρωμίου, μαγγανίου, μαγνησίου, μολύβδου και χαλκού (11)
        <ul>
          <li>Οξείδιο του μαγνησίου (0)
          </li>
          <li>Υδροξείδια για βαφές και χρωστικές ουσίες (5)
          <ul>
            <li>Υδροξείδιο του μολύβδου (0)
            </li>
            <li>Υδροξείδιο του μαγγανίου (0)
            </li>
            <li>Υδροξείδιο του μαγνησίου (0)
            </li>
            <li>Υδροξείδιο του χαλκού (0)
            </li>
            <li>Υδροξείδιο του χρωμίου (0)
            </li>
          </ul>
          </li>
          <li>Οξείδιο του μαγγανίου (0)
          </li>
          <li>Οξείδιο του μολύβδου (0)
          </li>
          <li>Οξείδιο του χρωμίου (0)
          </li>
          <li>Οξείδιο του χαλκού (0)
          </li>
        </ul>
        </li>
        <li>Οξείδιο και υπεροξείδιο του ψευδαργύρου· οξείδιο του τιτανίου, χρώματα και χρωστικές ουσίες (3)
        <ul>
          <li>Υπεροξείδιο του ψευδαργύρου (0)
          </li>
          <li>Οξείδιο του ψευδαργύρου (0)
          </li>
          <li>Οξείδιο του τιτανίου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Δεψικά και βαφικά εκχυλίσματα, τανίνες και χρωστικές ύλες (5)
      <ul>
        <li>Τανίνες (0)
        </li>
        <li>Χρωστικά εκχυλίσματα (0)
        </li>
        <li>Δεψικά εκχυλίσματα (0)
        </li>
        <li>Χρωστικές ύλες (0)
        </li>
        <li>Παρασκευάσματα τανίνης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Μηχανήματα για εξοπλισμό ορυχείων, λατομείων, κατασκευών (126)
  <ul>
    <li className='top-level'>Μηχανήματα και εξοπλισμός κατασκευαστικών έργων (26)
    <ul>
      <li>Μηχανήματα δομικών έργων (13)
      <ul>
        <li>Εκχιονιστήρες και φυσητήρες χιονιού (2)
        <ul>
          <li>Εκχιονιστήρες (0)
          </li>
          <li>Φυσητήρες χιονιού (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα δόνησης και συμπύκνωσης (0)
        </li>
        <li>Μηχανήματα έμπηξης πασσάλων (0)
        </li>
        <li>Μηχανήματα τοποθέτησης καλωδίων (0)
        </li>
        <li>Μηχανήματα εξαγωγής πασσάλων (0)
        </li>
        <li>Μηχανές διάστρωσης οδών (5)
        <ul>
          <li>Αυτοκινούμενοι κύλινδροι διάστρωσης οδών (0)
          </li>
          <li>Αυτοκινούμενες μηχανές διάστρωσης χαλίκων (0)
          </li>
          <li>Ισοπεδωτικά μηχανήματα (0)
          </li>
          <li>Μηχανικοί οδοστρωτήρες (0)
          </li>
          <li>Μηχανήματα οδοστρωσίας (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός κατασκευαστικών έργων (11)
      <ul>
        <li>Εξοπλισμός άρδευσης (0)
        </li>
        <li>Συγκροτήματα εξοπλισμού (0)
        </li>
        <li>Προκατασκευασμένος εξοπλισμός (0)
        </li>
        <li>Εξοπλισμός αποστράγγισης (1)
        <ul>
          <li>Εξοπλισμός πισίνας (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός κρεμαστής γέφυρας (0)
        </li>
        <li>Εξοπλισμός πάρκων και παιδικής χαράς (1)
        <ul>
          <li>Εξοπλισμός συντήρησης υπαίθριων χώρων (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός αποσυναρμολόγησης (0)
        </li>
        <li>Υδραυλικές εγκαταστάσεις (1)
        <ul>
          <li>Εξοπλισμός υδραυλικών εγκαταστάσεων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μηχανήματα χωματουργικών εργασιών και εκσκαφών και συναφή μέρη (15)
    <ul>
      <li>Αυτοκινούμενοι πτυοφόροι φορτωτές πρόσθιας φόρτωσης (2)
      <ul>
        <li>Αυτοκινούμενοι πτυοφόροι φορτωτές πρόσθιας φόρτωσης με ανεστραμμένο πτύο (0)
        </li>
        <li>Αυτοκινούμενοι πτυοφόροι φορτωτές πρόσθιας φόρτωσης χωρίς ανεστραμμένο πτύο (0)
        </li>
      </ul>
      </li>
      <li>Αυτοκινούμενοι ισοπεδωτήρες και ομαλυντήρες (1)
      <ul>
        <li>Αυτοκινούμενοι ισοπεδωτήρες (0)
        </li>
      </ul>
      </li>
      <li>Αυτοκινούμενοι αποξεστήρες (0)
      </li>
      <li>Αυτοκινούμενα μηχανικά πτύα, εκσκαφείς, πτυοφόροι φορτωτές και αυτοκινούμενα μηχανήματα ορυχείου (4)
      <ul>
        <li>Αυτοκινούμενα μηχανικά πτύα (1)
        <ul>
          <li>Αυτοκινούμενοι πτυοφόροι φορτωτές (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα εκσκαφής (1)
        <ul>
          <li>Αυτοκινούμενοι εκσκαφείς (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μηχανήματα χωματουργικών εργασιών (2)
      <ul>
        <li>Αυτοκινούμενοι προωθητήρες γαιών (0)
        </li>
        <li>Αρθρωτοί προωθητήρες γαιών (0)
        </li>
      </ul>
      </li>
      <li>Αυτοκινούμενοι οδοστρωτήρες (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Εξοπλισμός συνεργείου (6)
    <ul>
      <li>Εξοπλισμός σιδηρουργού (0)
      </li>
      <li>Εξοπλισμός κατεργασίας ξύλου (2)
      <ul>
        <li>Μηχανές προσάμμωσης (0)
        </li>
        <li>Εξοπλισμός πριονίσματος (0)
        </li>
      </ul>
      </li>
      <li>Ηλεκτροκίνητα εργαλεία (0)
      </li>
      <li>Εξοπλισμός υποδηματοποιίας (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Μέρη μηχανημάτων ορυχείων, λατομείων και κατασκευών (25)
    <ul>
      <li>Μέρη μηχανημάτων εκσκαφών (0)
      </li>
      <li>Μέρη μηχανημάτων διάνοιξης φρεάτων (0)
      </li>
      <li>Μέρη μηχανημάτων γεώτρησης (21)
      <ul>
        <li>Μέρη για διατρητικά μηχανήματα φρεάτων (7)
        <ul>
          <li>Αιχμές βιομηχανικών τρυπάνων (0)
          </li>
          <li>Εξοπλισμός σύνδεσης φρεατίων (0)
          </li>
          <li>Εξοπλισμός απαγωγής λάσπης (0)
          </li>
          <li>Σιδερένιοι εργάτες (0)
          </li>
          <li>Περιστρεφόμενες τράπεζες (0)
          </li>
          <li>Στηρίγματα γεφυρών (0)
          </li>
          <li>Κρουστικά γεωτρύπανα (0)
          </li>
        </ul>
        </li>
        <li>Μέρη μηχανών τεμαχισμού άνθρακα ή βράχων (2)
        <ul>
          <li>Μέρη μηχανών τεμαχισμού άνθρακα (0)
          </li>
          <li>Μέρη μηχανών τεμαχισμού βράχων (0)
          </li>
        </ul>
        </li>
        <li>Μέρη μηχανών διάνοιξης σηράγγων (0)
        </li>
        <li>Μέρη μηχανημάτων εξόρυξης από φρεάτια (8)
        <ul>
          <li>Αναρτήρες περιβλημάτων (0)
          </li>
          <li>Εξοπλισμός συγκράτησης κατακόρυφων σωλήνων παραγωγής (0)
          </li>
          <li>Συστήματα ελέγχου κεφαλών φρέατος (0)
          </li>
          <li>Εξοπλισμός φρέατος (0)
          </li>
          <li>Συστήματα ανύψωσης γεωτρύπανου (0)
          </li>
          <li>Εξοπλισμός ανάρτησης χιτωνίων (0)
          </li>
          <li>Τανυστήρες κατακόρυφων σωλήνων παραγωγής (0)
          </li>
          <li>Κεφαλές φρέατος (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μέρη μηχανημάτων για την επεξεργασία ορυκτών (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Εξοπλισμός ορυχείων (33)
    <ul>
      <li>Μηχανήματα κοπής άνθρακα ή βράχων και μηχανήματα διάνοιξης σηράγγων, μηχανήματα γεώτρησης και διάνοιξης φρεάτων (13)
      <ul>
        <li>Μηχανήματα διάνοιξης σηράγγων (0)
        </li>
        <li>Μηχανήματα διάτρησης (2)
        <ul>
          <li>Σύστημα διάνοιξης σηράγγων (0)
          </li>
          <li>Μηχανήματα διάτρησης βράχων (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα διάνοιξης φρεάτων (0)
        </li>
        <li>Μηχανήματα κοπής άνθρακα ή βράχων (0)
        </li>
        <li>Διατρητικά μηχανήματα διάνοιξης φρεάτων (6)
        <ul>
          <li>Εξοπλισμός για την ολοκλήρωση γεωτρήσεων (0)
          </li>
          <li>Εξοπλισμός δοκιμών γεωτρήσεων (0)
          </li>
          <li>Εξοπλισμός επεμβάσεων σε γεωτρήσεις (0)
          </li>
          <li>Εργαλεία τοποθέτησης σωλήνωσης γεώτρησης σε κεφαλές φρεατίου (0)
          </li>
          <li>Αποφρακτήρες ασφαλείας (BOP) (0)
          </li>
          <li>Συνδετήρες κατακόρυφων σωλήνων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Κινητά υδραυλικά στηρίγματα στέγης ορυχείων (0)
      </li>
      <li>Εξοπλισμός γεώτρησης (17)
      <ul>
        <li>Εξοπλισμός γεώτρησης βάθους (0)
        </li>
        <li>Μηχανήματα εγκατάστασης άντλησης πετρελαίου (1)
        <ul>
          <li>Βυθιζόμενες αντλίες (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός εξέδρας παραγωγής πετρελαίου (2)
        <ul>
          <li>Εξαρτήματα υποβάθρων κινούμενων επί σιδηροτροχιάς (0)
          </li>
          <li>Μονάδες υποβάθρων κινούμενων επί σιδηροτροχιάς (0)
          </li>
        </ul>
        </li>
        <li>Υποθαλάσσιος εξοπλισμός (1)
        <ul>
          <li>Υποθαλάσσια συστήματα ελέγχου (0)
          </li>
        </ul>
        </li>
        <li>Εξέδρες παραγωγής ανοικτής θαλάσσης (2)
        <ul>
          <li>Εξοπλισμός ανοικτής θαλάσσης (0)
          </li>
          <li>Μονάδα γεώτρησης ανοικτής θαλάσσης (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός πετρελαιοπηγών (5)
        <ul>
          <li>Γεωτρύπανο (0)
          </li>
          <li>Τρυπάνια (0)
          </li>
          <li>Μηχανήματα γεωτρήσεων (0)
          </li>
          <li>Εξοπλισμός αγωγών (0)
          </li>
          <li>Αναρτήρες χιτωνίων αγωγών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μηχανήματα μεταλλουργίας και συναφή μέρη αυτών (4)
    <ul>
      <li>Μηχανήματα κυλίνδρωσης μετάλλων (1)
      <ul>
        <li>Μέρη μηχανημάτων κυλίνδρωσης μετάλλων (0)
        </li>
      </ul>
      </li>
      <li>Μηχανήματα χύτευσης (1)
      <ul>
        <li>Μέρη μηχανημάτων χύτευσης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Όχημα με ερπύστριες (0)
    </li>
    <li className='top-level'>Μηχανήματα επεξεργασίας μεταλλευμάτων και μηχανήματα διαμόρφωσης μητρών χυτηρίων (9)
    <ul>
      <li>Μηχανήματα κατασκευής μητρών χυτηρίου (0)
      </li>
      <li>Μηχανήματα επεξεργασίας μεταλλευμάτων (7)
      <ul>
        <li>Μηχανήματα ανάμειξης αμμοχάλικου με πίσσα (0)
        </li>
        <li>Μηχανές εκτριβής (1)
        <ul>
          <li>Μύλοι κονιοποίησης άνθρακα (0)
          </li>
        </ul>
        </li>
        <li>Μηχανές ανάμειξης σκυροδέματος ή κονιαμάτων (1)
        <ul>
          <li>Μηχανές ανάμειξης τσιμέντου (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα διαλογής και κοσκινίσματος (0)
        </li>
        <li>Μήτρες χυτηρίου (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Δομές και υλικά κατασκευαστικών έργων· βοηθήματα κατασκευαστικών έργων (εκτός από ηλεκτρικές συσκευές) (538)
  <ul>
    <li className='top-level'>Εργαλεία, κλειδαριές, κλειδιά, μεντεσέδες, συνδετήρες, αλυσίδες και ελατήρια (74)
    <ul>
      <li>Συνδετήρες (16)
      <ul>
        <li>Μη κοχλιωτοί συνδετήρες (4)
        <ul>
          <li>Ασφαλιστικές περόνες (0)
          </li>
          <li>Ροδέλες (0)
          </li>
          <li>Αμφιδέτες από χάλυβα (0)
          </li>
          <li>Πριτσίνια (0)
          </li>
        </ul>
        </li>
        <li>Συνδετήρες από χαλκό (0)
        </li>
        <li>Κοχλιωτοί συνδετήρες (9)
        <ul>
          <li>Περικόχλια (0)
          </li>
          <li>Αυτοκοχλιούμενοι κοχλίες (0)
          </li>
          <li>Ξυλόβιδες (0)
          </li>
          <li>Κοχλιωτά είδη από σίδηρο ή χάλυβα (0)
          </li>
          <li>Σύστημα φλαντζωτών συνδέσμων (2)
          <ul>
            <li>Καρόβιδες και βίδες (0)
            </li>
            <li>Μπουλόνια και βίδες (0)
            </li>
          </ul>
          </li>
          <li>Παξιμάδια ή ροδέλες για βίδες (0)
          </li>
          <li>Μπουλόνια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Κλειδαριές, κλειδιά και μεντεσέδες (17)
      <ul>
        <li>Μεντεσέδες, συνδέσεις και εξαρτήματα (3)
        <ul>
          <li>Μεντεσέδες (0)
          </li>
          <li>Διατάξεις σύνδεσης (0)
          </li>
          <li>Εξαρτήματα (0)
          </li>
        </ul>
        </li>
        <li>Πόρπες, μέρη κλειδαριών και κλειδιά (4)
        <ul>
          <li>Πόρπες (0)
          </li>
          <li>Κλειδιά (0)
          </li>
          <li>Μέρη λουκέτων (0)
          </li>
          <li>Μέρη κλειδαριών (0)
          </li>
        </ul>
        </li>
        <li>Διάφορα λουκέτα και κλειδαριές (7)
        <ul>
          <li>Κλειδαριές (4)
          <ul>
            <li>Κλειδαριές για πόρτες (0)
            </li>
            <li>Ενισχυμένες κλειδαριές ασφαλείας (0)
            </li>
            <li>Ηλεκτρονικές κλειδαριές ασφαλείας (0)
            </li>
            <li>Κλειδαριές για έπιπλα (0)
            </li>
          </ul>
          </li>
          <li>Λουκέτα και αλυσίδες (1)
          <ul>
            <li>Λουκέτα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ελατήρια (0)
      </li>
      <li>Αλυσίδες (2)
      <ul>
        <li>Αρθρωτές αλυσίδες (0)
        </li>
        <li>Μέρη αλυσίδας (0)
        </li>
      </ul>
      </li>
      <li>Εργαλεία (34)
      <ul>
        <li>Ποδοκίνητα εργαλεία (0)
        </li>
        <li>Διάφορα εργαλεία χειρός (15)
        <ul>
          <li>Πένσες (1)
          <ul>
            <li>Σφιγκτήρες (0)
            </li>
          </ul>
          </li>
          <li>Εργαλεία έργων οδοποιίας (1)
          <ul>
            <li>Αιχμηρά εργαλεία διάτρησης επιφανειών οδοστρώματος (0)
            </li>
          </ul>
          </li>
          <li>Σμίλες (0)
          </li>
          <li>Λίμες για μέταλλα ή λίμες για ξύλα (0)
          </li>
          <li>Κατσαβίδια (0)
          </li>
          <li>Σφυριά (0)
          </li>
          <li>Λαβές σύρματος (0)
          </li>
          <li>Αιχμές τρυπανιών, κατσαβιδιών και άλλα εξαρτήματα (4)
          <ul>
            <li>Εργαλειοφορείς (0)
            </li>
            <li>Αιχμές κατσαβιδιών (0)
            </li>
            <li>Εργαλειοθήκες (0)
            </li>
            <li>Αιχμές τρυπανιών (0)
            </li>
          </ul>
          </li>
          <li>Ρυθμιζόμενα κλειδιά (0)
          </li>
        </ul>
        </li>
        <li>Εργαλεία χειρός και μέρη εργαλείων (2)
        <ul>
          <li>Μέρη εργαλείων (0)
          </li>
          <li>Λαβές εργαλείων (0)
          </li>
        </ul>
        </li>
        <li>Εργαλεία χειρός (13)
        <ul>
          <li>Χειροπρίονα (1)
          <ul>
            <li>Λεπίδες για χειροπρίονα (0)
            </li>
          </ul>
          </li>
          <li>Τσάπες και φτυάρια (2)
          <ul>
            <li>Τσάπες (0)
            </li>
            <li>Φτυάρια (0)
            </li>
          </ul>
          </li>
          <li>Αξίνες, σκαπάνες, σκαλιστήρια, τσουγκράνες και τσουγκράνες για την εκκαθάριση παραλιών (5)
          <ul>
            <li>Σκαπάνες (0)
            </li>
            <li>Τσουγκράνες (1)
            <ul>
              <li>Τσουγκράνες για την εκκαθάριση παραλιών (0)
              </li>
            </ul>
            </li>
            <li>Σκαλιστήρια (0)
            </li>
            <li>Αξίνες (0)
            </li>
          </ul>
          </li>
          <li>Πιρούνες κηπουρικής (0)
          </li>
          <li>Τσεκούρια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Καλώδια, σύρματα και συναφή προϊόντα (34)
    <ul>
      <li>Προϊόντα από σύρμα (21)
      <ul>
        <li>Σιδηρικά είδη (6)
        <ul>
          <li>Αμόνια (0)
          </li>
          <li>Υλικά σιδηρουργού (1)
          <ul>
            <li>Σιδηρουργικά είδη (0)
            </li>
          </ul>
          </li>
          <li>Υλικά σιδηρουργείου (0)
          </li>
          <li>Φορητό σιδηρουργείο (0)
          </li>
          <li>Εσχάρες (0)
          </li>
        </ul>
        </li>
        <li>Πολύκλωνα σύρματα (0)
        </li>
        <li>Ράβδοι από σύρμα (5)
        <ul>
          <li>Εξαρτήματα συγκόλλησης (0)
          </li>
          <li>Υλικά συγκόλλησης (0)
          </li>
          <li>Υλικά για κασσιτεροκόλληση ή χαλκοκόλληση (2)
          <ul>
            <li>Υλικά για κασσιτεροκόλληση (0)
            </li>
            <li>Υλικά για χαλκοκόλληση (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Σύρμα περιφράξεων (1)
        <ul>
          <li>Αγκαθωτό σύρμα (0)
          </li>
        </ul>
        </li>
        <li>Μεταλλικά πλέγματα (2)
        <ul>
          <li>Υφασμένες μεταλλικές ίνες (0)
          </li>
          <li>Δικτυωτό σύρμα περίφραξης (0)
          </li>
        </ul>
        </li>
        <li>Συρματαγωγοί (0)
        </li>
        <li>Αρτάνες από σίδηρο ή χάλυβα (0)
        </li>
      </ul>
      </li>
      <li>Καλώδια και συναφή είδη (6)
      <ul>
        <li>Εξαρτήματα καλωδίων (4)
        <ul>
          <li>Σωληνοειδή περιβλήματα καλωδίων (0)
          </li>
          <li>Συνδέσεις καλωδίων (0)
          </li>
          <li>Αγωγοί καλωδίων (0)
          </li>
          <li>Διατάξεις στερέωσης καλωδίων (0)
          </li>
        </ul>
        </li>
        <li>Καλώδια (0)
        </li>
      </ul>
      </li>
      <li>Ράβδοι, ράβδοι κυκλικής διατομής, σύρμα και διατομές για χρήση σε κατασκευαστικές εργασίες (4)
      <ul>
        <li>Ράβδοι (κατασκευαστικές εργασίες) (0)
        </li>
        <li>Διατομές (0)
        </li>
        <li>Ράβδοι κυκλικής διατομής (0)
        </li>
        <li>Σύρμα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Δεξαμενές, ταμιευτήρες και δοχεία· σώματα καλοριφέρ και λέβητες (64)
    <ul>
      <li>Δεξαμενές, ταμιευτήρες, δοχεία και δοχεία υπό πίεση (51)
      <ul>
        <li>Μεγάλοι κάδοι (9)
        <ul>
          <li>Τυποποιημένα εμπορευματοκιβώτια (0)
          </li>
          <li>Κάδοι αποθήκευσης (0)
          </li>
          <li>Απορριμματοφόροι κάδοι (0)
          </li>
          <li>Κάδοι αποβλήτων (0)
          </li>
          <li>Κάδοι ψύξης (1)
          <ul>
            <li>Ταμιευτήρες νερού (0)
            </li>
          </ul>
          </li>
          <li>Τροχοφόροι κάδοι (0)
          </li>
          <li>Βυτία νερού (0)
          </li>
          <li>Σιλό (0)
          </li>
        </ul>
        </li>
        <li>Δοχεία υπό πίεση (1)
        <ul>
          <li>Χαλύβδινα δοχεία υπό πίεση (0)
          </li>
        </ul>
        </li>
        <li>Ελαφριά δοχεία, πώματα-στεφάνες, καπάκια δοχείων, σκάφες και καπάκια (10)
        <ul>
          <li>Μεταλλικό δοχείο (1)
          <ul>
            <li>Κυτία τροφίμων (0)
            </li>
          </ul>
          </li>
          <li>Πώματα στεφάνες, πώματα, καπάκια δοχείων και καπάκια (5)
          <ul>
            <li>Σκέπαστρα (0)
            </li>
            <li>Πώματα-στεφάνες (0)
            </li>
            <li>Καπάκια δοχείων (0)
            </li>
            <li>Πλαστικά καπάκια (0)
            </li>
            <li>Πώματα (0)
            </li>
          </ul>
          </li>
          <li>Ελαφριά δοχεία (0)
          </li>
          <li>Σκάφες (0)
          </li>
        </ul>
        </li>
        <li>Κιβώτια (3)
        <ul>
          <li>Προκατασκευασμένα κιβώτια (0)
          </li>
          <li>Πλαίσια μετρητών (0)
          </li>
          <li>Χαρτοκιβώτια (0)
          </li>
        </ul>
        </li>
        <li>Άλλα δοχεία (5)
        <ul>
          <li>Βαρέλια (0)
          </li>
          <li>Θήκες (0)
          </li>
          <li>Κλούβες (0)
          </li>
          <li>Πλαίσια για παλέτες (0)
          </li>
          <li>Τύμπανα για καλώδια (0)
          </li>
        </ul>
        </li>
        <li>Κυλινδρικά δοχεία (1)
        <ul>
          <li>Κυλινδρικά δοχεία αποβλήτων (0)
          </li>
        </ul>
        </li>
        <li>Δοχεία για υγροποιημένα αέρια (2)
        <ul>
          <li>Δεξαμενές αερίου (0)
          </li>
          <li>Κυλινδροειδή δοχεία αερίου (0)
          </li>
        </ul>
        </li>
        <li>Κάδος (3)
        <ul>
          <li>Σύστημα αποθήκευσης εμπορευματοκιβωτίων (1)
          <ul>
            <li>Μηχανήματα στοίβαξης (0)
            </li>
          </ul>
          </li>
          <li>Κιβώτια αποθήκευσης (0)
          </li>
        </ul>
        </li>
        <li>Δεξαμενές (8)
        <ul>
          <li>Δεξαμενές αποθήκευσης (2)
          <ul>
            <li>Δεξαμενές αποθήκευσης ιλύος (0)
            </li>
            <li>Δεξαμενές αποθήκευσης πετρελαίου (0)
            </li>
          </ul>
          </li>
          <li>Ταμιευτήρες (0)
          </li>
          <li>Κυλινδροειδή δοχεία αέρος (1)
          <ul>
            <li>Κυλινδροειδή δοχεία πεπιεσμένου αέρος (0)
            </li>
          </ul>
          </li>
          <li>Δεξαμενές νερού (0)
          </li>
          <li>Αναπνευστικές συσκευές (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Σώματα καλοριφέρ, λέβητες κεντρικής θέρμανσης και μέρη τους (11)
      <ul>
        <li>Συστήματα ανάκτησης θερμότητας (1)
        <ul>
          <li>Εξοπλισμός ανάκτησης θερμότητας (0)
          </li>
        </ul>
        </li>
        <li>Σώματα καλοριφέρ και λέβητες (8)
        <ul>
          <li>Λέβητες (3)
          <ul>
            <li>Λέβητες κεντρικής θέρμανσης (1)
            <ul>
              <li>Μέρη λεβήτων κεντρικής θέρμανσης (0)
              </li>
            </ul>
            </li>
            <li>Λέβητες νερού (0)
            </li>
          </ul>
          </li>
          <li>Σώματα καλοριφέρ (3)
          <ul>
            <li>Σώματα καλοριφέρ κεντρικής θέρμανσης (2)
            <ul>
              <li>Μέρη σωμάτων καλοριφέρ κεντρικής θέρμανσης (0)
              </li>
              <li>Μη ηλεκτρικώς θερμαινόμενα σώματα καλοριφέρ κεντρικής θέρμανσης (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Διάφορα κατασκευασμένα προϊόντα και συναφή είδη (80)
    <ul>
      <li>Υποστηρίγματα και δοκοί ορυχείων (4)
      <ul>
        <li>Στυλοβάτες (1)
        <ul>
          <li>Υποστηρίγματα ορυχείων (0)
          </li>
        </ul>
        </li>
        <li>Οχήματα κινούμενα επί ερπυστριών (0)
        </li>
        <li>Δοκοί ορυχείων (0)
        </li>
      </ul>
      </li>
      <li>Διάφορα προϊόντα από χυτοσίδηρο (0)
      </li>
      <li>Εύκαμπτος χάλυβας (2)
      <ul>
        <li>Δίσκοι για την κοπή κερμάτων (0)
        </li>
        <li>Κυτία παραλαβής κερμάτων μηχανών πώλησης (0)
        </li>
      </ul>
      </li>
      <li>Ενισχυμένα ελάσματα (1)
      <ul>
        <li>Ελάσματα κάλυψης (0)
        </li>
      </ul>
      </li>
      <li>Είδη μπάνιου και κουζίνας (12)
      <ul>
        <li>Είδη εγκαταστάσεων υγιεινής (11)
        <ul>
          <li>Κρουνοί (0)
          </li>
          <li>Μπιντέδες (0)
          </li>
          <li>Ντουζιέρες (0)
          </li>
          <li>Καθίσματα λεκανών τουαλέτας, καλύμματα λεκανών τουαλέτας, λεκάνες τουαλέτας, λεκάνες και καζανάκια αποχωρητηρίου (4)
          <ul>
            <li>Καθίσματα λεκανών τουαλέτας (0)
            </li>
            <li>Λεκάνες αποχωρητηρίου (0)
            </li>
            <li>Καζανάκια αποχωρητηρίου (0)
            </li>
            <li>Καλύμματα λεκανών τουαλέτας (0)
            </li>
          </ul>
          </li>
          <li>Ουρητήρια (0)
          </li>
          <li>Νιπτήρες (0)
          </li>
          <li>Μπανιέρες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Είδη για χρήση σε κατασκευαστικά έργα (46)
      <ul>
        <li>Κουτιά και ταινίες υποδοχών μετρητών (3)
        <ul>
          <li>Κολλητική ταινία (0)
          </li>
          <li>Κουτιά υποδοχών μετρητών (0)
          </li>
          <li>Ανακλαστικές ταινίες (0)
          </li>
        </ul>
        </li>
        <li>Θωρακισμένα ή ενισχυμένα θησαυροφυλάκια, χρηματοκιβώτια και πόρτες (10)
        <ul>
          <li>Θησαυροφυλάκια (0)
          </li>
          <li>Κουτιά και θυρίδες φύλαξης (6)
          <ul>
            <li>Ερμάρια φύλαξης αποσκευών (0)
            </li>
            <li>Κλειδωτά ερμάρια (2)
            <ul>
              <li>Σκευοθήκες ασφαλείας (0)
              </li>
              <li>Θυρίδες ασφαλείας (0)
              </li>
            </ul>
            </li>
            <li>Κουτιά για χρεόγραφα (0)
            </li>
            <li>Χρηματοφυλάκια (0)
            </li>
          </ul>
          </li>
          <li>Χρηματοκιβώτια (0)
          </li>
          <li>Θωρακισμένες ή ενισχυμένες πόρτες (0)
          </li>
        </ul>
        </li>
        <li>Διάφορα είδη (22)
        <ul>
          <li>Κώδωνες (0)
          </li>
          <li>Σήματα και συναφή είδη (2)
          <ul>
            <li>Επιγραφές ονομάτων (0)
            </li>
            <li>Επιγραφές διευθύνσεων (0)
            </li>
          </ul>
          </li>
          <li>Μεταλλικές μήτρες (2)
          <ul>
            <li>Σφραγίδες από καουτσούκ (0)
            </li>
            <li>Καλούπια (0)
            </li>
          </ul>
          </li>
          <li>Στοιχεία φρεατίων επίσκεψης (7)
          <ul>
            <li>Πλαίσια οριοθέτησης για την τοποθέτηση οργάνων (0)
            </li>
            <li>Καλύμματα φρεατίων επίσκεψης (0)
            </li>
            <li>Πλαίσια φρεατίων επίσκεψης (0)
            </li>
            <li>Καλύμματα επιφανείας (0)
            </li>
            <li>Καλύμματα εισόδου (0)
            </li>
            <li>Καλύμματα υπονόμων (0)
            </li>
            <li>Σιδηρά σκαλοπάτια φρεατίων επίσκεψης (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός διακίνησης εμπορευμάτων (2)
          <ul>
            <li>Πλατφόρμες διακίνησης εμπορευμάτων (0)
            </li>
            <li>Εξαρτήματα συρματόσχοινων για τη διακίνηση εμπορευμάτων (0)
            </li>
          </ul>
          </li>
          <li>Θυσιαζόμενοι άνοδοι (0)
          </li>
          <li>Σκάλες (2)
          <ul>
            <li>Πτυσσόμενες βαθμίδες-σκαλοπάτια (0)
            </li>
            <li>Σκαμνιά με βαθμίδες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Δακτύλιοι, παρεμβύσματα, ταινίες, στελέχη και μηχανήματα συσκευασίας υγρού κονιάματος (6)
        <ul>
          <li>Ταινίες από καουτσούκ (0)
          </li>
          <li>Ελαστικοί δακτύλιοι (1)
          <ul>
            <li>Αυτοκόλλητοι ενισχυτικοί δακτύλιοι (0)
            </li>
          </ul>
          </li>
          <li>Πλαστικά στελέχη (0)
          </li>
          <li>Παρεμβύσματα από καουτσούκ (0)
          </li>
          <li>Μηχανήματα συσκευασίας υγρού κονιάματος (0)
          </li>
        </ul>
        </li>
        <li>Γραμματοκιβώτια (0)
        </li>
      </ul>
      </li>
      <li>Τριβεία (2)
      <ul>
        <li>Κυλινδρικοί τριβείς (0)
        </li>
        <li>Μικροσφαιρίδια (0)
        </li>
      </ul>
      </li>
      <li>Ποικίλος εξοπλισμός πυροπροστασίας (5)
      <ul>
        <li>Πυροσβεστικές κλίμακες (1)
        <ul>
          <li>Κλίμακες πυρόσβεσης (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές πυροπροστασίας (2)
        <ul>
          <li>Κρουνοί υδροληψίας για πυρόσβεση (0)
          </li>
          <li>Πυροσβεστικοί υδροσωλήνες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Δομικά υλικά (79)
    <ul>
      <li>Κατασκευές και μέρη κατασκευών (56)
      <ul>
        <li>Προκατασκευασμένα κτίρια (6)
        <ul>
          <li>Μαγειρεία εκστρατείας (0)
          </li>
          <li>Αρθρωτά και φορητά κτίρια (1)
          <ul>
            <li>Θάλαμοι (0)
            </li>
          </ul>
          </li>
          <li>Νοσοκομείο εκστρατείας (0)
          </li>
          <li>Θερμοκήπια (0)
          </li>
          <li>Θαλαμίσκοι (0)
          </li>
        </ul>
        </li>
        <li>Δομικά προϊόντα και μέρη αυτών εκτός από προκατασκευασμένα κτίρια (48)
        <ul>
          <li>Γωνίες και τμήματα (2)
          <ul>
            <li>Γωνίες (0)
            </li>
            <li>Τμήματα (0)
            </li>
          </ul>
          </li>
          <li>Γέφυρα (2)
          <ul>
            <li>Δομές γεφυρών (0)
            </li>
            <li>Τμήματα γεφυρών (0)
            </li>
          </ul>
          </li>
          <li>Πύργοι, δικτυωτοί στύλοι, πύργοι γεωτρήσεως και πυλώνες (19)
          <ul>
            <li>Ιστοί (0)
            </li>
            <li>Πυλώνες, στύλοι και παλούκια (7)
            <ul>
              <li>Υποστηρίγματα πυλώνων (0)
              </li>
              <li>Πυλώνες μεταφοράς ηλεκτρικής ενέργειας (0)
              </li>
              <li>Παλούκια (0)
              </li>
              <li>Στύλοι για υπέργειες γραμμές (0)
              </li>
              <li>Στύλοι (0)
              </li>
              <li>Στύλοι για τη μεταφορά ηλεκτρικής ενέργειας (0)
              </li>
              <li>Πυλώνες (0)
              </li>
            </ul>
            </li>
            <li>Πύργοι φόρτωσης (2)
            <ul>
              <li>Πύργοι γεώτρησης (0)
              </li>
              <li>Στατικοί πύργοι γεώτρησης (0)
              </li>
            </ul>
            </li>
            <li>Ιστοί ραδιοφώνου ή τηλεόρασης (3)
            <ul>
              <li>Ιστοί ραδιοφώνου (0)
              </li>
              <li>Ιστοί τηλεόρασης (0)
              </li>
              <li>Πλεγματοειδείς ιστοί (0)
              </li>
            </ul>
            </li>
            <li>Πύργοι (1)
            <ul>
              <li>Υδατόπυργοι (0)
              </li>
            </ul>
            </li>
            <li>Δοκοί (0)
            </li>
          </ul>
          </li>
          <li>Κατασκευές και μέρη αυτών (19)
          <ul>
            <li>Κλεισιάδες (1)
            <ul>
              <li>Πύλες κλεισιάδας (0)
              </li>
            </ul>
            </li>
            <li>Διάφορες κατασκευές (3)
            <ul>
              <li>Διαφράγματα ασφαλείας (0)
              </li>
              <li>Τηλεφωνικοί θάλαμοι (0)
              </li>
              <li>Στέγαστρα λεωφορείων (0)
              </li>
            </ul>
            </li>
            <li>Ικριώματα (8)
            <ul>
              <li>Ορθοστάτες αψίδων (0)
              </li>
              <li>Κατασκευές ικριωμάτων (0)
              </li>
              <li>Στηρίγματα αγωγών (0)
              </li>
              <li>Βάθρα αψίδων (0)
              </li>
              <li>Βραχίονες ανάρτησης σωλήνων (0)
              </li>
              <li>Συνδετήρες αψίδων (0)
              </li>
              <li>Εξοπλισμός ικριωμάτων (0)
              </li>
              <li>Υποστηρίγματα (0)
              </li>
            </ul>
            </li>
            <li>Μέρη κατασκευών (3)
            <ul>
              <li>Επενδύσεις (0)
              </li>
              <li>Πύλες υδατοφράκτου (0)
              </li>
              <li>Θύρες υδροφράκτου (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Πάσσαλοι (1)
          <ul>
            <li>Πασσαλοσανίδες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Είδη ξυλουργικής για χρήση σε οικοδομικές εργασίες (17)
      <ul>
        <li>Παράθυρα, πόρτες και συναφή είδη (16)
        <ul>
          <li>Πόρτες (7)
          <ul>
            <li>Θύρες πυροπροστασίας (0)
            </li>
            <li>Συρόμενες πόρτες (0)
            </li>
            <li>Γκαραζόπορτες (0)
            </li>
            <li>Σώματα πορτών (3)
            <ul>
              <li>Κατώφλια θυρών (0)
              </li>
              <li>Πλαίσια πορτών (0)
              </li>
              <li>Σίτες πόρτας (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Παράθυρα (3)
          <ul>
            <li>Γαλλικά παράθυρα (0)
            </li>
            <li>Πλαίσια παραθύρων (1)
            <ul>
              <li>Μονάδες διπλών υαλοπινάκων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Πύλες (1)
          <ul>
            <li>Πύλες εισόδου (0)
            </li>
          </ul>
          </li>
          <li>Κατώφλια (0)
          </li>
          <li>Παραθυρόφυλλα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Δομικά στοιχεία για χρήση σε οικοδομικές εργασίες (3)
      <ul>
        <li>Κλίμακες (0)
        </li>
        <li>Ξύλινοι σκελετοί στέγης (0)
        </li>
        <li>Έτοιμα πλαίσια περίφραξης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Λίθοι για κατασκευές, ασβεστόλιθος, γύψος και σχιστόλιθος (19)
    <ul>
      <li>Σχιστόλιθος (0)
      </li>
      <li>Λίθοι για κατασκευές (8)
      <ul>
        <li>Διάφοροι οικοδομικοί λίθοι (4)
        <ul>
          <li>Λίθοι κρασπέδου (0)
          </li>
          <li>Γρανίτης (0)
          </li>
          <li>Βασάλτης (0)
          </li>
          <li>Ψαμμίτης (0)
          </li>
        </ul>
        </li>
        <li>Μάρμαρο και ασβεστούχοι οικοδομικοί λίθοι (2)
        <ul>
          <li>Τραβερτίνης (0)
          </li>
          <li>Μάρμαρο (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ασβεστόλιθος, γύψος και κιμωλία (8)
      <ul>
        <li>Ασβεστόλιθος και γύψος (4)
        <ul>
          <li>Ασβεστόλιθος (0)
          </li>
          <li>Γύψος (0)
          </li>
          <li>Άσβεστος (1)
          <ul>
            <li>Σκόνη ασβέστου (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κιμωλία και δολομίτης (2)
        <ul>
          <li>Κιμωλία (0)
          </li>
          <li>Δολομίτης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Βαφές, βερνίκια και μαστίχες (21)
    <ul>
      <li>Βερνίκια (0)
      </li>
      <li>Χρώματα επίχρισης (10)
      <ul>
        <li>Χρώμα διαγράμμισης οδών (0)
        </li>
        <li>Χρώματα ζωγραφικής (8)
        <ul>
          <li>Χρώματα ως εκπαιδευτικό υλικό (2)
          <ul>
            <li>Χρώματα για επιγραφοποιούς (0)
            </li>
            <li>Σετ χρωμάτων (0)
            </li>
          </ul>
          </li>
          <li>Λαδομπογιές και νερομπογιές (2)
          <ul>
            <li>Λαδομπογιές (0)
            </li>
            <li>Νερομπογιές (0)
            </li>
          </ul>
          </li>
          <li>Εφυαλώματα και υαλοβερνίκια (0)
          </li>
          <li>Είδη διακόσμησης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μαστίχες, πληρωτικά, στόκος και διαλύτες (8)
      <ul>
        <li>Μαστίχες, πληρωτικά, στόκος (4)
        <ul>
          <li>Υλικά πλήρωσης (0)
          </li>
          <li>Μαστίχες (0)
          </li>
          <li>Ρευστό κονίαμα (0)
          </li>
          <li>Στόκος (0)
          </li>
        </ul>
        </li>
        <li>Διαλύτες (2)
        <ul>
          <li>Μέσα αφαίρεσης χρώματος (0)
          </li>
          <li>Αραιωτικά (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Κατασκευαστικά υλικά και παρόμοια είδη (159)
    <ul>
      <li>Αποχετευτικοί αγωγοί (4)
      <ul>
        <li>Τερματικά καλύμματα από όλκιμο χάλυβα (0)
        </li>
        <li>Καμπύλες σωληνώσεων (0)
        </li>
        <li>Θάλαμοι αποχέτευσης (0)
        </li>
        <li>Βόθροι (0)
        </li>
      </ul>
      </li>
      <li>Ποικίλα κατασκευαστικά υλικά (10)
      <ul>
        <li>Ποικίλα κατασκευαστικά υλικά από ξύλο (6)
        <ul>
          <li>Συμπυκνωμένη ξυλεία (0)
          </li>
          <li>Μοριοσανίδες (0)
          </li>
          <li>Ινοσανίδες (0)
          </li>
          <li>Κόντρα πλακέ (0)
          </li>
          <li>Ελασματοποιημένο ξύλο (0)
          </li>
          <li>Σανίδες παρκέ (0)
          </li>
        </ul>
        </li>
        <li>Άλλα ποικίλα κατασκευαστικά υλικά (2)
        <ul>
          <li>Αφρός από PVC (0)
          </li>
          <li>Καρφιά (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Πλάκες, φύλλα, ταινίες και λεπτά φύλλα σε σχέση με κατασκευαστικά υλικά (6)
      <ul>
        <li>Πλάκες (κατασκευαστικά έργα) (0)
        </li>
        <li>Ταινίες (0)
        </li>
        <li>Λεπτά φύλλα (0)
        </li>
        <li>Μεμβράνες (0)
        </li>
        <li>Φύλλα (κατασκευαστικά έργα) (0)
        </li>
        <li>Φατνώματα (0)
        </li>
      </ul>
      </li>
      <li>Κατασκευαστικά υλικά (75)
      <ul>
        <li>Διάφορες οικοδομικές κατασκευές (18)
        <ul>
          <li>Επενδύσεις δαπέδων (4)
          <ul>
            <li>Άκαμπτες επενδύσεις δαπέδων (0)
            </li>
            <li>Λινοτάπητες (0)
            </li>
            <li>Παρκέ (0)
            </li>
            <li>Ψευδοδάπεδα (0)
            </li>
          </ul>
          </li>
          <li>Υλικά οροφών (1)
          <ul>
            <li>Ταβανοσάνιδα (0)
            </li>
          </ul>
          </li>
          <li>Ηχομονωτικά μέσα (0)
          </li>
          <li>Οροφές (3)
          <ul>
            <li>Υποστηρίγματα οροφών (0)
            </li>
            <li>Πλαίσια οροφών (0)
            </li>
            <li>Σκελετοί οροφών (0)
            </li>
          </ul>
          </li>
          <li>Δοκάρια (0)
          </li>
          <li>Διαχωριστικά στοιχεία (1)
          <ul>
            <li>Διαχωριστικοί τοίχοι (0)
            </li>
          </ul>
          </li>
          <li>Υπόστεγα (2)
          <ul>
            <li>Μέρη υποστέγων (0)
            </li>
            <li>Διαμορφωμένα τμήματα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Σκυρόδεμα (5)
        <ul>
          <li>Έτοιμο σκυρόδεμα (0)
          </li>
          <li>Προϊόντα σκυροδέματος (3)
          <ul>
            <li>Πλάκες από σκυρόδεμα (0)
            </li>
            <li>Πάσσαλοι από σκυρόδεμα (0)
            </li>
            <li>Αγωγοί από σκυρόδεμα και προσαρμογές (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Οικοδομικά υλικά (15)
        <ul>
          <li>Χρώματα και επενδύσεις τοίχων (0)
          </li>
          <li>Μονωτές και εξαρτήματα μόνωσης (5)
          <ul>
            <li>Θερμομονωτικά υλικά (0)
            </li>
            <li>Μονωτές (1)
            <ul>
              <li>Ηλεκτρικοί μονωτές (0)
              </li>
            </ul>
            </li>
            <li>Μονωτικό γυαλί (0)
            </li>
            <li>Ηλεκτρικά εξαρτήματα μόνωσης (0)
            </li>
          </ul>
          </li>
          <li>Πλακίδια (0)
          </li>
          <li>Τούβλα (0)
          </li>
          <li>Κονίαμα (κατασκευαστικά έργα) (0)
          </li>
          <li>Τσιμέντο (1)
          <ul>
            <li>Τσιμέντο γεωτρήσεων (0)
            </li>
          </ul>
          </li>
          <li>Είδη κεραμικής (0)
          </li>
          <li>Κυβόλιθοι (0)
          </li>
          <li>Κεραμικές πλάκες (0)
          </li>
        </ul>
        </li>
        <li>Υλικά κατασκευής οδών (18)
        <ul>
          <li>Υλικά συντήρησης οδών (1)
          <ul>
            <li>Υλικά χειμερινής συντήρησης οδών (0)
            </li>
          </ul>
          </li>
          <li>Γυάλινες χάντρες (0)
          </li>
          <li>Πλάκες λιθοστρώσεως (0)
          </li>
          <li>Βιτουμένια και άσφαλτος (2)
          <ul>
            <li>Άσφαλτος (0)
            </li>
            <li>Βιτουμένιο (0)
            </li>
          </ul>
          </li>
          <li>Υλικά πλακόστρωσης (3)
          <ul>
            <li>Πλάκες οδοστρώματος (0)
            </li>
            <li>Λίθοι οδοστρώματος (0)
            </li>
            <li>Λίθοι οδοστρωσίας (0)
            </li>
          </ul>
          </li>
          <li>Υλικά επιφάνειας οδοστρώματος (1)
          <ul>
            <li>Κάλυψη επιφάνειας (0)
            </li>
          </ul>
          </li>
          <li>Επιχρισμένα υλικά (3)
          <ul>
            <li>Επιχρισμένα υλικά οδοποιίας (0)
            </li>
            <li>Υλικά επίχρισης (0)
            </li>
            <li>Επιχρισμένοι λίθοι οδοστρώματος (0)
            </li>
          </ul>
          </li>
          <li>Υλικά επισκευής οδών (0)
          </li>
        </ul>
        </li>
        <li>Βοηθητικός οικοδομικός εξοπλισμός (14)
        <ul>
          <li>Εξωτερικά παραθυρόφυλλα (1)
          <ul>
            <li>Κουβούκλια (0)
            </li>
          </ul>
          </li>
          <li>Μηχανισμοί ανέλκυσης για αναπήρους (0)
          </li>
          <li>Αγωγοί εγκαταστάσεων (0)
          </li>
          <li>Διατάξεις ηλιοπροστασίας (0)
          </li>
          <li>Φεγγίτες (0)
          </li>
          <li>Εσωτερικός εξοπλισμός κτιρίων (2)
          <ul>
            <li>Σιδηρόδρομοι και αναρτήρες για κουρτίνες (1)
            <ul>
              <li>Σιδηρόδρομοι για κουρτίνες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Συστήματα αυτόματης πυρόσβεσης με ψεκαστήρες (0)
          </li>
          <li>Υλικά υδραυλικών εγκαταστάσεων και εγκαταστάσεων θέρμανσης (2)
          <ul>
            <li>Υλικά υδραυλικών εγκαταστάσεων (0)
            </li>
            <li>Υλικά εγκαταστάσεων θέρμανσης (0)
            </li>
          </ul>
          </li>
          <li>Παραθυρόφυλλα σε ρολλά (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Προϊόντα σε σχέση με κατασκευαστικά υλικά (5)
      <ul>
        <li>Παλέτες (0)
        </li>
        <li>Πασσαλίσκοι (0)
        </li>
        <li>Αγωγοί (1)
        <ul>
          <li>Ηλεκτρικοί αγωγοί (0)
          </li>
        </ul>
        </li>
        <li>Πλαίσια (0)
        </li>
      </ul>
      </li>
      <li>Σωλαγωγοί, σωληνώσεις, σωλήνες, περιβλήματα, σωληνωτά υλικά και συναφή είδη (53)
      <ul>
        <li>Σωλήνες που προορίζονται για τις πετρελαιοπαραγωγικές χώρες (0)
        </li>
        <li>Εύκαμπτοί σωλήνες, κατακόρυφοι σωλήνες και χιτώνια (5)
        <ul>
          <li>Εύκαμπτοί σωλήνες (1)
          <ul>
            <li>Εύκαμπτοι σωλήνες γεωτρήσεων (0)
            </li>
          </ul>
          </li>
          <li>Χιτώνια (0)
          </li>
          <li>Κατακόρυφοι σωλήνες (1)
          <ul>
            <li>Εύκαμπτοι κατακόρυφοι αγωγοί (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Διάφορα εξαρτήματα σωληνώσεων (6)
        <ul>
          <li>Καμπύλες, "ταυ" και εξαρτήματα σωληνώσεων (0)
          </li>
          <li>Καμπύλες (0)
          </li>
          <li>Σφιγκτήρες επισκευής και δακτύλιοι (0)
          </li>
          <li>Σύνδεσμοι (2)
          <ul>
            <li>Φλάντζες (1)
            <ul>
              <li>Προσαρμογείς για φλάντζες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Συστήματα αγωγών (5)
        <ul>
          <li>Σύστημα αγωγών πόσιμου νερού (0)
          </li>
          <li>Εξοπλισμός για αγωγούς (0)
          </li>
          <li>Συστήματα αγωγών διανομής (0)
          </li>
          <li>Στόμια εκροής αγωγών (0)
          </li>
          <li>Φρεάτια επίσκεψης από υαλοποιημένο πηλό (0)
          </li>
        </ul>
        </li>
        <li>Σωληνώσεις και εξαρτήματα (15)
        <ul>
          <li>Εξαρτήματα προσαρμογής αγωγών (4)
          <ul>
            <li>Ζεύξεις αγωγών (1)
            <ul>
              <li>Μονωμένες συνδέσεις (0)
              </li>
            </ul>
            </li>
            <li>Σύνδεσμοι αγωγών (0)
            </li>
            <li>Σφιγκτήρες αγωγών (0)
            </li>
          </ul>
          </li>
          <li>Σωληνώσεις (9)
          <ul>
            <li>Αγωγοί χαμηλής πίεσης (0)
            </li>
            <li>Αγωγοί αποχέτευσης (0)
            </li>
            <li>Αγωγοί ατμού και νερού (0)
            </li>
            <li>Αγωγοί διανομής και εξαρτήματα (0)
            </li>
            <li>Αγωγοί αποστράγγισης (2)
            <ul>
              <li>Σύστημα αποστράγγισης (0)
              </li>
              <li>Αγωγοί εκκένωσης (0)
              </li>
            </ul>
            </li>
            <li>Αγωγοί τηλεθέρμανσης (1)
            <ul>
              <li>Αγωγοί θέρμανσης (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Σωληναγωγοί (11)
        <ul>
          <li>Υποβρύχιοι αγωγοί (1)
          <ul>
            <li>Υποθαλάσσιοι αγωγοί (0)
            </li>
          </ul>
          </li>
          <li>Σωλήνες ύδρευσης (0)
          </li>
          <li>Αγωγοί αερίου (1)
          <ul>
            <li>Δίκτυο διανομής αερίου (0)
            </li>
          </ul>
          </li>
          <li>Σωληναγωγοί χαμηλής πίεσης (0)
          </li>
          <li>Σωληναγωγοί υψηλής πίεσης (0)
          </li>
          <li>Συσκευές για τον εσωτερικό καθαρισμό αγωγών (3)
          <ul>
            <li>Μηχανισμοί προώθησης συσκευών εσωτερικού καθαρισμού αγωγών (0)
            </li>
            <li>Τερματικοί σταθμοί συσκευών εσωτερικού καθαρισμού αγωγών (0)
            </li>
            <li>Ενδιάμεσοι σταθμοί συσκευών εσωτερικού καθαρισμού αγωγών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Περιβλήματα και σωληνώσεις (4)
        <ul>
          <li>Σωληνοειδή υλικά (1)
          <ul>
            <li>Σωλήνες και εξαρτήματα (0)
            </li>
          </ul>
          </li>
          <li>Σωλήνες (0)
          </li>
          <li>Περιβλήματα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες γεωργίας, δασοκομίας, φυτοκομίας, υδατοκαλλιέργειας και μελισσοκομίας (53)
  <ul>
    <li className='top-level'>Υπηρεσίες φυτοκομίας (13)
    <ul>
      <li>Φύτευση και συντήρηση χώρων πρασίνου (7)
      <ul>
        <li>Υπηρεσίες εκκαθάρισης από αγριόχορτα (1)
        <ul>
          <li>Υπηρεσίες εξόντωσης ζιζανίων (0)
          </li>
        </ul>
        </li>
        <li>Συντήρηση διακοσμητικών κήπων ή κήπων αναψυχής (0)
        </li>
        <li>Υπηρεσίες συντήρησης οικοπέδων (1)
        <ul>
          <li>Υπηρεσίες χορτοκάλυψης (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες σποράς (0)
        </li>
        <li>Υπηρεσίες συντήρησης πάρκων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επίδειξης φυτών (0)
      </li>
      <li>Συντήρηση αθλητικών γηπέδων (0)
      </li>
      <li>Κλάδεμα δένδρων και θάμνων (2)
      <ul>
        <li>Κλάδεμα θάμνων (0)
        </li>
        <li>Κλάδεμα δένδρων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες μελισσοκομίας (0)
    </li>
    <li className='top-level'>Υπηρεσίες σχετιζόμενες με την αλιεία (0)
    </li>
    <li className='top-level'>Ζωολογικές υπηρεσίες (0)
    </li>
    <li className='top-level'>Υπηρεσίες κυνηγιού (1)
    <ul>
      <li>Υπηρεσίες τοποθέτησης παγίδων (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Γεωργικές υπηρεσίες (4)
    <ul>
      <li>Υπηρεσίες σχετιζόμενες με τη γεωργική παραγωγή (2)
      <ul>
        <li>Ενοικίαση γεωργικών μηχανημάτων με χειριστή (0)
        </li>
        <li>Ενοικίαση χορτοκοπτικών ή γεωργικών μηχανημάτων με χειριστή (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες παραγωγής οργανικού λιπάσματος (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Κτηνοτροφικές υπηρεσίες (1)
    <ul>
      <li>Υπηρεσίες διάδοσης παιγχνιδιών (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες δασοκομίας (20)
    <ul>
      <li>Υπηρεσίες σχετιζόμενες με τη δασοκομία (10)
      <ul>
        <li>Υπηρεσίες διαχείρισης δασοκομίας (9)
        <ul>
          <li>Υπηρεσίες δασικού τομεακού σχεδιασμού (0)
          </li>
          <li>Υπηρεσίες παρακολούθησης ή αξιολόγησης δασών (0)
          </li>
          <li>Υπηρεσίες δασικής επέκτασης (0)
          </li>
          <li>Υπηρεσίες διαχείρισης δασών (0)
          </li>
          <li>Υπηρεσίες διαχείρισης δασικών πόρων (0)
          </li>
          <li>Υπηρεσίες απογραφής δασών (0)
          </li>
          <li>Υπηρεσίες ελέγχου παρασίτων του δάσους (0)
          </li>
          <li>Υπηρεσίες αναδάσωσης (0)
          </li>
          <li>Υπηρεσίες διαχείρισης φυτωρίων δασικών δέντρων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εμποτισμού ξυλείας (0)
      </li>
      <li>Υπηρεσίες υλοτομίας (7)
      <ul>
        <li>Υπηρεσίες σχετιζόμενες με την υλοτομία (6)
        <ul>
          <li>Υπηρεσίες φροντίδας δένδρων (0)
          </li>
          <li>Υπηρεσίες υλοτόμισης (0)
          </li>
          <li>Σπορά σπόρων δένδρων (0)
          </li>
          <li>Υπηρεσίες περισυλλογής ξυλείας (0)
          </li>
          <li>Μεταφορά κορμών δένδρων στο εσωτερικό δάσους (0)
          </li>
          <li>Υπηρεσίες κοπής δένδρων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες υδατοκαλλιέργειας (5)
    <ul>
      <li>Υπηρεσίες θαλάσσιας υδατοκαλλιέργειας (0)
      </li>
      <li>Υπηρεσίες κογχυλοκαλλιέργειας (0)
      </li>
      <li>Υπηρεσίες ιχθυοκαλλιέργειας (0)
      </li>
      <li>Υπηρεσίες εκτροφής γαρίδων (0)
      </li>
      <li>Υπηρεσίες οστρακοκαλλιέργειας (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Μηχανήματα γραφείου και υπολογιστές, εξοπλισμός και προμήθειες εκτός από έπιπλα και πακέτα λογισμικών (400)
  <ul>
    <li className='top-level'>Εξοπλισμός ηλεκτρονικών υπολογιστών και προμήθειες (133)
    <ul>
      <li>Μηχανές επεξεργασίας δεδομένων (υλισμικό) (24)
      <ul>
        <li>Προσωπικοί ηλεκτρονικοί υπολογιστές (5)
        <ul>
          <li>Φορητοί επιτραπέζιοι μικροϋπολογιστές (0)
          </li>
          <li>Φορητοί υπολογιστές για την εισαγωγή χειρόγραφου κειμένου με τη χρήση γραφίδας (0)
          </li>
          <li>Κεντρικές μονάδες επεξεργασίας προσωπικών ηλεκτρονικών υπολογιστών (0)
          </li>
          <li>Ηλεκτρονικοί υπολογιστές τσέπης (0)
          </li>
          <li>Επιτραπέζιοι ηλεκτρονικοί υπολογιστές (0)
          </li>
        </ul>
        </li>
        <li>Υλισμικό μικροϋπολογιστών (1)
        <ul>
          <li>Κεντρικές μονάδες επεξεργασίας μικροϋπολογιστών (0)
          </li>
        </ul>
        </li>
        <li>Μαγνητικοί ή οπτικοί αναγνώστες (6)
        <ul>
          <li>Αναγνώστες μαγνητικής κάρτας (0)
          </li>
          <li>Αναγνώστες διάτρητων δελτίων (0)
          </li>
          <li>Οπτικοί αναγνώστες (3)
          <ul>
            <li>Σαρωτές για χρήση στον τομέα της πληροφορικής (0)
            </li>
            <li>Εξοπλισμός οπτικής αναγνώρισης χαρακτήρων (0)
            </li>
            <li>Αναγνώστες κωδικών ραβδώσεων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κεντρικός ηλεκτρονικός υπολογιστής (5)
        <ul>
          <li>Υπερ-υπολογιστής (0)
          </li>
          <li>Υποδομή ηλεκτρονικών υπολογιστών (0)
          </li>
          <li>Εξοπλισμός κεντρικού ηλεκτρονικού υπολογιστή (0)
          </li>
          <li>Κεντρικές μονάδες επεξεργασίας (CPU) ή επεξεργαστές (0)
          </li>
          <li>Διαμόρφωση ηλεκτρονικών υπολογιστών (0)
          </li>
        </ul>
        </li>
        <li>Σταθμοί εργασίας (0)
        </li>
        <li>Υλισμικό για μίνι ηλεκτρονικούς υπολογιστές (1)
        <ul>
          <li>Κεντρικές μονάδες επεξεργασίας μίνι ηλεκτρονικών υπολογιστών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός ψηφιακής χαρτογράφησης (1)
      <ul>
        <li>Ψηφιακοί χάρτες κτηματολογίου (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός ηλεκτρονικών υπολογιστών (105)
      <ul>
        <li>Μονάδες αποθήκευσης και ανάγνωσης (20)
        <ul>
          <li>Μονάδες αποθήκευσης δεδομένων ηλεκτρονικών υπολογιστών (16)
          <ul>
            <li>Μονάδες αποθήκευσης μαγνητικού δίσκου (2)
            <ul>
              <li>Μηχανισμοί ανάγνωσης δισκέτας (0)
              </li>
              <li>Μηχανισμοί ανάγνωσης σκληρού δίσκου (0)
              </li>
            </ul>
            </li>
            <li>Μονάδες αποθήκευσης μαγνητικής ταινίας (0)
            </li>
            <li>Μονάδες αποθήκευσης μνήμης φλας (0)
            </li>
            <li>Μηχανισμοί για ταινίες συνεχούς ροής (1)
            <ul>
              <li>Εξοπλισμός χειρισμού κασετών (0)
              </li>
            </ul>
            </li>
            <li>Συσκευή ελέγχου δίσκου (0)
            </li>
            <li>Περιστρεφόμενες μονάδες αρχειοθέτησης (0)
            </li>
            <li>Συσκευές αποθήκευσης άμεσης προσπέλασης (DASD) (1)
            <ul>
              <li>Πλεονάζουσα παράταξη ανεξάρτητων δίσκων (RAID) (0)
              </li>
            </ul>
            </li>
            <li>Μονάδες αποθήκευσης μαγνητικής κάρτας (0)
            </li>
            <li>Μηχανισμοί ανάγνωσης οπτικού δίσκου (3)
            <ul>
              <li>Συσκευές ανάγνωσης ή/και αντιγραφής σύμπυκνων δίσκων (CD) (0)
              </li>
              <li>Συσκευές ανάγνωσης ή/και αντιγραφής ψηφιακών βιντεοδίσκων (DVD) (0)
              </li>
              <li>Συσκευές ανάγνωσης ή/και αντιγραφής σύμπυκνων δίσκων (CD) και ψηφιακών βιντεοδίσκων (DVD) (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Συσκευές ανάγνωσης έξυπνων καρτών (2)
          <ul>
            <li>Συσκευές ανάγνωσης έξυπνων καρτών και δακτυλικών αποτυπωμάτων (0)
            </li>
            <li>Συσκευές ανάγνωσης δακτυλικών αποτυπωμάτων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Μέρη, εξαρτήματα και προμήθειες υπολογιστών (46)
        <ul>
          <li>Προμήθειες ηλεκτρονικών υπολογιστών (8)
          <ul>
            <li>Φύσιγγες ψηφιακών ταινιών ήχου (DAT) (0)
            </li>
            <li>Φύσιγγες εγργαφής (0)
            </li>
            <li>Φύσιγγες δεδομένων (0)
            </li>
            <li>Φύσιγγες τύπου χαρακτήρων για εκτυπωτές (0)
            </li>
            <li>Φύσιγγες ψηφιακών γραμμικών ταινιών (DLT) (0)
            </li>
            <li>Δισκέτες (0)
            </li>
            <li>Δίσκοι CD-ROM (0)
            </li>
            <li>Φύσιγγες γραμμικών ταινίων ανοιχτής τεχνολογίας (LTO) (0)
            </li>
          </ul>
          </li>
          <li>Εξαρτήματα εισαγωγής δεδομένων (10)
          <ul>
            <li>Πληκτρολόγια τυφλών (1)
            <ul>
              <li>Ηλεκτρικοί αισθητήρες (0)
              </li>
            </ul>
            </li>
            <li>Ποντίκια ηλεκτρονικών υπολογιστών (0)
            </li>
            <li>Φωτοπένες (0)
            </li>
            <li>Ιχνοσφαίρες (0)
            </li>
            <li>Πινακίδες γραφικών (0)
            </li>
            <li>Πληκτρολόγια υπολογιστών (1)
            <ul>
              <li>Προγραμματιζόμενα πληκτρολόγια (0)
              </li>
            </ul>
            </li>
            <li>Μονάδες εισόδου (0)
            </li>
            <li>Χειριστήρια (0)
            </li>
          </ul>
          </li>
          <li>Εξαρτήματα ηλεκτρονικών υπολογιστών (13)
          <ul>
            <li>Εξαρτήματα καθαρισμού ηλεκτρονικών υπολογιστών (3)
            <ul>
              <li>Καθαριστικός πεπιεσμένος αέρας (0)
              </li>
              <li>Σετ καθαρισμού ηλεκτρονικών υπολογιστών (0)
              </li>
              <li>Καλύμματα ηλεκτρονικών υπολογιστών για προστασία από τη σκόνη (0)
              </li>
            </ul>
            </li>
            <li>Χώρος κρυφής μνήμης (0)
            </li>
            <li>Κάμερες Ιστού (0)
            </li>
            <li>Εξαρτήματα τροφοδοσίας ισχύος (0)
            </li>
            <li>Βάσεις χειρισμού ποντικιού (0)
            </li>
            <li>Αντιθαμπωτικά φίλτρα (0)
            </li>
            <li>Στηρίγματα καρπού για πληκτρολόγια (1)
            <ul>
              <li>Πλέγματα απομόνωσης πλήκτρων για πληκτρολόγια (0)
              </li>
            </ul>
            </li>
            <li>Βραχίονες επιτοίχιας στερέωσης οθόνης (0)
            </li>
            <li>Θήκες μεταφοράς φορητών ηλεκτρονικών υπολογιστών (0)
            </li>
          </ul>
          </li>
          <li>Μέρη υπολογιστών (11)
          <ul>
            <li>Θύρες επικοινωνίας ηλεκτρονικών υπολογιστών (1)
            <ul>
              <li>Σειριακές θύρες υπέρυθρων (0)
              </li>
            </ul>
            </li>
            <li>Κάρτες ηλεκτρονικών υπολογιστών (6)
            <ul>
              <li>Κάρτες επιτάχυνσης γραφικών (0)
              </li>
              <li>Προσαρμογείς και διεπαφές διεθνούς ένωσης καρτών μνήμης προσωπικών υπολογιστών (PCMCIA) (0)
              </li>
              <li>Ηλεκτρονικές κάρτες (0)
              </li>
              <li>Διεπαφές καθολικού σειριακού αγωγού (USB) (0)
              </li>
              <li>Κάρτες ήχου (0)
              </li>
              <li>Κάρτες διεπαφής δικτύων (0)
              </li>
            </ul>
            </li>
            <li>Μητρικές πλακέτες (0)
            </li>
            <li>Διασυνδέσεις δικτύου (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Περιφερειακός εξοπλισμός (8)
        <ul>
          <li>Μονάδα κεντρικού ελέγχου (0)
          </li>
          <li>Κωδικοποιητές (0)
          </li>
          <li>Εκτυπωτές και σχεδιογράφοι (5)
          <ul>
            <li>Εκτυπωτές λέιζερ (0)
            </li>
            <li>Εκτυπωτές έγχρωμων γραφικών (0)
            </li>
            <li>Εκτυπωτές ψεκασμού μελάνης (0)
            </li>
            <li>Σχεδιογράφοι (0)
            </li>
            <li>Εκτυπωτές μήτρας κουκίδων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Μέσα αποθήκευσης (7)
        <ul>
          <li>Μνήμες φλας (0)
          </li>
          <li>Οπτικοί δίσκοι (0)
          </li>
          <li>Σύμπυκνοι δίσκοι (CD) (0)
          </li>
          <li>Μαγνητικές ταινίες (0)
          </li>
          <li>Μαγνητικοί δίσκοι (0)
          </li>
          <li>Ψηφιακοί βιντεοδίσκοι (DVD) (0)
          </li>
          <li>Μέσα αποθήκευσης μνήμης (0)
          </li>
        </ul>
        </li>
        <li>Ποικίλος εξοπλισμός ηλεκτρονικών υπολογιστών (12)
        <ul>
          <li>Εξοπλισμός επέκτασης μνήμης (10)
          <ul>
            <li>Μνήμη απλής ανάγνωσης (ROM) (3)
            <ul>
              <li>Απαλείψιμη προγραμματίσιμη μνήμη μόνο για ανάγνωση (EPROM) (0)
              </li>
              <li>Ηλεκτρονικά απαλείψιμη προγραμματίσιμη μνήμη μόνο για ανάγνωση (EEPROM) (0)
              </li>
              <li>Προγραμματίσιμη μνήμη απλής ανάγνωσης (PROM) (0)
              </li>
            </ul>
            </li>
            <li>Μνήμη τυχαίας προσπέλασης (RAM) (5)
            <ul>
              <li>Δυναμική μνήμη τυχαίας προσπέλασης (DRAM) (0)
              </li>
              <li>Σύγχρονη δυναμική μνήμη τυχαίας προσπέλασης (SDRAM) (0)
              </li>
              <li>Στατική μνήμη τυχαίας προσπέλασης (SRAM) (0)
              </li>
              <li>Δυναμική μνήμη τυχαίας προσπέλασης Rambus (RDRAM) ή ισοδύναμη (0)
              </li>
              <li>Σύγχρονη μνήμη γραφικών τυχαίας προσπέλασης (SGRAM) (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός επεξεργασίας δεδομένων (0)
          </li>
        </ul>
        </li>
        <li>Οθόνες και κονσόλες ηλεκτρονικών υπολογιστών (5)
        <ul>
          <li>Τερματικά ηλεκτρονικών υπολογιστών (0)
          </li>
          <li>Κονσόλες (0)
          </li>
          <li>Οθόνες απεικόνισης (2)
          <ul>
            <li>Οθόνες αφής (0)
            </li>
            <li>Επίπεδες οθόνες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ηλεκτρονικής βιβλιοθήκης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μηχανήματα γραφείου, εξοπλισμός και προμήθειες εκτός από ηλεκτρονικούς υπολογιστές, εκτυπωτές και έπιπλα (265)
    <ul>
      <li>Διάφορα μηχανήματα γραφείου, εξοπλισμός και προμήθειες (168)
      <ul>
        <li>Οργανωτές και εξαρτήματα (9)
        <ul>
          <li>Βιβλιοστάτες (0)
          </li>
          <li>Κιβώτια αποθήκευσης εγγράφων (0)
          </li>
          <li>Επιτραπέζιοι δίσκοι ή οργανωτές (0)
          </li>
          <li>Υποδοχές φωτοαντιγράφων (0)
          </li>
          <li>Οργανωτές συρταριών (0)
          </li>
          <li>Θήκες εντύπων (0)
          </li>
          <li>Αναρτώμενοι οργανωτές (0)
          </li>
          <li>Βάσεις για προσωπικά ημερολόγια ή ημεροδείκτες (0)
          </li>
          <li>Υποδοχές σημειωμάτων (0)
          </li>
        </ul>
        </li>
        <li>Πίνακες (15)
        <ul>
          <li>Πίνακες μαρκαδόρου ή εξαρτήματα (0)
          </li>
          <li>Πίνακες κιμωλίας ή εξαρτήματα (0)
          </li>
          <li>Πίνακες ανακοινώσεων ή εξαρτήματα (0)
          </li>
          <li>Πίνακες χαρακτήρων ή εξαρτήματα (0)
          </li>
          <li>Λευκοί πίνακες και μαγνητικοί πίνακες (6)
          <ul>
            <li>Λευκοί πίνακες (3)
            <ul>
              <li>Καβαλέτα για περιστρεφόμενους πίνακες (0)
              </li>
              <li>Εξαρτήματα για λευκούς πίνακες (0)
              </li>
              <li>Καβαλέτα για λευκούς πίνακες (0)
              </li>
            </ul>
            </li>
            <li>Μαγνητικοί πίνακες (1)
            <ul>
              <li>Σβηστήρες για μαγνητικούς πίνακες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Σετ καθαρισμού πινάκων ή εξαρτήματα (0)
          </li>
          <li>Αναρτώμενες ράγες ή υποδοχές (0)
          </li>
          <li>Πλανογράμματα ή εξαρτήματα (0)
          </li>
          <li>Ηλεκτρονικοί πίνακες αντιγραφής ή εξαρτήματα (0)
          </li>
        </ul>
        </li>
        <li>Προμήθειες σχεδίασης (15)
        <ul>
          <li>Καμπύλες (0)
          </li>
          <li>Κουκίδες, ταινίες και μεμβράνες σχεδίασης (2)
          <ul>
            <li>Μεμβράνες σχεδίασης (0)
            </li>
            <li>Κουκίδες ή ταινίες σχεδίασης (0)
            </li>
          </ul>
          </li>
          <li>Κιτ, σετ και χαρτί σχεδίασης (2)
          <ul>
            <li>Κιτ ή σετ σχεδίασης (0)
            </li>
            <li>Χαρτί σχέδιασης (0)
            </li>
          </ul>
          </li>
          <li>Γωνιόμετρα (0)
          </li>
          <li>Καλύμματα τραπεζών σχεδίασης (0)
          </li>
          <li>Βοθήματα απόδοσης συμβόλων και χαρακτήρων (0)
          </li>
          <li>Γνώμονες σχεδίασης σε σχήμα "ταυ" και τρίγωνα (2)
          <ul>
            <li>Τρίγωνα (0)
            </li>
            <li>Γνώμονες σχεδίασης σε σχήμα "ταυ" (0)
            </li>
          </ul>
          </li>
          <li>Προστατευτικά καλύμματα επιφανειών εργασίας (0)
          </li>
          <li>Πρότυπα σχεδίασης (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός γραφείου εκτός από έπιπλα (7)
        <ul>
          <li>Επιδιασκόπια (0)
          </li>
          <li>Εξοπλισμός αρχειοθέτησης (4)
          <ul>
            <li>Ράφια περιοδικών (0)
            </li>
            <li>Φορητοί πίνακες σημειώσεων με συνδετήρα (0)
            </li>
            <li>Περιστρεφόμενα συστήματα ταξινόμησης καρτών (0)
            </li>
            <li>Εξαρτήματα προσωπικής αναγνώρισης (0)
            </li>
          </ul>
          </li>
          <li>Συσκευές κατατεμαχισμού εγγράφων (0)
          </li>
        </ul>
        </li>
        <li>Προμήθειες ειδών γραφείου (43)
        <ul>
          <li>Είδη για συσκευές αναπαραγωγής εγγράφων (0)
          </li>
          <li>Αυτοκόλλητες ετικέτες (0)
          </li>
          <li>Γομολάστιχες (24)
          <ul>
            <li>Πίνακες ανακοινώσεων (0)
            </li>
            <li>Μολύβια (4)
            <ul>
              <li>Μηχανικά στυλό (0)
              </li>
              <li>Μολυβοθήκες (0)
              </li>
              <li>Ανταλλακτικές μύτες μολυβιών (0)
              </li>
              <li>Ξύστρες μολυβιών (0)
              </li>
            </ul>
            </li>
            <li>Σφραγίδες αναγραφής ημερομηνιών (5)
            <ul>
              <li>Ανταλλακτικοί μελανωτήρες (0)
              </li>
              <li>Σφραγίδες με φράσεις (0)
              </li>
              <li>Σφραγίδες αρίθμησης (0)
              </li>
              <li>Υποδοχές σφραγίδων γραφείου (0)
              </li>
              <li>Σφραγίδες σφάγισης (0)
              </li>
            </ul>
            </li>
            <li>Μελάνια (3)
            <ul>
              <li>Μελανωτήρες για εκτυπωτικά μηχανήματα (0)
              </li>
              <li>Ταμπόν μελάνης (0)
              </li>
              <li>Φυσίγγες μελάνης (0)
              </li>
            </ul>
            </li>
            <li>Διορθωτικά (0)
            </li>
            <li>Μαρκαδόροι επισήμανσης (0)
            </li>
            <li>Στυλογράφοι διαρκείας με μπίλια (0)
            </li>
            <li>Μηχανικοί στυλογράφοι (0)
            </li>
            <li>Στυλογράφοι μελάνης (0)
            </li>
            <li>Μαρκαδοράκια (0)
            </li>
            <li>Μαρκαδόροι (0)
            </li>
            <li>Θήκες στυλογράφων (0)
            </li>
          </ul>
          </li>
          <li>Διορθωτικά μέσα (5)
          <ul>
            <li>Ηλεκτρικοί διαγραφείς (0)
            </li>
            <li>Διορθωτικό υγρό (0)
            </li>
            <li>Διορθωτικές μεμβράνες ή ταινίες (0)
            </li>
            <li>Διορθωτικά στυλό (0)
            </li>
            <li>Ανταλλακτικά διορθωτικών στυλό (0)
            </li>
          </ul>
          </li>
          <li>Μελανοταινίες (5)
          <ul>
            <li>Μελανοταινίες συσκευών τηλεομοιοτυπίας (0)
            </li>
            <li>Μελανοταινίες και κύλινδροι αριθμομηχανών (0)
            </li>
            <li>Μελανοταινίες αριθμομηχανών (0)
            </li>
            <li>Μελανοταινίες γραφομηχανών (0)
            </li>
            <li>Μελανοταινίες εκτυπωτών (0)
            </li>
          </ul>
          </li>
          <li>Διαφάνειες μηχανημάτων προβολής (0)
          </li>
          <li>Γραφική ύλη (0)
          </li>
          <li>Ταινίες μέτρησης (0)
          </li>
          <li>Πίνακες σχεδίασης (0)
          </li>
        </ul>
        </li>
        <li>Μηχανές λαχειοφόρων αγορών (1)
        <ul>
          <li>Κληρωτίδες (0)
          </li>
        </ul>
        </li>
        <li>Γραφική ύλη και άλλα είδη από χαρτί (39)
        <ul>
          <li>Χαρτί επιχρισμένο με γόμα ή αυτοκόλλητο χαρτί (1)
          <ul>
            <li>Αυτοκόλλητο χαρτί (0)
            </li>
          </ul>
          </li>
          <li>Κουτιά ταξινόμησης εγγράφων, επιστολοθήκες, κουτιά αρχειοθέτησης και παρόμοια είδη (0)
          </li>
          <li>Καρμπόν, αυτοαντιγραφικό χαρτί, μεμβράνες πολυγράφων και μη αντιγραφικό χαρτί (4)
          <ul>
            <li>Χάρτινες μεμβράνες πολυγράφου (0)
            </li>
            <li>Μη αντιγραφικό χαρτί (0)
            </li>
            <li>Καρμπόν (0)
            </li>
            <li>Αυτοαντιγραφικό χαρτί (0)
            </li>
          </ul>
          </li>
          <li>Διαχωριστικά για χαρτικά είδη (0)
          </li>
          <li>Φάκελοι, επιστολικά δελτάρια και μη εικονογραφημένες ταχυδρομικές κάρτες (4)
          <ul>
            <li>Επιστολικά δελτάρια (0)
            </li>
            <li>Μη εικονογραφημένες ταχυδρομικές κάρτες (0)
            </li>
            <li>Φάκελοι (0)
            </li>
            <li>Εξοπλισμός αλληλογραφίας (0)
            </li>
          </ul>
          </li>
          <li>Χαρτικά είδη εκτός από έντυπα (19)
          <ul>
            <li>Χαρτί σημειώσεων/Επιστολόχαρτο/Χαρτί αλληλογραφίας (0)
            </li>
            <li>Ετικέτες (3)
            <ul>
              <li>Αντικλεπτικές ετικέτες (0)
              </li>
              <li>Ετικέτες αποσκευών (0)
              </li>
              <li>Ετικέτες με αποτυπωμένες κωδικοποιημένες ραβδώσεις (0)
              </li>
            </ul>
            </li>
            <li>Τυπωμένοι φάκελοι (3)
            <ul>
              <li>Τυπωμένοι φάκελοι για ακτινογραφίες (0)
              </li>
              <li>Τυπωμένοι φάκελοι χωρίς διαφανή θυρίδα (0)
              </li>
              <li>Τυπωμένοι φάκελοι με διαφανή θυρίδα (0)
              </li>
            </ul>
            </li>
            <li>Επαγγελματικές κάρτες (1)
            <ul>
              <li>Θήκες επαγγελματικών καρτών (0)
              </li>
            </ul>
            </li>
            <li>Αυτοκόλλητο πρόχειρο σημείωμα (0)
            </li>
            <li>Κουπόνια (0)
            </li>
            <li>Κουπόνια φαγητού (0)
            </li>
            <li>Εκτυπωμένα ωράρια δρομολογίων (3)
            <ul>
              <li>Οργανογράμματα τοίχου (0)
              </li>
              <li>Βάσεις ημερολογίων (0)
              </li>
              <li>Ημερολόγια (0)
              </li>
            </ul>
            </li>
            <li>Στυπόχαρτα (0)
            </li>
          </ul>
          </li>
          <li>Ανάγλυφα ή διάτρητα χαρτιά (4)
          <ul>
            <li>Ανάγλυφο ή διάτρητο εκτυπωτικό χαρτί (0)
            </li>
            <li>Συνεχή έντυπα (0)
            </li>
            <li>Συνεχές χαρτί για εκτυπωτές ηλεκτρονικών υπολογιστών (0)
            </li>
            <li>Ανάγλυφο ή διάτρητο χαρτί γραφής (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Συστήματα προγραμματισμού (3)
        <ul>
          <li>Θυρίδες υποβολής προτάσεων (0)
          </li>
          <li>Οργανωτές συναντήσεων (0)
          </li>
          <li>Ατζέντες για συναντήσεις ή ανταλλακτικά φύλλα (0)
          </li>
        </ul>
        </li>
        <li>Μικρός εξοπλισμός γραφείου (27)
        <ul>
          <li>Χαρτοκόπτες, συρραπτικά και διατρητήρες (4)
          <ul>
            <li>Χαρτοκόπτες (0)
            </li>
            <li>Διατρητήρες (0)
            </li>
            <li>Συρραπτικά (1)
            <ul>
              <li>Αποσυρραπτικά (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Επεξεργασμένο χαρτί και χαρτόνι (10)
          <ul>
            <li>Εκτυπωτικό χαρτί (0)
            </li>
            <li>Αυτοαντιγραφικό χαρτί ή άλλο αντιγραφικό χαρτί (5)
            <ul>
              <li>Φωτοαντιγραφικό και ξηρογραφικό χαρτί (0)
              </li>
              <li>Κάρτες εκτύπωσης (0)
              </li>
              <li>Θερμογραφικό χαρτί (0)
              </li>
              <li>Φωτοαντιγραφικό χαρτί (0)
              </li>
              <li>Ξηρογραφικό χαρτί (0)
              </li>
            </ul>
            </li>
            <li>Χαρτί γραφής (1)
            <ul>
              <li>Μπλοκ περιστρεφόμενων πινάκων (0)
              </li>
            </ul>
            </li>
            <li>Σύνθετο χαρτί και χαρτόνι (0)
            </li>
          </ul>
          </li>
          <li>Βουλοκέρι (1)
          <ul>
            <li>Εξαρτήματα για βουλοκέρι (0)
            </li>
          </ul>
          </li>
          <li>Δαχτυλοβρεχτήρες για γραμματόσημα (0)
          </li>
          <li>Συνδετήρες συρραφής, πρόκες, πινέζες (3)
          <ul>
            <li>Πρόκες (0)
            </li>
            <li>Συνδετήρες συρραφής (0)
            </li>
            <li>Πινέζες (0)
            </li>
          </ul>
          </li>
          <li>Ελαστικοί δακτύλιοι φακέλων και συνδετήρες χαρτιών (3)
          <ul>
            <li>Ελαστικοί δακτύλιοι φακέλων (0)
            </li>
            <li>Συνδετήρες χαρτιών (1)
            <ul>
              <li>Θήκες συνδετήρων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Φωτοαντιγραφικές συσκευές και εκτυπωτικά μηχανήματα όφσετ (40)
      <ul>
        <li>Μηχανές γραφείου και επαγγελματικές μηχανές (9)
        <ul>
          <li>Μηχανήματα αυτόματης διάθεσης τραπεζογραμματίων (0)
          </li>
          <li>Μηχανές πτύχωσης (0)
          </li>
          <li>Μηχανές χειρισμού κερμάτων (3)
          <ul>
            <li>Μηχανές συσκευασίας κερμάτων (0)
            </li>
            <li>Μηχανές διαλογής κερμάτων (0)
            </li>
            <li>Μηχανές καταμέτρησης κερμάτων (0)
            </li>
          </ul>
          </li>
          <li>Μηχανήματα αναπαραγωγής με μεμβράνη (0)
          </li>
          <li>Μηχανές διάτρησης (0)
          </li>
          <li>Μηχανές ακύρωσης εισιτηρίων (0)
          </li>
        </ul>
        </li>
        <li>Μέρη και εξαρτήματα φωτοαντιγραφικών συσκευών (4)
        <ul>
          <li>Φυσίγγες σκόνης τόνερ (3)
          <ul>
            <li>Τόνερ για κέντρα επεξεργασίας δεδομένων, έρευνας και τεκμηρίωσης (0)
            </li>
            <li>Τόνερ για φωτοαντιγραφικές μηχανές (0)
            </li>
            <li>Τόνερ για εκτυπωτές λέιζερ/συσκευές τηλεομοιοτυπίας (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Μέρη και εξαρτήματα μηχανών γραφείου (13)
        <ul>
          <li>Κιτ σύντηξης (0)
          </li>
          <li>Μονάδες σύντηξης (5)
          <ul>
            <li>Έλαιο μονάδας σύντηξης (0)
            </li>
            <li>Φίλτρα μονάδων σύντηξης (0)
            </li>
            <li>Πανάκια καθαρισμού μονάδων σύντηξης (0)
            </li>
            <li>Ψήκτρα μονάδας τήξης (0)
            </li>
            <li>Λυχνίες μονάδας σύντηξης (0)
            </li>
          </ul>
          </li>
          <li>Εξαρτήματα σαρωτών (3)
          <ul>
            <li>Μηχανές οπισθογράφησης (0)
            </li>
            <li>Προσαρμογείς διαφανειών για σαρωτές (0)
            </li>
            <li>Τροφοδότες εγγράφων για σαρωτές (0)
            </li>
          </ul>
          </li>
          <li>Κασέτες συρραφής (0)
          </li>
          <li>Κύλινδροι για μηχανήματα γραφείου (0)
          </li>
        </ul>
        </li>
        <li>Φωτοαντιγραφικές και θερμοαντιγραφικές συσκευές (7)
        <ul>
          <li>Μηχανήματα αναπαραγωγής εγγράφων (0)
          </li>
          <li>Φωτοαντιγραφικές συσκευές (0)
          </li>
          <li>Φωτοαντιγραφικά (0)
          </li>
          <li>Μηχανές αναπαραγωγής εγγράφων (3)
          <ul>
            <li>Συσκευές τηλεομοιοτυπίας (0)
            </li>
            <li>Ψηφιακοί πολύγραφοι (0)
            </li>
            <li>Ψηφιακοί πομποί (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εκτυπωτικά μηχανήματα όφσετ γραφείου (2)
        <ul>
          <li>Συστήματα ψηφιακής εκτύπωσης όφσετ (0)
          </li>
          <li>Εξοπλισμός ψηφιακής εκτύπωσης όφσετ (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μηχανές οπισθογράφησης και συμπλήρωσης επιταγών (2)
      <ul>
        <li>Μηχανές συμπλήρωσης επιταγών (0)
        </li>
        <li>Μηχανές οπισθογράφησης επιταγών (0)
        </li>
      </ul>
      </li>
      <li>Μηχανές ετικεταρίσματος (9)
      <ul>
        <li>Εξοπλισμός απόδοσης χαρακτήρων και συμβόλων (0)
        </li>
        <li>Μηχανές ετικετοποίησης (0)
        </li>
        <li>Μηχανές εκτύπωσης αναγνωριστικού κωδικού (0)
        </li>
        <li>Αυτόματα συστήματα ετικεταρίσματος (0)
        </li>
        <li>Διανομείς ετικετών (0)
        </li>
        <li>Μηχανές ανάγλυφης εκτύπωσης σε ταινίες (0)
        </li>
        <li>Ημιαυτόματα συστήματα ετικεταρίσματος (0)
        </li>
        <li>Μηχανές επικόλλησης ετικετών (0)
        </li>
        <li>Μηχανές χρονολόγησης ή αρίθμησης (0)
        </li>
      </ul>
      </li>
      <li>Μηχανήματα επεξεργασίας κειμένου (1)
      <ul>
        <li>Επεξεργαστές κειμένων (0)
        </li>
      </ul>
      </li>
      <li>Αριθμομηχανές και λογιστικές μηχανές (15)
      <ul>
        <li>Μηχανές με ενσωματωμένη υπολογιστική διάταξη (4)
        <ul>
          <li>Μηχανήματα έκδοσης εισιτηρίων (0)
          </li>
          <li>Ταχυδρομικές μηχανές γραμματοσήμανσης (0)
          </li>
          <li>Αυτόματη είσπραξη ναύλου με ρίψη κέρματος (0)
          </li>
          <li>Μηχανήματα μέτρησης οχημάτων (0)
          </li>
        </ul>
        </li>
        <li>Υπολογιστικές μηχανές (4)
        <ul>
          <li>Αριθμομηχανές τσέπης (0)
          </li>
          <li>Αριθμομηχανές με δυνατότητα εκτύπωσης (0)
          </li>
          <li>Μηχανές πρόσθεσης (0)
          </li>
          <li>Επιτραπέζιες αριθμομηχανές (0)
          </li>
        </ul>
        </li>
        <li>Λογιστικές μηχανές και ταμειακές μηχανές (2)
        <ul>
          <li>Λογιστικές μηχανές (0)
          </li>
          <li>Ταμειακές μηχανές (0)
          </li>
        </ul>
        </li>
        <li>Μέρη και εξαρτήματα υπολογιστικών μηχανών (1)
        <ul>
          <li>Χαρτοταινίες αριθμομηχανών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μαγνητικές κάρτες (4)
      <ul>
        <li>Έξυπνες κάρτες (0)
        </li>
        <li>Κάρτες χρέωσης (1)
        <ul>
          <li>Πιστωτικές κάρτες καυσίμων (0)
          </li>
        </ul>
        </li>
        <li>Πιστωτικές κάρτες (0)
        </li>
      </ul>
      </li>
      <li>Γραφομηχανές (2)
      <ul>
        <li>Ηλεκτρονικές γραφομηχανές (0)
        </li>
        <li>Μέρη και εξαρτήματα γραφομηχανών (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός ταχυδρομείου (15)
      <ul>
        <li>Εξοπλισμός διαλογής (3)
        <ul>
          <li>Μηχανές διαλογής (0)
          </li>
          <li>Εξοπλισμός διαλογής αλληλογραφίας (0)
          </li>
          <li>Μηχανές μέτρησης τραπεζογραμματίων (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ταξινόμησης αλληλογραφίας (8)
        <ul>
          <li>Μηχανές πλήρωσης φακέλων (0)
          </li>
          <li>Μηχανές ανοίγματος αλληλογραφίας (0)
          </li>
          <li>Μηχανές πτύχωσης χαρτιού ή φακέλων (0)
          </li>
          <li>Μηχανές σφράγισης αλληλογραφίας (0)
          </li>
          <li>Μηχανές γραμματοσήμανσης (0)
          </li>
          <li>Μηχανές αποτύπωσης ταχυδρομικών τελών (0)
          </li>
          <li>Μηχανές ακύρωσης γραμματοσήμων (0)
          </li>
          <li>Μηχανές αποτύπωσης διευθύνσεων (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ταχυδρόμησης (1)
        <ul>
          <li>Εξοπλισμός ταχυδρόμησης φακέλων χύδην (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Εξοπλισμός ραδιοφωνίας, τηλεόρασης, επικοινωνιών, τηλεπικοινωνιών και συναφής εξοπλισμός (225)
  <ul>
    <li className='top-level'>Τηλεοράσεις και ραδιόφωνα, συσκευές εγγραφής και αναπαραγωγής ήχου ή εικόνας (90)
    <ul>
      <li>Τηλεοπτικός και οπτικοακουστικός εξοπλισμός (19)
      <ul>
        <li>Τηλεοπτικοί δέκτες (7)
        <ul>
          <li>Εξοπλισμός τηλεόρασης (1)
          <ul>
            <li>Δορυφορικές κεραίες (0)
            </li>
          </ul>
          </li>
          <li>Κεραίες τηλεόρασης (0)
          </li>
          <li>Τηλεοπτικοί δέκτες ψηφιακής τηλεόρασης (0)
          </li>
          <li>Έγχρωμοι τηλεοπτικοί δέκτες (0)
          </li>
          <li>Συντονιστές τηλεοπτικής εικόνας (0)
          </li>
          <li>Ασπρόμαυροι τηλεοπτικοί δέκτες (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές τηλεοπτικής προβολής (3)
        <ul>
          <li>Οπτικοακουστικό υλικό (0)
          </li>
          <li>Εξοπλισμός κινηματογραφικών ταινιών και βίντεο (0)
          </li>
          <li>Οπτικοακουστικός εξοπλισμός (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός πολυμέσων (0)
        </li>
        <li>Οθόνες οπτικής απεικόνισης (5)
        <ul>
          <li>Εξοπλισμός αναπαραγωγής ταινιών βίντεο (0)
          </li>
          <li>Εξοπλισμός βίντεο (0)
          </li>
          <li>Ασπρόμαυρες οθόνες (0)
          </li>
          <li>Σύστημα βιντεοπαρακολούθησης (0)
          </li>
          <li>Έγχρωμες οθόνες παρακολούθησης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μικρόφωνα και μεγάφωνα (28)
      <ul>
        <li>Μικρόφωνα (0)
        </li>
        <li>Ενισχυτές (2)
        <ul>
          <li>Ενισχυτές ακουστικών συχνοτήτων (0)
          </li>
          <li>Φορητά μεγάφωνα (0)
          </li>
        </ul>
        </li>
        <li>Μεγάφωνα (11)
        <ul>
          <li>Ακουστικά αυτιών (0)
          </li>
          <li>Μικρόφωνα και συγκροτήματα μεγαφώνων (0)
          </li>
          <li>Ακουστικά κεφαλής (0)
          </li>
          <li>Συσκευές ακουστικής (7)
          <ul>
            <li>Εξοπλισμός ήχου (2)
            <ul>
              <li>Ηχεία (0)
              </li>
              <li>Ηχεία πολύ μικρών διαστάσεων (0)
              </li>
            </ul>
            </li>
            <li>Σύστημα φωνητικών ανακοινώσεων (0)
            </li>
            <li>Κονσόλα μείξης ήχου για στούντιο (0)
            </li>
            <li>Σύστημα συμπύκνωσης ηχητικών εγγραφών (0)
            </li>
            <li>Συσκευές εγγραφής φωνητικών μηνυμάτων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Συσκευές λήψης ραδιοτηλεφωνίας ή ραδιοτηλεγραφίας (11)
        <ul>
          <li>Ραδιοφωνικοί δέκτες (8)
          <ul>
            <li>Ραδιοφωνικές εγκαταστάσεις (0)
            </li>
            <li>Πύργος ραδιοφωνίας (0)
            </li>
            <li>Εξοπλισμός ασύρματης επικοινωνίας (0)
            </li>
            <li>Εξοπλισμός ραδιοφωνίας και πολυπλεκτών (0)
            </li>
            <li>Συσκευές ασύρματης τηλεειδοποίησης (0)
            </li>
            <li>Ραδιοφωνικοί σταθμοί (0)
            </li>
            <li>Φορητές ασύρματες συσκευές (0)
            </li>
            <li>Σύστημα ελέγχου ασύρματης επικοινωνίας και τηλεφωνίας (0)
            </li>
          </ul>
          </li>
          <li>Φορητοί δέκτες κλήσης και τηλεειδοποίησης (1)
          <ul>
            <li>Σύστημα καταγραφής φωνητικών δεδομένων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός ενδοεπικοινωνίας (0)
      </li>
      <li>Δέκτες ραδιοφωνικής μετάδοσης (0)
      </li>
      <li>Μέρη εξοπλισμού ήχου και εικόνας (22)
      <ul>
        <li>Εγγραφή ήχου (2)
        <ul>
          <li>Κασέτες εγγραφής ήχου (0)
          </li>
          <li>Μουσικοί δίσκοι (0)
          </li>
        </ul>
        </li>
        <li>Κεραίες και ανακλαστήρες (2)
        <ul>
          <li>Μέρη εξοπλισμού ραδιοεπικοινωνίας και ραντάρ (0)
          </li>
          <li>Ανταλλακτικά και εξαρτήματα ραντάρ (0)
          </li>
        </ul>
        </li>
        <li>Προϊόντα για φιλμ (10)
        <ul>
          <li>Ακτινολογικό φιλμ (2)
          <ul>
            <li>Φιλμ ακτίνων Χ (0)
            </li>
            <li>Φιλμ τύπου blue diazo (0)
            </li>
          </ul>
          </li>
          <li>Κινηματογραφικό φιλμ (0)
          </li>
          <li>Βιντεοκασέτες (0)
          </li>
          <li>Βιντεοταινίες (0)
          </li>
          <li>Φιλμ βίντεο (0)
          </li>
          <li>Φωτογραφικό φιλμ (0)
          </li>
          <li>Κολλητικές ταινίες (0)
          </li>
          <li>Φιλμ άμεσης εκτύπωσης (0)
          </li>
        </ul>
        </li>
        <li>Εξαρτήματα εξοπλισμού ήχου και εικόνας (4)
        <ul>
          <li>Εξαρτήματα εξοπλισμού ήχου (1)
          <ul>
            <li>Κασέτες ήχου (0)
            </li>
          </ul>
          </li>
          <li>Αντιανεμικά φύλλα (0)
          </li>
          <li>Εξοπλισμός επιμέλειας ταινιών βίντεο (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Συσκευές για την εγγραφή και αναπαραγωγή ήχου και εικόνας (15)
      <ul>
        <li>Μαγνητόφωνα (3)
        <ul>
          <li>Μηχανές υπαγόρευσης (0)
          </li>
          <li>Συσκευές ηχογράφησης (0)
          </li>
          <li>Αυτόματοι τηλεφωνητές (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές εγγραφής ή αναπαραγωγής εικόνας (4)
        <ul>
          <li>Συσκευές εγγραφής εικόνας (0)
          </li>
          <li>Συσκευές αναπαραγωγής εικόνας (0)
          </li>
          <li>Βιντεοκάμερες (0)
          </li>
          <li>Συσκευές απλής αναπαραγωγής εικόνας (0)
          </li>
        </ul>
        </li>
        <li>Συστήματα περιστροφής δίσκων (5)
        <ul>
          <li>Πικάπ (0)
          </li>
          <li>Συσκευές εγγραφής (0)
          </li>
          <li>Συσκευές αναπαραγωγής αρχείων MP3 (0)
          </li>
          <li>Κασετόφωνα (0)
          </li>
          <li>Συσκευές αναπαραγωγής ήχου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Συσκευές εκπομπής για ραδιοτηλεφωνία, ραδιοτηλεγραφία, ραδιοφωνική μετάδοση και τηλεόραση (24)
    <ul>
      <li>Συσκευές ψηφιακής μετάδοσης (0)
      </li>
      <li>Συσκευές εκπομπής τηλεόρασης χωρίς συσκευές λήψης (4)
      <ul>
        <li>Συσκευές μετάδοσης τηλεοπτικών εκπομπών (0)
        </li>
        <li>Συσκευές μετάδοσης εικόνας (0)
        </li>
        <li>Ραδιοφάροι (0)
        </li>
        <li>Μηχανές κωδικοποίησης σήματος εικόνας (0)
        </li>
      </ul>
      </li>
      <li>Κινητά τηλέφωνα (5)
      <ul>
        <li>Τηλέφωνα αυτοκινήτου (1)
        <ul>
          <li>Σετ ανοιχτής ακρόασης (0)
          </li>
        </ul>
        </li>
        <li>Τηλέφωνα GSM (2)
        <ul>
          <li>Κινητά τηλέφωνα ανοιχτής ακρόασης (1)
          <ul>
            <li>Κινητά τηλέφωνα ανοιχτής ακρόασης (ασύρματα) (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός ραδιοφωνικών και τηλεοπτικών μεταδόσεων (1)
      <ul>
        <li>Εξοπλισμός παραγωγής μεταδόσεων (0)
        </li>
      </ul>
      </li>
      <li>Τηλεοπτικές μηχανές λήψης (0)
      </li>
      <li>Συσκευές ραδιοφωνικής εκπομπής με συσκευές λήψης (7)
      <ul>
        <li>Συσκευές τηλεόρασης κλειστού κυκλώματος (0)
        </li>
        <li>Εξοπλισμός τηλεδιασκέψεων (0)
        </li>
        <li>Ραδιοτηλέφωνα (0)
        </li>
        <li>Φορητά ραδιοτηλέφωνα (0)
        </li>
        <li>Σταθμοί ενίσχυσης ραδιοσυχνοτήτων (0)
        </li>
        <li>Σύστημα παρακολούθησης κλειστού κυκλώματος (0)
        </li>
        <li>Κάμερες κλειστού κυκλώματος τηλεόρασης (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός μετάδοσης δεδομένων (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Τηλεπικοινωνιακό υλικό (73)
    <ul>
      <li>Τηλεφωνικός εξοπλισμός (28)
      <ul>
        <li>Ηλεκτρικές συσκευές για την ενσύρματη τηλεφωνία ή την ενσύρματη τηλεγραφία (20)
        <ul>
          <li>Θυροτηλέφωνα (0)
          </li>
          <li>Τηλεφωνικές συσκευές (6)
          <ul>
            <li>Ασύρματα τηλέφωνα (0)
            </li>
            <li>Εξοπλισμός για τηλεφωνικούς κερματοδέκτες (0)
            </li>
            <li>Τηλέφωνα για άτομα με μειωμένη όραση (0)
            </li>
            <li>Δημόσια τηλέφωνα (0)
            </li>
            <li>Τηλέφωνα για άτομα με μειωμένη ακοή (0)
            </li>
            <li>Τηλέφωνα έκτακτης ανάγκης (0)
            </li>
          </ul>
          </li>
          <li>Συσκευές μετατροπής σήματος ακουστικής συχνότητας (3)
          <ul>
            <li>Μετατροπείς συχνοτήτων (0)
            </li>
            <li>Μόντεμ (0)
            </li>
            <li>Συσκευές κωδικοποίησης (0)
            </li>
          </ul>
          </li>
          <li>Τηλεφωνικές ή τηλεγραφικές συσκευές μεταγωγής (3)
          <ul>
            <li>Ψηφιακά τηλεφωνικά κέντρα (0)
            </li>
            <li>Τηλεφωνικές συσκευές μεταγωγής (0)
            </li>
            <li>Πολυπλέκτες (0)
            </li>
          </ul>
          </li>
          <li>Συσκευές τηλεκειμενογραφίας (2)
          <ul>
            <li>Εξοπλισμός τέλεξ (0)
            </li>
            <li>Τερματικά τηλεεικονογραφίας (0)
            </li>
          </ul>
          </li>
          <li>Μονάδες οπτικής απεικόνισης (0)
          </li>
        </ul>
        </li>
        <li>Μέρη ηλεκτρικών τηλεφωνικών ή τηλεγραφικών συσκευών (0)
        </li>
        <li>Καλώδια και εξοπλισμός τηλεφωνίας (5)
        <ul>
          <li>Τηλεφωνικές συνδέσεις (0)
          </li>
          <li>Συστήματα τηλεφωνικών διακλαδώσεων (0)
          </li>
          <li>Ακουστικά για τηλεφωνητές (0)
          </li>
          <li>Τηλεφωνικά καλώδια (0)
          </li>
          <li>Δίκτυο τηλεφωνίας (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός επικοινωνιών (6)
      <ul>
        <li>Καλώδια επικοινωνιών (3)
        <ul>
          <li>Καλώδια επικοινωνιών με πολλαπλούς ηλεκτρικούς αγωγούς (0)
          </li>
          <li>Καλώδια επικοινωνιών με ομοαξονικούς αγωγούς (0)
          </li>
          <li>Καλώδια επικοινωνιών για ειδικές εφαρμογές (0)
          </li>
        </ul>
        </li>
        <li>Σύστημα ελέγχου επικοινωνιών (0)
        </li>
        <li>Υποδομή επικοινωνιών (0)
        </li>
      </ul>
      </li>
      <li>Καλώδια και εξοπλισμός τηλεπικοινωνιών (4)
      <ul>
        <li>Σύστημα τηλεπικοινωνιών (0)
        </li>
        <li>Καλώδια τηλεπικοινωνιών (0)
        </li>
        <li>Εγκαταστάσεις τηλεπικοινωνιών (0)
        </li>
        <li>Εξοπλισμός τηλεπικοινωνιών (0)
        </li>
      </ul>
      </li>
      <li>Υλικό οπτικών ινών (5)
      <ul>
        <li>Συνδέσεις οπτικών ινών (0)
        </li>
        <li>Καλώδια οπτικών ινών (3)
        <ul>
          <li>Καλώδια οπτικών ινών για μετάδοση πληροφοριών (0)
          </li>
          <li>Καλώδια οπτικών ινών για μετάδοση δεδομένων (0)
          </li>
          <li>Καλώδια οπτικών ινών για τηλεπικοινωνίες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός δικτύου δεδομένων (10)
      <ul>
        <li>Φορείς δεδομένων και φωνής (0)
        </li>
        <li>Μέσα καταγραφής δεδομένων (0)
        </li>
        <li>Φορείς δεδομένων (0)
        </li>
        <li>Εξοπλισμός επικοινωνίας δεδομένων (6)
        <ul>
          <li>Εξοπλισμός τηλεομοιοτυπίας (1)
          <ul>
            <li>Εξαρτήματα και δομικά μέρη εξοπλισμού τηλεομοιοτυπίας (0)
            </li>
          </ul>
          </li>
          <li>Καλώδια διαβίβασης δεδομένων (3)
          <ul>
            <li>Καλώδια διαβίβασης δεδομένων με πολλαπλούς ηλεκτρικούς αγωγούς (0)
            </li>
            <li>Καλώδια διαβίβασης δεδομένων με ομαξονικούς αγωγούς (0)
            </li>
            <li>Καλώδια διαβίβασης δεδομένων για ειδικές εφαρμογές (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Πίνακες διακοπτών (8)
      <ul>
        <li>Εξοπλισμός αυτόματων τηλεφωνικών κέντρων (0)
        </li>
        <li>Τηλεφωνικά κέντρα (0)
        </li>
        <li>Πίνακες τηλεφωνικών κέντρων (0)
        </li>
        <li>Τηλεφωνικοί πίνακες εν κενώ (0)
        </li>
        <li>Εξοπλισμός τηλεφωνικών κέντρων (0)
        </li>
        <li>Εξοπλισμός ψηφιακών τηλεφωνικών κέντρων (1)
        <ul>
          <li>Ψηφιακοί πίνακες διακοπτών (0)
          </li>
        </ul>
        </li>
        <li>Συστήματα αυτόματων τηλεφωνικών κέντρων (0)
        </li>
      </ul>
      </li>
      <li>Σύστημα ασύρματης τηλεπικοινωνίας (0)
      </li>
      <li>Εξοπλισμός σχετιζόμενος με τις επικοινωνίες μέσω δορυφόρου (4)
      <ul>
        <li>Επίγειοι δορυφορικοί σταθμοί (0)
        </li>
        <li>Δορυφορικές πλατφόρμες (0)
        </li>
        <li>Εξοπλισμός επικοινωνιών μέσω δορυφόρου (0)
        </li>
        <li>Πιάτα κεραιών δορυφορικής λήψης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Δίκτυα (34)
    <ul>
      <li>Τερματικά και εξοπλισμός τηλεμετρίας (9)
      <ul>
        <li>Εξοπλισμός τηλεμετρίας (3)
        <ul>
          <li>Σύστημα τηλεματικής (0)
          </li>
          <li>Εξοπλισμός τηλεμετρίας και ελέγχου (0)
          </li>
          <li>Τηλεμετρικό σύστημα παρακολούθησης (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός τερματικών (4)
        <ul>
          <li>Πίνακες τερματικών (0)
          </li>
          <li>Εξομοιωτές τερματικών (0)
          </li>
          <li>Τερματικές μονάδες εισόδου-εξόδου καλωδίων (0)
          </li>
          <li>Κυτία τερματικών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός δικτύου (9)
      <ul>
        <li>Καλωδίωση δικτύου (0)
        </li>
        <li>Εκδοτικά συστήματα μέσω δικτύου (0)
        </li>
        <li>Κόμβοι δικτύου (0)
        </li>
        <li>Λειτουργικό σύστημα δικτύου (0)
        </li>
        <li>Εξοπλισμός δικτύου τηλεφωνίας (0)
        </li>
        <li>Αναβάθμιση δικτύου (0)
        </li>
        <li>Σύστημα δικτύου (0)
        </li>
        <li>Μέρη δικτύου (0)
        </li>
        <li>Υποδομή δικτύου (0)
        </li>
      </ul>
      </li>
      <li>Τοπικό δίκτυο (12)
      <ul>
        <li>Δίκτυο Ethernet (0)
        </li>
        <li>Δίκτυα πολυμέσων (0)
        </li>
        <li>Δίκτυο επικοινωνιών (3)
        <ul>
          <li>Δίκτυο τηλεπικοινωνιών (2)
          <ul>
            <li>Δίκτυο Ιντερνέτ (0)
            </li>
            <li>Δίκτυο Ιντρανέτ (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Ραδιοδίκτυο (0)
        </li>
        <li>Ψηφιακό δίκτυο ολοκληρωμένων υπηρεσιών (ISDN) (1)
        <ul>
          <li>Δίκτυο ISDX (0)
          </li>
        </ul>
        </li>
        <li>Ολοκληρωμένο δίκτυο (1)
        <ul>
          <li>Δρομείς δικτύου (0)
          </li>
        </ul>
        </li>
        <li>Δακτυλιοειδές δίκτυο αδειοδοτικού (0)
        </li>
      </ul>
      </li>
      <li>Δίκτυο ευρείας ζώνης (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Ηλεκτρικές μηχανές, συσκευές, εξοπλισμός και αναλώσιμα· φωτισμός (362)
  <ul>
    <li className='top-level'>Ηλεκτροκινητήρες, ηλεκτρογεννήτριες και ηλεκτρικοί μετασχηματιστές (58)
    <ul>
      <li>Ηλεκτρικοί κινητήρες (1)
      <ul>
        <li>Προσαρμογείς (0)
        </li>
      </ul>
      </li>
      <li>Μετασχηματιστές (4)
      <ul>
        <li>Μετασχηματιστές ισχύος (0)
        </li>
        <li>Μετατροπέας μετρήσεων (0)
        </li>
        <li>Μετασχηματιστές τάσης (0)
        </li>
        <li>Μετασχηματιστές με υγρό μονωτικό υλικό (0)
        </li>
      </ul>
      </li>
      <li>Γεννήτριες (19)
      <ul>
        <li>Γεννήτρια εκτάκτου ανάγκης (0)
        </li>
        <li>Δυναμό (0)
        </li>
        <li>Μονάδες γεννητριών (1)
        <ul>
          <li>Στήλες καυσίμων (0)
          </li>
        </ul>
        </li>
        <li>Στροβιλογεννήτρια (0)
        </li>
        <li>Ηλεκτρογεννήτριες με ατμοστρόβιλο και συναφείς συσκευές (2)
        <ul>
          <li>Συσκευές ελέγχου στροβιλογεννητριών (0)
          </li>
          <li>Ζεύγη στροβίλου — γεννήτριας (0)
          </li>
        </ul>
        </li>
        <li>Συγκροτήματα ηλεκτροπαραγωγών ζευγών (10)
        <ul>
          <li>Ανεμογεννήτριες (5)
          <ul>
            <li>Ανεμόμυλοι (0)
            </li>
            <li>Στρόβιλοι αιολικής ενέργειας (0)
            </li>
            <li>Στροβιλογεννήτριες αιολικής ενέργειας (1)
            <ul>
              <li>Στροφεία στροβίλου (0)
              </li>
            </ul>
            </li>
            <li>Αιολικό πάρκο (0)
            </li>
          </ul>
          </li>
          <li>Ηλεκτροπαραγωγά ζεύγη με κινητήρα του οποίου η ανάφλεξη γίνεται με σπινθήρα (0)
          </li>
          <li>Ηλεκτροπαραγωγά ζεύγη με κινητήρες ανάφλεξης μέσω συμπίεσης (2)
          <ul>
            <li>Μεταλλάκτες ηλεκτρικής ενέργειας (1)
            <ul>
              <li>Ηλεκτρικοί περιστροφικοί μεταλλάκτες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εναλλάκτες (4)
      <ul>
        <li>Μονοφασικοί κινητήρες (2)
        <ul>
          <li>Άνοδοι (0)
          </li>
          <li>Ενεργοποιητές (0)
          </li>
        </ul>
        </li>
        <li>Πολυφασικοί κινητήρες (0)
        </li>
      </ul>
      </li>
      <li>Πύργοι ψύξης (1)
      <ul>
        <li>Ψύκτες νερού (0)
        </li>
      </ul>
      </li>
      <li>Αδρανές αέριο για λαμπτήρες ή σωλήνες εκκένωσης (10)
      <ul>
        <li>Φορτιστές (3)
        <ul>
          <li>Υπερσυμπιεστές (0)
          </li>
          <li>Φορτιστές μπαταριών (0)
          </li>
          <li>Στροβιλοϋπερπληρωτής (0)
          </li>
        </ul>
        </li>
        <li>Διακοπτόμενα τροφοδοτικά ρεύματος (0)
        </li>
        <li>Επαγωγείς (0)
        </li>
        <li>Στατικοί μεταλλάκτες (0)
        </li>
        <li>Αναστροφείς συχνότητας (0)
        </li>
        <li>Αδιάλειπτα τροφοδοτικά ρεύματος (0)
        </li>
        <li>Ανορθωτές (0)
        </li>
      </ul>
      </li>
      <li>Μέρη ηλεκτροκινητήρων, ηλεκτρογεννητριών και ηλεκτρικών μετασχηματιστών (12)
      <ul>
        <li>Μέρη ηλεκτρικών μετασχηματιστών, επαγωγικών πηνίων και στατικών μετατροπέων (1)
        <ul>
          <li>Μέρη συμπυκνωτών (0)
          </li>
        </ul>
        </li>
        <li>Μέρη ηλεκτρικών κινητήρων και γεννητριών (9)
        <ul>
          <li>Περιστρεφόμενο τύμπανο γεννητριών (0)
          </li>
          <li>Πρωτεύοντα συστήματα νερού (0)
          </li>
          <li>Συστήματα ελέγχου ηλεκτρικής τάσης (0)
          </li>
          <li>Συστήματα ύδατος ψύξης για στάτορες (0)
          </li>
          <li>Συστήματα ψύξης αερίου (0)
          </li>
          <li>Συστήματα διέγερσης (0)
          </li>
          <li>Μέρη ατμογεννητριών (0)
          </li>
          <li>Συστήματα στεγάνωσης με λάδι (0)
          </li>
          <li>Μέρη αεριογεννητριών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Συσσωρευτές, πρωτογενή στοιχεία και πρωτογενείς συστοιχίες (11)
    <ul>
      <li>Μπαταρίες (0)
      </li>
      <li>Πρωτογενείς συστοιχίες (2)
      <ul>
        <li>Συγκροτήματα συσσωρευτών (0)
        </li>
        <li>Στοιχεία συσσωρευτών από μόλυβδο (0)
        </li>
      </ul>
      </li>
      <li>Πρωτογενή στοιχεία (1)
      <ul>
        <li>Αλκαλικές μπαταρίες (0)
        </li>
      </ul>
      </li>
      <li>Ηλεκτρικοί συσσωρευτές (4)
      <ul>
        <li>Συσσωρευτές νικελίου-σιδήρου (0)
        </li>
        <li>Συσσωρευτές νικελίου-καδμίου (0)
        </li>
        <li>Συσσωρευτές μολύβδου-οξέος (0)
        </li>
        <li>Συσσωρευτές λιθίου (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Συσκευές διανομής και ελέγχου ηλεκτρικού ρεύματος (65)
    <ul>
      <li>Στοιχεία ηλεκτρικού κυκλώματος (19)
      <ul>
        <li>Συνδέσεις και στοιχεία επαφών (9)
        <ul>
          <li>Ομοαξονικοί συζευκτήρες (0)
          </li>
          <li>Καλώδια συνδέσεων (0)
          </li>
          <li>Κυτία συνδέσεων (0)
          </li>
          <li>Κιβώτια σύνδεσης (0)
          </li>
          <li>Ρεοστάτες (0)
          </li>
          <li>Τερματικά (0)
          </li>
          <li>Σετ υλικών για τη σύνδεση καλωδίων (1)
          <ul>
            <li>Καλώδια επέκτασης (0)
            </li>
          </ul>
          </li>
          <li>Ρευματολήπτες και ρευματοδότες (0)
          </li>
        </ul>
        </li>
        <li>Ηλεκτρονόμοι (7)
        <ul>
          <li>Ηλεκτρονόμοι εναλλασσόμενης τάσης (0)
          </li>
          <li>Ηλεκτρονόμοι ισχύος (0)
          </li>
          <li>Ηλεκτρονόμοι ρευματοδοτών (0)
          </li>
          <li>Ηλεκτρονόμοι γενικής χρήσης (0)
          </li>
          <li>Ηλεκτρονόμοι υπερφόρτωσης (0)
          </li>
          <li>Ηλεκτρονόμοι με χρονοδιακόπτη (0)
          </li>
          <li>Ηλεκτρονόμοι υδραργύρου (0)
          </li>
        </ul>
        </li>
        <li>Υποδοχές λαμπτήρων (0)
        </li>
      </ul>
      </li>
      <li>Μέρη συσκευών ελέγχου ή διανομής ηλεκτρικού ρεύματος (0)
      </li>
      <li>Ηλεκτρικές συσκευές για τη διακοπή ή την προστασία ηλεκτρικών κυκλωμάτων (43)
      <ul>
        <li>Καταστολείς υπέρτασης ρεύματος (0)
        </li>
        <li>Μηχανισμός διακοπής (16)
        <ul>
          <li>Ηλεκτρικοί πίνακες διακοπτών (2)
          <ul>
            <li>Πίνακες διανομής (0)
            </li>
            <li>Πίνακες διανομής ρεύματος μέσης τάσης (0)
            </li>
          </ul>
          </li>
          <li>Συσκευή αποσύνδεσης διακοπτών (0)
          </li>
          <li>Ηλεκτρικοί πίνακες υπαίθρου (0)
          </li>
          <li>Διακόπτες (9)
          <ul>
            <li>Διακόπτες ασφαλείας (0)
            </li>
            <li>Διακόπτες πίεσης (0)
            </li>
            <li>Ρεοστατικοί διακόπτες (0)
            </li>
            <li>Κυλινδρικοί διακόπτες (0)
            </li>
            <li>Διακόπτες μοχλού (0)
            </li>
            <li>Οριακοί διακόπτες (0)
            </li>
            <li>Διακόπτης γείωσης (0)
            </li>
            <li>Μονωτικοί διακόπτες (0)
            </li>
            <li>Ολισθαίνοντες διακόπτες (0)
            </li>
          </ul>
          </li>
          <li>Συσκευή αποσύνδεσης ασφαλειών (0)
          </li>
        </ul>
        </li>
        <li>Ηλεκτρικοί πίνακες και κυβώτια ασφαλειών (8)
        <ul>
          <li>Κυτία ασφαλειών (0)
          </li>
          <li>Ασφάλειες (4)
          <ul>
            <li>Πλακέτες ασφαλειών (0)
            </li>
            <li>Γενικός διακόπτης ρεύματος (0)
            </li>
            <li>Συνδετήρες ασφαλειών (0)
            </li>
            <li>Σύρματα ασφαλειών (0)
            </li>
          </ul>
          </li>
          <li>Ηλεκτρικοί πίνακες (1)
          <ul>
            <li>Πίνακες ελέγχου (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός διανομής ηλεκτρικού ρεύματος (4)
        <ul>
          <li>Θάλαμος κατανομής καλωδίων (0)
          </li>
          <li>Σύστημα διανομής (0)
          </li>
          <li>Μετασχηματιστές διανομής ηλεκτρικού ρεύματος (0)
          </li>
          <li>Κυτία διανομής ηλεκτρικού ρεύματος (0)
          </li>
        </ul>
        </li>
        <li>Περιοριστές τάσης (0)
        </li>
        <li>Αποζεύκτες ισχύος (4)
        <ul>
          <li>Μαγνητικοί διακόπτες κυκλώματος (0)
          </li>
          <li>Αποζεύκτες ισχύος εναέριων γραμμών (0)
          </li>
          <li>Μικροδιακόπτες κυκλώματος (0)
          </li>
          <li>Συσκευές ελέγχου κυκλωμάτων (0)
          </li>
        </ul>
        </li>
        <li>Προστατευτικά ερμάρια (0)
        </li>
        <li>Ζυγός ηλεκτρικού πίνακα διανομής (0)
        </li>
        <li>Αλεξικέραυνα (2)
        <ul>
          <li>Εξοπλισμός αντικεραυνικής προστασίας (0)
          </li>
          <li>Κεραυναγωγοί (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ηλεκτρονικό, ηλεκτρομηχανολογικό και ηλεκτροτεχνικό υλικό (78)
    <ul>
      <li>Ηλεκτρομηχανολογικός εξοπλισμός (0)
      </li>
      <li>Ηλεκτροτεχνικός εξοπλισμός (2)
      <ul>
        <li>Ηλεκτροτεχνικό υλικό (1)
        <ul>
          <li>Στοιχεία κατασκευής (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ηλεκτρονικός εξοπλισμός (73)
      <ul>
        <li>Μικροηλεκτρονικά μηχανήματα και συσκευές, καθώς και μικροσυστήματα (43)
        <ul>
          <li>Τυπωμένα κυκλώματα (30)
          <ul>
            <li>Τρανζίστορ (9)
            <ul>
              <li>Διπολικά τρανζίστορ Darlington ή ραδιοσυχνοτήτων (RF) (0)
              </li>
              <li>Τρανζίστορ πεδίου επαφής (JFET) (0)
              </li>
              <li>Πλινθία τρανζίστορ (0)
              </li>
              <li>Τρανζίστορ διπολικής ένωσης (BJT) (0)
              </li>
              <li>Φωτοευαίσθητα τρανζίστορ (0)
              </li>
              <li>Διπολικά τρανζίστορ μονωμένης πύλης (IGBT) (0)
              </li>
              <li>Τρανζίστορ επιδράσεως πεδίου ημιαγωγού μεταλλικού οξειδίου (MOSFET) (0)
              </li>
              <li>Τρανζίσορ μονοεπαφής (0)
              </li>
              <li>Τρανζίστορ επιδράσεως πεδίου (FET) (0)
              </li>
            </ul>
            </li>
            <li>Κατειλημμένοι πίνακες τυπωμένων κυκλωμάτων (0)
            </li>
            <li>Μη κατειλημμένοι πίνακες τυπωμένων κυκλωμάτων (0)
            </li>
            <li>Ημιαγωγοί (6)
            <ul>
              <li>Φωτοβολταϊκά στοιχεία (0)
              </li>
              <li>Ταλαντωτές κρυστάλλου (0)
              </li>
              <li>Θυρίστορ (0)
              </li>
              <li>Θυρίστορ αμφίπλευρης τριόδου (0)
              </li>
              <li>Οπτικά συζευγμένοι μονωτές (0)
              </li>
              <li>Θυρίστορ αμφίπλευρης διόδου (0)
              </li>
            </ul>
            </li>
            <li>Δίοδοι (9)
            <ul>
              <li>Δίοδοι μικροκυμάτων ή ασθενών σημάτων (0)
              </li>
              <li>Φωτοευαίσθητες δίοδοι (0)
              </li>
              <li>Δίοδοι σήραγγος (0)
              </li>
              <li>Δίοδοι ισχύος ή ηλιακές δίοδοι (0)
              </li>
              <li>Δίοδοι εκπομπής φωτός (0)
              </li>
              <li>Δίοδοι ζένερ (0)
              </li>
              <li>Δίοδοι Schottky (0)
              </li>
              <li>Δίοδοι ραδιοσυχνοτήτων (0)
              </li>
              <li>Δίοδοι λέιζερ (0)
              </li>
            </ul>
            </li>
            <li>Προσαρμοσμένοι πιεζοηλεκτρικοί κρύσταλλοι (0)
            </li>
          </ul>
          </li>
          <li>Μικροσυστήματα (0)
          </li>
          <li>Μικροηλεκτρονικές συσκευές και μηχανήμτα (10)
          <ul>
            <li>Ηλεκτρονικά ολοκληρωμένα κυκλώματα και μικροσύνολα (9)
            <ul>
              <li>Πακέτα ολοκληρωμένων κυκλωμάτων (0)
              </li>
              <li>Μικροσύνολα (0)
              </li>
              <li>Κάρτες τηλεφώνου (0)
              </li>
              <li>Καλύμματα ολοκληρωμένων κυκλωμάτων (0)
              </li>
              <li>Μικροεπεξεργαστές (0)
              </li>
              <li>Ολοκληρωμένα ηλεκτρονικά κυκλώματα (0)
              </li>
              <li>Κάρτες SIM (0)
              </li>
              <li>Υποδοχές ή διατάξεις στερέωσης ολοκληρωμένων κυκλωμάτων (0)
              </li>
              <li>Κάρτες με ολοκληρωμένα κυκλώματα (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Ηλεκτρονικό υλικό (28)
        <ul>
          <li>Ηλεκτρονικοί πίνακες γηπέδων (0)
          </li>
          <li>Ηλεκτρονικές λυχνίες και λυχνίες ραδιοφώνου (9)
          <ul>
            <li>Λυχνίες τηλεόρασης καθοδικών ακτίνων (1)
            <ul>
              <li>Λυχνίες μηχανημάτων τηλεοπτικής λήψης (0)
              </li>
            </ul>
            </li>
            <li>Ηλεκτρονικές λυχνίες (0)
            </li>
            <li>Λυχνίες μικροκυμάτων και εξοπλισμός (4)
            <ul>
              <li>Εξοπλισμός ασύρματης επικοινωνίας με μικροκύματα (0)
              </li>
              <li>Λυχνίες κλύστρον (0)
              </li>
              <li>Λυχνίες μάγνετρον (0)
              </li>
              <li>Εξοπλισμός μικροκυμάτων (0)
              </li>
            </ul>
            </li>
            <li>Λυχνίες δεκτών ή ενισχυτών (0)
            </li>
          </ul>
          </li>
          <li>Εξαρτήματα ηλεκτρονικών ειδών (10)
          <ul>
            <li>Ηλεκτρόδια (0)
            </li>
            <li>Ηλεκτρικοί πυκνωτές (4)
            <ul>
              <li>Σταθεροί πυκνωτές (0)
              </li>
              <li>Μεταβλητοί ή ρυθμιζόμενοι πυκνωτές (0)
              </li>
              <li>Συστοιχίες πυκνωτών (0)
              </li>
              <li>Δίκτυα πυκνωτών (0)
              </li>
            </ul>
            </li>
            <li>Μετατροπέας μορφών ενέργειας (0)
            </li>
            <li>Αντιστάσεις (1)
            <ul>
              <li>Ηλεκτρικές αντιστάσεις (0)
              </li>
            </ul>
            </li>
            <li>Πομποδέκτες (0)
            </li>
          </ul>
          </li>
          <li>Ηλεκτρονικά συστήματα χρονομέτρησης (1)
          <ul>
            <li>Σύστημα καταγραφής χρόνου παρουσίας (0)
            </li>
          </ul>
          </li>
          <li>Μέρη ηλεκτρονικών συνόλων (3)
          <ul>
            <li>Μέρη ηλεκτρονικών λυχνιών και λυχνιών ραδιοφώνου (0)
            </li>
            <li>Μέρη ηλεκτρικών αντιστάσεων, ροοστατών και ποτενσιομέτρων (0)
            </li>
            <li>Μέρη ηλεκτρικών πυκνωτών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μονωμένα σύρματα και καλώδια (21)
    <ul>
      <li>Ηλεκτρικοί αγωγοί για χρήση σε δίκτυα δεδομένων και ελέγχου (1)
      <ul>
        <li>Ηλεκτρικοί αγωγοί για συστήματα ελέγχου πρόσβασης (0)
        </li>
      </ul>
      </li>
      <li>Ομοαξονικά καλώδια (0)
      </li>
      <li>Εξαρτήματα καλωδίων, μονωμένα (4)
      <ul>
        <li>Στυπιοθλίπτες καλωδίων, μονωμένες (0)
        </li>
        <li>Ζεύξεις καλωδίων, μονωμένες (0)
        </li>
        <li>Συνδέσεις καλωδίων, μονωμένες (0)
        </li>
        <li>Εξέλικτρα καλωδίων, μονωμένα (0)
        </li>
      </ul>
      </li>
      <li>Αγωγοί τροφοδοσίας δικτύου (1)
      <ul>
        <li>Συνδέσεις κύριων αγωγών (0)
        </li>
      </ul>
      </li>
      <li>Καλώδια διανομής ηλεκτρικού ρεύματος (10)
      <ul>
        <li>Γραμμές ηλεκτρικού ρεύματος (9)
        <ul>
          <li>Εναέριες ηλεκτρικές γραμμές (0)
          </li>
          <li>Υποβρύχια καλώδια (0)
          </li>
          <li>Καλώδια χαμηλής και μέσης τάσης (2)
          <ul>
            <li>Καλώδια χαμηλής τάσης (0)
            </li>
            <li>Καλώδια μέσης τάσης (0)
            </li>
          </ul>
          </li>
          <li>Καλώδια με θωράκιση (0)
          </li>
          <li>Υποθαλάσσια καλώδια (0)
          </li>
          <li>Καλώδια υψηλής τάσης (0)
          </li>
          <li>Καλώδια σηματοδότησης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Φωτιστικός εξοπλισμός και ηλεκτρικοί λαμπτήρες (68)
    <ul>
      <li>Λαμπτήρες και φωτιστικά εξαρτήματα (25)
      <ul>
        <li>Ηλεκτρικές λάμπες (6)
        <ul>
          <li>Φωτιστικά δαπέδου (0)
          </li>
          <li>Επιτραπέζια πορτατίφ (0)
          </li>
          <li>Φορητοί ηλεκτρικοί λαμπτήρες (3)
          <ul>
            <li>Προειδοποιητικά φώτα (0)
            </li>
            <li>Επαναφορτιζόμενοι φορητοί ηλεκτρικοί λαμπτήρες (0)
            </li>
            <li>Ηλεκτρικοί φορητοί φανοί (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Φωτιστικά σώματα δέσμης φωτός (6)
        <ul>
          <li>Υποβρύχιοι λαμπτήρες (0)
          </li>
          <li>Φωτιστικά εξωτερικών χώρων (3)
          <ul>
            <li>Λυχνοστάτες (0)
            </li>
            <li>Φωτιστικά συστήματα (0)
            </li>
            <li>Φωτιστικά για εξέδρες (0)
            </li>
          </ul>
          </li>
          <li>Φωτιστικά κατοικιών (0)
          </li>
        </ul>
        </li>
        <li>Φωτιστικά για χριστουγεννιάτικα δένδρα (0)
        </li>
        <li>Εξαρτήματα φωτιστικών οροφής ή τοίχου (5)
        <ul>
          <li>Εξαρτήματα φωτιστικών οροφής (2)
          <ul>
            <li>Φωτιστικά σώματα χειρουργείου (0)
            </li>
            <li>Φωτιστικά οροφής (0)
            </li>
          </ul>
          </li>
          <li>Εξαρτήματα φωτιστικών τοίχου (1)
          <ul>
            <li>Φωτιστικά τοίχου (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Φωτεινές επιγραφές και πινακίδες (3)
        <ul>
          <li>Φωτεινές επιγραφές (0)
          </li>
          <li>Διαφημιστικοί λαμπτήρες νέον (0)
          </li>
          <li>Πινακίδες μονίμων μηνυμάτων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ηλεκτρικοί λαμπτήρες πυράκτωσης (20)
      <ul>
        <li>Λαμπτήρες εκκένωσης (0)
        </li>
        <li>Λαμπτήρες υπεριώδων ακτίνων (0)
        </li>
        <li>Λαμπτήρες πυρακτώσεως και νέον (2)
        <ul>
          <li>Λαμπτήρες πυρακτώσεως (0)
          </li>
          <li>Λαμπτήρες νέον (0)
          </li>
        </ul>
        </li>
        <li>Λαμπτήρες πυράκτωσης βολφραμίου-αλογόνου (3)
        <ul>
          <li>Λαμπτήρες αλογόνου, δύο ακίδων (0)
          </li>
          <li>Λαμπτήρες αλογόνου, γραμμικοί (0)
          </li>
          <li>Λαμπτήρες αλογόνου, διχρωικοί (0)
          </li>
        </ul>
        </li>
        <li>Μονάδες σφραγισμένων λαμπτήρων εκπομπής δέσμης φωτός (0)
        </li>
        <li>Λαμπτήρες υπέρυθρων ακτίνων (0)
        </li>
        <li>Λυχνίες βολταϊκού τόξου (0)
        </li>
        <li>Φωτιστικά σηματοδότησης (7)
        <ul>
          <li>Φωτιστικά στέγης (0)
          </li>
          <li>Προβολείς φωτισμού (0)
          </li>
          <li>Εξοπλισμός φωτισμού εκτάκτου ανάγκης (2)
          <ul>
            <li>Ράβδοι φωτισμού (0)
            </li>
            <li>Λάμπες θυέλλης (0)
            </li>
          </ul>
          </li>
          <li>Προβολείς (0)
          </li>
          <li>Λαμπτήρες ατμών υδραργύρου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μέρη λαμπών και φωτιστικού εξοπλισμού (20)
      <ul>
        <li>Λαμπτήρες (1)
        <ul>
          <li>Σωλήνες φωτισμού (0)
          </li>
        </ul>
        </li>
        <li>Μέρη λαμπών και φωτιστικών εξαρτημάτων (17)
        <ul>
          <li>Λυχνίες (2)
          <ul>
            <li>Λυχνίες φθορισμού (0)
            </li>
            <li>Λυχνίες φθορισμού μικρών διαστάσεων (0)
            </li>
          </ul>
          </li>
          <li>Ακροδέκτες λυχνίας (0)
          </li>
          <li>Πηνία λαμπτήρων (1)
          <ul>
            <li>Πηνία λαμπήρων φθορισμού (0)
            </li>
          </ul>
          </li>
          <li>Σφαιρικοί λαμπτήρες (1)
          <ul>
            <li>Σφαιρικοί λαμπτήρες φθορισμού μικρών διαστάσεων (0)
            </li>
          </ul>
          </li>
          <li>Βραχίονες φωτιστικών (0)
          </li>
          <li>Φώτα φθορισμού (2)
          <ul>
            <li>Λυχνίες και λαμπτήρες φθορισμού (0)
            </li>
            <li>Λαμπτήρες φθορισμού (0)
            </li>
          </ul>
          </li>
          <li>Εκκινητές λυχνίας (1)
          <ul>
            <li>Εκκινητές λαμπτήρων φθορισμού (0)
            </li>
          </ul>
          </li>
          <li>Δακτυλιοειδείς προβολείς (1)
          <ul>
            <li>Προβολείς φθορισμού (0)
            </li>
          </ul>
          </li>
          <li>Καλύμματα λαμπτήρων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ηλεκτρολογικός εξοπλισμός (54)
    <ul>
      <li>Ηλεκτρικά μέρη μηχανημάτων ή συσκευών (3)
      <ul>
        <li>Γυάλινα περιβλήματα και καθοδικές λυχνίες (2)
        <ul>
          <li>Γυάλινα περιβλήματα (0)
          </li>
          <li>Καθοδικές λυχνίες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ηλεκτρολογικός εξοπλισμός για κινητήρες και οχήματα (5)
      <ul>
        <li>Σετ καλωδίων (0)
        </li>
        <li>Κλώνοι ηλεκτρικών καλωδίων για κινητήρες (3)
        <ul>
          <li>Εκκινητήρες (0)
          </li>
          <li>Ηλεκτρικός εξοπλισμός σηματοδότησης για κινητήρες (1)
          <ul>
            <li>Προειδοποιητικοί σηματοδότες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ηλεκτρικές μηχανές και συσκευές που έχουν ειδικές λειτουργίες (10)
      <ul>
        <li>Ηλεκτρονικές συσκευές ανίχνευσης (5)
        <ul>
          <li>Συσκευές ανίχνευσης ναρκών (0)
          </li>
          <li>Συσκευές ανίχνευσης πλαστικών υλών (0)
          </li>
          <li>Συσκευές ανίχνευσης ξυλείας (0)
          </li>
          <li>Συσκευές ανίχνευσης για μεταλλικούς σωλήνες (0)
          </li>
          <li>Συσκευές ανίχνευσης μη μεταλλικών αντικειμένων (0)
          </li>
        </ul>
        </li>
        <li>Επιταχυντές σωματιδίων (1)
        <ul>
          <li>Γραμμικοί επιταχυντές (0)
          </li>
        </ul>
        </li>
        <li>Μηχανές φλίπερ (0)
        </li>
        <li>Διάφορες συσκευές καταγραφής δεδομένων (0)
        </li>
      </ul>
      </li>
      <li>Προμήθειες ηλεκτρικών ειδών και εξαρτήματα (23)
      <ul>
        <li>Υλικά παροχής ηλεκτρικού ρεύματος (15)
        <ul>
          <li>Εξοπλισμός ρεύματος μέσης τάσης (1)
          <ul>
            <li>Πίνακες ρεύματος μέσης τάσης (0)
            </li>
          </ul>
          </li>
          <li>Εφεδρικός ηλεκτρικός εξοπλισμός (4)
          <ul>
            <li>Εφεδρικά συστήματα παροχής ενέργειας (0)
            </li>
            <li>Εξοπλισμός υποσταθμού (0)
            </li>
            <li>Εφεδρικά συστήματα παραγωγής ενέργειας (0)
            </li>
            <li>Συστήματα διακοπής ρεύματος σε περίπτωση έκτακτης ανάγκης (0)
            </li>
          </ul>
          </li>
          <li>Υπέργειος ηλεκτρολογικός εξοπλισμός (1)
          <ul>
            <li>Φορείς υπέργειων καλωδίων (0)
            </li>
          </ul>
          </li>
          <li>Πίνακες οργάνων (3)
          <ul>
            <li>Όργανα και εξοπλισμός ελέγχου (0)
            </li>
            <li>Πίνακες απεικόνισης γραφικών (0)
            </li>
            <li>Πίνακες μείξης (0)
            </li>
          </ul>
          </li>
          <li>Κυτία ηλεκτρικού ρεύματος (1)
          <ul>
            <li>Καλύμματα για κυτία ηλεκτρικού ρεύματος (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Ηλεκτρολογικά εξαρτήματα (6)
        <ul>
          <li>Ηλεκτρικές επαφές (0)
          </li>
          <li>Ηλεκτρικά κυκλώματα (0)
          </li>
          <li>Συσκευές φόρτισης (0)
          </li>
          <li>Ηλεκτρικές αντλίες (0)
          </li>
          <li>Μέρη ηλεκτρικών συσκευών (1)
          <ul>
            <li>Ηλεκτρολογικό υλικό (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ηλεκτρόδια άνθρακα (0)
      </li>
      <li>Μονωτικά εξαρτήματα (1)
      <ul>
        <li>Ηλεκτρικές ταινίες (0)
        </li>
      </ul>
      </li>
      <li>Ηλεκτρικές συσκευές ηχητικής ή οπτικής σηματοδότησης (4)
      <ul>
        <li>Συστήματα συναγερμού κλοπής και πυρκαγιάς (3)
        <ul>
          <li>Συστήματα πυρανίχνευσης (0)
          </li>
          <li>Συστήματα συναγερμού πυρκαγιάς (0)
          </li>
          <li>Συστήματα αντιδιαρρηκτικού συναγερμού (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μαγνήτες (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Εξοπλισμός μεταφοράς και βοηθητικά μέσα μεταφοράς (467)
  <ul>
    <li className='top-level'>Μέρη και εξαρτήματα για αυτοκίνητα οχήματα και για τους κινητήρες τους (49)
    <ul>
      <li>Καθίσματα αεροσκαφών πολιτικής αεροπορίας (0)
      </li>
      <li>Καθίσματα αυτοκίνητων οχημάτων (0)
      </li>
      <li>Μηχανές και μέρη τους (οχήματα) (12)
      <ul>
        <li>Μέρη κινητήρων (7)
        <ul>
          <li>Πιστόνια (0)
          </li>
          <li>Στεγανοποιητικά παρεμβύσματα (0)
          </li>
          <li>Ελαστικοί ιμάντες μεταφοράς (0)
          </li>
          <li>Ψυγεία οχημάτων (0)
          </li>
          <li>Σπινθηριστές (0)
          </li>
          <li>Ελαστικοί ιμάντες μετάδοσης (0)
          </li>
          <li>Ιμάντες ανεμιστήρων (0)
          </li>
        </ul>
        </li>
        <li>Μηχανές (οχήματα) (3)
        <ul>
          <li>Κινητήρες εσωτερικής καύσης για αυτοκίνητα οχήματα και μοτοσικλέτες (2)
          <ul>
            <li>Κινητήρες εσωτερικής καύσης με ανάφλεξη μέσω συμπίεσης (0)
            </li>
            <li>Κινητήρες εσωτερικής καύσης με ανάφλεξη μέσω σπινθηριστή (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξαρτήματα ελκυστήρων (0)
      </li>
      <li>Μηχανολογικά ανταλλακτικά εκτός από κινητήρες και μέρη τους (24)
      <ul>
        <li>Τροχοί, μέρη και εξαρτήματά τους (1)
        <ul>
          <li>Εξοπλισμός ζυγοστάθμισης τροχών (0)
          </li>
        </ul>
        </li>
        <li>Τράπεζες δοκιμών, κιτ μετατροπής οχημάτων και ζώνες ασφαλείας (3)
        <ul>
          <li>Τράπεζες δοκιμών (0)
          </li>
          <li>Ζώνες ασφαλείας (0)
          </li>
          <li>Κιτ μετατροπής οχημάτων (0)
          </li>
        </ul>
        </li>
        <li>Φρένα και μέρη τους (5)
        <ul>
          <li>Τακάκια φρένων (0)
          </li>
          <li>Σιαγόνες φρένων (0)
          </li>
          <li>Εξοπλισμός φρένων (0)
          </li>
          <li>Επενδύσεις φρένων (0)
          </li>
          <li>Δισκόφρενα (0)
          </li>
        </ul>
        </li>
        <li>Τιμόνια, άξονες και κιβώτια διεύθυνσης (2)
        <ul>
          <li>Άξονες και κιβώτια (0)
          </li>
          <li>Τιμόνια (0)
          </li>
        </ul>
        </li>
        <li>Γρύλλοι οχημάτων, συμπλέκτες και μέρη τους (2)
        <ul>
          <li>Γρύλλοι οχημάτων (0)
          </li>
          <li>Συμπλέκτες και μέρη τους (0)
          </li>
        </ul>
        </li>
        <li>Άξονες και κιβώτια ταχυτήτων (2)
        <ul>
          <li>Κιβώτια ταχυτήτων (0)
          </li>
          <li>Άξονες τροχών (0)
          </li>
        </ul>
        </li>
        <li>Σιγαστήρες και εξατμίσεις (2)
        <ul>
          <li>Εξατμίσεις (0)
          </li>
          <li>Σιγαστήρες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ανταλλακτικά για οχήματα μεταφοράς αγαθών, μικρά φορτηγά και αυτοκίνητα (0)
      </li>
      <li>Ελαστικά ελαφράς και βαρέας χρήσεως (6)
      <ul>
        <li>Ελαστικά επίσωτρα βαρέας χρήσεως (3)
        <ul>
          <li>Ελαστικά επίσωτρα φορτηγών αυτοκινήτων (0)
          </li>
          <li>Ελαστικά επίσωτρα γεωργικών οχημάτων (0)
          </li>
          <li>Ελαστικά επίσωτρα λεωφορείων (0)
          </li>
        </ul>
        </li>
        <li>Ελαστικά ελαφράς χρήσεως (1)
        <ul>
          <li>Ελαστικά επίσωτρα αυτοκινήτων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Αεροσκάφη και διαστημόπλοια (36)
    <ul>
      <li>Ελικόπτερα, αεροπλάνα, διαστημικά οχήματα και άλλα αεροσκάφη μηχανικής προώθησης (11)
      <ul>
        <li>Διαστημόπλοια, δορυφόροι και οχήματα εκτόξευσης (3)
        <ul>
          <li>Συστήματα εκτόξευσης διαστημοπλοίων (0)
          </li>
          <li>Δορυφόροι (0)
          </li>
          <li>Διαστημόπλοια (0)
          </li>
        </ul>
        </li>
        <li>Ελικόπτερα και αεροπλάνα (6)
        <ul>
          <li>Αεροσκάφη με πιλότο (0)
          </li>
          <li>Αεροσκάφη ειδικής χρήσης (0)
          </li>
          <li>Αεροσκάφη χωρίς πιλότο (0)
          </li>
          <li>Αεροπλάνα (1)
          <ul>
            <li>Αεροσκάφη (0)
            </li>
          </ul>
          </li>
          <li>Ελικόπτερα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Αερόστατα, πηδαλιουχούμενα και άλλα μη μηχανοκίνητα αεροσκάφη (5)
      <ul>
        <li>Ανεμόπτερα (1)
        <ul>
          <li>Αιωρόπτερα (0)
          </li>
        </ul>
        </li>
        <li>Αερόστατα και πηδαλιουχούμενα (2)
        <ul>
          <li>Αερόστατα (0)
          </li>
          <li>Πηδαλιουχούμενα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός αεροσκαφών ή διαστημοπλοίων, συστήματα εκπαίδευσης, προσομοιωτές και συναφή μέρη (7)
      <ul>
        <li>Εξοπλισμός αεροσκαφών (6)
        <ul>
          <li>Συστήματα καταπελτών για την εκτόξευση αεροσκαφών (0)
          </li>
          <li>Μηχανισμός εκτόξευσης αεροσκαφών (0)
          </li>
          <li>Συσκευές εδάφους για εκπαίδευση στην πτήση (0)
          </li>
          <li>Σάκοι για την αεροναυτία (0)
          </li>
          <li>Ανασχετικός μηχανισμός προσγείωσης αεροσκαφών (0)
          </li>
          <li>Προσομοιωτές πτήσης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μέρη αεροσκαφών, διαστημικών σκαφών και ελικοπτέρων (9)
      <ul>
        <li>Μέρη αεροσκαφών (8)
        <ul>
          <li>Μέρη ελικοπτέρων (0)
          </li>
          <li>Ελαστικά επίσωτρα αεροσκαφών (0)
          </li>
          <li>Μέρη κινητήρων αεροσκαφών (0)
          </li>
          <li>Κινητήρες αεροσκαφών (0)
          </li>
          <li>Αεροσκάφη με στροβιλοκινητήρα αεριοπροώσεως (0)
          </li>
          <li>Αεροσκάφη με στροβιλοκίνητο ελικοφόρο κινητήρα (0)
          </li>
          <li>Κινητήρες αεριοπροώσεως (0)
          </li>
          <li>Μέρη στροβιλοκινητήρων προώσεως ή ελικοστροβιλοκινητήρων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Αμαξώματα για αυτοκίνητα οχήματα, ρυμουλκούμενα και ημιρυμουλκούμενα οχήματα (25)
    <ul>
      <li>Αμαξώματα οχημάτων (4)
      <ul>
        <li>Αμαξώματα λεωφορείων, αμαξώματα ασθενοφόρων και αμαξώματα για οχήματα μεταφοράς εμπορευμάτων (3)
        <ul>
          <li>Αμαξώματα για οχήματα μεταφοράς εμπορευμάτων (0)
          </li>
          <li>Αμαξώματα λεωφορείων (0)
          </li>
          <li>Αμαξώματα ασθενοφόρων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ρυμουλκούμενα, ημιρυμουλκούμενα και κινητά εμπορευματοκιβώτια (19)
      <ul>
        <li>Κινητά εμπορευματοκιβώτια ειδικής χρήσης (3)
        <ul>
          <li>Κινητές μονάδες ατυχημάτων (0)
          </li>
          <li>Κινητές μονάδες εκτάκτου ανάγκης (0)
          </li>
          <li>Μονάδες χημικών ατυχημάτων (0)
          </li>
        </ul>
        </li>
        <li>Ρυμουλκούμενα και ημιρυμουλκούμενα οχήματα (11)
        <ul>
          <li>Βυτιοφόρα οχήματα ανεφοδιασμού αεροπλάνων (0)
          </li>
          <li>Ρυμουλκούμενα οχήματα (7)
          <ul>
            <li>Ρυμουλκούμενα οχήματα μεταφοράς ίππων (0)
            </li>
            <li>Ρυμουλκούμενα βυτία (0)
            </li>
            <li>Ρυμουλκούμενα οχήματα περιστρεφόμενων κλιμάκων (0)
            </li>
            <li>Ρυμουλκούμενα οχήματα γενικής χρήσης (0)
            </li>
            <li>Ρυμουλκούμενα βυτία ανεφοδιασμού καυσίμων (0)
            </li>
            <li>Ανατρεπόμενα ρυμουλκούμενα οχήματα (0)
            </li>
            <li>Κινητές ρυμουλκούμενες μονάδες (0)
            </li>
          </ul>
          </li>
          <li>Ημιρυμουλκούμενα (0)
          </li>
          <li>Ρυμουλκούμενα και ημιρυμουλκούμενα τύπου τροχόσπιτου (0)
          </li>
        </ul>
        </li>
        <li>Μέρη ρυμουλκουμένων, ημιρυμουλκουμένων και άλλων οχημάτων (2)
        <ul>
          <li>Μέρη ρυμουλκουμένων και ημιρυμουλκουμένων (0)
          </li>
          <li>Μέρη άλλων οχημάτων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μοτοσικλέτες, δίκυκλα και σάιντ-καρ (12)
    <ul>
      <li>Μοτοσικλέτες (4)
      <ul>
        <li>Μέρη και εξαρτήματα μοτοσικλετών (3)
        <ul>
          <li>Ελαστικά επίσωτρα μοτοσικλετών (0)
          </li>
          <li>Σάιντ-καρ (1)
          <ul>
            <li>Μέρη και εξαρτήματα για σάιντ-καρ (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Σκούτερ και μοτοποδήλατα με επικουρικό κινητήρα (2)
      <ul>
        <li>Μοτοποδήλατα με επικουρικό κινητήρα (0)
        </li>
        <li>Σκούτερ (0)
        </li>
      </ul>
      </li>
      <li>Ποδήλατα (3)
      <ul>
        <li>Μέρη και εξαρτήματα για δίκυκλα (1)
        <ul>
          <li>Ελαστικά επίσωτρα ποδηλάτων (0)
          </li>
        </ul>
        </li>
        <li>Δίκυκλα χωρίς κινητήρα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Αυτοκίνητα οχήματα (86)
    <ul>
      <li>Αυτοκίνητα οχήματα μεγάλης αντοχής (36)
      <ul>
        <li>Αυτοκίνητα οχήματα ειδικής χρήσης (30)
        <ul>
          <li>Οχήματα για τις υπηρεσίες εκτάκτου ανάγκης (5)
          <ul>
            <li>Οχήματα οδικής βοήθειας (0)
            </li>
            <li>Πυροσβεστικά οχήματα (3)
            <ul>
              <li>Υδροφόρες πυροσβεστικών οχημάτων (0)
              </li>
              <li>Φορτηγά με περιστρεφόμενη κλίμακα (0)
              </li>
              <li>Πυροσβεστικές αντλίες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Αυτοκινούμενα τροχόσπιτα (0)
          </li>
          <li>Οχήματα περισυλλογής απορριμμάτων και ακάθαρτων νερών (4)
          <ul>
            <li>Βυτία μεταφοράς αποχετευτικών λυμάτων (0)
            </li>
            <li>Οχήματα περισυλλογής απορριμμάτων (2)
            <ul>
              <li>Οχήματα συμπίεσης απορριμμάτων (0)
              </li>
              <li>Απορριμματοφόρα οχήματα με συμπιεστή απορριμμάτων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Οχήματα κοινής ωφελείας (6)
          <ul>
            <li>Τροχοφόροι φορτωτές (0)
            </li>
            <li>Οχήματα ρυμούλκησης αεροσκαφών (0)
            </li>
            <li>Οχήματα μεταφοράς φορτίων (1)
            <ul>
              <li>Αυτοκινούμενοι γερανοί για τη διακίνηση εμπορευμάτων (0)
              </li>
            </ul>
            </li>
            <li>Οχήματα ανεφοδιασμού καυσίμων για αεροπλάνα (0)
            </li>
            <li>Οχήματα κινητών βιβλιοθηκών (0)
            </li>
          </ul>
          </li>
          <li>Ηλεκτρικά οχήματα (1)
          <ul>
            <li>Ηλεκτρικά λεωφορεία (0)
            </li>
          </ul>
          </li>
          <li>Κινητοί πύργοι γεωτρήσεων (0)
          </li>
          <li>Οχήματα οδικής συντήρησης (6)
          <ul>
            <li>Οχήματα διάστρωσης άμμου (0)
            </li>
            <li>Οχήματα ψεκασμού (0)
            </li>
            <li>Οχήματα εκκένωσης βόθρων (0)
            </li>
            <li>Οχήματα οδικού καθαρισμού (1)
            <ul>
              <li>Αυτοκινούμενα απορροφητικά σάρωθρα (0)
              </li>
            </ul>
            </li>
            <li>Μηχανήματα διασκόρπισης αλατιού (0)
            </li>
          </ul>
          </li>
          <li>Κινητές γέφυρες (0)
          </li>
        </ul>
        </li>
        <li>Οχήματα χειμερινής συντήρησης (0)
        </li>
        <li>Γερανοφόρα φορτηγά και ανατρεπόμενα οχήματα (3)
        <ul>
          <li>Φορτωτές κάδων (0)
          </li>
          <li>Φορτηγά με ανυψωτική πλατφόρμα (0)
          </li>
          <li>Ανατρεπόμενα οχήματα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Προσομοιωτές (2)
      <ul>
        <li>Προσομοιωτές οδήγησης (0)
        </li>
        <li>Προσομοιωτές εκπαίδευσης (0)
        </li>
      </ul>
      </li>
      <li>Αυτοκίνητα οχήματα για τη μεταφορά εμπορευμάτων (17)
      <ul>
        <li>Μεταχειρισμένα οχήματα για τη μεταφορά εμπορευμάτων (0)
        </li>
        <li>Μηχανοκίνητα έλκηθρα (0)
        </li>
        <li>Ημιφορτηγά (0)
        </li>
        <li>Αρθρωτά φορτηγά οχήματα (2)
        <ul>
          <li>Βυτιοφόρα (1)
          <ul>
            <li>Βυτιοφόρα μεταφοράς καυσίμων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Φορτηγά με επίπεδη και ανατρεπόμενη καρότσα (2)
        <ul>
          <li>Φορτηγά με επίπεδη καρότσα (0)
          </li>
          <li>Φορτηγά με ανατρεπόμενη καρότσα (0)
          </li>
        </ul>
        </li>
        <li>Πλαίσια οχημάτων (3)
        <ul>
          <li>Πλαίσια οχημάτων με θάλαμο οδήγησης (0)
          </li>
          <li>Αμαξώματα πλαισίων οχημάτων (0)
          </li>
          <li>Πλήρη πλαίσια οχημάτων (0)
          </li>
        </ul>
        </li>
        <li>Οδικοί ελκυστήρες (0)
        </li>
        <li>Φορτηγάκια (2)
        <ul>
          <li>Φορτηγάκια με διαχωριστικά τοιχώματα (0)
          </li>
          <li>Μικρά φορτηγά (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Αυτοκίνητα οχήματα για τη μεταφορά 10 ή περισσοτέρων ατόμων (6)
      <ul>
        <li>Λεωφορεία και πούλμαν (5)
        <ul>
          <li>Λεωφορεία χαμηλού δαπέδου (0)
          </li>
          <li>Αρθρωτά λεωφορεία (0)
          </li>
          <li>Διώροφα λεωφορεία (0)
          </li>
          <li>Λεωφορεία δημόσιων συγκοινωνιών (0)
          </li>
          <li>Πούλμαν (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Επιβατικά αυτοκίνητα (20)
      <ul>
        <li>Άλλα επιβατηγά αυτοκίνητα (2)
        <ul>
          <li>Αυτοκίνητα οχήματα για τη μεταφορά κάτω των 10 ατόμων (0)
          </li>
          <li>Μεταχειρισμένα οχήματα για τη μεταφορά επιβατών (0)
          </li>
        </ul>
        </li>
        <li>Ειδικά οχήματα (9)
        <ul>
          <li>Αστυνομικά αυτοκίνητα (1)
          <ul>
            <li>Οχήματα μεταφοράς κρατουμένων (0)
            </li>
          </ul>
          </li>
          <li>Μικρά λεωφορεία (0)
          </li>
          <li>Οχήματα εκτάκτου ανάγκης (4)
          <ul>
            <li>Παραϊατρικά οχήματα (2)
            <ul>
              <li>Οχήματα μεταφοράς ασθενών (0)
              </li>
              <li>Ασθενοφόρα (0)
              </li>
            </ul>
            </li>
            <li>Οχήματα διάσωσης (0)
            </li>
          </ul>
          </li>
          <li>Οχήματα κοινωνικής πρόνοιας (0)
          </li>
        </ul>
        </li>
        <li>Αυτοκίνητα με κίνηση στους τέσσερις τροχούς (3)
        <ul>
          <li>Οχήματα παντός εδάφους (0)
          </li>
          <li>Αυτοκίνητα τύπου τζιπ (0)
          </li>
          <li>Οχήματα εκτός δρόμου (0)
          </li>
        </ul>
        </li>
        <li>Αυτοκίνητα τύπου στέισον και σεντάν (2)
        <ul>
          <li>Αυτοκίνητα τύπου στέισον (0)
          </li>
          <li>Αυτοκίνητα τύπου σεντάν (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μηχανές και τροχαίος εξοπλισμός σιδηροδρόμων και τροχιοδρόμων και μέρη τους (26)
    <ul>
      <li>Μέρη σιδηροδρομικών ή τροχιοδρομικών μηχανών έλξης ή τροχαίου υλικού· εξοπλισμός ελέγχου της κυκλοφορίας για σιδηροδρόμους (9)
      <ul>
        <li>Εξοπλισμός ελέγχου της κυκλοφορίας για σιδηροδρόμους (3)
        <ul>
          <li>Ηλεκτρολογικές εγκαταστάσεις σιδηροδρομικών γραμμών (0)
          </li>
          <li>Μηχανική σηματοδότηση (0)
          </li>
          <li>Ηλεκτρικός εξοπλισμός σηματοδότησης για σιδηροδρόμους (0)
          </li>
        </ul>
        </li>
        <li>Μέρη σιδηροδρομικών ή τροχιοδρομικών μηχανών έλξης ή τροχαίου υλικού (4)
        <ul>
          <li>Άξονες τροχών, επίσωτρα και άλλα μέρη σιδηροδρομικών μηχανών έλξης ή τροχαίου υλικού (0)
          </li>
          <li>Καθίσματα τροχαίου υλικού (0)
          </li>
          <li>Προφυλακτήρες και συστήματα έλξης (0)
          </li>
          <li>Τροχοί ενός τεμαχίου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Στοιχεία αυτοκινήτων (0)
      </li>
      <li>Σιδηροδρομικές μηχανές και εφοδιοφόρα (4)
      <ul>
        <li>Εφοδιοφόρα σιδηροδρομικών μηχανών και τελεφερίκ (2)
        <ul>
          <li>Εφοδιοφόρα σιδηροδρομικών μηχανών (0)
          </li>
          <li>Τελεφερίκ (0)
          </li>
        </ul>
        </li>
        <li>Σιδηροδρομικές μηχανές (0)
        </li>
      </ul>
      </li>
      <li>Τροχαίος εξοπλισμός (9)
      <ul>
        <li>Σιδηροδρομικές ή τροχιοδρομικές άμαξες μεταφοράς επιβατών και τρόλεϊ (5)
        <ul>
          <li>Τρόλεϊ (0)
          </li>
          <li>Σιδηροδρομικά βαγόνια (0)
          </li>
          <li>Επιβατικά βαγόνια αστικού σιδηροδρόμου (0)
          </li>
          <li>Σκευοφόροι και οχήματα ειδικής χρήσης (0)
          </li>
          <li>Επιβατικά βαγόνια σιδηροδρόμων (0)
          </li>
        </ul>
        </li>
        <li>Οχήματα συντήρησης ή επισκευής σιδηροδρομικών γραμμών, και σιδηροδρομικά βαγόνια για τη μεταφορά φορτίων (2)
        <ul>
          <li>Σιδηροδρομικά βαγόνια για τη μεταφορά φορτίων (0)
          </li>
          <li>Οχήματα συντήρησης και επισκευής σιδηροδρομικών γραμμών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Πλοία και πλοιάρια (61)
    <ul>
      <li>Πλοία (41)
      <ul>
        <li>Πλωτές ή καταδυόμενες εξέδρες γεώτρησης ή παραγωγής (9)
        <ul>
          <li>Πλατφόρμες ανύψωσης (0)
          </li>
          <li>Εξέδρες γεώτρησης (0)
          </li>
          <li>Πλοία γεωτρήσεων (0)
          </li>
          <li>Πλωτές εξέδρες γεώτρησης (0)
          </li>
          <li>Πλωτή εγκατάσταση παραγωγής (0)
          </li>
          <li>Εξέδρες ανοιχτής θαλάσσης (0)
          </li>
          <li>Εξοπλισμός για εξέδρες γεώτρησης (0)
          </li>
          <li>Ημιβυθιζόμενες εξέδρες γεώτρησης (0)
          </li>
          <li>Κινητή εξέδρα (0)
          </li>
        </ul>
        </li>
        <li>Αλιευτικά, σωστικά και άλλα ειδικά σκάφη (14)
        <ul>
          <li>Πλωτές αποβάθρες θαλάσσης (1)
          <ul>
            <li>Σκάφη υποστήριξης καταδύσεων (0)
            </li>
          </ul>
          </li>
          <li>Σκάφη σεισμολογικών παρατηρήσεων (1)
          <ul>
            <li>Σκάφη επίβλεψης (0)
            </li>
          </ul>
          </li>
          <li>Σκάφη ελέγχου της ρύπανσης (1)
          <ul>
            <li>Πυροσβεστικά σκάφη (0)
            </li>
          </ul>
          </li>
          <li>Αλιευτικά σκάφη (1)
          <ul>
            <li>Πλοία-εργοστάσια (0)
            </li>
          </ul>
          </li>
          <li>Ρυμουλκά πλοία (1)
          <ul>
            <li>Βυθοκόροι (0)
            </li>
          </ul>
          </li>
          <li>Σκάφη διάσωσης (1)
          <ul>
            <li>Ελαφρά σκάφη (0)
            </li>
          </ul>
          </li>
          <li>Πλωτοί γερανοί (1)
          <ul>
            <li>Σκάφη παραγωγής (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Προστατευτικά πλευρών σκάφους (0)
        </li>
        <li>Πλοία και παρόμοια σκάφη για τη μεταφορά επιβατών ή εμπορευμάτων (10)
        <ul>
          <li>Εμπορικά σκάφη (0)
          </li>
          <li>Πλοία για τη μεταφορά εμπορευματοκιβωτίων (0)
          </li>
          <li>Δεξαμενόπλοια (0)
          </li>
          <li>Κρουαζιερόπλοια (0)
          </li>
          <li>Πλοία οχηματαγωγά τύπου ρο-ρο (0)
          </li>
          <li>Πορθμεία (0)
          </li>
          <li>Πλοία για τη μεταφορά οχημάτων (1)
          <ul>
            <li>Πλοία ψυγεία (0)
            </li>
          </ul>
          </li>
          <li>Πλοία για τη μεταφορά εμπορευμάτων χύμα (0)
          </li>
          <li>Φορτηγά πλοία (0)
          </li>
        </ul>
        </li>
        <li>Πλωτές κατασκευές (2)
        <ul>
          <li>Σημαδούρες (0)
          </li>
          <li>Φουσκωτές σχεδίες (0)
          </li>
        </ul>
        </li>
        <li>Σκάφη θαλάσσιας περιπολίας (0)
        </li>
      </ul>
      </li>
      <li>Σκάφη (18)
      <ul>
        <li>Ειδικά σκάφη (4)
        <ul>
          <li>Ναυαγοσωστικά σκάφη (0)
          </li>
          <li>Περιπολικά σκάφη τελωνείου (0)
          </li>
          <li>Σκάφη επιτήρησης (0)
          </li>
          <li>Περιπολικά σκάφη αστυνομίας (0)
          </li>
        </ul>
        </li>
        <li>Σκάφη αναψυχής και αθλητικών δραστηριοτήτων (12)
        <ul>
          <li>Λέμβοι με κουπιά (0)
          </li>
          <li>Σωσίβιες λέμβοι (1)
          <ul>
            <li>Μικρά ιστιοπλοϊκά σκάφη (0)
            </li>
          </ul>
          </li>
          <li>Λέμβοι με πεντάλ (0)
          </li>
          <li>Λέμβοι από καουτσούκ (1)
          <ul>
            <li>Κανό (0)
            </li>
          </ul>
          </li>
          <li>Μικρές βάρκες (1)
          <ul>
            <li>Βάρκες από ίνες υάλου (0)
            </li>
          </ul>
          </li>
          <li>Ιστιοπλοϊκά σκάφη (1)
          <ul>
            <li>Ιστιοπλοϊκά σκάφη τύπου καταμαράν (0)
            </li>
          </ul>
          </li>
          <li>Ημιάκαμπτα σκάφη (1)
          <ul>
            <li>Φουσκωτά σκάφη (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ποικίλος εξοπλισμός μεταφοράς και ανταλλακτικά (164)
    <ul>
      <li>Εξοπλισμός σιδηροδρόμων (33)
      <ul>
        <li>Εξοπλισμός σηματοδότησης (2)
        <ul>
          <li>Σηματοδότες (0)
          </li>
          <li>Κυτία σηματοδότησης (0)
          </li>
        </ul>
        </li>
        <li>Σιδηροδρομικές γραμμές και εξαρτήματα (6)
        <ul>
          <li>Ανισόπεδες διαβάσεις σιδηροδρομικών γραμμών (0)
          </li>
          <li>Γραμμή αστικού σιδηροδρόμου (0)
          </li>
          <li>Εγκάρσιες δοκοί (0)
          </li>
          <li>Σιδηροτροχιές (0)
          </li>
          <li>Κλειδιά αλλαγής σιδηροτροχιών (0)
          </li>
          <li>Ράβδοι (0)
          </li>
        </ul>
        </li>
        <li>Συστήματα θέρμανσης σιδηροδρομικών συζευκτικών κόμβων (0)
        </li>
        <li>Μηχάνημα χάραξης ευθύγραμμης τροχιάς (0)
        </li>
        <li>Στρωτήρες και μέρη στρωτήρων (2)
        <ul>
          <li>Μέρη στρωτήρων (0)
          </li>
          <li>Διασυνδετικές δοκοί (0)
          </li>
        </ul>
        </li>
        <li>Σύστημα παρακολούθησης τραίνων (0)
        </li>
        <li>Υλικά και προμήθειες κατασκευής σιδηροδρομικών γραμμών (16)
        <ul>
          <li>Υλικό κατασκευής σιδηροδρομικών γραμμών (10)
          <ul>
            <li>Καθίσματα και υλικό στερέωσης καθισμάτων (0)
            </li>
            <li>Ηλεκτρικά αγώγιμες σιδηροτροχιές (0)
            </li>
            <li>Συνδετήρες, πλάκες και σύνδεσμοι σιδηροτροχιών (2)
            <ul>
              <li>Συνδετήρες σιδηροτροχιών (0)
              </li>
              <li>Πλάκες και σύνδεσμοι σιδηροτροχιών (0)
              </li>
            </ul>
            </li>
            <li>Λάμες και καρδιές διασταύρωσης σιδηροτροχιών, διακλαδώσεις και εξαρτήματα διασταύρωσης (4)
            <ul>
              <li>Καρδιές διασταύρωσης σιδηροτροχιών (0)
              </li>
              <li>Διακλαδώσεις (0)
              </li>
              <li>Εξαρτήματα διασταύρωσης (0)
              </li>
              <li>Λάμες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Υλικά κατασκευής σιδηροδρομικών γραμμών (4)
          <ul>
            <li>Υλικά σιδηροδρόμων (2)
            <ul>
              <li>Αντιτροχιές (0)
              </li>
              <li>Αμφιδέτες και υπόβαθρα (0)
              </li>
            </ul>
            </li>
            <li>Τροχιές (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός αερολιμένων (21)
      <ul>
        <li>Εξοπλισμός εδάφους παγκατευθυντικών ραδιοφάρων VHF Doppler (DVOR) (0)
        </li>
        <li>Εξοπλισμός μέτρησης αποστάσεων (DME) (0)
        </li>
        <li>Εξοπλισμός ελέγχου εναέριας κυκλοφορίας (5)
        <ul>
          <li>Έλεγχος εναέριας κυκλοφορίας (3)
          <ul>
            <li>Κατάρτιση σε σχέση με τον έλεγχο εναέριας κυκλοφορίας (0)
            </li>
            <li>Προσομοίωση ελέγχου εναέριας κυκλοφορίας (0)
            </li>
            <li>Συστήματα ελέγχου εναέριας κυκλοφορίας (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός πύργου ελέγχου (0)
          </li>
        </ul>
        </li>
        <li>Ραδιογωνιόμετρα και μη κατευθυντικοί ραδιοφάροι (2)
        <ul>
          <li>Μη κατευθυντικοί ραδιοφάροι (NDB) (0)
          </li>
          <li>Ραδιογωνιόμετρα (RDF) (0)
          </li>
        </ul>
        </li>
        <li>Σύστημα διακίνησης αποσκευών (1)
        <ul>
          <li>Εξοπλισμός διακίνησης αποσκευών (0)
          </li>
        </ul>
        </li>
        <li>Σύστημα ενόργανης προσγείωσης (ILS) (0)
        </li>
        <li>Γέφυρες και σκάλες επιβίβασης επιβατών για αεροσκάφη (2)
        <ul>
          <li>Γέφυρες επιβίβασης επιβατών για αεροσκάφη (0)
          </li>
          <li>Σκάλες επιβίβασης επιβατών για αεροσκάφη (0)
          </li>
        </ul>
        </li>
        <li>Συστήματα επικοινωνίας αερολιμένων (COM) (0)
        </li>
        <li>Συστήματα επιτήρησης και συστήματα φωτισμού αερολιμένων (2)
        <ul>
          <li>Συστήματα φωτισμού αερολιμένων (PAPI) (0)
          </li>
          <li>Συστήματα επιτήρησης αερολιμένων (SUR) (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός παρακολούθησης της κυκλοφορίας οχημάτων (2)
      <ul>
        <li>Σύστημα μέτρησης της ροής κυκλοφορίας των οχημάτων (0)
        </li>
        <li>Εξοπλισμός φωτογραφικών μηχανών ταχείας λήψης για τον ηλεκτρονικό έλεγχο της ταχύτητας (0)
        </li>
      </ul>
      </li>
      <li>Ιππήλατα ή χειροκίνητα αμάξια, λοιπά οχήματα χωρίς μηχανική πρόωση, χειράμαξες μεταφοράς αποσκευών και διάφορα ανταλλακτικά (14)
      <ul>
        <li>Διάφορα ανταλλακτικά (9)
        <ul>
          <li>Κλίμακες για πυροσβεστικά οχήματα (0)
          </li>
          <li>Παραβλήματα πλοίων (0)
          </li>
          <li>Έλικες πλοίων (0)
          </li>
          <li>Άγκυρες (0)
          </li>
          <li>Κλειδαριές για αυτοκίνητα οχήματα (1)
          <ul>
            <li>Κλειδαριές ποδηλάτων (0)
            </li>
          </ul>
          </li>
          <li>Ρολόγια οχημάτων (0)
          </li>
          <li>Μεταχειρισμένα ελαστικά (0)
          </li>
          <li>Αναγομωμένα ελαστικά (0)
          </li>
        </ul>
        </li>
        <li>Ιππήλατα ή χειροκίνητα αμάξια και λοιπά οχήματα χωρίς μηχανική πρόωση (1)
        <ul>
          <li>Χειραμάξια (0)
          </li>
        </ul>
        </li>
        <li>Χειράμαξες μεταφοράς αποσκευών (1)
        <ul>
          <li>Παιδικά καρότσια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός ελέγχου, ασφαλείας, σηματοδότησης και φωτισμού (26)
      <ul>
        <li>Φωτιστικά οδών (1)
        <ul>
          <li>Φωτιστικά σηράγγων (0)
          </li>
        </ul>
        </li>
        <li>Φωτιστικά σώματα υπαίθριων χώρων (0)
        </li>
        <li>Εξοπλισμός ελέγχου, ασφαλείας και σηματοδότησης αερολιμένων (3)
        <ul>
          <li>Εγκαταστάσεις φωτισμού αερολιμένων (1)
          <ul>
            <li>Φωτιστικά διαδρόμων προσγείωσης-απογείωσης (0)
            </li>
          </ul>
          </li>
          <li>Συσκευές καταγραφής δεδομένων πτήσης (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ελέγχου, ασφαλείας και σηματοδότησης λιμενικών εγκαταστάσεων (0)
        </li>
        <li>Φωτιστικά για καθοδήγηση και φωτισμό σκαφών (1)
        <ul>
          <li>Φωτιστικά για καθοδήγηση και φωτισμό ποταμόπλοιων (0)
          </li>
        </ul>
        </li>
        <li>Πινακίδες και φωτεινές πινακίδες (3)
        <ul>
          <li>Σήματα αστικού οδικού δικτύου (0)
          </li>
          <li>Οδικά σήματα (0)
          </li>
          <li>Φωτεινές πινακίδες κυκλοφορίας (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ελέγχου, ασφαλείας και σηματοδότησης οδών (3)
        <ul>
          <li>Εξοπλισμός ελέγχου, ασφαλείας και σηματοδότησης χώρων στάθμευσης (0)
          </li>
          <li>Φωτεινοί σηματοδότες (0)
          </li>
          <li>Εξοπλισμός ελέγχου, ασφαλείας και σηματοδότησης υδάτινων οδών (0)
          </li>
        </ul>
        </li>
        <li>Φωτιστικά για καθοδήγηση και φωτισμό αεροσκαφών (0)
        </li>
        <li>Γεννήτριες σημάτων, διανεμητές ασύρματων σημάτων και μηχανήματα επιμετάλλωσης με ηλεκτρόλυση (6)
        <ul>
          <li>Διανεμητές ασύρματων σημάτων (0)
          </li>
          <li>Μηχανήματα επιμετάλλωσης με ηλεκτρόλυση (0)
          </li>
          <li>Μοντέλα υπό κλίμακα (2)
          <ul>
            <li>Μοντέλα αεροσκαφών υπό κλίμακα (0)
            </li>
            <li>Μοντέλα θαλάσσιων σκαφών υπό κλίμακα (0)
            </li>
          </ul>
          </li>
          <li>Γεννήτριες σημάτων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός αποβάθρας φόρτωσης (10)
      <ul>
        <li>Ράμπες πρόσβασης (2)
        <ul>
          <li>Ράμπες πορθμείου (0)
          </li>
          <li>Διάζωμα επιβατών (0)
          </li>
        </ul>
        </li>
        <li>Πλωτή αποβάθρα (1)
        <ul>
          <li>Πλωτή μονάδα αποθήκευσης (0)
          </li>
        </ul>
        </li>
        <li>Κινητή γέφυρα για επιβάτες (0)
        </li>
        <li>Εξέδρες εισόδου (2)
        <ul>
          <li>Εξοπλισμός χειρισμού ιλύος (0)
          </li>
          <li>Εγκατάσταση ξήρανσης ιλύος (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ανύψωσης υδραυλικής πλατφόρμας (0)
        </li>
      </ul>
      </li>
      <li>Οδικός εξοπλισμός (40)
      <ul>
        <li>Εξοπλισμός ελέγχου στάθμευσης αυτοκινήτων (0)
        </li>
        <li>Εξοπλισμός διοδίων (1)
        <ul>
          <li>Αντιπαγετικό αλάτι (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός διαγράμμισης οδών (2)
        <ul>
          <li>Υλικά σήμανσης οδών (1)
          <ul>
            <li>Υαλοσφαιρίδια διαγράμμισης οδών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός συντήρησης οδών (2)
        <ul>
          <li>Σάρωθρα καθαρισμού διαδρόμων προσγείωσης-απογείωσης (0)
          </li>
          <li>Σάρωθρα οδικού καθαρισμού (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός οδικού δικτύου (27)
        <ul>
          <li>Στηθαία πρόσκρουσης (2)
          <ul>
            <li>Μέρη κιγκλιδωμάτων (0)
            </li>
            <li>Κιγκλιδώματα οδικού δικτύου (0)
            </li>
          </ul>
          </li>
          <li>Περιφράξεις (3)
          <ul>
            <li>Ξύλινοι πάσσαλοι (0)
            </li>
            <li>Ηχοφράκτες (0)
            </li>
            <li>Στοιχεία περίφραξης (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός φωτισμού οδών (3)
          <ul>
            <li>Κολώνες φωτισμού οδών (0)
            </li>
            <li>Φωτιστικά μέσα οδών (0)
            </li>
            <li>Στύλοι φωτισμού (0)
            </li>
          </ul>
          </li>
          <li>Στηθαία ασφαλείας (4)
          <ul>
            <li>Χιονοφράκτες (0)
            </li>
            <li>Φράγματα ασφαλείας (0)
            </li>
            <li>Μπάρες φύλαξης σιδηροδρομικών διαβάσεων (0)
            </li>
            <li>Διατάξεις προστασίας από χιονοστιβάδες (0)
            </li>
          </ul>
          </li>
          <li>Αστικός εξοπλισμός (10)
          <ul>
            <li>Δοχεία και κάδοι απορριμμάτων (0)
            </li>
            <li>Σήματα (2)
            <ul>
              <li>Στύλοι πινακίδων σήμανσης (0)
              </li>
              <li>Εξοπλισμός πινακίδων σήμανσης (0)
              </li>
            </ul>
            </li>
            <li>Φωτεινά σήματα (0)
            </li>
            <li>Στύλοι στάσεων λεωφορείου (0)
            </li>
            <li>Στυλίσκοι (0)
            </li>
            <li>Φωτεινές προειδοποιητικές ενδείξεις κινδύνου (0)
            </li>
            <li>Οδικοί κώνοι (0)
            </li>
            <li>Πάσσαλοι οριοθέτησης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Πινακίδες μεταβαλλόμενου μηνύματος (0)
        </li>
        <li>Υλικά αυτοκινητοδρόμων (0)
        </li>
        <li>Εξοπλισμός ελέγχου οδικής κυκλοφορίας (0)
        </li>
      </ul>
      </li>
      <li>Ταξιδιωτικά εισιτήρια (0)
      </li>
      <li>Θαλάσσιος εξοπλισμός (9)
      <ul>
        <li>Συγκροτήματα ραντάρ (0)
        </li>
        <li>Προωστικά πτερύγια (0)
        </li>
        <li>Εξοπλισμός λιμένων (5)
        <ul>
          <li>Εγκαταστάσεις για αποβάθρες (0)
          </li>
          <li>Σκάλες επιβίβασης επιβατών για σκάφη (0)
          </li>
          <li>Προσομοιωτές γεφυρών σκαφών (0)
          </li>
          <li>Εξοπλισμός ελέγχου κυκλοφορίας σκαφών (0)
          </li>
          <li>Γέφυρες επιβίβασης επιβατών για σκάφη (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ναυσιπλοΐας (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Μουσικά όργανα, είδη αθλητισμού, επιτραπέζια παιχνίδια, παιδικά παιχνίδια, είδη χειροτεχνίας, υλικά και εξαρτήματα καλλιτεχνίας (344)
  <ul>
    <li className='top-level'>Αθλητικά είδη και εξοπλισμός (199)
    <ul>
      <li>Εξοπλισμός για παιχνίδια με στόχους και επιτραπέζια παιχνίδια (24)
      <ul>
        <li>Επιτραπέζια παιχνίδια και σχετικός εξοπλισμός (9)
        <ul>
          <li>Τραπέζια ή εξαρτήματα για χόκεϊ με αέρα (0)
          </li>
          <li>Μπάλες επιτραπέζιου ποδοσφαίρου (2)
          <ul>
            <li>Αναπληρωματικοί παίκτες επιτραπέζιου ποδοσφαίρου (0)
            </li>
            <li>Τραπέζια επιτραπέζιου ποδοσφαίρου (0)
            </li>
          </ul>
          </li>
          <li>Στέκες μπιλιάρδου (0)
          </li>
          <li>Shuffleboard (επιτραπέζιο παιχνίδι στο οποίο οι παίκτες σπρώχνουν δίσκους ή βάρη σε μαρκαρισμένα πεδία βαθμολογίας στην απέναντι πλευρά του τραπεζιού) (0)
          </li>
          <li>Τραπέζια επιτραπέζιας αντισφαίρισης (2)
          <ul>
            <li>Μπαλάκια επιτραπέζιας αντισφαίρισης (0)
            </li>
            <li>Ρακέτες επιτραπέζιας αντισφαίρισης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Παιχνίδια με στόχους και σχετικός εξοπλισμός (13)
        <ul>
          <li>Παιχνίδι με βελάκια (1)
          <ul>
            <li>Στόχοι για παιχνίδι με βελάκια (0)
            </li>
          </ul>
          </li>
          <li>Βελάκια (0)
          </li>
          <li>Εξοπλισμός σκοποβολής επί εκτοξευόμενων αντικειμένων (0)
          </li>
          <li>Εξοπλισμός τοξοβολίας (8)
          <ul>
            <li>Προφυλακτήρες χεριού για τοξοβολία (0)
            </li>
            <li>Βέλη τοξοβολίας (0)
            </li>
            <li>Τόξα τοξοβολίας (0)
            </li>
            <li>Βάσεις στόχων τοξοβολίας (0)
            </li>
            <li>Γάντια τοξοβολίας (0)
            </li>
            <li>Προστατευτικά πλέγματα τοξοβολίας (0)
            </li>
            <li>Χορδές τόξων τοξοβολίας (0)
            </li>
            <li>Στόχοι τοξοβολίας (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός γκολφ και μπόουλινγκ (11)
      <ul>
        <li>Εξοπλισμός γκολφ (9)
        <ul>
          <li>Μπάλες του γκολφ (0)
          </li>
          <li>Σάκοι του γκολφ (0)
          </li>
          <li>Μπαστούνια του γκολφ (0)
          </li>
          <li>Υποστηρίγματα τοποθέτησης μπάλας του γκολφ (0)
          </li>
          <li>Μονοκυάλια για γήπεδα του γκολφ (0)
          </li>
          <li>Καλύμματα άκρου για μπαστούνια του γκολφ (0)
          </li>
          <li>Διατάξεις επιδιόρθωσης του τμήματος του χλοοτάπητα που βγαίνει κατά τη διάρκεια του χτυπήματος (0)
          </li>
          <li>Γάντια του γκολφ (0)
          </li>
          <li>Προσομοιωτές τρύπας του γκολφ (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός μπόουλινγκ (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός πυγμαχίας (3)
      <ul>
        <li>Ρινγκ πυγμαχίας (0)
        </li>
        <li>Γάντια πυγμαχίας (0)
        </li>
        <li>Σάκοι δύναμης (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός για αθλήματα υπαίθριων χώρων (54)
      <ul>
        <li>Εξοπλισμός αναψυχής (0)
        </li>
        <li>Είδη κυνηγίου ή ψαρέματος (12)
        <ul>
          <li>Σύνεργα ψαρέματος (6)
          <ul>
            <li>Ψεύτικα δολώματα ψαρέματος (0)
            </li>
            <li>Έλικτρα πετονιάς (0)
            </li>
            <li>Πετονιές ψαρέματος (0)
            </li>
            <li>Βαρίδια ή μολυβήθρες ψαρέματος (0)
            </li>
            <li>Καλάμια ψαρέματος (0)
            </li>
            <li>Δολώματα ψαρέματος (0)
            </li>
          </ul>
          </li>
          <li>Προϊόντα κηνυγιού (4)
          <ul>
            <li>Ομοιώματα (0)
            </li>
            <li>Κράχτες (0)
            </li>
            <li>Κάνες όπλων (0)
            </li>
            <li>Παγίδες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Είδη κατασκήνωσης (6)
        <ul>
          <li>Εστίες μαγειρέματος για κατασκήνωση ή υπαίθριους χώρους (0)
          </li>
          <li>Στρώματα ύπνου (0)
          </li>
          <li>Σετ επισκευής αντίσκηνου (0)
          </li>
          <li>Θερμομονωτικά δοχεία ποτών (0)
          </li>
          <li>Φορητοί καταψύκτες (0)
          </li>
          <li>Στολές επιβίωσης (0)
          </li>
        </ul>
        </li>
        <li>Αθλητικός εξοπλισμός (0)
        </li>
        <li>Εξοπλισμός θαλασσίων σπορ (18)
        <ul>
          <li>Πέδιλα θαλασσίου σκι (0)
          </li>
          <li>Εξοπλισμός ελεύθερης κατάδυσης και κατάδυσης με αναπνευστήρα (10)
          <ul>
            <li>Στολές κατάδυσης υγρού τύπου (0)
            </li>
            <li>Ρυθμιστές πίεσης για ελεύθερη κατάδυση (0)
            </li>
            <li>Όργανα ή εξοπλισμός καταδύσεων (3)
            <ul>
              <li>Στολές καταδύσεων (0)
              </li>
              <li>Αναπνευστικές συσκευές καταδύσεων (0)
              </li>
              <li>Προστατευτικές στολές καταδύσεων (0)
              </li>
            </ul>
            </li>
            <li>Αναπνευστικές φιάλες κατάδυσης (0)
            </li>
            <li>Ρυθμιστές πλευστότητας (0)
            </li>
            <li>Μάσκες, βατραχοπέδιλα ή αναπνευστήρες (0)
            </li>
            <li>Στεγανές στολές κατάδυσης (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός και εξαρτήματα κυματοδρομίας και κολύμβησης (5)
          <ul>
            <li>Ιστιοσανίδες (0)
            </li>
            <li>Εξολισμός ιστιοσανίδων (0)
            </li>
            <li>Γυαλιά κολύμβησης ή βατραχοπέδιλα (0)
            </li>
            <li>Εξοπλισμός θαλάσσιου αλεξιπτωτισμού (0)
            </li>
            <li>Μονές σανίδες κυματοδρομίας, σανίδες κυματοδρομίας σε γονατιστή στάση ή μισές σανίδες κυματοδρομίας (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Χειμερινός εξοπλισμός (12)
        <ul>
          <li>Εξοπλισμός πατινάζ και χόκεϊ επί πάγου (3)
          <ul>
            <li>Παγοπέδιλα (0)
            </li>
            <li>Μπαστούνια παικτών του χόκεϊ (0)
            </li>
            <li>Δίσκοι χόκεϊ (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός χιονοδρομίας και χιονοσανίδας (6)
          <ul>
            <li>Σανίδες χιονοδρομίας (0)
            </li>
            <li>Μπότες χιονοδρομίας (0)
            </li>
            <li>Μπαστούνια χιονοδρομίας (0)
            </li>
            <li>Δέστρες (0)
            </li>
            <li>Ρουχισμός χιονοδρομίας (0)
            </li>
            <li>Χιονοπέδιλα (0)
            </li>
          </ul>
          </li>
          <li>Ρουχισμός και εξοπλισμός πολικού ψύχους (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός γυμναστικής (24)
      <ul>
        <li>Εξοπλισμός άρσης βαρών και προπόνησης με αντιστάσεις (13)
        <ul>
          <li>Ρυθμιζόμενα βάρη (0)
          </li>
          <li>Μηχανές γυμναστικής με τη μέθοδο Pilates (0)
          </li>
          <li>Πολυόργανα γυμναστικής (0)
          </li>
          <li>Αλτήρες (0)
          </li>
          <li>Πάγκοι άρσης βαρών ή σχάρες στερέωσης βαρών (0)
          </li>
          <li>Όργανα ενδυνάμωσης λαβής (0)
          </li>
          <li>Μηχανές προπόνησης με αντίσταση για το επάνω και το κάτω μέρος του σώματος (2)
          <ul>
            <li>Μηχανές προπόνησης με αντίσταση για το επάνω μέρος του σώματος (0)
            </li>
            <li>Μηχανές προπόνησης με αντίσταση για το κάτω μέρος του σώματος (0)
            </li>
          </ul>
          </li>
          <li>Βάρη γυμναστικής (0)
          </li>
          <li>Λάστιχα και σχοινάκια αντίστασης (2)
          <ul>
            <li>Λάστιχα αντίστασης (0)
            </li>
            <li>Σχοινάκια αντίστασης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός αεροβικής γυμναστικής (9)
        <ul>
          <li>Εξοπλισμός αεροβικής άσκησης τύπου σκάλας (στεπ) (0)
          </li>
          <li>Στατικά ποδήλατα (0)
          </li>
          <li>Τραμπολίνα άσκησης (0)
          </li>
          <li>Κυλιόμενοι διάδρομοι (0)
          </li>
          <li>Συνδυασμός οργάνων γυμναστικής (0)
          </li>
          <li>Κωπηλατικές μηχανές (0)
          </li>
          <li>Προσομοιωτές ανάβασης σκαλών (0)
          </li>
          <li>Μπάλες άσκησης (0)
          </li>
          <li>Σχοινάκια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός υπαίθριων αθλημάτων και αθλημάτων γηπέδου (62)
      <ul>
        <li>Εξοπλισμός υπαίθριων αθλημάτων (27)
        <ul>
          <li>Χόκεϊ επί χόρτου (2)
          <ul>
            <li>Μπαστούνια χόκεϊ επί χόρτου (0)
            </li>
            <li>Μπάλες χόκεϊ επί χόρτου (0)
            </li>
          </ul>
          </li>
          <li>Χειροσφαίριση (1)
          <ul>
            <li>Σχολικά σετ χειροσφαίρισης (0)
            </li>
          </ul>
          </li>
          <li>Μπάλες του μπέιζμπολ (6)
          <ul>
            <li>Μπαστούνια του μπέιζμπολ (0)
            </li>
            <li>Προστατευτικός εξοπλισμός μπέιζμπολ ή σόφτμπολ (0)
            </li>
            <li>Προστατευτικά πλέγματα ή φράχτες του μπέιζμπολ (0)
            </li>
            <li>Βοηθήματα προπόνησης μπέιζμπολ (0)
            </li>
            <li>Γάντια του μπέιζμπολ (0)
            </li>
            <li>Βάσεις του μπέιζμπολ (0)
            </li>
          </ul>
          </li>
          <li>Μπαστούνια του λακρός (0)
          </li>
          <li>Μηχανές ρίψης μπάλας (0)
          </li>
          <li>Μπάλες αμερικανικού ποδοσφαίρου (4)
          <ul>
            <li>Υποστηρίγματα τοποθέτησης μπάλας για λάκτισμα στο αμερικανικό ποδόσφαιρο (0)
            </li>
            <li>Προσομοιωτές αμυντικών αντίπαλης ομάδας για προπόνηση αμερικανικού ποδοσφαίρου (0)
            </li>
            <li>Εξοπλισμός σημαιών (0)
            </li>
            <li>Προσομοιωτές αντικανονικού μαρκαρίσματος για προπόνηση στο αμερικανικό ποδόσφαιρο (0)
            </li>
          </ul>
          </li>
          <li>Μπάλες του λακρός (0)
          </li>
          <li>Μπάλες του σόφτμπολ (2)
          <ul>
            <li>Γάντια του σόφτμπολ (0)
            </li>
            <li>Μπαστούνια του σόφτμπολ (0)
            </li>
          </ul>
          </li>
          <li>Μπάλες ποδοσφαίρου (3)
          <ul>
            <li>Εξοπλισμός σήμανσης γηπέδων ποδοσφαίρου (0)
            </li>
            <li>Βοηθήματα προπόνησης ποδοσφαίρου (0)
            </li>
            <li>Προστατευτικός εξοπλισμός ποδοσφαίρου (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός αθλημάτων στίβου (7)
        <ul>
          <li>Κοντάρια άλματος επί κοντώ (0)
          </li>
          <li>Σφαίρες (0)
          </li>
          <li>Εμπόδια (0)
          </li>
          <li>Σκυτάλες (0)
          </li>
          <li>Πήχεις αλμάτων (0)
          </li>
          <li>Δίσκοι (0)
          </li>
          <li>Ακόντια (0)
          </li>
        </ul>
        </li>
        <li>Ρακέτες και εξοπλισμός αθλημάτων γηπέδου (25)
        <ul>
          <li>Μπαλάκια, λαβές και πλέγματα (χορδές) για ρακέτες του ράκετμπολ (3)
          <ul>
            <li>Πλέγματα (χορδές) για ρακέτες του ράκετμπολ (0)
            </li>
            <li>Λαβές για ρακέτες του ράκετμπολ (0)
            </li>
            <li>Μπαλάκια του ράκετμπολ (0)
            </li>
          </ul>
          </li>
          <li>Σκουός (2)
          <ul>
            <li>Μπάλες του σκουός (0)
            </li>
            <li>Ρακέτες του σκουός (0)
            </li>
          </ul>
          </li>
          <li>Μπάλες πετοσφαίρισης (2)
          <ul>
            <li>Αποθηκευτικές διατάξεις για μπάλες και δίχτυα πετοσφαίρισης (0)
            </li>
            <li>Βασικός εξοπλισμός γυμναστηρίου πετοσφαίρισης (0)
            </li>
          </ul>
          </li>
          <li>Αντισφαίριση (4)
          <ul>
            <li>Μπάλες αντισφαίρισης (0)
            </li>
            <li>Ρακέτες αντισφαίρισης (0)
            </li>
            <li>Βοηθήματα προπόνησης για αντισφαίριση (0)
            </li>
            <li>Εξοπλισμός γηπέδων αντισφαίρισης (0)
            </li>
          </ul>
          </li>
          <li>Μπάλες καλαθοσφαίρισης (1)
          <ul>
            <li>Πλήρη συστήματα παιχνιδιού για καλαθοσφαίριση (0)
            </li>
          </ul>
          </li>
          <li>Μπάντμιντον (2)
          <ul>
            <li>Μπάλες με φτερά ή μπάλες του μπάντμιντον (0)
            </li>
            <li>Ρακέτες του μπάντμιντον (0)
            </li>
          </ul>
          </li>
          <li>Προστατευτικός εξοπλισμός για χόκεϊ κλειστού χώρου (0)
          </li>
          <li>Μπάλες και στύλοι tetherball (παιχνίδι για δύο με μπάλα δεμένη με σχοινί σε στύλο όπου ο σκοπός είναι να τυλιχτεί πλήρως το σχοινί γύρω από το στύλο) (2)
          <ul>
            <li>Στύλοι tetherball (0)
            </li>
            <li>Μπάλες tetherball (0)
            </li>
          </ul>
          </li>
          <li>Ρακέτες του ράκετμπολ (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μηχανές ή συσκευές για εξοπλισμό δρατηριοτήτων ελεύθερου χρόνου (2)
      <ul>
        <li>Μηχανές διατήρησης πάγου (0)
        </li>
        <li>Πίνακες ανακοινώσεων με πληροφορίες σχετικά με αθλήματα (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός γυμναστηρίου (11)
      <ul>
        <li>Τραμπολίνα ενόργανης γυμναστικής (0)
        </li>
        <li>Ασύμμετροι ζυγοί ή δοκοί ισορροπίας (2)
        <ul>
          <li>Ασύμμετροι ζυγοί (0)
          </li>
          <li>Δοκοί ισορροπίας (0)
          </li>
        </ul>
        </li>
        <li>Σχοινιά κρίκων ή κρίκοι ή εξαρτήματα αναρρίχησης για ενόργανη γυμναστική (3)
        <ul>
          <li>Κρίκοι ενόργανης γυμναστικής (0)
          </li>
          <li>Σχοινιά κρίκων ενόργανης γυμναστικής (0)
          </li>
          <li>Εξαρτήματα αναρρίχησης για ενόργανη γυμναστική (0)
          </li>
        </ul>
        </li>
        <li>Τάπητες γυμναστηρίων (0)
        </li>
        <li>Εξοπλισμός δοκού ισορροπίας (0)
        </li>
        <li>Εξπλισμός άλματος ίππου ενόργανης γυμναστικής (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Είδη χειροτεχνίας και καλλιτεχνίας (18)
    <ul>
      <li>Είδη χειροτεχνίας (0)
      </li>
      <li>Υλικά καλλιτεχνίας (16)
      <ul>
        <li>Στυλογράφοι σχεδιασμού (4)
        <ul>
          <li>Κάρβουνο σχεδίασης (0)
          </li>
          <li>Κραγιόνια (0)
          </li>
          <li>Κιμωλίες (0)
          </li>
          <li>Παστέλ (0)
          </li>
        </ul>
        </li>
        <li>Λαδόχαρτο και άλλα είδη χαρτιού (9)
        <ul>
          <li>Χαρτί καλλιτεχνίας και χειροτεχνίας (0)
          </li>
          <li>Χαρτί σχεδιασμού (0)
          </li>
          <li>Πολύπτυχο χαρτί και χαρτόνι (0)
          </li>
          <li>Χαρτί περιτυλίγματος (0)
          </li>
          <li>Χαρτογραφικό χαρτί (0)
          </li>
          <li>Διαφανές ή ημιδιαφανές χαρτί (0)
          </li>
          <li>Χαρτί ιχνογραφίας (0)
          </li>
          <li>Λαδόχαρτο (0)
          </li>
          <li>Υαλόχαρτο (0)
          </li>
        </ul>
        </li>
        <li>Πινέλα καλλιτεχνών (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Επιτραπέζια και παιδικά παιχνίδια· μέσα διασκέδασης για υπαίθριους χώρους πανηγυριών (54)
    <ul>
      <li>Μηχανές τυχερών παιχνιδιών (0)
      </li>
      <li>Είδη για λούνα παρκ, για επιτραπέζια παιχνίδια ή παιχνίδια συναναστροφής (23)
      <ul>
        <li>Παιχνίδια που λειτουργούν με τη ρίψη κέρματος ή την εισαγωγή δισκέτας (0)
        </li>
        <li>Περιστρεφόμενες εξέδρες, κούνιες, περίπτερα σκοποβολής και άλλα μέσα διασκέδασης για υπαίθριους χώρους πανηγυριών (13)
        <ul>
          <li>Εξοπλισμός παιδικής χαράς (11)
          <ul>
            <li>Τσουλήθρες για παιδικές χαρές (0)
            </li>
            <li>Κερκίδες για παιδικές χαρές (0)
            </li>
            <li>Εξοπλισμός αναρρίχησης σε τοίχο και αναρρίχησης με σχοινιά (2)
            <ul>
              <li>Εξοπλισμός αναρρίχησης σε τοίχο (0)
              </li>
              <li>Εξοπλισμός αναρρίχησης με σχοινιά (0)
              </li>
            </ul>
            </li>
            <li>Γύροι για παιδικές χαρές (0)
            </li>
            <li>Κιβώτια άμμου για παιδικές χαρές (0)
            </li>
            <li>Εξολισμός αναρρίχησης για παιδικές χαρές (0)
            </li>
            <li>Τραμπάλες για παιδικές χαρές (0)
            </li>
            <li>Κούνιες παιδικής χαράς (0)
            </li>
            <li>Σήραγγες για παιδικές χαρές (0)
            </li>
          </ul>
          </li>
          <li>Κούνιες (0)
          </li>
        </ul>
        </li>
        <li>Μπιλιάρδα (5)
        <ul>
          <li>Μύτες για στέκες μπιλιάδου (0)
          </li>
          <li>Κιμωλία μπιλιάρδου (0)
          </li>
          <li>Τραπέζια μπιλιάρδου (0)
          </li>
          <li>Μπάλες του μπιλιάρδου (0)
          </li>
          <li>Τρίγωνα μπιλιάρδου (0)
          </li>
        </ul>
        </li>
        <li>Παιγνιόχαρτα (0)
        </li>
        <li>Ηλεκτρονικά παιχνίδια (0)
        </li>
      </ul>
      </li>
      <li>Παιδικά παιχνίδια (23)
      <ul>
        <li>Κουβαδάκια (0)
        </li>
        <li>Μπαλόνια και μπάλες (παιχνίδια) (0)
        </li>
        <li>Παιχνίδια (10)
        <ul>
          <li>Παιχνίδια μνήμης (0)
          </li>
          <li>Κλασικά παιχνίδια (0)
          </li>
          <li>Επιτραπέζια παιχνίδια (0)
          </li>
          <li>Σετ παιχνιδιών (0)
          </li>
          <li>Εκπαιδευτικά παιχνίδια (0)
          </li>
          <li>Παιχνίδια συνεργασίας (0)
          </li>
          <li>Εξαρτήματα παιχνιδιών (0)
          </li>
          <li>Παιχνίδια λαχειοφόρου αγοράς (1)
          <ul>
            <li>Δελτία λαχειοφόρου αγοράς (0)
            </li>
          </ul>
          </li>
          <li>Παιχνίδια στρατηγικής (0)
          </li>
        </ul>
        </li>
        <li>Παζλ (0)
        </li>
        <li>Τροχοφόρα παιχνίδια (0)
        </li>
        <li>Φουσκωτά παιχνίδια και παιχνίδια με αναβάτη (2)
        <ul>
          <li>Φουσκωτά παιχνίδια (0)
          </li>
          <li>Παιχνίδια με αναβάτη (0)
          </li>
        </ul>
        </li>
        <li>Παιδικά μουσικά όργανα (0)
        </li>
        <li>Όπλα (παιχνίδια) (0)
        </li>
        <li>Τρένα και οχήματα (παιχνίδια) (2)
        <ul>
          <li>Οχήματα (παιχνίδια) (0)
          </li>
          <li>Τρένα (παιχνίδια) (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Κούκλες (4)
      <ul>
        <li>Μέρη ή εξαρτήματα για κούκλες (0)
        </li>
        <li>Μαριονέτες (1)
        <ul>
          <li>Κουκλοθέατρα (0)
          </li>
        </ul>
        </li>
        <li>Κουκλόσπιτα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μουσικά όργανα και μέρη τους (69)
    <ul>
      <li>Μέρη και εξαρτήματα μουσικών οργάνων (16)
      <ul>
        <li>Μέρη μουσικών οργάνων (7)
        <ul>
          <li>Μπαγκέτες διευθυντή ορχήστρας (0)
          </li>
          <li>Σφουγγαράκια για πλήκτρα φλάουτου πίκολο (0)
          </li>
          <li>Μουσικά κουτιά ή μηχανισμοί (0)
          </li>
          <li>Διαπασών (0)
          </li>
          <li>Κλειδιά κουρδίσματος (0)
          </li>
          <li>Επιστόμια (0)
          </li>
          <li>Φίμωτρα (0)
          </li>
        </ul>
        </li>
        <li>Εξαρτήματα μουσικών οργάνων (7)
        <ul>
          <li>Εξαρτήματα κρουστών μουσικών οργάνων (0)
          </li>
          <li>Σάκοι ή θήκες ή εξαρτήματα μουσικών οργάνων (0)
          </li>
          <li>Μετρονόμοι (0)
          </li>
          <li>Χορδές ή πένες οργάνων (0)
          </li>
          <li>Βάσεις ή αναλόγια μουσικών οργάνων (0)
          </li>
          <li>Γλωττίδες (0)
          </li>
          <li>Εξαρτήματα έγχορδων μουσικών οργάνων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μουσικά όργανα (51)
      <ul>
        <li>Πληκτροφόρα όργανα (4)
        <ul>
          <li>Πιάνα (0)
          </li>
          <li>Ακορντεόν (0)
          </li>
          <li>Εκκλησιαστικά όργανα (0)
          </li>
          <li>Τσελέστα (0)
          </li>
        </ul>
        </li>
        <li>Πνευστά όργανα (11)
        <ul>
          <li>Όμποε (0)
          </li>
          <li>Καζού (0)
          </li>
          <li>Κορνέτα και φλάουτα (2)
          <ul>
            <li>Φλάουτα (0)
            </li>
            <li>Κορνέτα (0)
            </li>
          </ul>
          </li>
          <li>Άσκαυλοι (γκάιντες) (0)
          </li>
          <li>Αγγλικό κόρνο (0)
          </li>
          <li>Πίκολο (μικρά φλάουτα) (0)
          </li>
          <li>Φυσαρμόνικες (0)
          </li>
          <li>Κλαρινέτα (0)
          </li>
          <li>Οκαρίνα (0)
          </li>
        </ul>
        </li>
        <li>Κρουστά μουσικά όργανα (7)
        <ul>
          <li>Καστανιέτες (0)
          </li>
          <li>Βιμπράφωνα (0)
          </li>
          <li>Κύμβαλα (0)
          </li>
          <li>Ξυλόφωνα (0)
          </li>
          <li>Τύμπανα (μουσικά όργανα) (0)
          </li>
          <li>Ντέφια (0)
          </li>
          <li>Καμπάνες (όργανα) (0)
          </li>
        </ul>
        </li>
        <li>Έγχορδα μουσικά όργανα (9)
        <ul>
          <li>Κιθάρες (0)
          </li>
          <li>Βιολοντσέλα (0)
          </li>
          <li>Κοντραμπάσα (0)
          </li>
          <li>Κλαβεσέν (0)
          </li>
          <li>Μαντολίνα (0)
          </li>
          <li>Βιολιά (0)
          </li>
          <li>Μπάντζο (0)
          </li>
          <li>Τσέμπαλα (0)
          </li>
          <li>Άρπες (0)
          </li>
        </ul>
        </li>
        <li>Μουσικά όργανα με ηλεκτρικό ενισχυτή (1)
        <ul>
          <li>Συνθεσάιζερ (0)
          </li>
        </ul>
        </li>
        <li>Χάλκινα πνευστά όργανα (13)
        <ul>
          <li>Κόρνο άλτο, μπάσο, τενόρο και γαλλικό (4)
          <ul>
            <li>Άλτο κόρνο (0)
            </li>
            <li>Γαλλικό κόρνο (0)
            </li>
            <li>Κόρνο τενόρο (0)
            </li>
            <li>Μπάσο κόρνο (0)
            </li>
          </ul>
          </li>
          <li>Τρομπόνια (0)
          </li>
          <li>Μελόφωνα (0)
          </li>
          <li>Γερμανικά βαθύφωνα (0)
          </li>
          <li>Σάλπιγγες (0)
          </li>
          <li>Βαρύτονα σαξόφωνα (0)
          </li>
          <li>Σαξόφωνα (0)
          </li>
          <li>Τρομπέτες (0)
          </li>
          <li>Σφυρίχτρες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες εγκατάστασης (εκτός από υπηρεσίες εγκατάστασης λογισμικού) (109)
  <ul>
    <li className='top-level'>Υπηρεσίες εγκατάστασης ηλεκτρομηχανολογικού εξοπλισμού (26)
    <ul>
      <li>Υπηρεσίες εγκατάστασης ατμογεννητριών, στροβίλων, συμπιεστών και καυστήρων αερίου (7)
      <ul>
        <li>Υπηρεσίες εγκατάστασης κλιβάνων (2)
        <ul>
          <li>Υπηρεσίες εγκατάστασης καυστήρων (1)
          <ul>
            <li>Υπηρεσίες εγκατάστασης κλιβάνων αποτέφρωσης απορριμμάτων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εγκατάστασης στροβίλων (1)
        <ul>
          <li>Υπηρεσίες εγκατάστασης στροβίλων αερίου (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εγκατάστασης συμπιεστών (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης ατμογεννητριών (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εγκατάστασης κινητήρων (6)
      <ul>
        <li>Υπηρεσίες εγκατάστασης κινητήρων αεροσκαφών (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης πετρελαιοκινητήρων (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης κινητήρων σιδηροδρόμου (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης κινητήρων αυτοκινήτων (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης βενζινοκινητήρων (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης κινητήρων πλοίων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εγκατάστασης μηχανολογικού εξοπλισμού (2)
      <ul>
        <li>Υπηρεσίες εγκατάστασης ιστών σημαίας (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού γυμναστικής (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εγκατάστασης ηλεκτρολογικού εξοπλισμού (7)
      <ul>
        <li>Υπηρεσίες εγκατάστασης ηλεκτροκινητήρων, γεννητριών και μετασχηματιστών (3)
        <ul>
          <li>Υπηρεσίες εγκατάστασης ηλεκτροκινητήρων (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης γεννητριών (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης μετασχηματιστών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού διανομής και ελέγχου ηλεκτρικού ρεύματος (2)
        <ul>
          <li>Υπηρεσίες εγκατάστασης εξοπλισμού διανομής ηλεκτρικού ρεύματος (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης εξοπλισμού ελέγχου ηλεκτρικού ρεύματος (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες εγκατάστασης Η/Υ και εξοπλισμού γραφείου (7)
    <ul>
      <li>Υπηρεσίες εγκατάστασης εξοπλισμού γραφείου (0)
      </li>
      <li>Υπηρεσίες εγκατάστασης Η/Υ και εξοπλισμού επεξεργασίας πληροφοριών (5)
      <ul>
        <li>Υπηρεσίες εγκατάστασης Η/Υ (3)
        <ul>
          <li>Υπηρεσίες εγκατάστασης υλικού πληροφορικής (2)
          <ul>
            <li>Υπηρεσίες εγκατάστασης οθονών ή πινάκων απεικόνισης παραγματικού χρόνου για αναχωρήσεις και αφίξεις σε σιδηροδρομικούς σταθμούς (0)
            </li>
            <li>Υπηρεσίες εγκατάστασης οθονών ή πινάκων απεικόνισης παραγματικού χρόνου για αναχωρήσεις και αφίξεις σε αερολιμένες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού επεξεργασίας πληροφοριών (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες εγκατάστασης εξοπλισμού τηλεπικοινωνιών (11)
    <ul>
      <li>Υπηρεσίες εγκατάστασης πομπών ραδιοφώνου και τηλεόρασης (2)
      <ul>
        <li>Υπηρεσίες εγκατάστασης πομπών τηλεόρασης (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης πομπών ραδιοφώνου (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εγκατάστασης εξοπλισμού ραδιοφωνίας, τηλεόρασης, ήχου και βίντεο (4)
      <ul>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού ραδιοφωνίας (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού βίντεο (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού τηλεόρασης (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού ήχου (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εγκατάστασης εξοπλισμού ενσύρματης τηλεφωνίας (0)
      </li>
      <li>Υπηρεσίες εγκατάστασης συσκευών ραδιοτηλεφωνίας (0)
      </li>
      <li>Υπηρεσίες εγκατάστασης εξοπλισμού ενσύρματης τηλεγραφίας (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες εγκατάστασης ιατρικού και χειρουργικού εξοπλισμού (9)
    <ul>
      <li>Υπηρεσίες εγκατάστασης χειρουργικού εξοπλισμού (0)
      </li>
      <li>Υπηρεσίες εγκατάστασης εργαστηριακού εξοπλισμού (0)
      </li>
      <li>Υπηρεσίες εγκατάστασης ιατρικού εξοπλισμού (6)
      <ul>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού κινησιοθεραπείας (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού απεικόνισης (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού ακτινοθεραπείας (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού ηλεκτροθεραπείας (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης οδοντιατρικού και παραοδοντιατρικού εξοπλισμού (0)
        </li>
        <li>IΥπηρεσίες εγκατάστασης εξοπλισμού μηχανοθεραπείας (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες εγκατάστασης μηχανημάτων και εξοπλισμού (34)
    <ul>
      <li>Υπηρεσίες εγκατάστασης εργαλειομηχανών (0)
      </li>
      <li>Υπηρεσίες εγκατάστασης γεωργικών και δασοκομικών μηχανημάτων (2)
      <ul>
        <li>Υπηρεσίες εγκατάστασης γεωργικών μηχανημάτων (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης δασοκομικών μηχανημάτων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εγκατάστασης μηχανημάτων και εξοπλισμού γενικής χρήσης (9)
      <ul>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού ανύψωσης και διακίνησης, εκτός από ανελκυστήρες και κυλιόμενες σκάλες (5)
        <ul>
          <li>Υπηρεσίες εγκατάστασης εξοπλισμού αυτόματης μεταφοράς (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης αιωρούμενων ικριωμάτων και κουβούκλιων (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης εξοπλισμού διακίνησης (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης εξοπλισμού ανύψωσης (1)
          <ul>
            <li>Υπηρεσίες εγκατάστασης γερανών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εγκατάστασης διάφορων μηχανημάτων γενικής χρήσης (2)
        <ul>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων και συσκευών διήθησης ή καθαρισμού υγρών (1)
          <ul>
            <li>Υπηρεσίες εγκατάστασης μηχανημάτων και συσκευών διήθησης ή καθαρισμού νερού (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εγκατάστασης οπλικών συστημάτων (0)
      </li>
      <li>Υπηρεσίες εγκατάστασης μηχανημάτων και εξοπλισμού ειδικής χρήσης (18)
      <ul>
        <li>Υπηρεσίες εγκατάστασης μηχανημάτων ορυχείων, λατομείων, κατασκευαστικών έργων και μεταλλουργίας (4)
        <ul>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων κατασκευαστικών έργων (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων μεταλλουργίας (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων ορυχείων (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων λατομείων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εγκατάστασης μηχανημάτων παραγωγής χαρτιού και χαρτονιού (2)
        <ul>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων παραγωγής χαρτονιού (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων παραγωγής χαρτιού (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εγκατάστασης υπαίθριων γραμματοκιβωτίων (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης μηχανημάτων παραγωγής κλωστοϋφαντουργικών ειδών, ρουχισμού και κατεργασίας δερμάτων (4)
        <ul>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων κατεργασίας δερμάτων (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων παραγωγής ειδών ρουχισμού (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων παραγωγής κλωστοϋφαντουργικών ειδών (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης πλυντηρίων ρούχων, μηχανών στεγνού καθαρισμού και στεγνωτηρίων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εγκατάστασης μηχανημάτων επεξεργασίας τροφίμων, ποτών και καπνού (3)
        <ul>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων επεξεργασίας καπνού (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων επεξεργασίας τροφίμων (0)
          </li>
          <li>Υπηρεσίες εγκατάστασης μηχανημάτων επεξεργασίας ποτών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες εγκατάστασης συστημάτων οδήγησης και ελέγχου (0)
    </li>
    <li className='top-level'>Υπηρεσίες εγκατάστασης εξοπλισμού μέτρησης, ελέγχου, δοκιμών και πλοήγησης (11)
    <ul>
      <li>Υπηρεσίες εγκατάστασης εξοπλισμού ναυσιπλοΐας (0)
      </li>
      <li>Υπηρεσίες εγκατάστασης εξοπλισμού δοκιμών (0)
      </li>
      <li>Υπηρεσίες εγκατάστασης εξοπλισμού ελέγχου (1)
      <ul>
        <li>Υπηρεσίες εγκατάστασης αυτόματων διατάξεων ελέγχου εισιτηρίων επιβατών σε αερολιμένες (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εγκατάστασης εξοπλισμού μέτρησης (6)
      <ul>
        <li>Υπηρεσίες εγκατάστασης συσκευών καταγραφής χρόνου (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης μετεωρολογικού εξοπλισμού (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης εξοπλισμού χρονομέτρησης (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης παρκομέτρων (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης γεωλογικού εξοπλισμού (0)
        </li>
        <li>Υπηρεσίες εγκατάστασης οργάνων αποτύπωσης χρόνου (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες εγκατάστασης μεταλλικών δοχείων (2)
    <ul>
      <li>Υπηρεσίες εγκατάστασης ταμιευτήρων (0)
      </li>
      <li>Υπηρεσίες εγκατάστασης δεξαμενών (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες εγκατάστασης εξοπλισμού πυροπροστασίας (0)
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες μεταφορών (εκτός από μεταφορά αποβλήτων) (54)
  <ul>
    <li className='top-level'>Υπηρεσίες μεταφορών με πλωτά μέσα (13)
    <ul>
      <li>Πλωτή μεταφορά ταχυδρομείου (0)
      </li>
      <li>Ενοικίαση πλωτού μεταφορικού εξοπλισμού με πλήρωμα (8)
      <ul>
        <li>Ενοικίαση σκαφών με πλήρωμα (6)
        <ul>
          <li>Ενοικίαση σκαφών εσωτερικών πλωτών οδών με πλήρωμα (0)
          </li>
          <li>Υπηρεσίες πλοίων κατά της ρύπανσης (0)
          </li>
          <li>Υπηρεσίες σκαφών για τη μεταφορά βαρέων φορτίων (0)
          </li>
          <li>Ενοικίαση ποντοπόρων πλοίων με πλήρωμα (0)
          </li>
          <li>Υπηρεσίες εφεδρικών πλοίων (0)
          </li>
          <li>Υπηρεσίες πλοίων για τον ανεφοδιασμό ανοιχτής θαλάσσης (0)
          </li>
        </ul>
        </li>
        <li>Ενοικίαση πλοίων με πλήρωμα (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες μεταφορών με πορθμεία (0)
      </li>
      <li>Ναυτιλιακές μεταφορικές επιχειρήσεις (0)
      </li>
      <li>Υπηρεσίες τοποθέτησης καλωδίων με πλοίο (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες αεροπορικών μεταφορών (17)
    <ul>
      <li>Υπηρεσίες προγραμματισμένων αεροπορικών μεταφορών (1)
      <ul>
        <li>Υπηρεσίες προγραμματισμένης αεροπορικής μεταφοράς αλληλογραφίας (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες μη προγραμματισμένων αεροπορικών μεταφορών (6)
      <ul>
        <li>Υπηρεσίες ναυλωμένων πτήσεων (0)
        </li>
        <li>Ενοικίαση αεροπορικού μεταφορικού εξοπλισμού με πλήρωμα (3)
        <ul>
          <li>Ενοικίαση αεροσκαφών με πλήρωμα (2)
          <ul>
            <li>Ενοικίαση αεροσκαφών με σταθερές πτέρυγες με πλήρωμα (0)
            </li>
            <li>Ενοικίαση ελικοπτέρων με πλήρωμα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες μη προγραμματισμένης αεροπορικής μεταφοράς αλληλογραφίας (0)
        </li>
      </ul>
      </li>
      <li>Αεροπορικές και συναφείς υπηρεσίες (7)
      <ul>
        <li>Υπηρεσίες χειρισμού αεροσκαφών (0)
        </li>
        <li>Υπηρεσίες αεροψεκασμού (0)
        </li>
        <li>Υπηρεσίες λειτουργίας αεροσκαφών (1)
        <ul>
          <li>Υπηρεσίες χειριστού αεροσκάφους (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες πυρόσβεσης δασικών πυρκαγιών από τον αέρα (0)
        </li>
        <li>Υπηρεσίες διάσωσης από τον αέρα (1)
        <ul>
          <li>Υπηρεσίες εναέριας-θαλάσσιας διάσωσης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες διαστημικών μεταφορών (2)
    <ul>
      <li>Υπηρεσίες εκτόξευσης δορυφόρων (0)
      </li>
      <li>Υπηρεσίες πειραματικού ωφέλιμου φορτίου (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες μεταφορών μέσω αγωγού (0)
    </li>
    <li className='top-level'>Υπηρεσίες μεταφορών μέσω σιδηροδρόμων (2)
    <ul>
      <li>Ταχυδρομικές μεταφορές μέσω σιδηροδρόμων (0)
      </li>
      <li>Υπηρεσίες δημόσιων μεταφορών μέσω σιδηροδρόμων (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες οδικών μεταφορών (14)
    <ul>
      <li>Μεταφορές επιβατών με οχήματα που σύρονται από ζώα (0)
      </li>
      <li>Ενοικίαση οχημάτων μεταφοράς εμπορευμάτων με οδηγό (3)
      <ul>
        <li>Ενοικίαση μικρών φορτηγών αυτοκινήτων με οδηγό (0)
        </li>
        <li>Ενοικίαση οχημάτων επαγγελματικής χρήσης με οδηγό (0)
        </li>
        <li>Ενοικίαση φορτηγών αυτοκινήτων με οδηγό (0)
        </li>
      </ul>
      </li>
      <li>Ενοικίαση οχημάτων μεταφοράς επιβατών με οδηγό (2)
      <ul>
        <li>Ενοικίαση επιβατικών αυτοκινήτων με οδηγό (0)
        </li>
        <li>Ενοικίαση λεωφορείων και πούλμαν με οδηγό (0)
        </li>
      </ul>
      </li>
      <li>Μη προγραμματισμένες μεταφορές επιβατών (0)
      </li>
      <li>Υπηρεσίες ταξί (0)
      </li>
      <li>Υπηρεσίες ειδικών οδικών μεταφορών επιβατών (0)
      </li>
      <li>Οδική μεταφορά αλληλογραφίας (1)
      <ul>
        <li>Υπηρεσίες μεταφοράς δεμάτων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες δημόσιων οδικών μεταφορών (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες λυμάτων, απορριμμάτων, καθαρισμού και περιβαλλοντικές υπηρεσίες (196)
  <ul>
    <li className='top-level'>Υπηρεσίες σχετιζόμενες με απορρίμματα και απόβλητα (47)
    <ul>
      <li>Διάθεση και επεξεργασία απορριμμάτων (17)
      <ul>
        <li>Υπηρεσίες ανακύκλωσης απορριμμάτων (0)
        </li>
        <li>Υπηρεσίες επεξεργασίας και διάθεσης μη επικίνδυνων απορριμμάτων και αποβλήτων (9)
        <ul>
          <li>Υπηρεσίες διάθεσης ιλύος (0)
          </li>
          <li>Υπηρεσίες απόρριψης στάχτης (0)
          </li>
          <li>Υπηρεσίες αποτέφρωσης απορριμμάτων (0)
          </li>
          <li>Επεξεργασία και διάθεση ακάθαρτων υγρών (0)
          </li>
          <li>Υπηρεσίες αφαίρεσης ιλύος (0)
          </li>
          <li>Υπηρεσίες επεξεργασίας ιλύος (0)
          </li>
          <li>Υπηρεσίες μεταφοράς ιλύος (0)
          </li>
          <li>Υπηρεσίες διάθεσης στερεών αστικών απορριμμάτων (0)
          </li>
          <li>Υπηρεσίες διάθεσης οικιακών απορριμμάτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες αποκομιδής απορριμμάτων (4)
        <ul>
          <li>Υπηρεσίες αποκομιδής απορριμμάτων από υπαίθρια δοχεία (0)
          </li>
          <li>Υπηρεσίες αποκομιδής στερεών αστικών απορριμμάτων (0)
          </li>
          <li>Υπηρεσίες αποκομιδής οικιακών απορριμμάτων (0)
          </li>
          <li>Υπηρεσίες αποκομιδής χάρτου (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες μεταφοράς απορριμμάτων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες ραδιενεργών, τοξικών, ιατρικών και επικίνδυνων αποβλήτων (24)
      <ul>
        <li>Υπηρεσίες ιατρικών αποβλήτων (4)
        <ul>
          <li>Υπηρεσίες διάθεσης νοσοκομειακών αποβλήτων (0)
          </li>
          <li>Υπηρεσίες απομάκρυνσης βιολογικών αποβλήτων (0)
          </li>
          <li>Υπηρεσίες αποκομιδής νοσοκομειακών αποβλήτων (0)
          </li>
          <li>Αποκομιδή, μεταφορά και διάθεση νοσοκομειακών αποβλήτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επεξεργασίας ραδιενεργών αποβλήτων (9)
        <ul>
          <li>Αποκομιδή ραδιενεργών αποβλήτων (0)
          </li>
          <li>Συσκευασία ραδιενεργών αποβλήτων (2)
          <ul>
            <li>Συσκευασία ραδιενεργών αποβλήτων χαμηλής ραδιενέργειας (0)
            </li>
            <li>Συσκευασία ραδιενεργών αποβλήτων μέσης ραδιενέργειας (0)
            </li>
          </ul>
          </li>
          <li>Διάθεση ραδιενεργών αποβλήτων (0)
          </li>
          <li>Υπηρεσίες αποθήκευσης ραδιενεργών αποβλήτων (0)
          </li>
          <li>Μεταφορά ραδιενεργών αποβλήτων (2)
          <ul>
            <li>Μεταφορά ραδιενεργών αποβλήτων χαμηλής ραδιενέργειας (0)
            </li>
            <li>Μεταφορά ραδιενεργών αποβλήτων μέσης ραδιενέργειας (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες σχετιζόμενες με μολυσμένο έδαφος (4)
        <ul>
          <li>Καθαρισμός και επεξεργασία εδάφους (0)
          </li>
          <li>Υπηρεσίες επεξεργασίας μολυσμένου εδάφους (0)
          </li>
          <li>Απομάκρυνση μολυσμένου εδάφους (0)
          </li>
          <li>Διάθεση μολυσμένου εδάφους (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες διάθεσης τοξικών αποβλήτων εκτός από ραδιενεργά απόβλητα και μολυσμένο έδαφος (3)
        <ul>
          <li>Υπηρεσίες απομάκρυνσης όπλων και καταστροφής πυρομαχικών (0)
          </li>
          <li>Υπηρεσίες απομάκρυνσης βομβών (0)
          </li>
          <li>Υπηρεσίες αλιείας ναρκών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Λειτουργία χώρου απόθεσης απορριμμάτων (3)
      <ul>
        <li>Υπηρεσίες διαχείρισης χωματερής (0)
        </li>
        <li>Υπηρεσίες διαχείρισης χώρου απόρριψης μπαζών (0)
        </li>
        <li>Υπηρεσίες διαχείρισης χώρου αποθήκευσης άνθρακα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες αποχέτευσης (12)
    <ul>
      <li>Υπηρεσίες καθαρισμού αποχετεύσεων (0)
      </li>
      <li>Υπηρεσίες εκκένωσης βόθρων ή σηπτικών δεξαμενών (0)
      </li>
      <li>Υπηρεσίες αφαίρεσης λυμάτων (0)
      </li>
      <li>Υπηρεσίες επεξεργασίας λυμάτων βόθρων (0)
      </li>
      <li>Υπηρεσίες παροχής συμβουλών σχετικά με την εποπτεία αποχετεύσεων και την επεξεργασία λυμάτων (2)
      <ul>
        <li>Υπηρεσίες παροχής συμβουλών για την επεξεργασία λυμάτων (0)
        </li>
        <li>Υπηρεσίες επιθεώρησης αποχετεύσεων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επεξεργασίας λυμάτων (0)
      </li>
      <li>Υπηρεσίες επεξεργασίας σηπτικών δεξαμενών (0)
      </li>
      <li>Υπηρεσίες διαχείρισης των αποχετεύσεων (1)
      <ul>
        <li>Λειτουργία σταθμού επεξεργασίας λυμάτων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες διάθεσης λυμάτων (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες καθαριότητας και εξυγίανσης σε αστικές ή αγροτικές ζώνες, καθώς και συναφείς υπηρεσίες (13)
    <ul>
      <li>Υπηρεσίες απολύμανσης και εξολόθρευσης σε αστικές ή αγροτικές ζώνες (0)
      </li>
      <li>Υπηρεσίες καθαρισμού και σάρωσης οδών (2)
      <ul>
        <li>Υπηρεσίες σάρωσης δρόμων (0)
        </li>
        <li>Υπηρεσίες οδοκαθαρισμού (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες καθαρισμού από τον πάγο (0)
      </li>
      <li>Υπηρεσίες καθαρισμού και εκκένωσης φρεατίων υπονόμων (2)
      <ul>
        <li>Υπηρεσίες εκκένωσης φρεατίων υπονόμων (0)
        </li>
        <li>Υπηρεσίες καθαρισμού φρεατίων υπονόμων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες απομάκρυνσης μολύβδου (0)
      </li>
      <li>Υπηρεσίες απομάκρυνσης επιτοίχιων παραστάσεων (γκράφιτι) (0)
      </li>
      <li>Υπηρεσίες εκχιονισμού (0)
      </li>
      <li>Υπηρεσίες καθαρισμού παραλίας (0)
      </li>
      <li>Υπηρεσίες απομάκρυνσης αμιάντου (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες καθαρισμού και απολύμανσης (23)
    <ul>
      <li>Υπηρεσίες απολύμανσης εγκαταστάσεων (4)
      <ul>
        <li>Υπηρεσίες εξόντωσης αρουραίων (0)
        </li>
        <li>Υπηρεσίες απολύμανσης διά υποκαπνισμού (0)
        </li>
        <li>Υπηρεσίες καταπολέμησης επιβλαβών εντόμων (0)
        </li>
        <li>Υπηρεσίες απολύμανσης και εξολόθρευσης (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες καθαρισμού (17)
      <ul>
        <li>Υπηρεσίες καθαρισμού κατοικιών, κτιρίων και υαλοπινάκων (3)
        <ul>
          <li>Υπηρεσίες καθαρισμού κτιρίων (0)
          </li>
          <li>Υπηρεσίες καθαρισμού κατοικιών (0)
          </li>
          <li>Υπηρεσίες καθαρισμού υαλοπινάκων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες καθαρισμού κάδων (0)
        </li>
        <li>Υπηρεσίες καθαρισμού γραφείων, σχολείων και εξοπλισμού γραφείων (3)
        <ul>
          <li>Υπηρεσίες καθαρισμού σχολείων (0)
          </li>
          <li>Υπηρεσίες καθαρισμού εξοπλισμού γραφείων (0)
          </li>
          <li>Υπηρεσίες καθαρισμού γραφείων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες καθαρισμού τηλεφωνικού εξοπλισμού (0)
        </li>
        <li>Υπηρεσίες καθαρισμού χώρων στάθμευσης αυτοκινήτων (0)
        </li>
        <li>Υπηρεσίες καθαρισμού σωλήνων με αμμοβολή (0)
        </li>
        <li>Υπηρεσίες καθαρισμού κλιβάνων και καπνοδόχων (0)
        </li>
        <li>Υπηρεσίες καθαρισμού βυτίων και δεξαμενών (2)
        <ul>
          <li>Υπηρεσία καθαρισμού δεξαμενών (0)
          </li>
          <li>Υπηρεσίες καθαρισμού βυτίων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες καθαρισμού εξοπλισμού μεταφοράς (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Περιβαλλοντικές υπηρεσίες (96)
    <ul>
      <li>Υπηρεσίες εντοπισμού και παρακολούθησης και αποκατάστασης ρυπαντών (12)
      <ul>
        <li>Υπηρεσίες που σχετίζονται με τις διαρροές πετρελαίου (3)
        <ul>
          <li>Υπηρεσίες ελέγχου διαρροών πετρελαίου (0)
          </li>
          <li>Υπηρεσίες αποκατάστασης διαρροών πετρελαίου (0)
          </li>
          <li>Υπηρεσίες παρακολούθησης διαρροών πετρελαίου (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες που σχετίζονται με τη μόλυνση από τοξικές ουσίες (2)
        <ul>
          <li>Υπηρεσίες παρακολούθησης τοξικών ουσιών (0)
          </li>
          <li>Υπηρεσίες αποκατάστασης σε σχέση με τοξικές ουσίες (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες που σχετίζονται με την ηχορύπανση (4)
        <ul>
          <li>Υπηρεσίες προστασίας από την ηχορύπανση (0)
          </li>
          <li>Υπηρεσίες παρακολούθησης ηχορύπανσης (0)
          </li>
          <li>Υπηρεσίες παροχής συμβουλών σχετικά με την ηχορύπανση (0)
          </li>
          <li>Υπηρεσίες ελέγχου θορύβου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Προστασία του περιβάλλοντος (13)
      <ul>
        <li>Υπηρεσίες ασφάλειας του περιβάλλοντος (8)
        <ul>
          <li>Υπηρεσίες προστασίας από τοξικές ουσίες (0)
          </li>
          <li>Υπηρεσίες προστασίας από φυσικούς υπαρκτούς ή δυνητικούς κινδύνους (0)
          </li>
          <li>Υπηρεσίες προστασίας γενετικών πηγών (0)
          </li>
          <li>Υπηρεσίες προστασίας τροφών ή ζωοτροφών από μόλυνση (0)
          </li>
          <li>Υπηρεσίες προστασίας του όζοντος (0)
          </li>
          <li>Υπηρεσίες προστασίας από ακτινοβολία (0)
          </li>
          <li>Υπηρεσίες προστασίας του τοπίου (0)
          </li>
          <li>Υπηρεσίες προστασίας ειδών υπό εξαφάνιση (0)
          </li>
        </ul>
        </li>
        <li>Αποκατάσταση του περιβάλλοντος (3)
        <ul>
          <li>Υπηρεσίες απολύμανσης του περιβάλλοντος (0)
          </li>
          <li>Υπηρεσίες αναμόρφωσης γαιών (0)
          </li>
          <li>Αποκατάσταση βιομηχανικών χώρων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Διαχείριση περιβάλλοντος (34)
      <ul>
        <li>Περιβαλλοντική επιθεώρηση (6)
        <ul>
          <li>Υπηρεσίες ελέγχου ασφάλειας του περιβάλλοντος (0)
          </li>
          <li>Υπηρεσίες περιβαλλοντικής επιθεώρησης για συγκεκριμένες δραστηριότητες (0)
          </li>
          <li>Υπηρεσίες τομεακής περιβαλλοντικής επιθεώρησης (0)
          </li>
          <li>Υπηρεσίες συλλογικής περιβαλλοντικής επιθεώρησης (0)
          </li>
          <li>Υπηρεσίες ελέγχου ποιότητας του περιβάλλοντος (0)
          </li>
          <li>Περιβαλλοντικά πληροφοριακά συστήματα (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες έρευνας μόλυνσης (12)
        <ul>
          <li>Υπηρεσίες έρευνας μόλυνσης από χημικά και πετρέλαιο (2)
          <ul>
            <li>Έρευνα χώρων εργοστασίων αερίου (0)
            </li>
            <li>Έρευνα χώρων αποβλήτων χημικών εργασιών ή διύλισης πετρελαίου (0)
            </li>
          </ul>
          </li>
          <li>Λοιπές υπηρεσίες έρευνας μόλυνσης (8)
          <ul>
            <li>Έρευνα χώρων εγκαταστάσεων στεγνού καθαρισμού (0)
            </li>
            <li>Έρευνα χώρων εγκαταστάσεων επεξεργασίας τροφίμων (0)
            </li>
            <li>Έρευνα χώρων εγκαταστάσεων επεξεργασίας ξύλου (0)
            </li>
            <li>Έρευνα χώρων εγκαταστάσεων ανακύκλωσης (0)
            </li>
            <li>Έρευνα χώρων βιομηχανικών αποβλήτων (0)
            </li>
            <li>Έρευνα βιομηχανικών χώρων (0)
            </li>
            <li>Έρευνα χώρων αποθηκών ή τερματικών σταθμών πετρελαίου (0)
            </li>
            <li>Έρευνα χώρων χυτηρίων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες παροχής συμβουλών για περιβαλλοντικά ζητήματα (1)
        <ul>
          <li>Υπηρεσίες παροχής συμβουλών για την υδροδότηση και τα λύματα, εκτός των συμβουλών που προορίζονται για κατασκευαστικά έργα (0)
          </li>
        </ul>
        </li>
        <li>Περιβαλλοντικός σχεδιασμός (5)
        <ul>
          <li>Κατασκευή ή σχεδιασμός περιβαλλοντικών ιδρυμάτων (0)
          </li>
          <li>Σχεδιασμός αστικής περιβαλλοντικής ανάπτυξης (0)
          </li>
          <li>Διαχείριση φυσικών πηγών ή υπηρεσίες στρατηγικού σχεδιασμού διατήρησης (0)
          </li>
          <li>Στρατηγικός σχεδιασμός διατήρησης θαλάσσιου περιβάλλοντος (0)
          </li>
          <li>Στρατηγικός σχεδιασμός διατήρησης δασών (0)
          </li>
        </ul>
        </li>
        <li>Αξιολόγηση περιβαλλοντικής επίδρασης, εκτός αυτής που προορίζεται για κατασκευαστικά έργα (5)
        <ul>
          <li>Ανάλυση περιβαλλοντικών δεικτών, εκτός της ανάλυσης που προορίζεται για κατασκευαστικά έργα (0)
          </li>
          <li>Περιβαλλοντική παρακολούθηση, εκτός αυτής που προορίζεται για κατασκευαστικά έργα (0)
          </li>
          <li>Περιβαλλοντικά πρότυπα, εκτός αυτών που προορίζονται για κατασκευαστικά έργα (0)
          </li>
          <li>Αξιολόγηση υπαρκτών ή δυνητικών κινδύνων, εκτός αυτής που προορίζεται για κατασκευαστικά έργα (0)
          </li>
          <li>Υπηρεσίες αξιολόγησης περιβαλλοντικών επιπτώσεων (EIA), εκτός της αξιολόγησης που προορίζεται για κατασκευαστικά έργα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εντοπισμός και παρακολούθηση και αποκατάσταση μόλυνσης (33)
      <ul>
        <li>Υπηρεσίες που σχετίζονται με την ατμοσφαιρική ρύπανση (10)
        <ul>
          <li>Υπηρεσίες διαχείρισης ή ελέγχου διασυνοριακής ατμοσφαιρικής ρύπανσης (1)
          <ul>
            <li>Συναλλαγή πιστώσεων εκπομπής CO2 (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες προστασίας από την ατμοσφαιρική ρύπανση (0)
          </li>
          <li>Υπηρεσίες παρακολούθησης της καταστροφής της στιβάδας του όζοντος (0)
          </li>
          <li>Υπηρεσίες ανίχνευσης τοξικών αερίων (0)
          </li>
          <li>Υπηρεσίες παρακολούθησης διοξειδίου του άνθρακα (0)
          </li>
          <li>Διαχείριση ποιότητας αέρα (0)
          </li>
          <li>Παρακολούθηση μεθανίου (0)
          </li>
          <li>Παρακολούθηση αερόφερτων σωματιδίων (0)
          </li>
          <li>Υπηρεσίες παρακολούθησης ή μέτρησης ατμοσφαιρικής ρύπανσης (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες που σχετίζονται με ρυπασμένα ύδατα (9)
        <ul>
          <li>Υπηρεσίες αποστράγγισης ρυπασμένων υπόγειων υδάτων (0)
          </li>
          <li>Υπηρεσίες παρακολούθησης ή ελέγχου ρυπασμένων υπόγειων υδάτων (0)
          </li>
          <li>Υπηρεσίες αποκατάστασης ρυπασμένων επιφανειακών υδάτων (0)
          </li>
          <li>Υπηρεσίες επεξεργασίας επιφανειακών υδάτων (0)
          </li>
          <li>Υπηρεσίες παρακολούθησης ή ελέγχου ρυπασμένων επιφανειακών υδάτων (0)
          </li>
          <li>Υπηρεσίες αποστράγγισης ρυπασμένων επιφανειακών υδάτων (0)
          </li>
          <li>Υπηρεσίες προστασίας ρυπασμένων επιφανειακών υδάτων (0)
          </li>
          <li>Επεξεργασία ή αποκατάσταση ρυπασμένων υπόγειων υδάτων (0)
          </li>
          <li>Υπηρεσίες διαχείρισης ή ελέγχου ρυπασμένων διασυνοριακών υδάτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες που σχετίζονται με τη μόλυνση του εδάφους (11)
        <ul>
          <li>Μέτρηση ή παρακολούθηση ρυπασμένων εδαφών (0)
          </li>
          <li>Χαρτογράφηση ρυπασμένων εδαφών (0)
          </li>
          <li>Υπηρεσίες παροχής συμβουλών σχετικά με ρυπασμένα εδάφη (0)
          </li>
          <li>Αξιολόγηση μόλυνσης από παρασιτοκτόνα (0)
          </li>
          <li>Επεξεργασία ή αποκατάσταση ρυπασμένων εδαφών (0)
          </li>
          <li>Αξιολόγηση μόλυνσης από νιτρικά και φωσφορικά άλατα (2)
          <ul>
            <li>Αξιολόγηση μόλυνσης από φωσφορικά άλατα (0)
            </li>
            <li>Αξιολόγηση μόλυνσης από νιτρικά άλατα (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες προστασίας ρυπασμένου εδάφους (0)
          </li>
          <li>Υπηρεσίες αφαίρεσης ρυπασμένου εδάφους (0)
          </li>
          <li>Αξιολόγηση μόλυνσης από οργανικά λιπάσματα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Τρόφιμα, ποτά, καπνός και συναφή προϊόντα (476)
  <ul>
    <li className='top-level'>Γαλακτοκομικά προϊόντα (41)
    <ul>
      <li>Τυροκομικά προϊόντα (12)
      <ul>
        <li>Τριμμένο τυρί, τυρί σε σκόνη, τυρί τύπου ροκφόρ και άλλα τυριά (4)
        <ul>
          <li>Παρμεζάνα (0)
          </li>
          <li>Τριμμένο τυρί (0)
          </li>
          <li>Τυρί τύπου ροκφόρ (0)
          </li>
          <li>Τυρί τσένταρ (0)
          </li>
        </ul>
        </li>
        <li>Σκληρό τυρί (0)
        </li>
        <li>Τυριά για επάλειψη (0)
        </li>
        <li>Επιτραπέζιο τυρί (0)
        </li>
        <li>Φρέσκο τυρί (3)
        <ul>
          <li>Μαλακό τυρί (0)
          </li>
          <li>Άσπρο άπαχο τυρί τύπου κότατζ (0)
          </li>
          <li>Τυρί φέτα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Γάλα και κρέμα γάλακτος (14)
      <ul>
        <li>Γάλα (8)
        <ul>
          <li>Αποβουτυρωμένο γάλα (0)
          </li>
          <li>Παστεριωμένο γάλα (0)
          </li>
          <li>Σκόνη γάλακτος (0)
          </li>
          <li>Ημιαποβουτυρωμένο γάλα (0)
          </li>
          <li>Συμπυκνωμένο γάλα (0)
          </li>
          <li>Πλήρες γάλα (0)
          </li>
          <li>Αποστειρωμένο γάλα (1)
          <ul>
            <li>Γάλα UHT (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κρέμα γάλακτος (4)
        <ul>
          <li>Κρέμα σαντιγύ (0)
          </li>
          <li>Απλή κρέμα (0)
          </li>
          <li>Διπλή κρέμα (0)
          </li>
          <li>Πηχτή κρέμα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ανάμεικτα γαλακτοκομικά προϊόντα (11)
      <ul>
        <li>Παγωτό και συναφή προϊόντα (2)
        <ul>
          <li>Παγωτό (0)
          </li>
          <li>Γρανίτα (0)
          </li>
        </ul>
        </li>
        <li>Τυρόγαλα (0)
        </li>
        <li>Γιαούρτι και άλλα γαλακτοκομικά προϊόντα που έχουν υποστεί ζύμωση (4)
        <ul>
          <li>Βουτυρόγαλα (0)
          </li>
          <li>Γιαούρτι (2)
          <ul>
            <li>Γιαούρτι με άρωμα (0)
            </li>
            <li>Γιαούρτι χωρίς άρωμα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Λακτόζη και σιρόπι λακτόζης (0)
        </li>
        <li>Καζεΐνη (0)
        </li>
      </ul>
      </li>
      <li>Βούτυρο (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Ζωικά προϊόντα, κρέας και προϊόντα κρέατος (53)
    <ul>
      <li>Κρέας (27)
      <ul>
        <li>Χοιρινό κρέας (0)
        </li>
        <li>Πουλερικά (7)
        <ul>
          <li>Νωπά πουλερικά (4)
          <ul>
            <li>Γαλοπούλες (0)
            </li>
            <li>Κοτόπουλα (0)
            </li>
            <li>Πάπιες (0)
            </li>
            <li>Χήνες (0)
            </li>
          </ul>
          </li>
          <li>Εντόσθια πουλερικών (1)
          <ul>
            <li>Φουά γκρα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Διάφορα κρέατα (6)
        <ul>
          <li>Βατραχοπόδαρα (0)
          </li>
          <li>Θηράματα (0)
          </li>
          <li>Περιστέρια (0)
          </li>
          <li>Κρέας λαγού (0)
          </li>
          <li>Κρέας ψαριού (0)
          </li>
          <li>Κρέας κουνελιού (0)
          </li>
        </ul>
        </li>
        <li>Εντόσθια (0)
        </li>
        <li>Βόειο κρέας (2)
        <ul>
          <li>Βοδινό κρέας (0)
          </li>
          <li>Μοσχαρίσιο κρέας (0)
          </li>
        </ul>
        </li>
        <li>Κρέας αιγοειδών (0)
        </li>
        <li>Αρνίσιο και πρόβειο κρέας (2)
        <ul>
          <li>Αρνίσιο κρέας (0)
          </li>
          <li>Πρόβειο κρέας (0)
          </li>
        </ul>
        </li>
        <li>Κρέατα αλόγων, γαϊδουριών, μουλαριών ή ημιόνων (2)
        <ul>
          <li>Κρέας γαϊδουριών, μουλαριών ή ημιόνων (0)
          </li>
          <li>Κρέας αλόγου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Προϊόντα κρέατος (24)
      <ul>
        <li>Διατηρημένα και παρασκευασμένα κρέατα (23)
        <ul>
          <li>Παρασκευάσματα από βοδινό και μοσχαρίσιο κρέας (3)
          <ul>
            <li>Μπιφτέκια από βοδινό (0)
            </li>
            <li>Κεφτέδες από βοδινό κρέας (0)
            </li>
            <li>Κιμάς από βοδινό κρέας (0)
            </li>
          </ul>
          </li>
          <li>Παρασκευάσματα από συκώτι (2)
          <ul>
            <li>Παρασκευάσματα από συκώτι χήνας ή πάπιας (0)
            </li>
            <li>Πατέ (0)
            </li>
          </ul>
          </li>
          <li>Αποξηραμένα, αλατισμένα, καπνιστά και καρυκευμένα κρέατα (3)
          <ul>
            <li>Σαλάμι (0)
            </li>
            <li>Παστό χοιρομέρι (0)
            </li>
            <li>Μπέικον (0)
            </li>
          </ul>
          </li>
          <li>Προϊόντα από κρέας για λουκάνικα (5)
          <ul>
            <li>Προϊόντα από λουκάνικα (0)
            </li>
            <li>Κρέας για λουκάνικα (0)
            </li>
            <li>Λουκάνικα (2)
            <ul>
              <li>Λουκάνικα από πουλερικά (0)
              </li>
              <li>Λουκάνικα αίματος και άλλα λουκάνικα από αίμα (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Παρασκευάσματα από κρέας πουλερικών (0)
          </li>
          <li>Προϊόντα από χοιρινό κρέας (3)
          <ul>
            <li>Έτοιμα φαγητά από χοιρινό κρέας (0)
            </li>
            <li>Κεφτέδες από κρέας (0)
            </li>
            <li>Χοιρομέρι (0)
            </li>
          </ul>
          </li>
          <li>Παρασκευάσματα από κρέας (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ζωικά ή φυτικά έλαια και λίπη (24)
    <ul>
      <li>Εξευγενισμένα έλαια και λίπη (4)
      <ul>
        <li>Εξευγενισμένα έλαια (0)
        </li>
        <li>Υδρογονωμένα ή εστεροποιημένα έλαια ή λίπη (0)
        </li>
        <li>Φυτικοί κηροί (0)
        </li>
        <li>Εξευγενισμένα λίπη (0)
        </li>
      </ul>
      </li>
      <li>Ακατέργαστα ζωικά ή φυτικά έλαια και λίπη (13)
      <ul>
        <li>Στερεά κατάλοιπα φυτικών λιπών ή ελαίων (1)
        <ul>
          <li>Ελαιόπιτες (0)
          </li>
        </ul>
        </li>
        <li>Λίπη (2)
        <ul>
          <li>Ζωικά λίπη (0)
          </li>
          <li>Φυτικά λίπη (0)
          </li>
        </ul>
        </li>
        <li>Ζωικά ή φυτικά έλαια (7)
        <ul>
          <li>Φυτικά έλαια (4)
          <ul>
            <li>Έλαιο από καρύδα (0)
            </li>
            <li>Αραχιδέλαιο (0)
            </li>
            <li>Ελαιόλαδο (0)
            </li>
            <li>Σουσαμέλαιο (0)
            </li>
          </ul>
          </li>
          <li>Μαγειρικό λάδι (1)
          <ul>
            <li>Έλαιο για τηγάνισμα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Βρώσιμα λίπη (4)
      <ul>
        <li>Μαργαρίνη και παρόμοια παρασκευάσματα (3)
        <ul>
          <li>Αλειφόμενα παρασκευάσματα μειωμένης ή χαμηλής περιεκτικότητας λιπαρών (0)
          </li>
          <li>Μαργαρίνη (1)
          <ul>
            <li>Ρευστή μαργαρίνη (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Διάφορα προϊόντα διατροφής (130)
    <ul>
      <li>Κακάο· προϊόντα σοκολατοποιίας και ζαχαροπλαστικής (14)
      <ul>
        <li>Κακάο (4)
        <ul>
          <li>Σκόνη κακάου με προσθήκη γλυκαντικών ουσιών (0)
          </li>
          <li>Φυσική σκόνη κακάου (0)
          </li>
          <li>Βούτυρο, λίπος ή έλαιο κακάου (0)
          </li>
          <li>Πολτός κακάου (0)
          </li>
        </ul>
        </li>
        <li>Προϊόντα σοκολατοποιίας και ζαχαροπλαστικής (8)
        <ul>
          <li>Φρούτα, ξηροί καρποί ή φλοιοί καρπών διατηρημένοι με ζάχαρη (0)
          </li>
          <li>Προϊόντα σοκολάτας (2)
          <ul>
            <li>Ρόφημα σοκολάτας (0)
            </li>
            <li>Πλάκες σοκολάτας (0)
            </li>
          </ul>
          </li>
          <li>Σοκολάτα (0)
          </li>
          <li>Προϊόντα ζαχαροπλαστικής (2)
          <ul>
            <li>Βρασμένα γλυκίσματα (0)
            </li>
            <li>Μαντολάτο (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Καφές, τσάι και συναφή προϊόντα (10)
      <ul>
        <li>Τσάι (2)
        <ul>
          <li>Πράσινο τσάι (0)
          </li>
          <li>Μαύρο τσάι (0)
          </li>
        </ul>
        </li>
        <li>Υποκατάστατα του καφέ (0)
        </li>
        <li>Παρασκευάσματα με βάση το τσάι ή το ματέ (1)
        <ul>
          <li>Τσάι σε φακελάκια (0)
          </li>
        </ul>
        </li>
        <li>Καφές (2)
        <ul>
          <li>Καβουρδισμένος καφές (0)
          </li>
          <li>Καφές χωρίς καφεΐνη (0)
          </li>
        </ul>
        </li>
        <li>Αφεψήματα βοτάνων (0)
        </li>
      </ul>
      </li>
      <li>Αρτοσκευάσματα, φρέσκα είδη ζαχαροπλαστικής και γλυκίσματα (15)
      <ul>
        <li>Εδέσματα προγεύματος (0)
        </li>
        <li>Είδη ζαχαροπλαστικής και γλυκίσματα (5)
        <ul>
          <li>Είδη ζαχαροπλαστικής (3)
          <ul>
            <li>Πίτες (2)
            <ul>
              <li>Αλμυρές πίτες (0)
              </li>
              <li>Γλυκές πίτες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Γλυκά (0)
          </li>
        </ul>
        </li>
        <li>Προϊόντα άρτου (7)
        <ul>
          <li>Ψωμάκια (0)
          </li>
          <li>Παρασκευασμένα προϊόντα άρτου (2)
          <ul>
            <li>Σάντουιτς (1)
            <ul>
              <li>Έτοιμα σάντουιτς (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Κρουασάν (0)
          </li>
          <li>Τηγανίτες (0)
          </li>
          <li>Ψωμί (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ειδικά προϊόντα διατροφής (3)
      <ul>
        <li>Διαιτητικά προϊόντα (0)
        </li>
        <li>Τροφές για μωρά (0)
        </li>
        <li>Ομογενοποιημένα παρασκευάσματα τροφίμων (0)
        </li>
      </ul>
      </li>
      <li>Ζάχαρη και συναφή προϊόντα (10)
      <ul>
        <li>Κατάλοιπα από την παραγωγή ζάχαρης (0)
        </li>
        <li>Ζάχαρη (5)
        <ul>
          <li>Μελάσα (0)
          </li>
          <li>Σιρόπια ζάχαρης (0)
          </li>
          <li>Ζάχαρη και σιρόπι σφενδάμου (0)
          </li>
          <li>Μέλι (0)
          </li>
          <li>Λευκή ζάχαρη (0)
          </li>
        </ul>
        </li>
        <li>Ζαχαρώδη προϊόντα (2)
        <ul>
          <li>Επιδόρπια (1)
          <ul>
            <li>Τάρτες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Διάφορα προϊόντα διατροφής και ξηρά τροφή (37)
      <ul>
        <li>Ξηρά προϊόντα (3)
        <ul>
          <li>Μείγματα για επιδόρπια (0)
          </li>
          <li>Μείγματα ζωμών κρέατος (0)
          </li>
          <li>Μείγματα τροφών (0)
          </li>
        </ul>
        </li>
        <li>Ζυμωτική σκόνη (0)
        </li>
        <li>Κονσερβοποιημένα προϊόντα και ξηρά τροφή (3)
        <ul>
          <li>Ξηρά τροφή (0)
          </li>
          <li>Πακέτα τροφίμων (0)
          </li>
          <li>Κονσερβοποιημένα προϊόντα (0)
          </li>
        </ul>
        </li>
        <li>Προϊόντα φαστ-φουντ (1)
        <ul>
          <li>Χάμπουργκερ (0)
          </li>
        </ul>
        </li>
        <li>Σούπες και ζωμοί (9)
        <ul>
          <li>Κρεατόσουπες (0)
          </li>
          <li>Ψαρόσουπες (0)
          </li>
          <li>Χορτόσουπες (0)
          </li>
          <li>Σούπες (1)
          <ul>
            <li>Μείγματα σούπας (0)
            </li>
          </ul>
          </li>
          <li>Ανάμεικτες σούπες (0)
          </li>
          <li>Ζωμός (1)
          <ul>
            <li>Μείγματα για την παραγωγή ζωμών κρέατος (0)
            </li>
          </ul>
          </li>
          <li>Ζωμοί (0)
          </li>
        </ul>
        </li>
        <li>Προϊόντα βαθείας καταψύξεως (0)
        </li>
        <li>Παρασκευασμένα προϊόντα διατροφής (9)
        <ul>
          <li>Εκλεκτά εδέσματα (0)
          </li>
          <li>Πρόχειρο φαγητό (0)
          </li>
          <li>Συστατικά για σάντουιτς (0)
          </li>
          <li>Έτοιμα γεύματα για χορτοφάγους (0)
          </li>
          <li>Έτοιμα γεύματα (2)
          <ul>
            <li>Σχολικά γεύματα (0)
            </li>
            <li>Γεύματα νοσοκομείου (0)
            </li>
          </ul>
          </li>
          <li>Έτοιμα φαγητά (0)
          </li>
          <li>Είδη που διατίθενται μέσω αυτόματων μηχανών πώλησης (0)
          </li>
        </ul>
        </li>
        <li>Μαγιά (0)
        </li>
        <li>Φυτικοί χυμοί, εκχυλίσματα, πεπτικές ουσίες και πηκτικοί παράγοντες (3)
        <ul>
          <li>Πηκτικοί παράγοντες (0)
          </li>
          <li>Φυτικά εκχυλίσματα (0)
          </li>
          <li>Φυτικοί χυμοί (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Παξιμάδια και μπισκότα· διατηρημένα είδη ζαχαροπλαστικής και γλυκίσματα (6)
      <ul>
        <li>Φρυγανισμένα προϊόντα άρτου και είδη ζαχαροπλαστικής (5)
        <ul>
          <li>Γλυκά μπισκότα (0)
          </li>
          <li>Φρυγανισμένα προϊόντα άρτου (3)
          <ul>
            <li>Φρυγανιές (0)
            </li>
            <li>Παξιμάδια (0)
            </li>
            <li>Τραγανό ψωμί (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Προϊόντα ζυμαρικών (8)
      <ul>
        <li>Αλευρώδη προϊόντα (7)
        <ul>
          <li>Μη μαγειρεμένα ζυμαρικά (0)
          </li>
          <li>Μαγειρεμένα ζυμαρικά και κουσκούς (5)
          <ul>
            <li>Κουσκούς (0)
            </li>
            <li>Μαγειρεμένα ζυμαρικά (0)
            </li>
            <li>Λαζάνια (0)
            </li>
            <li>Κονσερβοποιημένα ζυμαρικά (0)
            </li>
            <li>Παραγεμισμένα ζυμαρικά (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Καρυκεύματα και αρτύματα (18)
      <ul>
        <li>Ξίδι· σάλτσες· ανάμεικτα καρυκεύματα· άλευρο και χονδράλευρο σιναπιού· παρασκευασμένη μουστάρδα (11)
        <ul>
          <li>Ξίδι και υποκατάστατα του ξιδιού (1)
          <ul>
            <li>Ξίδι ή ισοδύναμο (0)
            </li>
          </ul>
          </li>
          <li>Σάλτσες, ανάμεικτα καρυκεύματα και αρτύματα (8)
          <ul>
            <li>Σάλτσα σόγιας (0)
            </li>
            <li>Σάλτσες (0)
            </li>
            <li>Μουστάρδα (0)
            </li>
            <li>Ανάμεικτα καρυκεύματα (3)
            <ul>
              <li>Αλειφόμενα παρασκευάσματα για σάντουιτς (0)
              </li>
              <li>Τουρσί (0)
              </li>
              <li>Μαγιονέζα (0)
              </li>
            </ul>
            </li>
            <li>Κέτσαπ (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Αρωματικά βότανα και μπαχαρικά (5)
        <ul>
          <li>Πιπερόριζα (0)
          </li>
          <li>Μπαχαρικά (0)
          </li>
          <li>Πιπέρι (0)
          </li>
          <li>Αλάτι (0)
          </li>
          <li>Αρωματικά βότανα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Φρούτα, λαχανικά και συναφή προϊόντα (93)
    <ul>
      <li>Χυμοί φρούτων και λαχανικών (11)
      <ul>
        <li>Χυμοί λαχανικών (1)
        <ul>
          <li>Χυμός τομάτας (0)
          </li>
        </ul>
        </li>
        <li>Χυμοί φρούτων (8)
        <ul>
          <li>Χυμός λεμονιού (0)
          </li>
          <li>Συμπυκνωμένοι χυμοί (0)
          </li>
          <li>Χυμός πορτοκαλιού (0)
          </li>
          <li>Χυμός ανανά (0)
          </li>
          <li>Χυμός σταφυλιού (0)
          </li>
          <li>Χυμός μήλου (0)
          </li>
          <li>Συμπυκνωμένος χυμός γκρέιπ φρουτ (0)
          </li>
          <li>Μείγματα μη συμπυκνωμένων χυμών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Πατάτες και προϊόντα πατάτας (11)
      <ul>
        <li>Κατεψυγμένες πατάτες (2)
        <ul>
          <li>Πατάτες σε κύβους, φέτες και άλλα είδη κατεψυγμένης πατάτας (0)
          </li>
          <li>Κομμένες πατάτες για τηγάνισμα (0)
          </li>
        </ul>
        </li>
        <li>Επεξεργασμένες πατάτες (0)
        </li>
        <li>Προϊόντα πατάτας (6)
        <ul>
          <li>Εδέσματα πατάτας (0)
          </li>
          <li>Στιγμιαίος πουρές πατάτας (0)
          </li>
          <li>Τσιπς πατάτας (1)
          <ul>
            <li>Αρωματισμένα τσιπς πατάτας (0)
            </li>
          </ul>
          </li>
          <li>Κροκέτες πατάτας (0)
          </li>
          <li>Προτηγανισμένες πατάτες για τηγάνισμα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Επεξεργασμένα φρούτα και λαχανικά (68)
      <ul>
        <li>Φυτικά υποπροϊόντα (0)
        </li>
        <li>Επεξεργασμένα λαχανικά (36)
        <ul>
          <li>Νωπά ή κατεψυγμένα λαχανικά (15)
          <ul>
            <li>Επεξεργασμένα κονδυλώδη λαχανικά (0)
            </li>
            <li>Επεξεργασμένα βολβοειδή λαχανικά (0)
            </li>
            <li>Φασόλια, αρακάς, πιπεριές, τομάτες και άλλα λαχανικά (8)
            <ul>
              <li>Επεξεργασμένος αρακάς (0)
              </li>
              <li>Ανθός σόγιας (0)
              </li>
              <li>Τρούφες (0)
              </li>
              <li>Επεξεργασμένες πιπεριές (0)
              </li>
              <li>Αρακάς ανοιγμένος στα δύο (0)
              </li>
              <li>Επεξεργασμένα φασόλια (0)
              </li>
              <li>Επεξεργασμένες τομάτες (0)
              </li>
              <li>Επεξεργασμένα μανιτάρια (0)
              </li>
            </ul>
            </li>
            <li>Φυλλώδη και κραμβοειδή λαχανικά (1)
            <ul>
              <li>Επεξεργασμένη κράμβη (0)
              </li>
            </ul>
            </li>
            <li>Κατεψυγμένα λαχανικά (0)
            </li>
            <li>Επεξεργασμένα όσπρια (0)
            </li>
          </ul>
          </li>
          <li>Διατηρημένα ή και κονσερβοποιημένα λαχανικά (18)
          <ul>
            <li>Μανιτάρια σε κονσέρβα (0)
            </li>
            <li>Γλυκό καλαμπόκι (0)
            </li>
            <li>Κονσερβοποιημένα λαχανικά (6)
            <ul>
              <li>Ολόκληρα φασόλια σε κονσέρβα (0)
              </li>
              <li>Λάχανο τουρσί σε κονσέρβα (0)
              </li>
              <li>Αρακάς σε κονσέρβα (0)
              </li>
              <li>Ελιές σε κονσέρβα (0)
              </li>
              <li>Αποφλοιωμένα φασόλια σε κονσέρβα (0)
              </li>
              <li>Σπαράγγια σε κονσέρβα (0)
              </li>
            </ul>
            </li>
            <li>Διατηρημένες τομάτες (4)
            <ul>
              <li>Σάλτσα από τομάτα (0)
              </li>
              <li>Τοματοπολτός (0)
              </li>
              <li>Τομάτες σε κονσέρβα (0)
              </li>
              <li>Τοματοπελτές (0)
              </li>
            </ul>
            </li>
            <li>Επεξεργασμένες ελιές (0)
            </li>
            <li>Φασόλια σε σάλτσα τομάτας (1)
            <ul>
              <li>Μαγειρεμένα φασόλια (0)
              </li>
            </ul>
            </li>
            <li>Λαχανικά προσωρινής διατήρησης (0)
            </li>
          </ul>
          </li>
          <li>Λαχανικά διατηρημένα σε ξίδι (0)
          </li>
        </ul>
        </li>
        <li>Επεξεργασμένα φρούτα και καρποί (29)
        <ul>
          <li>Διατηρημένα φρούτα (4)
          <ul>
            <li>Αποξηραμένα φρούτα (3)
            <ul>
              <li>Επεξεργασμένα φραγκοστάφυλα (0)
              </li>
              <li>Επεξεργασμένα σταφύλια (0)
              </li>
              <li>Σουλτανίνα (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Επεξεργασμένα φρούτα (5)
          <ul>
            <li>Ρήο (0)
            </li>
            <li>Επεξεργασμένες μπανάνες (0)
            </li>
            <li>Επεξεργασμένα μήλα (0)
            </li>
            <li>Πεπόνια (0)
            </li>
            <li>Επεξεργασμένα αχλάδια (0)
            </li>
          </ul>
          </li>
          <li>Μαρμελάδες· ζελέδες φρούτων· πουρέδες και πολτοί φρούτων ή ξηρών καρπών (15)
          <ul>
            <li>Πολτός ξηρών καρπών (1)
            <ul>
              <li>Φυστικοβούτυρο (0)
              </li>
            </ul>
            </li>
            <li>Μαρμελάδες (6)
            <ul>
              <li>Μαρμελάδα κεράσι (0)
              </li>
              <li>Μαρμελάδα βατόμουρο (0)
              </li>
              <li>Μαρμελάδα βερίκοκο (0)
              </li>
              <li>Μαρμελάδα σμέουρο (0)
              </li>
              <li>Μαρμελάδα φράουλα (0)
              </li>
              <li>Μαρμελάδα μαύρο φραγκοστάφυλο (0)
              </li>
            </ul>
            </li>
            <li>Ζελέδες φρούτων (0)
            </li>
            <li>Πουρέδες φρούτων (0)
            </li>
            <li>Πολτός φρούτων (0)
            </li>
            <li>Μαρμελάδες από εσπεριδοειδή (2)
            <ul>
              <li>Μαρμελάδα λεμόνι (0)
              </li>
              <li>Μαρμελάδα πορτοκάλι (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Επεξεργασμένοι καρποί (1)
          <ul>
            <li>Καβουρδισμένοι ή αλατισμένοι ξηροί καρποί (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Προϊόντα αλεσμένων δημητριακών, άμυλα και αμυλώδη προϊόντα (45)
    <ul>
      <li>Προϊόντα αλεσμένων δημητριακών (29)
      <ul>
        <li>Αποφλοιωμένο ρύζι (0)
        </li>
        <li>Πίτουρο (0)
        </li>
        <li>Προϊόντα από σπόρους δημητριακών (7)
        <ul>
          <li>Προϊόντα από δημητριακά (5)
          <ul>
            <li>Ρολό από βρώμη (0)
            </li>
            <li>Παρασκευασμένα δημητριακά για πρόγευμα (3)
            <ul>
              <li>Μείγμα σπόρων δημητριακών για πρόγευμα ή ισοδύναμο (0)
              </li>
              <li>Φουσκωμένο σιτάρι (0)
              </li>
              <li>Νιφάδες αραβοσίτου (κορν φλέικς) (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Αλεσμένοι σπόροι βρώμης (0)
          </li>
        </ul>
        </li>
        <li>Επεξεργασμένο ρύζι (3)
        <ul>
          <li>Θρυμματισμένο ρύζι (0)
          </li>
          <li>Αλεσμένο ρύζι (0)
          </li>
          <li>Μακρόκαρπο ρύζι (0)
          </li>
        </ul>
        </li>
        <li>Άλευρα δημητριακών ή φυτικά άλευρα και συναφή προϊόντα (14)
        <ul>
          <li>Προϊόντα αρτοποιίας (0)
          </li>
          <li>Σιτάλευρο (5)
          <ul>
            <li>Αλεύρι που φουσκώνει μόνο του (0)
            </li>
            <li>Κοινό αλεύρι αρτοποιίας (0)
            </li>
            <li>Καθαρό αλεύρι (0)
            </li>
            <li>Αλεύρι ζαχαροπλαστικής (0)
            </li>
            <li>Αλεύρι ολικής αλέσεως (0)
            </li>
          </ul>
          </li>
          <li>Φυτικά άλευρα και χονδράλευρα (0)
          </li>
          <li>Μείγματα για την παρασκευή αρτοσκευασμάτων (2)
          <ul>
            <li>Μείγματα αρτοποιίας (0)
            </li>
            <li>Μείγματα για την παρασκευή κέικ (0)
            </li>
          </ul>
          </li>
          <li>Αλεύρι δημητριακών (2)
          <ul>
            <li>Ριζάλευρο (0)
            </li>
            <li>Καλαμποκάλευρο (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Άμυλα και αμυλώδη προϊόντα (14)
      <ul>
        <li>Γλυκόζη και προϊόντα γλυκόζης· φρουκτόζη και προϊόντα φρουκτόζης (8)
        <ul>
          <li>Γλυκόζη και προϊόντα γλυκόζης (2)
          <ul>
            <li>Σιρόπι γλυκόζης (0)
            </li>
            <li>Γλυκόζη (0)
            </li>
          </ul>
          </li>
          <li>Φρουκτόζη και προϊόντα φρουκτόζης (4)
          <ul>
            <li>Φρουκτόζη (0)
            </li>
            <li>Παρασκευάσματα από φρουκτόζη (2)
            <ul>
              <li>Σιρόπι φρουκτόζης (0)
              </li>
              <li>Διαλύματα φρουκτόζης (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Σιμιγδάλι (0)
        </li>
        <li>Σκόνη κρέμας από γάλα και αυγά (0)
        </li>
        <li>Αραβοσιτέλαιο (0)
        </li>
        <li>Ταπιόκα (0)
        </li>
        <li>Άμυλα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Παρασκευασμένα και διατηρημένα ψάρια (33)
    <ul>
      <li>Φιλέτα ψαριών, συκώτια ψαριών και αυγοτάραχο (4)
      <ul>
        <li>Αυγοτάραχο (0)
        </li>
        <li>Φιλέτα ψαριών (1)
        <ul>
          <li>Νωπά φιλέτα ψαριών (0)
          </li>
        </ul>
        </li>
        <li>Συκώτια ψαριών (0)
        </li>
      </ul>
      </li>
      <li>Κατεψυγμένα ψάρια, φιλέτα ψαριών και άλλα κρέατα ψαριών (2)
      <ul>
        <li>Κατεψυγμένα προϊόντα ψαριού (0)
        </li>
        <li>Κατεψυγμένα ψάρια (0)
        </li>
      </ul>
      </li>
      <li>Αποξηραμένα ή παστά ψάρια· ψάρια σε άλμη· καπνιστά ψάρια (5)
      <ul>
        <li>Καπνιστό ψάρι (0)
        </li>
        <li>Παστά ψάρια (0)
        </li>
        <li>Αποξηραμένα ψάρια (0)
        </li>
        <li>Ψάρια σε άλμη (0)
        </li>
        <li>Διατηρημένα ψάρια (0)
        </li>
      </ul>
      </li>
      <li>Θαλασσινά (3)
      <ul>
        <li>Παρασκευασμένα ή διατηρημένα καρκινοειδή (0)
        </li>
        <li>Κατεψυγμένα καρκινοειδή (0)
        </li>
        <li>Προϊόντα οστρακοειδών (0)
        </li>
      </ul>
      </li>
      <li>Κονσερβοποιημένα ψάρια και άλλα παρασκευασμένα ή διατηρημένα ψάρια (14)
      <ul>
        <li>Παρασκευάσματα ψαριού (0)
        </li>
        <li>Χαβιάρι και αυγά ψαριού (2)
        <ul>
          <li>Αυγά ψαριού (0)
          </li>
          <li>Χαβιάρι (0)
          </li>
        </ul>
        </li>
        <li>Έτοιμα φαγητά ψαριών (0)
        </li>
        <li>Κονσερβοποιημένα και παρασκευασμένα ψάρια (8)
        <ul>
          <li>Παρασκευασμένες ή διατηρημένες ρέγγες (0)
          </li>
          <li>Κονσερβοποιημένος τόνος (0)
          </li>
          <li>Σκουμπρί (0)
          </li>
          <li>Παναρισμένα τεμάχια ψαριού (0)
          </li>
          <li>Σαρδέλες (0)
          </li>
          <li>Αντζούγες (0)
          </li>
          <li>Κροκέτες ψαριών (0)
          </li>
          <li>Κονσερβοποιημένος σολομός (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ζωοτροφές (4)
    <ul>
      <li>Παρασκευασμένες ζωοτροφές για ζώα εκτρεφόμενα σε αγροκτήματα και άλλα ζώα (3)
      <ul>
        <li>Ξηρή ζωοτροφή (0)
        </li>
        <li>Τροφές για ψάρια (0)
        </li>
        <li>Τροφές για κατοικίδια ζώα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ποτά, καπνός και συναφή προϊόντα (44)
    <ul>
      <li>Μη αποσταγμένα ποτά που προέρχονται από ζύμωση (1)
      <ul>
        <li>Βερμούτ (0)
        </li>
      </ul>
      </li>
      <li>Μη αλκοολούχα ποτά (10)
      <ul>
        <li>Αναψυκτικά (2)
        <ul>
          <li>Φρουτοχυμοί (0)
          </li>
          <li>Σοκολατούχο γάλα (0)
          </li>
        </ul>
        </li>
        <li>Μεταλλικό νερό (6)
        <ul>
          <li>Μη αεριούχο μεταλλικό νερό (0)
          </li>
          <li>Αεριούχο μεταλλικό νερό (0)
          </li>
          <li>Αρωματικά μεταλλικά νερά (0)
          </li>
          <li>Νερό σε στερεά μορφή (2)
          <ul>
            <li>Χιόνι (0)
            </li>
            <li>Πάγος (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Αποσταγμένα αλκοολούχα ποτά (3)
      <ul>
        <li>Οινοπνευματώδη ποτά (2)
        <ul>
          <li>Λικέρ (0)
          </li>
          <li>Οινοπνευματώδη (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μηλίτης οίνος και άλλα κρασιά από φρούτα (2)
      <ul>
        <li>Μηλίτης οίνος (0)
        </li>
        <li>Κρασιά από φρούτα (0)
        </li>
      </ul>
      </li>
      <li>Καπνός, προϊόντα με βάση τον καπνό και συναφή είδη (10)
      <ul>
        <li>Τσιγαρόχαρτο και χαρτί για φίλτρα τσιγάρων (2)
        <ul>
          <li>Χαρτί για φίλτρα τσιγάρων (0)
          </li>
          <li>Τσιγαρόχαρτο (0)
          </li>
        </ul>
        </li>
        <li>Προϊόντα με βάση τον καπνό (3)
        <ul>
          <li>Πουράκια (0)
          </li>
          <li>Τσιγάρα (0)
          </li>
          <li>Πούρα (0)
          </li>
        </ul>
        </li>
        <li>Καπνός (1)
        <ul>
          <li>Μεταποιημένος καπνός (0)
          </li>
        </ul>
        </li>
        <li>Είδη καπνιστού (0)
        </li>
      </ul>
      </li>
      <li>Κρασιά (8)
      <ul>
        <li>Μη αρωματισμένα κρασιά (6)
        <ul>
          <li>Μαδέρα (0)
          </li>
          <li>Επιτραπέζιο κρασί (0)
          </li>
          <li>Μούστος (0)
          </li>
          <li>Πορτό (0)
          </li>
          <li>Αφρώδης οίνος (0)
          </li>
          <li>Σέρρυ (0)
          </li>
        </ul>
        </li>
        <li>Οινολάσπη (0)
        </li>
      </ul>
      </li>
      <li>Μπίρα από βύνη (3)
      <ul>
        <li>Μπίρα (1)
        <ul>
          <li>Μπίρα τύπου λάγκερ (0)
          </li>
        </ul>
        </li>
        <li>Κατάλοιπα ζυθοποιίας ή απόσταξης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Έντυπο υλικό και συναφή προϊόντα (108)
  <ul>
    <li className='top-level'>Εφημερίδες, περιοδικές εκδόσεις και περιοδικά (6)
    <ul>
      <li>Εφημερίδες (5)
      <ul>
        <li>Περιοδικές εκδόσεις (1)
        <ul>
          <li>Σειρές (0)
          </li>
        </ul>
        </li>
        <li>Περιοδικά (0)
        </li>
        <li>Περιοδικές εφημερίδες (1)
        <ul>
          <li>Επίσημες εφημερίδες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Τυπωμένα βιβλία, διαφημιστικά φυλλάδια και ενημερωτικά έντυπα (18)
    <ul>
      <li>Τυπωμένα βιβλία (11)
      <ul>
        <li>Σχολικά βιβλία (0)
        </li>
        <li>Βιβλία διδασκαλίας (0)
        </li>
        <li>Βιβλία βιβλιοθήκης (0)
        </li>
        <li>Λεξικά, χάρτες, παρτιτούρες και άλλα βιβλία (7)
        <ul>
          <li>Λεξικά (0)
          </li>
          <li>Γεωγραφικοί άτλαντες (0)
          </li>
          <li>Εγκυκλοπαίδειες (0)
          </li>
          <li>Χάρτες (2)
          <ul>
            <li>Χάρτες κτηματολογίων (1)
            <ul>
              <li>Σχεδιαγράμματα (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Παρτιτούρες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Δημοσιεύσεις (1)
      <ul>
        <li>Δημοσιεύσεις τεχνικών θεμάτων (0)
        </li>
      </ul>
      </li>
      <li>Ευρετήρια (0)
      </li>
      <li>Διαφημιστικά φυλλάδια (0)
      </li>
      <li>Ενημερωτικά έντυπα (0)
      </li>
      <li>Βιβλιάρια (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Εκτυπωτικές πλάκες ή κύλινδροι ή άλλα μέσα που χρησιμοποιούνται στις εκτυπώσεις (3)
    <ul>
      <li>Πλάκες όφσετ (0)
      </li>
      <li>Εξοπλισμός ξηρής χάραξης (1)
      <ul>
        <li>Εξοπλισμός ανάγλυφης αποτύπωσης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Γραμματόσημα, επιταγές, τραπεζογραμμάτια, πιστοποιητικά αποθήκης, εμπορικό διαφημιστικό υλικό, κατάλογοι και εγχειρίδια (28)
    <ul>
      <li>Γραμματόσημα (4)
      <ul>
        <li>Κουπόνια εμπορικών προσφορών (0)
        </li>
        <li>Νέα γραμματόσημα (0)
        </li>
        <li>Θήκες γραμματοσήμων (0)
        </li>
        <li>Χριστουγεννιάτικα γραμματόσημα (0)
        </li>
      </ul>
      </li>
      <li>Τραπεζογραμμάτια (0)
      </li>
      <li>Χαρτί με αποτύπωμα σφραγίδας (0)
      </li>
      <li>Επιταγές (0)
      </li>
      <li>Εγχειρίδια (3)
      <ul>
        <li>Εγχειρίδια οδηγιών χρήσης (0)
        </li>
        <li>Εγχειρίδια ηλεκτρονικών υπολογιστών (0)
        </li>
        <li>Τεχνικά εγχειρίδια (0)
        </li>
      </ul>
      </li>
      <li>Έντυπο υλικό ασφαλείας (11)
      <ul>
        <li>Άδειες (0)
        </li>
        <li>Άδειες οδήγησης (0)
        </li>
        <li>Εισιτήρια (1)
        <ul>
          <li>Διαφημιστικά αυτοκόλλητα και ταινίες (0)
          </li>
        </ul>
        </li>
        <li>Ταυτότητες (1)
        <ul>
          <li>Περικάρπια αναγνώρισης ταυτότητας (0)
          </li>
        </ul>
        </li>
        <li>Κάρτες εισόδου (0)
        </li>
        <li>Δελτία παραγγελίας (0)
        </li>
        <li>Διαβατήρια (0)
        </li>
        <li>Ταχυδρομικές εντολές (0)
        </li>
        <li>Σήματα τελών κυκλοφορίας (0)
        </li>
      </ul>
      </li>
      <li>Εμπορικό διαφημιστικό υλικό, εμπορικοί κατάλογοι και εγχειρίδια (3)
      <ul>
        <li>Διαφημιστικό υλικό (0)
        </li>
        <li>Κατάλογοι (1)
        <ul>
          <li>Θήκες καταλόγων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ποικίλα έντυπα υλικά (8)
    <ul>
      <li>Δημοσιογραφικός χάρτης, χειροποίητο χαρτί και άλλο μη επιχρισμένο χαρτί ή χαρτόνι για γραφική χρήση (7)
      <ul>
        <li>Χειροποίητο χαρτί ή χαρτόνι (0)
        </li>
        <li>Φωτοευαίσθητο, θερμοευαίσθητο ή θερμογραφικό χαρτί και χαρτόνι (4)
        <ul>
          <li>Θερμογραφικό χαρτί ή χαρτόνι (0)
          </li>
          <li>Θερμοευαίσθητο χαρτί ή χαρτόνι (0)
          </li>
          <li>Φωτοευαίσθητο χαρτί ή χαρτόνι (0)
          </li>
          <li>Κυματοειδές χαρτί ή χαρτόνι (0)
          </li>
        </ul>
        </li>
        <li>Δημοσιογραφικό χαρτί (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ταχυδρομικές κάρτες, ευχετήριες κάρτες και άλλο έντυπο υλικό (7)
    <ul>
      <li>Ευχετήριες κάρτες (1)
      <ul>
        <li>Χριστουγεννιάτικες κάρτες (0)
        </li>
      </ul>
      </li>
      <li>Ταχυδρομικές κάρτες (4)
      <ul>
        <li>Χαλκομανίες (0)
        </li>
        <li>Φωτογραφίες (0)
        </li>
        <li>Εικόνες (0)
        </li>
        <li>Σχέδια (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μητρώα από χαρτί ή χαρτόνι, λογιστικά βιβλία, κλασέρ, έντυπα και άλλα τυπωμένα χαρτικά είδη (27)
    <ul>
      <li>Έντυπα (4)
      <ul>
        <li>Επαγγελματικά έντυπα (2)
        <ul>
          <li>Μη συνεχή επαγγελματικά έντυπα (0)
          </li>
          <li>Συνεχή επαγγελματικά έντυπα (0)
          </li>
        </ul>
        </li>
        <li>Εκλογικά έντυπα (0)
        </li>
      </ul>
      </li>
      <li>Κλασέρ και εξαρτήματα (4)
      <ul>
        <li>Θήκες εγγράφων (0)
        </li>
        <li>Ντοσιέ (1)
        <ul>
          <li>Καλύμματα εγγράφων (0)
          </li>
        </ul>
        </li>
        <li>Κλασέρ (0)
        </li>
      </ul>
      </li>
      <li>Τετράδια (2)
      <ul>
        <li>Ανταλλακτικό χαρτί για σχολικά τετράδια (0)
        </li>
        <li>Φύλλα γραφής (0)
        </li>
      </ul>
      </li>
      <li>Μητρώα από χαρτί ή χαρτόνι (9)
      <ul>
        <li>Σημειωματάρια (0)
        </li>
        <li>Λογιστικά βιβλία (0)
        </li>
        <li>Μπλοκ (3)
        <ul>
          <li>Αυτοκόλλητα σημειωματάρια (0)
          </li>
          <li>Μπλοκ σημειώσεων (0)
          </li>
          <li>Τετράδια στενογραφίας (0)
          </li>
        </ul>
        </li>
        <li>Βιβλία διευθύνσεων (0)
        </li>
        <li>Μπλοκ αποδείξεων (0)
        </li>
        <li>Ημερολόγια ή προσωπικές ατζέντες (0)
        </li>
      </ul>
      </li>
      <li>Λευκώματα για δείγματα (3)
      <ul>
        <li>Λευκώματα για συλλογές (2)
        <ul>
          <li>Βιβλία γραμματοσήμων (0)
          </li>
          <li>Βιβλία συλλογών γραμματοσήμων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μελάνη (3)
    <ul>
      <li>Τυπογραφική μελάνη (2)
      <ul>
        <li>Σινική μελάνη (0)
        </li>
        <li>Μελάνη βαθυτυπίας (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες έρευνας και ανάπτυξης, καθώς και συναφείς υπηρεσίες παροχής συμβουλών (25)
  <ul>
    <li className='top-level'>Μελέτη και εκτέλεση ερευνητικών και αναπτυξιακών δραστηριοτήτων (0)
    </li>
    <li className='top-level'>Υπηρεσίες έρευνας και πειραματικής ανάπτυξης (4)
    <ul>
      <li>Υπηρεσίες πειραματικής ανάπτυξης (0)
      </li>
      <li>Υπηρεσίες έρευνας (2)
      <ul>
        <li>Υπηρεσίες θαλάσσιων ερευνών (0)
        </li>
        <li>Υπηρεσίες εργαστηριακών ερευνών (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες έρευνας και ανάπτυξης σε θέματα ασφάλειας και άμυνας (15)
    <ul>
      <li>Δοκιμές και αξιολόγηση (6)
      <ul>
        <li>Δοκιμές και αξιολόγηση στρατιωτικών οχημάτων (0)
        </li>
        <li>Δοκιμές και αξιολόγηση στρατιωτικών ηλεκτρονικών συστημάτων (0)
        </li>
        <li>Δοκιμές και αξιολόγηση πολεμικών πλοίων (0)
        </li>
        <li>Δοκιμές και αξιολόγηση στρατιωτικών αεροσκαφών, πυραύλων και διαστημικών αεροσκαφών (0)
        </li>
        <li>Δοκιμές και αξιολόγηση εξοπλισμού ασφαλείας (0)
        </li>
        <li>Δοκιμές και αξιολόγηση πυροβόλων όπλων και πυρομαχικών (0)
        </li>
      </ul>
      </li>
      <li>Στρατιωτική έρευνα και τεχνολογία (0)
      </li>
      <li>Προκαταρκτικές μελέτες σκοπιμότητας και τεχνολογική επίδειξη (6)
      <ul>
        <li>Ανάπτυξη εξοπλισμού ασφαλείας (0)
        </li>
        <li>Ανάπτυξη πολεμικών πλοίων (0)
        </li>
        <li>Ανάπτυξη στρατιωτικών αεροσκαφών, πυραύλων και διαστημικών αεροσκαφών (0)
        </li>
        <li>Ανάπτυξη στρατιωτικών ηλεκτρονικών συστημάτων (0)
        </li>
        <li>Ανάπτυξη πυροβόλων όπλων και πυρομαχικών (0)
        </li>
        <li>Ανάπτυξη στρατιωτικών οχημάτων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες παροχής συμβουλών σε θέματα έρευνας και ανάπτυξης (2)
    <ul>
      <li>Υπηρεσίες παροχής συμβουλών σε θέματα έρευνας (0)
      </li>
      <li>Υπηρεσίες παροχής συμβουλών σε θέματα ανάπτυξης (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Εξορυκτικά προϊόντα, βασικά μέταλλα και συναφή προϊόντα (147)
  <ul>
    <li className='top-level'>Προϊόντα ορυχείων και λατομείων (16)
    <ul>
      <li>Πολύτιμοι και ημιπολύτιμοι λίθοι· κισηρόλιθοι· σμύριδα· φυσικά λειαντικά· άλλα ορυκτά και πολύτιμα μέταλλα (15)
      <ul>
        <li>Ορυκτά, πολύτιμα μέταλλα και συναφή προϊόντα (4)
        <ul>
          <li>Άργυρος (0)
          </li>
          <li>Χρυσός (0)
          </li>
          <li>Λευκόχρυσος (0)
          </li>
          <li>Ορυκτά (0)
          </li>
        </ul>
        </li>
        <li>Πολύτιμοι και ημιπολύτιμοι λίθοι (4)
        <ul>
          <li>Ημιπολύτιμοι λίθοι (1)
          <ul>
            <li>Κονιοποιημένοι ημιπολύτιμοι λίθοι (0)
            </li>
          </ul>
          </li>
          <li>Πολύτιμοι λίθοι (1)
          <ul>
            <li>Κονιοποιημένοι πολύτιμοι λίθοι (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Βιομηχανικά διαμάντια· κισηρόλιθοι· σμύριδα· άλλα φυσικά λειαντικά (4)
        <ul>
          <li>Σμύριδα (0)
          </li>
          <li>Φυσικά λειαντικά (0)
          </li>
          <li>Βιομηχανικά διαμάντια (0)
          </li>
          <li>Κισηρόλιθος (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Βασικά μέταλλα (52)
    <ul>
      <li>Σίδηρος, μόλυβδος, ψευδάργυρος, κασσίτερος και χαλκός (6)
      <ul>
        <li>Κασσίτερος (0)
        </li>
        <li>Σίδηρος (1)
        <ul>
          <li>Ακατέργαστος χυτοσίδηρος (0)
          </li>
        </ul>
        </li>
        <li>Χαλκός (0)
        </li>
        <li>Ψευδάργυρος (0)
        </li>
        <li>Μόλυβδος (0)
        </li>
      </ul>
      </li>
      <li>Καίσιο, στρόντιο, ρουβήδιο και ασβέστιο (4)
      <ul>
        <li>Ρουβίδιο (0)
        </li>
        <li>Ασβέστιο (0)
        </li>
        <li>Στρόντιο (0)
        </li>
        <li>Καίσιο (0)
        </li>
      </ul>
      </li>
      <li>Κάλιο, μαγνήσιο, νάτριο και λίθιο (4)
      <ul>
        <li>Κάλιο (0)
        </li>
        <li>Νάτριο (0)
        </li>
        <li>Μαγνήσιο (0)
        </li>
        <li>Λίθιο (0)
        </li>
      </ul>
      </li>
      <li>Ιρίδιο, γάλλιο, ίνδιο, θάλλιο και βάριο (5)
      <ul>
        <li>Ιρίδιο (0)
        </li>
        <li>Ίνδιο (0)
        </li>
        <li>Βάριο (0)
        </li>
        <li>Γάλλιο (0)
        </li>
        <li>Θάλλιο (0)
        </li>
      </ul>
      </li>
      <li>Μολυβδαίνιο, τεχνήτιο, ρουθήνιο και ρόδιο (4)
      <ul>
        <li>Ρόδιο (0)
        </li>
        <li>Ρουθήνιο (0)
        </li>
        <li>Μολυβδαίνιο (0)
        </li>
        <li>Τεχνήτιο (0)
        </li>
      </ul>
      </li>
      <li>Κάδμιο, λουτήτιο, άφνιο, ταντάλιο και βολφράμιο (5)
      <ul>
        <li>Βολφράμιο (0)
        </li>
        <li>Ταντάλιο (0)
        </li>
        <li>Λουτήτιο (0)
        </li>
        <li>Κάδμιο (0)
        </li>
        <li>Άφνιο (0)
        </li>
      </ul>
      </li>
      <li>Νιόβιο, όσμιο, ρήνιο και παλλάδιο (4)
      <ul>
        <li>Ρήνιο (0)
        </li>
        <li>Νιόβιο (0)
        </li>
        <li>Παλλάδιο (0)
        </li>
        <li>Όσμιο (0)
        </li>
      </ul>
      </li>
      <li>Αλουμίνιο, νικέλιο, σκάνδιο, τιτάνιο και βανάδιο (6)
      <ul>
        <li>Σκάνδιο (0)
        </li>
        <li>Τιτάνιο (0)
        </li>
        <li>Βανάδιο (0)
        </li>
        <li>Νικέλιο (0)
        </li>
        <li>Αλουμίνιο (1)
        <ul>
          <li>Οξείδιο του αργιλίου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Χρώμιο, μαγγάνιο, κοβάλτιο, ύττριο και ζιρκόνιο (5)
      <ul>
        <li>Κοβάλτιο (0)
        </li>
        <li>Χρώμιο (0)
        </li>
        <li>Μαγγάνιο (0)
        </li>
        <li>Ζιρκόνιο (0)
        </li>
        <li>Ύττριο (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Αλάτι και καθαρό χλωριούχο νάτριο (4)
    <ul>
      <li>Άλμη (0)
      </li>
      <li>Αλάτι προερχόμενο από εξάτμιση και καθαρό χλωριούχο νάτριο (0)
      </li>
      <li>Θαλασσινό αλάτι (0)
      </li>
      <li>Ορυκτό αλάτι (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Ανακτηθείσες δευτερογενείς πρώτες ύλες (3)
    <ul>
      <li>Τέφρα και υπολείμματα που περιέχουν μέταλλα (0)
      </li>
      <li>Ανακτηθείσες δευτερογενείς πρώτες ύλες μεταλλικής μορφής (0)
      </li>
      <li>Ανακτηθείσες δευτερογενείς μη μεταλλικές πρώτες ύλες (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Χημικά και ορυκτά λιπάσματα (8)
    <ul>
      <li>Ορυκτά λιπάσματα (6)
      <ul>
        <li>Φυσικό ασβέστιο, φωσφορικό αργίλιο-ασβέστιο και άλλα φυσικά ακατέργαστα άλατα καλίου (3)
        <ul>
          <li>Φυσικό ασβέστιο (0)
          </li>
          <li>Φυσικά ακατέργαστα άλατα του καλίου (0)
          </li>
          <li>Φωσφορικά άλατα αργιλίου-ασβεστίου (0)
          </li>
        </ul>
        </li>
        <li>Σιδηροπυρίτες (1)
        <ul>
          <li>Άφρυκτοι σιδηροπυρίτες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Χημικά ορυκτά (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Διάφορα μη μεταλλικά ορυκτά προϊόντα (10)
    <ul>
      <li>Γυαλί (0)
      </li>
      <li>Ίνες υάλου (0)
      </li>
      <li>Λειαντικά προϊόντα (7)
      <ul>
        <li>Λειαντικές σκόνες ή κόκκοι (0)
        </li>
        <li>Τεχνητό κορούνδιο (0)
        </li>
        <li>Μυλόπετρες, ακονόπετρες και ακονιστικοί τροχοί (3)
        <ul>
          <li>Μυλόπετρες (0)
          </li>
          <li>Ακονιστικοί τροχοί (0)
          </li>
          <li>Ακονόπετρες (0)
          </li>
        </ul>
        </li>
        <li>Τεχνητός γραφίτης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Άμμος και άργιλος (24)
    <ul>
      <li>Αμμοχάλικο, άμμος, λιθοτρίμματα και αδρανή υλικά (20)
      <ul>
        <li>Άμμος (1)
        <ul>
          <li>Φυσική άμμος (0)
          </li>
        </ul>
        </li>
        <li>Σκυρόστρωμα, πισσοσκυρόστρωμα και αμμώδης ασφαλτική επίστρωση (3)
        <ul>
          <li>Σκυρόστρωμα (0)
          </li>
          <li>Άμμος εμποτισμένη με πίσσα (0)
          </li>
          <li>Πισσοσκυρόστρωμα (0)
          </li>
        </ul>
        </li>
        <li>Άμμος, λιθόκοκκοι, χαλίκι, λιθοτρίμματα και λιθόσκονη, κροκάλες, αμμοχάλικο, τεμαχισμένοι ή θρυμματισμένοι λίθοι, μείγματα αμμοχάλικου και άλλα αδρανή υλικά (13)
        <ul>
          <li>Χώμα (3)
          <ul>
            <li>Υπέδαφος (0)
            </li>
            <li>Χώμα επιφανείας (0)
            </li>
            <li>Θρυμματισμένοι λίθοι (0)
            </li>
          </ul>
          </li>
          <li>Αδρανή υλικά (1)
          <ul>
            <li>Μείγμα αμμοχάλικου (0)
            </li>
          </ul>
          </li>
          <li>Κροκάλες και αμμοχάλικο (2)
          <ul>
            <li>Κροκάλες (0)
            </li>
            <li>Αμμοχάλικο (0)
            </li>
          </ul>
          </li>
          <li>Τεμαχισμένοι και θρυμματισμένοι λίθοι (3)
          <ul>
            <li>Θρυμματισμένος γρανίτης (0)
            </li>
            <li>Θρυμματισμένος βασάλτης (0)
            </li>
            <li>Έρμα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Άργιλος και καολίνη (2)
      <ul>
        <li>Άργιλος (0)
        </li>
        <li>Καολίνη (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μεταλλεύματα και μέταλλα (22)
    <ul>
      <li>Κράματα (6)
      <ul>
        <li>Χάλυβας (0)
        </li>
        <li>Κράματα σιδήρου (4)
        <ul>
          <li>Κράματα σιδήρου εκτός των κραμάτων της ΕΚΑΧ (3)
          <ul>
            <li>Σιδηροχρώμιο (0)
            </li>
            <li>Σιδηρομαγγάνιο (0)
            </li>
            <li>Σιδηρονικέλιο (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μεταλλεύματα (13)
      <ul>
        <li>Διάφορα μεταλλεύματα (0)
        </li>
        <li>Μεταλλεύματα ουρανίου και θορίου (2)
        <ul>
          <li>Μεταλλεύματα θορίου (0)
          </li>
          <li>Μεταλλεύματα ουρανίου (0)
          </li>
        </ul>
        </li>
        <li>Μη σιδηρούχα μεταλλεύματα (7)
        <ul>
          <li>Μεταλλεύματα ψευδαργύρου (0)
          </li>
          <li>Μεταλλεύματα λευκοσιδήρου (0)
          </li>
          <li>Μεταλλεύματα αλουμινίου (0)
          </li>
          <li>Πολύτιμα μέταλλα (0)
          </li>
          <li>Μεταλλεύματα μολύβδου (0)
          </li>
          <li>Μεταλλεύματα νικελίου (0)
          </li>
          <li>Μεταλλεύματα χαλκού (0)
          </li>
        </ul>
        </li>
        <li>Σιδηρομεταλλεύματα (0)
        </li>
      </ul>
      </li>
      <li>Σκωρίες, σκωρίες υψικαμίνου, σιδηρούχα απορρίμματα και υπολείμματα (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Μηχανήματα βιομηχανικών εγκαταστάσεων (551)
  <ul>
    <li className='top-level'>Μηχανήματα για την παραγωγή και χρήση της μηχανικής ενέργειας (163)
    <ul>
      <li>Τουρμπίνες και κινητήρες (27)
      <ul>
        <li>Μέρη στροβίλων (17)
        <ul>
          <li>Μέρη στροβίλων υδρατμού (10)
          <ul>
            <li>Πλάκες βάσης (0)
            </li>
            <li>Συστήματα ψύξης αέρα με συμπυκνωτή (0)
            </li>
            <li>Διαχωριστήρες υγρασίας (1)
            <ul>
              <li>Αφυγραντήρες (0)
              </li>
            </ul>
            </li>
            <li>Συστήματα λιπαντικού ελαίου (0)
            </li>
            <li>Περιστρεφόμενος εξοπλισμός (2)
            <ul>
              <li>Πτερύγια (0)
              </li>
              <li>Στροφεία (0)
              </li>
            </ul>
            </li>
            <li>Περιστρεφόμενα μέρη (0)
            </li>
            <li>Πλαίσια επένδυσης (0)
            </li>
          </ul>
          </li>
          <li>Μέρη αεριοστροβίλων (3)
          <ul>
            <li>Συστήματα προσαγωγής αέρα (0)
            </li>
            <li>Στοιχείο ανάφλεξης αερίου (0)
            </li>
            <li>Συστήματα καυσίμων σε αέρια μορφή (0)
            </li>
          </ul>
          </li>
          <li>Μέρη υδραυλικών στροβίλων (0)
          </li>
          <li>Μέρη υδροτροχών (0)
          </li>
        </ul>
        </li>
        <li>Κινητήρες παραγωγής (μηχανική ενέργεια) (1)
        <ul>
          <li>Εξωλέμβιες μηχανές (0)
          </li>
        </ul>
        </li>
        <li>Εγκαταστάσεις τουρμπίνων (6)
        <ul>
          <li>Τουρμπίνες ατμού (0)
          </li>
          <li>Εξοπλισμός τουρμπινών (1)
          <ul>
            <li>Όργανα τουρμπίνων (0)
            </li>
          </ul>
          </li>
          <li>Υδραυλικές τουρμπίνες (1)
          <ul>
            <li>Υδραυλικοί τροχοί (0)
            </li>
          </ul>
          </li>
          <li>Τουρμπίνες αερίου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Οδοντωτοί τροχοί, συστήματα οδοντωτών τροχών και μηχανισμοί μετάδοσης της κίνησης (16)
      <ul>
        <li>Απλοί οδοντωτοί τροχοί, συστήματα οδοντωτών τροχών και μηχανισμοί μετάδοσης της κίνησης (12)
        <ul>
          <li>Οδοντωτοί τροχοί και συστήματα οδοντωτών τροχών (0)
          </li>
          <li>Πολύσπαστο (0)
          </li>
          <li>Σφόνδυλοι και τροχαλίες (1)
          <ul>
            <li>Βαρούλκα (0)
            </li>
          </ul>
          </li>
          <li>Έδρανα τριβέων (0)
          </li>
          <li>Συνδέσεις γενικής χρήσης (0)
          </li>
          <li>Ζεύξεις αξόνων (0)
          </li>
          <li>Άξονες μετάδοσης κίνησης, εκκεντροφόροι και στροφαλοφόροι άξονες (3)
          <ul>
            <li>Στροφαλοφόροι άξονες (0)
            </li>
            <li>Εκκεντροφόροι άξονες (0)
            </li>
            <li>Άξονες μετάδοσης της κίνησης (0)
            </li>
          </ul>
          </li>
          <li>Συμπλέκτες (0)
          </li>
        </ul>
        </li>
        <li>Μέρη οδοντωτών τροχών και στοιχείων μετάδοσης της κίνησης (2)
        <ul>
          <li>Μέρη στοιχείων μετάδοσης της κίνησης (0)
          </li>
          <li>Μέρη στοιχείων οδοντωτών τροχών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Στρόφιγγες, κρουνοί, δικλείδες και παρόμοια είδη (41)
      <ul>
        <li>Μέρη στροφίγγων και δικλείδων (6)
        <ul>
          <li>Μέρη στροφίγγων (0)
          </li>
          <li>Μέρη δικλείδων (0)
          </li>
          <li>Συστήματα ενεργοποίησης δικλείδων (3)
          <ul>
            <li>Ηλεκτρικά συστήματα ενεργοποίησης δικλείδων (0)
            </li>
            <li>Υδραυλικά συστήματα ενεργοποίησης δικλείδων (0)
            </li>
            <li>Πνευματικά συστήματα ενεργοποίησης δικλείδων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Στρόφιγγες, κρουνοί και δικλείδες (33)
        <ul>
          <li>Στρόφιγγες, κρουνοί ή δικλείδες ειδών υγιεινής (0)
          </li>
          <li>Βαλβίδες προσδιοριζόμενες από την κατασκευή τους (11)
          <ul>
            <li>Δικλείδες με σφαίρα (0)
            </li>
            <li>Δικλείδες βελόνης (0)
            </li>
            <li>Ρουφράκτες (θυροφράγματα) (0)
            </li>
            <li>Στραγγαλισικές δικλείδες (0)
            </li>
            <li>Δικλείδες πτώσεως (0)
            </li>
            <li>Δικλείδες διαφράγματος (2)
            <ul>
              <li>Ποδοβαλβίδες (0)
              </li>
              <li>Συρταρωτές δικλείδες (0)
              </li>
            </ul>
            </li>
            <li>Βαλβίδες λεπίδας (0)
            </li>
            <li>Σφαιρικές δικλείδες (0)
            </li>
            <li>Δικλείδες με πώμα (0)
            </li>
          </ul>
          </li>
          <li>Βαλβίδες συγκεκριμένης λειτουργίας (15)
          <ul>
            <li>Δίκτυο κρουνών υδροληψίας (0)
            </li>
            <li>Βαλβίδες μείωσης της πίεσης, βαλβίδες ελέγχου, ρυθμιστικές και ασφαλιστικές βαλβίδες (8)
            <ul>
              <li>Βαλβίδες μείωσης πίεσης (0)
              </li>
              <li>Βαλβίδες ελέγχου λειτουργιών (0)
              </li>
              <li>Μη επιστροφικές βαλβίδες (0)
              </li>
              <li>Βαλβίδες φραγμού (0)
              </li>
              <li>Ρυθμιστικές βαλβίδες (0)
              </li>
              <li>Βαλβίδες ελέγχου (0)
              </li>
              <li>Δικλείδες ασφαλείας (0)
              </li>
              <li>Βαλβίδες ελέγχου ροής (0)
              </li>
            </ul>
            </li>
            <li>Βαλβίδες θυρίδος εκροής (0)
            </li>
            <li>Δικλείδες εξαγωγής κυλινδρικών δοχείων αερίων (0)
            </li>
            <li>Βαλβίδες σωμάτων κεντρικής θέρμανσης (0)
            </li>
            <li>Δικλείδες κατακόρυφων σωλήνων (0)
            </li>
            <li>Ρυθμιστές θερμοκρασίας (0)
            </li>
          </ul>
          </li>
          <li>Κωνικές δικλείδες και άλλα συστήματα δικλείδων (3)
          <ul>
            <li>Κεφαλές φρέατος πετρελαιοπηγών (0)
            </li>
            <li>Συγκροτήματα βαλβίδων (0)
            </li>
            <li>Πολλαπλές στραγγαλισμού ροής (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Πυρηνικοί αντιδραστήρες και μέρη τους (4)
      <ul>
        <li>Πυρηνικοί αντιδραστήρες (0)
        </li>
        <li>Μέρη πυρηνικών αντιδραστήρων (2)
        <ul>
          <li>Συστήματα ψύξης αντιδραστήρων (0)
          </li>
          <li>Μέρη δεξαμενών πυρηνικών αντιδραστήρων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Λεβητοστάσια (5)
      <ul>
        <li>Βοηθητικός εξοπλισμός για λέβητες (0)
        </li>
        <li>Συμπυκνωτές ατμού (0)
        </li>
        <li>Ατμογεννήτριες (0)
        </li>
        <li>Λέβητες παραγωγής ατμού (0)
        </li>
        <li>Λέβητες ζεστού νερού (0)
        </li>
      </ul>
      </li>
      <li>Αντλίες και συμπιεστές (64)
      <ul>
        <li>Συμπιεστές (10)
        <ul>
          <li>Φυγοκεντρικοί συμπιεστές (0)
          </li>
          <li>Συμπιεστές για εξοπλισμό ψύξης (0)
          </li>
          <li>Στροβιλοσυμπιεστές (0)
          </li>
          <li>Αεροσυμπιεστές (1)
          <ul>
            <li>Συναρμολογημένοι αεροσυμπιεστές (0)
            </li>
          </ul>
          </li>
          <li>Παλινδρομικοί συμπιεστές μετατόπισης (1)
          <ul>
            <li>Συγκρότημα πεπιεσμένου αέρα (0)
            </li>
          </ul>
          </li>
          <li>Αεριοσυμπιεστές (0)
          </li>
          <li>Συμπιεστές για χρήση σε αεροσκάφη της πολιτικής αεροπορίας (0)
          </li>
          <li>Περιστροφικοί συμπιεστές (0)
          </li>
        </ul>
        </li>
        <li>Μέρη αντλιών, συμπιεστών, μηχανών ή κινητήρων (19)
        <ul>
          <li>Μέρη μηχανών ή κινητήρων (3)
          <ul>
            <li>Μέρη κινητήρων αντίδρασης (0)
            </li>
            <li>Μέρη πνευματικών μηχανών (0)
            </li>
            <li>Μέρη υδραυλικών κινητήρων ή μηχανών (0)
            </li>
          </ul>
          </li>
          <li>Μέρη αντλιών ή διατάξεων ανύψωσης υγρών (9)
          <ul>
            <li>Μέρη φυγοκεντρικών αντλιών (0)
            </li>
            <li>Μέρη αντλιών καυσίμου, χειροκίνητων αντλιών και αντλιών σκυροδέματος (3)
            <ul>
              <li>Μέρη αντλιών σκυροδέματος (0)
              </li>
              <li>Μέρη αντλιών καυσίμου (0)
              </li>
              <li>Μέρη χειροκίνητων αντλιών (0)
              </li>
            </ul>
            </li>
            <li>Μέρη παλινδρομικών αντλιών θετικής μετατόπισης (2)
            <ul>
              <li>Μέρη δοσιμετρικών αντλιών (0)
              </li>
              <li>Μέρη υδραυλικών συγκροτημάτων παραγωγής ενέργειας (0)
              </li>
            </ul>
            </li>
            <li>Μέρη περιστροφικών αντλιών θετικής μετατόπισης (0)
            </li>
          </ul>
          </li>
          <li>Μέρη αεραντλιών ή αντλιών κενού, αεροσυμπιεστών ή αεριοσυμπιεστών (4)
          <ul>
            <li>Μέρη αντλιών κενού (0)
            </li>
            <li>Μέρη αεραντλιών (0)
            </li>
            <li>Μέρη αεριοσυμπιεστών (0)
            </li>
            <li>Μέρη αεροσυμπιεστών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Αντλίες (26)
        <ul>
          <li>Παλινδρομικές αντλίες θετικής μετατόπισης για υγρά (3)
          <ul>
            <li>Αντλίες δοσιμέτρησης (0)
            </li>
            <li>Υδραυλικά συγκροτήματα παραγωγής ενέργειας (0)
            </li>
            <li>Αντλίες αποχέτευσης (0)
            </li>
          </ul>
          </li>
          <li>Συσκευές αύξησης της πίεσης υγρών (0)
          </li>
          <li>Αντλίες υγρών (8)
          <ul>
            <li>Αντλίες ψύξης (1)
            <ul>
              <li>Αντλίες νερού ψύξης (0)
              </li>
            </ul>
            </li>
            <li>Αντλίες καυσίμου (0)
            </li>
            <li>Αντλίες σκυροδέματος (0)
            </li>
            <li>Αντλίες λίπανσης (0)
            </li>
            <li>Αντλίες πυρόσβεσης (0)
            </li>
            <li>Αντλίες νερού (0)
            </li>
            <li>Συγκρότημα ανεφοδιασμού καυσίμων ελικοπτέρων (0)
            </li>
          </ul>
          </li>
          <li>Φυγοκεντρικές αντλίες και διατάξεις ανύψωσης υγρών (9)
          <ul>
            <li>Ανυψωτικές διατάξεις υγρών (0)
            </li>
            <li>Αεραντλίες (0)
            </li>
            <li>Περιστροφικές αντλίες (0)
            </li>
            <li>Αντλίες για ιατρική χρήση (2)
            <ul>
              <li>Αντλίες παροχής τροφής (0)
              </li>
              <li>Αντλίες έκχυσης υγρού (0)
              </li>
            </ul>
            </li>
            <li>Αντλίες κενού (0)
            </li>
            <li>Αντλίες με πτερωτό στροφείο (0)
            </li>
            <li>Φυγοκεντρικές αντλίες (0)
            </li>
          </ul>
          </li>
          <li>Αντλίες και εξαρτήματα εργαστηρίου (1)
          <ul>
            <li>Περισταλτικές αντλίες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υδραυλικοί ή πνευματικοί κινητήρες και μηχανές παραγωγής ενέργειας (5)
        <ul>
          <li>Πνευματικές μηχανές (οχήματα) (0)
          </li>
          <li>Πνευματικοί κινητήρες (μηχανική ενέργεια) (0)
          </li>
          <li>Υδραυλικές μηχανές (οχήματα) (0)
          </li>
          <li>Υδραυλικοί κινητήρες (μηχανική ενέργεια) (0)
          </li>
          <li>Υδραυλικοί ή πνευματικοί κύλινδροι (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Βιομηχανικοί ή εργαστηριακοί κλίβανοι, καυστήρες και φούρνοι (7)
    <ul>
      <li>Αποτεφρωτές (0)
      </li>
      <li>Κλίβανοι αποτέφρωσης απορριμμάτων (0)
      </li>
      <li>Καυστήρες κλιβάνων (0)
      </li>
      <li>Μέρη καυστήρων κλιβάνων, κλιβάνων και φούρνων (0)
      </li>
      <li>Κλίβανοι τήξης (0)
      </li>
      <li>Μη οικιακοί φούρνοι (1)
      <ul>
        <li>Επαγγελματικοί φούρνοι (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εξοπλισμός ψύξης και αερισμού (33)
    <ul>
      <li>Μέρη εξοπλισμού ψύξης και κατάψυξης και αντλιών θερμότητας (3)
      <ul>
        <li>Μέρη εξοπλισμού κατάψυξης (0)
        </li>
        <li>Μέρη ψυκτικού εξοπλισμού (0)
        </li>
        <li>Μέρη αντλιών θερμότητας (0)
        </li>
      </ul>
      </li>
      <li>Μονάδες εναλλαγής θερμότητας, κλιματιστικός και ψυκτικός εξοπλισμός, μηχανήματα διήθησης (24)
      <ul>
        <li>Λέβητες κεντρικής αστικής θέρμανσης (0)
        </li>
        <li>Κλιματιστικές εγκαταστάσεις (7)
        <ul>
          <li>Κλιματιστικά μηχανήματα προσαρμοζόμενα επί παραθύρου (0)
          </li>
          <li>Συγκροτήματα θέρμανσης, αερισμού και κλιματισμού (0)
          </li>
          <li>Μέρη κλιματιστικών μηχανημάτων (2)
          <ul>
            <li>Στόμιο αερισμού με περσίδες (0)
            </li>
            <li>Υγραντήρες (0)
            </li>
          </ul>
          </li>
          <li>Κλιματιστικά μηχανήματα προσαρμοζόμενα επί τοίχου (0)
          </li>
          <li>Κλιματιστικές εγκαταστάσεις οχημάτων (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα και συσκευές διήθησης ή καθαρισμού αερίων (4)
        <ul>
          <li>Ηλεκτροστατικές συσκευές καθαρισμού αέρα και αερίων (0)
          </li>
          <li>Συσκευές διήθησης (2)
          <ul>
            <li>Φίλτρα αερίων (0)
            </li>
            <li>Φίλτρα αέρα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Μονάδες εναλλαγής θερμότητας και μηχανήματα υγροποίησης αέρα ή άλλων αερίων (3)
        <ul>
          <li>Μηχανές υγροποίησης αέρα ή άλλων αερίων (0)
          </li>
          <li>Μονάδες εναλλαγής θερμότητας (1)
          <ul>
            <li>Αντλίες θερμότητας (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ψύξης και κατάψυξης (5)
        <ul>
          <li>Εξοπλισμός κατάψυξης (0)
          </li>
          <li>Εξοπλισμός ψύξης (3)
          <ul>
            <li>Ανοιχτά ψυγεία πώλησης τροφίμων (0)
            </li>
            <li>Επαγγελματικός ψυκτικός εξοπλισμός (0)
            </li>
            <li>Βιτρίνες-ψυγεία για την έκθεση ειδών διατροφής (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός αερισμού (3)
      <ul>
        <li>Μη οικιακοί ανεμιστήρες (1)
        <ul>
          <li>Μέρη ανεμιστήρων (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός απαγωγής καπναερίων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μηχανήματα παραγωγής κλωστοϋφαντουργικών προϊόντων, ειδών ένδυσης και δερμάτινων ειδών (18)
    <ul>
      <li>Μέρη μηχανημάτων παραγωγής κλωστοϋφαντουργικών προϊόντων και ειδών ένδυσης (0)
      </li>
      <li>Μηχανήματα παραγωγής κλωστοϋφαντουργικών προϊόντων (16)
      <ul>
        <li>Μηχανές επεξεργασίας τεχνητών κλωστοϋφαντουργικών υλικών (0)
        </li>
        <li>Εξοπλισμός σιδερώματος και διπλώματος ρούχων (1)
        <ul>
          <li>Εξοπλισμός διπλώματος ρούχων (0)
          </li>
        </ul>
        </li>
        <li>Πλυντήρια ρούχων, μηχανές στεγνού καθαρισμού και στεγνωτήρια (5)
        <ul>
          <li>Εγκατάσταση πλυντηρίου ρούχων (3)
          <ul>
            <li>Εξοπλισμός πλυσίματος (0)
            </li>
            <li>Μηχανές στεγνού καθαρισμού (0)
            </li>
            <li>Πλυντήρια ρούχων (0)
            </li>
          </ul>
          </li>
          <li>Στεγνωτήρια (0)
          </li>
        </ul>
        </li>
        <li>Υφαντικές μηχανές (0)
        </li>
        <li>Πλεκτομηχανές (0)
        </li>
        <li>Ραπτομηχανές (0)
        </li>
        <li>Μηχανές κλώσης κλωστοϋφαντουργικών προϊόντων (0)
        </li>
        <li>Μηχανήματα για το φινίρισμα κλωστοϋφαντουργικών προϊόντων (2)
        <ul>
          <li>Πρέσες σιδερώματος (0)
          </li>
          <li>Μηχανήματα σιδερώματος (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εργαλειομηχανές (60)
    <ul>
      <li>Εργαλειομηχανές που λειτουργούν με λέιζερ και μηχανουργεία (4)
      <ul>
        <li>Μηχανουργεία (2)
        <ul>
          <li>Μηχανουργεία κατεργασίας κατακόρυφης ατράκτου (0)
          </li>
          <li>Μηχανουργεία κατεργασίας οριζόντιας ατράκτου (0)
          </li>
        </ul>
        </li>
        <li>Εργαλειομηχανές ειδικής χρήσης (0)
        </li>
      </ul>
      </li>
      <li>Εργαλειομηχανές για κατεργασία μετάλλων (12)
      <ul>
        <li>Μηχανές λείανσης σφυρηλατημένων μετάλλων (0)
        </li>
        <li>Εργαλειομηχανές για φινίρισμα μετάλλων (0)
        </li>
        <li>Μηχανουργεία κατεργασίας μετάλλων (0)
        </li>
        <li>Πρέσες (1)
        <ul>
          <li>Υδραυλικές πρέσες (0)
          </li>
        </ul>
        </li>
        <li>Εργαλειομηχανές για τη διάτρηση, τη διάνοιξη οπών ή το φρεζάρισμα μετάλλων (3)
        <ul>
          <li>Εργαλειομηχανές για τη διάτρηση μετάλλων (0)
          </li>
          <li>Εργαλειομηχανές για το φρεζάρισμα μετάλλων (0)
          </li>
          <li>Εργαλειομηχανές για τη διάνοιξη οπών σε μέταλλα (0)
          </li>
        </ul>
        </li>
        <li>Εργαλειομηχανές ψηφιακού ελέγχου (0)
        </li>
        <li>Μηχανές σφυρηλασίας (0)
        </li>
        <li>Μηχανές για κάμψη, δίπλωση, ίσιωμα ή επιπεδοποίηση (0)
        </li>
      </ul>
      </li>
      <li>Εργαλειομηχανές για την κατεργασία σκληρών υλικών εκτός από μέταλλα (11)
      <ul>
        <li>Εργαλειομηχανές για την κατεργασία ξύλου, φελλού, οστών, σκληρού καουτσούκ, ή σκληρού πλαστικού (5)
        <ul>
          <li>Εργαλειομηχανές για την κατεργασία σκληρού καουτσούκ (0)
          </li>
          <li>Εργαλειομηχανές για την κατεργασία φελλού (0)
          </li>
          <li>Εργαλειομηχανές για την κατεργασία οστού (0)
          </li>
          <li>Εργαλειομηχανές για την κατεργασία ξύλου (0)
          </li>
          <li>Εργαλειομηχανές για την κατεργασία σκληρού πλαστικού (0)
          </li>
        </ul>
        </li>
        <li>Εργαλειομηχανές για την κατεργασία λίθων, κεραμικών, σκυροδέματος ή υάλου (4)
        <ul>
          <li>Εργαλειομηχανές για την κατεργασία υάλου (0)
          </li>
          <li>Εργαλειομηχανές για την κατεργασία λίθων (0)
          </li>
          <li>Εργαλειομηχανές για την κατεργασία κεραμικών (0)
          </li>
          <li>Εργαλειομηχανές για την κατεργασία σκυροδέματος (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εργαλεία για μαλακή συγκόλληση, σκληρή συγκόλληση και θερμοσυγκόλληση, μηχανές και συσκευές για επιφανειακή βαφή και ψεκασμό εν θερμώ (10)
      <ul>
        <li>Μηχανήματα ψεκασμού μετάλλων (0)
        </li>
        <li>Εξοπλισμός για μαλακή και σκληρή συγκόλληση (2)
        <ul>
          <li>Εξοπλισμός για σκληρή συγκόλληση (0)
          </li>
          <li>Εξοπλισμός για μαλακή συγκόλληση (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα σκλήρυνσης επιφανειών (0)
        </li>
        <li>Εξοπλισμός τήξης (1)
        <ul>
          <li>Εξοπλισμός θερμοπλαστικής συγκόλλησης (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός συγκόλλησης (2)
        <ul>
          <li>Εξοπλισμός ηλεκτροσυγκόλλησης (0)
          </li>
          <li>Εξοπλισμός μη ηλεκτρικής συγκόλλησης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Τόρνοι, τρυπάνια και φρέζες (4)
      <ul>
        <li>Μηχανές διαμόρφωσης σπειρωμάτων και ελίκων σε μέταλλα (0)
        </li>
        <li>Τόρνοι (1)
        <ul>
          <li>Ηλεκτρονικοί τόρνοι (0)
          </li>
        </ul>
        </li>
        <li>Φρέζες (0)
        </li>
      </ul>
      </li>
      <li>Πνευματικά ή μηχανοκίνητα εργαλεία χειρός (2)
      <ul>
        <li>Πνευματικά εργαλεία χειρός (0)
        </li>
        <li>Ηλεκτρομηχανικά εργαλεία χειρός (0)
        </li>
      </ul>
      </li>
      <li>Μέρη και εξαρτήματα εργαλειομηχανών (10)
      <ul>
        <li>Μέρη εργαλείων χειρός (0)
        </li>
        <li>Διατάξεις συγκράτησης των προς επεξεργασία τεμαχίων για εργαλειομηχανές (0)
        </li>
        <li>Μέρη πνευματικών εργαλείων (0)
        </li>
        <li>Μέρη και εξαρτήματα για εργαλειομηχανές κατεργασίας μετάλλου (0)
        </li>
        <li>Ειδικά εξαρτήματα εργαλειομηχανών τεμαχισμού (0)
        </li>
        <li>Μέρη και εξαρτήματα για εργαλειομηχανές κατεργασίας σκληρών υλικών (1)
        <ul>
          <li>Μέρη αλυσοπριόνων (0)
          </li>
        </ul>
        </li>
        <li>Εργαλειοφορεία (2)
        <ul>
          <li>Εργαλειοφορεία εργαστηρίου (1)
          <ul>
            <li>Σχάρες δοκιμαστικών σωλήνων για υδατόλουτρα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μέρη και εξοπλισμός ανύψωσης και διακίνησης υλικών (82)
    <ul>
      <li>Κάδοι, πτύα, αρπάγες και λαβές για γερανούς ή εκσκαφείς (0)
      </li>
      <li>Εξοπλισμός ανύψωσης και διακίνησης (80)
      <ul>
        <li>Ανελκυστήρες, καδοφόροι ανυψωτήρες, ανυψωτικά μηχανήματα, κυλιόμενες σκάλες και κυλιόμενοι τάπητες (9)
        <ul>
          <li>Ανυψωτήρες (0)
          </li>
          <li>Κυλιόμενα δάπεδα (0)
          </li>
          <li>Καδοφόροι ανυψωτήρες (1)
          <ul>
            <li>Ανυψωτικά μηχανήματα κάδων (0)
            </li>
          </ul>
          </li>
          <li>Κυλιόμενες σκάλες (0)
          </li>
          <li>Ανελκυστήρες (3)
          <ul>
            <li>Μηχανικοί ανελκυστήρες (0)
            </li>
            <li>Ανυψωτικοί μηχανισμοί μπάνιου (0)
            </li>
            <li>Αναβατόρια φορτίων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Πολύσπαστα και ανυψωτικά βαρούλκα (0)
        </li>
        <li>Περονοφόρα ανυψωτικά οχήματα, οχήματα κατασκευαστικών έργων, ελκυστήρες για αποβάθρες σιδηροδρομικών σταθμών (7)
        <ul>
          <li>Ανυψωτικά οχήματα (1)
          <ul>
            <li>Περονοφόρα ανυψωτικά οχήματα (0)
            </li>
          </ul>
          </li>
          <li>Ελκυστήρες για αποβάθρες σιδηροδρομικών σταθμών (2)
          <ul>
            <li>Εξοπλισμός οχημάτων έκτακτης ανάγκης (0)
            </li>
            <li>Οχήματα ελεύθερης οδήγησης (0)
            </li>
          </ul>
          </li>
          <li>Οχήματα κατασκευαστικών έργων (1)
          <ul>
            <li>Οχήματα κατασκευαστικών έργων με εξοπλισμό χειρισμού (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Γερανοί, κινητά ανυψωτικά πλαίσια και γερανοφόρα οχήματα κατασκευών (15)
        <ul>
          <li>Γερανοί (5)
          <ul>
            <li>Πυργωτοί γερανοί (0)
            </li>
            <li>Γερανοί εμπορευματοκιβωτίων (0)
            </li>
            <li>Γερανοί για αποβάθρες (0)
            </li>
            <li>Γερανοί λιμένων (0)
            </li>
            <li>Γερανοί στοιβασίας (0)
            </li>
          </ul>
          </li>
          <li>Υπερκείμενοι κυλιόμενοι γερανοί (2)
          <ul>
            <li>Κυλιόμενοι γερανοί (0)
            </li>
            <li>Γερανογέφυρα (0)
            </li>
          </ul>
          </li>
          <li>Φορητοί γερανοί με βραχίονα (2)
          <ul>
            <li>Γερανοί με βραχίονα σε βάθρο (0)
            </li>
            <li>Γερανογέφυρα επί ικριώματος (0)
            </li>
          </ul>
          </li>
          <li>Γερανοί προσαρμοσμένοι επί οχημάτων (1)
          <ul>
            <li>Γερανοί για φορτηγά (0)
            </li>
          </ul>
          </li>
          <li>Γερανογέφυρες (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα ανύψωσης, διακίνησης, φόρτωσης ή εκφόρτωσης (13)
        <ul>
          <li>Μηχανήματα φόρτωσης ή διακίνησης (4)
          <ul>
            <li>Εξοπλισμός φόρτωσης (0)
            </li>
            <li>Εξοπλισμός διακίνησης εμπορευματοκιβωτίων (0)
            </li>
            <li>Πλευρικοί φορτωτές (0)
            </li>
            <li>Εξοπλισμός εκφόρτωσης (0)
            </li>
          </ul>
          </li>
          <li>Προωθητικές διατάξεις βαγονιών ορυχείων, συστήματα προώθησης μηχανών έλξης ή βαγονιών (0)
          </li>
          <li>Περιστρεφόμενοι μηχανισμοί αποθήκευσης και ανάκτησης υλικού (0)
          </li>
          <li>Μηχανολογικός εξοπλισμός χειρισμού (0)
          </li>
          <li>Εξοπλισμός μονοτρόχιου σιδηροδρόμου ή ανελκυστήρων χιονοδρόμων (3)
          <ul>
            <li>Εξοπλισμός εναέριου σιδηροδρόμου απλής τροχιάς (0)
            </li>
            <li>Τελεσιέζ (0)
            </li>
            <li>Ανελκυστήρες χιονοδρόμων (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός εκσκαφής με ειδικές αιχμές (0)
          </li>
        </ul>
        </li>
        <li>Ανυψωτήρες και μεταφορικά συστήματα (7)
        <ul>
          <li>Μεταφορικά συστήματα (3)
          <ul>
            <li>Θωρακισμένοι μεταφορείς για την εκμετάλλευση ορυχείων (0)
            </li>
            <li>Ανυψωτήρες και ταινίες μεταφοράς, συνεχούς λειτουργίας, τύπου ιμάντα (0)
            </li>
            <li>Ανυψωτήρες και ταινίες μεταφοράς, συνεχούς λειτουργίας, τύπου κάδου (0)
            </li>
          </ul>
          </li>
          <li>Πνευματικοί ανυψωτήρες ή μεταφορικά συστήματα (0)
          </li>
          <li>Μεταφορικός εξοπλισμός (1)
          <ul>
            <li>Μεταφορικές ταινίες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Μέρη εξοπλισμού ανύψωσης και διακίνησης υλικών (11)
        <ul>
          <li>Μέρη γερανών (0)
          </li>
          <li>Μέρη ανελκυστήρων, καδοφόρων ανυψωτήρων ή κυλιόμενων κλιμάκων (4)
          <ul>
            <li>Μέρη ανελκυστήρων (0)
            </li>
            <li>Μέρη καδοφόρων ανυψωτήρων (0)
            </li>
            <li>Μέρη κυλιόμενων διαδρόμων (0)
            </li>
            <li>Μέρη κυλιόμενων κλιμάκων (0)
            </li>
          </ul>
          </li>
          <li>Μέρη οχημάτων κατασκευαστικών έργων (0)
          </li>
          <li>Μέρη μεταφορικών ταινιών (2)
          <ul>
            <li>Μέρη μεταφορικών ταινιών με ιμάντα (0)
            </li>
            <li>Μέρη μεταφορικών ταινιών με κάδους (0)
            </li>
          </ul>
          </li>
          <li>Μέρη μηχανημάτων ανέλκυσης και άλλου εξοπλισμού ανύψωσης ή διακίνησης (0)
          </li>
        </ul>
        </li>
        <li>Γρύλοι και ανυψωτικά βαρούλκα οχημάτων (5)
        <ul>
          <li>Υδραυλικοί γρύλοι (0)
          </li>
          <li>Ανυψωτικά συστήματα οχημάτων (0)
          </li>
          <li>Πνευματικοί γρύλοι (0)
          </li>
          <li>Μηχανικοί γρύλοι (0)
          </li>
          <li>Ενσωματωμένα ανυψωτικά μηχανήματα (0)
          </li>
        </ul>
        </li>
        <li>Μηχανές ανέλκυσης στο στόμιο του φρέατος, βαρούλκα για υπόγεια χρήση και εργάτες (4)
        <ul>
          <li>Βαρούλκα-εργάτες (0)
          </li>
          <li>Μηχανές ανέλκυσης στο στόμιο του φρέατος και βαρούλκα για υπόγεια χρήση (2)
          <ul>
            <li>Μηχανές ανέλκυσης στο στόμιο του φρέατος (0)
            </li>
            <li>Βαρούλκα για υπόγεια χρήση (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μηχανήματα επεξεργασίας τροφίμων, ποτών και καπνού και συναφή μέρη αυτών (22)
    <ul>
      <li>Μέρη μηχανημάτων για την επεξεργασία τροφίμων, ποτών και καπνού (5)
      <ul>
        <li>Μέρη μηχανημάτων για την επεξεργασία τροφίμων (2)
        <ul>
          <li>Μέρη μηχανημάτων επεξεργασίας γαλακτοκομικών (1)
          <ul>
            <li>Μέρη αμελκτικών μηχανών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Μέρη μηχανημάτων επεξεργασίας ποτών (0)
        </li>
        <li>Μέρη μηχανημάτων κατεργασίας καπνού (0)
        </li>
      </ul>
      </li>
      <li>Μηχανήματα για την επεξεργασία τροφίμων, ποτών και καπνού (15)
      <ul>
        <li>Μηχανήματα επεξεργασίας δημητριακών ή αποξηραμένων λαχανικών (0)
        </li>
        <li>Μηχανήματα που χρησιμοποιούνται στην παρασκευή οινοπνευματωδών ποτών ή φρουτοχυμών (0)
        </li>
        <li>Φούρνοι μαγειρέματος, ξηραντήρες γεωργικών προϊόντων και εξοπλισμός για μαγείρεμα ή ζέσταμα (3)
        <ul>
          <li>Φούρνοι μαγειρέματος (1)
          <ul>
            <li>Εσχάρες μαγειρέματος (0)
            </li>
          </ul>
          </li>
          <li>Ξηραντήρες γεωργικών προϊόντων (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα κατεργασίας καπνού (0)
        </li>
        <li>Μηχανήματα επεξεργασίας γαλακτοκομικών (1)
        <ul>
          <li>Φυγοκεντρικές μηχανές διαχωρισμού της κρέμας (αποκορύφωσης) (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα για τη βιομηχανική προετοιμασία ή παρασκευή τροφίμων ή ποτών (5)
        <ul>
          <li>Μηχανήματα επεξεργασίας τροφίμων (0)
          </li>
          <li>Μηχανήματα τεμαχισμού τροφίμων (2)
          <ul>
            <li>Μηχανήματα κοπής ψωμιού σε φέτες (0)
            </li>
            <li>Μηχανήματα κοπής μπέικον σε φέτες (0)
            </li>
          </ul>
          </li>
          <li>Μηχανήματα παραγωγής ζυμαρικών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Μηχανήματα παραγωγής χαρτιού και χαρτονιού (1)
    <ul>
      <li>Μέρη μηχανημάτων παραγωγής χαρτιού και χαρτονιού (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Διάφορα μηχανήματα γενικής και ειδικής χρήσης (156)
    <ul>
      <li>Μηχανήματα φυγοκέντρισης, στίλβωσης και αυτόματοι πωλητές (12)
      <ul>
        <li>Μηχανήματα στίλβωσης (1)
        <ul>
          <li>Μηχανήματα κυλίνδρωσης (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα φυγοκέντρισης (5)
        <ul>
          <li>Εργαστηριακά μηχανήματα φυγοκέντρησης και εξαρτήματα (4)
          <ul>
            <li>Επιδαπέδια μηχανήματα φυγοκέντρισης (0)
            </li>
            <li>Μειωτήρες για μηχανήματα φυγοκέντρισης (0)
            </li>
            <li>Επιτραπέζια μηχανήματα φυγοκέντρισης (0)
            </li>
            <li>Περιστροφικός εξοπλισμός για μηχανήματα φυγοκέντρισης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Αυτόματα μηχανήματα πώλησης (3)
        <ul>
          <li>Αυτόματα μηχανήματα πώλησης προϊόντων (0)
          </li>
          <li>Αυτόματα μηχανήματα πώλησης γραμματοσήμων (0)
          </li>
          <li>Αυτόματα μηχανήματα πώλησης ειδών προσωπικής υγιεινής (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Διάφορα μηχανήματα ειδικής χρήσης (47)
      <ul>
        <li>Σύστημα ανύψωσης παλετών (1)
        <ul>
          <li>Σύστημα ανάκτησης παλετών (0)
          </li>
        </ul>
        </li>
        <li>Ηλεκτρικά είδη ειδικής χρήσης (3)
        <ul>
          <li>Θάλαμος απαλλαγμένος από ανακλάσεις ήχου (0)
          </li>
          <li>Κλωβοί φάραντεϊ (0)
          </li>
          <li>Υλικό ηλεκτρομαγνητικής απορρόφησης (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα παραγωγής χαρτιού, εκτύπωσης, βιβλιοδεσίας και μέρη τους (9)
        <ul>
          <li>Ξηραντήρια ξυλείας, χαρτοπολτού, χαρτιού ή χαρτονιού (0)
          </li>
          <li>Μηχανήματα βιβλιοδεσίας (1)
          <ul>
            <li>Μηχανήματα συρραφής βιβλίων (0)
            </li>
          </ul>
          </li>
          <li>Εκτυπωτικά μηχανήματα (3)
          <ul>
            <li>Μηχανήματα στοιχειοθεσίας (0)
            </li>
            <li>Εκτυπωτές εισιτηρίων (0)
            </li>
            <li>Εκτυπωτικά μηχανήματα όφσετ (0)
            </li>
          </ul>
          </li>
          <li>Μέρη μηχανημάτων εκτύπωσης και βιβλιοδεσίας (0)
          </li>
          <li>Σύστημα φωτοστοιχειοθεσίας (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα χημικής βιομηχανίας (2)
        <ul>
          <li>Συσκευές χλωρίωσης (0)
          </li>
          <li>Μηχανήματα δοσιμέτρησης (0)
          </li>
        </ul>
        </li>
        <li>Διάφορα μηχανήματα καθαρισμού (2)
        <ul>
          <li>Εξοπλισμός σήραγγας πλυσίματος (0)
          </li>
          <li>Μηχανές καθαρισμού ακτών (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα για την κατεργασία ελαστικού ή πλαστικού (4)
        <ul>
          <li>Μηχανήματα για την κατεργασία πλαστικών (2)
          <ul>
            <li>Συσκευές στρωματοποίησης (0)
            </li>
            <li>Εξαρτήματα στρωματοποίησης (0)
            </li>
          </ul>
          </li>
          <li>Μηχανές παραγωγής παραθύρων και πλαισίων από πλαστικές ύλες (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα επεξεργασίας λυμάτων (10)
        <ul>
          <li>Πρέσες αποβλήτων (0)
          </li>
          <li>Κλίνες ιζηματογένεσης (0)
          </li>
          <li>Κατακρημνιστές (0)
          </li>
          <li>Μονάδες ανάμειξης (0)
          </li>
          <li>Αποξεστήρες (0)
          </li>
          <li>Φίλτρα ακαθάρτων υδάτων (0)
          </li>
          <li>Εξοπλισμός επεξεργασίας ιλύος (0)
          </li>
          <li>Εξοπλισμός οξυγόνωσης (0)
          </li>
          <li>Κονιοποιητές (1)
          <ul>
            <li>Πολτοποιητές επεξεργασίας αποβλήτων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Μη οικιακές ηλεκτρικές σκούπες και μηχανές στίλβωσης δαπέδων (4)
        <ul>
          <li>Μη οικιακές ηλεκτρικές σκούπες (0)
          </li>
          <li>Μέρη μη οικιακών μηχανών στίλβωσης δαπέδου (0)
          </li>
          <li>Μέρη μη οικιακών ηλεκτρικών σαρώθρων (0)
          </li>
          <li>Μη οικιακές μηχανές στίλβωσης δαπέδων (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός δικτύου αγωγών (3)
        <ul>
          <li>Μηχανές καθαρισμού της εσωτερικής επιφάνειας αγωγών (0)
          </li>
          <li>Μηχανές επιθεώρησης της εσωτερικής επιφάνειας αγωγών (0)
          </li>
          <li>Βιομηχανικά ρομπότ (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μηχανήματα καθαρισμού φιαλών, συσκευασίας και ζύγισης, μηχανήματα ψεκασμού (23)
      <ul>
        <li>Μηχανήματα καθαρισμού, πλήρωσης, συσκευασίας ή περιτυλίγματος φιαλών ή άλλων δοχείων (6)
        <ul>
          <li>Μηχανήματα πλήρωσης ή κλεισίματος φιαλών, κυτίων ή άλλων δοχείων (0)
          </li>
          <li>Μηχανήματα καθαρισμού ή στεγνώματος φιαλών ή άλλων δοχείων (0)
          </li>
          <li>Μηχανήματα συσκευασίας ή περιτυλίγματος (3)
          <ul>
            <li>Μηχανήματα δεσίματος και συσκευασίας με ιμάντα (0)
            </li>
            <li>Μηχανήματα περιτυλίγματος (0)
            </li>
            <li>Μηχανήματα συσκευασίας (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Μηχανήματα ζύγισης και πλάστιγγες (6)
        <ul>
          <li>Μηχανήματα ζύγισης (1)
          <ul>
            <li>Ζυγαριές (0)
            </li>
          </ul>
          </li>
          <li>Ζυγοί (3)
          <ul>
            <li>Ζυγαριές συνεχούς ζύγισης αγαθών (0)
            </li>
            <li>Ζυγοί ελέγχου βάρους (0)
            </li>
            <li>Ζυγοί καταστημάτων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ψεκασμού (1)
        <ul>
          <li>Πιστόλια-ψεκαστήρες (0)
          </li>
        </ul>
        </li>
        <li>Μηχανικές συσκευές εκτόξευσης, διασκόρπισης ή ψεκασμού (5)
        <ul>
          <li>Συσκευές αποσμητικών χώρου (0)
          </li>
          <li>Συσκευές καθαρισμού με εκτόξευση νερού υπό πίεση (0)
          </li>
          <li>Εξοπλισμός απολύμανσης κυρίως εκ τοξικών ουσιών (0)
          </li>
          <li>Συσκευές καθαρισμού υψηλής πίεσης (0)
          </li>
          <li>Συσκευές διασκόρπισης αερίων (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές ατμοβολής ή αμμοβολής (0)
        </li>
      </ul>
      </li>
      <li>Μέρη πλυντηρίων πιάτων και μηχανών καθαρισμού, πλήρωσης, συσκευασίας ή περιτυλίγματος (5)
      <ul>
        <li>Μέρη μηχανών καθαρισμού (0)
        </li>
        <li>Μέρη μηχανών πλήρωσης (0)
        </li>
        <li>Μέρη μηχανών περιτυλίγματος (0)
        </li>
        <li>Μέρη πλυντηρίων πιάτων (0)
        </li>
        <li>Μέρη μηχανών συσκευασίας (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός συστημάτων χειρισμού και ελέγχου, εκτύπωσης, γραφικών, αυτοματισμών γραφείου και επεξεργασίας πληροφοριών (22)
      <ul>
        <li>Εξοπλισμός αυτοματοποίησης γραφείου (0)
        </li>
        <li>Εξοπολισμός εκτύπωσης και γραφικών (5)
        <ul>
          <li>Σύστημα εκτύπωσης φιλμ (0)
          </li>
          <li>Εκτυπωτική πρέσα (0)
          </li>
          <li>Μηχανές χάραξης (0)
          </li>
          <li>Σταθμοί επεξεργασίας γραφικών (0)
          </li>
          <li>Πολύγραφοι (0)
          </li>
        </ul>
        </li>
        <li>Σύστημα χειρισμού και ελέγχου (4)
        <ul>
          <li>Σύστημα εντοπισμού οχημάτων (0)
          </li>
          <li>Σύστημα αποστολής (0)
          </li>
          <li>Σύστημα ελέγχου εισόδου (0)
          </li>
          <li>Σύστημα εποπτικού ελέγχου και απόκτησης δεδομένων (SCADA) ή ισοδύναμο (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός επεξεργασίας πληροφοριών (2)
        <ul>
          <li>Σύστημα διαχείρισης αποθήκης (1)
          <ul>
            <li>Σύστημα αποθήκης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Συσκευές διανομής (3)
        <ul>
          <li>Μηχανές πώλησης ειδών υγιεινής (0)
          </li>
          <li>Μηχανές πώλησης ποτών (0)
          </li>
          <li>Σύστημα διάθεσης χαρτιού υγείας σε ρόλους (0)
          </li>
        </ul>
        </li>
        <li>Μονάδες ελεγκτών (1)
        <ul>
          <li>Μονάδα ψηφιακού τηλεχειρισμού (0)
          </li>
        </ul>
        </li>
        <li>Πρέσες νομισμάτων (0)
        </li>
      </ul>
      </li>
      <li>Μηχανήματα για τη θερμική επεξεργασία υλικών (13)
      <ul>
        <li>Κλίβανοι και σχετικά εξαρτήματα (1)
        <ul>
          <li>Κλίβανοι κενού (0)
          </li>
        </ul>
        </li>
        <li>Μηχανήματα θερμικής επεξεργασίας αερίων (0)
        </li>
        <li>Θερμοστατικά λουτρά και σχετικά εξαρτήματα (9)
        <ul>
          <li>Ψυχόμενοι κυκλοφορητές και ψυχόμενοι κυκλοφορητές θέρμανσης (0)
          </li>
          <li>Κυκλοφορητές θέρμανσης (1)
          <ul>
            <li>Καλύμματα για διεργασίες λουτρών (0)
            </li>
          </ul>
          </li>
          <li>Κυκλοφορητές υψηλής θερμοκρασίας (0)
          </li>
          <li>Ψύκτες με σύστημα ανακυκλοφορίας (0)
          </li>
          <li>Λουτρά υπερήχων (1)
          <ul>
            <li>Θερμοστάτες εμβάπτισης (0)
            </li>
          </ul>
          </li>
          <li>Ψύκτες εμβάπτισης (0)
          </li>
          <li>Ψυκτικά πηνία (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Αεριογεννήτριες (1)
      <ul>
        <li>Γεννήτριες όζοντος (0)
        </li>
      </ul>
      </li>
      <li>Συσκευές απόσταξης, διήθησης ή επαναδιύλισης (16)
      <ul>
        <li>Εξοπλισμός ανακύκλωσης (0)
        </li>
        <li>Μηχανήματα και συσκευές διήθησης ή καθαρισμού υγρών (10)
        <ul>
          <li>Μηχανές και συσκευές διήθησης υγρών (3)
          <ul>
            <li>Υδροκυκλώνες (0)
            </li>
            <li>Συσκευές διήθησης ή καθαρισμού ποτών (0)
            </li>
            <li>Συσκευές διήθησης ιλύος γεωτρήσεων (0)
            </li>
          </ul>
          </li>
          <li>Μηχανήματα και συσκευές διήθησης ή καθαρισμού νερού (5)
          <ul>
            <li>Συσκευές καθαρισμού νερού (0)
            </li>
            <li>Συσκευές απαέρωσης (0)
            </li>
            <li>Εξοπλισμός εγκαταστάσεων διήθησης (0)
            </li>
            <li>Συσκευές διήθησης νερού (0)
            </li>
            <li>Συσκευές αφαλάτωσης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Φίλτρα λαδιού, βενζίνης και αέρα (3)
        <ul>
          <li>Φίλτρα λαδιού (0)
          </li>
          <li>Φίλτρα βενζίνης (0)
          </li>
          <li>Φίλτρα αέρα για κινητήρες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μέρη μηχανημάτων γενικής χρήσης (8)
      <ul>
        <li>Μέρη μηχανημάτων κυλίνδρωσης (0)
        </li>
        <li>Μέρη μηχανημάτων κάθαρσης (0)
        </li>
        <li>Σταθμά για ζυγαριές (0)
        </li>
        <li>Πλυντήρια πιάτων επαγγελματικού τύπου (0)
        </li>
        <li>Μέρη στιλβωτικών μηχανημάτων (0)
        </li>
        <li>Μέρη φυγοκεντρικών μηχανών (0)
        </li>
        <li>Μέρη μηχανημάτων ψεκασμού (0)
        </li>
        <li>Μέρη μηχανημάτων διήθησης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες δημόσιας διοίκησης, άμυνας και κοινωνικής ασφάλισης (56)
  <ul>
    <li className='top-level'>Υπηρεσίες διοίκησης (15)
    <ul>
      <li>Διοικητικές υπηρεσίες οργανισμών (5)
      <ul>
        <li>Διοικητικές εκπαιδευτικές υπηρεσίες (0)
        </li>
        <li>Διοικητικές υγειονομικές υπηρεσίες (0)
        </li>
        <li>Διοικητικές ψυχαγωγικές, πολιτιστικές και θρησκευτικές υπηρεσίες (0)
        </li>
        <li>Διοικητικές υπηρεσίες σχετιζόμενες με θέματα τουρισμού (0)
        </li>
        <li>Υπηρεσίες διαχείρισης κατοικιών (0)
        </li>
      </ul>
      </li>
      <li>Δημόσιες υπηρεσίες γενικής φύσεως (5)
      <ul>
        <li>Διοικητικές υπηρεσίες επιχειρηματικών δραστηριοτήτων (1)
        <ul>
          <li>Διοικητικές υπηρεσίες σχετιζόμενες με αναπτυξιακά σχέδια (0)
          </li>
        </ul>
        </li>
        <li>Εκτελεστικές και νομοθετικές υπηρεσίες (2)
        <ul>
          <li>Νομοθετικές υπηρεσίες (0)
          </li>
          <li>Εκτελεστικές υπηρεσίες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Βοηθητικές κρατικές υπηρεσίες (2)
      <ul>
        <li>Κρατικές υπηρεσίες (1)
        <ul>
          <li>Γενικές υπηρεσίες προσωπικού για το δημόσιο (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες υποχρεωτικής κοινωνικής ασφάλισης (9)
    <ul>
      <li>Επιδόματα τέκνων (0)
      </li>
      <li>Οικογενειακά επιδόματα (0)
      </li>
      <li>Συνταξιοδοτικά προγράμματα δημοσίων υπαλλήλων (0)
      </li>
      <li>Υπηρεσίες παροχής επιδομάτων (5)
      <ul>
        <li>Επιδόματα ασθενείας (0)
        </li>
        <li>Επιδόματα ανεργίας (0)
        </li>
        <li>Επιδόματα αναπηρίας (1)
        <ul>
          <li>Επιδόματα προσωρινής αναπηρίας (0)
          </li>
        </ul>
        </li>
        <li>Επιδόματα μητρότητας (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Παροχή υπηρεσιών στο κοινωνικό σύνολο (29)
    <ul>
      <li>Πυροσβεστικές υπηρεσίες και υπηρεσίες διάσωσης (5)
      <ul>
        <li>Υπηρεσίες διάσωσης (0)
        </li>
        <li>Πυροσβεστικές υπηρεσίες (3)
        <ul>
          <li>Υπηρεσίες καταπολέμησης πυρκαγιών (2)
          <ul>
            <li>Υπηρεσίες καταπολέμησης δασοπυρκαγιών (0)
            </li>
            <li>Υπηρεσίες πρόληψης πυρκαγιών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες άμυνας (2)
      <ul>
        <li>Υπηρεσίες στρατιωτικής άμυνας (0)
        </li>
        <li>Υπηρεσίες πολιτικής άμυνας (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες στον τομέα της δικαιοσύνης (7)
      <ul>
        <li>Δικαστικές υπηρεσίες (6)
        <ul>
          <li>Υπηρεσίες που σχετίζονται με την κράτηση ή την κοινωνική επανένταξη εγκληματιών (4)
          <ul>
            <li>Υπηρεσίες συνοδείας κρατουμένων (0)
            </li>
            <li>Υπηρεσίες φυλακών (0)
            </li>
            <li>Υπηρεσίες δικαστικής επιτήρησης (0)
            </li>
            <li>Υπηρεσίες φύλαξης κρατουμένων (0)
            </li>
          </ul>
          </li>
          <li>Νομικές διοικητικές υπηρεσίες που σχετίζονται με τα δικαστήρια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εξωτερικών υποθέσεων και άλλες υπηρεσίες (5)
      <ul>
        <li>Υπηρεσίες εξωτερικής πολιτικής (4)
        <ul>
          <li>Διπλωματικές υπηρεσίες (1)
          <ul>
            <li>Υπηρεσίες προξενείου (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες που σχετίζονται με θέματα εξωτερικής οικονομικής βοήθειας (0)
          </li>
          <li>Υπηρεσίες που σχετίζονται με θέματα εξωτερικής στρατιωτικής βοήθειας (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες δημόσιας ασφάλειας και τήρησης της νομίμου τάξεως (5)
      <ul>
        <li>Υπηρεσίες δημόσιας τάξης και ασφάλειας (2)
        <ul>
          <li>Υπηρεσίες δημόσιας τάξης (1)
          <ul>
            <li>Υπηρεσίες δικαστικού κλητήρα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες δημόσιας ασφάλειας (1)
        <ul>
          <li>Αστυνομικές υπηρεσίες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Ιατρικές συσκευές, φαρμακευτικά προϊόντα και προϊόντα ατομικής περιποίησης (640)
  <ul>
    <li className='top-level'>Φαρμακευτικά προϊόντα (131)
    <ul>
      <li>Φάρμακα για το πεπτικό σύστημα και το μεταβολισμό (9)
      <ul>
        <li>Βιταμίνες (1)
        <ul>
          <li>Προβιταμίνες (0)
          </li>
        </ul>
        </li>
        <li>Φάρμακα θεραπείας του διαβήτη (1)
        <ul>
          <li>Ινσουλίνη (0)
          </li>
        </ul>
        </li>
        <li>Καθαρκτικά (0)
        </li>
        <li>Φάρμακα για διαταραχές της γαστρικής έκκρισης (0)
        </li>
        <li>Φάρμακα για λειτουργικές γαστρεντερικές διαταραχές (0)
        </li>
        <li>Αντιδιαρροϊκά, φάρμακα κατά των φλεγμονωδών νόσων/λοιμώξεων του εντέρου (0)
        </li>
        <li>Συμπληρώματα ιχνοστοιχείων (0)
        </li>
      </ul>
      </li>
      <li>Φαρμακευτικά είδη (3)
      <ul>
        <li>Πλακίδια από ελαστικό (0)
        </li>
        <li>Προστατευτικές επενδύσεις από ελαστικό (0)
        </li>
        <li>Θηλές θηλάστρων, προστατευτικά καλύμματα θηλών και άλλα είδη για βρέφη (0)
        </li>
      </ul>
      </li>
      <li>Διάφορα φάρμακα (37)
      <ul>
        <li>Αντιδραστήρια και σκιαγραφικές ουσίες (8)
        <ul>
          <li>Αντιδραστήρια αιματολογικών τεστ (0)
          </li>
          <li>Αντιδραστήρια ουρολογίας (0)
          </li>
          <li>Χημικά αντιδραστήρια (0)
          </li>
          <li>Αντιδραστήρια εργαστηρίων (0)
          </li>
          <li>Μέσα αντίθεσης ακτινολογίας (0)
          </li>
          <li>Αντιδραστήρια ηλεκτροφόρησης (0)
          </li>
          <li>Αντιδραστήρια για την εξακρίβωση της ομάδας αίματος (0)
          </li>
          <li>Ισοτοπικά αντιδραστήρια (0)
          </li>
        </ul>
        </li>
        <li>Διαγνωστικές ουσίες (0)
        </li>
        <li>Άλλα μη θεραπευτικά προϊόντα (0)
        </li>
        <li>Ιατρικά διαλύματα (10)
        <ul>
          <li>Γαληνικά διαλύματα (0)
          </li>
          <li>Διαλύματα γλυκόζης (0)
          </li>
          <li>Εντερικές τροφές (0)
          </li>
          <li>Ενέσιμα διαλύματα (1)
          <ul>
            <li>Υγρά ενδοφλέβιας έγχυσης (0)
            </li>
          </ul>
          </li>
          <li>Διαλύματα αιμοδιύλισης (0)
          </li>
          <li>Διαλύματα έγχυσης (0)
          </li>
          <li>Διαλύματα εγχύσεων (0)
          </li>
          <li>Προϊόντα παρεντερικής διατροφής (1)
          <ul>
            <li>Διαλύματα παρεντερικής διατροφής (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Αντιπαρασιτικά, εντομοκτόνα και εντομοαπωθητικά (3)
        <ul>
          <li>Ανθελμινθικά (0)
          </li>
          <li>Αντιπρωτοζωικά (0)
          </li>
          <li>Παρασιτοκτόνα τοπικής χρήσης, συμπεριλαμβανομένων των φαρμάκων κατά της ψώρας και των φθειριάσεων, των εντομοκτόνων και των εντομοαποθητικών (0)
          </li>
        </ul>
        </li>
        <li>Άλλα θεραπευτικά προϊόντα (3)
        <ul>
          <li>Τοξίνες (0)
          </li>
          <li>Θεραπεία εθισμών (0)
          </li>
          <li>Υποκατάστατα νικοτίνης (0)
          </li>
        </ul>
        </li>
        <li>Κλινικά προϊόντα (3)
        <ul>
          <li>Αδένες και εκχυλίσματά τους (0)
          </li>
          <li>Πεπτικές ουσίες (0)
          </li>
          <li>Καλλιέργειες μικροβιολογίας (0)
          </li>
        </ul>
        </li>
        <li>Ιατρικά παρασκευάσματα εκτός από αναλώσιμα οδοντιατρικά υλικά (2)
        <ul>
          <li>Παρασκευάσματα υποκλυσμού (1)
          <ul>
            <li>Τσιμεντοκονιάματα ανακατασκευής οστών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Φάρμακα για το αίμα, τα αιμοποιητικά όργανα και το καρδιαγγειακό σύστημα (14)
      <ul>
        <li>Φάρμακα για το καρδιαγγειακό σύστημα (8)
        <ul>
          <li>Φάρμακα που επηρεάζουν το σύστημα ρενίνης-αγγειοτασίνης (0)
          </li>
          <li>Διουρητικά (0)
          </li>
          <li>Αντιυπερτασικά (0)
          </li>
          <li>Αντιαιμορροϊδικά τοπικής χρήσης (0)
          </li>
          <li>Αγγειοπροστατευτικά (0)
          </li>
          <li>Φάρμακα θεραπείας των καρδιακών παθήσεων (0)
          </li>
          <li>Ανταγωνιστές του ασβεστίου (0)
          </li>
          <li>Β-αδρενεργικοί αποκλειστές (0)
          </li>
        </ul>
        </li>
        <li>Φάρμακα για το αίμα, τα αιμοποιητικά όργανα (4)
        <ul>
          <li>Αντιθρομβωτικά (0)
          </li>
          <li>Φάρμακα αναιμιών (0)
          </li>
          <li>Αντιαιμορραγικά (0)
          </li>
          <li>Υποκατάστατα πλάσματος και παρεντερικά διαλύματα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Φάρμακα παθήσεων του νευρικού συστήματος και των αισθητηρίων οργάνων (10)
      <ul>
        <li>Φάρμακα παθήσεων του νευρικού συστήματος (7)
        <ul>
          <li>Αντιπαρκινσονικά (0)
          </li>
          <li>Αναλγητικά (0)
          </li>
          <li>Ψυχοληπτικά (0)
          </li>
          <li>Αντιεπιληπτικά (0)
          </li>
          <li>Άλλα φάρμακα παθήσεων του νευρικού συστήματος (0)
          </li>
          <li>Αναισθητικά (0)
          </li>
          <li>Ψυχοαναληπτικά (0)
          </li>
        </ul>
        </li>
        <li>Φάρμακα παθήσεων αισθητηρίων οργάνων (1)
        <ul>
          <li>Φάρμακα οφθαλμικών παθήσεων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Φάρμακα παθήσεων δέρματος και μυοσκελετικών παθήσεων (13)
      <ul>
        <li>Φάρμακα παθήσεων δέρματος (8)
        <ul>
          <li>Μαλακτικά και προστατευτικά (0)
          </li>
          <li>Αντιβιοτικά και χημειοθεραπευτικά τοπικής χρήσης (0)
          </li>
          <li>Φάρμακα κατά της ακμής (0)
          </li>
          <li>Φάρμακα κατά της ψωρίασης (0)
          </li>
          <li>Κορτικοστεροειδή τοπικής χρήσης και σκευάσματα τοπικής χρήσης (0)
          </li>
          <li>Αντιμυκητιασικά τοπικής χρήσης (1)
          <ul>
            <li>Σαλικυλικά οξέα (0)
            </li>
          </ul>
          </li>
          <li>Αντισηπτικά και απολυμαντικά (0)
          </li>
        </ul>
        </li>
        <li>Φάρμακα μυοσκελετικών παθήσεων (3)
        <ul>
          <li>Αντιφλεγμονώδη και φάρμακα ρευματικών νόσων (0)
          </li>
          <li>Μυοχαλαρωτικά (0)
          </li>
          <li>Φάρμακα κατά της ουρικής αρθρίτιδας (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Φάρμακα για το γεννητικό και ουροποιητικό σύστημα και ορμόνες (11)
      <ul>
        <li>Φάρμακα για το γεννητικό και ουροποιητικό σύστημα και γεννητικές ορμόνες (6)
        <ul>
          <li>Άλλα φάρμακα χρησιμοποιούμενα στη γυναικολογία (0)
          </li>
          <li>Φάρμακα κατά των λοιμώξεων και αντισηπτικά το γεννητικού συστήματος (0)
          </li>
          <li>Γεννητικές ορμόνες και ρυθμιστές του γεννητικού συστήματος (0)
          </li>
          <li>Αντισυλληπτικά (2)
          <ul>
            <li>Χημικές αντισυλληπτικές ουσίες (0)
            </li>
            <li>Αντισυλληπτικά χάπια (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Φάρμακα συστηματικών ορμονών, εξαιρουμένων των γεννητικών ορμονών (3)
        <ul>
          <li>Φάρμακα παθήσεων του θυροειδούς (0)
          </li>
          <li>Φάρμακα υποφύσεως, υποθαλάμου και συναφείς ουσίες (0)
          </li>
          <li>Κορτικοστεροειδή συστηματικής χρήσης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Φάρμακα παθήσεων αναπνευστικού συστήματος (3)
      <ul>
        <li>Αντιισταμινικά συστηματικής χρήσης (0)
        </li>
        <li>Φάρμακα αποφρακτικών παθήσεων των αναπνευστικών οδών (0)
        </li>
        <li>Αντιβηχικά και φάρμακα κατά του κρυολογήματος (0)
        </li>
      </ul>
      </li>
      <li>Φάρμακα κατά των λοιμώξεων συστηματικής χρήσης, εμβόλια, αντινεοπλασματικά και ανοσορρυθμιστικά φάρμακα (22)
      <ul>
        <li>Φάρμακα κατά των λοιξώξεων συστηματικής χρήσης (17)
        <ul>
          <li>Φάρμακα κατά των μυκοβακτηρίων (0)
          </li>
          <li>Οροί κατά των δηλητηριάσεων και ανοσοσφαιρίνες (2)
          <ul>
            <li>Ανοσοσφαιρίνες (0)
            </li>
            <li>Αντιοροί (0)
            </li>
          </ul>
          </li>
          <li>Ιοστατικά συστηματικής χρήσης (0)
          </li>
          <li>Αντιμικροβιακά συστηματικής χρήσης (0)
          </li>
          <li>Εμβόλια (9)
          <ul>
            <li>Εμβόλια διφθερίτιδας-τετάνου-κοκκύτη (0)
            </li>
            <li>Εμβόλια πολυομελίτιδας (0)
            </li>
            <li>Εμβόλια κτηνιατρικής (0)
            </li>
            <li>Αντιγριππικά εμβόλια (0)
            </li>
            <li>Εμβόλια τύφου (0)
            </li>
            <li>Εμβόλια ηπατίτιδας Β (0)
            </li>
            <li>Εμβόλια ιλαράς-παρωτίτιδας-ερυθράς (0)
            </li>
            <li>Εμβόλια διφθερίτιδας-τετάνου (0)
            </li>
            <li>Εμβόλια BCG (σε σκόνη) (0)
            </li>
          </ul>
          </li>
          <li>Αντιμυκητιασικά συστηματικής χρήσης (0)
          </li>
        </ul>
        </li>
        <li>Αντινεοπλασματικά και ανοσορρυθμιστικά φάρμακα (3)
        <ul>
          <li>Αντινεοπλασματικά φάρμακα (0)
          </li>
          <li>Ανοσοκατασταλτικά φάρμακα (0)
          </li>
          <li>Φάρμακα παθήσεων των ενδοκρινών αδένων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Προϊόντα ατομικής περιποίησης (89)
    <ul>
      <li>Εργαστηριακά είδη, είδη υγιεινής ή φαρμακευτικά είδη από γυαλί (3)
      <ul>
        <li>Γυάλινα είδη εργαστηρίου (0)
        </li>
        <li>Φαρμακευτικά είδη από γυαλί (0)
        </li>
        <li>Είδη υγιεινής από γυαλί (0)
        </li>
      </ul>
      </li>
      <li>Προϊόντα για την περιποίηση των χεριών και των νυχιών (7)
      <ul>
        <li>Προϊόντα περιποίησης των νυχιών (2)
        <ul>
          <li>Βερνίκι νυχιών (0)
          </li>
          <li>Νυχοκόπτες (0)
          </li>
        </ul>
        </li>
        <li>Προϊόντα για την περιποίηση των χεριών (3)
        <ul>
          <li>Προϊόντα καθαρισμού των χεριών (0)
          </li>
          <li>Λοσιόν για το σώμα ή τα χέρια (0)
          </li>
          <li>Απολυμαντικά χεριών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Προϊόντα για την περιποίηση των ματιών και διορθωτικοί φακοί (12)
      <ul>
        <li>Φακοί επαφής (3)
        <ul>
          <li>Διορθωτικοί φακοί (2)
          <ul>
            <li>Ενδοφθάλμιοι φακοί (0)
            </li>
            <li>Φακοί ματογυαλιών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Ματογυάλια (2)
        <ul>
          <li>Γυαλί για ματογυάλια (0)
          </li>
          <li>Σκελετοί και υποστηρίγματα γυαλιών (0)
          </li>
        </ul>
        </li>
        <li>Δίοπτρα-προσωπίδες (2)
        <ul>
          <li>Σκελετοί και υποστηρίγματα προστατευτικών διόπτρων (0)
          </li>
          <li>Προφυλακτικά δίοπτρα (0)
          </li>
        </ul>
        </li>
        <li>Γυαλιά ηλίου (0)
        </li>
        <li>Λοσιόν για φακούς επαφής (0)
        </li>
      </ul>
      </li>
      <li>Αρώματα, παρασκευάσματα ατομικής περιποίησης και προφυλακτικά (40)
      <ul>
        <li>Προϊόντα για την περιποίηση των ποδιών (0)
        </li>
        <li>Αρώματα και παρασκευάσματα ατομικής περιποίησης (37)
        <ul>
          <li>Παρασκευάσματα για την περιποίηση των νυχιών χεριών και ποδιών (0)
          </li>
          <li>Παρασκευάσματα για τη φροντίδα του δέρματος (4)
          <ul>
            <li>Αφρόλουτρα σε μορφή ζελέ για το μπάνιο (0)
            </li>
            <li>Σκουφάκια για το ντους (0)
            </li>
            <li>Παραφαρμακευτικές κρέμες ή λοσιόν (0)
            </li>
            <li>Προϊόντα αντιηλιακής προστασίας (0)
            </li>
          </ul>
          </li>
          <li>Παρασκευάσματα για μακιγιάζ (0)
          </li>
          <li>Σαπούνι (0)
          </li>
          <li>Παρασκευάσματα ξυρίσματος (1)
          <ul>
            <li>Κρέμες ξυρίσματος (0)
            </li>
          </ul>
          </li>
          <li>Είδη και παρασκευάσματα για την υγιεινή του στόματος ή των δοντιών (9)
          <ul>
            <li>Οδοντιατρικά σετ (0)
            </li>
            <li>Οδοντογλυφίδες (0)
            </li>
            <li>Αποσμητικά στόματος (0)
            </li>
            <li>Στοματικά διαλύματα (0)
            </li>
            <li>Πιπίλες, τεχνητές θηλές και θηλές μπιμπερό για βρέφη (0)
            </li>
            <li>Οδοντικό νήμα (0)
            </li>
            <li>Οδοντόπαστα (0)
            </li>
            <li>Οδοντόβουρτσες (0)
            </li>
            <li>Δισκία καθαρισμού τεχνητών οδοντοστοιχιών (0)
            </li>
          </ul>
          </li>
          <li>Κολόνιες (5)
          <ul>
            <li>Αποσμητικά κατά της εφίδρωσης (0)
            </li>
            <li>Αρωματικά (0)
            </li>
            <li>Ροδόνερο (0)
            </li>
            <li>Αποσμητικά (0)
            </li>
            <li>Κολώνιες (0)
            </li>
          </ul>
          </li>
          <li>Παρασκευάσματα και είδη για τα μαλλιά (4)
          <ul>
            <li>Σετ καλλυντικών (0)
            </li>
            <li>Σαμπουάν (0)
            </li>
            <li>Περούκες (0)
            </li>
            <li>Χτένες (0)
            </li>
          </ul>
          </li>
          <li>Προϊόντα καλλωπισμού (5)
          <ul>
            <li>Σετ μακιγιάζ (0)
            </li>
            <li>Ωτογλυφίδες (μπατονέτες) (0)
            </li>
            <li>Ατομικά μαντιλάκια μίας χρήσης (0)
            </li>
            <li>Τατουάζ (0)
            </li>
            <li>Μαλακτικά προϊόντα για τα χείλη (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Προφυλακτικά (0)
        </li>
      </ul>
      </li>
      <li>Προϊόντα βρεφικής περιποίησης (2)
      <ul>
        <li>Χάρτινα τραπεζομάντιλα μιας χρήσης (0)
        </li>
        <li>Επιθέματα θηλασμού (0)
        </li>
      </ul>
      </li>
      <li>Ξυράφια και σύνολα για την περιποίηση των χεριών ή των ποδιών (9)
      <ul>
        <li>Ξυράφια (2)
        <ul>
          <li>Ηλεκτρικές ξυριστικές μηχανές (0)
          </li>
          <li>Λεπίδες ξυραφιών (0)
          </li>
        </ul>
        </li>
        <li>Σύνολα για την περιποίηση των χεριών ή των ποδιών (5)
        <ul>
          <li>Σύνολα για την περιποίηση των ποδιών (1)
          <ul>
            <li>Εργαλεία για την περιποίηση των ποδιών (0)
            </li>
          </ul>
          </li>
          <li>Σύνολα για την περιποίηση των χεριών (1)
          <ul>
            <li>Εργαλεία για την περιποίηση των χεριών (0)
            </li>
          </ul>
          </li>
          <li>Μπαρέτες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Χαρτί υγείας, χαρτομάντιλα, πετσέτες χειρός και χαρτοπετσέτες (4)
      <ul>
        <li>Χαρτομάντιλα (0)
        </li>
        <li>Χαρτοπετσέτες (0)
        </li>
        <li>Χαρτί υγείας (0)
        </li>
        <li>Χάρτινες χειροπετσέτες (0)
        </li>
      </ul>
      </li>
      <li>Χαρτί υγιεινής (4)
      <ul>
        <li>Χάρτινα είδη μιας χρήσης (0)
        </li>
        <li>Προϊόντα υγιεινής από χαρτί (2)
        <ul>
          <li>Βρεφικές πάνες (0)
          </li>
          <li>Σερβιέτες υγείας ή ταμπόν (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ιατρικές συσκευές (349)
    <ul>
      <li>Οδοντιατρικά και παραοδοντιατρικά όργανα και συσκευές (43)
      <ul>
        <li>Εξαρτήματα οδοντικής προφύλαξης (0)
        </li>
        <li>Προϊόντα προσθετικής και επένδυσης δοντιών (1)
        <ul>
          <li>Οδοντοστοιχίες (0)
          </li>
        </ul>
        </li>
        <li>Οδοντιατρικό εμφύτευμα (0)
        </li>
        <li>Συσκευές ορθοδοντικής (0)
        </li>
        <li>Εξαρτήματα εντυπώματος δοντιών (0)
        </li>
        <li>Οδοντιατρικά εργαλεία χειρός (34)
        <ul>
          <li>Οδοντιατρική βελόνα ραφής (0)
          </li>
          <li>Οδοντιατρικά εργαλεία μιας χρήσης (0)
          </li>
          <li>Όργανο σφραγίσματος δοντιών (0)
          </li>
          <li>Οδοντιατρικά χειρουργικά εργαλεία (27)
          <ul>
            <li>Οδοντιατρικές ριζοβελόνες, ξέστρα οδοντικής πλάκας και ζυγοί (3)
            <ul>
              <li>Οδοντιατρικά ξέστρα οδοντικής πλάκας (0)
              </li>
              <li>Οδοντιατρικές ριζοβελόνες (0)
              </li>
              <li>Οδοντιατρικοί ζυγοί (0)
              </li>
            </ul>
            </li>
            <li>Οδοντιατρικά προστατευτικά δαχτύλων και λαβίδες (2)
            <ul>
              <li>Οδοντιατρικά προστατευτικά δαχτύλων (0)
              </li>
              <li>Οδοντιατρικές λαβίδες (0)
              </li>
            </ul>
            </li>
            <li>Οδοντιατρικές τσιμπίδες, βούρτσες, διευρυντήρες και φρέζες λείανσης (4)
            <ul>
              <li>Οδοντιατρικές τσιμπίδες (0)
              </li>
              <li>Βούρτσες οδοντιατρικών επεμβάσεων (0)
              </li>
              <li>Οδοντιατρικές φρέζες λείανσης (0)
              </li>
              <li>Οδοντιατρικοί διευρυντήρες (0)
              </li>
            </ul>
            </li>
            <li>Οδοντιατρικές σπάτουλες, τανάλιες και μαχαίρια για κερί (3)
            <ul>
              <li>Οδοντιατρικές τανάλιες (0)
              </li>
              <li>Οδοντιατρικές σπάτουλες (0)
              </li>
              <li>Οδοντιατρικά μαχαίρια για κερί (0)
              </li>
            </ul>
            </li>
            <li>Οδιαντρικές διατάξεις κρυοχειρουργικής, μέτρησης, ανελκτήρες και εκσκαφείς (4)
            <ul>
              <li>Οδοντιατρικές μονάδες κρυοχειρουργικής (0)
              </li>
              <li>Οδοντιατρικοί εκσκαφείς (0)
              </li>
              <li>Οδοντιατρικοί ανελκτήρες (0)
              </li>
              <li>Οδοντιατρικοί μετρητές βάθους (0)
              </li>
            </ul>
            </li>
            <li>Οδοντιατρικοί καθρέφτες και εγγλυφίδες (2)
            <ul>
              <li>Οδοντιατρικοί καθρέφτες (0)
              </li>
              <li>Οδοντιατρικές εγγλυφίδες (0)
              </li>
            </ul>
            </li>
            <li>Οδοντιατρικά ψαλίδια και λεπίδες (2)
            <ul>
              <li>Οδοντατρικές λεπίδες (0)
              </li>
              <li>Οδοντιατρικά ψαλίδια (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Οδοντιατρικός καθετήρας (0)
          </li>
          <li>Οδοντιατρικό όργανο εξαγωγής (1)
          <ul>
            <li>Διατρυτική συσκευή δοντιών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξαρτήματα ενδοδοντικής (0)
        </li>
        <li>Περιστροφικό και λειαντικό όργανο (0)
        </li>
      </ul>
      </li>
      <li>Διάφορες ιατρικές συσκευές και προϊόντα (60)
      <ul>
        <li>Συσκευές αποστείρωσης, απολύμανσης και υγιεινής δοντιών (2)
        <ul>
          <li>Αποστειρωτές (1)
          <ul>
            <li>Αυτόκλειστα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ηλεκτρονικών υπολογιστών ιατρικής χρήσης (0)
        </li>
        <li>Νοσοκομειακά είδη από χαρτί (2)
        <ul>
          <li>Κομπρέσες από χαρτί (0)
          </li>
          <li>Χάρτινες σακούλες ή περιτυλίγματα αποστείρωσης (0)
          </li>
        </ul>
        </li>
        <li>Ιατρικές προμήθειες (20)
        <ul>
          <li>Ιατρική επίπλωση, εκτός από κλίνες και τράπεζες (5)
          <ul>
            <li>Εξοπλισμός αίθουσας χειρουργείου εκτός από τράπεζες (0)
            </li>
            <li>Υποδοχή φιάλης ούρων (0)
            </li>
            <li>Διατάξεις έλξης ή αιώρησης ιατρικών κλινών (0)
            </li>
            <li>Εξοπλισμός μετάγγισης (0)
            </li>
            <li>Κλίβανοι ιατρικών καλλιεργειών (0)
            </li>
          </ul>
          </li>
          <li>Ανυψωτικός εξοπλισμός για τον τομέα της υγείας (0)
          </li>
          <li>Ιατρικές κλίνες (6)
          <ul>
            <li>Κρεβάτια νοσοκομείων για θεραπευτική αγωγή (0)
            </li>
            <li>Νοσοκομειακές κλίνες (0)
            </li>
            <li>Ορθοπεδικές κλίνες (0)
            </li>
            <li>Ηλεκτρικώς κινούμενα κρεβάτια νοσοκομείων (0)
            </li>
            <li>Ανάκλιντρο ψυχιατρικών εξετάσεων (0)
            </li>
            <li>Φορεία (0)
            </li>
          </ul>
          </li>
          <li>Ιατρεία οδοντιάτρων (1)
          <ul>
            <li>Πολυθρόνα οδοντιάτρων (0)
            </li>
          </ul>
          </li>
          <li>Ιατρικές τράπεζες (2)
          <ul>
            <li>Τράπεζες χειρουργείου (0)
            </li>
            <li>Τράπεζες ιατρικών εξετάσεων (0)
            </li>
          </ul>
          </li>
          <li>Δοκιμαστικοί σωλήνες (0)
          </li>
        </ul>
        </li>
        <li>Αναπηρικά καρότσια, πολυθρόνες με τροχούς και συναφή είδη (16)
        <ul>
          <li>Μέρη και εξαρτήματα για αναπηρικά καρότσια και πολυθρόνες με τροχούς (11)
          <ul>
            <li>Μέρη και εξαρτήματα για πολυθρόνες με τροχούς (5)
            <ul>
              <li>Μεταλλικά πλαίσια για πολυθρόνες με τροχούς (0)
              </li>
              <li>Καθίσματα για πολυθρόνες με τροχούς (0)
              </li>
              <li>Επίσωτρα αναπηρικών καρεκλών (0)
              </li>
              <li>Μαξιλάρια για πολυθρόνες με τροχούς (0)
              </li>
              <li>Τροχοί για πολυθρόνες με τροχούς (0)
              </li>
            </ul>
            </li>
            <li>Μέρη και εξαρτήματα για αναπηρικά καρότσια (4)
            <ul>
              <li>Κινητήρες για αναπηρικά καρότσια (0)
              </li>
              <li>Σύστημα ελέγχου για αναπηρικά καρότσια (0)
              </li>
              <li>Μεταλλικός σκελετός για αναπηρικά καρότσια (0)
              </li>
              <li>Σύστημα διακυβέρνησης για αναπηρικά καρότσια (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Αναπηρικά καρότσια και πολυθρόνες με τροχούς (3)
          <ul>
            <li>Αναπηρικά καρότσια (0)
            </li>
            <li>Πολυθρόνες με τροχούς (1)
            <ul>
              <li>Πολυθρόνες με ηλεκτρική κίνηση των τροχών (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Ιατρικά βοηθήματα (2)
        <ul>
          <li>Εξοπλισμός για ηλικιωμένα άτομα (0)
          </li>
          <li>Εξοπλισμός για άτομα με ειδικές ανάγκες (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές και όργανα μετάγγισης και έγχυσης (6)
        <ul>
          <li>Συσκευές και όργανα μετάγγισης (2)
          <ul>
            <li>Προμήθειες μετάγγισης αίματος (0)
            </li>
            <li>Διατάξεις μετάγγισης αίματος (0)
            </li>
          </ul>
          </li>
          <li>Συσκευές και όργανα έγχυσης (2)
          <ul>
            <li>Αντλίες ενδοφλέβιας έγχυσης (0)
            </li>
            <li>Αναλώσιμα έγχυσης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Σύστημα παρακολούθησης των ασθενών (3)
        <ul>
          <li>Συστήματα παρακολούθησης (1)
          <ul>
            <li>Σύστημα παρακολούθησης της αναπνοής (0)
            </li>
          </ul>
          </li>
          <li>Κεντρικός σταθμός παρακολούθησης (0)
          </li>
        </ul>
        </li>
        <li>Ιατρικός ρουχισμός (0)
        </li>
      </ul>
      </li>
      <li>Ιατρικά αναλώσιμα (84)
      <ul>
        <li>Μη χημικά ιατρικά αναλώσιμα υλικά μιας χρήσεως και αιματολογικά αναλώσιμα υλικά (83)
        <ul>
          <li>Διατάξεις παρακέντησης της φλέβας και εξαρτήματα δειγματοληψίας αίματος (11)
          <ul>
            <li>Σύριγγες (0)
            </li>
            <li>Ιατρικές βελόνες (9)
            <ul>
              <li>Βελόνες βιοψίας (0)
              </li>
              <li>Βελόνες επισκληριδίου αναισθησίας (0)
              </li>
              <li>Βελόνες ακτινολογικών διαδικασιών (0)
              </li>
              <li>Βελόνες παροχέτευσης συριγγίου (0)
              </li>
              <li>Βελόνες αιμοδιάλυσης (0)
              </li>
              <li>Βελόνες αμνιοπαρακέντησης (0)
              </li>
              <li>Βελόνες αναισθησίας (0)
              </li>
              <li>Αρτηριακές βελόνες (0)
              </li>
              <li>Σύριγγες με οπή εξαερισμού (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Κόφτες συρμάτων και νυστέρια· χειρουργικά γάντια (3)
          <ul>
            <li>Κόφτες συρμάτων και νυστέρια (1)
            <ul>
              <li>Ξέστρα και λάμες (0)
              </li>
            </ul>
            </li>
            <li>Χειρουργικά γάντια (0)
            </li>
          </ul>
          </li>
          <li>Ορθοπεδικά υλικά (7)
          <ul>
            <li>Ορθοπεδικά υποδήματα (0)
            </li>
            <li>Βοηθητικά βαδίσματος (0)
            </li>
            <li>Συσκευές καταγμάτων, καρφιά και πλάκες (0)
            </li>
            <li>Νάρθηκες (0)
            </li>
            <li>Χειρουργικά περιλαίμια (0)
            </li>
            <li>Δεκανίκια (0)
            </li>
            <li>Τεχνητές αρθρώσεις (0)
            </li>
          </ul>
          </li>
          <li>Αναλώσιμα αιματολογικά προϊόντα (8)
          <ul>
            <li>Ζωικό αίμα (0)
            </li>
            <li>Πηκτικοί παράγοντες αίματος (0)
            </li>
            <li>Ηπαρίνη (0)
            </li>
            <li>Ανθρώπινα όργανα (0)
            </li>
            <li>Προϊόντα αίματος (0)
            </li>
            <li>Αλβουμίνη (0)
            </li>
            <li>Ανθρώπινο αίμα (0)
            </li>
            <li>Εκχυλίσματα πλάσματος (0)
            </li>
          </ul>
          </li>
          <li>Επίδεσμοι· αγκτήρες, ράμματα, υλικά απολίνωσης (19)
          <ul>
            <li>Αγκτήρες, ράμματα και υλικά ραμμάτων (8)
            <ul>
              <li>Υλικά για χειρουργικά ράμματα τραυμάτων (0)
              </li>
              <li>Ράμματα (0)
              </li>
              <li>Βελόνες για ραφές (0)
              </li>
              <li>Απορροφούμενα αιμοστατικά (0)
              </li>
              <li>Χειρουργικά ράμματα τραύματος (0)
              </li>
              <li>Δοχείο απορριμμάτων οξέων αντικειμένων (0)
              </li>
              <li>Υποθέματα αιχμηρών αντικειμένων (0)
              </li>
              <li>Χειρουργικά τσιμπιδάκια (0)
              </li>
            </ul>
            </li>
            <li>Επίδεσμοι (9)
            <ul>
              <li>Υδρόφιλο βαμβάκι (0)
              </li>
              <li>Αποστειρωμένες κομπρέσσες (0)
              </li>
              <li>Αυτοκόλλητοι επίδεσμοι (0)
              </li>
              <li>Ιατρικό βαμβάκι (0)
              </li>
              <li>Ταινία επιδέσμου (0)
              </li>
              <li>Επίδεσμοι ατομικά συσκευασμένοι (0)
              </li>
              <li>Ιατρική γάζα (0)
              </li>
              <li>Λευκοπλάστης με γάζα (0)
              </li>
              <li>Χειρουργικές γάζες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Συλλέκτες και σάκοι συλλογής, παροχέτευσης και εξοπλισμοί τους (15)
          <ul>
            <li>Σάκοι συλλογής υγρών (3)
            <ul>
              <li>Σάκοι αίματος (0)
              </li>
              <li>Σάκοι για ούρα (0)
              </li>
              <li>Σάκοι πλάσματος (0)
              </li>
            </ul>
            </li>
            <li>Ηθμοί διήθησης του πλάσματος του αίματος (0)
            </li>
            <li>Παροχέτευση (2)
            <ul>
              <li>Εξαρτήματα παροχέτευσης (0)
              </li>
              <li>Καθετήρες παροχέτευσης (0)
              </li>
            </ul>
            </li>
            <li>Ιατρικοί εξοπλισμοί (6)
            <ul>
              <li>Εξοπλισμοί διάγνωσης (0)
              </li>
              <li>Κυτία πρώτων βοηθειών (0)
              </li>
              <li>Εξοπλισμοί πρόληψης του AIDS (0)
              </li>
              <li>Εξοπλισμοί ακράτειας (0)
              </li>
              <li>Εξοπλισμοί χορήγησης φαρμάκων με ορρό (0)
              </li>
              <li>Εξοπλισμοί χορήγησης των δόσεων του φαρμάκου (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Νυστέρια αιμοληψίας (0)
          </li>
          <li>Καθετήρες (4)
          <ul>
            <li>Καθετήρες με μπαλονάκι (0)
            </li>
            <li>Εξαρτήματα καθετήρων (0)
            </li>
            <li>Διαστολέας (0)
            </li>
            <li>Σωληνίσκοι (0)
            </li>
          </ul>
          </li>
          <li>Αναλώσιμα οδοντιατρικά υλικά (7)
          <ul>
            <li>Αιμοστατικό για οδοντιατρική χρήση (0)
            </li>
            <li>Προϊόντα υγιεινής δοντιών (0)
            </li>
            <li>Υλικά σφραγίσματος δοντιών (0)
            </li>
            <li>Δόντια (2)
            <ul>
              <li>Τεχνητά δόντια πορσελάνης (0)
              </li>
              <li>Ακρυλικά δόντια (0)
              </li>
            </ul>
            </li>
            <li>Βασικό τσιμεντοκονίαμα δοντιών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Αναισθησία και ανάνηψη (9)
      <ul>
        <li>Διατάξεις αναισθησίας και ανάνηψης (5)
        <ul>
          <li>Σετ ή συσκευασίες επισκληριδίου αναισθησίας (0)
          </li>
          <li>Όργανα αναισθησίας (1)
          <ul>
            <li>Προσωπίδα αναισθησίας (0)
            </li>
          </ul>
          </li>
          <li>Διατάξεις ανάνηψης (1)
          <ul>
            <li>Προσωπίδα ανάνηψης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Συσκευές αναισθησίας και ανάνηψης (2)
        <ul>
          <li>Συσκευές ανάνηψης (0)
          </li>
          <li>Συσκευές αναισθησίας (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Λειτουργικιή υποστήριξη (39)
      <ul>
        <li>Διατάξεις καρδιακής υποστήριξης (9)
        <ul>
          <li>Διατάξεις καρδιοχειρουργικής (0)
          </li>
          <li>Διατάξεις καρδιακής τόνωσης (5)
          <ul>
            <li>Μέρη και εξαρτήματα για βηματοδότες (1)
            <ul>
              <li>Στήλες για βηματοδότες (0)
              </li>
            </ul>
            </li>
            <li>Βηματοδότες (0)
            </li>
            <li>Καρδιακές βαλβίδες (0)
            </li>
            <li>Καρδιακή κοιλία (0)
            </li>
          </ul>
          </li>
          <li>Σύστημα καρδιακής ακτινογραφίας (0)
          </li>
          <li>Απινιδωτής (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές ορθοπεδικής υποβοήθησης (3)
        <ul>
          <li>Τεχνητά μέλη ορθοπεδικής (0)
          </li>
          <li>Συσκευές οστεοσύνθεσης (0)
          </li>
          <li>Ορθοπεδικά μοσχεύματα (0)
          </li>
        </ul>
        </li>
        <li>Ακουστικές προθέσεις (4)
        <ul>
          <li>Τεχνητός λάρυγγας (0)
          </li>
          <li>Ωτολαρυγγολογικά μοσχεύματα (0)
          </li>
          <li>Κοχλιακά μοσχεύματα (0)
          </li>
          <li>Μέρη και εξαρτήματα βοηθημάτων ακοής (0)
          </li>
        </ul>
        </li>
        <li>Τεχνητά μέλη του σώματος (8)
        <ul>
          <li>Αγγειακές προθέσεις (0)
          </li>
          <li>Χειρουργικά μοσχεύματα (0)
          </li>
          <li>Τεχνητά μέρη της καρδιάς (0)
          </li>
          <li>Προθέσεις στήθους (2)
          <ul>
            <li>Εσωτερικές προθέσεις μαστού (0)
            </li>
            <li>Εξωτερικές προθέσεις μαστού (0)
            </li>
          </ul>
          </li>
          <li>Στεφανιαίες ενδοπροθέσεις (0)
          </li>
          <li>Τεχνητό μάτι (0)
          </li>
        </ul>
        </li>
        <li>Μονάδα εξωσωματικής κυκλοφορίας (2)
        <ul>
          <li>Συσκευές θέρμανσης αίματος και υγρών (0)
          </li>
          <li>Οξυγονωτής (0)
          </li>
        </ul>
        </li>
        <li>Διατάξεις νεφρικής υποστήριξης (7)
        <ul>
          <li>Συσκευές ατομικής παρακολούθησης της αιμοδιάλυσης (0)
          </li>
          <li>Συσκευή αιμοδιάλυσης για πολλούς ασθενείς (0)
          </li>
          <li>Αιμοδιάλυση (0)
          </li>
          <li>Αναλώσιμα υλικά θεραπείας νεφρών (2)
          <ul>
            <li>Νεφρικό υγρό (0)
            </li>
            <li>Αναλώσιμα υλικά διάλυσης νεφρών (0)
            </li>
          </ul>
          </li>
          <li>Ηθμός διάλυσης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Συσκευές ακτινοθεραπείας, μηχανοθεραπείας, ηλεκτροθεραπείας και κινησιοθεραπείας (28)
      <ul>
        <li>Σύστημα κλινικής χημείας (0)
        </li>
        <li>Συσκευές οξυγονοθεραπείας και υποβοήθησης της αναπνοής (9)
        <ul>
          <li>Θάλαμος συμπιέσεως (0)
          </li>
          <li>Μονάδα χορήγησης οξυγόνου (1)
          <ul>
            <li>Μονάδα οξυγονοθεραπείας (0)
            </li>
          </ul>
          </li>
          <li>Μάσκες ιατρικών αερίων (1)
          <ul>
            <li>Προσωπίδα οξυγόνου (0)
            </li>
          </ul>
          </li>
          <li>Αναπνευστικές συσκευές οξυγόνου (0)
          </li>
          <li>Φιάλη εμφύσησης για την οξυγονοθεραπεία (0)
          </li>
          <li>Σκηνή οξυγόνου (0)
          </li>
          <li>Ιατρικές αναπνευστικές συσκευές (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές για ψυχολογικές δοκιμές (0)
        </li>
        <li>Ηλεκτρικές, ηλεκτρομαγνητικές και μηχανικές θεραπείες (6)
        <ul>
          <li>Συσκευές ηλεκτροθεραπείας (1)
          <ul>
            <li>Συσκευές τόνωσης (0)
            </li>
          </ul>
          </li>
          <li>Ηλεκτρομαγνητική μονάδα (0)
          </li>
          <li>Υπεριώδης εξοπλισμός ιατρικής χρήσεως (0)
          </li>
          <li>Μονάδα μηχανικής θεραπείας (0)
          </li>
          <li>Υπερέρυθρος εξοπλισμός ιατρικής χρήσεως (0)
          </li>
        </ul>
        </li>
        <li>Κλίβανος (0)
        </li>
        <li>Λιθοτρίπτης (0)
        </li>
        <li>Συσκευές μηχανοθεραπείας (0)
        </li>
        <li>Συσκευές κινησιοθεραπείας (0)
        </li>
        <li>Συσκευές και εξαρτήματα για ακτινοθεραπεία (4)
        <ul>
          <li>Συσκευές για θεραπεία με ακτίνες Χ (0)
          </li>
          <li>Συσκευές για θεραπεία με ακτίνες γάμμα (0)
          </li>
          <li>Φασματογράφοι (0)
          </li>
          <li>Υλικά ακτινοθεραπείας (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εγχειριτική τεχνική (18)
      <ul>
        <li>Δερματολογικές διατάξεις (0)
        </li>
        <li>Ηλεκτροχειρουργική μονάδα (0)
        </li>
        <li>Συσκευές και όργανα χειρουργείου (2)
        <ul>
          <li>Εργαλεία χειρουργείου (0)
          </li>
          <li>Εξοπλισμός χειρουργείου (0)
          </li>
        </ul>
        </li>
        <li>Σκηνή ιατρικής χρήσεως (0)
        </li>
        <li>Κοιλιοσκοπική διάταξη (1)
        <ul>
          <li>Κολποσκόπιο (0)
          </li>
        </ul>
        </li>
        <li>Διατάξεις κρυοχειρουργικής και κρυοθεραπείας (0)
        </li>
        <li>Διατάξεις ενδοσκοπίας και ενδοχειρουργικής (1)
        <ul>
          <li>Ενδοσκόπια (0)
          </li>
        </ul>
        </li>
        <li>Χειρουργικά όργανα (5)
        <ul>
          <li>Χειρουργικά δοχεία (0)
          </li>
          <li>Χειρουργικό λέιζερ (0)
          </li>
          <li>Χειρουργικά συστήματα ανίχνευσης και ιχνηλάτησης (0)
          </li>
          <li>Χειρουργικά καλάθια (0)
          </li>
          <li>Χειρουργικοί δίσκοι (0)
          </li>
        </ul>
        </li>
        <li>Φωτισμός χειρουργείου (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός απεικόνισης ιατρικής, οδοντιατρικής και κτηνιατρικής χρήσης (34)
      <ul>
        <li>Εξοπλισμός απεικόνισης μαγνητικού συντονισμού (2)
        <ul>
          <li>Σπιρογράφοι μαγνητικού συντονισμού (1)
          <ul>
            <li>Σπινθηρογράφοι πυρηνικού μαγνητικού συντονισμού (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Συσκευές φασματοσκοπίας (0)
        </li>
        <li>Ακτινολογικά μηχανήματα (18)
        <ul>
          <li>Διαγνωστικό σύστημα ακτίνων Χ (0)
          </li>
          <li>Συσκευές ακτινογραφίας (5)
          <ul>
            <li>Συσκευές μαστογραφίας (0)
            </li>
            <li>Θερμογράφημα (0)
            </li>
            <li>Μονάδα μαγνητικού συντονισμού (0)
            </li>
            <li>Κάμερες ακτίνων γάμμα (0)
            </li>
            <li>Συσκευές μέτρησης οστικής πυκνότητας (0)
            </li>
          </ul>
          </li>
          <li>Ακτινολογικά εργαστήρια (0)
          </li>
          <li>Ακτινογραφία δοντιών (0)
          </li>
          <li>Τράπεζα ακτινογραφίας (0)
          </li>
          <li>Συσκευές ακτινοσκόπησης (0)
          </li>
          <li>Θάλαμος αγγειογραφίας (5)
          <ul>
            <li>Υλικά αγγειοπλαστικής (0)
            </li>
            <li>Υλικά αγγειογραφίας (0)
            </li>
            <li>Συσκευές αγγειογραφίας (1)
            <ul>
              <li>Συσκευές ψηφιακής αγγειογραφίας (0)
              </li>
            </ul>
            </li>
            <li>Συσκευές αγγειοπλαστικής (0)
            </li>
          </ul>
          </li>
          <li>Συσκευές εμφάνισης ακτινογραφιών (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές τομογραφίας (2)
        <ul>
          <li>Υπολογιστικοί τομογράφοι (0)
          </li>
          <li>Υπολογιστικοί αξονικοί τομογράφοι (CAT) (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ηχογραφίας, υπερηχογραφίας και doppler (7)
        <ul>
          <li>Σπινθηρογράφοι υπερήχων (4)
          <ul>
            <li>Ηχοκαρδιογράφοι (0)
            </li>
            <li>Εξοπλισμός ντόπλερ (0)
            </li>
            <li>Ηχοεγκεφαλογράφος (0)
            </li>
            <li>Έγχρωμο ντόπλερ (0)
            </li>
          </ul>
          </li>
          <li>Μονάδα υπερήχων (0)
          </li>
          <li>Υπερηχοκαρδιογράφος (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Συστήματα καταγραφής και ερευνητικές συσκευές (25)
      <ul>
        <li>Συσκευές και προμήθειες διαγνωστικής και ακτινοδιαγνωστικής (7)
        <ul>
          <li>Συσκευές διαγνωστικής (4)
          <ul>
            <li>Προμήθειες συσκευών διαγνωστικής (1)
            <ul>
              <li>Αντιδραστήρια σε ταινίες (0)
              </li>
            </ul>
            </li>
            <li>Συσκευές διαγνωστικής με υπερήχους (0)
            </li>
            <li>Συστήματα διαγνωστικής (0)
            </li>
          </ul>
          </li>
          <li>Συσκευές ακτινοδιαγνωστικής (1)
          <ul>
            <li>Υλικά ακτινοδιαγνωστικής (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Συσκευές στοματολογίας (0)
        </li>
        <li>Ιατρικά λέιζερ εκτός αυτών που προορίζονται για χρήση στη χειρουργική (0)
        </li>
        <li>Συσκευές ανοσολογικών αναλύσεων (0)
        </li>
        <li>Σύστημα μακροχρόνιας περιπατητικής καταγραφής (5)
        <ul>
          <li>Ηλεκτροεγκεφαλογράφημα (0)
          </li>
          <li>Ακουόμετρα (0)
          </li>
          <li>Συσκευές σπινθηρογραφήματος (0)
          </li>
          <li>Ηλεκτροκαρδιογράφημα (0)
          </li>
          <li>Ηλεκτρομυογράφημα (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ουρολογικών ερευνών (0)
        </li>
        <li>Καρδιοαγγειακές διατάξεις (5)
        <ul>
          <li>Διατάξεις ηλεκτροκαρδιογραφήματος (3)
          <ul>
            <li>Καρδιογράφοι (0)
            </li>
            <li>Σύστημα παρακολούθησης της καρδιάς (0)
            </li>
            <li>Καρδιοαγγειογραφικές συσκευές (0)
            </li>
          </ul>
          </li>
          <li>Πιεσόμετρο αρτηριακής πίεσης (0)
          </li>
        </ul>
        </li>
        <li>Οφθαλμολογικός εξοπλισμός (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εξοπλισμός και προμήθειες νεκροψίας και νεκροτομείου (67)
    <ul>
      <li>Έπιπλα αυτοψίας (8)
      <ul>
        <li>Σταθμοί ή εξαρτήματα κοπής ιστών για αυτοψίες (0)
        </li>
        <li>Νιπτήρες ή εξαρτήματα αυτοψίας (0)
        </li>
        <li>Τράπεζες ή εξαρτήματα νεκροψίας (0)
        </li>
        <li>Σταθμοί καθοδικού ρεύματος αέρα ή εξαρτήματά τους για αυτοψίες (0)
        </li>
        <li>Σταθμοί ή εξαρτήματα ταρίχευσης (0)
        </li>
        <li>Τράπεζες ή εξαρτήματα ανατομής ζώων για νεκροψίες (0)
        </li>
        <li>Τράπεζες ή εξαρτήματα αυτοψίας (1)
        <ul>
          <li>Τράπεζες αυτοψίας (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός και προμήθειες νεκροτομείου (5)
      <ul>
        <li>Στολές νεκροτομείου (0)
        </li>
        <li>Υλικά περιτυλίγματος για νεκροτομεία (0)
        </li>
        <li>Αναρροφητήρες νεκροτομείου (0)
        </li>
        <li>Σετ νεκροτομείου (0)
        </li>
        <li>Σύνθετα υλικά σκλήρυνσης για νεκροτομεία (0)
        </li>
      </ul>
      </li>
      <li>Όργανα και προμήθειες παθολογοανατομίας (14)
      <ul>
        <li>Τετράγωνα τεμάχια και υποθέματα ανατομής (0)
        </li>
        <li>Λεπίδες ή εξαρτήματα πριονιών για αυτοψίες (1)
        <ul>
          <li>Πριόνια αυτοψίας (0)
          </li>
        </ul>
        </li>
        <li>Θήκες για χειρουργικά εργαλεία ή εξαρτήματα νεκροψίας (0)
        </li>
        <li>Ψαλίδια αυτοψίας (0)
        </li>
        <li>Υποδοχές εργαλείων για χειρουργικά εργαλεία ή εξαρτήματα νεκροψίας (0)
        </li>
        <li>Φλεβικά οδηγά σύρματα για αυτοψίες (0)
        </li>
        <li>Σετ ανατομής για αυτοψίες (1)
        <ul>
          <li>Ψαλίδια ανατομής για αυτοψίες (0)
          </li>
        </ul>
        </li>
        <li>Αισθητήρες σφαιρών για αυτοψίες (0)
        </li>
        <li>Νήματα, βελόνες ή συνδετήρες τομής για νεκροψίες (3)
        <ul>
          <li>Συνδετήρες τομής για νεκροψίες (0)
          </li>
          <li>Βελόνες νεκροψίας (0)
          </li>
          <li>Νήματα νεκροψίας (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός και προμήθειες αυτοψίας (12)
      <ul>
        <li>Σετ έκτασης δέρματος πτωμάτων (0)
        </li>
        <li>Συλλέκτες σκόνης οστών (0)
        </li>
        <li>Διατάξεις ευθυγράμμισης δαχτύλων για νεκροψίες (0)
        </li>
        <li>Σάκοι μεταφοράς πτωμάτων (0)
        </li>
        <li>Ετικέτες ή βραχιόλια ταυτότητας για νεκροψίες (0)
        </li>
        <li>Αναρροφητήρες κενού ή σωλήνες συλλογής υγρών για αυτοψίες (0)
        </li>
        <li>Κιτ ανίχνευσης λοιμωδών νόσων για αυτοψίες (0)
        </li>
        <li>Πρωκτικά θερμόμετρα για νεκροψίες (0)
        </li>
        <li>Στηρίγματα κεφαλής, φορεία ή πλάστιγγες αυτοψίας (3)
        <ul>
          <li>Φορεία για αυτοψίες (0)
          </li>
          <li>Στηρίγματα κεφαλής για αυτοψίες (0)
          </li>
          <li>Πλάστιγγες για αυτοψίες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός και προμήθειες ταρίχευσης (8)
      <ul>
        <li>Υγρά ή χημικά μέσα επεξεργασίας για ταρίχευση (0)
        </li>
        <li>Νιπτήρες ή εξαρτήματα ταρίχευσης (0)
        </li>
        <li>Σωλήνες έγχυσης για ταρίχευση (0)
        </li>
        <li>Σετ ταρίχευσης (0)
        </li>
        <li>Καλύμματα οφθαλμών (0)
        </li>
        <li>Εγχυτήρες πλήρωσης σωματικών κοιλοτήτων για ταρίχευση (0)
        </li>
        <li>Βελόνες έγχυσης για ταρίχευση (0)
        </li>
        <li>Σωλήνες εκκένωσης φλεβών για ταρίχευση (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός και προμήθειες μεταφοράς και αποθήκευσης πτωμάτων (9)
      <ul>
        <li>Θάλαμοι ψύξης ή κατάψυξης για νεκροτομεία (0)
        </li>
        <li>Δίσκοι τραπεζών αυτοψίας (0)
        </li>
        <li>Αμαξίδια ψαλιδωτής ανύψωσης για μεταφορά πτωμάτων (0)
        </li>
        <li>Φορεία πτωμάτων (0)
        </li>
        <li>Αμαξίδια για νεκροτομεία (0)
        </li>
        <li>Δοχεία μεταφοράς πτωμάτων (0)
        </li>
        <li>Καταψύκτες-θάλαμοι για νεκροτομεία (0)
        </li>
        <li>Διατάξεις ανύψωσης ή μεταφοράς πτωμάτων (0)
        </li>
        <li>Ράφια αποθήκευσης πτωμάτων (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός και προμήθειες ιατροδικαστικής (4)
      <ul>
        <li>Υλικά λήψης δακτυλικών αποτυπωμάτων και αποτύπωσης για νεκροψίες (0)
        </li>
        <li>Σετ συλλογής βιολογικών στοιχείων (0)
        </li>
        <li>Αντισηπτικές προσωπίδες (0)
        </li>
        <li>Σετ ή προμήθειες ανίχνευσης αίματος για νεκροψίες (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Πετρελαϊκά προϊόντα, καύσιμα, ηλεκτρική και άλλες πηγές ενέργειας (93)
  <ul>
    <li className='top-level'>Ηλεκτρική, θερμαντική, ηλιακή και πυρηνική ενέργεια (16)
    <ul>
      <li>Ατμός, θερμό νερό και συναφή προϊόντα (4)
      <ul>
        <li>Ατμός (0)
        </li>
        <li>Κεντρική αστική θέρμανση (0)
        </li>
        <li>Θερμό νερό (0)
        </li>
        <li>Τηλεθέρμανση (0)
        </li>
      </ul>
      </li>
      <li>Ηλεκτρισμός (0)
      </li>
      <li>Ηλιακή ενέργεια (4)
      <ul>
        <li>Ηλιακά κάτοπτρα (2)
        <ul>
          <li>Ηλιακοί συλλέκττες για την παραγωγή θερμότητας (0)
          </li>
          <li>Ηλιακά φωτοβολταϊκά στοιχεία (0)
          </li>
        </ul>
        </li>
        <li>Ηλιακές εγκαταστάσεις (0)
        </li>
      </ul>
      </li>
      <li>Πυρηνικά καύσιμα (4)
      <ul>
        <li>Ουράνιο (0)
        </li>
        <li>Ραδιενεργά υλικά (0)
        </li>
        <li>Ραδιοϊσότοπα (0)
        </li>
        <li>Πλουτώνιο (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Καύσιμα (41)
    <ul>
      <li>Πετρέλαιο και αποστάγματα (18)
      <ul>
        <li>Υγροποιημένο αέριο πετρελαίου (υγραέριο) (0)
        </li>
        <li>Πετρέλαιο εξωτερικής καύσης (2)
        <ul>
          <li>Πετρέλαιο θέρμανσης (1)
          <ul>
            <li>Καύσιμα έλαια μικρής περιεκτικότητας σε θείο (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Ακάθαρτο πετρέλαιο (7)
        <ul>
          <li>Πετρέλαιο ντίζελ (0)
          </li>
          <li>Καύσιμο πετρελαιοκινητήρων ντίζελ (5)
          <ul>
            <li>Καύσιμα ντίζελ (EN 590) (0)
            </li>
            <li>Βιοντίζελ (2)
            <ul>
              <li>Βιοντίζελ (B20) (0)
              </li>
              <li>Βιοντίζελ (B100) (0)
              </li>
            </ul>
            </li>
            <li>Καύσιμα ντίζελ (0,2) (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Κηροζίνη αεροσκαφών (1)
        <ul>
          <li>Καύσιμα αεριωθουμένων τύπου κηροζίνης (0)
          </li>
        </ul>
        </li>
        <li>Βενζίνη (3)
        <ul>
          <li>Μείγμα βενζίνης με αιθανόλη (0)
          </li>
          <li>Μολυβδούχος βενζίνη (0)
          </li>
          <li>Αμόλυβδη βενζίνη (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Αέρια καύσιμα (9)
      <ul>
        <li>Φυσικό αέριο (0)
        </li>
        <li>Προπάνιο και βουτάνιο (4)
        <ul>
          <li>Αέριο προπάνιο (1)
          <ul>
            <li>Υγροποιημένο προπάνιο (0)
            </li>
          </ul>
          </li>
          <li>Αέριο βουτάνιο (1)
          <ul>
            <li>Υγροποιημένο βουτάνιο (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Φωταέριο, αέριο μέσω αγωγών ή παρόμοια αέρια (2)
        <ul>
          <li>Αέριο μέσω αγωγών (0)
          </li>
          <li>Φωταέριο ή παρόμοια αέρια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Στερεά καύσιμα (11)
      <ul>
        <li>Λιγνίτης και τύρφη (2)
        <ul>
          <li>Τύρφη (0)
          </li>
          <li>Λιγνίτης (0)
          </li>
        </ul>
        </li>
        <li>Άνθρακας και καύσιμα με βάση τον άνθρακα (6)
        <ul>
          <li>Φυσικά καύσιμα (0)
          </li>
          <li>Γαιάνθρακας (0)
          </li>
          <li>Καύσιμα με βάση τον άνθρακα (2)
          <ul>
            <li>Λιθάνθρακας (0)
            </li>
            <li>Μπρικέτες (0)
            </li>
          </ul>
          </li>
          <li>Καύσιμα με βάση το ξύλο (0)
          </li>
        </ul>
        </li>
        <li>Οπτάνθρακας (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Πετρέλαιο, άνθρακας και πετρελαιοειδή (33)
    <ul>
      <li>Πετρέλαιο (αργό) (0)
      </li>
      <li>Πετρελαϊκά προϊόντα και προϊόντα άνθρακα (3)
      <ul>
        <li>Ασφαλτούχο ή σχιστολιθικό ορυκτέλαιο (0)
        </li>
        <li>Προϊόντα με βάση τον άνθρακα (1)
        <ul>
          <li>Πετρέλαιο από απόσταξη πισσανθράκων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Λιπαντικά παρασκευάσματα (19)
      <ul>
        <li>Λιπαντικά έλαια και λιπαντικά μέσα (18)
        <ul>
          <li>Λιπαντικά συστημάτων υποπολλαπλασιασμού (0)
          </li>
          <li>Λιπαντικά υδραυλικών συστημάτων και άλλων χρήσεων (5)
          <ul>
            <li>Υγρά φρένων (0)
            </li>
            <li>Λιπαντικά αφαίρεσης καλουπιών (0)
            </li>
            <li>Ηλεκτρομονωτικά έλαια (0)
            </li>
            <li>Υγρά υδραυλικών συστημάτων (0)
            </li>
            <li>Αντιδιαβρωτικά έλαια (0)
            </li>
          </ul>
          </li>
          <li>Λιπαντικά συμπιεστών (0)
          </li>
          <li>Λιπαντικά κιβωτίων οδοντωτών τροχών (0)
          </li>
          <li>Λιπαντικά στροβίλων (0)
          </li>
          <li>Πετρελαϊκά έλαια και παρασκευάσματα (2)
          <ul>
            <li>Ελαφρά έλαια (0)
            </li>
            <li>Ορυκτέλαια (0)
            </li>
          </ul>
          </li>
          <li>Λιπαντικά έλαια συστημάτων έλξης (0)
          </li>
          <li>Έλαια κινητήρων (0)
          </li>
          <li>Λευκά έλαια και παραφινέλαια (2)
          <ul>
            <li>Παραφινέλαια (0)
            </li>
            <li>Λευκά έλαια (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Βαζελίνη, κεριά και ειδικά αποστάγματα (7)
      <ul>
        <li>Ειδικά αποστάγματα (1)
        <ul>
          <li>Πετρελαϊκός αιθέρας (White spirit) (0)
          </li>
        </ul>
        </li>
        <li>Βαζελίνη και κηροί πετρελαίου (4)
        <ul>
          <li>Κηρός παραφίνης (0)
          </li>
          <li>Βαζελίνη (0)
          </li>
          <li>Κατάλοιπα πετρελαίου (0)
          </li>
          <li>Κηρός πετρελαίου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Έπιπλα (όπου περιλαμβάνονται έπιπλα γραφείου), επιπλώσεις, οικιακές συσκευές (εκτός από συσκευές φωτισμού) και προϊόντα καθαρισμού (467)
  <ul>
    <li className='top-level'>Οικιακές συσκευές (76)
    <ul>
      <li>Μη ηλεκτρικές οικιακές συσκευές (14)
      <ul>
        <li>Μέρη θερμαστρών, συσκευών μαγειρέματος, συσκευών αναθέρμανσης φαγητών και οικιακών συσκευών (3)
        <ul>
          <li>Μέρη συσκευών αναθέρμανσης φαγητών (0)
          </li>
          <li>Μέρη συσκευών μαγειρέματος (0)
          </li>
          <li>Μέρη θερμαστρών (0)
          </li>
        </ul>
        </li>
        <li>Οικιακός εξοπλισμός μαγειρέματος ή θέρμανσης (9)
        <ul>
          <li>Μη ηλεκτρικά αερόθερμα ή διανεμητές θερμού αέρα (3)
          <ul>
            <li>Αερόθερμα (0)
            </li>
            <li>Στεγνωτήρες αέρα (1)
            <ul>
              <li>Στεγνωτήρες πεπιεσμένου αέρα (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Οικιακές συσκευές μαγειρέματος (0)
          </li>
          <li>Ψυγεία που λειτουργούν με αέριο (0)
          </li>
          <li>Μη ηλεκτρικοί θερμαντήρες νερού, στιγμιαίοι ή με θερμοσυσσώρευση (2)
          <ul>
            <li>Συσκευές αερίου (1)
            <ul>
              <li>Θερμαντήρες αερίου (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ηλεκτρικές οικιακές συσκευές (60)
      <ul>
        <li>Οικιακές ηλεκτρικές συσκευές καθαρισμού· σίδερα σιδερώματος (12)
        <ul>
          <li>Ηλεκτρικά σίδερα μαλλιών (1)
          <ul>
            <li>Σίδερα ατμού (0)
            </li>
          </ul>
          </li>
          <li>Πλυντήρια πιάτων (0)
          </li>
          <li>Πλυντήρια και στεγνωτήρια ρούχων (2)
          <ul>
            <li>Πλυντήρια/στεγνωτήρια (1)
            <ul>
              <li>Μονάδες στεγνώματος και σιδερώματος (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Μηχανές συντήρησης δαπέδων (4)
          <ul>
            <li>Ηλεκτρικές σκούπες (1)
            <ul>
              <li>Εξαρτήματα για ηλεκτρικές σκούπες (0)
              </li>
            </ul>
            </li>
            <li>Μηχανές καθαρισμού δαπέδων (0)
            </li>
            <li>Μηχανές στίλβωσης δαπέδων (0)
            </li>
          </ul>
          </li>
          <li>Συμπιεστές απορριμμάτων (0)
          </li>
        </ul>
        </li>
        <li>Θερμαντήρες νερού και θέρμανση κτιρίων· εξοπλισμός εγκαταστάσεων υγιεινής (7)
        <ul>
          <li>Ηλεκτρικοί θερμαντήρες νερού, στιγμιαίας θέρμανσης ή θερμοσυσσώρευσης, και θερμαντήρες εμβάπτισης (0)
          </li>
          <li>Εξοπλισμός θέρμανσης (4)
          <ul>
            <li>Ηλεκτρικές συσκευές θέρμανσης εδάφους (0)
            </li>
            <li>Ηλεκτρικές συσκευές θέρμανσης χώρων (0)
            </li>
            <li>Ηλεκτρικές θερμαντικές αντιστάσεις (0)
            </li>
            <li>Εξοπλισμός κεντρικής θέρμανσης (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός εγκαταστάσεων υγιεινής (0)
          </li>
        </ul>
        </li>
        <li>Οικιακές ηλεκτρικές συσκευές για τη φροντίδα του σώματος (4)
        <ul>
          <li>Μηχανές κουρέματος (0)
          </li>
          <li>Συσκευές στεγνώματος χεριών (0)
          </li>
          <li>Συσκευές κομμωτηρίου (1)
          <ul>
            <li>Στεγνωτήρες μαλλιών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Ανεμιστήρες και αεριοσυλλέκτες ανακύκλωσης του αέρα (2)
        <ul>
          <li>Εξαεριστήρες (1)
          <ul>
            <li>Απορροφητήρες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Ανεμιστήρες και συσκευές κλιματισμού (2)
        <ul>
          <li>Συσκευές κλιματισμού (0)
          </li>
          <li>Ανεμιστήρες (0)
          </li>
        </ul>
        </li>
        <li>Οικιακές ηλεκτρικές συσκευές μαγειρικής (26)
        <ul>
          <li>Ψηστιέρες, συσκευές διατήρησης θερμών φαγητών, θερμαντικές πλάκες και δακτύλιοι (4)
          <ul>
            <li>Ψηστιέρες (0)
            </li>
            <li>Συσκευές διατήρησης θερμών φαγητών (0)
            </li>
            <li>Θερμαντικές πλάκες (0)
            </li>
            <li>Θερμαντικοί δακτύλιοι (0)
            </li>
          </ul>
          </li>
          <li>Ανοιχτήρια κονσερβών (0)
          </li>
          <li>Ψυγεία και καταψύκτες (7)
          <ul>
            <li>Ψυγεία-καταψύκτες (0)
            </li>
            <li>Ψυγεία (0)
            </li>
            <li>Καταψύκτες (4)
            <ul>
              <li>Βιομηχανικοί καταψύκτες (0)
              </li>
              <li>Καταψύκτες τύπου κασέλας (0)
              </li>
              <li>Καταψύκτες οικιακής χρήσης (0)
              </li>
              <li>Καταψύκτες όρθιου τύπου (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Συσκευές κατεργασίας τροφίμων (2)
          <ul>
            <li>Μπλέντερ τροφίμων (1)
            <ul>
              <li>Αναμεικτήρες τροφίμων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Ηλεκτροθερμικές συσκευές (8)
          <ul>
            <li>Συσκευές αναθέρμανσης φαγητών (0)
            </li>
            <li>Ηλεκτρικές φρυγανιέρες (0)
            </li>
            <li>Ηλεκτρικές τσαγιέρες (0)
            </li>
            <li>Μηχανήματα για βάφλες (0)
            </li>
            <li>Φούρνοι (2)
            <ul>
              <li>Ηλεκτρικοί φούρνοι (0)
              </li>
              <li>Φούρνοι μικροκυμάτων (0)
              </li>
            </ul>
            </li>
            <li>Ηλεκτρικές καφετιέρες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Μέρη ηλεκτρικών οικιακών συσκευών (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ποικίλος εξοπλισμός (14)
    <ul>
      <li>Εξοπλισμός αποχετευτικών έργων (0)
      </li>
      <li>Εγκαταστάσεις ύδατος (0)
      </li>
      <li>Εξοπλισμός τροφοδοσίας (7)
      <ul>
        <li>Εξοπλισμός επαγγελματικού μαγειρείου (0)
        </li>
        <li>Ελαφρύς εξοπλισμός τροφοδοσίας (0)
        </li>
        <li>Εξοπλισμός εστιατορίου (0)
        </li>
        <li>Εξοπλισμός παρασκευής φαγητού (2)
        <ul>
          <li>Συσκευές τεμαχισμού κρέατος σε φέτες (0)
          </li>
          <li>Εξοπλισμός κυλικείου (0)
          </li>
        </ul>
        </li>
        <li>Ξενοδοχειακός εξοπλισμός (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός δικτύου φυσικού αερίου (1)
      <ul>
        <li>Εξολισμός πίεσης φυσικού αερίου (0)
        </li>
      </ul>
      </li>
      <li>Εξοπλισμός σφράγισης (0)
      </li>
      <li>Εξοπλισμός απολύμανσης (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υφαντουργικά είδη (110)
    <ul>
      <li>Έτοιμα είδη κλωστοϋφαντουργίας (26)
      <ul>
        <li>Αλεξίπτωτα (2)
        <ul>
          <li>Κατευθυνόμενα αλεξίπτωτα (0)
          </li>
          <li>Στροφόπτωτα (0)
          </li>
        </ul>
        </li>
        <li>Μουσαμάδες, ιστία για σκάφη, ιστιοσανίδες ή ιστιοφόρα οχήματα, τέντες, σκίαστρα, σκηνές και είδη κατασκήνωσης (12)
        <ul>
          <li>Μουσαμάδες, τέντες και σκίαστρα (3)
          <ul>
            <li>Τέντες (0)
            </li>
            <li>Μουσαμάδες (0)
            </li>
            <li>Σκίαστρα (0)
            </li>
          </ul>
          </li>
          <li>Ιστία (0)
          </li>
          <li>Καλύμματα παραλλαγής (0)
          </li>
          <li>Υφασμάτινα είδη κατασκήνωσης (5)
          <ul>
            <li>Υπνόσακοι (1)
            <ul>
              <li>Υπνόσακοι γεμισμένοι με φτερά ή πούπουλα (0)
              </li>
            </ul>
            </li>
            <li>Φουσκωτά στρώματα (0)
            </li>
            <li>Σκηνές (0)
            </li>
            <li>Πτυσσόμενα κρεβάτια (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Διάφορα έτοιμα είδη υφαντουργίας (9)
        <ul>
          <li>Στοιχεία φίλτρου από ύφασμα (0)
          </li>
          <li>Κουβέρτες πυρόσβεσης (0)
          </li>
          <li>Πανιά καθαρισμού (1)
          <ul>
            <li>Πανιά στίλβωσης (0)
            </li>
          </ul>
          </li>
          <li>Κουνουπιέρες (0)
          </li>
          <li>Σωσίβια (0)
          </li>
          <li>Ξεσκονόπανα (0)
          </li>
          <li>Πετσέτες πιάτων (0)
          </li>
          <li>Ιμάντες διάσωσης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Διάφορα είδη υφαντουργίας (23)
      <ul>
        <li>Υφασμάτινα παρεμβύσματα, νήματα, υφάσματα και είδη για τεχνική χρήση (9)
        <ul>
          <li>Υφαντουργικά προϊόντα καπιτονέ (0)
          </li>
          <li>Επιμεταλλωμένα νήματα (0)
          </li>
          <li>Υφαντά υφάσματα από μεταλλική κλωστή (0)
          </li>
          <li>Προϊόντα υφαντουργίας για τεχνικές χρήσεις (3)
          <ul>
            <li>Αγωγοί αερισμού (0)
            </li>
            <li>Μεταφορικοί ιμάντες από ύφασμα (0)
            </li>
            <li>Εύκαμπτοι σωλήνες από υφαντικές ύλες (0)
            </li>
          </ul>
          </li>
          <li>Υφαντικά υλικά παραγεμίσματος (0)
          </li>
          <li>Εμποτισμένα, επενδυμένα ή επικαλυμμένα υφαντά υφάσματα (0)
          </li>
        </ul>
        </li>
        <li>Τούλια, δαντέλες, στενά υφαντά υφάσματα, διακοσμητικές γαρνιτούρες και κεντήματα (11)
        <ul>
          <li>Στενά υφαντά υφάσματα και διακοσμητικές γαρνιτούρες (9)
          <ul>
            <li>Υφαντή ταινία (0)
            </li>
            <li>Κορδέλες (0)
            </li>
            <li>Ετικέτες και διακριτικά από υφαντικές ύλες (3)
            <ul>
              <li>Εμβλήματα (0)
              </li>
              <li>Υφαντές ετικέτες (0)
              </li>
              <li>Υφασμάτινα διακριτικά σήματα (0)
              </li>
            </ul>
            </li>
            <li>Εξαρτήματα στολισμού (2)
            <ul>
              <li>Σειρήτια (0)
              </li>
              <li>Επωμίδες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Δικτυωτά υφάσματα (0)
          </li>
        </ul>
        </li>
        <li>Πίλημα (0)
        </li>
      </ul>
      </li>
      <li>Είδη κλωστοϋφαντουργίας οικιακής χρήσης (36)
      <ul>
        <li>Κουρτίνες, διακοσμητικά υφάσματα, γύροι κρεβατιών και σκίαστρα από ύφασμα (9)
        <ul>
          <li>Γύροι κρεβατιών (0)
          </li>
          <li>Σκίαστρα (4)
          <ul>
            <li>Σκίαστρα από ύφασμα (0)
            </li>
            <li>Σκίαστρα καθέτου διάταξης λωρίδων (0)
            </li>
            <li>Περσίδες (0)
            </li>
            <li>Σκίαστρα εσωτερικού χώρου (0)
            </li>
          </ul>
          </li>
          <li>Διακοσμητικά υφάσματα (0)
          </li>
          <li>Κουρτίνες (1)
          <ul>
            <li>Παραπετάσματα για την προστασία από τον καπνό (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Είδη επίπλωσης (3)
        <ul>
          <li>Ελαφριά επίπλωση (2)
          <ul>
            <li>Μαξιλάρια καναπέ (0)
            </li>
            <li>Μαξιλάρια (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Τραπεζομάντιλα και πετσέτες φαγητού (2)
        <ul>
          <li>Τραπεζομάντιλα (0)
          </li>
          <li>Πετσέτες τραπεζιού (0)
          </li>
        </ul>
        </li>
        <li>Πετσέτες μπάνιου και κουζίνας (5)
        <ul>
          <li>Πετσέτες σε ρολό (0)
          </li>
          <li>Πετσέτες τσαγιού (0)
          </li>
          <li>Αυτόματοι διανομείς πετσετών (0)
          </li>
          <li>Πετσέτες (0)
          </li>
          <li>Πετσέτες προσώπου (0)
          </li>
        </ul>
        </li>
        <li>Κλινοσκεπάσματα (6)
        <ul>
          <li>Καλύμματα στρωμάτων (0)
          </li>
          <li>Σεντόνια (0)
          </li>
          <li>Καλύμματα παπλωμάτων (0)
          </li>
          <li>Πουπουλένιο πάπλωμα (0)
          </li>
          <li>Θήκες για μαξιλάρες (0)
          </li>
          <li>Μαξιλαροθήκες (0)
          </li>
        </ul>
        </li>
        <li>Υφασμάτινα είδη νοσοκομείου (2)
        <ul>
          <li>Σεντόνια χειρουργείου (0)
          </li>
          <li>Υφασμάτινα είδη χειρουργείου (0)
          </li>
        </ul>
        </li>
        <li>Κουβέρτες και κουβέρτες ταξιδίου (2)
        <ul>
          <li>Κουβέρτες (0)
          </li>
          <li>Ταξιδιωτικές κουβέρτες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Διάφορα σχοινιά, πλεξούδες, σπάγκοι και πλέγματα (10)
      <ul>
        <li>Ράκη (0)
        </li>
        <li>Σκοινιά, πλεξούδες, σπάγγοι και δίχτυα (8)
        <ul>
          <li>Δίχτυα από υφαντικά υλικά (2)
          <ul>
            <li>Δίχτυα με κόμπους (0)
            </li>
            <li>Αρτάνες (0)
            </li>
          </ul>
          </li>
          <li>Σπάγκοι, σχοινιά και πλεξούδες (4)
          <ul>
            <li>Πλεξούδες (0)
            </li>
            <li>Σκοινιά (0)
            </li>
            <li>Στρεπτά νήματα (0)
            </li>
            <li>Σπάγκοι (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Χαλιά, ψάθες και στενόμακρα καλύμματα δαπέδων (9)
      <ul>
        <li>Στενόμακροι τάπητες (0)
        </li>
        <li>Χαλάκια (ψάθες) (0)
        </li>
        <li>Χαλιά (5)
        <ul>
          <li>Φουντωτά χαλιά (1)
          <ul>
            <li>Πλακίδια μοκέτας (0)
            </li>
          </ul>
          </li>
          <li>Υφαντά χαλιά (0)
          </li>
          <li>Χαλιά με κόμπους (0)
          </li>
          <li>Υφασμάτινοι τάπητες δαπέδου (0)
          </li>
        </ul>
        </li>
        <li>Βιομηχανικοί τάπητες (0)
        </li>
      </ul>
      </li>
      <li>Μη υφαντά είδη (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Είδη καθαρισμού και στίλβωσης (34)
    <ul>
      <li>Επιφανειοδραστικές οργανικές ουσίες (2)
      <ul>
        <li>Καθαριστικά με αμμωνία (0)
        </li>
        <li>Καυστικά καθαριστικά (0)
        </li>
      </ul>
      </li>
      <li>Ευώδη παρασκευάσματα και κηροί (12)
      <ul>
        <li>Στιλβωτικές ουσίες και κρέμες (5)
        <ul>
          <li>Στεγανωτικά (0)
          </li>
          <li>Υλικά σφουγγαρίσματος (0)
          </li>
          <li>Στιλβωτικά δαπέδων (0)
          </li>
          <li>Στιλβωτικοί κηροί (0)
          </li>
          <li>Στιλβωτικά υποδημάτων (0)
          </li>
        </ul>
        </li>
        <li>Πολτοί και σκόνες λείανσης (0)
        </li>
        <li>Παρασκευάσματα για αρωματισμό και αποσμητικά χώρου (4)
        <ul>
          <li>Αποσμητικά χώρου τύπου Air freshner (1)
          <ul>
            <li>Διανομείς αποσμητικών χώρου τύπου Air freshner (0)
            </li>
          </ul>
          </li>
          <li>Αποσμητικά χώρου (0)
          </li>
          <li>Εξυγιαντές χώρου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Προϊόντα καθαρισμού (17)
      <ul>
        <li>Προϊόντα για το πλύσιμο των πιάτων (1)
        <ul>
          <li>Απορρυπαντικό σε μορφή σκόνης για το πλύσιμο των πιάτων (0)
          </li>
        </ul>
        </li>
        <li>Προϊόντα για το ξεσκόνισμα (0)
        </li>
        <li>Παρασκευάσματα πλυσίματος (12)
        <ul>
          <li>Καθαριστικά δαπέδου (0)
          </li>
          <li>Καθαριστικά οθόνης (0)
          </li>
          <li>Καθαριστικά αυτοκινήτου (0)
          </li>
          <li>Αυτόματοι διανομείς σαπουνιού (0)
          </li>
          <li>Απορρυπαντικά (5)
          <ul>
            <li>Μέσα απολίπανσης (0)
            </li>
            <li>Μέσα χώνεψης λιπαρών ουσιών (0)
            </li>
            <li>Ενώσεις καθαρισμού (0)
            </li>
            <li>Απορρυπαντικά πλυντηρίου πιάτων (0)
            </li>
            <li>Διαλύματα έκπλυσης (0)
            </li>
          </ul>
          </li>
          <li>Διαλυτικά ελαίων (0)
          </li>
          <li>Καθαριστικά τουαλέτας (0)
          </li>
        </ul>
        </li>
        <li>Διαλύματα καθαρισμού κοσμημάτων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Έπιπλα (100)
    <ul>
      <li>Καθίσματα, καρέκλες και συναφή μέρη και εξαρτήματα (16)
      <ul>
        <li>Καρέκλες (1)
        <ul>
          <li>Καρέκλες τραπεζαρίας (0)
          </li>
        </ul>
        </li>
        <li>Ποικίλα καθίσματα και καρέκλες (7)
        <ul>
          <li>Καναπέδες (0)
          </li>
          <li>Υποπόδια (0)
          </li>
          <li>Ξαπλώστρες (0)
          </li>
          <li>Πάγκοι· στασίδια (0)
          </li>
          <li>Πολυθρόνες (0)
          </li>
          <li>Σκαμνιά (0)
          </li>
          <li>Πάγκοι-καθίσματα (0)
          </li>
        </ul>
        </li>
        <li>Μέρη καθισμάτων (1)
        <ul>
          <li>Είδη ταπετσαρίας (0)
          </li>
        </ul>
        </li>
        <li>Καθίσματα (3)
        <ul>
          <li>Καθίσματα θεάτρου (0)
          </li>
          <li>Περιστρεφόμενα καθίσματα (0)
          </li>
          <li>Εκτοξευόμενα καθίσματα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Σχολικά έπιπλα (5)
      <ul>
        <li>Εποπτικά μέσα (3)
        <ul>
          <li>Εξοπλισμός διδασκαλίας (1)
          <ul>
            <li>Διδακτικό υλικό (0)
            </li>
          </ul>
          </li>
          <li>Βοηθήματα και υλικό κατάρτισης (0)
          </li>
        </ul>
        </li>
        <li>Έπιπλα παιδικού σταθμού (0)
        </li>
      </ul>
      </li>
      <li>Έπιπλα καταστημάτων (5)
      <ul>
        <li>Βιτρίνες επίδειξης (0)
        </li>
        <li>Μονάδες αποθήκευσης (0)
        </li>
        <li>Πάγκοι πώλησης (1)
        <ul>
          <li>Καντίνες (0)
          </li>
        </ul>
        </li>
        <li>Επιγραφές καταστημάτων (0)
        </li>
      </ul>
      </li>
      <li>Έπιπλα εργαστηρίου (1)
      <ul>
        <li>Πάγκοι εργαστηρίου (0)
        </li>
      </ul>
      </li>
      <li>Οικιακά έπιπλα (26)
      <ul>
        <li>Έπιπλα κήπου (0)
        </li>
        <li>Έπιπλα κρεβατοκάμαρας, τραπεζαρίας και καθιστικού (16)
        <ul>
          <li>Έπιπλα υπνοδωματίου (11)
          <ul>
            <li>Κρεβάτια, στρώματα και ειδικά υφασμάτινα είδη υπνοδωματίου (6)
            <ul>
              <li>Στρώματα (0)
              </li>
              <li>Ειδικά υφάσματα επιπλώσεων (0)
              </li>
              <li>Ηλεκτρικές κουβέρτες (0)
              </li>
              <li>Παιδικά κρεβάτια (0)
              </li>
              <li>Φύλλα από ελαστικό (0)
              </li>
              <li>Σομιέδες (0)
              </li>
            </ul>
            </li>
            <li>Έπιπλα υπνοδωματίου εκτός από κρεβάτια και κλινοστρωμνές (3)
            <ul>
              <li>Λινοθήκες (0)
              </li>
              <li>Κομοδίνα (0)
              </li>
              <li>Ιματιοθήκες (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Έπιπλα καθιστικού (1)
          <ul>
            <li>Τραπέζια σαλονιού (0)
            </li>
          </ul>
          </li>
          <li>Έπιπλα τραπεζαρίας (1)
          <ul>
            <li>Τραπέζια φαγητού (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Έπιπλα μπάνιου (0)
        </li>
        <li>Σταθερά εξαρτήματα για οιναποθήκες (0)
        </li>
        <li>Έπιπλα κουζίνας (5)
        <ul>
          <li>Εξαεριστήρες καπναερίων (0)
          </li>
          <li>Εντοιχισμένες κουζίνες (0)
          </li>
          <li>Ερμάρια και ράφια (0)
          </li>
          <li>Επιφάνειες εργασίας (0)
          </li>
          <li>Ερμάρια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Έπιπλα γραφείων (15)
      <ul>
        <li>Τραπέζια ταξινόμησης (1)
        <ul>
          <li>Πλαίσια ταξινόμησης (0)
          </li>
        </ul>
        </li>
        <li>Κρεμάστρες (0)
        </li>
        <li>Συστήματα αρχειοθέτησης (5)
        <ul>
          <li>Καροτσάκια γραφείου (0)
          </li>
          <li>Καρτελοθήκες (0)
          </li>
          <li>Αρχειοθήκες (0)
          </li>
          <li>Συστήματα αναρτημένων αρχείων (0)
          </li>
          <li>Περιστρεφόμενα συστήματα αρχειοθέτησης (0)
          </li>
        </ul>
        </li>
        <li>Ράφια γραφείου (1)
        <ul>
          <li>Ράφια αρχειοθέτησης (0)
          </li>
        </ul>
        </li>
        <li>Αποσκληρυντές νερού (0)
        </li>
        <li>Έπιπλα για ηλεκτρονικούς υπολογιστές (1)
        <ul>
          <li>Τραπέζια για ηλεκτρονικούς υπολογιστές (0)
          </li>
        </ul>
        </li>
        <li>Μονάδες επίδειξης προϊόντων (0)
        </li>
      </ul>
      </li>
      <li>Διάφορα έπιπλα και εξοπλισμός (13)
      <ul>
        <li>Κινητά ράφια για βιβλία (0)
        </li>
        <li>Έπιπλα βιβλιοθήκης (1)
        <ul>
          <li>Εξοπλισμός βιβλιοθήκης (0)
          </li>
        </ul>
        </li>
        <li>Έπιπλα καθιστικού και χώρου υποδοχής (0)
        </li>
        <li>Διάφορα έπιπλα (3)
        <ul>
          <li>Πάγκοι εργασίας (0)
          </li>
          <li>Συστήματα ραφιών (0)
          </li>
          <li>Σύνθετα έπιπλα (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός εκθέσεων (1)
        <ul>
          <li>Περίπτερα εκθέσεων (0)
          </li>
        </ul>
        </li>
        <li>Μέρη επίπλων (0)
        </li>
        <li>Έπιπλα αίθουσας διασκέψεων (1)
        <ul>
          <li>Βιβλιοστάτες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Χαρτί ταπετσαρίας και άλλες επενδύσεις τοίχων (4)
      <ul>
        <li>Επενδύσεις τοίχων από χαρτί ή χαρτόνι (1)
        <ul>
          <li>Χαρτί ταπετσαρίας τοίχου (0)
          </li>
        </ul>
        </li>
        <li>Ταπετσαρίες τοίχων από ύφασμα (0)
        </li>
        <li>Καλύμματα δαπέδων από χαρτί ή χαρτόνι (0)
        </li>
      </ul>
      </li>
      <li>Τραπέζια, ντουλάπια, γραφεία και βιβλιοθήκες (6)
      <ul>
        <li>Ντουλάπια και βιβλιοθήκες (2)
        <ul>
          <li>Σκευοθήκες (0)
          </li>
          <li>Βιβλιοθήκες (0)
          </li>
        </ul>
        </li>
        <li>Γραφεία και τραπέζια (2)
        <ul>
          <li>Τραπέζια (0)
          </li>
          <li>Γραφεία (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Επιπλώσεις (127)
    <ul>
      <li>Εξοπλισμός κουζίνας, είδη οικιακής χρήσης και αναλώσιμα τροφοδοσίας (61)
      <ul>
        <li>Κουτάλια, πιρούνια (2)
        <ul>
          <li>Πιρούνια (0)
          </li>
          <li>Κουτάλια (0)
          </li>
        </ul>
        </li>
        <li>Βελόνες και δαχτυλήθρες ραψίματος και πλεξίματος (4)
        <ul>
          <li>Δαχτυλήθρες (0)
          </li>
          <li>Βελόνες ραψίματος ή πλεξίματος (2)
          <ul>
            <li>Βελόνες ραψίματος (0)
            </li>
            <li>Βελόνες πλεξίματος (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός μαγειρείου (20)
        <ul>
          <li>Σκεύη κουζίνας (12)
          <ul>
            <li>Φλυτζάνια και ποτήρια (3)
            <ul>
              <li>Φλυτζάνια (0)
              </li>
              <li>Κούπες (0)
              </li>
              <li>Ποτήρια (0)
              </li>
            </ul>
            </li>
            <li>Πιατοθήκες (0)
            </li>
            <li>Φιάλες εν κενώ· θερμό (0)
            </li>
            <li>Πιατικά (0)
            </li>
            <li>Σχάρες στεγνώματος (0)
            </li>
            <li>Κυτία φαγητού (0)
            </li>
            <li>Μαγειρικά σκεύη (0)
            </li>
            <li>Παγούρια (0)
            </li>
            <li>Δίσκοι σερβιρίσματος (0)
            </li>
          </ul>
          </li>
          <li>Είδη τραπεζαρίας (6)
          <ul>
            <li>Πιατέλες (0)
            </li>
            <li>Μικρά πιάτα (0)
            </li>
            <li>Πιάτα (0)
            </li>
            <li>Λευκοσιδηρά σκεύη συσσιτίου (0)
            </li>
            <li>Καράφες (0)
            </li>
            <li>Μπολ (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υλικά τροφοδοσίας (4)
        <ul>
          <li>Αναλώσιμα υλικά τροφοδοσίας (2)
          <ul>
            <li>Φλυτζάνια μιας χρήσης (0)
            </li>
            <li>Μαχαιροπίρουνα και πιάτα μιας χρήσης (0)
            </li>
          </ul>
          </li>
          <li>Δίσκοι φαγητού (0)
          </li>
        </ul>
        </li>
        <li>Αναπτήρες, είδη από εύφλεκτο υλικό, πυροτεχνήματα, σπίρτα, υγρά ή υγροποιημένα αέρια καύσιμα (10)
        <ul>
          <li>Σπίρτα (0)
          </li>
          <li>Αέριο καύσιμο για αναπτήρες (0)
          </li>
          <li>Μπουκάλια, πεπλατυσμένες φιάλες και φιαλίδια (3)
          <ul>
            <li>Πεπλατυσμένες φιάλες (0)
            </li>
            <li>Μπουκάλια (0)
            </li>
            <li>Φιαλίδια (0)
            </li>
          </ul>
          </li>
          <li>Αντικείμενα πυροτεχνικής (0)
          </li>
          <li>Αναπτήρες τσιγάρων (0)
          </li>
          <li>Κεριά (0)
          </li>
          <li>Πυροφορικά κράματα (0)
          </li>
        </ul>
        </li>
        <li>Σκούπες και βούρτσες και άλλα είδη διαφόρων τύπων (9)
        <ul>
          <li>Σκούπες (0)
          </li>
          <li>Βούρτσες (1)
          <ul>
            <li>Βούρτσες ελαιοχρωματιστών (0)
            </li>
          </ul>
          </li>
          <li>Σκούπες και βούρτσες και άλλα είδη για την οικιακή καθαριότητα (5)
          <ul>
            <li>Κάδοι (0)
            </li>
            <li>Σφουγγάρια (0)
            </li>
            <li>Βούρτσες αποχωρητηρίου (0)
            </li>
            <li>Κουβάδες (0)
            </li>
            <li>Φαράσια (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Νταμιτζάνες, μπουκάλια, φιάλες και μπομπίνες (5)
        <ul>
          <li>Πηνία ή μπομπίνες (0)
          </li>
          <li>Νταμιτζάνες και μπουκάλια (2)
          <ul>
            <li>Νταμιτζάνες (0)
            </li>
            <li>Μπουκάλια (0)
            </li>
          </ul>
          </li>
          <li>Κιβώτια για μουκάλια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Θρησκευτικά είδη (0)
      </li>
      <li>Μαχαιροπίρουνα (6)
      <ul>
        <li>Μαχαίρια και ψαλίδια (5)
        <ul>
          <li>Ψαλίδια (0)
          </li>
          <li>Μαχαίρια (3)
          <ul>
            <li>Επιτραπέζια μαχαίρια (0)
            </li>
            <li>Μαχαίρια πολλαπλών χρήσεων (0)
            </li>
            <li>Μαχαίρια κουζίνας (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Διάφορα είδη επίπλωσης (40)
      <ul>
        <li>Αγαλματίδια και άλλα είδη διακόσμησης· κορνίζες για φωτογραφίες ή πίνακες και καθρέπτες (10)
        <ul>
          <li>Αγαλματίδια (0)
          </li>
          <li>Διακοσμητικά είδη (0)
          </li>
          <li>Κορνίζες για πίνακες (0)
          </li>
          <li>Υδρόγειες σφαίρες (0)
          </li>
          <li>Κορνίζες για φωτογραφίες (0)
          </li>
          <li>Έπαθλα (0)
          </li>
          <li>Ενυδρεία (0)
          </li>
          <li>Ανθοδοχεία (0)
          </li>
          <li>Διάφορα είδη διακόσμησης (1)
          <ul>
            <li>Χριστουγεννιάτικα δέντρα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Συσκευές και εξοπλισμός για επίδειξη (1)
        <ul>
          <li>Προϊόντα πληροφόρησης και προβολής (0)
          </li>
        </ul>
        </li>
        <li>Σχολικές πλάκες ή πίνακες με επιφάνεια ή όργανα γραφής ή σχεδίασης (6)
        <ul>
          <li>Χάρακες (0)
          </li>
          <li>Πλάκες γραφής (0)
          </li>
          <li>Όργανα γραφής (0)
          </li>
          <li>Μαυροπίνακες (1)
          <ul>
            <li>Σβηστήρες για μαυροπίνακες (0)
            </li>
          </ul>
          </li>
          <li>Όργανα σχεδίασης (0)
          </li>
        </ul>
        </li>
        <li>Γυάλινα είδη (3)
        <ul>
          <li>Γυαλί ασφαλείας (0)
          </li>
          <li>Γυάλινες φύσιγγες (0)
          </li>
          <li>Γυάλινοι καθρέπτες (0)
          </li>
        </ul>
        </li>
        <li>Ομπρέλες βροχής και ηλίου· μπαστούνια περιπάτου και μπαστούνια-καθίσματα (5)
        <ul>
          <li>Μέρη, συμπληρώματα και εξαρτήματα ομπρελών βροχής ή ηλίου, μπαστουνιών περιπάτου και παρόμοιων ειδών (0)
          </li>
          <li>Μπαστούνια περιπάτου (0)
          </li>
          <li>Ομπρέλες βροχής (0)
          </li>
          <li>Ομπρέλες ηλίου (0)
          </li>
          <li>Μπαστούνια-καθίσματα (0)
          </li>
        </ul>
        </li>
        <li>Είδη καθαριστηρίου (0)
        </li>
        <li>Προμήθειες στρατοπέδων (0)
        </li>
        <li>Τεχνητά προϊόντα (5)
        <ul>
          <li>Τεχνητή χλόη (0)
          </li>
          <li>Απομιμήσεις κοσμημάτων (0)
          </li>
          <li>Τεχνητά φρούτα (0)
          </li>
          <li>Τεχνητά άνθη (0)
          </li>
          <li>Τεχνητός χορτοτάπητας (0)
          </li>
        </ul>
        </li>
        <li>Είδη κηδειών (1)
        <ul>
          <li>Φέρετρα (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Προϊόντα ειδικών χρήσεων (4)
      <ul>
        <li>Κέρματα αυτόματων μηχανών (0)
        </li>
        <li>Κυτία σήψης φυτικών απορριμμάτων για την παραγωγή λιπάσματος (0)
        </li>
        <li>Θάλαμοι ψεκασμού· φούρνοι βαφής (0)
        </li>
        <li>Πάσσαλοι ένδειξης ορίων χιονισμένου δρόμου (0)
        </li>
      </ul>
      </li>
      <li>Δίσκοι παράδοσης και επιτραπέζιος εξοπλισμός γραφείου (5)
      <ul>
        <li>Δίσκοι παράδοσης (0)
        </li>
        <li>Εξαρτήματα για ντοσιέ ή χαρτοφύλακες ταξινόμησης εγγράφων (0)
        </li>
        <li>Επιτραπέζιος εξοπλισμός γραφείου (1)
        <ul>
          <li>Σετ γραφείου (0)
          </li>
        </ul>
        </li>
        <li>Άγκιστρα και κρίκοι (0)
        </li>
      </ul>
      </li>
      <li>Είδη ωρολογοποιίας (4)
      <ul>
        <li>Ρολόγια χώρου (3)
        <ul>
          <li>Ξυπνητήρια (0)
          </li>
          <li>Ρολόγια τοίχου (0)
          </li>
          <li>Γυαλί για ρολόγια χώρου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Πακέτα λογισμικού και συστήματα πληροφορικής (197)
  <ul>
    <li className='top-level'>Πακέτα λογισμικού δημιουργίας κειμένων, σχεδίασης, απεικόνισης, προγραμματισμού και παραγωγικότητας (28)
    <ul>
      <li>Πακέτα λογισμικού σχεδίασης και απεικόνισης (11)
      <ul>
        <li>Πακέτα λογισμικού σχεδίασης και ζωγραφικής (0)
        </li>
        <li>Πακέτα λογισμικού δημιουργίας διαγραμμάτων (0)
        </li>
        <li>Πακέτα λογισμικού χαρτογράφησης (1)
        <ul>
          <li>Σύστημα ψηφιακής χαρτογράφησης (0)
          </li>
        </ul>
        </li>
        <li>Πακέτα λογισμικού δημιουργίας εντύπων (0)
        </li>
        <li>Σύστημα απεικόνισης και αρχειοθέτησης (0)
        </li>
        <li>Πακέτα λογισμικού επεξεργασίας εικόνων (0)
        </li>
        <li>Πακέτα λογισμικού σχεδίασης με τη βοήθεια ηλεκτρονικού υπολογιστή (CAD) (1)
        <ul>
          <li>Συστήματα σχεδίασης με τη βοήθεια ηλεκτρονικού υπολογιστή (CAD) (0)
          </li>
        </ul>
        </li>
        <li>Πακέτα λογισμικού γραφικών (0)
        </li>
        <li>Πακέτα λογισμικού κατασκευής με τη βοήθεια ηλεκτρονικού υπολογιστή (CAM) (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού δημιουργίας κειμένων (11)
      <ul>
        <li>Πακετά λογισμικού φωνητικής αναγνώρισης (0)
        </li>
        <li>Πακέτα λογισμικού οπτικής ανάγνωσης χαρακτήρων (OCR) (1)
        <ul>
          <li>Σύστημα οπτικής ανάγνωσης (0)
          </li>
        </ul>
        </li>
        <li>Πακέτα λογισμικού παρουσίασης (0)
        </li>
        <li>Πακέτα λογισμικού ηλεκτρονικών εκδόσεων (0)
        </li>
        <li>Πακέτα λογισμικού επεξεργασίας κειμένου (0)
        </li>
        <li>Πακέτα λογισμικού σαρωτών (0)
        </li>
        <li>Ορθογράφοι (0)
        </li>
        <li>Πακέτα λογισμικού διαχείρισης κειμένων (1)
        <ul>
          <li>Σύστημα διαχείρισης κειμένων (0)
          </li>
        </ul>
        </li>
        <li>Πακέτα λογισμικού για επιτραπέζιες εκδόσεις (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού προγραμματισμού και παραγωγικότητας (3)
      <ul>
        <li>Πακέτα λογισμικού διαχείρισης έργου (0)
        </li>
        <li>Πακέτα λογισμικού διαχείρισης επαφών (0)
        </li>
        <li>Πακέτα λογισμικού προγραμματισμού (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Πακέτα λογισμικού επικοινωνίας και πολυμέσων (13)
    <ul>
      <li>Πακέτα λογισμικού επικοινωνίας (9)
      <ul>
        <li>Πακέτα λογισμικού εξομοίωσης (0)
        </li>
        <li>Πακέτα λογισμικού διαχείρισης μνήμης (0)
        </li>
        <li>Πακέτα λογισμικού διαποδιαμορφωτή (μόντεμ) (0)
        </li>
        <li>Πακέτα λογισμικού ανταλλαγής δεδομένων (0)
        </li>
        <li>Πακέτα λογισμικού αλληλεπιδραστικής φωνητικής απόκρισης (0)
        </li>
        <li>Πακέτα λογισμικού επιτραπέζιων επικοινωνιών (0)
        </li>
        <li>Πακέτα λογισμικού εικονοδιάσκεψης (0)
        </li>
        <li>Πακέτα λογισμικού τεχνολογίας των πληροφοριών (0)
        </li>
        <li>Πακέτα λογισμικού τηλεπρόσβασης (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού πολυμέσων (2)
      <ul>
        <li>Πακέτα λογισμικού εικονικού πληκτρολογίου (0)
        </li>
        <li>Πακέτα λογισμικού συρραφής (μοντάζ) μουσικής ή ήχου (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Πακέτα λογισμικού επιχειρηματικών συναλλαγών και προσωπικών υποθέσεων (25)
    <ul>
      <li>Πακέτα λογισμικού παρακολούθησης χρόνου εργασίας ή ανθρώπινων πόρων (1)
      <ul>
        <li>Πακέτα λογισμικού για σχεδιασμό επιχειρηματικών πόρων (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού διαχείρισης εγκαταστάσεων και πλατφόρμες πακέτων λογισμικού (2)
      <ul>
        <li>Πλατφόρμες πακέτων λογισμικού (0)
        </li>
        <li>Πακέτα λογισμικού διαχείρισης εγκαταστάσεων (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού δημοπρασιών (0)
      </li>
      <li>Πακέτα λογισμικού χρηματοπιστωτικής ανάλυσης και λογιστικής (6)
      <ul>
        <li>Λογιστικό σύστημα (1)
        <ul>
          <li>Σύστημα έκδοσης λογαριασμών (0)
          </li>
        </ul>
        </li>
        <li>Πακέτα λογισμικού λογιστικής (0)
        </li>
        <li>Πακέτα λογισμικού χρηματοπιστωτικής ανάλυσης (0)
        </li>
        <li>Πακέτα λογισμικού χρηματοπιστωτικών συστημάτων (0)
        </li>
        <li>Πακέτα λογισμικού διαχείρισης εξυπηρέτησης πελατών (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού αναλυτικού, επιστημονικού, μαθηματικού ή προβλέψεων (3)
      <ul>
        <li>Πακέτα αναλυτικού λογισμικού ή λογισμικού προβλέψεων (0)
        </li>
        <li>Πακέτα μαθηματικού λογισμικού ή λογισμικού προβλέψεων (0)
        </li>
        <li>Πακέτα λογισμικού στατιστικής (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισιμού διαχείρισης αποθεμάτων (0)
      </li>
      <li>Πακέτα λογισμικού διαχείρισης επενδύσεων και συμπλήρωσης φορολογικής δήλωσης (2)
      <ul>
        <li>Πακέτα λογισμικού συμπλήρωσης φορολογικής δήλωσης (0)
        </li>
        <li>Πακέτα λογισμικού διαχείρισης επενδύσεων (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού πωλήσεων, μάρκετινγκ και επιχειρηματικών πληροφοριών (2)
      <ul>
        <li>Πακέτα λογισμικού επιχειρηματικών πληροφοριών (0)
        </li>
        <li>Πακέτα λογισμικού πωλήσεων ή μάρκετινγκ (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού προμηθειών (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Συστήματα πληροφόρησης και εξυπηρετητές (18)
    <ul>
      <li>Συστήματα πληροφόρησης (11)
      <ul>
        <li>Συστήματα οικονομικών πληροφοριών (0)
        </li>
        <li>Σύστημα ηλεκτρονικού ταχυδρομείου (0)
        </li>
        <li>Σύστημα πληροφόρησης επιβατών (2)
        <ul>
          <li>Σύστημα πληροφόρησης επιβατών σε πραγματικό χρόνο (0)
          </li>
          <li>Ηλεκτρονικοί πίνακες ανακοινώσεων (0)
          </li>
        </ul>
        </li>
        <li>Συστήματα ιατρικών πληροφοριών (5)
        <ul>
          <li>Σύστημα κλινικών πληροφοριών (0)
          </li>
          <li>Σύστημα διαχείρισης μητρώων ασθενών (0)
          </li>
          <li>Σύστημα διαχείρισης αιθουσών χειρουργείου (0)
          </li>
          <li>Σύστημα πληροφόρησης νοσοκόμων (0)
          </li>
          <li>Σύστημα διαχείρισης ασθενών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Εξυπηρετητές (5)
      <ul>
        <li>Εξυπηρετητές εκτυπωτών (0)
        </li>
        <li>Εξυπηρετητές ηλεκτρονικών υπολογιστών (0)
        </li>
        <li>Εξυπηρετητές δικτύου (0)
        </li>
        <li>Εξυπηρετητές Ιστού (0)
        </li>
        <li>Εξυπηρετητές αρχείου (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Πακέτα λογισμικού βάσεων δεδομένων και λειτουργικών συστημάτων (14)
    <ul>
      <li>Λειτουργικά συστήματα (8)
      <ul>
        <li>Πακέτα λογισμικού λειτουργικών συστημάτων προσωπικού ηλεκτρονικού υπολογιστή (Η/Υ) (0)
        </li>
        <li>Αρχιτεκτονική μικρο-διαύλων (0)
        </li>
        <li>Πακέτα λογισμικού λειτουργικών συστημάτων μίνι-υπολογιστή (0)
        </li>
        <li>Πακέτα λογισμικού λειτουργικών συστημάτων κεντρικής μονάδας (0)
        </li>
        <li>Λειτουργικά συστήματα ανοιχτών συστημάτων (0)
        </li>
        <li>Πακετά λογισμικού λειτουργικών συστημάτων πραγματικού χρόνου (0)
        </li>
        <li>Πακέτα λογισμικού ομαδοποίησης (0)
        </li>
        <li>Πακέτα λογισμικού λειτουργικών συστημάτων μικροϋπολογιστή (0)
        </li>
      </ul>
      </li>
      <li>Συστήματα βάσεων δεδομένων (4)
      <ul>
        <li>Σύστημα ηλεκτρονικής διαχείρισης εγγράφων (0)
        </li>
        <li>Σύστημα διαχείρισης βάσης δεδομένων (0)
        </li>
        <li>Σύστημα απόκτησης δεδομένων (0)
        </li>
        <li>Πακέτα λογισμικού βάσεων δεδομένων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Πακέτα λογισμικού δικτύωσης, Διαδικτύου και ενδοδικτύων (ιντρανέτ) (26)
    <ul>
      <li>Πακέτα λογισμικού Διαδικτύου και ενδοδικτύου (ιντρανέτ) (4)
      <ul>
        <li>Πακέτα λογισμικού φυλλομέτρησης στο Διαδίκτυο (0)
        </li>
        <li>Πακέτα λογισμικού εξυπηρετητή Ιστού (0)
        </li>
        <li>Πακέτα λογισμικού επεξεργασίας ιστοσελίδων (0)
        </li>
        <li>Πακέτα λογισμικού ηλεκτρονικής αλληλογραφίας (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού δικτύωσης (20)
      <ul>
        <li>Ποικίλα πακέτα λογισμικού δικτύωσης (8)
        <ul>
          <li>Πακέτα λογισμικού πύλης (0)
          </li>
          <li>Πακέτα λογισμικού εξυπηρετητή επικοινωνιών (0)
          </li>
          <li>Πακέτα λογισμικού εξυπηρετητή σύμπυκνων δίσκων (CD) (0)
          </li>
          <li>Πακέτα λογισμικού διαχείρισης (0)
          </li>
          <li>Πακέτα λογισμικού εξυπηρετητή συναλλαγών (0)
          </li>
          <li>Πακέτα λογισμικού μεταγωγέων ή δρομολογητών (0)
          </li>
          <li>Πακέτα λογισμικού γεφύρωσης (0)
          </li>
          <li>Πακέτα λογισμικού πολυπλέκτη (0)
          </li>
        </ul>
        </li>
        <li>Πακέτα λογισμικού για εξομοίωση τερματικών συνδεσιμότητας δικτύων (0)
        </li>
        <li>Πακέτα λογισμικού διαχείρισης αδειών (0)
        </li>
        <li>Πακέτα λογισμικού για διασυνδεσιμότητα πλατφορμών (0)
        </li>
        <li>Πακέτα λογισμικού ανάπτυξης δικτύων (0)
        </li>
        <li>Πακέτα λογισμικού δικτυακών λειτουργικών συστημάτων (0)
        </li>
        <li>Πακέτα λογισμικού επεξεργασίας συναλλαγών (3)
        <ul>
          <li>Πακέτα λογισμικού επεξεργασίας συναλλαγών μέσω μικροϋπολογιστή (0)
          </li>
          <li>Πακέτα λογισμικού για επεξεργασία συναλλαγών μέσω κεντρικής μονάδας (0)
          </li>
          <li>Πακέτα λογισμικού επεξεργασίας συναλλαγών μέσω μίνι-υπολογιστή (0)
          </li>
        </ul>
        </li>
        <li>Πακέτα λογισμικού για εξυπηρετητές jukebox οπτικών δίσκων (0)
        </li>
        <li>Πακέτα λογισμικού βελτίωσης λειτουργικών συστημάτων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Βοηθητικά προγράμματα πακέτων λογισμικού (19)
    <ul>
      <li>Πακέτα λογισμικού φόρτωσης μέσων αποθήκευσης (0)
      </li>
      <li>Πακέτα λογισμικού διαχείρισης συστημάτων, αποθήκευσης και περιεχομένου (3)
      <ul>
        <li>Πακέτα λογισμικού διαχείρισης συστημάτων (0)
        </li>
        <li>Πακέτα λογισμικού διαχείρισης περιεχομένου (0)
        </li>
        <li>Πακέτα λογισμικού διαχείρισης αποθήκευσης (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού ελέγχου έκδοσης (0)
      </li>
      <li>Πακέτα λογισμικού προστασίας από ιούς (1)
      <ul>
        <li>Πακέτα λογισμικού αντιιικής προστασίας (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού εφεδρικής αποθήκευσης ή ανάκτησης (0)
      </li>
      <li>Πακέτα λογισμικού ασφαλείας (2)
      <ul>
        <li>Πακέτα λογισμικού ασφάλειας δεδομένων (0)
        </li>
        <li>Πακέτα λογισμικού ασφάλειας αρχείων (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού κωδικοποίησης με γραμμικούς κώδικες (0)
      </li>
      <li>Πακέτα λογισμικού μετάφρασης ξένων γλωσσών (0)
      </li>
      <li>Πακέτα λογισμικού βοηθητικών προγραμμάτων γενικής χρήσης, συμπίεσης και εκτύπωσης (4)
      <ul>
        <li>Πακέτα λογισμικού βοηθητικών προγραμμάτων γενικής χρήσης (0)
        </li>
        <li>Βοηθητικά προγράμματα εκτύπωσης (1)
        <ul>
          <li>Πακέτα λογισμικού ετερoχρονισμού εκτύπωσης (0)
          </li>
        </ul>
        </li>
        <li>Βοηθητικά προγράμματα συμπίεσης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Διάφορα πακέτα λογισμικού και συστήματα ηλεκτρονικών υπολογιστών (31)
    <ul>
      <li>Γλώσσες και εργαλεία προγραμματισμού (7)
      <ul>
        <li>Πακέτα λογισμικού μεταγλώττισης (0)
        </li>
        <li>Πακέτα λογισμικού διαχείρισης παραμέτρων (0)
        </li>
        <li>Πακέτα λογισμικού ανάπτυξης (0)
        </li>
        <li>Εργαλεία λογισμικού διεπαφής χρήστη γραφικών (GUI) (0)
        </li>
        <li>Γλώσσες προγραμματισμού (0)
        </li>
        <li>Πακέτα λογισμικού δοκιμής προγραμμάτων (0)
        </li>
        <li>Πακέτα λογισμικού εξουδετέρωσης σφαλμάτων (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού αυτοματισμών γραφείου (1)
      <ul>
        <li>Ηλεκτρονικά συστήματα αυτοματοπίησης (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού λογιστικών φύλλων και βελτίωσης (1)
      <ul>
        <li>Πακέτα λογισμικού λογιστικών φύλλων (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού προγραμμάτων ψηφιακής εκτύπωσης (2)
      <ul>
        <li>Πακέτα λογισμικού δημιουργίας ετικετών (0)
        </li>
        <li>Πακέτα λογισμικού δημιουργίας ευρετηρίων διευθύνσεων (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού για σχεδιασμό προτύπων και ημεροδείκτες (2)
      <ul>
        <li>Πακέτα λογισμικού για σχεδιασμό προτύπων (0)
        </li>
        <li>Πακέτα λογισμικού ημεροδεικτών (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού εξάσκησης και ψυχαγωγίας (2)
      <ul>
        <li>Πακέτα λογισμικού ψυχαγωγίας (0)
        </li>
        <li>Πακέτα λογισμικού εξάσκησης (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού παιχνιδιών ηλεκτρονικών υπολογιστών, ηλεκτρονικά παιχνίδια για όλη την οικογένεια και προγράμματα προφύλαξης οθόνης (3)
      <ul>
        <li>Ηλεκτρονικά παιχνίδια για όλη την οικογένεια (0)
        </li>
        <li>Πακέτα λογισμικού παιχνιδιών ηλεκτρονικών υπολογιστών (0)
        </li>
        <li>Προγράμματα προφύλαξης οθόνης (0)
        </li>
      </ul>
      </li>
      <li>Συστήματα εντοπισμού θέσης πλοίων και μεγαφωνικής εγκατάστασης (2)
      <ul>
        <li>Σύστημα εντοπισμού θέσης πλοίων (0)
        </li>
        <li>Μεγαφωνική εγκατάσταση (0)
        </li>
      </ul>
      </li>
      <li>Προγράμματα οδήγησης και πακέτα λογισμικού συστημάτων (2)
      <ul>
        <li>Προγράμματα οδήγησης καρτών γραφικών (0)
        </li>
        <li>Προγράμματα οδήγησης Ethernet (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Πακέτα λογισμικού για συγκεκριμένους κλάδους (14)
    <ul>
      <li>Πακέτα λογισμικού για έλεγχο πτήσης (1)
      <ul>
        <li>Πακέτα λογισμικού για έλεγχο εναέριας κυκλοφορίας (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού συμμόρφωσης (0)
      </li>
      <li>Πακέτα εκπαιδευτικού λογισμικού (0)
      </li>
      <li>Πακέτα λογισμικού για υποστήριξη εδάφους και δοκιμές αεροπλοΐας (2)
      <ul>
        <li>Πακέτα λογισμικού για υποστήριξη εδάφους αεροπλοΐας (0)
        </li>
        <li>Πακέτα λογισμικού για δοκιμές αεροπλοΐας (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα ιατρικού λογισμικού (0)
      </li>
      <li>Πακέτα λογισμικού για έλεγχο σιδηροδρομικής κυκλοφορίας (0)
      </li>
      <li>Πακέτα λογισμικού για σημεία πώλησης (POS) (0)
      </li>
      <li>Πακέτα λογισμικού για βιομηχανικό έλεγχο (1)
      <ul>
        <li>Σύστημα ελέγχου με ηλεκτρονικό υπολογιστή (0)
        </li>
      </ul>
      </li>
      <li>Πακέτα λογισμικού βιβλιοθηκών (1)
      <ul>
        <li>Σύστημα διαχείρισης βιβλιοθηκών (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υγειονομικές και κοινωνικές υπηρεσίες (97)
  <ul>
    <li className='top-level'>Κτηνιατρικές υπηρεσίες (1)
    <ul>
      <li>Σταθμοί φροντίδας κατοικίδιων ζώων (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υγειονομικές υπηρεσίες (72)
    <ul>
      <li>Ιατρικές και συναφείς υπηρεσίες (22)
      <ul>
        <li>Ιατρικές υπηρεσίες (21)
        <ul>
          <li>Ιατρικές υπηρεσίες παρεχόμενες από ειδικευμένους γιατρούς (18)
          <ul>
            <li>Παιδιατρικές ή ουρολογικές υπηρεσίες (2)
            <ul>
              <li>Ουρολογικές υπηρεσίες (0)
              </li>
              <li>Παιδιατρικές υπηρεσίες (0)
              </li>
            </ul>
            </li>
            <li>Γυναικολογικές ή μαιευτικές υπηρεσίες (0)
            </li>
            <li>Γαστροεντερολογικές και γηριατρικές υπηρεσίες (2)
            <ul>
              <li>Γηριατρικές υπηρεσίες (0)
              </li>
              <li>Γαστροεντερολογικές υπηρεσίες (0)
              </li>
            </ul>
            </li>
            <li>Οφθαλμολογικές, δερματολογικές ή ορθοπεδικές υπηρεσίες (3)
            <ul>
              <li>Δερματολογικές υπηρεσίες (0)
              </li>
              <li>Ορθοπεδικές υπηρεσίες (0)
              </li>
              <li>Οφθαλμολογικές υπηρεσίες (0)
              </li>
            </ul>
            </li>
            <li>Ωτορινολαρυγγολογικές ή ακουολογικές υπηρεσίες (0)
            </li>
            <li>Νεφρολογικές υπηρεσίες ή υπηρεσίες ειδικευμένου ιατρού για το κεντρικό νευρικό σύστημα (0)
            </li>
            <li>Καρδιολογικές υπηρεσίες ή υπηρεσίες ειδικευμένου πνευμονολόγου (2)
            <ul>
              <li>Υπηρεσίες ειδικευμένου πνευμονολόγου (0)
              </li>
              <li>Καρδιολογικές υπηρεσίες (0)
              </li>
            </ul>
            </li>
            <li>Ψυχιατρικές ή ψυχολογικές υπηρεσίες (1)
            <ul>
              <li>Υπηρεσίες οικημάτων για άτομα με ψυχολογικές διαταραχές (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Ιατρικές υπηρεσίες παρεχόμενες από γιατρούς γενικής ιατρικής (0)
          </li>
          <li>Ιατρικές υπηρεσίες παρεχόμενες από χειρούργους γιατρούς (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Διάφορες υγειονομικές υπηρεσίες (21)
      <ul>
        <li>Υπηρεσίες ιατρικών εργαστηρίων (0)
        </li>
        <li>Φαρμακευτικές υπηρεσίες (0)
        </li>
        <li>Υπηρεσίες ασθενοφόρων (0)
        </li>
        <li>Υπηρεσίες τραπεζών αίματος (2)
        <ul>
          <li>Υπηρεσίες τραπεζών ανθρωπίνων οργάνων (0)
          </li>
          <li>Υπηρεσίες τραπεζών σπέρματος (0)
          </li>
        </ul>
        </li>
        <li>Υγειονομικές υπηρεσίες στο χώρο εργασίας (0)
        </li>
        <li>Παραϊατρικές υπηρεσίες (4)
        <ul>
          <li>Υπηρεσίες υγιεινής (0)
          </li>
          <li>Υπηρεσίες παράδοσης κατ' οίκον ειδών κατά της ακράτειας (0)
          </li>
          <li>Υπηρεσίες ομοιοπαθητικής (0)
          </li>
          <li>Υπηρεσίες φυσιοθεραπείας (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες παρεχόμενες από ιατρικό προσωπικό (5)
        <ul>
          <li>Υπηρεσίες νοσοκόμων (3)
          <ul>
            <li>Συμβουλευτικές υπηρεσίες που παρέχονται από νοσοκόμους (0)
            </li>
            <li>Ιατρικές υπηρεσίες κατ' οίκον (1)
            <ul>
              <li>Υπηρεσίες αιμοκάθαρσης κατ' οίκον (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες μαιών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες ιατρικών αναλύσεων (0)
        </li>
        <li>Υπηρεσίες οίκων παροχής υγειονομικής περίθαλψης (1)
        <ul>
          <li>Υπηρεσίες νοσοκόμων με εσωτερική διαμονή (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Νοσοκομειακές και συναφείς υπηρεσίες (17)
      <ul>
        <li>Νοσοκομειακές υπηρεσίες (13)
        <ul>
          <li>Υπηρεσίες οξυγονοθεραπείας (0)
          </li>
          <li>Νοσοκομειακές υπηρεσίες αιμοδιάλυσης (0)
          </li>
          <li>Υπηρεσίες παθολογίας (2)
          <ul>
            <li>Υπηρεσίες βακτηριολογικής ανάλυσης (0)
            </li>
            <li>Υπηρεσίες ανάλυσης αίματος (0)
            </li>
          </ul>
          </li>
          <li>Νοσοκομειακές υπηρεσίες υγειονομικής αποκατάστασης (0)
          </li>
          <li>Νοσοκομειακές ιατρικές υπηρεσίες (0)
          </li>
          <li>Νοσοκομειακές ψυχιατρικές υπηρεσίες (0)
          </li>
          <li>Υπηρεσίες χειρουργικών μονάδων (0)
          </li>
          <li>Υπηρεσίες σχετιζόμενες με την όρθωση (0)
          </li>
          <li>Νοσοκομειακές γυναικολογικές υπηρεσίες (2)
          <ul>
            <li>Υπηρεσίες μαιευτηρίου (0)
            </li>
            <li>Υπηρεσίες γονιμοποίησης σε δοκιμαστικό σωλήνα (in-vitro) (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Νοσοκομειακές υπηρεσίες υποστήριξης (2)
        <ul>
          <li>Υπηρεσίες περίθαλψης σε εξωτερικά ιατρεία (0)
          </li>
          <li>Υπηρεσίες παροχής σεντονίων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες οπτικού (0)
      </li>
      <li>Υπηρεσίες ιατρικής απεικόνισης (0)
      </li>
      <li>Οδοντιατρικές και συναφείς υπηρεσίες (3)
      <ul>
        <li>Οδοντιατρικές υπηρεσίες (2)
        <ul>
          <li>Ορθοδοντικές υπηρεσίες (1)
          <ul>
            <li>Υπηρεσίες ορθοδοντικής χειρουργικής (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες βελονισμού και χειροπρακτικής (2)
      <ul>
        <li>Υπηρεσίες χειροπρακτικής (0)
        </li>
        <li>Υπηρεσίες βελονισμού (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες κοινωνικής μέριμνας και συναφείς υπηρεσίες (21)
    <ul>
      <li>Υπηρεσίες κοινωνικής μέριμνας (16)
      <ul>
        <li>Κοινωνικές υπηρεσίες με παροχή καταλύματος (3)
        <ul>
          <li>Υπηρεσίες κοινωνικής πρόνοιας που παρέχονται σε άτομα με ειδικές ανάγκες (0)
          </li>
          <li>Υπηρεσίες κοινωνικής πρόνοιας που παρέχονται σε παιδιά και νέους (0)
          </li>
          <li>Υπηρεσίες κοινωνικής πρόνοιας που παρέχονται σε άτομα τρίτης ηλικίας (0)
          </li>
        </ul>
        </li>
        <li>Κοινωνικές υπηρεσίες χωρίς παροχή καταλύματος (11)
        <ul>
          <li>Υπηρεσίες καθοδήγησης και παροχής συμβουλών (3)
          <ul>
            <li>Υπηρεσίες παροχής συμβουλών (0)
            </li>
            <li>Υπηρεσίες καθοδήγησης (0)
            </li>
            <li>Υπηρεσίες οικογενειακού προγραμματισμού (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες αποκατάστασης (1)
          <ul>
            <li>Υπηρεσίες επαγγελματικής αποκατάστασης (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες κοινωνικής πρόνοιας που δεν παρέχονται μέσω οικοτροφείων (0)
          </li>
          <li>Υπηρεσίες ημερήσιας φροντίδας (2)
          <ul>
            <li>Υπηρεσίες ημερήσιας φροντίδας για παιδιά (0)
            </li>
            <li>Υπηρεσίες ημερήσιας φροντίδας για παιδιά και νέους με ειδικές ανάγκες (0)
            </li>
          </ul>
          </li>
          <li>Κατ' οίκον παράδοση προμηθειών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Κοινωνικές υπηρεσίες (3)
      <ul>
        <li>Πρόγραμμα κοινοτικής δράσης (0)
        </li>
        <li>Κοινοτικές υγειονομικές υπηρεσίες (0)
        </li>
        <li>Διοικητικές κοινωνικές υπηρεσίες (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Λοιπές κοινοτικές, κοινωνικές και ατομικές υπηρεσίες (84)
  <ul>
    <li className='top-level'>Διάφορες υπηρεσίες (57)
    <ul>
      <li>Υπηρεσίες αναβάθμισης των συνθηκών διαβίωσης των πολιτών (3)
      <ul>
        <li>Υπηρεσίες διαχείρισης στάθμευσης αυτοκινήτων (2)
        <ul>
          <li>Υπηρεσίες στάθμευσης αυτοκινήτων (1)
          <ul>
            <li>Υπηρεσίες ελέγχου παράνομης στάθμευσης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εργολάβων κηδειών και σχετικές υπηρεσίες (6)
      <ul>
        <li>Υπηρεσίες εργολάβων κηδειών (5)
        <ul>
          <li>Υπηρεσίες νεκροταφείων και αποτέφρωσης νεκρών (3)
          <ul>
            <li>Υπηρεσίες αποτέφρωσης νεκρών (0)
            </li>
            <li>Υπηρεσίες νεκροταφείων (1)
            <ul>
              <li>Υπηρεσίες συντήρησης νεκροταφείου (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες ταφής νεκρών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Ναυτικές υπηρεσίες (4)
      <ul>
        <li>Υπηρεσίες καταδύσεων (0)
        </li>
        <li>Υπηρεσίες σχετιζόμενες με υδρόβιους ζώντες οργανισμούς (0)
        </li>
        <li>Υπηρεσίες διαχείρισης λιμένα (1)
        <ul>
          <li>Υπηρεσίες υποστήριξης ναυτικής βάσης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες πλυσίματος και στεγνού καθαρισμού (9)
      <ul>
        <li>Υπηρεσίες βαφής (0)
        </li>
        <li>Υπηρεσίες καθαρισμού υφασμάτων (1)
        <ul>
          <li>Υπηρεσίες εμποτισμού υφασμάτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες καθαρισμού γούνας (0)
        </li>
        <li>Υπηρεσίες χρωματισμού (0)
        </li>
        <li>Υπηρεσίες σιδερωτηρίου (0)
        </li>
        <li>Υπηρεσίες συγκέντρωσης ρούχων για πλύσιμο (2)
        <ul>
          <li>Υπηρεσίες λειτουργίας πλυντηρίου (0)
          </li>
          <li>Υπηρεσίες διαχείρισης πλυντηρίου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες κομμωτηρίων και υπηρεσίες καλλωπισμού (8)
      <ul>
        <li>Υπηρεσίες καλλωπισμού (5)
        <ul>
          <li>Υπηρεσίες αισθητικού και υπηρεσίες μανικιούρ και πεντικιούρ (4)
          <ul>
            <li>Υπηρεσίες πεντικιούρ (0)
            </li>
            <li>Υπηρεσίες μακιγιάζ (0)
            </li>
            <li>Υπηρεσίες μανικιούρ (0)
            </li>
            <li>Υπηρεσίες αισθητικού (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες κομμωτηρίων (1)
        <ul>
          <li>Υπηρεσίες κουρείων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες βελτίωσης της σωματικής κατάστασης (5)
      <ul>
        <li>Υπηρεσίες τουρκικών λουτρών (0)
        </li>
        <li>Υπηρεσίες μασάζ (0)
        </li>
        <li>Υπηρεσίες ιαματικών λουτρών (0)
        </li>
        <li>Υπηρεσίες ευεξίας (0)
        </li>
        <li>Υπηρεσίες προπόνησης, εκγύμνασης ή αεροβικής άσκησης (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες παροχής καταλύματος και υπηρεσίες γραφείου (7)
      <ul>
        <li>Υπηρεσίες παροχής καταλύματος (5)
        <ul>
          <li>Υπηρεσίες διαχείρισης καταλύματος (4)
          <ul>
            <li>Υπηρεσίες φύλακα κτιρίου (0)
            </li>
            <li>Υπηρεσίες θυρωρού (0)
            </li>
            <li>Υπηρεσίες πορτιέρη (0)
            </li>
            <li>Υπηρεσίες νοικοκυριού (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες διαμόρφωσης χώρου εργασίας (0)
        </li>
      </ul>
      </li>
      <li>Λοιπές υπηρεσίες (6)
      <ul>
        <li>Υπηρεσίες μετεγκατάστασης (0)
        </li>
        <li>Υπηρεσίες ράφτη (0)
        </li>
        <li>Υπηρεσίες παύσης λειτουργίας (0)
        </li>
        <li>Υπηρεσίες επένδυσης με ταπετσαρία (0)
        </li>
        <li>Υπηρεσίες κουρδίσματος μουσικών οργάνων (0)
        </li>
        <li>Υπηρεσίες κλειθροποιού (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες κατασκευής σπιτιών για σκύλους (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Παροχή υπηρεσιών από οργανισμούς και φορείς εκτός επικράτειας (1)
    <ul>
      <li>Παροχή ειδικών υπηρεσιών προς διεθνείς οργανισμούς και φορείς (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες παροχής συμβουλών για ζητήματα ίσων ευκαιριών (0)
    </li>
    <li className='top-level'>Υπηρεσίες συλλογικών οργανώσεων (12)
    <ul>
      <li>Διάφορες υπηρεσίες συλλογικών οργανώσεων (5)
      <ul>
        <li>Θρησκευτικές υπηρεσίες (0)
        </li>
        <li>Παροχή υπηρεσιών από πολιτικές οργανώσεις (0)
        </li>
        <li>Παροχή υπηρεσιών από κοινωνικές συλλογικές οργανώσεις (2)
        <ul>
          <li>Υπηρεσίες για τη βελτίωση του αστικού περιβάλλοντος και την παροχή κοινοτικών διευκολύνσεων υποστήριξης (1)
          <ul>
            <li>Παροχή υπηρεσιών από οργανώσεις νέων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες που παρέχονται από επιχειρηματικούς, επαγγελματικούς και εξειδικευμένους οργανισμούς (4)
      <ul>
        <li>Υπηρεσίες που παρέχονται από εξειδικευμένους οργανισμούς (1)
        <ul>
          <li>Υπηρεσίες πυρηνικής προστασίας (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες που παρέχονται από επιχειρηματικούς οργανισμούς (0)
        </li>
        <li>Παροχή υπηρεσιών από επαγγελματικές οργανώσεις (0)
        </li>
      </ul>
      </li>
      <li>Παροχή υπηρεσιών από συνδικάτα (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Νοικοκυριά που απασχολούν οικιακό βοηθητικό προσωπικό (9)
    <ul>
      <li>Υπηρεσίες εμπορικών και βιομηχανικών εργατών (8)
      <ul>
        <li>Οικιακές υπηρεσίες (0)
        </li>
        <li>Υπηρεσίες βιομηχανικών εργατών (0)
        </li>
        <li>Υπηρεσίες ανθρώπινου δυναμικού για οικιακές εργασίες (4)
        <ul>
          <li>Υπηρεσίες υπαλληλικού προσωπικού γραφείων για οικιακές εργασίες (0)
          </li>
          <li>Προσωρινό προσωπικό για οικιακές εργασίες (1)
          <ul>
            <li>Υπηρεσίες οικιακής βοήθειας (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες πρακτορείων διάθεσης εργατικού δυναμικού για οικιακές εργασίες (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εμπορικών εργατών (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Εξοπλισμός εργαστηριακός, οπτικός και ακριβείας (εκτός από γυαλιά) (315)
  <ul>
    <li className='top-level'>Συσκευές ελέγχου και δοκιμών (72)
    <ul>
      <li>Μετρητές παραγωγής (5)
      <ul>
        <li>Στροφόμετρα (3)
        <ul>
          <li>Ταχύμετρα οχημάτων (2)
          <ul>
            <li>Ταξίμετρα (0)
            </li>
            <li>Ταχόμετρα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Στροβοσκόπια (0)
        </li>
      </ul>
      </li>
      <li>Όργανα και συσκευές ρύθμισης και ελέγχου (1)
      <ul>
        <li>Συσκευές περιορισμού ταχύτητας (0)
        </li>
      </ul>
      </li>
      <li>Σαρωτές (6)
      <ul>
        <li>Σαρωτές πίεσης (0)
        </li>
        <li>Σαρωτές χρωματογραφίας (0)
        </li>
        <li>Δοσίμετρα (0)
        </li>
        <li>Δοσίμετρα θαλάμων ιοντισμού (0)
        </li>
        <li>Συστήματα δευτερογενούς πρότυπης δοσιμέτρησης (0)
        </li>
        <li>Συσκευές δοσιμέτρησης φαντάσματος (0)
        </li>
      </ul>
      </li>
      <li>Μικροσκόπια (37)
      <ul>
        <li>Διοφθαλμικά σύνθετα φωτομικροσκόπια (0)
        </li>
        <li>Μικροσκόπια φθορισμού και πολωτικά μικροσκόπια (2)
        <ul>
          <li>Πολωτικά μικροσκόπια (0)
          </li>
          <li>Μικροσκόπια φθορισμού (0)
          </li>
        </ul>
        </li>
        <li>Ιοντικά και μοριακά μικροσκόπια (2)
        <ul>
          <li>Μονοφθάλμια μικροσκόπια (0)
          </li>
          <li>Ιοντικά μικροσκόπια (0)
          </li>
        </ul>
        </li>
        <li>Ανεστραμμένα μικροσκόπια και μεταλλουργικά μικροσκόπια (2)
        <ul>
          <li>Μεταλλουργικά μικροσκόπια (0)
          </li>
          <li>Ανεστραμμένα μικροσκόπια (0)
          </li>
        </ul>
        </li>
        <li>Ακουστικά μικροσκόπια και μικροσκόπια προβολής (2)
        <ul>
          <li>Μικροσκόπια προβολής (0)
          </li>
          <li>Ακουστικά μικροσκόπια (0)
          </li>
        </ul>
        </li>
        <li>Ηλεκτρονικά μικροσκόπια (2)
        <ul>
          <li>Ηλεκτρονικά μικροσκόπια μετάδοσης (0)
          </li>
          <li>Ηλεκτρονικά μικροσκόπια σάρωσης (0)
          </li>
        </ul>
        </li>
        <li>Διάφορα δομικά μέρη για μικροσκόπια (14)
        <ul>
          <li>Αντικειμενοφόροι πλάκες αυτόματης ρύθμισης για μικροσκόπια (0)
          </li>
          <li>Ανταλλακτικές λυχνίες για εργαστηριακά μικροσκόπια (0)
          </li>
          <li>Αντικειμενικοί φακοί μικροσκοπίων (0)
          </li>
          <li>Διατάξεις φωτισμού για μικροσκόπια (0)
          </li>
          <li>Προσοφθάλμιοι φακοί, συμπυκνωτές, συλλέκτες, σωλήνες, αντικειμενοφόροι πλάκες και καλύμματα για μικροσκόπια (6)
          <ul>
            <li>Προσοφθάλμιοι φακοί μικροσκοπίων (0)
            </li>
            <li>Καλύμματα μικροσκοπίων (0)
            </li>
            <li>Συμπυκνωτές μικροσκοπίων (0)
            </li>
            <li>Αντικειμενοφόροι πλάκες μικροσκοπίων (0)
            </li>
            <li>Σωλήνες μικροσκοπίων (0)
            </li>
            <li>Συλλέκτες μικροσκοπίων (0)
            </li>
          </ul>
          </li>
          <li>Προσαρτήματα φωτογραφικά ή βίντεο για μικροσκόπια (2)
          <ul>
            <li>Προσαρτήματα βίντεο για μικροσκόπια (0)
            </li>
            <li>Φωτογραφικά προσαρτήματα για μικροσκόπια (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Μικροσκόπια σκοτεινού πεδίου και μικροσκόπια με κεφαλή σάρωσης (2)
        <ul>
          <li>Μικροσκόπια με κεφαλή σάρωσης (0)
          </li>
          <li>Μικροσκόπια σκοτεινού πεδίου (0)
          </li>
        </ul>
        </li>
        <li>Φωτομικροσκόπια ευρέος πεδίου, στερεοσκοπικά ή ανατομικά (2)
        <ul>
          <li>Στερεοσκοπικά ή ανατομικά φωτομικροσκόπια (0)
          </li>
          <li>Φωτομικροσκόπια ευρέος πεδίου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Μηχανές και συσκευές δοκιμών και μετρήσεων (9)
      <ul>
        <li>Όργανα οχημάτων (0)
        </li>
        <li>Συσκευές δοκιμών συγκολλησιμότητας (0)
        </li>
        <li>Συστήματα δοσιμετρίας (0)
        </li>
        <li>Συσκευές ανίχνευσης ναρκωτικών (0)
        </li>
        <li>Σερβοϋδραυλικές συσκευές δοκιμών (0)
        </li>
        <li>Σύστημα ανίχνευσης εκρηκτικών υλών (1)
        <ul>
          <li>Ανιχνευτές βομβών (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός ανίχνευσης αερίων (0)
        </li>
        <li>Συσκευές ποιοτικής ή ποσοτικής ανάλυσης αερίων (0)
        </li>
      </ul>
      </li>
      <li>Μετρητές (4)
      <ul>
        <li>Μαγνητικοί μετρητές (0)
        </li>
        <li>Μετρητές ενέργειας (0)
        </li>
        <li>Ηλεκτρονικοί μετρητές (0)
        </li>
        <li>Μετρητές ηλεκτρικής ενέργειας (0)
        </li>
      </ul>
      </li>
      <li>Συσκευές περίθλασης (0)
      </li>
      <li>Μη ιατρικός εξοπλισμός βασισμένος στη χρήση ακτινοβολίας (2)
      <ul>
        <li>Εξοπλισμός σάρωσης αποσκευών (0)
        </li>
        <li>Εξοπλισμός επιθεώρησης με ακτίνες Χ (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εξοπλισμός ελέγχου και τηλεελέγχου βιομηχανικών διεργασιών (4)
    <ul>
      <li>Εξοπλισμός ελέγχου βιομηχανικών διεργασιών (0)
      </li>
      <li>Εξοπλισμός τηλεχειρισμού (2)
      <ul>
        <li>Συσκευές ασύρματου τηλεχειρισμού (0)
        </li>
        <li>Συστήματα τηλεχειρισμού σειρήνων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Ποικίλα όργανα εκτιμήσεων ή δοκιμών (22)
    <ul>
      <li>Όργανα μέτρησης ατμοσφαιρικής υγρασίας και υγρασίας (2)
      <ul>
        <li>Συσκευές δοκιμής θερμοκρασίας/υγρασίας (0)
        </li>
        <li>Μετρητές υγρασίας (0)
        </li>
      </ul>
      </li>
      <li>Αλυσιδωτή αντίδραση πολυμεράσης (PCR) (1)
      <ul>
        <li>Αλυσιδωτή αντίδραση πολυμεράσης (PCR) πραγματικού χρόνου (0)
        </li>
      </ul>
      </li>
      <li>Όργανα πυρηνικών μετρήσεων (7)
      <ul>
        <li>Μετρητές άλφα και βήτα ακτινοβολίας (0)
        </li>
        <li>Μετρητές γάμμα ακτινοβολίας (0)
        </li>
        <li>Μετρητές άλφα ακτινοβολίας (0)
        </li>
        <li>Μετρητές βήτα ακτινοβολίας (0)
        </li>
        <li>Μετρητες KVP (0)
        </li>
        <li>Μικροαναλυτές ακτίνων Χ (0)
        </li>
        <li>Μετρητές βήτα και γάμμα ακτινοβολίας (0)
        </li>
      </ul>
      </li>
      <li>Τεχνικοί προσομοιωτές για ερευνητική, δοκιμαστική και επιστημονική χρήση (0)
      </li>
      <li>Εξοπλισμός διανομής σπόρων και χορτονομής (3)
      <ul>
        <li>Μετρητές σπόρων (0)
        </li>
        <li>Αναλυτές χορτονομής (0)
        </li>
        <li>Αναλυτές σπόρων (0)
        </li>
      </ul>
      </li>
      <li>Συστήματα ακινητοποίησης του οχήματος σε περίπτωση υψηλής αλκοολαιμίας του οδηγού (0)
      </li>
      <li>Εξοπλισμός παρακολούθησης και δοκιμής διαδικασιών υγιεινής (2)
      <ul>
        <li>Σετ αυτοματοποιημένων ελέγχων υγιεινής επιφανειών με την τεχνική του επιχρίσματος (0)
        </li>
        <li>Σετ χειροκίνητων ελέγχων υγιεινής επιφανειών με την τεχνική του επιχρίσματος (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Συσκευές καταγραφής χρόνου και παρόμοιες συσκευές· παρκόμετρα (6)
    <ul>
      <li>Όργανα αποτύπωσης χρόνου (0)
      </li>
      <li>Παρκόμετρα (1)
      <ul>
        <li>Κερματοδέκτες (0)
        </li>
      </ul>
      </li>
      <li>Συσκευές χρονισμού (0)
      </li>
      <li>Συσκευές καταγραφής χρόνου (0)
      </li>
      <li>Χρονοδιακόπτες (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Γεωλογικά και γεωφυσικά όργανα (16)
    <ul>
      <li>Όργανα και συσκευές τοπογραφικά, υδρογραφικά, ωκεανογραφικά και υδρολογικά (6)
      <ul>
        <li>Τοπογραφικός εξοπλισμός (0)
        </li>
        <li>Θεοδόλιχοι (0)
        </li>
        <li>Συσκευές τηλεμετρίας (0)
        </li>
        <li>Όργανα γεωδαισίας (0)
        </li>
        <li>Υδρογραφικά όργανα (0)
        </li>
        <li>Σεισμολογικός εξοπλισμός (0)
        </li>
      </ul>
      </li>
      <li>Συσκευές γεωλογικής διασκόπησης (1)
      <ul>
        <li>Συστήματα γεωγραφικών πληροφοριών (GIS ή ισοδύναμα) (0)
        </li>
      </ul>
      </li>
      <li>Γεωφυσικά όργανα μαγνητομέτρων (0)
      </li>
      <li>Βαρύμετρα (0)
      </li>
      <li>Γεωφυσικά όργανα βαρύτητας (0)
      </li>
      <li>Ηλεκτρομαγνητικά γεωφυσικά όργανα (0)
      </li>
      <li>Γεωφυσικά όργανα επαγόμενης πόλωσης (IP) (0)
      </li>
      <li>Γεωφυσικά όργανα ειδικής αντίστασης (0)
      </li>
      <li>Γεωλογικές πυξίδες (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Όργανα μετρήσεων (25)
    <ul>
      <li>Ζυγοί ακριβείας (4)
      <ul>
        <li>Ηλεκτρονικοί ζυγοί και εξαρτήματα (3)
        <ul>
          <li>Ηλεκτρονικοί αναλυτικοί ζυγοί (0)
          </li>
          <li>Ηλεκτρονικοί τεχνικοί ζυγοί (1)
          <ul>
            <li>Πρότυπα βάρη βαθμονόμησης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Τραπέζια σχεδίασης (3)
      <ul>
        <li>Μηχανές σχεδίασης (0)
        </li>
        <li>Παντογράφοι (0)
        </li>
        <li>Λογαριθμικοί κανόνες (0)
        </li>
      </ul>
      </li>
      <li>Όργανα μέτρησης μεγεθών (13)
      <ul>
        <li>Συσκευές παρακολούθησης της ρύπανσης (0)
        </li>
        <li>Όργανα μέτρησης ακτινοβολίας (8)
        <ul>
          <li>Συσκευές παρακολούθησης της ακτινοβολίας (0)
          </li>
          <li>Συσκευές παρακολούθησης της μόλυνσης (0)
          </li>
          <li>Όργανα μέτρησης ηλεκτρικών μεγεθών (2)
          <ul>
            <li>Αμπερόμετρα (0)
            </li>
            <li>Βολτόμετρα (0)
            </li>
          </ul>
          </li>
          <li>Δοσιμετρητές ακτινοβολίας (0)
          </li>
          <li>Καταγραφείς δέσμης ηλεκτρονίων (0)
          </li>
          <li>Μετρητές Γκάιγκερ (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός παρακολούθησης σφάλματος (0)
        </li>
        <li>Παλμοσκόπια (1)
        <ul>
          <li>Παλμογράφοι (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Όργανα χειρός για τη μέτρηση του μήκους (1)
      <ul>
        <li>Ορθογωνιόμετρα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Όργανα ελέγχου φυσικών χαρακτηριστικών (94)
    <ul>
      <li>Όργανα και συσκευές ανίχνευσης και ανάλυσης (63)
      <ul>
        <li>Φασματοφωτόμετρα (4)
        <ul>
          <li>Εξοπλισμός μέτρησης εκπομπών (1)
          <ul>
            <li>Φασματοφωτόμετρο εκπομπής (0)
            </li>
          </ul>
          </li>
          <li>Αναλυτής φάσματος (0)
          </li>
          <li>Φασματοφωτόμετρα μάζας (0)
          </li>
        </ul>
        </li>
        <li>Ανακινητήρες και εξαρτήματα (26)
        <ul>
          <li>Διασπαστές υπερήχων (3)
          <ul>
            <li>Μήλες για διασπαστές υπερήχων (0)
            </li>
            <li>Θάλαμοι συνεχούς ροής για διασπαστές υπερήχων (0)
            </li>
            <li>Μετατροπείς για διασπαστές υπερήχων (0)
            </li>
          </ul>
          </li>
          <li>Ανακινητήρες επώασης (2)
          <ul>
            <li>Θερμαντικές εστίες για φιάλες (0)
            </li>
            <li>Θερμαντικές εστίες (0)
            </li>
          </ul>
          </li>
          <li>Περιστροφικοί εξατμιστήρες (3)
          <ul>
            <li>Προστατευτικά πετάσματα για περιστροφικούς εξατμιστήρες (0)
            </li>
            <li>Ρυθμιστές πίεσης για περιστροφικούς εξατμιστήρες (0)
            </li>
            <li>Αισθητήρες θερμοκρασίας βρασμού για περιστροφικούς εξατμιστήρες (0)
            </li>
          </ul>
          </li>
          <li>Μηχανικοί αναδευτήρες (1)
          <ul>
            <li>Φτερωτές για μηχανικούς αναδευτήρες (0)
            </li>
          </ul>
          </li>
          <li>Μηχανικοί ανακινητήρες (7)
          <ul>
            <li>Προσαρμογείς φιαλών για ανακινητήρες (0)
            </li>
            <li>Υποστάτες τριβλίων Petri για προσαρμογή σε ανακινητήρες (0)
            </li>
            <li>Σφιγκτήρες συγκράτησης κωνικών φιαλών (Erlenmeyer) για προσαρμογή σε ανακινητήρες (0)
            </li>
            <li>Υποστάτες δοκιμαστικών σωλήνων για προσαρμογή σε ανακινητήρες (0)
            </li>
            <li>Υποστάτες για διαχωριστικές φιάλες (0)
            </li>
            <li>Βάσεις κωνικών φιαλών (Erlenmeyer) για προσαρμογή σε ανακινητήρες (0)
            </li>
            <li>Πλατφόρμες με υποδοχές για κλιπ συγκράτησης κωνικών φιαλών (Erlenmeyer) για προσαρμογή σε ανακινητήρες (0)
            </li>
          </ul>
          </li>
          <li>Ομογενοποιητές εμβάπτισης (1)
          <ul>
            <li>Όργανα διασποράς για ομογενοποιητές εμβάπτισης (0)
            </li>
          </ul>
          </li>
          <li>Ομογενοποιητές με περιστρεφόμενα πτερύγια (0)
          </li>
          <li>Μαγνητικοί ανακινητήρες (1)
          <ul>
            <li>Ρυθμιστές θερμότητας για μηχανικούς ανακινητήρες με θερμαντικές πλάκες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Συσκευές αναλύσεων (4)
        <ul>
          <li>Χρωματογράφοι (1)
          <ul>
            <li>Χρωματογράφοι αερίου (0)
            </li>
          </ul>
          </li>
          <li>Συσκευές ανάλυσης αερίων (0)
          </li>
          <li>Συσκευές ανάλυσης καπναερίων (0)
          </li>
        </ul>
        </li>
        <li>Εργαστηριακά σιφώνια (πιπέτες) και εξαρτήματα (3)
        <ul>
          <li>Σιφώνια (πιπέτες) (2)
          <ul>
            <li>Υποστάτες σιφωνίων (πιπετών) (0)
            </li>
            <li>Ρύγχη σιφωνίων (πιπετών) (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Αναλυτές (16)
        <ul>
          <li>Αναλυτές διαστολών (0)
          </li>
          <li>Εξοπλισμός μέτρησης θορύβου (1)
          <ul>
            <li>Ηχόμετρα (0)
            </li>
          </ul>
          </li>
          <li>Βιοχημικοί αναλύτες (8)
          <ul>
            <li>Αναλυτές αίματος (0)
            </li>
            <li>Βιοϊατρικός εξοπλισμός (0)
            </li>
            <li>Αναλυτές γάλακτος (0)
            </li>
            <li>Χημικοί αναλυτές (0)
            </li>
            <li>Συσκευές αιματολογικών αναλύσεων (0)
            </li>
            <li>Συσκευές ανάλυσης για ανοσολογικές εξετάσεις (0)
            </li>
            <li>Κυτταρόμετρα (0)
            </li>
            <li>Μετρητές κυττάρων αίματος (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός μέτρησης ήχου (2)
          <ul>
            <li>Ακοόμετρα (0)
            </li>
            <li>Αναλυτές ηχητικής ταχύτητας (0)
            </li>
          </ul>
          </li>
          <li>Αναλύτες κραδασμών (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές ανίχνευσης (3)
        <ul>
          <li>Συσκευές ανίχνευσης καπνού (0)
          </li>
          <li>Συσκευές ανίχνευσης αερίων (0)
          </li>
          <li>Ανιχνευτές σφάλματος (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές ανίχνευσης υγρών (0)
        </li>
      </ul>
      </li>
      <li>Όργανα μέτρησης (8)
      <ul>
        <li>Ψυχρόμετρα (0)
        </li>
        <li>Πεχάμετρα (0)
        </li>
        <li>Θερμοστοιχεία (0)
        </li>
        <li>Υγρόμετρα (0)
        </li>
        <li>Υδρόμετρα (0)
        </li>
        <li>Θερμιδόμετρα (0)
        </li>
        <li>Θερμόμετρα (0)
        </li>
        <li>Πυρόμετρα (0)
        </li>
      </ul>
      </li>
      <li>Όργανα μέτρησης της ροής, της στάθμης, και της πίεσης των υγρών και των αερίων (20)
      <ul>
        <li>Εξοπλισμός μέτρησης ροής (2)
        <ul>
          <li>Μετρητές νερού (1)
          <ul>
            <li>Μετρητές ροής (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός μηχανικής ρευστών (8)
        <ul>
          <li>Ιξωδόμετρα (0)
          </li>
          <li>Συσκευές μέτρησης επιφανειακής τάσης (0)
          </li>
          <li>Μανόμετρα (0)
          </li>
          <li>Συσκευές εκτίμησης δομής (0)
          </li>
          <li>Δείκτες βάθους (0)
          </li>
          <li>Αραιόμετρα (0)
          </li>
          <li>Συσκευές εκτίμησης ισχύος (0)
          </li>
          <li>Πυκνόμετρα (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός μέτρησης πίεσης (1)
        <ul>
          <li>Δείκτες πίεσης (0)
          </li>
        </ul>
        </li>
        <li>Εξοπλισμός μέτρησης και ελέγχου (0)
        </li>
        <li>Μετρητές ηλεκτρομαγνητικής ροής (0)
        </li>
        <li>Εξοπλισμός μέτρησης στάθμης (0)
        </li>
        <li>Ροόμετρα (0)
        </li>
        <li>Κουλόμετρα (0)
        </li>
        <li>Περιστροφόμετρα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Οπτικά όργανα (44)
    <ul>
      <li>Συσκευές με υγρούς κρυστάλλους (1)
      <ul>
        <li>Περισκόπια (0)
        </li>
      </ul>
      </li>
      <li>Αστρονομικά και οπτικά όργανα (8)
      <ul>
        <li>Οπτικά μικροσκόπια (0)
        </li>
        <li>Ειδικά οπτικά όργανα (2)
        <ul>
          <li>Λέιζερ (1)
          <ul>
            <li>Βιομηχανικά λέιζερ (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Γυαλιά νυκτός (0)
        </li>
        <li>Τηλεσκόπια (0)
        </li>
        <li>Τηλεσκοπικές διόπτρες (0)
        </li>
        <li>Διόπτρες (0)
        </li>
      </ul>
      </li>
      <li>Πολωτικό υλικό (4)
      <ul>
        <li>Συσκευές οπτικών ινών (0)
        </li>
        <li>Οπτικά φίλτρα (0)
        </li>
        <li>Οπτικά βοηθήματα (0)
        </li>
        <li>Καθρέφτες (0)
        </li>
      </ul>
      </li>
      <li>Φωτογραφικός εξοπλισμός (27)
      <ul>
        <li>Συσκευές για φωτογραφικά εργαστήρια (6)
        <ul>
          <li>Φωτογραφικές συσκευές μεγέθυνσης (0)
          </li>
          <li>Συσκευές φλας (2)
          <ul>
            <li>Φωτογραφικά φλας (1)
            <ul>
              <li>Φωτογραφικά κυβοφλάς μιας χρήσης (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Οθόνες προβολής (0)
          </li>
          <li>Συσκευές και εξοπλισμός εμφάνισης φωτογραφικού φιλμ (0)
          </li>
        </ul>
        </li>
        <li>Κινηματογραφικές μηχανές προβολής (5)
        <ul>
          <li>Συσκευές σμίκρυνσης (0)
          </li>
          <li>Μηχανές προβολής (2)
          <ul>
            <li>Βιντεοπροβολείς (0)
            </li>
            <li>Προβολείς διαφανειών (0)
            </li>
          </ul>
          </li>
          <li>Συσκευές μεγέθυνσης (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές ανάγνωσης μικροφίλμ και μικροφωτοδελτίων (6)
        <ul>
          <li>Εξοπλισμός μικροφορμών (1)
          <ul>
            <li>Αναγνώστες μικροφορμών (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός μικροφωτοδελτίων (1)
          <ul>
            <li>Αναγνώστες μικροφωτοδελτίων (0)
            </li>
          </ul>
          </li>
          <li>Εξοπλισμός μικροφίλμ (1)
          <ul>
            <li>Αναγνώστες μικροφίλμ (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Φωτογραφικές μηχανές (6)
        <ul>
          <li>Ψηφιακές κάμερες (0)
          </li>
          <li>Φωτογραφικές μηχανές για την προετοιμασία εκτυπωτικών πλακών ή κυλίνδρων (0)
          </li>
          <li>Στελέχη φωτογραφικών μηχανών (0)
          </li>
          <li>Φωτογραφικές μηχανές άμεσης εκτύπωσης (0)
          </li>
          <li>Φακοί φωτογραφικών μηχανών (0)
          </li>
          <li>Κινηματογραφικές μηχανές λήψης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Όργανα πλοήγησης και μετεωρολογίας (23)
    <ul>
      <li>Όργανα ναυσιπλοΐας (9)
      <ul>
        <li>Συσκευές ραντάρ (1)
        <ul>
          <li>Εξοπλισμός παρακολούθησης με ραντάρ (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές ηχητικού εντοπισμού (0)
        </li>
        <li>Εξοπλισμός προσανατολισμού (2)
        <ul>
          <li>Πυξίδες (1)
          <ul>
            <li>Εξαρτήματα πυξίδων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Εξάντες (1)
        <ul>
          <li>Συστήματα παγκόσμιας πλοήγησης και παγκόσμιου προσδιορισμού θέσης (GPS ή ισοδύναμα) (0)
          </li>
        </ul>
        </li>
        <li>Ηχοβολίδες (0)
        </li>
      </ul>
      </li>
      <li>Μετεωρολογικά όργανα (12)
      <ul>
        <li>Συσκευές καταγραφής δεδομένων κατακρήμνισης και εξάτμισης (0)
        </li>
        <li>Μετεωρολογικοί σταθμοί (0)
        </li>
        <li>Εξαρτήματα μετεωρολογικών οργάνων (0)
        </li>
        <li>Ανεμόμετρα (0)
        </li>
        <li>Βαρόμετρα (0)
        </li>
        <li>Συσκευές ραδιοβόλησης (0)
        </li>
        <li>Επίγειες συσκευές παρατήρησης (4)
        <ul>
          <li>Επίγειες συσκευές παρατήρησης ανεμομετρικών στοιχείων (0)
          </li>
          <li>Επίγειες συσκευές παρατήρησης φαινομένων κατακρήμνισης και εξάτμισης (0)
          </li>
          <li>Επίγειες συσκευές παρατήρησης ηλιακής ακτινοβολίας (0)
          </li>
          <li>Επίγειες συσκευές παρατήρησης θερμοκρασίας ή υγρασίας (0)
          </li>
        </ul>
        </li>
        <li>Συσκευές καταγραφής δεδομένων βροχόπτωσης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες αρχιτεκτονικής, κατασκευαστικών έργων, έργων πολιτικού μηχανικού και επίβλεψης (183)
  <ul>
    <li className='top-level'>Υπηρεσίες τεχνικών δοκιμών, ανάλυσης και παροχής συμβουλών (20)
    <ul>
      <li>Υπηρεσίες αναλύσεων (1)
      <ul>
        <li>Υπηρεσίες τεχνικής ανάλυσης ή παροχής συμβουλών (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες δοκιμών και αναλύσεων σύνθεσης και καθαρότητας (0)
      </li>
      <li>Υπηρεσίες τεχνικού ελέγχου και δοκιμών (16)
      <ul>
        <li>Υπηρεσίες τεχνικών δοκιμών (2)
        <ul>
          <li>Υπηρεσίες δοκιμών βαλβίδων (0)
          </li>
          <li>Υπηρεσίες μη καταστρεπτικών δοκιμών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες τεχνικής επιθεώρησης (12)
        <ul>
          <li>Υπηρεσίες επιθεώρησης μηχανημάτων (0)
          </li>
          <li>Υπηρεσίες τεχνικού ελέγχου αυτοκινήτων (0)
          </li>
          <li>Υπηρεσίες τεχνικής επιθεώρησης τεχνικών έργων (8)
          <ul>
            <li>Υπηρεσίες επιθεώρησης φραγμάτων (0)
            </li>
            <li>Υπηρεσίες επιθεώρησης τροχιοδρόμων (0)
            </li>
            <li>Υπηρεσίες ελέγχου διαρροής (0)
            </li>
            <li>Υπηρεσίες εποπτείας ναυτασφάλισης (0)
            </li>
            <li>Υπηρεσίες ελέγχου ροής (0)
            </li>
            <li>Υπηρεσίες επιθεώρησης αεροδιαδρόμων (0)
            </li>
            <li>Υπηρεσίες επιθεώρησης οδών (0)
            </li>
            <li>Υπηρεσίες επιθεώρησης γεφυρών (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες τεχνικής επιθεώρησης κτιρίων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες παροχής συμβουλών σε θέματα υδροδότησης και αποβλήτων (0)
    </li>
    <li className='top-level'>Αρχιτεκτονικές και συναφείς υπηρεσίες (19)
    <ul>
      <li>Υπηρεσίες αρχιτέκτονα, μηχανικού και υπεύθυνου έργου (1)
      <ul>
        <li>Αρχιτεκτονικές υπηρεσίες και υπηρεσίες επίβλεψης κτιρίων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες παροχής αρχιτεκτονικών συμβουλών (0)
      </li>
      <li>Υπηρεσίες οργάνωσης διαγωνισμών αρχιτεκτονικής μελέτης (0)
      </li>
      <li>Υπηρεσίες αρχιτεκτονικού σχεδιασμού (5)
      <ul>
        <li>Αρχιτεκτονικές υπηρεσίες για κτίρια (0)
        </li>
        <li>Αρχιτεκτονικές υπηρεσίες για εξωτερικούς χώρους (2)
        <ul>
          <li>Υπηρεσίες χαρτογράφησης αγροτικών περιοχών (0)
          </li>
          <li>Υπηρεσίες χαρτογράφησης αστικών περιοχών (0)
          </li>
        </ul>
        </li>
        <li>Αρχιτεκτονικές υπηρεσίες για επεκτάσεις κτιρίων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες αρχιτέκτονα, μηχανικού και τεχνικού γραφείου (8)
      <ul>
        <li>Προσχέδια (συστήματα και ολοκλήρωση) (0)
        </li>
        <li>Επίβλεψη οικοδομικών εργασιών (0)
        </li>
        <li>Προετοιμασία έργων και σχεδίων, εκτίμηση δαπανών (0)
        </li>
        <li>Μελέτες σκοπιμότητας, υπηρεσίες παροχής συμβουλών, υπηρεσίες αναλύσεων (0)
        </li>
        <li>Σχέδια έγκρισης, σχέδια εργασίας και προδιαγραφές (0)
        </li>
        <li>Προσδιορισμός και καταλογογράφηση ποσοτήτων για κατασκευαστικά έργα (0)
        </li>
        <li>Υπολογισμός δαπανών, παρακολούθηση δαπανών (0)
        </li>
        <li>Επίβλεψη έργων και τεκμηρίωση (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εργαστηριακές υπηρεσίες (0)
    </li>
    <li className='top-level'>Κατασκευαστικές και συναφείς υπηρεσίες (7)
    <ul>
      <li>Υπηρεσίες διεξαγωγής ερευνών χώρων (0)
      </li>
      <li>Υπηρεσίες σιδηρουργού (0)
      </li>
      <li>Υπηρεσίες διαχείρισης κατασκευών (1)
      <ul>
        <li>Υπηρεσίες διαχείρισης κατασκευαστικών έργων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επίβλεψης κατασκευών (1)
      <ul>
        <li>Υπηρεσίες επίβλεψης χώρου κατασκευών (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες παροχής συμβουλών σε θέματα κατασκευών (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες πολεοδομικού σχεδιασμού και αρχιτεκτονικής τοπίου (3)
    <ul>
      <li>Υπηρεσίες πολεοδομικού σχεδιασμού (0)
      </li>
      <li>Υπηρεσίες αρχιτεκτονικής τοπίου (1)
      <ul>
        <li>Υπηρεσίες αρχιτεκτονικής κήπου (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες παρακολούθησης και ελέγχου (2)
    <ul>
      <li>Υπηρεσίες βιομηχανικής επιθεώρησης (1)
      <ul>
        <li>Υπηρεσίες βιομηχανικού ελέγχου ποιότητας (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες μηχανικού (124)
    <ul>
      <li>Διάφορες υπηρεσίες μηχανικού (7)
      <ul>
        <li>Μηχανολογικές υπηρεσίες γεωτεχνικού σχεδιασμού (0)
        </li>
        <li>Τεχνικές υπηρεσίες σε θέματα διάβρωσης (0)
        </li>
        <li>Μηχανολογικές υπηρεσίες υποστήριξης (0)
        </li>
        <li>Τεχνικές μελέτες (0)
        </li>
        <li>Τεχνικές υπηρεσίες σε θέματα ιλύος γεωτρήσεων (0)
        </li>
        <li>Μηχανολογικές και ηλεκτρολογικές υπηρεσίες (0)
        </li>
        <li>Μηχανολογικές υπηρεσίες (0)
        </li>
      </ul>
      </li>
      <li>Ολοκληρωμένες τεχνικές υπηρεσίες μηχανικού (0)
      </li>
      <li>Υπηρεσίες παροχής συμβουλών σε θέματα μηχανικής και κατασκευών (38)
      <ul>
        <li>Υπηρεσίες παροχής συμβουλών σε κατασκευαστικά θέματα μεγάλων τεχνικών έργων (0)
        </li>
        <li>Υπηρεσίες σχετιζόμενες με την ενέργεια και συναφείς υπηρεσίες (4)
        <ul>
          <li>Ηλεκτρολογικές υπηρεσίες (0)
          </li>
          <li>Υπηρεσίες διαχείρισης της ενέργειας (0)
          </li>
          <li>Υπηρεσίες παροχής συμβουλών σχετικά με την ενεργειακή απόδοση (1)
          <ul>
            <li>Υπηρεσίες θερμικής φυσικής των κτιρίων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα τηλεπικοινωνιών (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών για εργασίες πολιτικού μηχανικού (7)
        <ul>
          <li>Υπηρεσίες παροχής συμβουλών για υποδομή (0)
          </li>
          <li>Υπηρεσίες υποστήριξης για έργα πολιτικού μηχανικού (0)
          </li>
          <li>Υπηρεσίες παροχής συμβουλών για μεταφορικά συστήματα (4)
          <ul>
            <li>Υπηρεσίες παροχής συμβουλών σε θέματα αυτοκινητοδρόμων (0)
            </li>
            <li>Υπηρεσίες μηχανικού σχετιζόμενες με την κατασκευή αυτοκινητοδρόμων (0)
            </li>
            <li>Υπηρεσίες μηχανικού σχετιζόμενες με την κατασκευή αερολιμένων (0)
            </li>
            <li>Υπηρεσίες μηχανικού σχετιζόμενες με την κατασκευή σιδηροδρόμων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες παροχής συμβουλών για θέματα περιβαλλοντικής μηχανικής (8)
        <ul>
          <li>Υπηρεσίες παροχής συμβουλών στον τομέα της ηχορύπανσης (0)
          </li>
          <li>Υπηρεσίες παροχής συμβουλών στον τομέα της ηχομόνωσης και ακουστικής αιθουσών (0)
          </li>
          <li>Εκτίμηση περιβαλλοντικών επιπτώσεων για κατασκευαστικά έργα (5)
          <ul>
            <li>Ανάλυση περιβαλλοντικών δεικτών για κατασκευαστικά έργα (0)
            </li>
            <li>Εκτίμηση επικινδυνότητας ή κινδύνου για κατασκευαστικά έργα (0)
            </li>
            <li>Περιβαλλοντική παρακολούθηση για κατασκευαστικά έργα (0)
            </li>
            <li>Υπηρεσίες εκτίμησης περιβαλλοντικών επιπτώσεων (ΕΠΕ) για κατασκευαστικά έργα (0)
            </li>
            <li>Περιβαλλοντικά πρότυπα για κατασκευαστικά έργα (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες παροχής συμβουλών και γνωμοδότησης σε θέματα μηχανικού (1)
        <ul>
          <li>Υπηρεσίες μηχανικής τεχνητού και φυσικού φωτισμού κτιρίων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες πραγματογνωμόνων (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σχετιζόμενες με τον έλεγχο και την προστασία από κινδύνους (3)
        <ul>
          <li>Υπηρεσίες υγείας και ασφάλειας (1)
          <ul>
            <li>Υπηρεσίες παροχής συμβουλών σε θέματα υγείας και ασφάλειας (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες παροχής συμβουλών σχετικά με την προστασία και τον έλεγχο πυρκαγιάς και εκρήξεων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες σχετιζόμενες με τις τεχνικές εγκαταστάσεις κτιρίων (6)
        <ul>
          <li>Υπηρεσίες επίβλεψης κτιρίων (0)
          </li>
          <li>Υπηρεσίες επιθεώρησης κτιρίων (1)
          <ul>
            <li>Επιθεώρηση συστημάτων αερισμού (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες παροχής συμβουλών για θέματα που αφορούν κατασκευές (1)
          <ul>
            <li>Υπηρεσίες παροχής συμβουλών σχετικά με τις οικοδομικές εγκαταστάσεις (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες παροχής συμβουλών για τα οικοδομικά στοιχεία των κτιρίων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες εκπόνησης τεχνικών μελετών (19)
      <ul>
        <li>Υπηρεσίες σχεδιασμού θεμελιώσεων (0)
        </li>
        <li>Υπηρεσίες εκπόνησης τεχνικών μελετών για την κατασκευή έργων πολιτικού μηχανικού (5)
        <ul>
          <li>Υπηρεσίες σχεδιασμού αγωγών (0)
          </li>
          <li>Υπηρεσίες σχεδιασμού φραγμάτων (0)
          </li>
          <li>Υπηρεσίες ποσοτικής αξιολόγησης για έργα πολιτικού μηχανικού (0)
          </li>
          <li>Υπηρεσίες τεχνικού σχεδιασμού οδών (0)
          </li>
          <li>Υπηρεσίες σχεδιασμού γεφυρών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες σχεδιασμού φερουσών δομών (0)
        </li>
        <li>Υπηρεσίες επαλήθευσης του σχεδιασμού φερουσών δομών (0)
        </li>
        <li>Υπηρεσίες εκπόνησης τεχνικών μελετών για βιομηχανικές διαδικασίες και βιομηχανική παραγωγή (2)
        <ul>
          <li>Υπηρεσίες σχεδιασμού τεχνικών εγκαταστάσεων (0)
          </li>
          <li>Υπηρεσίες σχεδιασμού συστημάτων ηλεκτρικής ενέργειας (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εκπόνησης τεχνικών μελετών για μηχανολογικές και ηλεκτρολογικές εγκαταστάσεις κτιρίων (4)
        <ul>
          <li>Υπηρεσίες παροχής συμβουλών σε θέματα αερισμού (0)
          </li>
          <li>Υπηρεσίες σχεδιασμού συστημάτων θέρμανσης (0)
          </li>
          <li>Υπηρεσίες παροχής συμβουλών σε θέματα υδραυλικής (0)
          </li>
          <li>Υπηρεσίες οικονομικής εκτίμησης κατασκευών (0)
          </li>
        </ul>
        </li>
        <li>Βοηθητικές υπηρεσίες κτιρίων (0)
        </li>
        <li>Υπηρεσίες ποσοτικού ελέγχου (0)
        </li>
      </ul>
      </li>
      <li>Επιστημονικές και τεχνικές υπηρεσίες που αφορούν τεχνικά έργα (55)
      <ul>
        <li>Γεωλογικές, γεωφυσικές και άλλες επιστημονικές υπηρεσίες ερευνών (30)
        <ul>
          <li>Υπηρεσίες πρόβλεψης καιρού (3)
          <ul>
            <li>Μετεωρολογικές υπηρεσίες (2)
            <ul>
              <li>Υπηρεσίες υδρομετεωρολογίας (0)
              </li>
              <li>Υπηρεσίες σε θέματα κλιματολογίας (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Τοπογραφικές υπηρεσίες και υπηρεσίες ανίχνευσης υπόγειων υδάτων (3)
          <ul>
            <li>Υπηρεσίες ανίχνευσης υπόγειων υδάτων (0)
            </li>
            <li>Τοπογραφικές υπηρεσίες (1)
            <ul>
              <li>Τοπογραφικές μελέτες αρχαιολογικών χώρων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες μικροπαλαιοντολογικής ανάλυσης (0)
          </li>
          <li>Υπηρεσίες πετροφυσικών ερευνών (0)
          </li>
          <li>Προπαρασκευαστικές και αναλυτικές υπηρεσίες καρότου γεωτρήσεων (0)
          </li>
          <li>Υπηρεσίες γεωλογίας, ωκεανογραφίας και υδρολογίας (10)
          <ul>
            <li>Υπηρεσίες ωκεανογραφίας και υδρολογίας (4)
            <ul>
              <li>Υπηρεσίες βαθυμετρικής αποτύπωσης (0)
              </li>
              <li>Υπηρεσίες ωκεανογραφίας ποταμοκόλπων (0)
              </li>
              <li>Υπηρεσίες φυσικής ωκεανογραφίας (0)
              </li>
              <li>Υπηρεσίες υποβρύχιας διασκόπησης (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες γεωλογίας (4)
            <ul>
              <li>Αρχαιολογικές υπηρεσίες (0)
              </li>
              <li>Υπηρεσίες φωτογεωλογίας (0)
              </li>
              <li>Υπηρεσίες στρωματογραφικής γεωλογίας (0)
              </li>
              <li>Υπηρεσίες γεωλογικής διασκόπησης (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες εδαφολογικών ερευνών (0)
          </li>
          <li>Υπηρεσίες επιστημονικών ερευνών (3)
          <ul>
            <li>Γεωφυσικές μελέτες αρχαιολογικών χώρων (0)
            </li>
            <li>Γεωλογικές υπηρεσίες ανίχνευσης πιθανών κοιτασμάτων (0)
            </li>
            <li>Υπηρεσίες γεωφυσικών ερευνών (0)
            </li>
          </ul>
          </li>
          <li>Παροχή υπηρεσιών συμβούλου σε θέματα γεωλογίας και γεωφυσικής (2)
          <ul>
            <li>Υπηρεσίες παροχής γεωφυσικών συμβουλών (0)
            </li>
            <li>Παροχή υπηρεσιών συμβούλου σε θέματα γεωλογίας (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες χαρτογράφησης (5)
        <ul>
          <li>Υπηρεσίες θαλάσσιων μελετών (0)
          </li>
          <li>Υπηρεσίες κτηματολογικής έρευνας (0)
          </li>
          <li>Υπηρεσίες εναέριας χαρτογράφησης (0)
          </li>
          <li>Υπηρεσίες ψηφιακής χαρτογράφησης (0)
          </li>
          <li>Υδρογραφικές υπηρεσίες (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες αποτύπωσης υπεδάφους (6)
        <ul>
          <li>Υπηρεσίες μαγνητομετρικής αξιολόγησης (0)
          </li>
          <li>Σεισμολογικές υπηρεσίες (4)
          <ul>
            <li>Υπηρεσίες απόκτησης σεισμολογικών δεδομένων (0)
            </li>
            <li>Υπηρεσίες συλλογής σεισμολογικών δεδομένων (0)
            </li>
            <li>Υπηρεσίες επεξεργασίας σεισμικών δεδομένων (0)
            </li>
            <li>Υπηρεσίες σεισμογραφικής αποτύπωσης (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Τεχνικές υπηρεσίες (4)
        <ul>
          <li>Υπηρεσίες τεχνικού ελέγχου (0)
          </li>
          <li>Υπηρεσίες τεχνικής βοήθειας (0)
          </li>
          <li>Υπηρεσίες τεχνικής υποστήριξης (0)
          </li>
          <li>Υπηρεσίες τεχνικού σχεδιασμού (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες χωρομέτρησης (2)
        <ul>
          <li>Υπηρεσίες υδρογραφικής αποτύπωσης (0)
          </li>
          <li>Υπηρεσίες διαστασιολογικής αποτύπωσης (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες χωροστάθμισης (2)
        <ul>
          <li>Υπηρεσίες αποτύπωσης επιτελικού χάρτη (0)
          </li>
          <li>Φωτογραμμομετρικές υπηρεσίες (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Επιχειρήσεις δημόσιας ωφέλειας (16)
  <ul>
    <li className='top-level'>Υπηρεσίες ανάγνωσης μετρητών (0)
    </li>
    <li className='top-level'>Διανομή ύδατος και συναφείς υπηρεσίες (7)
    <ul>
      <li>Διανομή ύδατος (1)
      <ul>
        <li>Διανομή πόσιμου νερού (0)
        </li>
      </ul>
      </li>
      <li>Λειτουργία σταθμού καθαρισμού του νερού (3)
      <ul>
        <li>Υπηρεσίες αφαλάτωσης ύδατος (0)
        </li>
        <li>Υπηρεσίες αποσκλήρυνσης ύδατος (0)
        </li>
        <li>Υπηρεσίες απιονισμού ύδατος (0)
        </li>
      </ul>
      </li>
      <li>Λειτουργία εγκατάστασης παροχής νερού (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Προμήθεια και διανομή άλλων πηγών ενέργειας (1)
    <ul>
      <li>Λειτουργία σταθμού ηλεκτροπαραγωγής (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Διανομή αερίου και συναφείς υπηρεσίες (1)
    <ul>
      <li>Διανομή αερίου (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Διανομή ηλεκτρικού και συναφείς υπηρεσίες (2)
    <ul>
      <li>Διανομή ηλεκτρικού (0)
      </li>
      <li>Λειτουργία ηλεκτρικών εγκαταστάσεων (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες τεχνολογίας των πληροφοριών: παροχή συμβουλών, ανάπτυξη λογισμικού, Διαδίκτυο και υποστήριξη (251)
  <ul>
    <li className='top-level'>Υπηρεσίες προγραμματισμού λογισμικού και παροχής συμβουλών (184)
    <ul>
      <li>Υπηρεσίες σχετιζόμενες με λογισμικά (10)
      <ul>
        <li>Υπηρεσίες σύνθεσης λογισμικού (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα λογισμικού (0)
        </li>
        <li>Υπηρεσίες ανάπτυξης λογισμικού (0)
        </li>
        <li>Υπηρεσίες αναπαραγωγής λογισμικού (0)
        </li>
        <li>Υπηρεσίες προμήθειας λογισμικού (0)
        </li>
        <li>Υπηρεσίες υποστήριξης λογισμικού (0)
        </li>
        <li>Υπηρεσίες υλοποίησης λογισμικού (0)
        </li>
        <li>Υπηρεσίες συντήρησης και επισκευής λογισμικού (2)
        <ul>
          <li>Επισκευή λογισμικού τεχνολογίας των πληροφοριών (0)
          </li>
          <li>Συντήρηση λογισμικού τεχνολογίας των πληροφοριών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες ανάπτυξης εξατομικευμένων λογισμικών (2)
      <ul>
        <li>Ανάπτυξη λογισμικού για στρατιωτικές εφαρμογές (0)
        </li>
        <li>Ανάπτυξη λογισμικού επεξεργασίας συναλλαγών και εξατομικευμένου λογισμικού (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες προγραμματισμού πακέτων λογισμικού (140)
      <ul>
        <li>Υπηρεσίες προγραμματισμού λογισμικού εφαρμογών (138)
        <ul>
          <li>Υπηρεσίες ανάπτυξης λογισμικού βάσεων δεδομένων και λειτουργικών συστημάτων (7)
          <ul>
            <li>Υπηρεσίες ανάπτυξης λογισμικού ομαδοποίησης (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού λειτουργικών συστημάτων προσωπικού ηλεκτρονικού υπολογιστή (Η/Υ) (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού λειτουργικών συστημάτων μίνι-υπολογιστή (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού λειτουργικών συστημάτων μικροϋπολογιστή (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού λειτουργικών συστημάτων κεντρικής μονάδας (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού βάσεων δεδομένων (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού λειτουργικών συστημάτων πραγματικού χρόνου (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες ανάπτυξης λογισμικού επικοινωνίας και πολυμέσων (13)
          <ul>
            <li>Υπηρεσίες ανάπτυξης λογισμικού πολυμέσων (2)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού συρραφής (μοντάζ) μουσικής ή ήχου (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού εικονικού πληκτρολογίου (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού επικοινωνίας (9)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού τεχνολογίας των πληροφοριών (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης μνήμης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαποδιαμορφωτή (μόντεμ) (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού εικονοδιάσκεψης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού αλληλεπιδραστικής φωνητικής απόκρισης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού τηλεπρόσβασης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού εξομοίωσης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού ανταλλαγής δεδομένων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού επιτραπέζιων επικοινωνιών (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Βοηθητικά προγράμματα για υπηρεσίες ανάπτυξης λογισμικού (17)
          <ul>
            <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης συστημάτων, αποθήκευσης και περιεχομένου (3)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης συστημάτων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης αποθήκευσης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης περιεχομένου (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού μετάφρασης ξένων γλωσσών (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού εφεδρικής αποθήκευσης ή ανάκτησης (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού ελέγχου έκδοσης (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού κωδικοποίηση με γραμμικό κώδικα (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού βοηθητικών προγραμμάτων γενικής χρήσης, συμπίεσης και εκτύπωσης (2)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού γενικής χρήσης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού βοηθητικών προγραμμάτων εκτύπωσης (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού προστασίας από ιούς (1)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού αντιιικής προστασίας (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού ασφαλείας (2)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού ασφάλειας δεδομένων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού ασφάλειας φακέλων αρχείων (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού φόρτωσης μέσων αποθήκευσης (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες ανάπτυξης λογισμικού δικτύωσης, Διαδικτύου και ενδοδικτύων (ιντρανέτ) (15)
          <ul>
            <li>Υπηρεσίες ανάπτυξης λογισμικού δικτύωσης (9)
            <ul>
              <li>Διάφορες υπηρεσίες ανάπτυξης λογισμικού δικτύωσης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού για εξυπηρετητές jukebox οπτικών δίσκων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης αδειών (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού για διασυνδεσιμότητα πλατφορμών (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού για εξομοίωση τερματικών συνδεσιμότητας δικτύων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού δικτυακών λειτουργικών συστημάτων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού βελτίωσης λειτουργικών συστημάτων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού επεξεργασίας συναλλαγών (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού ανάπτυξης δικτύων (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού Διαδικτύου και ενδοδικτύου (ιντρανέτ) (4)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού εξυπηρετητή Ιστού (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού φυλλομέτρησης στο Διαδίκτυο (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού ηλεκτρονικής αλληλογραφίας (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού επεξεργασίας ιστοσελίδων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες ανάπτυξης λογισμικού δημιουργίας κειμένων, σχεδίασης, απεικόνισης, προγραμματισμού και παραγωγικότητας (22)
          <ul>
            <li>Υπηρεσίες ανάπτυξης λογισμικού σχεδίασης και απεικόνισης (8)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού δημιουργίας διαγραμμάτων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού δημιουργίας εντύπων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού επεξεργασίας εικόνων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού γραφικών (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού σχεδίασης με τη βοήθεια ηλεκτρονικού υπολογιστή (CAD) (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού σχεδίασης και ζωγραφικής (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού κατασκευής με τη βοήθεια ηλεκτρονικού υπολογιστή (CAM) (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού χαρτογράφησης (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού δημιουργίας κειμένων (8)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού οπτικής ανάγνωσης χαρακτήρων (OCR) (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού παρουσίασης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης κειμένων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού φωνητικής αναγνώρισης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού επεξεργασίας κειμένου (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού σαρωτών (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού ηλεκτρονικών εκδόσεων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού επιτραπέζιων εκδόσεων (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού προγραμματισμού και παραγωγικότητας (3)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού προγραμματισμού (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης επαφών (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης έργου (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Διάφορες υπηρεσίες ανάπτυξης λογισμικού και συστήματα ηλεκτρονικών υπολογιστών (21)
          <ul>
            <li>Υπηρεσίες ανάπτυξης λογισμικού λογιστικών φύλλων και βελτίωσης (1)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού λογιστικών φύλλων (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης παιχνιδιών ηλεκτρονικών υπολογιστών, ηλεκτρονικά παιχνίδια για όλη την οικογένεια και προγράμματα προφύλαξης οθόνης (1)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού παιχνιδιών ηλεκτρονικών υπολογιστών (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού σχεδίασης προτύπων και ημεροδεικτών (2)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού ημεροδεικτών (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού σχεδίασης προτύπων (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού εξάσκησης και ψυχαγωγίας (2)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού εξάσκησης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού ψυχαγωγίας (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού ψηφιακής εκτύπωσης (2)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού δημιουργίας ετικετών (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού δημιουργίας ευρετηρίων διευθύνσεων (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης γλωσσών και εργαλείων προγραμματισμού (5)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού μεταγλώττισης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης παραμέτρων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού δοκιμής προγραμμάτων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού ανάπτυξης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού εξουδετέρωσης σφαλμάτων (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού αυτοματισμών γραφείου (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού προγραμμάτων οδήγησης και συστημάτων (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες ανάπτυξης λογισμικού επιχειρηματικών συναλλαγών και προσωπικών υποθέσεων (23)
          <ul>
            <li>Υπηρεσίες ανάπτυξης λογισμικού δημοπρασιών (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης αποθεμάτων (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού παρακολούθησης χρόνου εργασίας ή ανθρώπινων πόρων (1)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού για σχεδιασμό επιχειρηματικών πόρων (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης εγκαταστάσεων και πλατφόρμες υπηρεσιών ανάπτυξης λογισμικού (2)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης εγκαταστάσεων (0)
              </li>
              <li>Πλατφόρμες υπηρεσιών ανάπτυξης λογισμικού (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού προμηθειών (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού χρηματοπιστωτικής ανάλυσης και λογιστικής (4)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού εξυπηρέτησης πελατών (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού χρηματοπιστωτικών συστημάτων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού χρηματοπιστωτικής ανάλυσης (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού λογιστικής (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού αναλυτικού, επιστημονικού, μαθηματικού ή προβλέψεων (3)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού στατιστικής (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού αναλυτικού ή επιστημονικού (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού μαθηματικού ή προβλέψεων (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης επενδύσεων και συμπλήρωσης φορολογικής δήλωσης (2)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού διαχείρισης επενδύσεων (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού συμπλήρωσης φορολογικής δήλωσης (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού πωλήσεων, μάρκετινγκ και επιχειρηματικών πληροφοριών (2)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού επιχειρηματικών πληροφοριών (0)
              </li>
              <li>Υηρεσίες ανάπτυξης λογισμικού πωλήσεων ή μάρκετινγκ (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες ανάπτυξης λογισμικού για συγκεκριμένους κλάδους (12)
          <ul>
            <li>Υπηρεσίες ανάπτυξης λογισμικού για έλεγχο σιδηροδρομικής κυκλοφορίας (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού για υποστήριξη εδάφους και δοκιμές αεροπλοΐας (2)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού για δοκιμές αεροπλοΐας (0)
              </li>
              <li>Υπηρεσίες ανάπτυξης λογισμικού για υποστήριξη εδάφους αεροπλοΐας (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού για σημεία πώλησης (POS) (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης εκπαιδευτικού λογισμικού (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού βιβλιοθηκών (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού για βιομηχανικό έλεγχο (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού συμμόρφωσης (0)
            </li>
            <li>Υπηρεσίες ανάπτυξης λογισμικού για έλεγχο πτήσης (1)
            <ul>
              <li>Υπηρεσίες ανάπτυξης λογισμικού για έλεγχο εναέριας κυκλοφορίας (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες ανάπτυξης ιατρικού λογισμικού (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες προγραμματισμού λογισμικών συστήματος και χρήστη (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες συστήματος και υποστήριξης (7)
      <ul>
        <li>Υπηρεσίες ηλεκτρονικής αρχειοθέτησης (0)
        </li>
        <li>Υπηρεσίες αποκατάστασης του λογισμικού σε περίπτωση καταστροφής (0)
        </li>
        <li>Γραμμή άμεσης βοήθειας και υπηρεσίες υποστήριξης (2)
        <ul>
          <li>Υπηρεσίες γραμμής άμεσης βοήθειας (0)
          </li>
          <li>Υπηρεσίες υποστήριξης συστημάτων πληροφορικής (0)
          </li>
        </ul>
        </li>
        <li>Δοκιμές λογισμικού (1)
        <ul>
          <li>Υπηρεσίες δοκιμής συστημάτων πληροφορικής (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες ανάλυσης και προγραμματισμού συστημάτων (6)
      <ul>
        <li>Υπηρεσίες ανάλυσης συστημάτων και προγραμματισμού βάσει συμβολαίου (0)
        </li>
        <li>Υπηρεσίες προγραμματισμού (0)
        </li>
        <li>Υπηρεσίες καθορισμού στόχου κρίσιμου σχεδιασμού (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα συστημάτων πληροφορικής (0)
        </li>
        <li>Υπηρεσίες κατασκευής πρωτοτύπων (0)
        </li>
        <li>Υπηρεσίες τυποποίησης σχεδιασμού (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες παροχής συμβουλών για συστήματα και τεχνικά θέματα (13)
      <ul>
        <li>Υπηρεσίες στρατηγικής αναθεώρησης και προγραμματισμού συστημάτων ή τεχνολογίας των πληροφοριών (3)
        <ul>
          <li>Υπηρεσίες προγραμματισμού συστημάτων ή τεχνολογίας των πληροφοριών (0)
          </li>
          <li>Υπηρεσίες τεχνολογίας των πληροφοριών (0)
          </li>
          <li>Υπηρεσίες στρατηγικής αναθεώρησης συστημάτων ή τεχνολογίας των πληροφοριών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα ολοκλήρωσης λογισμικού (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών επιχειρησιακής ανάλυσης (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα διαχείρισης έργων (2)
        <ul>
          <li>Υπηρεσίες προγραμματισμού ποιοτικού ελέγχου συστημάτων (0)
          </li>
          <li>Υπηρεσίες προγραμματισμού υλοποίησης συστημάτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες αξιολόγησης και αναθεώρησης της ποιοτικής διασφάλισης των συστημάτων (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα δοκιμών αποδοχής λογισμικού συστημάτων (0)
        </li>
        <li>Υπηρεσίες αναθεώρησης απαιτήσεων τεχνολογίας της πληροφορίας (0)
        </li>
        <li>Υπηρεσίες παροχής συμβουλών σε θέματα ολοκλήρωσης υλικού πληροφορικής (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες πληροφορικής (13)
    <ul>
      <li>Επαγγελματικές υπηρεσίες σχετιζόμενες με ηλεκτρονικούς υπολογιστές (1)
      <ul>
        <li>Συμφωνίες ανάπτυξης επιπέδου εξυπηρέτησης (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες διαχείρισης που σχετίζονται με ηλεκτρονικούς υπολογιστές (7)
      <ul>
        <li>Υπηρεσίες λογισμικού διαχείρισης δικτύου (0)
        </li>
        <li>Υπηρεσίες διαχείρισης εγκαταστάσεων ηλεκτρονικού υπολογιστή (3)
        <ul>
          <li>Υπηρεσίες διαχείρισης εγκαταστάσεων για τη συντήρηση συστημάτων πληροφορικής (0)
          </li>
          <li>Υπηρεσίες διαχείρισης εγκαταστάσεων με τη χρήση ηλεκτρονικών υπολογιστών (0)
          </li>
          <li>Υπηρεσίες διαχείρισης εγκαταστάσεων για την ανάπτυξη συστημάτων πληροφορικής (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες διαχείρισης εγγράφων (0)
        </li>
        <li>Υπηρεσίες αυτοματισμού γραφείου (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες αναβάθμισης ηλεκτρονικών υπολογιστών (2)
      <ul>
        <li>Υπηρεσίες επέκτασης δυνατοτήτων ηλεκτρονικών υπολογιστών (1)
        <ul>
          <li>Υπηρεσίες επέκτασης μνήμης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες σχετιζόμενες με εφεδρικούς ηλεκτρονικούς υπολογιστές και υπηρεσίες μετατροπής καταλόγων ηλεκτρονικών υπολογιστών (2)
    <ul>
      <li>Υπηρεσίες μετατροπής καταλόγων ηλεκτρονικών υπολογιστών (0)
      </li>
      <li>Υπηρεσίες σχετιζόμενες με εφεδρικούς ηλεκτρονικούς υπολογιστές (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες ελέγχου και δοκιμών με ηλεκτρονικούς υπολογιστές (2)
    <ul>
      <li>Υπηρεσίες δοκιμών με ηλεκτρονικούς υπολογιστές (0)
      </li>
      <li>Υπηρεσίες ελέγχου με ηλεκτρονικούς υπολογιστές (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες παροχής συμβουλών και υποστήριξης Η/Υ (2)
    <ul>
      <li>Υπηρεσίες υποστήριξης ηλεκτρονικών υπολογιστών (1)
      <ul>
        <li>Υπηρεσίες τεχνικής υποστήριξης ηλεκτρονικών υπολογιστών (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες Διαδικτύου (11)
    <ul>
      <li>Υπηρεσίες ανάπτυξης Διαδικτύου (2)
      <ul>
        <li>Υπηρεσίες ανάπτυξης εφαρμογών πελατών Διαδικτύου ή ενδοδικτύων (ιντρανέτ) (0)
        </li>
        <li>Υπηρεσίες ανάπτυξης εφαρμογών εξυπηρετητή Διαδικτύου ή ενδοδικτύων (ιντρανέτ) (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες παρόχου (7)
      <ul>
        <li>Παροχείς μηχανών αναζήτησης στον Παγκόσμιο Ιστό (0)
        </li>
        <li>Παροχείς υπηρεσιών εφαρμογών (0)
        </li>
        <li>Υπηρεσίες παρόχων υπηρεσιών Διαδικτύου (ISP) (0)
        </li>
        <li>Υπηρεσίες σχεδιασμού τοποθεσιών Παγκόσμιου Ιστού (WWW) (0)
        </li>
        <li>Υπηρεσίες φιλοξενίας τοποθεσιών Παγκόσμιου Ιστού (WWW) (0)
        </li>
        <li>Ονόματα τομέα Διαδικτύου (0)
        </li>
        <li>Υπηρεσίες παρόχου ηλεκτρονικής αλληλογραφίας (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες δικτύου ηλεκτρονικών υπολογιστών (2)
    <ul>
      <li>Υπηρεσίες δικτύου ευρείας ζώνης (0)
      </li>
      <li>Υπηρεσίες τοπικού δικτύου (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες δεδομένων (21)
    <ul>
      <li>Υπηρεσίες τυποποίησης και ταξινόμησης περιεχομένου ή δεδομένων (0)
      </li>
      <li>Υπηρεσίες βάσεων δεδομένων (2)
      <ul>
        <li>Υπηρεσίες βάσεων δεδομένων προστιθέμενης αξίας (0)
        </li>
        <li>Υπηρεσίες διαχείρισης δεδομένων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επεξεργασίας δεδομένων (16)
      <ul>
        <li>Υπηρεσίες συλλογής και διαταξινόμησης δεδομένων (0)
        </li>
        <li>Υπηρεσίες ανάλυσης δεδομένων (0)
        </li>
        <li>Υπηρεσίες διαχείρισης δικτύων δεδομένων και υπηρεσίες υποστήριξης (2)
        <ul>
          <li>Υπηρεσίες διαχείρισης δικτύου δεδομένων (0)
          </li>
          <li>Υπηρεσίες υποστήριξης δικτύου δεδομένων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες εισαγωγής δεδομένων (2)
        <ul>
          <li>Υπηρεσίες οπτικής αναγνώρισης χαρακτήρων (0)
          </li>
          <li>Υπηρεσίες προετοιμασίας δεδομένων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες μετάδοσης δεδομένων (0)
        </li>
        <li>Υπηρεσίες σύλληψης δεδομένων (0)
        </li>
        <li>Υπηρεσίες προμήθειας δεδομένων (0)
        </li>
        <li>Υπηρεσίες ηλεκτρονικής πινακοποίησης δεδομένων (3)
        <ul>
          <li>Υπηρεσίες καταμερισμού χρόνου λειτουργίας ηλεκτρονικών υπολογιστών (0)
          </li>
          <li>Υπηρεσίες μετατροπής δεδομένων (0)
          </li>
          <li>Υπηρεσίες επεξεργασίας παρτίδων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες αποθήκευσης δεδομένων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες παροχής συμβουλών για θέματα εξοπλισμού πληροφορικής (5)
    <ul>
      <li>Υπηρεσίες παροχής συμβουλών για δοκιμές αποδοχής υλικού πληροφορικής (0)
      </li>
      <li>Υπηρεσίες παροχής συμβουλών σε θέματα οργάνωσης σταθμών εργασίας Η/Υ (0)
      </li>
      <li>Υπηρεσίες παροχής συμβουλών σε θέματα ελέγχου με υπολογιστές και σε θέματα υλικού πληροφορικής (0)
      </li>
      <li>Υπηρεσίες παροχής συμβουλών σε θέματα αποκατάστασης βλαβών υλικού πληροφορικής (0)
      </li>
      <li>Υπηρεσίες παροχής συμβουλών σε θέματα επιλογής υλικού πληροφορικής (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες ξενοδοχείων, εστιατορίων και λιανικού εμπορίου (38)
  <ul>
    <li className='top-level'>Υπηρεσίες πώλησης ποτών (1)
    <ul>
      <li>Υπηρεσίες διαχείρισης κυλικείου (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες λιανικού εμπορίου (0)
    </li>
    <li className='top-level'>Παροχή καταλύματος σε χώρους κατασκήνωσης και άλλους μη ξενοδοχειακούς χώρους (10)
    <ul>
      <li>Υπηρεσίες παροχής απλού καταλύματος με πρωινό (0)
      </li>
      <li>Υπηρεσίες κλιναμαξών (0)
      </li>
      <li>Υπηρεσίες χώρων κατασκήνωσης (1)
      <ul>
        <li>Υπηρεσίες χώρων για τροχόσπιτα (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες ξενώνων νεότητας (0)
      </li>
      <li>Υπηρεσίες κέντρων διακοπών και παραθεριστικών κατοικιών (3)
      <ul>
        <li>Υπηρεσίες παιδικών κατασκηνώσεων (0)
        </li>
        <li>Υπηρεσίες παραθεριστικών κατοικιών (0)
        </li>
        <li>Υπηρεσίες κέντρων παραθερισμού (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες ενοικίασης μικρής διάρκειας επιπλωμένων καταλυμάτων (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες ξενοδοχείων (3)
    <ul>
      <li>Υπηρεσίες ξενοδοχείων σε σχέση με συναντήσεις και συνέδρια (0)
      </li>
      <li>Υπηρεσίες ξενοδοχειακών καταλυμάτων (0)
      </li>
      <li>Άλλες υπηρεσίες ξενοδοχείων (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες εστιατορίου και παροχής φαγητού (7)
    <ul>
      <li>Υπηρεσίες παροχής γευμάτων (2)
      <ul>
        <li>Υπηρεσίες προετοιμασίας γευμάτων (0)
        </li>
        <li>Υπηρεσίες παρασκευής γευμάτων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες καφετέριας (0)
      </li>
      <li>Υπηρεσίες εστιατορίου με σερβιτόρους (2)
      <ul>
        <li>Υπηρεσίες εστιατορίων με σερβιτόρους περιορισμένης πελατείας (0)
        </li>
        <li>Υπηρεσίες εστιατορίων με σερβιτόρους μη περιορισμένης πελατείας (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες καντίνας και τροφοδοσίας με έτοιμα φαγητά (11)
    <ul>
      <li>Υπηρεσίες καντίνας (2)
      <ul>
        <li>Υπηρεσίες καντίνας και άλλες υπηρεσίες καφετέριας περιορισμένης πελατείας (0)
        </li>
        <li>Υπηρεσίες διαχείρισης καντίνας (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες τροφοδοσίας (7)
      <ul>
        <li>Υπηρεσίες τροφοδοσίας εταιρειών μεταφορών με έτοιμα γεύματα (0)
        </li>
        <li>Υπηρεσίες τροφοδοσίας άλλων επιχειρήσεων ή ιδρυμάτων με έτοιμα γεύματα (1)
        <ul>
          <li>Υπηρεσίες σχολικών γευμάτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες τροφοδοσίας σχολείων με έτοιμα γεύματα (0)
        </li>
        <li>Υπηρεσίες τροφοδοσίας ιδιωτικών οικιών με έτοιμα φαγητά (2)
        <ul>
          <li>Υπηρεσίες παράδοσης γευμάτων (0)
          </li>
          <li>Υπηρεσίες παροχής γευμάτων κατ’ οίκον από την κοινωνική πρόνοια (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες σχετιζόμενες με τη βιομηχανία πετρελαίου και φυσικού αερίου (66)
  <ul>
    <li className='top-level'>Επαγγελματικές υπηρεσίες για τη βιομηχανία πετρελαίου (6)
    <ul>
      <li>Υπηρεσίες σχετιζόμενες με την εξόρυξη πετρελαίου (5)
      <ul>
        <li>Υπηρεσίες ανάρτησης εσωτερικής κάλυψης σωλήνων (4)
        <ul>
          <li>Υπηρεσίες εσωτερικής κάλυψης σωλήνων (2)
          <ul>
            <li>Υπηρεσίες εσωτερικές επένδυσης του φρέατος γεώτρησης (0)
            </li>
            <li>Υπηρεσίες εσωτερικής επένδυσης του φρέατος δοκιμής (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες ελέγχου της περιεκτικότητας σε ίζημα της ιλύος των γεωτρήσεων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες τοποθέτησης γεωτρυπάνων (32)
    <ul>
      <li>Υπηρεσίες δειγματοληπτικού ελέγχου προϊόντων γεώτρησης (3)
      <ul>
        <li>Λοιπές υπηρεσίες ημερολογιακής καταγραφής δεδομένων (0)
        </li>
        <li>Υπηρεσίες ημερολογιακής καταγραφής δεδομένων φρέατος με περίβλημα (0)
        </li>
        <li>Υπηρεσίες ημερολογιακής καταγραφής δεδομένων ανοιχτού φρέατος (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες δοκιμών για πετρελαιοπηγές (3)
      <ul>
        <li>Υπηρεσίες δοκιμής της επιθεώρησης του χώρου γεώτρησης (0)
        </li>
        <li>Υπηρεσίες δοκιμής θραύσης φρέατος (0)
        </li>
        <li>Υπηρεσίες δοκιμής εξοπλισμού γεώτρησης (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες υποστήριξης πετρελαιοπηγών (0)
      </li>
      <li>Υπηρεσίες αποπεράτωσης γεωτρήσεων (2)
      <ul>
        <li>Υπηρεσίες κάλυψης γεωτρήσεων (0)
        </li>
        <li>Υπηρεσίες τοποθέτησης εξοπλισμού γεωτρήσεων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες διασωλήνωσης (0)
      </li>
      <li>Υπηρεσίες διαχείρισης πετρελαιοπηγών (0)
      </li>
      <li>Υπηρεσίες γεώτρησης και παραγωγής πετρελαίου (7)
      <ul>
        <li>Υπηρεσίες γεώτρησης (6)
        <ul>
          <li>Υπηρεσίες παρακολούθησης εξέδρας γεώτρησης (0)
          </li>
          <li>Υπηρεσίες πλευρικής ρηχής βοηθητικής γεώτρησης (0)
          </li>
          <li>Υπηρεσίες ανύψωσης σωληνώσεων γεώτρησης (0)
          </li>
          <li>Υπηρεσίες τοποθέτησης σωλήνων γεώτρησης (0)
          </li>
          <li>Υπηρεσίες επίβλεψης γεώτρησης (0)
          </li>
          <li>Υπηρεσίες ελέγχου γεώτρησης (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες τοποθέτησης σωλήνων και αγωγών σε γεώτρηση (5)
      <ul>
        <li>Υπηρεσίες τοποθέτησης περιβλημάτων γεωτρήσεων (4)
        <ul>
          <li>Υπηρεσίες εκγλύφανσης περιβλημάτων γεωτρήσεων (0)
          </li>
          <li>Υπηρεσίες αποπεράτωσης περιβλημάτων γεωτρήσεων (0)
          </li>
          <li>Υπηρεσίες συνεργείου τοποθέτησης περιβλημάτων γεωτρήσεων (0)
          </li>
          <li>Υπηρεσίες σχεδιασμού τοποθέτησης περιβλημάτων γεωτρήσεων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες έγχυσης σκυροδέματος σε πετρελαιοπηγές (3)
      <ul>
        <li>Υπηρεσίες έγχυσης σκυροδέματος σε πώματα γεώτρησης (0)
        </li>
        <li>Υπηρεσίες έγχυσης σκυροδέματος σε εσωτερικές επενδύσεις φρέατος (0)
        </li>
        <li>Υπηρεσίες έγχυσης αφρώδους σκυροδέματος (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες επιθεώρησης αγωγών (0)
    </li>
    <li className='top-level'>Υπηρεσίες ξηράς και ανοικτής θαλάσσης (13)
    <ul>
      <li>Εργασίες σωλήνωσης φρεάτων (8)
      <ul>
        <li>Υπηρεσίες διεύρυνσης σωλήνωσης φρεάτων (0)
        </li>
        <li>Υπηρεσίες καταγραφής στη σωλήνωση φρεάτων (0)
        </li>
        <li>Υπηρεσίες σε σχέση με εργαλεία που χρησιμοποιούνται στη σωλήνωση φρεάτων (1)
        <ul>
          <li>Υπηρεσίες σε σχέση με εργαλεία που χρησιμοποιούνται στη σωλήνωση φρεάτων πετρελαιοπηγών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες συνεχούς δειγματοληπτικού ελέγχου προϊόντων γεώτρησης (0)
        </li>
        <li>Υπηρεσίες διάνοιξης οπών στη σωλήνωση φρεάτων (0)
        </li>
        <li>Υπηρεσίες άντλησης στη σωλήνωση φρεάτων (0)
        </li>
        <li>Υπηρεσίες ελέγχου κραδασμών στη σωλήνωση φρεάτων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες ξηράς (0)
      </li>
      <li>Υπηρεσίες ανοικτής θαλάσσης (2)
      <ul>
        <li>Υπηρεσίες εγκαταστάσεων ανοικτής θαλάσσης (0)
        </li>
        <li>Υπηρεσίες πλοίου ανεφοδιασμού εγκαταστάσεων ανοιχτής θαλάσσης (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Επαγγελματικές υπηρεσίες για τη βιομηχανία φυσικού αερίου (4)
    <ul>
      <li>Υπηρεσίες σχετιζόμενες με την εξόρυξη αερίου (1)
      <ul>
        <li>Υπηρεσίες επαναφοράς υγροποιηθέντος αερίου σε αέρια μορφή (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες καταδύσεων σχετιζόμενες με την εξόρυξη αερίου (1)
      <ul>
        <li>Υπηρεσίες καταδύσεων σχετιζόμενες με υποβρύχια φρέατα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Εργασίες γεώτρησης (5)
    <ul>
      <li>Υπηρεσίες γεωτρήσεων ανοικτής θαλάσσης (0)
      </li>
      <li>Σωληνωτές γεωτρήσεις (0)
      </li>
      <li>Υπηρεσίες γεώτρησης με στροβίλους (1)
      <ul>
        <li>Υπηρεσίες γεώτρησης με σπειροειδείς στροβίλους (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες γεώτρησης σχετιζόμενες με την εξόρυξη αερίου (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Ψυχαγωγικές, πολιτιστικές και αθλητικές υπηρεσίες (104)
  <ul>
    <li className='top-level'>Υπηρεσίες καφετέριας παροχής υπηρεσιών κυβερνοχώρου (0)
    </li>
    <li className='top-level'>Υπηρεσίες βιβλιοθηκών, αρχείων, μουσείων και άλλες πολιτιστικές υπηρεσίες (18)
    <ul>
      <li>Υπηρεσίες βιβλιοθηκών και αρχείων (3)
      <ul>
        <li>Υπηρεσίες αρχείων (1)
        <ul>
          <li>Υπηρεσίες καταστροφής αρχείων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες βιβλιοθηκών (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες μουσείων και συναφείς υπηρεσίες (8)
      <ul>
        <li>Υπηρεσίες συντήρησης ιστορικών χώρων και κτιρίων (2)
        <ul>
          <li>Υπηρεσίες συντήρησης ιστορικών χώρων (0)
          </li>
          <li>Υπηρεσίες συντήρησης ιστορικών κτιρίων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες μουσείων (4)
        <ul>
          <li>Υπηρεσίες εκθέσεων μουσείων (0)
          </li>
          <li>Υπηρεσίες συντήρησης εκθεμάτων και συλλεκτικών τεμαχίων (2)
          <ul>
            <li>Υπηρεσίες συντήρησης συλλεκτικών τεμαχίων (0)
            </li>
            <li>Υπηρεσίες συντήρησης εκθεμάτων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες βοτανικών και ζωολογικών κήπων και προστατευόμενων φυσικών βιοτόπων (4)
      <ul>
        <li>Υπηρεσίες διατήρησης συνθηκών φυσικού περιβάλλοντος (0)
        </li>
        <li>Υπηρεσίες προστατευόμενων φυσικών βιοτόπων (0)
        </li>
        <li>Υπηρεσίες βοτανικών κήπων (0)
        </li>
        <li>Υπηρεσίες ζωολογικών κήπων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Κινηματογραφικές υπηρεσίες και υπηρεσίες βιντεοταινιών (19)
    <ul>
      <li>Υπηρεσίες προβολής κινηματογραφικών ταινιών (0)
      </li>
      <li>Υπηρεσίες προβολής βιντεοταινιών (0)
      </li>
      <li>Υπηρεσίες διανομής κινηματογραφικών ταινιών και ταινιών βίντεο (2)
      <ul>
        <li>Υπηρεσίες διανομής βιντεοταινιών (0)
        </li>
        <li>Υπηρεσίες διανομής κινηματογραφικών ταινιών (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες παραγωγής κινηματογραφικών ταινιών και βιντεοταινιών και σχετικές υπηρεσίες (13)
      <ul>
        <li>Υπηρεσίες παραγωγής κινηματογραφικών ταινιών και ταινιών βίντεο (11)
        <ul>
          <li>Παραγωγή εκπαιδευτικών ταινιών και βιντεοταινιών (0)
          </li>
          <li>Παραγωγή διαφημιστικών, προπαγανδιστικών και ενημερωτικών ταινιών και βιντεοταινιών (6)
          <ul>
            <li>Παραγωγή προπαγανδιστικών ταινιών (0)
            </li>
            <li>Παραγωγή ενημερωτικών βιντεοταινιών (0)
            </li>
            <li>Παραγωγή διαφημιστικών βιντεοταινιών (0)
            </li>
            <li>Παραγωγή προπαγανδιστικών βιντεοταινιών (0)
            </li>
            <li>Παραγωγή ενημερωτικών ταινιών (0)
            </li>
            <li>Παραγωγή διαφημιστικών ταινιών (0)
            </li>
          </ul>
          </li>
          <li>Παραγωγή ψυχαγωγικών ταινιών και βιντεοταινιών (2)
          <ul>
            <li>Παραγωγή ψυχαγωγικών βιντεοταινιών (0)
            </li>
            <li>Παραγωγή ψυχαγωγικών ταινιών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες που σχετίζονται με την παραγωγή κινηματογραφικών ταινιών και βιντεοταινιών (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες ειδησεογραφικών πρακτορείων (0)
    </li>
    <li className='top-level'>Ραδιοφωνικές και τηλεοπτικές υπηρεσίες (17)
    <ul>
      <li>Τηλεοπτικές υπηρεσίες (6)
      <ul>
        <li>Ψηφιακή τηλεόραση (0)
        </li>
        <li>Αλληλεπιδραστική τηλεόραση (1)
        <ul>
          <li>Κινηματογραφικές ταινίες κατά παραγγελία (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες τηλεοπτικών παραγωγών (0)
        </li>
        <li>Υπηρεσίες τηλεόρασης κλειστού κυκλώματος (0)
        </li>
        <li>Τηλεπρογραμματισμός (0)
        </li>
      </ul>
      </li>
      <li>Ραδιοφωνικές υπηρεσίες (6)
      <ul>
        <li>Γενικές κινητές ραδιοϋπηρεσίες/Οικογενειακές ραδιοϋπηρεσίες (GMRS/FRS) (0)
        </li>
        <li>Υπηρεσίες ραδιοφωνικών στούντιο ή εξοπλισμού (0)
        </li>
        <li>Υπηρεσίες ραδιοφωνικών συστημάτων μικρής κλίμακας (0)
        </li>
        <li>Οικογενειακές ραδιοϋπηρεσίες (FRS) (0)
        </li>
        <li>Υπηρεσίες ραδιοφωνικών παραγωγών (0)
        </li>
        <li>Γενικές κινητές ραδιοϋπηρεσίες (GMRS) (0)
        </li>
      </ul>
      </li>
      <li>Ραδιοφωνικές και τηλεοπτικές καλωδιακές υπηρεσίες (2)
      <ul>
        <li>Διεθνείς διμερείς υπηρεσίες και υπηρεσίες διεθνών ιδιωτικών μισθωμένων γραμμών (0)
        </li>
        <li>Καλωδιακή τηλεόραση (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες ψυχαγωγικού περιεχομένου (39)
    <ul>
      <li>Υπηρεσίες τεχνικών ήχου (0)
      </li>
      <li>Παροχή υπηρεσιών χορού και διασκέδασης (4)
      <ul>
        <li>Υπηρεσίες διδασκαλίας χορού (2)
        <ul>
          <li>Υπηρεσίες αιθουσών διδασκαλίας σύγχρονων χορών (0)
          </li>
          <li>Υπηρεσίες αιθουσών διδασκαλίας κλασσικού χορού (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες τσίρκων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες χώρων ψυχαγωγίας (5)
      <ul>
        <li>Υπηρεσίες λουτρικών εγκαταστάσεων παραλιών (0)
        </li>
        <li>Υπηρεσίες εκθέσεων και ψυχαγωγικών πάρκων (3)
        <ul>
          <li>Υπηρεσίες ψυχαγωγικών πάρκων (1)
          <ul>
            <li>Υπηρεσίες δημιουργικής απασχόλησης παιδιών (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες πανηγυριών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες λειτουργίας καλλιτεχνικών εγκαταστάσεων (0)
      </li>
      <li>Πυροτεχνικές υπηρεσίες (0)
      </li>
      <li>Υπηρεσίες καλλιτεχνικής και λογοτεχνικής δημιουργίας και ερμηνείας (17)
      <ul>
        <li>Καλλιτεχνικές υπηρεσίες (15)
        <ul>
          <li>Ψυχαγωγικές υπηρεσίες θεατρικών παραγωγών, συγκροτημάτων τραγουδιστών, μουσικών συγκροτημάτων και ορχηστρών (4)
          <ul>
            <li>Ψυχαγωγικές υπηρεσίες ορχηστρών (0)
            </li>
            <li>Ψυχαγωγικές υπηρεσίες μουσικών συγκροτημάτων (0)
            </li>
            <li>Ψυχαγωγικές υπηρεσίες θεατρικών παραγωγών (0)
            </li>
            <li>Ψυχαγωγικές υπηρεσίες συγκροτημάτων τραγουδιστών (0)
            </li>
          </ul>
          </li>
          <li>Παροχή υπηρεσιών από συγγραφείς, συνθέτες, γλύπτες, διασκεδαστές και άλλους μεμονωμένους καλλιτέχνες (9)
          <ul>
            <li>Υπηρεσίες παρεχόμενες από συγγραφείς (3)
            <ul>
              <li>Υπηρεσίες σχετιζόμενες με την προετοιμασία εκπαιδευτικών εγχειριδίων (0)
              </li>
              <li>Τεχνικές συγγραφικές υπηρεσίες (0)
              </li>
              <li>Υπηρεσίες πρακτορείων σύνταξης κειμένων (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες παρεχόμενες από διασκεδαστές (0)
            </li>
            <li>Υπηρεσίες παρεχόμενες από μεμονωμένους καλλιτέχνες (1)
            <ul>
              <li>Υπηρεσίες υπευθύνων μουσικής ψυχαγωγίας (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες παρεχόμενες από γλύπτες (0)
            </li>
            <li>Υπηρεσίες παρεχόμενες από συνθέτες (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Έργα τέχνης (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες τυχερών παιχνιδιών και στοιχημάτων (6)
      <ul>
        <li>Υπηρεσίες τυχερών παιχνιδιών (2)
        <ul>
          <li>Υπηρεσίες λειτουργίας καζίνου (0)
          </li>
          <li>Υπηρεσίες λαχειοφόρου αγοράς (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες στοιχημάτων (2)
        <ul>
          <li>Υπηρεσίες πρακτορείων στοιχημάτων (0)
          </li>
          <li>Υπηρεσίες λειτουργίας αθροιστικών μηχανών στοιχημάτων ιπποδρομιών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Αθλητικές υπηρεσίες (4)
    <ul>
      <li>Υπηρεσίες σχετιζόμενες με τον αθλητισμό (2)
      <ul>
        <li>Υπηρεσίες οργάνωσης αθλητικών εκδηλώσεων (0)
        </li>
        <li>Υπηρεσίες προώθησης αθλητικών εκδηλώσεων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες λειτουργίας αθλητικών εγκαταστάσεων (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Βοηθητικές και επικουρικές υπηρεσίες μεταφορών· υπηρεσίες ταξιδιωτικών πρακτορείων (84)
  <ul>
    <li className='top-level'>Υπηρεσίες ταξιδιωτικών πρακτορείων και οργανωμένων περιηγήσεων καθώς και υπηρεσίες παροχής βοήθειας σε τουρίστες (12)
    <ul>
      <li>Υπηρεσίες ταξιδιωτικών πρακτορείων και συναφείς υπηρεσίες (6)
      <ul>
        <li>Υπηρεσίες πώλησης τουριστικών εισιτηρίων και οργανωμένων εκδρομών (0)
        </li>
        <li>Ταξιδιωτικές υπηρεσίες (0)
        </li>
        <li>Υπηρεσίες οργανωμένων εκδρομών (0)
        </li>
        <li>Υπηρεσίες παροχής τουριστικών πληροφοριών (0)
        </li>
        <li>Υπηρεσίες διαχείρισης ταξιδίων (0)
        </li>
        <li>Υπηρεσίες ξενάγησης (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες γραφείων μεταφορών (4)
      <ul>
        <li>Υπηρεσίες ελλιμενισμού και πρακτορείων μεταφορών (0)
        </li>
        <li>Υπηρεσίες γραφείων μεταφορών εμπορευμάτων (0)
        </li>
        <li>Υπηρεσίες ναυλομεσιτών (0)
        </li>
        <li>Υπηρεσίες διεκπεραίωσης εγγράφων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες διακίνησης και αποθήκευσης εμπορευμάτων (10)
    <ul>
      <li>Υπηρεσίες αποθήκευσης εμπορευμάτων (4)
      <ul>
        <li>Υπηρεσίες αποθήκευσης αγαθών (0)
        </li>
        <li>Υπηρεσίες αποθήκευσης και ανάκτησης (2)
        <ul>
          <li>Υπηρεσίες αποθήκευσης (1)
          <ul>
            <li>Υπηρεσίες αποθήκευσης αερίου (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες διακίνησης εμπορευμάτων (4)
      <ul>
        <li>Υπηρεσίες διακίνησης εμπορευματοκιβωτίων (0)
        </li>
        <li>Υπηρεσίες διακίνησης αποσκευών (2)
        <ul>
          <li>Υπηρεσίες διακίνησης αποσκευών επιβατών (1)
          <ul>
            <li>Υπηρεσίες περισυλλογής αποσκευών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Βοηθητικές υπηρεσίες χερσαίων, πλωτών και εναέριων μεταφορών (59)
    <ul>
      <li>Υπηρεσίες υποστήριξης αεροπορικών μεταφορών (5)
      <ul>
        <li>Υπηρεσίες ανεφοδιασμού αεροσκαφών με καύσιμα (0)
        </li>
        <li>Υπηρεσίες ελέγχου εναέριας κυκλοφορίας (0)
        </li>
        <li>Υπηρεσίες υποστέγου συντήρησης αεροσκαφών (0)
        </li>
        <li>Υπηρεσίες λειτουργίας αερολιμένος (1)
        <ul>
          <li>Υπηρεσίες συντονισμού χρόνου χρήσης αερολιμένων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Βοηθητικές υπηρεσίες χερσαίων μεταφορών (17)
      <ul>
        <li>Βοηθητικές υπηρεσίες σιδηροδρομικών μεταφορών (2)
        <ul>
          <li>Υπηρεσίες παρακολούθησης αμαξοστοιχιών (0)
          </li>
          <li>Υπηρεσίες κινητών συνεργείων (0)
          </li>
        </ul>
        </li>
        <li>Βοηθητικές υπηρεσίες οδικών μεταφορών (13)
        <ul>
          <li>Υπηρεσίες χώρων στάθμευσης οχημάτων (0)
          </li>
          <li>Υπηρεσίες λειτουργίας γεφυρών και σηράγγων (4)
          <ul>
            <li>Υπηρεσίες λειτουργίας γεφυρών (1)
            <ul>
              <li>Υπηρεσίες διοδίων γεφυρών (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες λειτουργίας σηράγγων (1)
            <ul>
              <li>Υπηρεσίες διοδίων σηράγγων (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες λειτουργίας αυτοκινητοδρόμων (1)
          <ul>
            <li>Υπηρεσίες διοδίων αυτοκινητοδρόμων (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες ανεφοδιασμού οχημάτων (0)
          </li>
          <li>Υπηρεσίες γεφυροπλάστιγγας (0)
          </li>
          <li>Υπηρεσίες ελέγχου κυκλοφορίας (1)
          <ul>
            <li>Υπηρεσίες παρακολούθησης κυκλοφορίας (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες σταθμών λεωφορείων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες υποστήριξης μεταφορών με πλωτά μέσα (34)
      <ul>
        <li>Υπηρεσίες λειτουργίας λιμανιών και υδάτινων οδών και συναφείς υπηρεσίες (5)
        <ul>
          <li>Υπηρεσίες ανεφοδιασμού πλοίων (0)
          </li>
          <li>Υπηρεσίες λειτουργίας υδάτινων οδών (0)
          </li>
          <li>Υπηρεσίες τερματικών σταθμών επιβατών (0)
          </li>
          <li>Υπηρεσίες εφοδιασμού πλοίων με καύσιμα (0)
          </li>
          <li>Υπηρεσίες λειτουργίας λιμανιών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες ρυμούλκησης και ώθησης πλοίων (2)
        <ul>
          <li>Υπηρεσίες ρυμούλκησης (0)
          </li>
          <li>Υπηρεσίες ώθησης (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες διάσωσης σκαφών και επαναφοράς τους σε κατάσταση πλευστότητας (3)
        <ul>
          <li>Υπηρεσίες διάσωσης πλοίων (0)
          </li>
          <li>Υπηρεσίες ανέλκυσης πλοίων (0)
          </li>
          <li>Υπηρεσίες εφεδρικού πλοίου (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες ελλιμενισμού πλοίων (0)
        </li>
        <li>Διάφορες υπηρεσίες υποστήριξης μεταφορών με πλωτά μέσα (11)
        <ul>
          <li>Υπηρεσίες χειρισμού αγκυρών (0)
          </li>
          <li>Υπηρεσίες φόρτωσης σκαφών στην ξηρά (0)
          </li>
          <li>Υπηρεσίες νηολόγησης πλοίων (0)
          </li>
          <li>Υπηρεσίες ναύλωσης σκαφών (0)
          </li>
          <li>Υπηρεσίες ερευνητικού σκάφους (0)
          </li>
          <li>Υπηρεσίες προσωρινής απόσυρσης σκαφών (0)
          </li>
          <li>Υπηρεσίες λειτουργίας πλοίων (2)
          <ul>
            <li>Υπηρεσίες υποβρύχιου τηλεχειριζόμενου σκάφους (0)
            </li>
            <li>Υπηρεσίες καθέλκυσης πλοίου (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες παγοθραυστικού σκάφους (0)
          </li>
          <li>Υπηρεσίες αλιευτικού σκάφους (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες πλοήγησης πλοίων (0)
        </li>
        <li>Υπηρεσίες ναυσιπλοΐας (6)
        <ul>
          <li>Υπηρεσίες εντοπισμού στίγματος ανοικτής θαλάσσης (1)
          <ul>
            <li>Υπηρεσίες προσδιορισμού στίγματος πλωτών φάρων (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες φάρων (0)
          </li>
          <li>Υπηρεσίες προσδιορισμού στίγματος σημαδούρων (1)
          <ul>
            <li>Υπηρεσίες σήμανσης σημαδούρων (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες πλωτού φάρου (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες επισκευής και συντήρησης (223)
  <ul>
    <li className='top-level'>Υπηρεσίες επισκευής και συντήρησης αντλιών, δικλείδων, στροφίγγων, μεταλλικών δοχείων και μηχανημάτων (23)
    <ul>
      <li>Υπηρεσίες επισκευής και συντήρησης μηχανημάτων (12)
      <ul>
        <li>Υπηρεσίες επισκευής και συντήρησης μη ηλεκτρικών μηχανημάτων (6)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης γερανών-πύργων (1)
          <ul>
            <li>Υπηρεσίες αποσυναρμολόγησης πύργων γεώτρησης (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης γερανών (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης συμπιεστών (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης καυστήρων (0)
          </li>
          <li>Υπηρεσίες συντήρησης συσκευών αερίου (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης ηλεκτρικών μηχανημάτων, συσκευών και συναφούς εξοπλισμού (4)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης μετασχηματιστών (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης γεννητριών (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού διανομής ηλεκτρικού ρεύματος (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης ηλεκτρικών κινητήρων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης αντλιών, δικλείδων, στροφίγγων και μεταλλικών δοχείων (9)
      <ul>
        <li>Υπηρεσίες επισκευής και συντήρησης μεταλλικών δοχείων (3)
        <ul>
          <li>Υπηρεσίες επισκευής περιβλήματος σωληνώσεων (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης βυτίων (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης δεξαμενών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης αντλιών (2)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης αντλιών αερίου (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης αντλιών υγρών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης δικλείδων (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης στροφίγγων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες επισκευής και συντήρησης εγκαταστάσεων κτιρίου (9)
    <ul>
      <li>Υπηρεσίες επισκευής και συντήρησης ψυκτικών συγκροτημάτων (0)
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης εγκαταστάσεων κεντρικής θέρμανσης (1)
      <ul>
        <li>Θέση σε λειτουργία εγκαταστάσεων θέρμανσης (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης ηλεκτρομηχανολογικών εγκαταστάσεων κτιρίου (2)
      <ul>
        <li>Υπηρεσίες επισκευής και συντήρησης ηλεκτρολογικών εγκαταστάσεων κτιρίου (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης μηχανολογικών εγκαταστάσεων κτιρίου (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης κυλιόμενων κλιμάκων (0)
      </li>
      <li>Υπηρεσίες συντήρησης ανελκυστήρων (0)
      </li>
      <li>Επισκευή και συντήρηση δημόσιων αποχωρητηρίων (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Διάφορες υπηρεσίες επισκευής και συντήρησης (16)
    <ul>
      <li>Υπηρεσίες επιδιόρθωσης ενδυμάτων και υφασμάτινων ειδών (0)
      </li>
      <li>Υπηρεσίες επισκευής δερμάτινων προσωπικών ειδών (2)
      <ul>
        <li>Υπηρεσίες επιδιόρθωσης μποτών (0)
        </li>
        <li>Υπηρεσίες επιδιόρθωσης υποδημάτων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης επίπλων (0)
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης μουσικών οργάνων (0)
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού ξενοδοχείου και εστιατορίου (4)
      <ul>
        <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού ξενοδοχείου (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού εστιατορίου (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού κατασκήνωσης (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού τροφοδοσίας (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού παιδικής χαράς (0)
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης όπλων και οπλικών συστημάτων (2)
      <ul>
        <li>Υπηρεσίες επισκευής και συντήρησης όπλων (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης οπλικών συστημάτων (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής κοσμημάτων (0)
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες επισκευής, συντήρησης και παρεπόμενες υπηρεσίες για οχήματα και συναφή εξοπλισμό (40)
    <ul>
      <li>Διάλυση οχημάτων (0)
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης μηχανοκίνητων οχημάτων και παρεπόμενου εξοπλισμού (38)
      <ul>
        <li>Υπηρεσίες επισκευής και συντήρησης φορτηγών αυτοκινήτων (2)
        <ul>
          <li>Υπηρεσίες συντήρησης φορτηγών αυτοκινήτων (0)
          </li>
          <li>Υπηρεσίες επισκευής φορτηγών αυτοκινήτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης αυτοκινήτων (6)
        <ul>
          <li>Υπηρεσίες επισκευής αυτοκινήτων (3)
          <ul>
            <li>Υπηρεσίες επισκευής αμαξωμάτων οχημάτων (1)
            <ul>
              <li>Υπηρεσίες σφυρηλάτησης ελασμάτων (0)
              </li>
            </ul>
            </li>
            <li>Υπηρεσίες αντικατάστασης παρμπρίζ (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες συντήρησης αυτοκινήτων (0)
          </li>
          <li>Πλύσιμο αυτοκινήτων και παρόμοιες υπηρεσίες (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες διαχείρισης, επισκευής και συντήρησης στόλου οχημάτων (2)
        <ul>
          <li>Υπηρεσίες διαχείρισης στόλου οχημάτων (1)
          <ul>
            <li>Υπηρεσίες υποστήριξης στόλου οχημάτων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης μοτοσικλετών (2)
        <ul>
          <li>Υπηρεσίες επισκευής μοτοσικλετών (0)
          </li>
          <li>Υπηρεσίες συντήρησης μοτοσικλετών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες συντήρησης και επισκευής για ειδικά μέρη οχημάτων (7)
        <ul>
          <li>Υπηρεσίες επισκευής, προσαρμογής και ζυγοστάθμισης ελαστικών (1)
          <ul>
            <li>Υπηρεσίες αναγόμωσης ελαστικών (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης του συστήματος εκκίνησης (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης συστημάτων πέδησης οχημάτων (0)
          </li>
          <li>Υπηρεσίες επισκευής ηλεκτρολογικών συστημάτων (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης συστημάτων μετάδοσης κίνησης οχημάτων (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης κιβωτίων ταχυτήτων οχημάτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες οδικής βοήθειας (6)
        <ul>
          <li>Υπηρεσίες επισκευής βλαβών μηχανοκίνητων οχημάτων (0)
          </li>
          <li>Υπηρεσίες επισκευής βλαβών μοτοσικλετών (0)
          </li>
          <li>Υπηρεσίες επισκευής βλαβών επαγγελματικών οχημάτων (0)
          </li>
          <li>Υπηρεσίες επισκευής βλαβών αυτοκινήτων (1)
          <ul>
            <li>Υπηρεσίες απομάκρυνσης οχημάτων με γερανό (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες επισκευής βλαβών λεωφορείων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες μετατροπής και αποκατάστασης οχημάτων (3)
        <ul>
          <li>Υπηρεσίες συνολικής αποκατάστασης οχημάτων (0)
          </li>
          <li>Υπηρεσίες μετατροπής οχημάτων (0)
          </li>
          <li>Υπηρεσίες μετατροπής ασθενοφόρων οχημάτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης λεωφορείων (2)
        <ul>
          <li>Υπηρεσίες συντήρησης λεωφορείων (0)
          </li>
          <li>Υπηρεσίες επισκευής λεωφορείων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Επισκευή, συντήρηση και παρεπόμενες υπηρεσίες σε αεροσκάφη, σιδηροδρομικά και οδικά οχήματα και σκάφη (42)
    <ul>
      <li>Υπηρεσίες επισκευής, συντήρησης και συναφείς υπηρεσίες σε αεροσκάφη και λοιπό εξοπλισμό (9)
      <ul>
        <li>Υπηρεσίες επισκευής και συντήρησης ελικοπτέρων (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης αεροσκαφών (7)
        <ul>
          <li>Υπηρεσίες γενικής επισκευής αεροσκαφών (1)
          <ul>
            <li>Υπηρεσίες γενικής επισκευής κινητήρων αεροσκαφών (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες συντήρησης αεροσκαφών (0)
          </li>
          <li>Υπηρεσίες επισκευής αεροσκαφών (3)
          <ul>
            <li>Υπηρεσίες επισκευής και συντήρησης κινητήρων αεροσκαφών (2)
            <ul>
              <li>Υπηρεσίες επισκευής κινητήρων αεροσκαφών (0)
              </li>
              <li>Υπηρεσίες συντήρησης κινητήρων αεροσκαφών (0)
              </li>
            </ul>
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής, συντήρησης και συναφείς υπηρεσίες για οδούς και λοιπό εξοπλισμό (4)
      <ul>
        <li>Συντήρηση εγκαταστάσεων δημόσιου φωτισμού και φωτεινών σηματοδοτών (3)
        <ul>
          <li>Συντήρηση εγκαταστάσεων δημόσιου φωτισμού (1)
          <ul>
            <li>Θέση σε λειτουργία εγκαταστάσεων δημόσιου φωτισμού (0)
            </li>
          </ul>
          </li>
          <li>Συντήρηση εγκαταστάσεων φωτεινών σηματοδοτών (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης και συναφείς υπηρεσίες σχετιζόμενες με σκάφη και λοιπό εξοπλισμό (12)
      <ul>
        <li>Διάλυση πλοίων (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης πλοίων (2)
        <ul>
          <li>Υπηρεσίες επισκευής πλοίων (0)
          </li>
          <li>Υπηρεσίες επισκευής πορθμείων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες μετατροπής πλοίων (0)
        </li>
        <li>Υπηρεσίες βελτίωσης πλοίων (0)
        </li>
        <li>Υπηρεσίες συντήρησης εξοπλισμού λιμένων (4)
        <ul>
          <li>Υπηρεσίες συντήρησης σημαδούρων (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης πλωτών εξεδρών (0)
          </li>
          <li>Υπηρεσίες ξηρού δεξαμενισμού πλοίων (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης πλωτών εγκαταστάσεων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες γενικής επισκευής πλοίων και σκαφών (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης και συναφείς υπηρεσίες για σιδηροδρομικό υλικό και άλλο εξοπλισμό (13)
      <ul>
        <li>Διάλυση τροχαίου υλικού (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης τροχαίου υλικού (1)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης αποσβεστήρων δονήσεων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες γενικής επισκευής μηχανών έλξης συρμών (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης μηχανών έλξης συρμών (4)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης συστημάτων πέδησης μηχανών έλξης συρμών (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης συστημάτων μετάδοσης κίνησης μηχανών έλξης συρμών (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης κιβωτίων ταχυτήτων μηχανών έλξης συρμών (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης τροχών μηχανών έλξης συρμών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες γενικής επισκευής τροχαίου υλικού (2)
        <ul>
          <li>Υπηρεσίες επιδιόρθωσης καθισμάτων τροχαίου υλικού (0)
          </li>
          <li>Υπηρεσίες γενικής επισκευής επιβατικών βαγονιών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες συντήρησης τροχιοδρόμων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Επισκευή, συντήρηση και συναφείς υπηρεσίες για προσωπικούς Η/Υ, εξοπλισμό γραφείου, τηλεπικοινωνίες και οπτικοακουστικό εξοπλισμό (59)
    <ul>
      <li>Υπηρεσίες επισκευής και συντήρησης προσωπικών Η/Υ (8)
      <ul>
        <li>Συντήρηση και επισκευή περιφερειακού εξοπλισμού ηλεκτρονικών υπολογιστών (2)
        <ul>
          <li>Επισκευή περιφερειακού εξοπλισμού ηλεκτρονικών υπολογιστών (0)
          </li>
          <li>Συντήρηση περιφερειακού εξοπλισμού ηλεκτρονικών υπολογιστών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής προσωπικών Η/Υ (0)
        </li>
        <li>Υπηρεσίες συντήρησης προσωπικών Η/Υ (0)
        </li>
        <li>Υπηρεσίες υποστήριξης προσωπικών Η/Υ (2)
        <ul>
          <li>Υπηρεσίες προληπτικής συντήρησης (0)
          </li>
          <li>Υπηρεσίες συντήρησης συστημάτων (0)
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες συντήρησης εξοπλισμού τηλεπικοινωνίας (14)
      <ul>
        <li>Υπηρεσίες συντήρησης υποδομής τηλεπικοινωνιών (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης ενσύρματου τηλεφωνικού και τηλεγραφικού εξοπλισμού (8)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης ενσύρματου τηλεγραφικού εξοπλισμού (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης ενσύρματου εξοπλισμού τηλετύπου (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης ενσύρματου τηλεφωνικού εξοπλισμού (4)
          <ul>
            <li>Υπηρεσίες επισκευής και συντήρησης συσκευών τηλεφωνίας (0)
            </li>
            <li>Εκσυγχρονισμός τηλεφωνικού κέντρου (0)
            </li>
            <li>Συντήρηση τηλεφωνικού δικτύου (0)
            </li>
            <li>Υπηρεσίες επισκευής και συντήρησης τηλεφωνικών κέντρων (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες συντήρησης συστήματος επικοινωνίας (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες συντήρησης εξοπλισμού ασύρματης επικοινωνίας (2)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης συσκευών ραδιοτηλεφωνίας (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης ασύρματων πομπών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης γραμμών τηλεπικοινωνίας (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης οπτικοακουστικού εξοπλισμού (8)
      <ul>
        <li>Υπηρεσίες επισκευής και συντήρησης τηλεοπτικού εξοπλισμού (2)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού διαλογικής τηλεοπτικογραφίας (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης τηλεοπτικών πομπών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού βίντεο (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης οπτικού εξοπλισμού (2)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης φωτογραφικού εξοπλισμού (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης κινηματογραφικού εξοπλισμού (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης ακουστικού εξοπλισμού (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες συντήρησης και επισκευής μηχανημάτων γραφείου (25)
      <ul>
        <li>Υπηρεσίες συντήρησης και επισκευής μηχανών ακύρωσης εισιτηρίων (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης συσκευών τηλεομοιοτυπίας (0)
        </li>
        <li>Υπηρεσίες συντήρησης και επισκευής λογιστικών μηχανών γραφείου (1)
        <ul>
          <li>Υπηρεσίες συντήρησης και επισκευής αριθμομηχανών και λογιστικών μηχανών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης μηχανημάτων αυτόματων τηλεφωνητών (0)
        </li>
        <li>Υπηρεσίες συντήρησης και επισκευής μηχανών αναπαραγωγής (2)
        <ul>
          <li>Υπηρεσίες επισκευής φωτοαντιγραφικών μηχανημάτων (0)
          </li>
          <li>Υπηρεσίες συντήρησης φωτοαντιγραφικών μηχανημάτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες συντήρησης και επισκευής εξοπλισμού ηλεκτρονικών υπολογιστών (15)
        <ul>
          <li>Επισκευή και συντήρηση εξοπλισμού δικτύου δεδομένων (2)
          <ul>
            <li>Επισκευή εξοπλισμού δικτύου δεδομένων (0)
            </li>
            <li>Συντήρηση εξοπλισμού δικτύου δεδομένων (0)
            </li>
          </ul>
          </li>
          <li>Συντήρηση και επισκευή μικροϋπολογιστών (2)
          <ul>
            <li>Επισκευή μικροϋπολογιστών (0)
            </li>
            <li>Συντήρηση μικροϋπολογιστών (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες συντήρησης και επισκευής κεντρικών υπολογιστών (2)
          <ul>
            <li>Επισκευή κεντρικών υπολογιστών (0)
            </li>
            <li>Συντήρηση κεντρικών υπολογιστών (0)
            </li>
          </ul>
          </li>
          <li>Συντήρηση και επισκευή εξοπλισμού τεχνολογίας των πληροφοριών (2)
          <ul>
            <li>Επισκευή εξοπλισμού τεχνολογίας των πληροφοριών (0)
            </li>
            <li>Συντήρηση εξοπλισμού τεχνολογίας των πληροφοριών (0)
            </li>
          </ul>
          </li>
          <li>Συντήρηση και επισκευή μεσαίων υπολογιστών (2)
          <ul>
            <li>Συντήρηση μεσαίων υπολογιστών (0)
            </li>
            <li>Επισκευή μεσαίων υπολογιστών (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης μηχανών έκδοσης εισιτηρίων (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες επισκευής και συντήρησης ιατρικού εξοπλισμού και εξοπλισμού ακριβείας (20)
    <ul>
      <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού ακριβείας (3)
      <ul>
        <li>Υπηρεσίες επισκευής και συντήρησης ρολογιών τοίχου (0)
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης ρολογιών χειρός (0)
        </li>
        <li>Υπηρεσίες βαθμονόμησης (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης ιατρικού και χειρουργικού εξοπλισμού (4)
      <ul>
        <li>Υπηρεσίες επισκευής και συντήρησης ιατρικού εξοπλισμού (2)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης αναπηρικών καρεκλών (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού ακτινογραφίας (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης χειρουργικού εξοπλισμού (0)
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης συσκευών μέτρησης, δοκιμών και ελέγχου (10)
      <ul>
        <li>Υπηρεσίες επισκευής και συντήρησης συσκευών ελέγχου (2)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού πυρόσβεσης (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού ανίχνευσης αερίου (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης συσκευών μέτρησης (5)
        <ul>
          <li>Υπηρεσίες επισκευής και συντήρησης μετρητών αερίου (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης βιομηχανικών χρονομέτρων (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης μετρητών ηλεκτρικού ρεύματος (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης ταχυμέτρων (0)
          </li>
          <li>Υπηρεσίες επισκευής και συντήρησης μετρητών νερού (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες επισκευής και συντήρησης συσκευών δοκιμών (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες επισκευής και συντήρησης υλικών ασφαλείας και άμυνας (6)
    <ul>
      <li>Υπηρεσίες επισκευής και συντήρησης στρατιωτικών ηλεκτρονικών συστημάτων (0)
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης πυροβόλων όπλων και πυρομαχικών (0)
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού στρατιωτικών αεροσκαφών, πυραύλων και διαστημικών αεροσκαφών (0)
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης στρατιωτικών οχημάτων (0)
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης πολεμικών πλοίων (0)
      </li>
      <li>Υπηρεσίες επισκευής και συντήρησης εξοπλισμού ασφαλείας (0)
      </li>
    </ul>
    </li>
  </ul>
  </li>
  <li className='top-level'>Υπηρεσίες ταχυδρομείων και τηλεπικοινωνιών (54)
  <ul>
    <li className='top-level'>Υπηρεσίες τηλεπικοινωνιών (40)
    <ul>
      <li>Υπηρεσίες τηλεφωνίας και μετάδοσης δεδομένων (28)
      <ul>
        <li>Υπηρεσίες αποκλειστικών τηλεφωνικών δικτύων επιχειρήσεων (3)
        <ul>
          <li>Υπηρεσίες τηλεφωνικού κέντρου (0)
          </li>
          <li>Υπηρεσίες ενοικίασης δορυφορικών κυκλωμάτων (0)
          </li>
          <li>Υπηρεσίες ενοικίασης επίγειων επικοινωνιακών γραμμών (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες κινητής τηλεφωνίας (9)
        <ul>
          <li>Υπηρεσίες αποστολής σύντομων γραπτών μηνυμάτων (SMS) (0)
          </li>
          <li>Υπηρεσίες βελτιωμένης μηνυματοδοσίας (EMS) (0)
          </li>
          <li>Υπηρεσίες παρόχου προπληρωμένων τηλεφωνικών καρτών (0)
          </li>
          <li>Υπηρεσίες πρωτοκόλλου ασύρματων εφαρμογών (WAP) (0)
          </li>
          <li>Υπηρεσίες παρόχου κοινόχρηστης τηλεφωνίας (0)
          </li>
          <li>Υπηρεσίες γενικής πακετικής ραδιοϋπηρεσίας (GPRS) (0)
          </li>
          <li>Υπηρεσίες βελτιωμένων δεδομένων για εξέλιξη GSM (EDGE) (0)
          </li>
          <li>Υπηρεσίες παγκόσμιου συστήματος κινητών τηλεπικοινωνιών (UMTS) (0)
          </li>
          <li>Υπηρεσίες πολυμεσικής μηνυματοδοσίας (MMS) (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες τηλεφωνίας πρωτοκόλλου Διαδικτύου (IP) (0)
        </li>
        <li>Υπηρεσίες δημόσιας τηλεφωνίας (2)
        <ul>
          <li>Υπηρεσίες αστικής τηλεφωνίας (0)
          </li>
          <li>Υπηρεσίες υπεραστικής τηλεφωνίας (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες τηλεφωνικών δικτύων μεικτής επιχειρησιακής χρήσης (0)
        </li>
        <li>Υπηρεσίες ηλεκτρονικών μηνυμάτων και πληροφοριών (8)
        <ul>
          <li>Υπηρεσίες ηλεκτρονικής πληροφόρησης (1)
          <ul>
            <li>Υπηρεσίες πληροφόρησης προστιθέμενης αξίας (0)
            </li>
          </ul>
          </li>
          <li>Υπηρεσίες σχετιζόμενες με τη διαλογική τηλεοπτικογραφία (0)
          </li>
          <li>Υπηρεσίες ηλεκτρονικών μηνυμάτων (4)
          <ul>
            <li>Υπηρεσίες ηλεκτρονικού ταχυδρομείου (0)
            </li>
            <li>Υπηρεσίες τηλεγραφίας (0)
            </li>
            <li>Υπηρεσίες τέλεξ (0)
            </li>
            <li>Υπηρεσίες ανταλλαγής ηλεκτρονικών δεδομένων (0)
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      <li>Υπηρεσίες τηλεπικοινωνιών εκτός από υπηρεσίες τηλεφωνίας και μετάδοσης δεδομένων (10)
      <ul>
        <li>Υπηρεσίες τηλεδιασκέψεων (0)
        </li>
        <li>Υπηρεσίες τηλεργασίας (0)
        </li>
        <li>Ολοκληρωμένες υπηρεσίες τηλεπικοινωνιών (0)
        </li>
        <li>Υπηρεσίες διασύνδεσης (0)
        </li>
        <li>Υπηρεσίες τηλεματικής (0)
        </li>
        <li>Υπηρεσίες μετάδοσης τηλεοπτικών και ραδιοφωνικών προγραμμάτων (2)
        <ul>
          <li>Υπηρεσίες μετάδοσης ραδιοφωνικών προγραμμάτων (0)
          </li>
          <li>Υπηρεσίες μετάδοσης τηλεοπτικών προγραμμάτων (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες τηλεειδοποίησης (0)
        </li>
        <li>Υπηρεσίες τηλεπικοινωνιών αέρος-εδάφους (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
    <li className='top-level'>Υπηρεσίες κρατικών και ιδιωτικών ταχυδρομείων (12)
    <ul>
      <li>Υπηρεσίες ιδιωτικών ταχυδρομικών γραφείων (4)
      <ul>
        <li>Υπηρεσίες ιδιωτικών ταχυδρομικών γραφείων πολλαπλής φύσης (2)
        <ul>
          <li>Υπηρεσίες παράδοσης δεμάτων (0)
          </li>
          <li>Υπηρεσίες παράδοσης αλληλογραφίας (0)
          </li>
        </ul>
        </li>
        <li>Υπηρεσίες ενδοϋπηρεσιακής αλληλογραφίας και ταχυδρόμησης (0)
        </li>
      </ul>
      </li>
      <li>Ταχυδρομικές υπηρεσίες (6)
      <ul>
        <li>Ταχυδρομικές υπηρεσίες που αφορούν επιστολές (0)
        </li>
        <li>Υπηρεσίες ποστ ρεστάντ (0)
        </li>
        <li>Ταχυδρομικές υπηρεσίες που αφορούν εφημερίδες και περιοδικά (0)
        </li>
        <li>Ταχυδρομικές υπηρεσίες συναλλαγής με το κοινό (0)
        </li>
        <li>Υπηρεσίες ενοικίασης ταχυδρομικών θυρίδων (0)
        </li>
        <li>Ταχυδρομικές υπηρεσίες που αφορούν δέματα (0)
        </li>
      </ul>
      </li>
    </ul>
    </li>
  </ul>
  </li>
      </ul>
    </div>
  )};
  
  export default CategoriesAll;
  