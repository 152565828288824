import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getAccessToken, getRefreshToken } from "../hooks/user.actions";


const axiosService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,  // Adjust this baseURL as needed
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to add the access token to the headers
axiosService.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

// Response interceptor to handle errors
axiosService.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

// Function to handle token refresh
const refreshAuthLogic = async (failedRequest) => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    console.error("No refresh token available");
    localStorage.removeItem("auth");
    return Promise.reject("No refresh token available");
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/refresh/`,  // Ensure this URL is correct
      { refresh: refreshToken },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Get the existing auth object from localStorage
    const existingAuth = JSON.parse(localStorage.getItem("auth"));

    // Update the access token
    const newAccessToken = response.data.access;
    const newRefreshToken = response.data.refresh || existingAuth.refresh;  // Use existing refresh token if not returned
    const newUser = response.data.user || existingAuth.user;  // Use existing user info if not returned

    const updatedAuth = {
      access: newAccessToken,
      refresh: newRefreshToken,
      user: newUser,
    };

    localStorage.setItem("auth", JSON.stringify(updatedAuth));

    // Update the failed request with the new access token
    failedRequest.response.config.headers["Authorization"] = `Bearer ${newAccessToken}`;

    return Promise.resolve();
  } catch (error) {

    localStorage.removeItem("auth");
    //history.push("/login");
    window.location.reload(); // Force a page reload
    //return Promise.reject(error);
    return Promise.resolve(); // Don't reject the promise

  }
};

// Creating the auth refresh interceptor
createAuthRefreshInterceptor(axiosService, refreshAuthLogic);

// Fetcher function for convenience
export function fetcher(url) {
  return axiosService.get(url).then((res) => res.data);
}

export default axiosService;
