import React from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../hooks/user.actions";

function ProfileDetails(props) {
  const { user } = props;
  const navigate = useNavigate();

  if (!user) {
    return (
      <div className="flex flex-col gap-4 w-[80vw] md:w-[30vw]  m-auto">
        <div className="flex gap-4 items-center">
          <div className="skeleton w-16 h-16 rounded-full shrink-0"></div>
          <div className="flex flex-col gap-4">
            <div className="skeleton h-4 w-20"></div>
            <div className="skeleton h-4 w-28"></div>
          </div>
        </div>
        <div className="skeleton h-[20vh] w-full"></div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center m-auto w-full flex-col">
      <div className="flex items-center p5">
        <img
          src='/user.png'
          className="m-2 border-primary border-2 self-center rounded-full"
          width={120}
          height={120}
          alt="Avatar"
        />
      </div>
      <div className="flex flex-col justify-start self-center mt-2 mx-10">
        <p className="text-xl m-0">{user.email}</p>
        <p className="text-sm"></p>
        {user.id === getUser().id && (
          <button
            className="btn btn-primary rounded mt-4"
            onClick={() => navigate(`/profile/${user.id}/change-password/`)}
          >
            Αλλαγή Κωδικού
          </button>
        )}
      </div>
      <div className="flex flex-col justify-start self-center mt-2 mx-10">
        <h3 className="text-lg m-0">Πληροφορίες Συνδομής</h3>
        <p className="text-sm m-0">Κατάσταση: {user.membership.status}</p>
        <p className="text-sm m-0">Εναρξη: {new Date(user.membership.start_date).toLocaleDateString()}</p>
        {user.membership.end_date && (
          <p className="text-sm m-0">Λήγει Στις: {new Date(user.membership.end_date).toLocaleDateString()}</p>
        )}
      </div>
    </div>
  );
}

export default ProfileDetails;
