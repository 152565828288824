// src/helpers/context/toasterContext.js
import React, { createContext, useState, useContext } from 'react';

const ToasterContext = createContext();

export const ToasterProvider = ({ children }) => {
  const [toaster, setToaster] = useState({
    show: false,
    type: 'info',
    title: '',
    message: '',
    autoHide: true,
    autoHideDuration: 1500,
  });

  const updateToaster = (newToaster) => {
    setToaster((prevToaster) => ({
      ...prevToaster,
      ...newToaster,
      autoHideDuration: newToaster.autoHideDuration !== undefined ? newToaster.autoHideDuration : prevToaster.autoHideDuration,
    }));
  };

  return (
    <ToasterContext.Provider value={{ toaster, setToaster: updateToaster }}>
      {children}
    </ToasterContext.Provider>
  );
};

export const useToaster = () => {
  return useContext(ToasterContext);
};
