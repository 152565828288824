import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUserActions } from "../../hooks/user.actions";
import { useToaster } from "../../helpers/context/toasterContext";

function UpdateProfileForm(props) {
  const { profile } = props;
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    ...profile
  });
  const [error, setError] = useState(null);
  const userActions = useUserActions();
  const [avatar, setAvatar] = useState();
  const { setToaster } = useToaster();
  const [isFormValid, setIsFormValid] = useState(false);

 useEffect(() => {
   //setIsFormValid(form.email.trim() !== '' && form.password.trim() !== '');
   setIsFormValid(form.email.trim() !== '');
  }, [form]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const updateProfileForm = event.currentTarget;

    if (updateProfileForm.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    setValidated(true);

    const data = {
      email: form.email,
      password: form.password,
    };

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key] !== undefined ? data[key] : '');
    });

    if (avatar) {
      formData.append("avatar", avatar);
    }

    userActions
      .edit(formData, profile.id)
      .then(() => {
        setToaster({
          show:true,
          type: "success",
          message: "Profile updated successfully",
          title: "Profile updated",
          autohide:true
        });
        navigate(-1);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.detail || "An error occurred";
        setError(errorMessage);
        setToaster({
          type: "error",
          message: errorMessage,
          show: true,
          title: "Update failed",
        });
      });
  };

  return (
    <div    className="">

    <form
      id="registration-form"
      className=""
      noValidate
      onSubmit={handleSubmit}
      >
      <label className="form-control w-full max-w-xs p-4">
      <img
          src={form.avatar}
          className="m-2 border-primary border-2 self-center rounded-full"
          width={120}
          height={120}
          alt="Avatar"
        />

        <input type="file" className="file-input file-input-primary file-input-bordered w-full max-w-xs mt-4" />
      </label>

      <label className="input input-bordered flex items-center gap-2 mt-4">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" /></svg>
        <input type="text" className="grow" placeholder="Email" 
                  value={form.email}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  required
                  //placeholder="Enter email"
                  />
      </label>
      <button
         onClick={() => navigate(`/profile/${form.id}/change-password/`)}
        className="mt-4 btn btn-secondary"
      >
        Change Password
      </button><br></br>
      <button
        type="submit"
        disabled={!isFormValid}

        className={`mt-4 btn btn-primary ${!isFormValid ? 'btn-disabled' : 'btn-active'}`}
      >
        Save changes
      </button>
    </form>
    </div>


  );
}

export default UpdateProfileForm;
