import React, { useEffect, useRef, useState } from 'react';
import axiosService from '../helpers/axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Layout from "../components/Layout";

const DailyNotices = () => {
  const { cpv, date } = useParams();
  const [notices, setNotices] = useState([]);
  const [subcategoryCounts, setSubcategoryCounts] = useState({});
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [pendingSubcategories, setPendingSubcategories] = useState([]);
  const [error, setError] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [noticesCount, setNoticesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const pageSize = 15;
  const isFirstLoad = useRef(true);
  const [copiedId, setCopiedId] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const formatPrice = (price) => {
    if (!price) return '';
    const numPrice = typeof price === 'string' ? parseFloat(price) : price;
    return new Intl.NumberFormat('el-GR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(numPrice);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const subcategoriesParam = searchParams.get('subcategories');
    const pageParam = searchParams.get('page');

    if (subcategoriesParam) {
      const subcategories = subcategoriesParam.split(',').filter(Boolean);
      setSelectedSubcategories(subcategories);
      setPendingSubcategories(subcategories);
    }

    if (pageParam) {
      setCurrentPage(Number(pageParam));
    }

    if (cpv && date) {
      fetchNotices(cpv, date, subcategoriesParam ? subcategoriesParam.split(',').filter(Boolean) : [], pageParam ? Number(pageParam) : 1);
      fetchFilterOptions(cpv, date);
    }
  }, [cpv, date, location.search]);

  const copyToClipboard = (text, id) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedId(id);
      setTimeout(() => setCopiedId(null), 2000);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const ClipboardIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
      <path fillRule="evenodd" d="M15.988 3.012A2.25 2.25 0 0 1 18 5.25v6.5A2.25 2.25 0 0 1 15.75 14H13.5V7A2.5 2.5 0 0 0 11 4.5H8.128a2.252 2.252 0 0 1 1.884-1.488A2.25 2.25 0 0 1 12.25 1h1.5a2.25 2.25 0 0 1 2.238 2.012ZM11.5 3.25a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 .75.75v.25h-3v-.25Z" clipRule="evenodd" />
      <path fillRule="evenodd" d="M2 7a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7Zm2 3.25a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Zm0 3.5a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
    </svg>
  );

  const fetchNotices = async (cpv, date, subcategories = [], page = 1) => {
    try {
      const params = {
        cpv: cpv,
        date: date,
        limit: pageSize,
        page: page,
      };
      if (subcategories.length > 0) {
        params.subcategories = subcategories.join(',');
      }

      const response = await axiosService.get(`${process.env.REACT_APP_API_URL}/tenders/notices/by-cpv-date/`, { params });

      console.log('API response:', response.data);

      setNotices(response.data.results.notices);
      setNoticesCount(response.data.count);
      setNextPage(response.data.next);
      setPreviousPage(response.data.previous);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error fetching notices:', error);
      setError('Failed to fetch notices');
    }
  };

  const fetchFilterOptions = async (cpv, date) => {
    try {
      const response = await axiosService.get(`${process.env.REACT_APP_API_URL}/tenders/notices/filter-options/`, {
        params: { cpv, date }
      });
      setSubcategoryCounts(response.data);
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }
  };

  const handleCheckboxChange = (cpv_code) => {
    const newPendingSubcategories = pendingSubcategories.includes(cpv_code)
      ? pendingSubcategories.filter((item) => item !== cpv_code)
      : [...pendingSubcategories, cpv_code];
  
    setPendingSubcategories(newPendingSubcategories);
    setHasChanges(JSON.stringify(newPendingSubcategories.sort()) !== JSON.stringify(selectedSubcategories.sort()));
  };

  const applyFilters = () => {
    setSelectedSubcategories(pendingSubcategories);
    updateURLParams(pendingSubcategories, 1);
    setCurrentPage(1);
    fetchNotices(cpv, date, pendingSubcategories, 1);
    setHasChanges(false);
  };

const clearFilters = () => {
  if (selectedSubcategories.length > 0 || pendingSubcategories.length > 0) {
    setSelectedSubcategories([]);
    setPendingSubcategories([]);
    updateURLParams([], 1);
    setCurrentPage(1);
    fetchNotices(cpv, date, [], 1);
    setHasChanges(false);
  }
};

  const updateURLParams = (subcategories, page) => {
    const searchParams = new URLSearchParams(location.search);
    if (subcategories.length > 0) {
      searchParams.set('subcategories', subcategories.join(','));
    } else {
      searchParams.delete('subcategories');
    }
    searchParams.set('page', page);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPage(currentPage + 1);
      updateURLParams(selectedSubcategories, currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (previousPage && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      updateURLParams(selectedSubcategories, currentPage - 1);
    }
  };

  const groupedNotices = notices.reduce((acc, notice) => {
    const category = notice.category.name;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(notice);
    return acc;
  }, {});

  const displayStart = (currentPage - 1) * pageSize + 1;
  const displayEnd = Math.min(currentPage * pageSize, noticesCount);

  if (error) {
    return <div>{error}</div>;
  }

  let globalIndex = 0;

  return (
    <Layout>
      <div className="drawer">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col lg:flex-row">
          {/* Desktop filter area */}
          <div className="lg:w-1/4 p-4 lg:sticky lg:top-0 lg:h-screen lg:flex lg:flex-col">
            <h2 className="text-xl mb-4 hidden lg:inline">Υποκατηγορίες</h2>
            <label
              htmlFor="my-drawer"
              className="btn btn-accent drawer-button lg:hidden fixed top-20 right-0 rounded-none z-30"
            >
              Φίλτρα
            </label>
            <div className="hidden lg:flex lg:flex-col lg:h-full relative">
              <div className="flex-grow overflow-y-auto">
                {Object.keys(subcategoryCounts).map(cpv_code => (
                  <div key={cpv_code}>
                    <label className="cursor-pointer">
                      <input
                        type="checkbox"
                        checked={pendingSubcategories.includes(cpv_code)}
                        onChange={() => handleCheckboxChange(cpv_code)}
                        className="mr-2 checkbox checkbox-accent checkbox-xs"
                      />
                      <span className="relative bottom-[2px]">{subcategoryCounts[cpv_code]?.name} ({subcategoryCounts[cpv_code]?.count})</span>
                    </label>
                  </div>
                ))}
               <div className='min-h-[40px]'></div>
              </div>
              <div className="w-full sticky h-[60px] bg-base-100 bottom-0 pt-4 flex justify-between bg-base-100 p-4 shadow-2xl">
                <button className="btn btn-info btn-sm" onClick={applyFilters} disabled={!hasChanges}>Εφαρμογή</button>
                <button className="btn btn-error btn-sm" onClick={clearFilters} disabled={selectedSubcategories.length === 0 && pendingSubcategories.length === 0}>Καθαρισμός</button>
              </div>
            </div>

          </div>
          <div className="lg:w-3/4 p-4">
            <div className="top-0 p-4 z-10 lg:hidden">
              {selectedSubcategories.length > 0 && (
                <div className="text-center space-x-2">
                  <strong>Υποκατηγορίες</strong>
                  <div className="flex text-left overflow-x-auto flex-wrap justify-start items-start">
                    {selectedSubcategories.map(cpv_code => (
                      <span key={cpv_code} className="whitespace-nowrap overflow-hidden text-ellipsis w-[45%] m-2 p-2 bg-base-200">
                        {subcategoryCounts[cpv_code]?.name}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <h2 className="text-xl mb-4">Διαγωνισμοί</h2>
            <p className="mb-4">
              Αποτελέσματα {displayStart}-{displayEnd} απο {noticesCount}
            </p>
            {Object.keys(groupedNotices).map(category => (
              <div key={category} className="my-0 relative">
                {groupedNotices[category].map((notice, index) => {
                  globalIndex++;
                  const bgClass = globalIndex % 2 === 0 ? "bg-base-300" : "bg-base-200";
                  return (
                    <div key={notice.id} className={`notice-list p-0 my-0 ${bgClass}`}>
                      {index === 0 && (
                        <h3 className="text-md category-titles bg-info text-primary-content font-semibold px-4 py-1 w-fit">
                          {category}
                        </h3>
                      )}
                      <div className="p-4">
                        <h3 className="text-lg font-semibold">{notice.subject}</h3>
                        <p>{notice.description}</p>
                        <p><strong>Φορέας:</strong> {notice.organization_name}</p>
                        <p><strong>Αναρτήθηκε:</strong> {notice.published_date}</p>
                        <p><strong className='mr-1 block md:inline'>Καταληκτική Η/νία Υποβολής Προσφορών:</strong> {notice.deadline}</p>
                        <p><strong>Τυπος δημοσίευσης:</strong> {notice.source}</p>
                        <p><strong>Συνολικό Κόστος χωρίς ΦΠΑ:</strong> {formatPrice(notice.total_cost_excl_vat)}</p>
                        <p className="block md:flex justify-start items-center"><strong className='mr-1 block md:inline'>Μοναδικός Κωδικός - ΑΔΑΜ:</strong> {notice.identifier}
                          <button 
                            title="Αντιγραφή"
                            onClick={() => copyToClipboard(notice.identifier, notice.id)}
                            className="btn bg-transparent border-none btn-xs ml-1 text-info relative top-[4px] md:top-0"
                            aria-label="Copy identifier to clipboard"
                          >
                            <ClipboardIcon />
                            {copiedId === notice.id && (
                              <span className="absolute top-[-30px] left-[-10px] bg-success text-primary-content px-2 py-1 rounded text-xs">
                                Copied!
                              </span>
                            )}
                          </button>
                        </p>
                        <p><strong>Περιγραφή με όρους CPVS:</strong> {notice.category.name} ({notice.category.cpv_code})</p>
                        <p><strong>Αριθμός Πρωτοκόλλου:</strong> {notice.protocol_number}</p>
                        <p>
                          <strong>Πηγή:</strong>{" "}
                          <a 
                            href="https://cerpp.eprocurement.gov.gr/upgkimdis/unprotected/home.xhtml"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link link-primary text-info"
                          >
                            ΚΗΜΔΗΣ
                          </a>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}

            <div className="join grid grid-cols-3 w-full max-w-[400px] m-auto mt-5">
              <button 
                className="join-item btn btn-outline" 
                onClick={handlePreviousPage} 
                disabled={!previousPage}
              >
                «
              </button>
              <button className="join-item btn btn-outline">
                Σελ {currentPage} / {Math.ceil(noticesCount / pageSize)}
              </button>
              <button 
                className="join-item btn btn-outline" 
                onClick={handleNextPage} 
                disabled={!nextPage}
              >
                »
              </button>
            </div>
          </div>
        </div>
        <div className="drawer-side z-40">
          <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
          <ul className="menu p-4 w-80 min-h-full bg-base-200 flex flex-col">
            <h3 className="text-lg mb-2">Υποκατηγορίες</h3>
            <div className="flex-grow overflow-y-auto">
              {Object.keys(subcategoryCounts).map(cpv_code => (
                <div key={cpv_code}>
                  <label className="cursor-pointer">
                    <input
                      type="checkbox"
                      checked={pendingSubcategories.includes(cpv_code)}
                      onChange={() => handleCheckboxChange(cpv_code)}
                      className="checkbox checkbox-accent checkbox-xs mr-2"
                    />
                    <span className="relative bottom-[2px]">{subcategoryCounts[cpv_code]?.name} ({subcategoryCounts[cpv_code]?.count})</span>
                  </label>
                </div>
              ))}
            </div>
              <div className='min-h-[50px]'></div>
          </ul>
          <div className="mt-auto p-5 w-80 h-[60px] fixed bottom-0 pt-4 flex justify-between bg-base-100">
            <button className="btn btn-info btn-sm" onClick={applyFilters} disabled={!hasChanges}>Εφαρμογή</button>
            <button className="btn btn-error btn-sm" onClick={clearFilters} disabled={selectedSubcategories.length === 0 && pendingSubcategories.length === 0}>Καθαρισμός</button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DailyNotices;