import React, { useEffect, useState } from "react";
import { Link, useNavigate  } from "react-router-dom";
import { getUser, useUserActions } from "../hooks/user.actions";
import RegistrationForm from "../components/authentication/RegistrationForm";

function Registration() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const userActions = useUserActions();

  useEffect(() => {
    const checkUser = async () => {
      const localUser = getUser();
      if (!localUser) {
        setLoading(false);
        return;
      }

      try {
        const updatedUser = await userActions.fetchCurrentUser();
        setUser(updatedUser);
        if (updatedUser.is_verified) {
          navigate("/");
        } else {
          navigate("/verify-email-notice/");
        }
      } catch (error) {
        setUser(null);
      }
      setLoading(false);
    };

    checkUser();
  }, [userActions, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (user && user.is_verified) {
    return null; // Return null if the user is already verified and navigated
  }

  return (
    <div className="container mx-auto p-4 w-full bg-base-300 min-h-screen max-w-full flex justify-center items-center">
      <div className="flex flex-wrap max-w-[740px] m-auto">
        <div className="w-full h-auto flex justify-center items-center">
          <div className="text-center px-4">
            <h1 className="text-info font-semibold">Welcome to the Bidhunter!</h1>
            <p className="text-center px-4">
              This is a new site that will allow you to stay tuned about new government 
              contract opportunities. Register now and let the bidhunter find new business opportunites! <br />
              Or if you already have an account, please{" "}
              <Link to="/login/" className="text-info underline font-semibold">login</Link>.
            </p>
          </div>
        </div>
        <div className="w-full p-5">
          <RegistrationForm />
        </div>
      </div>
    </div>
  );
}

export default Registration;