import React from "react";
import { Link } from "react-router-dom";
import ForgotPasswordForm from "../components/authentication/ForgotPasswordForm";

function ForgotPassword() {
  return (
    <div className="container mx-auto p-4 w-full bg-base-300 min-h-screen max-w-full flex justify-center items-center">
      <div className="flex flex-wrap max-w-[740px] m-auto">
        <div className="w-full h-auto flex justify-center items-center">
          <div className="text-center px-4">
            <h1 className="text-info font-semibold">Forgot Password?</h1>
            <p className="content">
              Enter your email address below to receive a password reset link.<br></br>
              click here to return to {" "}
              <Link to="/login/" className="text-info underline font-semibold">login</Link>.
            </p>
          </div>
        </div>
        <div className="w-full p-5">
            <ForgotPasswordForm />
          </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
