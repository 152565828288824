import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserActions, getUser } from "../hooks/user.actions";

const VerifyEmailNotice = () => {
  const userActions = useUserActions();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("auth");
    navigate("/login/");
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const fetchedUser = await userActions.fetchCurrentUser();
        if (fetchedUser.is_verified) {
          navigate("/"); // Redirect to home if user is already verified
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        const localUser = getUser();
        if (localUser && localUser.is_verified) {
          navigate("/"); // Redirect to home if local user is already verified
        }
      }
    };

    fetchUser();
  }, [navigate, userActions]);

  const handleResendEmail = async () => {
    setLoading(true);
    try {
      await userActions.resendVerificationEmail();
      setMessage("Verification email has been resent. Please check your inbox.");
      setIsError(false);
    } catch (error) {
      setMessage("Failed to resend verification email. Please try again.");
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4 w-full bg-base-300 max-w-full min-h-screen flex items-center justify-center">
      <div className="flex flex-wrap max-w-[1520px] m-auto">
        <div className="text-center px-4">
          <h2 className="text-2xl font-bold mb-4 text-Primary">Email Verification Required</h2>
          <p>Your email is not verified. Please press the button to send a verification email. <br></br>Then check your inbox and follow the verification link.</p>
          <button
            onClick={handleResendEmail}
            className="btn btn-primary mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={loading}
          >
            {loading ? "Sending..." : "Send Email"}
          </button>
          {message && <p className={`mt-4 text-md ${isError ? 'text-error' : 'text-success'}`}>{message}</p>}
          <div className="m-8"><p>if you dont want to verify now, you can <a href="#" className="text-primary underline" onClick={handleLogout}>Sign out</a></p></div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailNotice;
