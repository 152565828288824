import React, { useContext } from "react";
import Navigationbar from "./Navbar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import Toaster from "./Toaster";
import ThemeContext from "../helpers/context/themeContext";

function Layout(props) {
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);

  const { hasNavigationBack } = props;
  return (
    <div className={`flex flex-col min-h-screen bg-base-100`} data-theme={theme}>
      <Navigationbar />
      {hasNavigationBack && (
        <div
          title="back"
          className="text-primary text-xl cursor-pointer ml-[5%] mt-5"
          onClick={() => navigate(-1)}
        > 
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-10">
            <path fillRule="evenodd" d="M7.28 7.72a.75.75 0 0 1 0 1.06l-2.47 2.47H21a.75.75 0 0 1 0 1.5H4.81l2.47 2.47a.75.75 0 1 1-1.06 1.06l-3.75-3.75a.75.75 0 0 1 0-1.06l3.75-3.75a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
          </svg>
        </div>
      )}
      <div className="container my-10 mx-auto grow">{props.children}</div>
      <Footer />
      <Toaster />
    </div>
  );
}

export default Layout;
