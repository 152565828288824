import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserActions } from "../../hooks/user.actions";
import { useToaster } from "../../helpers/context/toasterContext";

function ChangePasswordForm() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: ""
  });
  const [error, setError] = useState(null);
  const { setToaster } = useToaster();
  const userActions = useUserActions();
  const [isFormValid, setIsFormValid] = useState(false);

  const [showPasswords, setShowPasswords] = useState(false); // State for all password visibility

  useEffect(() => {
    setIsFormValid(form.old_password.trim() !== '' && form.new_password.trim() !== '' && form.confirm_new_password.trim() !== '');
  }, [form]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (form.new_password !== form.confirm_new_password) {
      setError("New passwords do not match");
      return;
    }

    userActions.changePassword(form)
      .then(() => {
        setToaster({
          show: true,
          type: "success",
          message: "Password changed successfully",
          title: "Password changed",
          autoHide: false
        });
        navigate("/");
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.detail || "An error occurred";
        setError(errorMessage);
        setToaster({
          type: "error",
          message: errorMessage,
          show: true,
          title: "Change failed",
        });
      });
  };

  return (
    <div className="w-[80%] m-auto flex flex-col relative">
      <button
        type="button"
        onClick={() => setShowPasswords(!showPasswords)}
        className="absolute top-0 right-0 mt-2 mr-2 text-sm leading-5 text-primary"
      >
        {showPasswords ? "Hide All" : "Show All"}
      </button>
      <form onSubmit={handleSubmit} className="mt-4">
        <label className="flex items-center gap-2 mt-4">
          <input
            type={showPasswords ? "text" : "password"} // Toggle between text and password
            placeholder="Παλιός Κωδικός"
            value={form.old_password}
            className="mt-1 block w-full px-3 py-2 border border-primary rounded"
            onChange={(e) => setForm({ ...form, old_password: e.target.value })}
            required
          />
        </label>
        <label className="flex items-center gap-2 mt-4">
          <input
            type={showPasswords ? "text" : "password"} // Toggle between text and password
            placeholder="Νέος Κωδικός"  
            value={form.new_password}
            className="mt-1 block w-full px-3 py-2 border border-primary rounded"
            onChange={(e) => setForm({ ...form, new_password: e.target.value })}
            required
          />
        </label>
        <label className="flex items-center gap-2 mt-4">
          <input
            type={showPasswords ? "text" : "password"} // Toggle between text and password
            placeholder="Επαλήθευση Νέου Κωδικού"
            value={form.confirm_new_password}
            className="mt-1 block w-full px-3 py-2 border border-primary rounded"
            onChange={(e) => setForm({ ...form, confirm_new_password: e.target.value })}
            required
          />
        </label>
        {error && <div className="text-error mt-2">{error}</div>}
        <button
          type="submit"
          disabled={!isFormValid}
          className={`mt-4 btn btn-primary ${!isFormValid ? 'btn-disabled' : 'btn-active'}`}
        >
          Change Password
        </button>
      </form>
    </div>
  );
}

export default ChangePasswordForm;
