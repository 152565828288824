import React from "react";
import Layout from "../components/Layout";

import { getUser } from "../hooks/user.actions";

import CategoryList from '../components/categories/CategoryList';


function Home() {

  const user = getUser();

  if (!user) {
    return <div>Loading!</div>;
  }

  return (
    <Layout>
      <div className="flex justify-evenly">
        <div className="flex flex-col justify-center items-center">
          <h2 className="card-title text-center mb-5">Newsletter Subscriptions</h2>
          <CategoryList />
        </div>
      </div>
    </Layout>
  );
}

export default Home;