// src/components/Toaster.js
import React, { useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import { useToaster } from '../helpers/context/toasterContext';

const Toaster = () => {
  const toastRef = useRef(null);
  const { toaster, setToaster } = useToaster();
  const { show, type, title, message, autoHide=true, autoHideDuration=3000 } = toaster;

  const typeClassMapping = {
    success: 'alert-success',
    warning: 'alert-warning',
    error: 'alert-error',
    info: 'alert-info',
  };

  const alertClass = typeClassMapping[type] || '';

  useEffect(() => {
    if (!autoHide) {
      const handleClickOutside = (event) => {
        if (toastRef.current && !toastRef.current.contains(event.target)) {
          console.log('Clicked outside, hiding toaster');
          setToaster({ ...toaster, show: false });
        }
      };

      if (show) {
        console.log('Adding mousedown listener for click outside');
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        console.log('Removing mousedown listener for click outside');
        document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [show, setToaster, autoHide, toaster]);

  useEffect(() => {
    let timer;
    if (show && autoHide) {
      console.log(`Setting auto-hide timer for ${autoHideDuration}ms`);
      timer = setTimeout(() => {
        console.log('Auto-hide timer triggered, hiding toaster');
        setToaster({ ...toaster, show: false });
      }, autoHideDuration);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [show, autoHide, autoHideDuration, setToaster, toaster]);

  return (
    <div className="toast toast-bottom toast-right">
      <Transition
        show={show}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div ref={toastRef} role="alert" className={`alert ${alertClass}`}>
          <h3 className="font-bold">{title}</h3>
          <div className="text-xs">{message}</div>
          {!autoHide && (
            <button onClick={() => setToaster({ ...toaster, show: false })} className="btn btn-sm">
              Close
            </button>
          )}
        </div>
      </Transition>
    </div>
  );
};

export default Toaster;
