import React, { useEffect, useState } from "react";
import { Link, useNavigate, } from "react-router-dom";
import { getUser, useUserActions } from "../hooks/user.actions";
import LoginForm from "../components/authentication/LoginForm";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const userActions = useUserActions();

  useEffect(() => {
    const checkUser = async () => {
      const localUser = getUser();
      if (!localUser) {
        setLoading(false);
        return;
      }

      try {
        const updatedUser = await userActions.fetchCurrentUser();
        setUser(updatedUser);
        if (updatedUser.is_verified) {
          navigate("/");
        } else {
          navigate("/verify-email-notice/");
        }
      } catch (error) {
        setUser(null);
      }
      setLoading(false);
    };

    checkUser();
  }, [userActions, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (user && user.is_verified) {
    return null; // Return null if the user is already verified and navigated
  }

  return (
    <div className="container mx-auto p-4 w-full bg-base-300 max-w-full">
      <div className="flex flex-wrap max-w-[1520px] m-auto">
        <div className="w-full md:w-1/2 flex items-center">
          <div className="text-center px-4">
            <h1 className="text-info font-semibold">Welcome to Bidhunter!</h1>
            <p className="content">
              Login now and set up your contract notifications! <br />
              Or if you don't have an account, please{" "}
              <Link to="/register/" className="text-info underline font-semibold">register</Link>.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-5">
          <LoginForm />
        </div>
      </div>
    </div>
  );
}

export default Login;