import React, { useEffect, useState } from 'react';
import axiosService from '../../helpers/axios';
import { useUserActions } from '../../hooks/user.actions';
import { useToaster } from '../../helpers/context/toasterContext';

const CategoryList = ({ onCategorySelect }) => {
  const [categories, setCategories] = useState([]);
  const [topLevelSubscriptions, setTopLevelSubscriptions] = useState([]);
  const [initialTopLevelSubscriptions, setInitialTopLevelSubscriptions] = useState([]);
  const [subscribe, setSubscribe] = useState([]);
  const [unsubscribe, setUnsubscribe] = useState([]);
  const [error, setError] = useState(null);
  const baseURL = process.env.REACT_APP_API_URL;
  const userActions = useUserActions();
  const { setToaster } = useToaster();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryResponse = await axiosService.get(`${baseURL}/newsletter/category/newsletter-categories/`);
        setCategories(categoryResponse.data);

        const subscriptionResponse = await axiosService.get(`${baseURL}/newsletter-subscriptions/subscriptions/`);
        
        // Extract the category IDs from the subscription objects
        const subscribedIds = Array.isArray(subscriptionResponse.data)
          ? subscriptionResponse.data.map(sub => sub.category)
          : [];
        
        setTopLevelSubscriptions(subscribedIds);
        setInitialTopLevelSubscriptions(subscribedIds);
      } catch (error) {
        console.error('Error fetching categories or subscriptions:', error);
        setError('Failed to fetch categories or subscriptions');
      }
    };

    fetchCategories();
  }, [baseURL]);

  useEffect(() => {
    const subscribeList = topLevelSubscriptions.filter(id => !initialTopLevelSubscriptions.includes(id));
    const unsubscribeList = initialTopLevelSubscriptions.filter(id => !topLevelSubscriptions.includes(id));
    setSubscribe(subscribeList);
    setUnsubscribe(unsubscribeList);
  }, [topLevelSubscriptions, initialTopLevelSubscriptions]);

  const handleCheckboxChange = (categoryId) => {
    setTopLevelSubscriptions((prevSubscriptions) =>
      prevSubscriptions.includes(categoryId)
        ? prevSubscriptions.filter((id) => id !== categoryId)
        : [...prevSubscriptions, categoryId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await userActions.updateNewsletterSubscriptions({ subscribe, unsubscribe });
      setToaster({
        show: true,
        type: 'success',
        message: 'Subscriptions updated successfully',
        title: 'Subscription Updated',
        autoHide: true,
      });
      setInitialTopLevelSubscriptions(topLevelSubscriptions);
    } catch (error) {
      console.error('Error updating subscriptions:', error);
      const errorMessage = error?.response?.data?.detail || 'Failed to update subscriptions';
      setError(errorMessage);
      setToaster({
        type: 'error',
        message: errorMessage,
        show: true,
        title: 'Subscription Update Failed',
      });
    }
  };

  const hasChanges = () => {
    return subscribe.length > 0 || unsubscribe.length > 0;
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="p-5">
      <form onSubmit={handleSubmit}>
        <ul>
          {categories.map(category => (
            <li key={category.id} className="border-solid border-b-[1px] border-base-content">
              <label className="category-label cursor-pointer text-left block w-full h-full p-2">
                <input
                  type="checkbox"
                  checked={topLevelSubscriptions.includes(category.id)}
                  onChange={() => handleCheckboxChange(category.id)}
                  className="category-checkbox checkbox checkbox-sm checkbox-info  toggle-info  mt-1 mr-5" 
                />
                <span className="category-label-span relative bottom-1">{category.name}</span>
              </label>
            </li>
          ))}
        </ul>
        <button 
          className="btn btn-info mt-5" 
          type="submit"
          disabled={!hasChanges()}
        >
          Update Subscriptions
        </button>
      </form>
    </div>
  );
};

export default CategoryList;
