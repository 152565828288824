import Home from "../pages/Home";
import Registration from "../pages/Registration";
import Login from "../pages/Login";
import Profile from "../pages/Profile";
import EditProfile from "../pages/EditProfile";
import ChangePassword from "../pages/ChangePassword";
import CategoriesAll from "../pages/CategoriesAll"
import VerifyEmail from "../pages/VerifyEmail";
import VerifyEmailNotice from "../pages/VerifyEmailNotice";
import DailyNotices from "../pages/DailyNotices";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";


const componentsMap = {
  Home,
  Registration,
  Login,
  Profile,
  EditProfile,
  ChangePassword,
  CategoriesAll,
  VerifyEmail,
  VerifyEmailNotice,
  DailyNotices,
  ForgotPassword,
  ResetPassword
};

const routesConfig = [
  {
    path: "/",
    component: "Home",
    protected: true,
    guestOnly: false,
  },
  {
    path: "profile/:profileId/",
    component: "Profile",
    protected: true,
    guestOnly: false,
  },
  {
    path: "profile/:profileId/edit/",
    component: "EditProfile",
    protected: true,
    guestOnly: false,
  },
  {
    path: "/profile/:profileId/change-password",
    component: "ChangePassword",
    protected: true,
    guestOnly: false,
  },
  {
    path: "/register/",
    component: "Registration",
    protected: false,
    guestOnly: true,
  },
  {
    path: "/login/",
    component: "Login",
    protected: false,
    guestOnly: true,
  },
  {
    path: "/categories/",
    component: "CategoriesAll",
    protected: false,
    guestOnly: false,
  },
  {
    path: "/verify-email/:token",
    component: "VerifyEmail",
    protected: true,
    guestOnly: false,
  },
  {
    path: "/verify-email-notice/",
    component: "VerifyEmailNotice",
    protected: false,
    guestOnly: false,
  },
  {
    path: "/daily-notices/:cpv/:date/",
    component: "DailyNotices",
    protected: true,
    guestOnly: false,
  },
  {
    path: "/forgot-password/",
    component: "ForgotPassword",
    protected: false,
    guestOnly: false,
  },
  {
    path: "/reset-password/:uidb64/:token",
    component: "ResetPassword",
    protected: false,
    guestOnly: false,
  }
];

export { routesConfig, componentsMap };
