import React from "react";
import ResetPasswordForm from "../components/authentication/ResetPasswordForm";

function ResetPassword() {
  return (
    <div className="container mx-auto p-4 w-full max-w-full flex items-center justify-center min-h-screen">
      <div className="flex flex-wrap max-w-[1520px] m-auto ">
        <div className="text-center px-4 w-full">
          <h1 className="text-info font-semibold">Reset Password</h1>
          <p className="content">
            Enter your new password below.
          </p>
        </div>
        <div className="w-full p-5">
        <ResetPasswordForm />
      </div>
      </div>
    </div>
  );
}

export default ResetPassword;
