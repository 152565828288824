import React, { useEffect, useState, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getUser, useUserActions } from "../hooks/user.actions";

function ProtectedRoute({ children, isProtected, guestOnly }) {
  const [loading, setLoading] = useState(true);
  const userActions = useUserActions();
  const location = useLocation();
  const isFirstLoad = useRef(true);

  useEffect(() => {
    const fetchUserIfNeeded = async () => {
      const currentUser = getUser();
      if (!currentUser || (currentUser && !currentUser.is_verified)) {
        try {
          await userActions.fetchCurrentUser();
        } catch (error) {
          // Handle error if needed
        }
      }
      setLoading(false);
    };

    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      fetchUserIfNeeded();
    } else {
      setLoading(false);
    }
  }, [userActions]);

  const user = getUser();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isProtected) {
    if (!user) {
      return <Navigate to="/login/" state={{ from: location }} />;
    }

    if (!user.is_verified && !location.pathname.startsWith(`/verify-email/`)) {
      return <Navigate to="/verify-email-notice/" />;
    }

    return <>{children}</>;
  }

  if (guestOnly && user) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}

export default ProtectedRoute;