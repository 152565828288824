import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserActions, getUser } from "../hooks/user.actions";
import LoginForm from "../components/authentication/LoginForm";

const VerifyEmail = () => {
  const { token } = useParams();
  const [message, setMessage] = useState("Processing...");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userActions = useUserActions();
  const navigate = useNavigate();
  const isFirstLoad = useRef(true);

  useEffect(() => {
    const user = getUser();
    if (isFirstLoad.current && user) {
      isFirstLoad.current = false;
      setIsLoggedIn(true);
      verifyEmail(token); // User is logged in, proceed to verify email
    } else {
      setMessage("You need to log in to verify your email.");
    }
  }, [token,]);

  const verifyEmail = async (token) => {
    try {
      const response = await userActions.verifyEmail(token);
      if (response.error) {
        setMessage(response.error);
      } else {
        setMessage("Verification succeeded. Redirecting...");    
        setTimeout(() => {
          navigate("/");
        }, 3000); // Redirect to home after 3 seconds
      }
    } catch (error) {
      setMessage("Verification failed. Please try again.");
    }
  };

  const handleLogin = async (data) => {
    try {
      const user = await userActions.login(data);
      setIsLoggedIn(true);
      if (user.is_verified) {
        navigate("/"); // Navigate to home if user is verified
      } else {
        verifyEmail(token); // Retry email verification after login if not verified
      }
    } catch (error) {
      setMessage("Login failed. Please try again.");
    }
  };

  return (
    <div className="container mx-auto p-4 w-full bg-base-300 max-w-full min-h-screen flex items-center justify-center">
      <div className="flex flex-wrap max-w-[1520px] m-auto">
        <div className="text-center px-4">
          <h2 className="text-2xl font-bold mb-4 text-primary">Email Verification</h2>
          <p>{message}</p>
          {!isLoggedIn && (
            <div>
              <p>Please log in to verify your email.</p>
              <LoginForm onSubmit={handleLogin} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
